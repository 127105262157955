import React, { useState, useEffect } from "react";
import logo from "../logo.svg";
import "../App.css";

import MyInvestEDetail from "../components/myInvest/MyInvestEDetail";
function MyInvestEPage() {
  const [message, setMessage] = useState("");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <MyInvestEDetail />{" "}
    </>
  );
}

export default MyInvestEPage;
