import styles from "../../../styles/admin.module.css";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import pro from "../../../assets/images/profile_info.png";
import Moment from "react-moment";
import CountUp from "react-countup";
import { numberToKorean } from "../../../lib/numberToKorean";
import { wonComma } from "../../../lib/wonComma";
import { FaArrowUp } from "react-icons/fa";
import moment from "moment";
const AdminIngOneDetail = ({ data }) => {
  const wonReplace = () => {
    let won = String(data.pro_target_amount);
    if (won.length === 6) {
      return wonComma(data.pro_target_amount);
    } else if (won.length === 7) return numberToKorean(data.pro_target_amount);
    // return numberToKorean(580000000);
  };
  const wonReplace1 = () => {
    let won = String(data.pro_target_amount);
    if (won.length === 6) {
      return wonComma(data.pro_target_amount);
    } else if (won.length === 7)
      // return numberToKorean(926350000);
      return numberToKorean(data.amount);
  };

  const percent = () => {
    let result = data.pro_target_amount;
    let ing = data.amount;

    return (ing / result) * 100;
    // return 159;
  };
  const totalSeconds = 86400;
  const [count, setCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [switchs, setSwtichs] = useState(true);

  useEffect(() => {
    const startSecond = new Date(
      moment().format("YYYY-MM-DD 00:00:00")
    ).getTime();

    const tenSecUp = (data.invest_interest_ing / totalSeconds) * 1; // 10초당 올라갈 숫자
    const interval = setInterval(() => {
      // console.log(tenSecUp);
      const elapsedMSec = (new Date().getTime() - startSecond) / 1000;

      const currentCount = (elapsedMSec / 1) * tenSecUp; // 현재 숫자 계산
      const endCounts = (elapsedMSec / 1 + 1) * tenSecUp; // 다음 숫자 계산

      setCount(data.invest_interest_sum + currentCount);

      setEndCount(data.invest_interest_sum + endCounts);
    }, 1000); // 10초마다 count 값 갱신

    return () => {
      clearInterval(interval);
    };
  }, []);

  const resultGoal = () => {
    const inter = data.invest_interest_ing;
    const goal = 100000;
    const ing = data.invest_interest_sum;

    if (goal > ing) {
      return ((goal - ing) / inter).toFixed(0);
    }
  };
  const resultGoalDate = () => {
    const inter = data.invest_interest_ing;
    const goal = 100000;
    const ing = data.invest_interest_sum;

    if (goal > ing) {
      return moment()
        .add(((goal - ing) / inter).toFixed(0), "days")
        .format("YYYY-MM-DD");
    }
  };

  const getDateDiff = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);

    const diffDate = date1.getTime() - date2.getTime();

    return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
  };
  const interestResult = () => {
    const toDate = moment().format("YYYY-MM-DD 00:00:00");
    const endDate = moment(data.invest_end_date).format("YYYY-MM-DD 00:00:00");

    let allDay = getDateDiff(toDate, endDate);

    return wonComma(
      Math.floor(allDay * data.invest_interest_ing + data.invest_interest_sum)
    );
  };
  return (
    <>
      <div className={styles.invest_wrap}>
        <div className={styles.invest_withdraw_section}>
          <div className={styles.invest_withdraw_box}>
            <div className={styles.invest_product_item}>
              <h2>투자 상품 현황</h2>
              <div className={styles.invest_product_box}>
                {data && (
                  <>
                    {" "}
                    <div className={styles.invest_my_data_box}>
                      <div className={styles.invest_my_data_top}>
                        <p>{data.pro_title}</p>
                        <div className={styles.invest_my_data_top_div}>
                          <div>
                            {" "}
                            <span>
                              시작일 :{" "}
                              <Moment format="YYYY-MM-DD 00:00:00">
                                {data.invest_start_date}
                              </Moment>
                            </span>{" "}
                            <span>
                              마감일 :{" "}
                              <Moment format="YYYY-MM-DD 23:59:59">
                                {data.invest_end_date}
                              </Moment>
                            </span>
                          </div>

                          <span>수익률 : {data.pro_rate}%</span>
                        </div>
                      </div>
                      <div className={styles.invest_my_data_box_1}>
                        <span>투자금액</span>
                        <p>
                          <em>{wonComma(data.invest_amount)}</em>원
                        </p>
                      </div>{" "}
                      <div className={styles.invest_my_data_box_2}>
                        <span>지금까지 쌓인 이자</span>{" "}
                        {data.invest_confirm_ing === "Y" &&
                          data.invest_interest_withdraw !== "C" &&
                          data.invest_interest_withdraw !== "R" && (
                            <p>
                              <em>
                                {" "}
                                <CountUp
                                  start={count}
                                  end={endCount}
                                  duration={1}
                                  separator={","}
                                  decimals={3}
                                  // suffix={"원"}
                                />
                              </em>
                              원 <br />
                              &nbsp;
                              {data.invest_interest_withdraw === "Y" &&
                                "(이자 출금 대기중)"}
                            </p>
                          )}{" "}
                        {(data.invest_interest_withdraw === "N" ||
                          data.invest_interest_withdraw === "C") &&
                          data.invest_confirm_ing === "N" && (
                            <p>
                              <em> 대기 상품</em> <br />
                              {data.invest_interest_withdraw === "C" &&
                                "(취소 대기중)"}
                              {/* <em> 0</em>원 */}
                            </p>
                          )}
                        {data.invest_interest_withdraw === "R" && (
                          <p>
                            <em> {wonComma(data.invest_refund_stop)}</em>원{" "}
                            <br />
                            (환불 대기중)
                          </p>
                        )}{" "}
                        {data.invest_confirm_ing === "E" && (
                          <p>
                            <em> {wonComma(data.invest_interest_sum)}</em>원{" "}
                            <br />
                            (종료 대기중)
                          </p>
                        )}
                        {/* <p>
                          <em>
                            {" "}
                            <CountUp
                              start={count}
                              end={endCount}
                              duration={1}
                              separator={","}
                              decimals={3}
                            />
                          </em>
                          원
                        </p> */}
                      </div>{" "}
                      {(data.invest_confirm_ing === "Y" ||
                        data.invest_confirm_ing === "N") && (
                        <>
                          <div className={styles.invest_my_data_box_2}>
                            <span>만기시 예상 이자 금액</span>
                            <p>
                              <em>{interestResult()}원</em>
                            </p>
                          </div>
                          <div className={styles.invest_my_data_box_2}>
                            <span>10만원 진행 도달 날짜(어제 자정 기준)</span>
                            <p>
                              <em>{resultGoalDate()} </em> ({resultGoal()}일 후)
                            </p>
                          </div>{" "}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminIngOneDetail;
