import main from "../../styles/main.module.css";
import MainNewsDetail from "./detail/MainNewsDetail";
import MainRankDetail from "./detail/MainRankDetail";
import { useState, useEffect } from "react";
import { CommonAxios } from "../CommonAxios";
import ready from "../../assets/images/ready.png";
import React from "react";
import ionc1 from "../../assets/images/Layer 515.png";
import ionc2 from "../../assets/images/Layer 516.png";
import ionc3 from "../../assets/images/Layer 517.png";
import ionc4 from "../../assets/images/Layer 518.png";
import ionc5 from "../../assets/images/Layer 519.png";
import { MdError } from "react-icons/md";
const MainRank = () => {
  // const [data, setData] = useState([]);
  // useEffect(() => {
  //   let sendData = {
  //     // no: id,
  //     no: "",
  //   };
  //   // dispatch(selectInvest(sendData));
  //   CommonAxios(
  //     process.env.REACT_APP_HOSTDONAME + "/api/select_main_ranking",
  //     sendData,
  //     function (result) {
  //       if (result.messageinfo.state === "ok") {
  //         setData(result.messageinfo.message);
  //       } else {
  //       }
  //     }
  //   );
  // }, []);
  // const [data1, setData1] = useState([]);

  // useEffect(() => {
  //   if (data) {
  //     // console.log(data1);
  //     let b = data;
  //     // setData1(data);
  //     if (data.length < 5) {
  //       for (let a = 0; a < 8 - data.length; a++) {
  //         // setData1(
  //         //   data1.concat({ no: "not", pro_title: "준비중인 상품", pro_img: "" })
  //         // );
  //         b.push({
  //           no: "not",
  //           pro_title: "준비중인 상품",
  //           pro_date: "",
  //           pro_img: ready,
  //         });
  //       }
  //       console.log(b);
  //       setData1(b);
  //     }
  //   }
  // }, [data]);
  const data = [
    {
      num: "1",
      tit: "[앵천리 M3팜] 담수경 수경재배 농장과 함께하는 스마트팜 투자 모델",
      per: 51,
      c: "(주)메타그린",
      img: ionc1,
    },
    {
      num: "2",
      tit: "[이지리프] 무농약 친환경 재배 프리미엄 유러피안 채소 투자 상품",
      per: 92,
      c: "(주)메타그린",
      img: ionc2,
    },
    {
      num: "3",
      tit: "[프릴아이스] 무농약 친환경 재배 프리미엄 유러피안 채소 투자 상품",
      per: 46,
      c: "(주)메타그린",
      img: ionc3,
    },
    {
      num: "4",
      tit: "[스마트팜] 무농약 친환경 재배 프리미엄 유러피안 채소 투자 상품",
      per: 63,
      c: "(주)메타그린",
      img: ionc4,
    },
    {
      num: "5",
      tit: "[농장분양] 무농약 친환경 재배 프리미엄 유러피안 채소 투자 상품!",
      per: 38,
      c: "(주)메타그린",
      img: ionc5,
    },
  ];
  const [mainRank, setMainRank] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  useEffect(() => {
    let sendData = {
      data: "",
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_rank",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setMainRank(result.messageinfo.message);
          setSkeleton(true);
          // setUserInfo(result.messageinfo.message[0]);
          // localStorage.removeItem("meta_info");
          // document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  }, []);

  const [click, setClick] = useState(1);
  return (
    <>
      <h3 className={main.main_title_h3}>실시간 인기상품</h3>
      {/* <span className={main.main_title_sub}>
        매일 새로운 펀딩 소식을 만나보세요!
      </span> */}
      {/* <ul className={main.main_rank_menu}>
        <li>
          <span
            className={
              click === 1
                ? `${main.rank_menu} ${main.active}`
                : `${main.active}`
            }
            onClick={() => setClick(1)}
          >
            채소/메타그린
          </span>
        </li>
        <li>
          <span
            className={
              click === 2
                ? `${main.rank_menu} ${main.active}`
                : `${main.active}`
            }
            onClick={() => setClick(2)}
          >
            제주농장
          </span>
        </li>
      </ul> */}
      <div className={main.main_rank_box}>
        {/* {!skeleton && <MainNewsSkeleton />} */}
        {/* {!skeleton && <MainNewsSkeleton />} */}
        {skeleton && mainRank.length === 0 && (
          <div className={`${main.main_rank_inner} ${main.main_rank_not}`}>
            <div className={main.main_not_data}>
              {" "}
              <span className={main.main_not_span}>
                <MdError style={{ marginRight: "4px" }} />
                상품이 등록되어 있지 않습니다.
              </span>
            </div>
          </div>
        )}
        {skeleton && mainRank.length > 0 && (
          <>
            <div className={main.main_rank_inner}>
              {mainRank.map((data1) => {
                return (
                  <React.Fragment>
                    {" "}
                    <MainRankDetail data1={data1} />
                  </React.Fragment>
                );
              })}
            </div>
          </>
        )}

        {/* <div className={main.main_rank_inner}>
          {data &&
            data.map((data1, index) => {
              return (
                <React.Fragment>
                  {" "}
                  <MainRankDetail data1={data1} index={index} />
                </React.Fragment>
              );
            })}
        </div> */}
      </div>
    </>
  );
};

export default MainRank;
