import MainItemSlider from "./MainItemSlider";
import main from "../../styles/main.module.css";
import { useEffect, useState } from "react";
import { CommonAxios } from "../CommonAxios";
import { useSelector } from "react-redux";
import store1 from "../../assets/images/Layer 521.png";
import store2 from "../../assets/images/Layer 522.png";
import store3 from "../../assets/images/Layer 523.png";
// import store4 from "../../assets/images/Layer 524.png";
import store4 from "../../assets/images/Layer 525.png";
import store5 from "../../assets/images/Layer 526.png";
import store6 from "../../assets/images/Layer 527.png";
import { BsQuestionCircle, BsQuestionCircleFill } from "react-icons/bs";
import { wonComma } from "../../lib/wonComma";
import { FaStar } from "react-icons/fa";
import { MdError } from "react-icons/md";
const MainSection5 = () => {
  const [data, setData] = useState([]);
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  useEffect(() => {
    let sendData = {
      // no: id,
      no: "",
    };

    if (user) {
      sendData.no = user.no;
    }
    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_store_two",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
          setSkeleton(true);
        } else {
        }
      }
    );
  }, []);
  const [brand, setBrand] = useState([]);
  const [skeleton1, setSkeleton1] = useState(false);
  useEffect(() => {
    let sendData = {
      // no: id,
      no: 2,
    };

    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_brand",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setBrand(result.messageinfo.message);
          setSkeleton1(true);
        } else {
        }
      }
    );
  }, []);

  const StoreData = ({ data }) => {
    const btn_move = (no) => {
      let sendData = {
        no: no,
      };
      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/main_store_click",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
          } else {
            //   alert(result.messageinfo.message);
          }
        }
      );

      // navigate(`/notice/${no}`);
    };

    return (
      <>
        <div
          className={main.store_item_mobile}
          onClick={() => btn_move(data.no)}
        >
          {" "}
          <a target="_blank" href={data.store_link}>
            <div className={main.store_item_inner_mobile}>
              <div className={main.store_item_img_mobile}>
                <img src={`/store/${data.no}/${data.store_img}`} />
              </div>
              <div className={main.store_item_cont_mobile}>
                <div className={main.store_item_content_mobile}>
                  {data.store_title}
                </div>
                <div className={main.store_item_detail_mobile}>
                  <p>{wonComma(data.store_people)}명 인증</p>
                  <p>
                    <FaStar /> {data.store_grade}
                  </p>
                </div>
                <div className={main.store_item_type_mobile}>
                  {data.icon1 && <span>무료배송</span>}
                  {data.icon2 && <span>와배송</span>}
                </div>
              </div>
            </div>{" "}
          </a>
        </div>
      </>
    );
  };
  const [skeleton, setSkeleton] = useState(false);
  const MainStorekeleton = () => {
    return (
      <div className={main.main_stroe_mobile_box_skeleton}>
        <div className={main.store_item_mobile_skeleton}>
          <div className={main.store_item_mobile_inner_skeleton}></div>
          <div className={main.store_item_mobile_cont_skeleton}></div>
        </div>
        <div className={main.store_item_mobile_skeleton}>
          <div className={main.store_item_mobile_inner_skeleton}></div>
          <div className={main.store_item_mobile_cont_skeleton}></div>
        </div>{" "}
        <div className={main.store_item_mobile_skeleton}>
          <div className={main.store_item_mobile_inner_skeleton}></div>
          <div className={main.store_item_mobile_cont_skeleton}></div>
        </div>{" "}
        <div className={main.store_item_mobile_skeleton}>
          <div className={main.store_item_mobile_inner_skeleton}></div>
          <div className={main.store_item_mobile_cont_skeleton}></div>
        </div>{" "}
        <div className={main.store_item_mobile_skeleton}>
          <div className={main.store_item_mobile_inner_skeleton}></div>
          <div className={main.store_item_mobile_cont_skeleton}></div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className={main.main_title_top}>
        <h3 className={main.main_title_h3}>스토어 투자상품</h3>
      </div>
      <div className={main.main_title_div}>
        {" "}
        <span className={main.main_title_sub}>
          투자의 기회를 잡으세요!
        </span>{" "}
        {skeleton1 && (
          <span className={main.main_store_more}>
            {" "}
            <em>{brand[0].brand_text1}</em>
            <em>&nbsp;{brand[0].brand_text2}</em>
            <p className={main.main_svg2_before}>
              <BsQuestionCircle />{" "}
            </p>
            <p className={main.main_svg2_after}>
              <BsQuestionCircleFill />
            </p>{" "}
            <div className={main.main_store_open}>
              <a href={`${brand[0].brand_link}`} target="_blank">
                {brand[0].brand_content}
              </a>
            </div>
            {/* <Link to="/notice">더보기</Link>{" "} */}
          </span>
        )}
      </div>
      <div className={main.main_store_box}>
        {!skeleton && <MainStorekeleton />}
        {skeleton && data.length === 0 && (
          <>
            {" "}
            <div className={main.main_not_data_store}>
              {" "}
              <span className={main.main_not_span_store}>
                <MdError style={{ marginRight: "4px" }} /> 상품이 등록되어 있지
                않습니다.
              </span>
            </div>
          </>
        )}
        {skeleton &&
          data &&
          data.map((data) => {
            return <StoreData data={data} />;
          })}
      </div>{" "}
      {/* <div className={main.main_store_button}>
        <button>
          <span className={main.main_btn_color}>매니저</span>추천 더보기
          <span className={main.main_btn_color}>1</span>/3{" "}
        </button>
      </div> */}
    </>
  );
};
export default MainSection5;
