import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CustomMainTable from "./../CustomMainTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "./../../CommonAxios";
import Swal from "sweetalert2";
import moment from "moment";
import Moment from "react-moment";
import styles from "./../../../styles/admin.module.css";
import { numberToKorean } from "../../../lib/numberToKorean";
const ProductTable = ({ cateogry }) => {
  const navigate = useNavigate();
  const TableColumn = [
    {
      Header: "상품 이미지",
      accessor: "pro_img",
      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            <div
              style={{
                cursor: "pointer",
                width: "50px",
                height: "50px",
                margin: "0 auto",
              }}
              // onClick={() => userDetailInfo(oriNo)}
            >
              {data.value && (
                <img
                  src={"/product/" + oriNo + "/" + data.value}
                  style={{ cursor: "pointer", width: "50px", height: "50px" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "상품명",
      accessor: "pro_title",
      Cell: ({ row }) => <Title1 row={row} />,
    },
    // {
    //   Header: "상품설명",
    //   accessor: "pro_content",
    //   Cell: ({ row }) => <Title row={row} />,
    // },
    {
      Header: "카테고리",
      accessor: "category_name",
      // Cell: ({ cell: { value } }) => <Category value={value} />,
    },

    {
      Header: "목표 금액",
      accessor: "pro_target_amount",
      Cell: ({ cell: { value } }) => (
        <>
          <NumberFormat value={value} />
        </>
      ),
    },
    {
      Header: "모집여부",
      accessor: "pro_end_yn",
      Cell: ({ cell: { value } }) => (
        <>{value == "Y" ? "마감" : value == "N" ? "진행" : "예정"}</>
      ),
      // Cell: ({ cell: { value } }) => <Category value={value} />,
    },
    {
      Header: "등록일자",
      accessor: "pro_date",
      Cell: ({ cell: { value } }) => <Date value={value} />,
    },

    {
      Header: "클릭횟수",
      accessor: "pro_click_cnt",
      Cell: ({ cell: { value } }) => <>{value}번</>,
    },

    // {
    //   Header: "기관명",
    //   accessor: "pro_org_name",
    // },
    // {
    //   Header: "추천상품",
    //   accessor: "pro_recommend",
    //   Cell: ({ cell: { value } }) => (
    //     <>{value == "Y" ? "추천상품" : "미추천상품"}</>
    //   ),
    // },
  ];
  const Title = ({ row }) => {
    return (
      <div style={{ display: "flex", width: "260px", margin: "0 auto" }}>
        <div
          style={{
            height: "40px",
            lineHeight: "40px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.original.pro_content}
        </div>
      </div>
    );
  };
  const Title1 = ({ row }) => {
    return (
      <div style={{ display: "flex", width: "160px", margin: "0 auto" }}>
        <div
          style={{
            height: "40px",
            lineHeight: "40px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.original.pro_title}
        </div>
      </div>
    );
  };
  const Date = ({ value }) => {
    if (value) {
      return <Moment format="YYYY.MM.DD">{value}</Moment>;
    } else {
      return <>기간 미지정</>;
    }
  };

  const Category = ({ value }) => {
    if (value == "farm") {
      return <>스마트팜</>;
    } else if (value == "cup") {
      return <>종이컵</>;
    } else if (value == "property") {
      return <>부동산</>;
    } else if (value == "gallery") {
      return <>갤러리</>;
    } else if (value == "beauty") {
      return <>뷰티</>;
    } else if (value == "design") {
      return <>디자인</>;
    }
  };

  const NumberFormat = ({ value }) => {
    if (value) {
      const numberStr = numberToKorean(value) + "원";

      return numberStr;
    } else {
      return "-";
    }
  };

  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState("all");

  // 체크 박스의 no 데이터값
  const [chkValue, setChkValue] = useState([]);

  const updateValue = (ubt) => {
    setChkValue(ubt);
  };

  useEffect(() => {}, [chkValue]);

  const linkData = (data) => {
    navigate("/admin/products/detail/" + data);
  };

  /// 여기까지

  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_product",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
  }, []);

  useEffect(() => {
    if (arrayList.length > 0) {
      if (options == "all") {
        if (filterWord == null) {
          setSendList(arrayList);
        } else {
          let list = arrayList.filter(
            (a) => a.pro_title.includes(filterWord) == true
          );
          setSendList(list);
        }
      }

      {
        cateogry &&
          cateogry.map((data) => {
            if (options == data.no) {
              const norList = arrayList.filter((a) => a.cno == data.no);
              if (filterWord == null) {
                setSendList(norList);
              } else {
                let list = norList.filter(
                  (a) => a.pro_title.includes(filterWord) == true
                );
                setSendList(list);
              }
            }
          });
      }

      // else if (options == "noraml") {
      //   const norList = arrayList.filter((a) => a.pro_open_yn == "Y");
      //   if (filterWord == null) {
      //     setSendList(norList);
      //   } else {
      //     let list = norList.filter(
      //       (a) => a.pro_title.includes(filterWord) == true
      //     );
      //     setSendList(list);
      //   }
      // } else {
      //   const norList = arrayList.filter((a) => a.pro_open_yn == "N");
      //   if (filterWord == null) {
      //     setSendList(norList);
      //   } else {
      //     let list = norList.filter(
      //       (a) => a.pro_title.includes(filterWord) == true
      //     );
      //     setSendList(list);
      //   }
      // }
    }
  }, [arrayList, filterWord, options]);

  const filterUser = (e) => {
    setFilterWord(e.target.value);
  };

  const chgRecommend = () => {
    let arr = [];

    for (let i = 0; i < chkValue.length; i++) {
      arr.push(chkValue[i]);
    }

    let sendData = {
      no: arr,
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_product_recommend",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          Swal.fire({
            title: "변경 완료",
            icon: "success",
            confirmButtonText: "확인",
          }).then((res) => {
            if (res.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
  };

  const [more, setMore] = useState(false);
  return (
    <>
      <div className={styles.user_search}>
        <span className={styles.search_icon}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <input
          type="text"
          onChange={(e) => filterUser(e)}
          placeholder="찾고자 하는 상품명을 검색하세요."
        />
      </div>
      <div
        className={styles.user_header}
        style={{
          height: "auto",
          overflow: more ? "initial" : "hidden",
          // background: "red",
        }}
      >
        <div
          className={styles.user_option}
          style={{
            width: "780px",
            height: cateogry.length > 5 ? (more ? "auto" : "50px") : "auto",
            // display: "flex",
            // overflow: "scroll",
          }}
        >
          <div
            className={
              options == "all" ? `${styles.all} ${styles.clicked}` : styles.all
            }
            style={{ width: "130px" }}
            onClick={() => setOptions("all")}
          >
            모든 상품 &nbsp; {arrayList.length}
          </div>
          {cateogry &&
            cateogry.map((data) => {
              return (
                <div
                  className={
                    options == data.no
                      ? `${styles.noraml} ${styles.clicked}`
                      : styles.noraml
                  }
                  style={{ width: "130px" }}
                  onClick={() => setOptions(data.no)}
                >
                  {data.category_name} &nbsp;{" "}
                  {arrayList.filter((a) => a.cno == data.no).length}
                </div>
              );
            })}
          {/* <div
            className={
              options == "noraml"
                ? `${styles.noraml} ${styles.clicked}`
                : styles.noraml
            }
            onClick={() => setOptions("noraml")}
          >
            공개 &nbsp; {arrayList.filter((a) => a.pro_open_yn == "Y").length}
          </div>
          <div
            className={
              options == "noram2"
                ? `${styles.noraml} ${styles.clicked}`
                : styles.noraml
            }
            onClick={() => setOptions("noram2")}
          >
            비공개 &nbsp; {arrayList.filter((a) => a.pro_open_yn == "N").length}
          </div> */}
        </div>
        {/* <div className={styles.header_btns}>
          <button onClick={() => chgRecommend()}>추천상품으로 변경</button>
          <button onClick={() => chgRecommend()}>미추천상품으로 변경</button>
        </div> */}
        {cateogry.length > 5 && !more && (
          <span
            style={{ height: "50px", lineHeight: "50px", cursor: "pointer" }}
            onClick={() => {
              setMore(true);
            }}
          >
            카테고리 펼치기
          </span>
        )}
        {cateogry.length > 5 && more && (
          <span
            style={{ height: "50px", lineHeight: "50px", cursor: "pointer" }}
            onClick={() => {
              setMore(false);
            }}
          >
            {" "}
            카테고리 접기
          </span>
        )}
      </div>
      <CustomMainTable
        columns={TableColumn}
        data={sendList}
        chkValue={chkValue}
        setChkValue={updateValue}
        linkData={linkData}
        setArrayList={setArrayList}
        setSendList={setSendList}
        setOptions={setOptions}
      />
    </>
  );
};

export default ProductTable;
