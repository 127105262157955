import React, { useCallback, useState, useEffect, useRef } from "react";
import { CommonAxios } from "./../../CommonAxios";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import Moment from "react-moment";
import styles from "./../../../styles/admin.module.css";
import { useParams } from "react-router-dom";
import { textReplace } from "../../../lib/textReplace";
import { FcEditImage } from "react-icons/fc";
import { textOverCut, getTextTotal } from "../../../lib/stringLenghts";
import { wonComma } from "../../../lib/wonComma";
const ProductInfo = () => {
  const location = useLocation();

  const [arrayList, setArrayList] = useState([]);
  const [showList, setShowList] = useState(false);

  const [regDate, setRegDate] = useState("");
  const [upDate, setUpDate] = useState("");
  const [recommend, setRecommend] = useState("");
  const [updateImg, setUpdateImg] = useState("");
  const { id } = useParams();
  useEffect(() => {
    // let adrs = location.pathname.split("/")[4];

    let sendData = { no: id };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_product_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          // setArrayList(result.messageinfo.message);
          setTitle(result.messageinfo.message[0].pro_title);

          let byte = getTextTotal(result.messageinfo.message[0].pro_title, 20);
          setTitleByte(byte.byte);
          setCom(result.messageinfo.message[0].pro_company);
          let byte2 = getTextTotal(
            result.messageinfo.message[0].pro_company,
            40
          );
          setComByte(byte2.byte);

          let byte1 = getTextTotal(
            result.messageinfo.message[0].pro_content,
            40
          );
          setContentByte(byte1.byte);

          setContent(result.messageinfo.message[0].pro_content);
          setAmount(wonComma(result.messageinfo.message[0].pro_target_amount));
          // setAgency(result.messageinfo.message[0].pro_org_name);
          setRateOne(result.messageinfo.message[0].pro_rate);
          setRate(result.messageinfo.message[0].pro_rate_change);
          setJoinDate(result.messageinfo.message[0].pro_join);
          setLinkValue(result.messageinfo.message[0].pro_link);
          setApplyValue(result.messageinfo.message[0].pro_link_yn);
          setOpenValue(result.messageinfo.message[0].pro_open_yn);

          setDateValue(
            moment(result.messageinfo.message[0].pro_end_date).format(
              "YYYY-MM-DD 23:59:59"
            )
          );
          setRecru(result.messageinfo.message[0].pro_end_yn);
          setRegDate(result.messageinfo.message[0].pro_date);
          setUpDate(result.messageinfo.message[0].pro_update_date);
          setCateValue(result.messageinfo.message[0].pro_category);
          if (result.messageinfo.message[0].pro_img) {
            setUpdateImg(
              `/product/${id}/${result.messageinfo.message[0].pro_img}`
            );
          }
          setCategoryData(result.messageinfo.message_data2);
          if (result.messageinfo.message_data2.length > 0) {
            setCateValue(result.messageinfo.message[0].pro_category);
          }
          setShowList(true);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
  }, []);

  // useEffect(() => {
  //   if (arrayList.length > 0) {
  //     setShowList(arrayList);
  //   }
  // }, [arrayList]);

  const Date = ({ value }) => {
    if (value) {
      return <Moment format="YY.MM.DD HH:mm">{value}</Moment>;
    } else {
      return <>-</>;
    }
  };

  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  const imgNameRef = useRef(); //이미지 파일 이름 REF
  const [imageFile, setImageFile] = useState(""); //이미지 속성정보
  const [getimage, setGetImage] = useState(""); //render.result 값
  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 1024 * 1;

    if (file[0] === undefined) {
      return;
    }

    // if (
    //   imgExtension[1] !== "jpg" ||
    //   imgExtension[1] !== "png" ||
    //   imgExtension[1] !== "jpeg" ||
    //   imgExtension[1] !== "gif"
    // ) {
    //   Swal.fire({
    //     title: "이미지 업로드 불가",
    //     text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",
    //     icon: "error",
    //     confirmButtonText: "확인",
    //     confirmButtonColor: "#FF0000",
    //   });
    //   return;
    // }
    if (file[0].size >= maxSize) {
      //이미지 사이즈가 500k보다 크다면,
      setImageFile(imageFile);
      Swal.fire({
        title: "이미지 용량 초과",
        html: `1MB 사진을 올려주세요.`,

        confirmButtonText: "확인",
      });
      return;
    } else {
      setImageFile(file);
    }
  };

  useEffect(() => {
    preview(); // 미리보기
  });

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      imageRef.current.src = reader.result;
      setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };

  const [title, setTitle] = useState("");
  const [titleByte, setTitleByte] = useState(0);

  const titleChange = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 20);
    let text = textOverCut(e.target.value, 20);

    setTitle(text);
    setTitleByte(byte.byte);
  }, []);
  const [com, setCom] = useState("");
  const [comByte, setComByte] = useState(0);

  const comChange = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 40);
    let text = textOverCut(e.target.value, 40);

    setCom(text);
    setComByte(byte.byte);
  }, []);

  const [content, setContent] = useState("");
  const [contentByte, setContentByte] = useState(0);
  const contentChange = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 40);
    let text = textOverCut(e.target.value, 40);

    setContent(text);
    setContentByte(byte.byte);
  }, []);

  const [amount, setAmount] = useState(0);

  const amountChange = useCallback((e) => {
    setAmount(wonComma(e.target.value.replace(/[^\d]+/g, "")));
  }, []);

  const [linkValue, setLinkValue] = useState("");

  const linkChange = useCallback((e) => {
    setLinkValue(e.target.value);
  }, []);

  const [rate, setRate] = useState(0);
  const [rateOne, setRateOne] = useState(0);
  const rateChange = useCallback((e) => {
    setRate(e.target.value);
  }, []);

  const [joinDate, setJoinDate] = useState(0);
  const joinChange = useCallback((e) => {
    setJoinDate(e.target.value);
  }, []);

  const [cateValue, setCateValue] = useState("스마트팜");
  const change_cate = useCallback((e) => {
    setCateValue(e.target.value);
  }, []);

  const [dateValue, setDateValue] = useState("");
  const changeDate = useCallback((e) => {
    setDateValue(e.target.value);
  }, []);

  // 변경 함수들
  const btn_change_title = (data) => {
    updateAxios("pro_title", textReplace(data), function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };
  const btn_change_com = (data) => {
    updateAxios("pro_company", textReplace(data), function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };
  const btn_change_content = (data) => {
    updateAxios("pro_content", textReplace(data), function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
      }
    });
  };

  const btn_change_amount = (data) => {
    updateAxios(
      "pro_target_amount",
      data.replace(/[^\d]+/g, ""),
      function (result) {
        if (result === "ok") {
          return Swal.fire({
            title: "변경 완료",

            confirmButtonText: "확인",
          });
          // setSaveDate(new Date());
        }
      }
    );
  };

  const btn_change_date = (data) => {
    if (
      !RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/).test(
        dateValue
      )
    ) {
      return Swal.fire({
        // title: "상품 변경 실패",
        html: `YYYY-MM-DD 형식으로 적어주세요.`,

        confirmButtonText: "확인",
      });
    }
    updateAxios(
      "pro_end_date",
      moment(data).format("YYYY-MM-DD 23:59:59"),
      function (result) {
        if (result === "ok") {
          return Swal.fire({
            title: "변경 완료",

            confirmButtonText: "확인",
          });
          // setSaveDate(new Date());
        }
      }
    );
  };

  const btn_change_link = (data) => {
    updateAxios("pro_link", data, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };

  const btn_change_category = (data) => {
    updateAxios("pro_category", data, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };

  const btn_change_rate = (data) => {
    Swal.fire({
      title: "주의 사항",
      html: `수익률 변경시 당일 기준으로 다음날 이자 적용이 진행됩니다.<br/>
      신중하게 생각 후 진행바랍니다. 변경하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        let sendData = {
          no: id,
          rate: rate,

          // updateDate: NowDateVer2(),
        };
        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/admin_update_product_rate",
          sendData,
          function (result) {
            return Swal.fire({
              title: "변경 완료",

              confirmButtonText: "확인",
            });
            // sendResult(result.messageinfo.state);
          }
        );
      }
    });
    // updateAxios("pro_return_rate", data, function (result) {
    //   if (result === "ok") {
    //     // setSaveDate(new Date());
    //   }
    // });
  };

  const btn_change_open = (data) => {
    updateAxios("pro_open_yn", data, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };
  const btn_change_join = (data) => {
    updateAxios("pro_join", data, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };

  const btn_change_recruitment = (data) => {
    updateAxios("pro_end_yn", data, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };
  const [applyValue, setApplyValue] = useState("N");
  const change_apply = (data) => {
    if (data === "N") {
      setLinkValue("");
    }
    setApplyValue(data);
  };

  const [openValue, setOpenValue] = useState("N");
  const change_open = (data) => {
    setOpenValue(data);
  };
  const [recru, setRecru] = useState("C");
  const change_recruitment = (data) => {
    setRecru(data);
  };

  const updateAxios = (sendKey, sendValue, sendResult) => {
    let sendData = {
      no: id,
      field: sendKey,
      value: sendValue,
      // updateDate: NowDateVer2(),
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_product",
      sendData,
      function (result) {
        sendResult(result.messageinfo.state);
      }
    );
  };

  const btn_change_img = () => {
    let sendData = {
      no: id,
      beforeImg: updateImg.split("/")[updateImg.split("/").length - 1],
      filename: "",
      image: "",
      exp: "",
      // updateDate: NowDateVer2(),
    };
    if (imageFile !== "") {
      var file_info = imageFile[0].name.split(".");
      var file_name = `${Math.floor(Math.random() * 100000)}.${file_info[1]}`;
      sendData.filename = file_name;
      sendData.exp = file_info[1];
      sendData.image = `${getimage}file_name:${file_name}file_name:file_name:/userProfile/`;
    }

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_product_img",
      sendData,
      function (result) {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // sendResult(result.messageinfo.state);
      }
    );
  };

  const [categoryData, setCategoryData] = useState([]);
  // useEffect(() => {
  //   let sendData = {};
  //   CommonAxios(
  //     process.env.REACT_APP_HOSTDONAME + "/api/admin_select_product_category",
  //     sendData,
  //     function (result) {
  //       if (result.messageinfo.state == "ok") {
  //         setCategoryData(result.messageinfo.message);
  //       }
  //     }
  //   );
  // }, []);

  return (
    <>
      {showList && (
        <div className={styles.wrap_content_flexbox_body}>
          <div className={styles.product_info_box}>
            <div className={styles.title_box}>
              <div className={styles.title_name}>상품정보</div>
            </div>
            <div className={styles.contents_box}>
              <div className={` ${styles.box_img}`}>
                <div className={styles.img_div}>
                  {updateImg === "" ? (
                    !imageFile ? (
                      <div>
                        <FcEditImage />
                      </div>
                    ) : (
                      <img src="" ref={imageRef} />
                    )
                  ) : (
                    <img src={updateImg} ref={imageRef} />
                  )}
                </div>
                <div className={styles.img_button}>
                  <label className={styles.product_file}>
                    <input
                      type="file"
                      className={styles.product_file_inp}
                      accept="image/*"
                      onChange={inpChange}
                    />
                    <span>이미지 업로드</span>
                  </label>
                  <button onClick={btn_change_img}>변경하기</button>
                </div>
              </div>
              <div className={styles.box_list}>
                <div>
                  {/* <span className={styles.box_list_title}>등록/수정</span> */}
                  <div className={styles.box_list_inputbox}>
                    등록 : <Date value={regDate} />
                    /수정 : <Date value={upDate} />
                  </div>
                </div>
              </div>{" "}
              <div className={styles.product_info_inner}>
                {" "}
                <div className={styles.product_left}>
                  <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>상품명</span>
                      <div className={styles.box_list_inputbox}>
                        <input
                          type="text"
                          value={title}
                          onChange={titleChange}
                        />{" "}
                        <span>{titleByte}/20</span>
                      </div>
                      <button onClick={() => btn_change_title(title)}>
                        변경
                      </button>
                    </div>
                  </div>
                  {/* <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>상품설명</span>
                      <div className={styles.box_list_inputbox}>
                        <input
                          type="text"
                          value={content}
                          onChange={contentChange}
                        />{" "}
                        <span>{contentByte}/40</span>
                      </div>
                      <button onClick={() => btn_change_content(content)}>
                        변경
                      </button>
                    </div>
                  </div> */}
                  <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>목표금액</span>
                      <div className={styles.box_list_inputbox}>
                        <input
                          type="text"
                          value={amount}
                          onChange={amountChange}
                        />
                      </div>
                      <button onClick={() => btn_change_amount(amount)}>
                        변경
                      </button>
                    </div>
                  </div>{" "}
                  <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>
                        투자종료날짜
                      </span>
                      <div className={styles.box_list_inputbox}>
                        <input
                          type="text"
                          placeholder="0000-00-00 형식으로 작성해주세요."
                          onChange={changeDate}
                          value={dateValue}
                        />
                      </div>
                      <button onClick={() => btn_change_date(dateValue)}>
                        변경
                      </button>
                    </div>
                  </div>
                  <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>외부링크</span>
                      <div className={`${styles.box_list_inputbox}`}>
                        <input
                          type="text"
                          value={linkValue}
                          onChange={linkChange}
                          disabled={applyValue === "Y" ? false : true}
                        />
                      </div>
                      <button onClick={() => btn_change_link(linkValue)}>
                        변경
                      </button>
                    </div>{" "}
                    <div>
                      <span className={styles.box_list_title}>적용유무</span>
                      <div
                        className={`${styles.box_list_inputbox} ${styles.box_list_etc}`}
                      >
                        <label>
                          <input
                            type="radio"
                            name="apply"
                            value="Y"
                            onChange={() => change_apply("Y")}
                            checked={applyValue === "Y" ? true : false}
                          />{" "}
                          적용
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="apply"
                            value="N"
                            onChange={() => change_apply("N")}
                            checked={applyValue === "N" ? true : false}
                          />{" "}
                          미적용
                        </label>{" "}
                      </div>
                    </div>
                  </div>{" "}
                  {/* <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>회사명</span>
                      <div className={styles.box_list_inputbox}>
                        <input type="text" value={com} onChange={comChange} />{" "}
                        <span>{comByte}/40</span>
                      </div>
                      <button onClick={() => btn_change_com(com)}>변경</button>
                    </div>
                  </div> */}
                </div>
                <div className={styles.product_left}>
                  <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>카테고리</span>
                      <div className={`${styles.box_list_inputbox}`}>
                        <select onChange={change_cate} value={cateValue}>
                          {categoryData &&
                            categoryData.map((data) => {
                              return (
                                <option value={data.no}>
                                  {data.category_name}
                                </option>
                              );
                            })}
                        </select>{" "}
                      </div>
                      <button onClick={() => btn_change_category(cateValue)}>
                        변경
                      </button>
                    </div>
                  </div>{" "}
                  <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>
                        적용된 수익률
                      </span>
                      <div
                        className={`${styles.box_list_inputbox} ${styles.box_list_etc}`}
                        style={{ color: "#00c73c", fontWeight: "700" }}
                      >
                        {/* <input
                          type="text"
                          value={rate}
                          // onChange={rateChange}
                          style={{ textAlign: "right", fontWeight: "700" }}
                          disabled
                        />{" "} */}
                        <span style={{ width: "100%", fontSize: "16px" }}>
                          {rateOne} %
                        </span>
                      </div>
                      {/* <button onClick={() => btn_change_rate(rate)}>
                        변경
                      </button> */}
                    </div>
                  </div>
                  <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>
                        변경할 수익률
                      </span>
                      <div className={styles.box_list_inputbox}>
                        <input
                          type="text"
                          value={rate}
                          onChange={rateChange}
                          style={{ textAlign: "right" }}
                        />{" "}
                        <span>%</span>
                      </div>
                      <button onClick={() => btn_change_rate(rate)}>
                        변경
                      </button>
                    </div>
                  </div>
                  <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>가입기간</span>
                      <div className={styles.box_list_inputbox}>
                        <input
                          type="text"
                          value={joinDate}
                          onChange={joinChange}
                          style={{ textAlign: "right" }}
                        />
                        <span>개월</span>
                      </div>
                      <button onClick={() => btn_change_join(joinDate)}>
                        변경
                      </button>
                    </div>
                  </div>{" "}
                  {/* <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>공개 여부</span>
                      <div
                        className={`${styles.box_list_inputbox} ${styles.box_list_etc}`}
                      >
                        <label>
                          <input
                            type="radio"
                            name="open"
                            value="Y"
                            onChange={() => change_open("Y")}
                            checked={openValue === "Y" ? true : false}
                          />{" "}
                          공개
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="open"
                            value="N"
                            onChange={() => change_open("N")}
                            checked={openValue === "N" ? true : false}
                          />{" "}
                          비공개
                        </label>
                      </div>
                      <button onClick={() => btn_change_open(openValue)}>
                        변경
                      </button>
                    </div>
                  </div>{" "} */}
                  <div className={styles.box_list}>
                    <div>
                      <span className={styles.box_list_title}>모집 여부</span>
                      <div
                        className={`${styles.box_list_inputbox} ${styles.box_list_etc}`}
                      >
                        <label>
                          <input
                            type="radio"
                            name="make"
                            value="C"
                            onChange={() => change_recruitment("C")}
                            checked={recru === "C" ? true : false}
                          />{" "}
                          예정
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="make"
                            value="N"
                            onChange={() => change_recruitment("N")}
                            checked={recru === "N" ? true : false}
                          />{" "}
                          진행
                        </label>{" "}
                        <label>
                          <input
                            type="radio"
                            name="make"
                            value="Y"
                            onChange={() => change_recruitment("Y")}
                            checked={recru === "Y" ? true : false}
                          />{" "}
                          마감
                        </label>
                      </div>
                      <button onClick={() => btn_change_recruitment(recru)}>
                        변경
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={styles.product_present_box}>
            <div className={styles.title_box}>
              <div className={styles.title_name}>상품현황</div>
            </div>
            <div className={styles.contents_box}>
              <div className={styles.box_list}>
                <div>
                  <span className={styles.box_list_title}>총 투자금액</span>
                  <div className={styles.box_list_inputbox}>
                  </div>
                </div>
              </div>
              <div className={styles.box_list}>
                <div>
                  <span className={styles.box_list_title}>투자인원</span>
                  <div className={styles.box_list_inputbox}>
                  </div>
                </div>
              </div>
              <div className={styles.box_list}>
                <div>
                  <span className={styles.box_list_title}>투자인원</span>
                  <div className={styles.box_list_inputbox}>
                  </div>
                </div>
              </div>
              <div className={styles.box_list}>
                <div>
                  <span className={styles.box_list_title}>정산일</span>
                  <div className={styles.box_list_inputbox}>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductInfo;
