import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import CustomTable from "./CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "../CommonAxios";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/admin.module.css";
import { Link, useNavigate } from "react-router-dom";
import InvestCustomTable from "./table/InvestCustomTable";
import { wonComma } from "../../lib/wonComma";
import { numberToKorean } from "../../lib/numberToKorean";
import { textReplace } from "../../lib/textReplace";
import axios from "axios";
const PolicyInfo = () => {
  const navigate = useNavigate();

  // {
  //   Header: "구분",
  //   accessor: "notice_type",

  //   Cell: ({ cell: { value } }) => (
  //     <>{value == 1 ? "공지사항" : "보도자료"}</>
  //   ),
  // },
  // {
  //   Header: "공개여부",
  //   accessor: "notice_open",
  //   Cell: ({ cell: { value } }) => <>{value == "Y" ? "공개" : "비공개"}</>,
  // },

  // {
  //   Header: "답변여부",
  //   accessor: "qna_answer_yn",
  //   Cell: ({ cell: { value } }) => (
  //     <>{value === "Y" ? "답변완료" : "답변미완료"}</>
  //   ),
  // },
  // {
  //   Header: "",
  //   accessor: "qna_date",
  //   Cell: ({ cell: { value } }) => <Date value={value} />,

  const [arrayList, setArrayList] = useState([]);
  const [cate, setCate] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState(1);
  const [options2, setOptions2] = useState(1);
  const [banSw, setBanSw] = useState(false);

  const [a1, setA1] = useState("");
  const [a2, setA2] = useState("");
  const [a3, setA3] = useState("");
  const [a4, setA4] = useState("");
  const [a5, setA5] = useState("");
  const [a6, setA6] = useState("");
  const [a7, setA7] = useState("");
  const [a8, setA8] = useState("");
  const [a9, setA9] = useState("");
  const [a10, setA10] = useState("");
  const [a11, setA11] = useState("");
  const [a12, setA12] = useState("");

  const [left1, setLeft1] = useState("");
  const [right1, setRight1] = useState("");
  const [left2, setLeft2] = useState("");
  const [right2, setRight2] = useState("");
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");
  const [one, setOne] = useState("");
  const [two, setTwo] = useState("");
  const [three, setThree] = useState("");
  const [four, setFour] = useState("");
  useEffect(() => {
    setBanSw(false);
    let sendData = {
      type: options,
      no: options2,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_policy",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          // setArrayList(result.messageinfo.message);
          if (result.messageinfo.message.length > 0) {
            setArrayList(result.messageinfo.message);
            setLeft1(result.messageinfo.message[0].policy_title);
            setLeft2(result.messageinfo.message[1].policy_title);
            setOne(result.messageinfo.message[2].policy_title);
            setTwo(result.messageinfo.message[3].policy_title);

            setRight1(result.messageinfo.message[0].policy_pdf);
            setRight2(result.messageinfo.message[1].policy_pdf);
            setThree(result.messageinfo.message[2].policy_pdf);
            setFour(result.messageinfo.message[3].policy_pdf);

            setImageFileNBefore(result.messageinfo.message[0].policy_pdf);
            setImageFileN2Before(result.messageinfo.message[1].policy_pdf);
            setImageFileN3Before(result.messageinfo.message[2].policy_pdf);
            setImageFileN4Before(result.messageinfo.message[3].policy_pdf);
            // setLink1(result.messageinfo.message[0].brand_link);
            // setLink2(result.messageinfo.message[1].brand_link);
            // setContent1(result.messageinfo.message[0].brand_content);
            // setContent2(result.messageinfo.message[1].brand_content);
          }

          setBanSw(true);
        } else {
          setBanSw(true);
        }
      }
    );
  }, [options, options2]);

  const [cateNo, setCateNo] = useState("");
  const [arr, setArr] = useState([]);
  const changeData = (e) => {
    setCateNo(e.target.value);
  };
  useEffect(() => {
    if (cateNo) {
      setBanSw(false);
      let sendData = {
        // type: options,
        no: cateNo,
      };
      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/admin_select_product_pdf_cate",
        sendData,
        function (result) {
          if (result.messageinfo.state == "ok") {
            if (result.messageinfo.message.length > 0) {
              setArr(result.messageinfo.message);
              setContent1(result.messageinfo.message[0].details_pdf_title);
              setContent2(result.messageinfo.message[0].details_pdf);
              setImageFileN3Before(result.messageinfo.message[0].details_pdf);
            } else {
              setArr([]);
              setContent1("");
              setContent2("");
              setImageFileN3Before("");
            }
            setBanSw(true);
          } else {
            setBanSw(true);
          }
        }
      );
    }
  }, [cateNo]);

  const changeCancel1 = () => {
    setLeft1(arrayList[0].brand_text1);

    setRight1(arrayList[0].brand_text2);

    setLink1(arrayList[0].brand_link);

    setContent1(arrayList[0].brand_content);

    setChange1(false);
  };
  const changeCancel2 = () => {
    setLeft2(arrayList[1].brand_text1);

    setRight2(arrayList[1].brand_text2);

    setLink2(arrayList[1].brand_link);

    setContent2(arrayList[1].brand_content);
    setChange2(false);
  };
  const axiosFun = () => {
    let sendData = {
      brand_text1: textReplace(left1),
      brand_text2: textReplace(right1),
      brand_content: textReplace(content1),
      brand_link: textReplace(link1),
      no: 1,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME +
        "/api/admin_update_product_notice_text",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          Swal.fire({
            title: "변경 완료",

            confirmButtonText: "확인",
          });
          setChange1(false);
          // setArrayList(result.messageinfo.message);
        }
      }
    );
  };
  const axiosFun1 = (data, no) => {
    let sendData = {
      brand_text1: textReplace(left2),
      brand_text2: textReplace(right2),
      brand_content: textReplace(content2),
      brand_link: textReplace(link2),
      no: 2,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_brand_text",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          Swal.fire({
            title: "변경 완료",

            confirmButtonText: "확인",
          });
          setChange2(false);
          // setArrayList(result.messageinfo.message);
        }
      }
    );
  };
  const change_left1 = useCallback((e) => {
    setLeft1(e.target.value);
  }, []);
  const change_right1 = useCallback((e) => {
    setRight1(e.target.value);
  }, []);
  const change_content1 = useCallback((e) => {
    setOne(e.target.value);
  }, []);
  const change_content2 = useCallback((e) => {
    setTwo(e.target.value);
  }, []);
  const change_link1 = useCallback((e) => {
    setLink1(e.target.value);
  }, []);
  const change_left2 = useCallback((e) => {
    setLeft2(e.target.value);
  }, []);
  const change_right2 = useCallback((e) => {
    setRight2(e.target.value);
  }, []);

  const change_link2 = useCallback((e) => {
    setLink2(e.target.value);
  }, []);

  const [change1, setChange1] = useState(false);
  const [change2, setChange2] = useState(false);
  const [change3, setChange3] = useState(false);
  const [change4, setChange4] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const [imageFile3, setImageFile3] = useState("");
  const [imageFile4, setImageFile4] = useState("");

  const [imageFileN, setImageFileN] = useState("");
  const [imageFileN2, setImageFileN2] = useState("");
  const [imageFileN3, setImageFileN3] = useState("");
  const [imageFileN4, setImageFileN4] = useState("");

  const [imageFileNBefore, setImageFileNBefore] = useState("");
  const [imageFileN2Before, setImageFileN2Before] = useState("");
  const [imageFileN3Before, setImageFileN3Before] = useState("");
  const [imageFileN4Before, setImageFileN4Before] = useState("");

  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "pdf") {
      Swal.fire({
        title: "업로드 불가",
        text: "pdf 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }
    setRight1(file[0].name);
    setImageFileN(file[0].name);
    setImageFile(file);
  };
  const inpChange2 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "pdf") {
      Swal.fire({
        title: "업로드 불가",
        text: "pdf 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }
    setRight2(file[0].name);
    setImageFileN2(file[0].name);
    setImageFile2(file);
  };
  const inpChange3 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "pdf") {
      Swal.fire({
        title: "업로드 불가",
        text: "pdf 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }
    setContent2(file[0].name);
    setImageFileN3(file[0].name);
    setImageFile3(file);
  };
  const inpChange4 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "pdf") {
      Swal.fire({
        title: "업로드 불가",
        text: "pdf 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }
    setContent2(file[0].name);
    setImageFileN3(file[0].name);
    setImageFile3(file);
  };
  const btn_change = (no, value, type) => {
    let sendData = {
      policy_title: textReplace(value),
      type: type,
      no: no,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_policy_text",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          Swal.fire({
            title: "변경 완료",

            confirmButtonText: "확인",
          });
          setChange1(false);
          // setArrayList(result.messageinfo.message);
        }
      }
    );
  };

  const btn_change_name = () => {
    updateAxios(1, imageFileN, imageFileNBefore, imageFile, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };

  const btn_change_name1 = () => {
    updateAxios(
      2,
      imageFileN2,
      imageFileN2Before,
      imageFile2,
      function (result) {
        if (result === "ok") {
          return Swal.fire({
            title: "변경 완료",

            confirmButtonText: "확인",
          });
          // setSaveDate(new Date());
        }
      }
    );
  };
  const btn_change_name2 = () => {
    updateAxios(
      3,
      imageFileN3,
      imageFileN3Before,
      imageFile3,
      function (result) {
        if (result === "ok") {
          return Swal.fire({
            title: "변경 완료",

            confirmButtonText: "확인",
          });
          // setSaveDate(new Date());
        }
      }
    );
  };
  const btn_change_name3 = () => {
    updateAxios(
      4,
      imageFileN4,
      imageFileN4Before,
      imageFile4,
      function (result) {
        if (result === "ok") {
          return Swal.fire({
            title: "변경 완료",

            confirmButtonText: "확인",
          });
          // setSaveDate(new Date());
        }
      }
    );
  };
  const updateAxios = (no, fileAftor, fileBefore, file, sendResult) => {
    // let sendData = {
    //   no: no,
    //   fileAfter: textReplace(fileAftor),
    //   fileBefore: textReplace(fileBefore),
    //   file: file,
    //   // updateDate: NowDateVer2(),
    // };

    let arr = { no: no, filename: fileAftor, fileBefore: fileBefore };
    const formData = new FormData();
    formData.append("no", no);
    formData.append("filename", fileAftor);
    formData.append("fileif", "");
    formData.append("fileBefore", fileBefore);
    formData.append("folder", "policy");
    formData.append("file", file[0]);

    axios
      .post(
        process.env.REACT_APP_HOSTDONAME + "/api/admin_upload_policy",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.messageinfo.state === "ok") {
          sendResult(response.data.messageinfo.state);
          // setUploadImage(
          //   uploadImage.concat({
          //     id: uploadImageRef.current++,
          //     imageUrl: `/classImage/${classinfo}/${chapterNum}/${path}/${fileNum}`,
          //     imagename: fileNum,
          //   })
          // );
          // setImageText(imageText + fileNum + ",");
          // // 응답 처리
          // setSpinnerView(false);
        }
      })
      .catch((error) => {
        // 예외 처리
      });

    // CommonAxios(
    //   process.env.REACT_APP_HOSTDONAME + "/api/admin_update_policy",
    //   sendData,
    //   function (result) {
    //     if (result.messageinfo.state === "ok") {
    //     } else {
    //     }

    //     sendResult(result.messageinfo.state);
    //   }
    // );
  };

  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          회사정보 &nbsp;〉 &nbsp;정책 및 약관{" "}
        </div>
        {arrayList.length > 0 && (
          <div
            className={`${styles.wrap_content_box_body} ${styles.ppdf_flex}`}
          >
            <div className={`${styles.info_write_wrap} ${styles.ppdf_wrap}`}>
              <div className={`${styles.info_write_inner}`}>
                <div className={styles.info_write_content}>
                  <ol>
                    {!change1 && (
                      <li className={`${styles.ppdf_li}`}>
                        <p>회원가입약관</p>
                        <div>
                          <div>
                            <ul>
                              <li>
                                <label>이름</label>
                                <span>
                                  {/* {arrayList[0].policy_title}{" "} */}
                                  <input
                                    type="text"
                                    value={left1}
                                    onChange={change_left1}
                                  />
                                  <button
                                    onClick={() => btn_change(1, left1, 1)}
                                  >
                                    변경
                                  </button>
                                </span>
                              </li>
                              <li>
                                <label>파일</label>
                                <div className={styles.pdf_span}>
                                  <span>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={inpChange}
                                      id="abc"
                                    />
                                    {/* {arrayList[0].policy_pdf} */}
                                    <input
                                      type="text"
                                      value={right1}
                                      // onChange={change_content1}
                                    />{" "}
                                    <label for="abc">업로드</label>{" "}
                                  </span>
                                  <button onClick={() => btn_change_name()}>
                                    변경
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>{" "}
                          {/* <button onClick={() => setChange1(true)}>수정</button> */}
                        </div>
                      </li>
                    )}{" "}
                  </ol>
                </div>
              </div>
            </div>{" "}
            <div className={`${styles.info_write_wrap} ${styles.ppdf_wrap}`}>
              <div className={`${styles.info_write_inner}`}>
                <div className={styles.info_write_content}>
                  <ol>
                    {" "}
                    {change2 && (
                      <li className={`${styles.ppdf_li2}`}>
                        {" "}
                        <div>
                          <ul>
                            <li>
                              <label>이름</label>
                              <span>
                                {" "}
                                <input
                                  type="text"
                                  value={left2}
                                  onChange={change_left2}
                                />
                              </span>
                              <span>
                                {" "}
                                <input
                                  type="text"
                                  value={right2}
                                  onChange={change_right2}
                                />
                              </span>
                            </li>
                            <li>
                              {" "}
                              <label>설명글</label>
                              <input
                                type="text"
                                value={content2}
                                onChange={change_content2}
                              />
                            </li>
                            <li>
                              {" "}
                              <label>링크</label>
                              <input
                                type="text"
                                value={link2}
                                onChange={change_link2}
                              />
                            </li>
                          </ul>
                        </div>{" "}
                        <div>
                          <button onClick={() => axiosFun1()}>변경</button>{" "}
                          <button onClick={() => changeCancel2()}>취소</button>
                        </div>
                      </li>
                    )}{" "}
                    {!change2 && (
                      <li className={`${styles.ppdf_li}`}>
                        <p>서포터 이용약관</p>
                        <div>
                          <div>
                            <ul>
                              <li>
                                <label>이름</label>
                                <span>
                                  {/* {arrayList[0].policy_title}{" "} */}
                                  <input
                                    type="text"
                                    value={left2}
                                    onChange={change_left2}
                                  />
                                  <button
                                    onClick={() => btn_change(2, left2, 1)}
                                  >
                                    변경
                                  </button>
                                </span>
                              </li>
                              <li>
                                <label>파일</label>
                                <div className={styles.pdf_span}>
                                  <span>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={inpChange2}
                                      id="abc2"
                                    />
                                    {/* {arrayList[0].policy_pdf} */}
                                    <input
                                      type="text"
                                      value={right2}
                                      // onChange={change_content1}
                                    />{" "}
                                    <label for="abc2">업로드</label>{" "}
                                  </span>
                                  <button onClick={() => btn_change_name1()}>
                                    변경
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>{" "}
                          {/* <button onClick={() => setChange1(true)}>수정</button> */}
                        </div>
                      </li>
                    )}
                  </ol>
                </div>
              </div>
            </div>{" "}
            <div className={`${styles.info_write_wrap} ${styles.ppdf_wrap}`}>
              <div className={`${styles.info_write_inner}`}>
                <div className={styles.info_write_content}>
                  <ol>
                    {" "}
                    {!change3 && (
                      <li className={`${styles.ppdf_li}`}>
                        <p>공통정책약관</p>

                        <div>
                          <div>
                            <ul>
                              <li>
                                <label>이름</label>
                                <span>
                                  {/* {arrayList[0].policy_title}{" "} */}
                                  <input
                                    type="text"
                                    value={one}
                                    onChange={change_content1}
                                  />
                                  <button onClick={() => btn_change(3, one, 1)}>
                                    변경
                                  </button>
                                </span>
                              </li>
                              <li>
                                <label>파일</label>
                                <div className={styles.pdf_span}>
                                  <span>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={inpChange3}
                                      id="abc3"
                                    />
                                    {/* {arrayList[0].policy_pdf} */}
                                    <input
                                      type="text"
                                      value={three}
                                      // onChange={change_content1}
                                    />{" "}
                                    <label for="abc3">업로드</label>{" "}
                                  </span>
                                  <button onClick={() => btn_change_name2()}>
                                    변경
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>{" "}
                          {/* <button onClick={() => setChange1(true)}>수정</button> */}
                        </div>
                      </li>
                    )}
                  </ol>
                </div>
              </div>
            </div>
            <div className={`${styles.info_write_wrap} ${styles.ppdf_wrap}`}>
              <div className={`${styles.info_write_inner}`}>
                <div className={styles.info_write_content}>
                  <ol>
                    {" "}
                    {!change4 && (
                      <li className={`${styles.ppdf_li}`}>
                        <p>개인정보처리방침</p>

                        <div>
                          <div>
                            <ul>
                              <li>
                                <label>이름</label>
                                <span>
                                  {/* {arrayList[0].policy_title}{" "} */}
                                  <input
                                    type="text"
                                    value={two}
                                    onChange={change_content2}
                                  />
                                  <button onClick={() => btn_change(4, two, 1)}>
                                    변경
                                  </button>
                                </span>
                              </li>
                              <li>
                                <label>파일</label>
                                <div className={styles.pdf_span}>
                                  <span>
                                    <input
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={inpChange4}
                                      id="abc3"
                                    />
                                    {/* {arrayList[0].policy_pdf} */}
                                    <input
                                      type="text"
                                      value={four}
                                      // onChange={change_content1}
                                    />{" "}
                                    <label for="abc3">업로드</label>{" "}
                                  </span>
                                  <button onClick={() => btn_change_name3()}>
                                    변경
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>{" "}
                          {/* <button onClick={() => setChange1(true)}>수정</button> */}
                        </div>
                      </li>
                    )}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PolicyInfo;
