import React, { useState, useEffect, useRef } from "react";
// import io from "socket.io-client";
// import close from "./../assets/images/close.png";
import Swal from "sweetalert2";
import { CommonAxios } from "../components/CommonAxios";
// import "../styles/chats.css";
// import AdminUserDetail from "../components/admin/detail/AdminUserDetail";
import AdminStoreList from "../components/admin/AdminStoreList";

const AdminStorePage = ({ roomid, onClose }) => {
  const [category, setCategory] = useState([]);
  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME +
        "/api/admin_select_product_category_list2",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setCategory(result.messageinfo.message);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
  }, []);
  return (
    <>
      <AdminStoreList category={category} />
    </>
  );
};

export default AdminStorePage;
