import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import InvestContentStatus from "./detail/InvestContentStatus";
import InvestTopSilder from "./detail/InvestTopSilder";
import InvestQna from "./detail/InvestQna";
import InvestNews from "./detail/InvestNews";
import InvestStory from "./detail/InvestStory";
import InvestReview from "./detail/InvestReview";
import MainBanner from "../main/MainBanner";
import MainBanner2 from "../main/MainBanner2";
const InvestStatusDatail = () => {
  return (
    <>
      <div className="common-wrap" style={{ backgroundColor: "#fff" }}>
        {/* <InvestTopSilder /> */}
        <MainBanner2 />
        <div className="common-inner-wrap">
          <InvestContentStatus />
          {/* <InvestNews />
          <InvestStory />
          <InvestReview />
          <InvestQna /> */}
          {/* <FundingSlider /> */}
          {/* <div className={intro.intro_wrap}> */}
          {/* <Intro_1 />
            <Intro_2 />
            <Intro_3 /> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default InvestStatusDatail;
