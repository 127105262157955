import inv from "../../../styles/invest.module.css";

import { GiFarmTractor } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcStackOfPhotos,
  FcSelfie,
  FcRuler,
} from "react-icons/fc";
import { RiLoginBoxLine } from "react-icons/ri";
import React from "react";
import farm_2020 from "../../../assets/images/farm_2020.png";
import farm_2021 from "../../../assets/images/farm_2021.jpg";
import farm_2022 from "../../../assets/images/farm_2022.jpg";
import farm_2023 from "../../../assets/images/farm_2023.jpg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link, useNavigate, useParams } from "react-router-dom";
import InvestProductItem from "./InvestProductItem";

// import FundingContentItem from "./FundingContentItem";

const MyinvestNot = () => {
  const navigate = useNavigate();
  const movePage = () => {
    sessionStorage.setItem("savePage", location.pathname);
    navigate("/login");
  };
  const location = useLocation();
  return (
    <div className={inv.not_user_wrap}>
      <div className={inv.not_user_box}>
        <h4>
          투자 내역을 확인하려면 <br />
          로그인이 필요합니다.
        </h4>
        <button onClick={() => movePage()}>로그인&회원가입하기</button>
      </div>
    </div>
  );
};
export default MyinvestNot;
