import inv from "../../../styles/invest.module.css";

import { GiFarmTractor } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcStackOfPhotos,
  FcSelfie,
  FcRuler,
} from "react-icons/fc";
import { RiLoginBoxLine } from "react-icons/ri";
import React from "react";
import farm_2020 from "../../../assets/images/farm_2020.png";
import farm_2021 from "../../../assets/images/farm_2021.jpg";
import farm_2022 from "../../../assets/images/farm_2022.jpg";
import farm_2023 from "../../../assets/images/farm_2023.jpg";
import { useSelector } from "react-redux";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import SwiperCore, { Virtual, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import Swal from "sweetalert2";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import InvestProductItem from "./InvestProductItem";

import Moment from "react-moment";
import moment from "moment";
import { wonComma } from "../../../lib/wonComma";
import { CommonAxios } from "../../CommonAxios";
import { MdError } from "react-icons/md";
// import FundingContentItem from "./FundingContentItem";
import CountUp from "react-countup";
const MyinvestJContent = ({ user }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const SliderItemList = ({ data }) => {
    return (
      <>
        <div className={inv.invest_myList_item}>
          <div className={inv.invest_myList_title}>{data.title}</div>
          <div className={inv.invest_myList_join}>가입일 {data.joinDate}</div>
          <ul>
            <li>
              <span>투자금액</span>
              <span className={inv.myList_value}>
                {wonComma(data.amount)}원
              </span>
            </li>{" "}
            <li>
              <span>금리</span>
              <span className={inv.myList_value}>{data.one}%</span>
            </li>{" "}
            {/* <li>
              <span>기번금리</span>
              <span className={inv.myList_value}>{data.two}%</span>
            </li>{" "}
            <li>
              <span>변동금리</span>
              <span className={inv.myList_value}>
                {data.three}% ~ {data.four}%
              </span>
            </li>{" "} */}
            <li>
              <span>만기일</span>
              <span className={inv.myList_value}>{data.endDate}</span>
            </li>{" "}
            {/* <li>
              <span>승인여부</span>
              <span className={inv.myList_value}>{data.endDate}</span>
            </li> */}
          </ul>
        </div>
      </>
    );
  };
  const SliderItemList2 = ({ data }) => {
    const [getInterest, setGetInterest] = useState("");
    const [mobileGetNum, setMobileGetNum] = useState("");
    const changeAge = useCallback((e) => {
      setGetInterest(e.target.value);
    }, []);

    const click_interest = () => {
      if (endCount === 0) {
        return;
      }
      if (data.invest_interest_withdraw !== "N") {
        return Swal.fire({
          title: "출금 신청 실패",
          html: `현재 ${
            data.invest_interest_withdraw === "Y"
              ? "이자"
              : data.invest_interest_withdraw === "C"
              ? "취소"
              : "환불"
          } 신청 진행을 하였습니다. 승인 후 이용가능합니다.
          `,

          confirmButtonText: "확인",
        });
      }
      if (endCount < 5000) {
        return Swal.fire({
          title: "이자 출금 신청",
          text: "이자 출금은 5000원 이상부터만 가능합니다.",
          confirmButtonText: "확인",
        });
      } else {
        let inputNum = "";
        let inputName = "";
        let inputBank = "";
        let inputAccount = "";
        let mobile = "";
        let mobileN = "";
        let inputNum12;
        let inp = "";
        // let inputNum1 = document.getElementById("num-value4");
        // inputNum1.addEventListener("change", function () {
        //   console.log("?");
        // });

        Swal.fire({
          title: "이자 출금 신청",
          showCancelButton: true,

          html: `아래 계좌로 입금 신청하시겠습니까?.<br/>
            <div style=" color:#00c73c; font-size:14px; "> (계좌 정보가 틀렸다면 마이페이지에서 변경하세요.)</div>

          



            <p style="text-align:center; margin-top:2px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">예금주</label><br/> ${myAccount[0].user_acName}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">은행 : </label> <br/>${myAccount[0].user_acBank}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">계좌번호 : </label><br/> ${myAccount[0].user_account}<p>
            <p style="text-align:center; margin-top:2px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">출금할 이자</label><br/>
               <input
                 type="text"
                 placeholder="숫자만 입력하세요"
                 class="swal2-input"
        
                 id="num-value4"></p>`,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
          inp,
          didOpen: () => {
            const inputNumber = Swal.getPopup().querySelector("#num-value4");
            inputNumber.addEventListener("input", (e) => {
              inputNumber.value = wonComma(
                e.target.value.replace(/[^\d]+/g, "")
              );
            });
          },

          preConfirm: (data) => {
            const inputNumber =
              Swal.getHtmlContainer().querySelector("#num-value4");

            if (!/[0-9]/g.test(inputNumber.value.replace(/[^\d]+/g, ""))) {
              Swal.showValidationMessage(`숫자만 입력하세요.`);
            } else if (inputNumber.value.replace(/[^\d]+/g, "") > endCount) {
              Swal.showValidationMessage(`범위내에서만 입력하세요.`);
            } else if (inputNumber.value.replace(/[^\d]+/g, "") < 5000) {
              Swal.showValidationMessage(`5,000원 이상만 출금 가능합니다.`);
            } else {
              inputNum = inputNumber.value.replace(/[^\d]+/g, "");
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "이자 출금 신청",
              showCancelButton: true,
              // icon: "error",
              html: ` <p style="text-align:center; font-size:14px;">
              본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
              <label>핸드폰 번호</label>
              <input type="text" placeholder="하이픈(-)없이 입력해주세요" class="swal2-input" id="num-value5" />  
               `,
              confirmButtonText: "확인",
              cancelButtonText: "취소",
              preConfirm: (data) => {
                const mobileNum =
                  Swal.getHtmlContainer().querySelector("#num-value5");

                if (!/[0-9]/g.test(mobileNum.value)) {
                  Swal.showValidationMessage(`숫자만 입력하세요.`);
                } else {
                  mobile = mobileNum.value;
                  let sendData = {
                    mobileNum: mobileNum.value,
                  };
                  CommonAxios(
                    process.env.REACT_APP_HOSTDONAME + "/api/send_sms",
                    sendData,
                    function (result) {
                      // console.log(result);
                      if (result.messageinfo.state == "ok") {
                        mobileN = result.messageinfo.message;
                      } else {
                        Swal.showValidationMessage(`숫자만 입력하세요.`);
                      }
                    }
                  );
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire({
                  title: "이자 출금 신청",
                  showCancelButton: true,
                  // icon: "error",
                  html: ` <p style="text-align:center; font-size:14px;">
                  본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
                  <label>인증 번호</label>
                  <input type="text" value='${mobileN}' class="swal2-input" id="num-value6" />  
                   `,
                  confirmButtonText: "확인",
                  cancelButtonText: "취소",
                  preConfirm: (data) => {
                    const mobileNumA =
                      Swal.getHtmlContainer().querySelector("#num-value6");

                    if (!/[0-9]/g.test(mobileNumA.value)) {
                      Swal.showValidationMessage(`숫자만 입력하세요.`);
                    } else {
                      if (Number(mobileN) === Number(mobileNumA.value)) {
                      } else {
                        Swal.showValidationMessage(
                          `인증번호가 일치하지 않습니다.`
                        );
                      }
                    }
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    var sendData = {
                      with_user_id: user.no,
                      with_invest_no: data.invest_wait_id,
                      with_product_id: data.invest_product_no,
                      with_account: myAccount[0].user_account,
                      with_bank: myAccount[0].user_acBank,
                      with_name: myAccount[0].user_acName,
                      with_mobile: mobile.replace(
                        /^(\d{2,3})(\d{3,4})(\d{4})$/,
                        `$1-$2-$3`
                      ),
                      with_invest_amount: inputNum,
                      with_all_yn: "Y",
                    };
                    // setMobileGetNum("123456");
                    CommonAxios(
                      process.env.REACT_APP_HOSTDONAME +
                        "/api/myInvest_insert_withdraw",
                      sendData,
                      function (result) {
                        // console.log(result);
                        if (result.messageinfo.state == "ok") {
                          let sendData2 = {
                            adminEmail: info[0].user_email,
                            adminMobile: info[0].user_mobile,
                            username: user.ur_name,
                            mobile: myAccount[0].user_mobile,
                            email: myAccount[0].user_email,
                            getamount: wonComma(inputNum),
                            price: wonComma(data.invest_amount),
                            type: "이자",
                            name: myAccount[0].user_name,
                          };
                          CommonAxios(
                            process.env.REACT_APP_HOSTDONAME +
                              "/api/user_invest_comfirm_send",
                            sendData2,
                            function (result) {
                              if (result.messageinfo.state === "ok") {
                              }
                            }
                          );
                          Swal.fire({
                            title: "이자 출금 신청 완료",

                            confirmButtonText: "확인",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              // setData(result.messageinfo.message);
                              let sendData = {
                                no: user.no,
                              };
                              // dispatch(selectInvest(sendData));
                              CommonAxios(
                                process.env.REACT_APP_HOSTDONAME +
                                  "/api/myInvest_select_join",
                                sendData,
                                function (result) {
                                  if (result.messageinfo.state === "ok") {
                                    setData(result.messageinfo.message);
                                    setInfo(result.messageinfo.message_data2);
                                    setMyAccount(
                                      result.messageinfo.message_data3
                                    );
                                    setSkeleton(true);
                                  } else {
                                  }
                                }
                              );
                            }
                          });
                        } else {
                        }
                      }
                    );
                  }
                });
              }
            });
          }
        });
      }
    };

    // window.changeHours = function (value) {
    //   var tfHours = document.getElementById("num-value4");
    //   // tfHours.innerHTML = value;
    //   console.log(value);
    // };

    const [refundSw, setRefundSw] = useState(false);
    const [refundNum, setRefundNum] = useState(0);
    useEffect(() => {
      let data2 = Number(data.datef) + 1;

      if (data2 >= 1 && data2 < 7) {
        setRefundNum(info[0].acc_refund_one_week);
      } else if (data2 > 6 && data2 < 14) {
        setRefundNum(info[0].acc_refund_two_weeks);
      } else if (data2 > 13) {
        setRefundNum(info[0].acc_refund_three);
      }

      setRefundSw(true);
    }, []);
    const click_all_interest = () => {
      if (!refundSw) {
        return;
      }
      if (endCount === 0) {
        return;
      }

      if (data.invest_interest_withdraw !== "N") {
        return Swal.fire({
          title: "출금 신청 실패",
          html: `현재 ${
            data.invest_interest_withdraw === "Y"
              ? "이자"
              : data.invest_interest_withdraw === "C"
              ? "취소"
              : "환불"
          } 신청 진행을 하였습니다. 승인 후 이용가능합니다.
          `,

          confirmButtonText: "확인",
        });
      }
      Swal.fire({
        title: "전액 출금 신청",
        showCancelButton: true,
        // icon: "error",
        html: `전액 환불시 상품 해지와 동시에 <br/>
        정책에 따라 ${refundNum}%만 환급 가능합니다.<br/>
        해지하시겠습니까?<br/>
        <p style="text-align:center; margin-top:5px; color:#00c73c; font-weight:500;"><label style="color:black;">투자금 : </label> ${wonComma(
          data.invest_amount
        )} 원<p>
        <p style="text-align:center; margin-top:5px; color:#00c73c; font-weight:500;"><label style="color:black;">현재 이자 : </label> ${wonComma(
          endCount
        )} 원<p>
        <p style="text-align:center; margin-top:5px; color:#00c73c; font-weight:500;"><label style="color:black;">수수료 제외한 비용 : </label> ${wonComma(
          data.invest_amount +
            Math.floor(endCount) -
            (data.invest_amount + Math.floor(endCount)) *
              ((100 - Number(refundNum)) / 100)
        )}원 <p>`,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      }).then((res) => {
        if (res.isConfirmed) {
          let inputNum = "";
          let inputName = "";
          let inputBank = "";
          let inputAccount = "";
          let mobile = "";
          let mobileN = "";
          Swal.fire({
            title: "전액 출금 신청",
            showCancelButton: true,
            html: `아래 계좌로 입금 신청하시겠습니까?.<br/>
            <div style=" color:#00c73c; font-size:14px; "> (계좌 정보가 틀렸다면 마이페이지에서 변경하세요.)</div>

          



            <p style="text-align:center; margin-top:2px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">예금주</label><br/> ${myAccount[0].user_acName}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">은행 : </label> <br/>${myAccount[0].user_acBank}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">계좌번호 : </label><br/> ${myAccount[0].user_account}<p>`,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: "전액 출금 신청",
                showCancelButton: true,
                // icon: "error",
                html: ` <p style="text-align:center; font-size:14px;">
              본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
              <label>핸드폰 번호</label>
              <input type="text" placeholder="하이픈(-)없이 입력해주세요" class="swal2-input" id="num-value5" />  
               `,
                confirmButtonText: "확인",
                cancelButtonText: "취소",
                preConfirm: (data) => {
                  const mobileNum =
                    Swal.getHtmlContainer().querySelector("#num-value5");

                  if (!/[0-9]/g.test(mobileNum.value)) {
                    Swal.showValidationMessage(`숫자만 입력하세요.`);
                  } else {
                    mobile = mobileNum.value;
                    let sendData = {
                      mobileNum: mobileNum.value,
                    };
                    CommonAxios(
                      process.env.REACT_APP_HOSTDONAME + "/api/send_sms",
                      sendData,
                      function (result) {
                        // console.log(result);
                        if (result.messageinfo.state == "ok") {
                          mobileN = result.messageinfo.message;
                        } else {
                          Swal.showValidationMessage(`숫자만 입력하세요.`);
                        }
                      }
                    );
                  }
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire({
                    title: "전액 출금 신청",
                    showCancelButton: true,
                    // icon: "error",
                    html: ` <p style="text-align:center; font-size:14px;">
                  본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
                  <label>인증 번호</label>
                  <input type="text" value='${mobileN}'  class="swal2-input" id="num-value6" />  
                   `,
                    confirmButtonText: "확인",
                    cancelButtonText: "취소",
                    preConfirm: (data) => {
                      const mobileNumA =
                        Swal.getHtmlContainer().querySelector("#num-value6");

                      if (!/[0-9]/g.test(mobileNumA.value)) {
                        Swal.showValidationMessage(`숫자만 입력하세요.`);
                      } else {
                        if (Number(mobileN) === Number(mobileNumA.value)) {
                        } else {
                          Swal.showValidationMessage(
                            `인증번호가 일치하지 않습니다.`
                          );
                        }
                      }
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      var sendData = {
                        end_user_id: user.no,
                        end_invest_no: data.invest_wait_id,
                        end_product_id: data.invest_product_no,
                        end_account: myAccount[0].user_account,
                        end_bank: myAccount[0].user_acBank,
                        end_name: myAccount[0].user_acName,
                        end_mobile: mobile.replace(
                          /^(\d{2,3})(\d{3,4})(\d{4})$/,
                          `$1-$2-$3`
                        ),
                        end_amount: data.invest_amount,
                        end_interest_result: Math.floor(endCount),
                        end_rate: data.pro_rate,
                        end_percent: refundNum,
                        end_amount_result:
                          data.invest_amount +
                          Math.floor(endCount) -
                          (data.invest_amount + Math.floor(endCount)) *
                            ((100 - Number(refundNum)) / 100),
                        end_kind: "R",
                      };
                      // setMobileGetNum("123456");
                      CommonAxios(
                        process.env.REACT_APP_HOSTDONAME +
                          "/api/myInvest_insert_end",
                        sendData,
                        function (result) {
                          // console.log(result);
                          if (result.messageinfo.state == "ok") {
                            let sendData2 = {
                              adminEmail: info[0].user_email,
                              adminMobile: info[0].user_mobile,
                              username: user.ur_name,
                              mobile: myAccount[0].user_mobile,
                              email: myAccount[0].user_email,
                              getamount: wonComma(
                                data.invest_amount +
                                  Math.floor(endCount) -
                                  (data.invest_amount + Math.floor(endCount)) *
                                    ((100 - Number(refundNum)) / 100)
                              ),
                              price: wonComma(data.invest_amount),
                              type: "환불",
                              name: myAccount[0].user_name,
                            };
                            CommonAxios(
                              process.env.REACT_APP_HOSTDONAME +
                                "/api/user_invest_comfirm_send",
                              sendData2,
                              function (result) {
                                if (result.messageinfo.state === "ok") {
                                }
                              }
                            );

                            Swal.fire({
                              title: "전액 출금(환불) 신청 완료",

                              confirmButtonText: "확인",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                let sendData = {
                                  no: user.no,
                                };
                                // dispatch(selectInvest(sendData));
                                CommonAxios(
                                  process.env.REACT_APP_HOSTDONAME +
                                    "/api/myInvest_select_join",
                                  sendData,
                                  function (result) {
                                    if (result.messageinfo.state === "ok") {
                                      setData(result.messageinfo.message);
                                      setInfo(result.messageinfo.message_data2);
                                      setMyAccount(
                                        result.messageinfo.message_data3
                                      );
                                      setSkeleton(true);
                                    } else {
                                    }
                                  }
                                );
                                // let sendData = {
                                //   // no: id,
                                //   no: user.no,
                                // };
                                // // dispatch(selectInvest(sendData));
                                // CommonAxios(
                                //   process.env.REACT_APP_HOSTDONAME +
                                //     "/api/myInvest_select_join",
                                //   sendData,
                                //   function (result) {
                                //     if (result.messageinfo.state === "ok") {
                                //       setData(result.messageinfo.message);
                                //       setSkeleton(true);
                                //     } else {
                                //     }
                                //   }
                                // );
                              }
                            });
                          } else {
                          }
                        }
                      );
                    }
                  });
                }
              });
            }
          });
        }
      });
    };
    const totalSeconds = 86400;
    const [count, setCount] = useState(0);
    const [endCount, setEndCount] = useState(0);
    const [switchs, setSwtichs] = useState(true);

    useEffect(() => {
      const startSecond = new Date(
        moment().format("YYYY-MM-DD 00:00:00")
      ).getTime();

      const tenSecUp = (data.invest_interest_ing / totalSeconds) * 1; // 10초당 올라갈 숫자
      const interval = setInterval(() => {
        // console.log(tenSecUp);
        const elapsedMSec = (new Date().getTime() - startSecond) / 1000;

        const currentCount = (elapsedMSec / 1) * tenSecUp; // 현재 숫자 계산
        const endCounts = (elapsedMSec / 1 + 1) * tenSecUp; // 다음 숫자 계산

        setCount(data.invest_interest_sum + currentCount);

        setEndCount(data.invest_interest_sum + endCounts);
      }, 1000); // 10초마다 count 값 갱신

      return () => {
        clearInterval(interval);
      };
    }, []);

    const click_cancel = () => {
      if (endCount === 0) {
        return;
      }
      if (data.invest_interest_withdraw !== "N") {
        return Swal.fire({
          title: "취소 신청 실패",
          html: `현재 이미 ${
            data.invest_interest_withdraw === "Y"
              ? "이자"
              : data.invest_interest_withdraw === "C"
              ? "취소"
              : "환불"
          } 신청 진행을 하였습니다.
          `,

          confirmButtonText: "확인",
        });
      }
      Swal.fire({
        title: "투자 취소하기",
        showCancelButton: true,
        // icon: "error",
        html: `투자를 취소하시겠습니까?<br/>
    
       `,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      }).then((res) => {
        if (res.isConfirmed) {
          let date = new Date(data.invest_start_date);

          const diffDate = new Date().getTime() - date.getTime();

          if (Math.abs(diffDate / (1000 * 60 * 60 * 24)) > 3) {
            return Swal.fire({
              title: "투자 취소 불가",
              html: `이틀이 지났습니다.`,

              confirmButtonText: "확인",
            });
          }

          let inputNum = "";
          let inputName = "";
          let inputBank = "";
          let inputAccount = "";
          let mobile = "";
          let mobileN = "";
          Swal.fire({
            title: "투자 취소 신청",
            showCancelButton: true,
            html: `아래 계좌로 입금 신청하시겠습니까?.<br/>
            <div style=" color:#00c73c; font-size:14px; "> (계좌 정보가 틀렸다면 마이페이지에서 변경하세요.)</div>

          



            <p style="text-align:center; margin-top:2px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">예금주</label><br/> ${myAccount[0].user_acName}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">은행 : </label> <br/>${myAccount[0].user_acBank}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">계좌번호 : </label><br/> ${myAccount[0].user_account}<p>`,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: "투자 취소 신청",
                showCancelButton: true,
                // icon: "error",
                html: ` <p style="text-align:center; font-size:14px;">
                본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
                <label>핸드폰 번호</label>
                <input type="text" placeholder="하이픈(-)없이 입력해주세요" class="swal2-input" id="num-value5" />  
                 `,
                confirmButtonText: "확인",
                cancelButtonText: "취소",
                preConfirm: (data) => {
                  const mobileNum =
                    Swal.getHtmlContainer().querySelector("#num-value5");

                  if (!/[0-9]/g.test(mobileNum.value)) {
                    Swal.showValidationMessage(`숫자만 입력하세요.`);
                  } else {
                    mobile = mobileNum.value;
                    let sendData = {
                      mobileNum: mobileNum.value,
                    };
                    CommonAxios(
                      process.env.REACT_APP_HOSTDONAME + "/api/send_sms",
                      sendData,
                      function (result) {
                        // console.log(result);
                        if (result.messageinfo.state == "ok") {
                          mobileN = result.messageinfo.message;
                        } else {
                          Swal.showValidationMessage(`숫자만 입력하세요.`);
                        }
                      }
                    );
                  }
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  Swal.fire({
                    title: "투자 취소 신청",
                    showCancelButton: true,
                    // icon: "error",
                    html: ` <p style="text-align:center; font-size:14px;">
                    본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
                    <label>인증 번호</label>
                    <input type="text" value='${mobileN}'  class="swal2-input" id="num-value6" />  
                     `,
                    confirmButtonText: "확인",
                    cancelButtonText: "취소",
                    preConfirm: (data) => {
                      const mobileNumA =
                        Swal.getHtmlContainer().querySelector("#num-value6");

                      if (!/[0-9]/g.test(mobileNumA.value)) {
                        Swal.showValidationMessage(`숫자만 입력하세요.`);
                      } else {
                        if (Number(mobileN) === Number(mobileNumA.value)) {
                        } else {
                          Swal.showValidationMessage(
                            `인증번호가 일치하지 않습니다.`
                          );
                        }
                      }
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      var sendData = {
                        end_user_id: user.no,
                        end_invest_no: data.invest_wait_id,
                        end_product_id: data.invest_product_no,
                        end_account: myAccount[0].user_account,
                        end_bank: myAccount[0].user_acBank,
                        end_name: myAccount[0].user_acName,
                        end_mobile: mobile.replace(
                          /^(\d{2,3})(\d{3,4})(\d{4})$/,
                          `$1-$2-$3`
                        ),
                        end_amount: data.invest_amount,
                        end_interest_result: 0,
                        end_rate: data.pro_rate,
                        end_percent: info[0].acc_refund_percent,
                        end_amount_result: data.invest_amount,
                        end_kind: "C",
                      };
                      // setMobileGetNum("123456");
                      CommonAxios(
                        process.env.REACT_APP_HOSTDONAME +
                          "/api/myInvest_insert_end",
                        sendData,
                        function (result) {
                          // console.log(result);
                          if (result.messageinfo.state == "ok") {
                            let sendData2 = {
                              adminEmail: info[0].user_email,
                              adminMobile: info[0].user_mobile,
                              username: user.ur_name,
                              mobile: myAccount[0].user_mobile,
                              email: myAccount[0].user_email,
                              getamount: wonComma(data.invest_amount),
                              price: wonComma(data.invest_amount),
                              type: "취소",
                              name: myAccount[0].user_name,
                            };
                            CommonAxios(
                              process.env.REACT_APP_HOSTDONAME +
                                "/api/user_invest_comfirm_send",
                              sendData2,
                              function (result) {
                                if (result.messageinfo.state === "ok") {
                                }
                              }
                            );
                            Swal.fire({
                              title: "투자 취소 신청 완료",

                              confirmButtonText: "확인",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                // setData(result.messageinfo.message);
                                let sendData = {
                                  no: user.no,
                                };
                                // dispatch(selectInvest(sendData));

                                CommonAxios(
                                  process.env.REACT_APP_HOSTDONAME +
                                    "/api/myInvest_select_join",
                                  sendData,
                                  function (result) {
                                    if (result.messageinfo.state === "ok") {
                                      setData(result.messageinfo.message);
                                      setInfo(result.messageinfo.message_data2);
                                      setMyAccount(
                                        result.messageinfo.message_data3
                                      );
                                      setSkeleton(true);
                                    } else {
                                    }
                                  }
                                );
                              }
                            });
                          } else {
                          }
                        }
                      );
                    }
                  });
                }
              });
            }
          });
        }
      });
    };

    return (
      <>
        <div className={inv.invest_my_data_box}>
          <div className={inv.invest_my_data_top}>
            <p> {data.pro_title ? data.pro_title : "삭제된 상품"}</p>
            <div className={inv.invest_my_data_top_div}>
              <div>
                {" "}
                <span>
                  시작일 :{" "}
                  <Moment format="YYYY-MM-DD 00:00">
                    {data.invest_start_date}
                  </Moment>
                </span>{" "}
                <span>
                  마감일 :{" "}
                  <Moment format="YYYY-MM-DD 00:00">
                    {data.invest_end_date}
                  </Moment>
                </span>
              </div>

              <span>수익률 : {data.pro_rate}%</span>
            </div>
          </div>
          <div className={inv.invest_my_data_box_1}>
            <span>투자금액</span>
            <p>
              <em>{wonComma(data.invest_amount)}</em>원
            </p>
          </div>{" "}
          <div className={inv.invest_my_data_box_2}>
            <span>지금까지 쌓인 이자</span>

            {data.invest_confirm_ing === "Y" &&
              data.invest_interest_withdraw !== "C" &&
              data.invest_interest_withdraw !== "R" && (
                <p>
                  <em>
                    {" "}
                    <CountUp
                      start={count}
                      end={endCount}
                      duration={1}
                      separator={","}
                      decimals={3}
                      // suffix={"원"}
                    />
                  </em>
                  원 <br />
                  &nbsp;
                  {data.invest_interest_withdraw === "Y" &&
                    "(이자 출금 대기중)"}
                </p>
              )}
            {(data.invest_interest_withdraw === "N" ||
              data.invest_interest_withdraw === "C") &&
              data.invest_confirm_ing === "N" && (
                <p>
                  <em> 대기 상품</em> <br />
                  {data.invest_interest_withdraw === "C" && "(취소 대기중)"}
                  {data.invest_interest_withdraw === "N" && <>&nbsp;</>}
                  {/* <em> 0</em>원 */}
                </p>
              )}

            {data.invest_interest_withdraw === "R" && (
              <p>
                <em> {wonComma(data.invest_refund_stop)}</em>원 <br />
                (환불 대기중)
              </p>
            )}
          </div>
          {data.invest_confirm_ing === "Y" && (
            <div className={inv.invest_my_data_btn}>
              <button onClick={click_interest}>이자 출금하기</button>{" "}
              <button onClick={click_all_interest}>전액 환불하기</button>
              {/* <button onClick={click_cancel}>투자 취소하기</button> */}
            </div>
          )}{" "}
          {(data.invest_interest_withdraw === "N" ||
            data.invest_interest_withdraw === "C") &&
            data.invest_confirm_ing === "N" && (
              <div className={inv.invest_my_data_btn}>
                <button style={{ background: "#00c73c" }}></button>{" "}
                {/* <button>투자 취소하기</button> */}
                <button onClick={click_cancel}>투자 취소하기</button>
              </div>
            )}
        </div>
      </>
    );
  };

  const slickRef = useRef(null);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };
  const [state, setState] = useState(1);
  const [skeleton, setSkeleton] = useState(false);
  const [switchs, setSwtichs] = useState(false);
  const [info, setInfo] = useState([]);
  const [myAccount, setMyAccount] = useState([]);
  useEffect(() => {
    let sendData = {
      // no: id,
      no: user.no,
    };

    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/myInvest_select_join",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
          setInfo(result.messageinfo.message_data2);
          setMyAccount(result.messageinfo.message_data3);
          setSkeleton(true);
        } else {
        }
      }
    );
  }, []);
  return (
    <div className={inv.invest_my_wrap}>
      {" "}
      <div className={`${inv.invest_my_content} ${inv.invest_my_content_join}`}>
        <div className={inv.invest_my_top}>
          <h4>
            <em>{user.ur_name}</em>님의
            <br />
            투자가입상품 내역입니다.
          </h4>
        </div>

        <div
          className={inv.invest_my_top_cont}
          style={{
            border: "2.5px solid #e5e7eb",

            padding: "20px",
            borderRadius: "20px",
          }}
        >
          {" "}
          {/* <div className="common-inner-wrap"> */}
          {!skeleton && (
            <>
              <div className={inv.skeleton_data_box}>
                <div
                  className={`${inv.invest_my_data_box} ${inv.invest_my_data_box_sk}  ${inv.invest_my_data_box_sk1}`}
                ></div>{" "}
                <div
                  className={`${inv.invest_my_data_box} ${inv.invest_my_data_box_sk}  ${inv.invest_my_data_box_sk2}`}
                ></div>{" "}
                <div
                  className={`${inv.invest_my_data_box} ${inv.invest_my_data_box_sk} ${inv.invest_my_data_box_sk3}`}
                ></div>{" "}
              </div>
            </>
          )}
          {skeleton && data.length === 0 && (
            <>
              {/* <div className={inv.invest_my_data_not}>
                <h4 className={inv.invest_my_data_not_h4}>
                  <MdError style={{ marginRight: "4px" }} /> 가입하신 투자
                  상품이 없습니다.
                </h4>
              </div> */}
            </>
          )}
          {skeleton && data && (
            <>
              <Swiper
                className="mySwiper"
                slidesPerView={1}
                ref={setSwiper}
                {...parmss}
                style={{ position: "relative" }}
                // clickable={true}
                // history={{
                //   key: "slide",
                // }}
                modules={[Navigation]}
                breakpoints={{
                  520: {
                    slidesPerView: 2,
                    // spaceBetween: 20,
                  },

                  970: {
                    slidesPerView: 3,
                    // spaceBetween: 20,
                  },
                }}
              >
                {data.map((data) => {
                  return (
                    <SwiperSlide>
                      <div>
                        <SliderItemList2 data={data} />
                      </div>
                    </SwiperSlide>
                  );
                })}
                {/* </div>
                );
              })} */}
              </Swiper>{" "}
              <ButtonPrev2
                ref={prevRef}

                // style={{ width: "100px", height: "100px", background: "blue" }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdKeyboardArrowLeft />{" "}
                </div>
              </ButtonPrev2>{" "}
              {/* <div
                ref={prevRef}
                style={{ width: "100px", height: "100px", background: "blue" }}
              ></div> */}
              <ButtonNext2 ref={nextRef}>
                {" "}
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdKeyboardArrowRight />
                </div>
              </ButtonNext2>
            </>
          )}
          {/* </div> */}
        </div>

        {/* <div className={inv.invest_my_bottom}>
          <h4>대기상품</h4>

          <div className={inv.invest_my_list_box}>
            <Slider {...settings} style={{ height: "255px" }} ref={slickRef}>
              {data.map((data) => {
                return (
                  <div>
                    <SliderItemList data={data} />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div> */}
      </div>
    </div>
  );
};
const ButtonPrev2 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 50%;
  left: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
  cursor: pointer;
`;
const ButtonNext2 = styled.div`
  position: absolute;
  z-index: 1;

  width: 35px;
  height: 35px;
  display: block;
  background: #fff;
  top: 50%;
  right: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
  cursor: pointer;
`;
export default MyinvestJContent;
