import styles from "../../styles/mypage.module.css";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import intro from "../../styles/intro.module.css";
import FundingSlider from "./detail/FundingSlider";
import { useEffect, useState } from "react";
import StoreSlider from "./detail/StoreSlider";
const StoreDetail = () => {
  const [cate, setCate] = useState([]);
  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_category_store",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setCate(result.messageinfo.message);

          // setUserInfo(result.messageinfo.message[0]);
          // localStorage.removeItem("meta_info");
          // document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  }, []);
  return (
    <>
      <div className="common-wrap">
        <div className="common-inner-wrap">
          {cate && <StoreSlider cate={cate} />}
          {/* <div className={intro.intro_wrap}> */}
          {/* <Intro_1 />
            <Intro_2 />
            <Intro_3 /> */}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default StoreDetail;
