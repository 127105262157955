import React, { useState, useEffect, useRef } from "react";
import { CommonAxios } from "./../../CommonAxios";
import { Link, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Moment from "react-moment";
import styles from "./../../../styles/admin.module.css";
import CustomMainTable from "./../CustomMainTable";
import { transMonth } from "../../../lib/date/TransDate";
import { textReplace } from "../../../lib/textReplace";
import { wonComma } from "../../../lib/wonComma";
const ProductEndList = () => {
  const location = useLocation();
  const { id } = useParams();
  const Dates = ({ value }) => {
    if (value) {
      return <Moment format="YYYY.MM.DD. hh:mm">{value}</Moment>;
    }
  };

  const NumberFormat = ({ value }) => {
    // const numberStr = parseInt(value + "0000").toLocaleString() + " 원";

    return 0;
  };

  const [subList, setSubList] = useState([]);
  const [showList, setShowList] = useState([]);

  const [dateOption, setDateOption] = useState("year");
  //연별 데이터
  const [startYear, setStartYear] = useState(new Date());
  const [endYear, setEndYear] = useState(new Date());
  //월별 데이터
  const [startMonth, setStartMonth] = useState(new Date());
  const [endMonth, setEndMonth] = useState(new Date());
  //일별 데이터
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [money, setMoney] = useState("default");
  const [name, setName] = useState("");
  /// 여기까지

  useEffect(() => {
    let sendData = {
      no: id,
      start: moment(startDate).format("YYYY-MM-DD 00:00:00"),
      end: moment(endDate).format("YYYY-MM-DD 23:59:59"),
      name: name,
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_product_end",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setShowList(result.messageinfo.message);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
  }, []);

  const TableColumn = [
    {
      Header: "이메일",
      accessor: "user_email",
    },
    {
      Header: "성명",
      accessor: "user_name",
    },
    {
      Header: "투자원금",
      accessor: "invest_amount",
      Cell: ({ cell: { value } }) => <>{wonComma(value)}원</>,
    },
    {
      Header: "투자당시이자",
      accessor: "end_interest_result",
      Cell: ({ cell: { value } }) => <>{wonComma(value)}원</>,
    },
    {
      Header: "최종 환급금액",
      accessor: "end_amount_result",
      Cell: ({ cell: { value } }) => <>{wonComma(value)}원</>,
    },
    {
      Header: "상태",
      accessor: "end_kind",
      Cell: ({ cell: { value } }) => <>{value === "R" ? "환불" : "종료"}</>,
    },
  ];

  const SearchData = () => {
    let sendData = {
      no: id,
      start: startDate ? moment(startDate).format("YYYY-MM-DD 00:00:00") : "",
      end: moment(endDate).format("YYYY-MM-DD 23:59:59"),

      name: name ? textReplace(name) : "",
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_product_end",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setShowList(result.messageinfo.message);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
    // let arrList = subList;
    // if (dateOption == "year") {
    //   var starts = moment(startYear).format("YYYY");
    //   var ends = moment(endYear).format("YYYY");
    // } else if (dateOption == "month") {
    //   var starts = moment(startMonth).format("YYYY-MM");
    //   var ends = moment(endMonth).format("YYYY-MM");
    // } else {
    //   var starts = new Date(startDate);
    //   var date = new Date(endDate);
    //   var ends = new Date(date.getTime() + 24 * 60 * 60 * 1000);
    // }
    // arrList = arrList.filter((item) => {
    //   if (money == "default") {
    //     const dates = new Date(item.invest_date);
    //     return dates >= starts && dates < ends;
    //   } else if (money == 1) {
    //     const dates = new Date(item.invest_date);
    //     const amounts = item.invest_amount;
    //     return dates >= starts && dates < ends && amounts < 100;
    //   } else if (money == 2) {
    //     const dates = new Date(item.invest_date);
    //     const amounts = item.invest_amount;
    //     return (
    //       dates >= starts && dates < ends && amounts >= 100 && amounts < 1000
    //     );
    //   } else if (money == 3) {
    //     const dates = new Date(item.invest_date);
    //     const amounts = item.invest_amount;
    //     return (
    //       dates >= starts && dates < ends && amounts >= 1000 && amounts < 2000
    //     );
    //   } else if (money == 4) {
    //     const dates = new Date(item.invest_date);
    //     const amounts = item.invest_amount;
    //     return (
    //       dates >= starts && dates < ends && amounts >= 200 && amounts < 3000
    //     );
    //   } else if (money == 5) {
    //     const dates = new Date(item.invest_date);
    //     const amounts = item.invest_amount;
    //     return dates >= starts && dates < ends && amounts >= 3000;
    //   }
    // });
    // setShowList(arrList);
  };

  useEffect(() => {
    if (subList.length > 0) {
      setShowList(subList);
    }
  }, [subList]);

  const transDate = (props) => {
    let text = props + "";
    let data = text.split(" ");
    let year = data[3];
    let month = transMonth(data[1]);
    let dayNumber = data[2];

    data = year + "-" + month + "-" + dayNumber;
    return data;
  };
  const [btnName, setBtnName] = useState("no1");
  const onClickButton = (e) => {
    const { name } = e.target;
    setBtnName(name);
    const today = new Date();
    let data;
    switch (name) {
      case "no1":
        setStartDate(today);
        return;
      case "no2":
        data = today - 1000 * 60 * 60 * 24 * 7;
        data = new Date(data);
        setStartDate(data);
        return;
      case "no3":
        data = today - 1000 * 60 * 60 * 24 * 15;
        data = new Date(data);
        setStartDate(data);
        return;
      case "no4":
        data = today - 1000 * 60 * 60 * 24 * 30;
        data = new Date(data);
        setStartDate(data);
        return;
      case "no5":
        data = today - 1000 * 60 * 60 * 24 * 90;
        data = new Date(data);
        setStartDate(data);
        return;
      case "no6":
        data = today - 1000 * 60 * 60 * 24 * 365;
        data = new Date(data);
        setStartDate("");
        return;
    }
  };
  const filters = [
    {
      id: 1,
      text: "오늘",
      name: "no1",
    },
    {
      id: 2,
      text: "7일",
      name: "no2",
    },
    {
      id: 3,
      text: "15일",
      name: "no3",
    },
    {
      id: 4,
      text: "1개월",
      name: "no4",
    },
    {
      id: 5,
      text: "3개월",
      name: "no5",
    },
    {
      id: 5,
      text: "전체",
      name: "no6",
    },
  ];
  return (
    <>
      <div>
        <div className={styles.search_history_box}>
          <div className={styles.search_search_title}>
            <div className={styles.search_history}>
              <div className={styles.search_title_box}>기간검색</div>
              <div className={styles.price_box}>
                <div>
                  <span className={styles.datepicker}>
                    <span>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        locale={ko}
                        onChange={(date) => setStartDate(date)}
                        maxDate={endDate}
                        selected={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText={"적용시작일"}
                      />
                    </span>
                    <span className={styles.datepicker_ico}>
                      <FaCalendar />
                    </span>
                  </span>
                  <span>~</span>
                  <span className={styles.datepicker}>
                    <span>
                      <DatePicker
                        dateFormat="yyyy-MM-dd"
                        locale={ko}
                        onChange={(date) => setEndDate(date)}
                        selected={endDate}
                        maxDate={new Date()}
                        startDate={endDate}
                        endDate={endDate}
                        placeholderText={"적용종료일"}
                      />
                    </span>
                    <span className={styles.datepicker_ico}>
                      <FaCalendar />
                    </span>
                  </span>
                </div>
              </div>
              <div className={styles.filter_box}>
                {filters.map((filter) => {
                  return (
                    <>
                      <button
                        className={
                          btnName === filter.name
                            ? `${styles.filter_text} ${styles.active}`
                            : `${styles.filter_text}`
                        }
                        type="button"
                        onClick={onClickButton}
                        name={filter.name}
                        key={filter.id}
                      >
                        {filter.text}
                      </button>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <div className={styles.search_search_title}>
            <div className={styles.search_history}>
              <div className={styles.search_title_box}>금액선택</div>
              <div className={styles.search_data}>
                <div>
                  <select
                    defaultValue={money}
                    onChange={(e) => setMoney(e.target.value)}
                  >
                    <option value="default">금액을 선택해주세요</option>
                    <option value={1}>10~100만원 미만</option>
                    <option value={2}>100~1000만원 미만</option>
                    <option value={3}>1000~2000만원 미만</option>
                    <option value={4}>200~3000만원 미만</option>
                    <option value={5}>3000만원 이상</option>
                  </select>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className={styles.product_filter_btn}>
          <button style={{ width: "100%" }} onClick={() => SearchData()}>
            검색
          </button>
        </div>
      </div>
      <CustomMainTable columns={TableColumn} data={showList} />
    </>
  );
};

export default ProductEndList;
