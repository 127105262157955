import { FaUserCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import MyInvestContent from "./detail/MyInvestContent";
import InvestTopSilder from "./detail/InvestTopSilder";
import InvestQna from "./detail/InvestQna";
import InvestNews from "./detail/InvestNews";
import InvestStory from "./detail/InvestStory";
import InvestReview from "./detail/InvestReview";
import { useEffect, useState } from "react";
import inv from "../../styles/invest.module.css";
import { Link, useParams } from "react-router-dom";
import MyinvestNot from "./detail/MyinvestNot";
import MyinvestJContent from "./detail/MyinvestJContent";
import { WarringAlert } from "../alert/Alert";

const MyInvestJDetail = () => {
  const [warringAlert, setWarringAlert] = useState(false);
  const [content, setContent] = useState("");
  const { id } = useParams();
  const { id2 } = useParams();
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  return (
    <>
      {" "}
      {warringAlert && (
        <WarringAlert content={content} setWarringAlert={setWarringAlert} />
      )}
      <div id="container">
        <div className="common-wrap">
          <div className={inv.common_invest_wrap}>
            <InvestTopSilder />
            <div className="common-inner-wrap">
              <MyInvestContent />
              {!user && <MyinvestNot />}
              {user && (
                <MyinvestJContent
                  user={user}
                  setWarringAlert={setWarringAlert}
                  setContent={setContent}
                />
              )}{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyInvestJDetail;
