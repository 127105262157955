import inv from "../../../styles/invest.module.css";
import { GiFarmTractor } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcStackOfPhotos,
  FcSelfie,
  FcRuler,
} from "react-icons/fc";
import { useState, useEffect } from "react";
import { CommonAxios } from "../../CommonAxios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectInvest } from "../../../modules/invest";
import { numberToKorean } from "../../../lib/numberToKorean";
import img1 from "../../../assets/images/investBack1.jpg";
import img2 from "../../../assets/images/investBack2.jpg";
import img3 from "../../../assets/images/investBack3.jpg";
import img4 from "../../../assets/images/investBack4.jpg";
import CountUp from "react-countup";
import { wonComma } from "../../../lib/wonComma";
import { FaArrowUp } from "react-icons/fa"; // import FundingContentItem from "./FundingContentItem";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}

const InvestSlideItem = ({ data }) => {
  const wonReplace = () => {
    return numberToKorean(data.pro_target_amount);
    // return numberToKorean(580000000);
  };
  const wonReplace1 = () => {
    let won = String(data.pro_target_amount);
    // if (won.length === 6) {
    //   return wonComma(data.pro_target_amount);
    // }else if(won.length === 7)

    // return 0;
    if (data.amount) {
      return numberToKorean(data.amount);
    } else {
      return "0";
    }
  };

  const percent = () => {
    let result = data.pro_target_amount;
    let ing = data.amount;

    return (ing / result) * 100;
  };

  useEffect(() => {}, []);
  return (
    <>
      <div
        className={inv.visual_item}
        style={{ backgroundImage: `url(${data.img})` }}
      >
        {" "}
        <div className="common-inner-wrap">
          <div className={inv.visual_box}>
            <span className={inv.visual_sub}>{data.category_name}</span>
            <div className={inv.visual_title}>{data.pro_title}</div>

            <div className={inv.visual_inner}>
              <ul>
                <li>
                  <span>수익률</span>
                  <div className={inv.num}>
                    <FaArrowUp />
                    {data.pro_rate}%
                  </div>
                </li>
                <li>
                  <span>가입기간</span>
                  <em className={inv.num}> {data.pro_join}개월</em>
                </li>
              </ul>
              {/* <div className={inv.line}> */}
              {/* <div
                className={inv.green_line}
                // style="width:100%;"
              >
                100%
              </div> */}
              {/* </div> */}
              <div className={inv.line_wrap}>
                <progress
                  className={inv.progress}
                  value={percent()}
                  min="0"
                  max="100"
                ></progress>
              </div>

              <div className={inv.line_text_box}>
                <span className={`${inv.per} ${inv.n1}`}>
                  모집률{" "}
                  <CountUp
                    start={0}
                    end={percent()}
                    decimals={1}
                    decimal="."
                    duration={2}
                  />
                  %
                </span>
                <span className={`${inv.per} ${inv.n2}`}>
                  {wonReplace1()} / {wonReplace()}원
                </span>
              </div>
            </div>
            <div className={inv.visual_btn}>
              <a className={inv.visual_go}>
                {data.pro_end_yn === "Y" && "모집 마감중인 상품입니다"}
                {data.pro_end_yn === "C" && "모집 예정중인 상품입니다"}
                {data.pro_end_yn === "N" && "모집 진행중인 상품입니다"}
              </a>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};
const InvestTopSilder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    let sendData = {
      no: id,
    };
    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/invest_select_four",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          let dataArr = [];
          let imgArr = [img1, img2, img3, img4];

          for (let i = 0; i < result.messageinfo.message.length; i++) {
            result.messageinfo.message[i].img = imgArr[i];
          }

          setData(result.messageinfo.message);
          setSkeleton(true);
        } else {
        }
      }
    );
  }, []);
  var settings = {
    centerMode: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,

    dotsClass: `invest_custom_class_name`,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };
  const [skeleton, setSkeleton] = useState(false);
  return (
    <div className={inv.visual_back}>
      <div className={inv.visual_front}>
        <div className={inv.visual}>
          <div className={inv.visual_list} style={{ overflow: "hidden" }}>
            {!skeleton && (
              <>
                {" "}
                <div
                  className={inv.visual_item}
                  style={{ backgroundImage: `url(${img1})` }}
                ></div>
              </>
            )}
            {skeleton && data.length === 0 && (
              <>
                {" "}
                <div
                  className={inv.visual_item}
                  style={{ backgroundImage: `url(${img1})` }}
                >
                  {" "}
                  <div className="common-inner-wrap">
                    <div className={inv.visual_box}>
                      <div className={inv.visual_title}>
                        상품들이 존재하지 않습니다.
                      </div>

                      <div className={inv.visual_inner}>
                        <ul>
                          <li>
                            <span>수익률</span>
                            <div className={inv.num}>-</div>
                          </li>
                          <li>
                            <span>가입기간</span>
                            <em className={inv.num}> -</em>
                          </li>
                        </ul>
                        {/* <div className={inv.line}> */}
                        {/* <div
                className={inv.green_line}
                // style="width:100%;"
              >
                100%
              </div> */}
                        {/* </div> */}
                        <div className={inv.line_wrap}>
                          <progress
                            className={inv.progress}
                            value="0"
                            min="0"
                            max="100"
                          ></progress>
                        </div>

                        <div className={inv.line_text_box}>
                          <span className={`${inv.per} ${inv.n1}`}>
                            모집률 <CountUp start={0} end={0} duration={2} />%
                          </span>
                          <span className={`${inv.per} ${inv.n2}`}>
                            0 / {0}원
                          </span>
                        </div>
                      </div>
                      <div className={inv.visual_btn}>
                        <a className={inv.visual_go}>-</a>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </>
            )}
            {skeleton && data && (
              <Slider {...settings} style={{ height: "100%" }}>
                {data &&
                  data.map((data) => {
                    return <InvestSlideItem data={data} />;
                  })}
              </Slider>
            )}
          </div>
          <div className={inv.visual_dots}></div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </div>{" "}
    </div>
    // </header>
  );
};
export default InvestTopSilder;
