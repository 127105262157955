import React, { useState, useEffect, useRef, useCallback } from "react";

import styles from "../styles/admin.module.css";
import { getTextTotal, textOverCut } from "../lib/stringLenghts";
import Swal from "sweetalert2";
import { CommonAxios } from "../components/CommonAxios";
import { textReplace } from "../lib/textReplace";
import { useNavigate, useParams } from "react-router-dom";
import { FcEditImage } from "react-icons/fc";
import { wonComma } from "../lib/wonComma";
const StoreDetail = () => {
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  const imgNameRef = useRef(); //이미지 파일 이름 REF
  const [imageFile, setImageFile] = useState(""); //이미지 속성정보
  const [getimage, setGetImage] = useState(""); //render.result 값

  const [updateImg, setUpdateImg] = useState("");
  const [openValue, setOpenValue] = useState("Y");
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [agency, setAgency] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [titleByte, setTitleByte] = useState(0);
  const changeTitle = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 20);
    let text = textOverCut(e.target.value, 20);

    setTitle(text);
    setTitleByte(byte.byte);
  }, []);

  const changeLink = useCallback((e) => {
    setLink(e.target.value);
  }, []);
  const changeAgency = useCallback((e) => {
    setAgency(e.target.value);
  }, []);

  const changeDate = useCallback((e) => {
    setDateValue(wonComma(e.target.value.replace(/[^\d]+/g, "")));
  }, []);

  const [cate, setCate] = useState("");
  const [cateByte, setCateByte] = useState(0);
  const changeCate = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 10);
    let text = textOverCut(e.target.value, 10);

    setCate(text);
    setCateByte(byte.byte);
  }, []);

  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 1024 * 1;

    if (file[0] === undefined) {
      return;
    }

    // if (
    //   imgExtension[1] !== "jpg" ||
    //   imgExtension[1] !== "png" ||
    //   imgExtension[1] !== "jpeg" ||
    //   imgExtension[1] !== "gif"
    // ) {
    //   Swal.fire({
    //     title: "이미지 업로드 불가",
    //     text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",
    //     icon: "error",
    //     confirmButtonText: "확인",
    //     confirmButtonColor: "#FF0000",
    //   });
    //   return;
    // }
    if (file[0].size >= maxSize) {
      //이미지 사이즈가 500k보다 크다면,
      setImageFile(imageFile);
      Swal.fire({
        title: "이미지 용량 초과",
        html: `1MB 사진을 올려주세요.`,

        confirmButtonText: "확인",
      });
      return;
    } else {
      setImageFile(file);
    }
  };

  useEffect(() => {
    preview(); // 미리보기
  });

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      imageRef.current.src = reader.result;
      setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };

  // const checkOnlyOne = (element) => {
  //   const checkboxes = document.getElementsByName("open");

  //   checkboxes.forEach((cb) => {
  //     cb.checked = false;
  //   });

  //   element.checked = true;
  // };

  const [imgArr, setImgArr] = useState([]);
  const btn_store_reg = () => {
    if (
      !title ||
      !openValue ||
      !link ||
      !updateImg ||
      !dateValue ||
      !cateValue
    ) {
      return Swal.fire({
        title: "빈 칸을 채워주세요.",

        confirmButtonText: "확인",
      });
    }

    let sendData = {
      no: id,
      title: textReplace(title),
      link: textReplace(link),
      open: openValue,
      store_category: cateValue,

      store_amount: dateValue.replace(/[^\d]+/g, ""),
      filename: "",
      image: "",
      beforeImg: updateImg.split("/")[updateImg.split("/").length - 1],

      // mobile :
    };

    if (imageFile !== "") {
      var file_info = imageFile[0].name.split(".");
      var file_name = `${Math.floor(Math.random() * 100000)}.${file_info[1]}`;
      sendData.filename = file_name;
      sendData.image = `${getimage}file_name:${file_name}file_name:file_name:/userProfile/`;
    }

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_store",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          Swal.fire({
            title: "수정 완료",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/admin/store");
            }
          });
        } else {
          navigate("/admin/store");
        }
      }
    );
  };
  const { id } = useParams();

  useEffect(() => {
    let sendData = {
      no: id,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_store_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setOpenValue(result.messageinfo.message[0].store_open);
          // setArrayList(result.messageinfo.message);
          setTitle(result.messageinfo.message[0].store_title);

          let byte = getTextTotal(
            result.messageinfo.message[0].store_title,
            20
          );
          setTitleByte(byte.byte);

          setDateValue(wonComma(result.messageinfo.message[0].store_amount));

          setLink(result.messageinfo.message[0].store_link);

          if (result.messageinfo.message[0].store_img) {
            setUpdateImg(
              `/store/${id}/${result.messageinfo.message[0].store_img}`
            );
          }

          setCategoryData(result.messageinfo.message_data2);
          if (result.messageinfo.message_data2.length > 0) {
            setCateValue(result.messageinfo.message[0].store_category);
          }
        } else {
        }
      }
    );
  }, []);

  const navigate = useNavigate();
  const change_open = (data) => {
    setOpenValue(data);
  };

  const [cateValue, setCateValue] = useState("");
  const change_cate = useCallback((e) => {
    setCateValue(e.target.value);
  }, []);
  const [categoryData, setCategoryData] = useState([]);
  // useEffect(() => {
  //   let sendData = {};
  //   CommonAxios(
  //     process.env.REACT_APP_HOSTDONAME + "/api/admin_select_store_category",
  //     sendData,
  //     function (result) {
  //       if (result.messageinfo.state == "ok") {
  //         setCategoryData(result.messageinfo.message);
  //         if (result.messageinfo.message.length > 0) {
  //           setCateValue(result.messageinfo.message[0].no);
  //         }
  //       }
  //     }
  //   );
  // }, []);
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          게시판 관리 &nbsp;〉 &nbsp;스토어 목록&nbsp;〉 &nbsp; 스토어 상세{" "}
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.store_write_wrap}>
            <div className={styles.store_write_inner}>
              <div className={styles.store_write_title}>
                <h4>스토어 수정</h4>
                <div className={styles.store_write_button}>
                  <button onClick={btn_store_reg}>수정</button>
                </div>
              </div>
              <div className={styles.store_write_content}>
                <div className={styles.store_write_top}>
                  {" "}
                  <div className={styles.store_file_box}>
                    <h4>대표 이미지 업로드</h4>
                    <div className={styles.store_img_box}>
                      {updateImg === "" ? (
                        !imageFile ? (
                          <div>
                            <span className={styles.image_span}> Image</span>
                          </div>
                        ) : (
                          <img src="" ref={imageRef} />
                        )
                      ) : (
                        <img src={updateImg} ref={imageRef} />
                      )}
                    </div>
                    <label className={styles.store_file}>
                      <input
                        type="file"
                        className={styles.store_file_inp}
                        accept="image/*"
                        onChange={inpChange}
                      />
                      이미지 업로드
                    </label>
                  </div>{" "}
                  <p className={styles.store_warning}>
                    {" "}
                    사이즈는 235(px) x 235(px) 사이즈로 업로드바랍니다.{" "}
                  </p>
                  {/* <div className={styles.store_open_box}>
                    <h4>공개 여부</h4>
                    <label>
                      <input
                        type="radio"
                        name="open"
                        value="Y"
                        onChange={() => change_open("Y")}
                        checked={openValue === "Y" ? true : false}
                      />{" "}
                      공개
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="open"
                        value="N"
                        onChange={() => change_open("N")}
                        checked={openValue === "N" ? true : false}
                      />{" "}
                      비공개
                    </label>
                  </div> */}
                </div>
                <div className={styles.store_write_bottom}>
                  <div>
                    <label>상품명</label>
                    <div className={`${styles.store_write_inpbox}`}>
                      <input
                        className={styles.store_write_inp}
                        placeholder="제목을 입력해주세요."
                        onChange={changeTitle}
                        value={title}
                        // onChange={onChangeTitle}
                        // value={title}
                      />{" "}
                      <span>{titleByte}/20</span>
                    </div>
                  </div>
                  <div>
                    {" "}
                    <label>링크</label>{" "}
                    <div className={`${styles.store_write_inpbox}`}>
                      <input
                        className={styles.store_write_inp}
                        placeholder="링크를 입력해주세요."
                        onChange={changeLink}
                        value={link}
                        // onChange={onChangeTitle}
                        // value={title}
                      />{" "}
                    </div>
                  </div>
                  <div className={styles.store_inp_last}>
                    <div>
                      <label>카테고리명</label>
                      <div className={`${styles.store_write_inpbox}`}>
                        <select onChange={change_cate} value={cateValue}>
                          {categoryData &&
                            categoryData.map((data) => {
                              return (
                                <option value={data.no}>
                                  {data.category_name}
                                </option>
                              );
                            })}
                        </select>{" "}
                        {/* <input
                      className={styles.store_write_inp}
                      placeholder="카테고리명을 입력하세요"
                      onChange={changeCate}
                      value={cate}
                      
                    />{" "}
                    <span>{cateByte}/10</span> */}
                      </div>
                    </div>{" "}
                    {/* <div>
                  <label>별점</label>
                  <div className={`${styles.store_write_inpbox}`}>
                    <input
                      className={styles.store_write_inp}
                      placeholder="별점을 입력하세요."
                      onChange={changeAgency}
                      value={agency}
                    />{" "}
                  </div>
                </div>{" "} */}
                    <div>
                      <label>가격</label>{" "}
                      <div className={`${styles.store_write_inpbox}`}>
                        <input
                          className={styles.store_write_inp}
                          placeholder="숫자를 입력하세요."
                          onChange={changeDate}
                          value={dateValue}
                          // onChange={onChangeTitle}
                          // value={title}
                        />{" "}
                      </div>
                    </div>
                  </div>
                  {/* <textarea></textarea> */}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreDetail;
