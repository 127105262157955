import IntroduceDetail from "../components/introduce/IntroduceDetail";
import { useEffect } from "react";
const Introduce = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <IntroduceDetail />
    </>
  );
};

export default Introduce;
