import React, { useState, useEffect, useRef } from "react";

import PressWrite from "../components/admin/PressWrite";
import ReviewWrite from "../components/admin/ReviewWrite";

const AdminReviewWrite = ({ roomid, onClose }) => {
  return (
    <>
      <ReviewWrite />
    </>
  );
};

export default AdminReviewWrite;
