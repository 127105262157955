import MypageInfoDetail from "../components/mypage/MypageInfoDetail";
import MypageInterestDetail from "../components/mypage/MypageInterestDetail";

const MypageInterestInfo = () => {
  return (
    <>
      <MypageInterestDetail />
    </>
  );
};

export default MypageInterestInfo;
