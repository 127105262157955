import { useState } from "react";
import inv from "../../../styles/invest.module.css";
import { useEffect, useRef } from "react";

import SwiperCore, {
  Virtual,
  Navigation,
  Pagination,
  History,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import React from "react";
import { CommonAxios } from "../../CommonAxios";
import { wonComma } from "../../../lib/wonComma";
import { TbBuildingFactory, TbApple, TbTruckDelivery } from "react-icons/tb";
import { numberToKorean } from "../../../lib/numberToKorean";
import { CgNotes } from "react-icons/cg";
import { MdOutlineDesignServices } from "react-icons/md";
import { BsBuildingGear } from "react-icons/bs";
import { GrCompliance } from "react-icons/gr";
import icon_1 from "../../../assets/images/icon_1.png";
import icon_2 from "../../../assets/images/icon_2.png";
import icon_3 from "../../../assets/images/icon_3.png";
import icon_4 from "../../../assets/images/icon_4_not.png";
import icon_2_not from "../../../assets/images/icon_2_not.png";
import icon_3_not from "../../../assets/images/icon_3_not.png";
import icon_4_not from "../../../assets/images/icon_4_not.png";
import icon_5 from "../../../assets/images/icon_5.png";
import icon_6 from "../../../assets/images/icon_6.png";
import icon_7 from "../../../assets/images/icon_7.png";
import icon_8 from "../../../assets/images/icon_8.png";

const InvestProductItem = ({ data, field }) => {
  const [moreView, setMoreView] = useState(false);

  const SliderFarmList = ({ data }) => {
    return (
      <>
        {data.farm_state === "Y" ? (
          <div className={inv.farm_item}>
            {" "}
            <a target="_blank" href={data.farm_link ? data.farm_link : "#"}>
              <div className={inv.farm_item_box}>
                <div className={inv.farm_top}>
                  <p className={inv.title}>{data.farm_title}</p>

                  {/* <div className={inv.time_box}>
                  <span className={inv.date}>7월 5일</span>
                  <span className={inv.time}>10:24:47</span>
                </div> */}
                </div>
                <div className={inv.farm_text}>
                  <ul>
                    <li>
                      <span className={inv.title}>{field.field_title_one}</span>
                      <span className={inv.text}>
                        {" "}
                        {wonComma(data.farm_area)} {field.field_unit_one}
                      </span>
                    </li>
                    <li>
                      <span className={inv.title}>{field.field_title_two}</span>
                      <span className={inv.text}>{data.farm_crops_1}</span>
                    </li>
                    <li>
                      <span className={inv.title}>
                        {field.field_title_three}
                      </span>
                      <span className={inv.text}>
                        <em className={inv.em_green}>
                          {wonComma(data.farm_estimated_earns)}
                        </em>{" "}
                        {field.field_unit_two}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className={inv.farm_now}>
                  <div className={inv.now_title}>{field.field_title_four}</div>
                  <ul className={inv.now_list}>
                    <li>
                      <div className={`${inv.now_icon} `}>
                        {data.farm_progress === 1 ||
                        data.farm_progress === 2 ||
                        data.farm_progress === 3 ||
                        data.farm_progress === 4 ? (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_B_img_one}`}
                            />
                          </>
                        ) : (
                          <>
                            {" "}
                            <img
                              src={`/field/${field.no}/${field.field_B_img_one_not}`}
                            />
                          </>
                        )}{" "}
                      </div>
                      {data.farm_progress === 1 ||
                      data.farm_progress === 2 ||
                      data.farm_progress === 3 ||
                      data.farm_progress === 4 ? (
                        <>
                          <span style={{ color: "#00c73c" }}>
                            {field.field_B_text_one}
                          </span>
                        </>
                      ) : (
                        <>
                          {" "}
                          <span> {field.field_B_text_one}</span>
                        </>
                      )}{" "}
                    </li>
                    <li>
                      <div className={`${inv.now_icon} `}>
                        {data.farm_progress === 2 ||
                        data.farm_progress === 3 ||
                        data.farm_progress === 4 ? (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_B_img_two}`}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_B_img_two_not}`}
                            />
                          </>
                        )}{" "}
                      </div>
                      {data.farm_progress === 2 ||
                      data.farm_progress === 3 ||
                      data.farm_progress === 4 ? (
                        <>
                          <span style={{ color: "#00c73c" }}>
                            {" "}
                            {field.field_B_text_two}
                          </span>
                        </>
                      ) : (
                        <>
                          <span> {field.field_B_text_two}</span>
                        </>
                      )}{" "}
                    </li>
                    <li>
                      <div className={`${inv.now_icon} `}>
                        {data.farm_progress === 3 ||
                        data.farm_progress === 4 ? (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_B_img_three}`}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_B_img_three_not}`}
                            />
                          </>
                        )}{" "}
                      </div>
                      {data.farm_progress === 3 || data.farm_progress === 4 ? (
                        <>
                          <span style={{ color: "#00c73c" }}>
                            {" "}
                            {field.field_B_text_three}
                          </span>
                        </>
                      ) : (
                        <>
                          <span> {field.field_B_text_three}</span>
                        </>
                      )}{" "}
                    </li>{" "}
                    <li>
                      <div className={`${inv.now_icon} `}>
                        {data.farm_progress === 4 ? (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_B_img_four}`}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_B_img_four_not}`}
                            />
                          </>
                        )}{" "}
                      </div>
                      {data.farm_progress === 4 ? (
                        <>
                          <span style={{ color: "#00c73c" }}>
                            {" "}
                            {field.field_B_text_four}
                          </span>
                        </>
                      ) : (
                        <>
                          <span> {field.field_B_text_four}</span>
                        </>
                      )}{" "}
                    </li>
                  </ul>
                </div>
              </div>{" "}
            </a>
          </div>
        ) : (
          <div className={inv.farm_item}>
            {" "}
            <a target="_blank" href={data.farm_link ? data.farm_link : "#"}>
              <div className={inv.farm_item_box}>
                <div className={inv.farm_top}>
                  <p className={inv.title}>{data.farm_title}</p>
                </div>
                <div className={inv.farm_text}>
                  <ul>
                    <li>
                      <span className={inv.title}>{field.field_title_one}</span>
                      <span className={inv.text}>
                        <em className={inv.farm_myriad_em}>
                          {wonComma(data.farm_area)}
                        </em>{" "}
                        {field.field_unit_one}
                      </span>
                    </li>
                    <li>
                      <span className={inv.title}>{field.field_title_two}</span>
                      <span className={inv.text}>{data.farm_crops_1}</span>
                    </li>
                    <li>
                      <span className={inv.title}>
                        {field.field_title_three}
                      </span>
                      <span className={inv.text}>
                        <em className={`${inv.em_green} ${inv.farm_myriad_em}`}>
                          {wonComma(data.farm_estimated_earns)}
                        </em>{" "}
                        {field.field_unit_two}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className={inv.farm_now}>
                  <div className={inv.now_title}>{field.field_title_four}</div>
                  <ul className={inv.now_list}>
                    <li>
                      <div className={`${inv.now_icon} `}>
                        {data.farm_progress === 1 ||
                        data.farm_progress === 2 ||
                        data.farm_progress === 3 ||
                        data.farm_progress === 4 ? (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_A_img_one}`}
                            />
                          </>
                        ) : (
                          <img
                            src={`/field/${field.no}/${field.field_A_img_one_not}`}
                          />
                        )}{" "}
                      </div>
                      {data.farm_progress === 1 ||
                      data.farm_progress === 2 ||
                      data.farm_progress === 3 ||
                      data.farm_progress === 4 ? (
                        <>
                          <span style={{ color: "#00c73c" }}>
                            {field.field_A_text_one}
                          </span>
                        </>
                      ) : (
                        <>
                          {" "}
                          <span> {field.field_A_text_one}</span>
                        </>
                      )}{" "}
                    </li>
                    <li>
                      <div className={`${inv.now_icon} `}>
                        {data.farm_progress === 2 ||
                        data.farm_progress === 3 ||
                        data.farm_progress === 4 ? (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_A_img_two}`}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_A_img_two_not}`}
                            />
                          </>
                        )}{" "}
                      </div>
                      {data.farm_progress === 2 ||
                      data.farm_progress === 3 ||
                      data.farm_progress === 4 ? (
                        <>
                          <span style={{ color: "#00c73c" }}>
                            {" "}
                            {field.field_A_text_two}
                          </span>
                        </>
                      ) : (
                        <>
                          <span> {field.field_A_text_two}</span>
                        </>
                      )}{" "}
                    </li>
                    <li>
                      <div className={`${inv.now_icon} `}>
                        {data.farm_progress === 3 ||
                        data.farm_progress === 4 ? (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_A_img_three}`}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_A_img_three_not}`}
                            />
                          </>
                        )}{" "}
                      </div>
                      {data.farm_progress === 3 || data.farm_progress === 4 ? (
                        <>
                          <span style={{ color: "#00c73c" }}>
                            {" "}
                            {field.field_A_text_three}
                          </span>
                        </>
                      ) : (
                        <>
                          <span> {field.field_A_text_three}</span>
                        </>
                      )}{" "}
                    </li>{" "}
                    <li>
                      <div className={`${inv.now_icon} `}>
                        {data.farm_progress === 4 ? (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_A_img_four}`}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              src={`/field/${field.no}/${field.field_A_img_four_not}`}
                            />
                          </>
                        )}{" "}
                      </div>
                      {data.farm_progress === 4 ? (
                        <>
                          <span style={{ color: "#00c73c" }}>
                            {" "}
                            {field.field_A_text_four}
                          </span>
                        </>
                      ) : (
                        <>
                          <span> {field.field_A_text_four}</span>
                        </>
                      )}{" "}
                    </li>
                  </ul>
                </div>
              </div>{" "}
            </a>
          </div>
        )}
      </>
    );
  };
  const SliderFarmList1 = ({ data }) => {
    return (
      <>
        {data.goods_state === "Y" ? (
          <div className={inv.farm_item}>
            <div className={inv.farm_item_box}>
              <div className={inv.farm_top}>
                <p className={inv.title}>{data.goods_title}</p>

                {/* <div className={inv.time_box}>
                  <span className={inv.date}>7월 5일</span>
                  <span className={inv.time}>10:24:47</span>
                </div> */}
              </div>
              <div className={inv.farm_text}>
                <ul>
                  <li>
                    <span className={inv.title}>생산 면적</span>
                    <span className={inv.text}>
                      {" "}
                      {wonComma(data.goods_area)} {field.field_unit_one}
                    </span>
                  </li>
                  <li>
                    <span className={inv.title}>생산 품종</span>
                    <span className={inv.text}>
                      {data.goods_crops_1},{data.goods_crops_2}등
                    </span>
                  </li>
                  <li>
                    <span className={inv.title}>예상 매출액</span>
                    <span className={inv.text}>
                      <em className={inv.em_green}>
                        {wonComma(data.goods_estimated_earns)}
                      </em>{" "}
                      {field.field_unit_two}
                    </span>
                  </li>
                </ul>
              </div>
              <div className={inv.farm_now}>
                <div className={inv.now_title}>주차 실시간 현황</div>
                <ul className={inv.now_list}>
                  <li>
                    <div className={`${inv.now_icon} `}>
                      {data.goods_progress === 1 ||
                      data.goods_progress === 2 ||
                      data.goods_progress === 3 ||
                      data.goods_progress === 4 ? (
                        <>
                          <img src={icon_1} />
                        </>
                      ) : (
                        <></>
                      )}{" "}
                    </div>
                    {data.goods_progress === 1 ||
                    data.goods_progress === 2 ||
                    data.goods_progress === 3 ||
                    data.goods_progress === 4 ? (
                      <>
                        <span style={{ color: "#00c73c" }}>생산</span>
                      </>
                    ) : (
                      <>
                        {" "}
                        <span>생산</span>
                      </>
                    )}{" "}
                  </li>
                  <li>
                    <div className={`${inv.now_icon} `}>
                      {data.goods_progress === 2 ||
                      data.goods_progress === 3 ||
                      data.goods_progress === 4 ? (
                        <>
                          <img src={icon_2} />
                        </>
                      ) : (
                        <>
                          <img src={icon_2_not} />
                        </>
                      )}{" "}
                    </div>
                    {data.goods_progress === 2 ||
                    data.goods_progress === 3 ||
                    data.goods_progress === 4 ? (
                      <>
                        <span style={{ color: "#00c73c" }}>수확</span>
                      </>
                    ) : (
                      <>
                        <span>수확</span>
                      </>
                    )}{" "}
                  </li>
                  <li>
                    <div className={`${inv.now_icon} `}>
                      {data.goods_progress === 3 ||
                      data.goods_progress === 4 ? (
                        <>
                          <img src={icon_3} />
                        </>
                      ) : (
                        <>
                          <img src={icon_3_not} />
                        </>
                      )}{" "}
                    </div>
                    {data.goods_progress === 3 || data.goods_progress === 4 ? (
                      <>
                        <span style={{ color: "#00c73c" }}>납품</span>
                      </>
                    ) : (
                      <>
                        <span>납품</span>
                      </>
                    )}{" "}
                  </li>{" "}
                  <li>
                    <div className={`${inv.now_icon} `}>
                      {data.goods_progress === 4 ? (
                        <>
                          <img src={icon_4} />
                        </>
                      ) : (
                        <>
                          <img src={icon_4_not} />
                        </>
                      )}{" "}
                    </div>
                    <span>배당</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ) : (
          <div className={inv.farm_item}>
            <div className={inv.farm_item_box}>
              <div className={inv.farm_top}>
                <p className={inv.title}>{data.goods_title}</p>
              </div>
              <div className={inv.farm_text}>
                <ul>
                  <li>
                    <span className={inv.title}>생산 면적</span>
                    <span className={inv.text}>
                      <em className={inv.farm_myriad_em}>
                        {wonComma(data.goods_area)}
                      </em>{" "}
                      평
                    </span>
                  </li>
                  <li>
                    <span className={inv.title}>생산 품종</span>
                    <span className={inv.text}>
                      {" "}
                      {data.goods_crops_1},{data.goods_crops_2}등
                    </span>
                  </li>
                  <li>
                    <span className={inv.title}>예상 매출액</span>
                    <span className={inv.text}>
                      <em className={`${inv.em_green} ${inv.farm_myriad_em}`}>
                        {wonComma(data.goods_estimated_earns)}
                      </em>{" "}
                      원
                    </span>
                  </li>
                </ul>
              </div>
              <div className={inv.farm_now}>
                <div className={inv.now_title}>1주차 실시간 현황</div>
                <ul className={inv.now_list}>
                  <li>
                    <div className={`${inv.now_icon} `}>
                      <img src={icon_5} />
                    </div>
                    <span style={{ color: "#00c73c" }}>발주</span>
                  </li>
                  <li>
                    <div className={`${inv.now_icon} `}>
                      <img src={icon_6} />
                    </div>
                    <span style={{ color: "#00c73c" }}>공사</span>
                  </li>
                  <li>
                    {/* <!-- 깜빡이는 아이콘에 클래스 cc 추가 --> */}
                    <div className={`${inv.now_icon} `}>
                      <img src={icon_7} />
                    </div>
                    <span>종묘</span>
                  </li>
                  <li>
                    {/* <!-- 깜빡이는 아이콘에 클래스 cc 추가 --> */}
                    <div className={`${inv.now_icon} `}>
                      <img src={icon_4_not} />
                    </div>
                    <span>배당</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  var settings = {
    centerMode: false,
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  return (
    <>
      {" "}
      <Swiper
        className="mySwiper"
        slidesPerView={1}
        ref={setSwiper}
        {...parmss}
        style={{ position: "relative" }}
        // history={{
        //   key: "slide",
        // }}
        modules={[Navigation]}
        breakpoints={{
          520: {
            slidesPerView: 2,
            // spaceBetween: 20,
          },
          740: {
            slidesPerView: 3,
            // spaceBetween: 20,
          },
          970: {
            slidesPerView: 4,
            // spaceBetween: 20,
          },
        }}
      >
        {data.map((data) => {
          return (
            <SwiperSlide>
              <SliderFarmList data={data} />
            </SwiperSlide>
          );
        })}
      </Swiper>{" "}
      <ButtonPrev1
        ref={prevRef}

        // style={{ width: "100px", height: "100px", background: "blue" }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <MdKeyboardArrowLeft />{" "}
        </div>
      </ButtonPrev1>{" "}
      {/* <div
                ref={prevRef}
                style={{ width: "100px", height: "100px", background: "blue" }}
              ></div> */}
      <ButtonNext1 ref={nextRef}>
        {" "}
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <MdKeyboardArrowRight />
        </div>
      </ButtonNext1>
    </>
  );
};

const ButtonPrev1 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 50%;
  left: -14px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonNext1 = styled.div`
  position: absolute;
  z-index: 1;

  width: 35px;
  height: 35px;
  display: block;
  background: #fff;
  top: 50%;
  right: -14px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
export default InvestProductItem;
