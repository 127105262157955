import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import close from "./../assets/images/close.png";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import AdminUserDetail from "../components/admin/detail/AdminUserDetail";
import NoticeList from "../components/admin/NoticeList";
import QnaList from "../components/admin/QnaList";
import BannerList from "../components/admin/BannerList";
import FooterList from "../components/admin/FooterList";

const AdminFooterPage = ({ roomid, onClose }) => {
  return (
    <>
      <FooterList />
    </>
  );
};

export default AdminFooterPage;
