import styles from "../../styles/admin.module.css";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../CommonAxios";
import StoryWriteDetail from "./detail/StoryWriteDetail";
import PressWriteDetail from "./detail/PressWriteDetail";
// import AdminUserInfo from "./AdminUserInfo";
const PressWrite = () => {
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          회사소개 &nbsp;〉 &nbsp;언론보도 목록 〉&nbsp;언론보도 등록{" "}
        </div>
        <div className={styles.wrap_content_box_body}>
          <PressWriteDetail />
        </div>
      </div>
    </>
  );
};

export default PressWrite;
