import MainItemSlider from "./MainItemSlider";
import main from "../../styles/main.module.css";
import { useEffect, useState } from "react";
import { CommonAxios } from "../CommonAxios";
import { useSelector } from "react-redux";
import store1 from "../../assets/images/Layer 521.png";
import store2 from "../../assets/images/Layer 522.png";
import store3 from "../../assets/images/Layer 523.png";
// import store4 from "../../assets/images/Layer 524.png";
import store4 from "../../assets/images/Layer 525.png";
import store5 from "../../assets/images/Layer 526.png";
import store6 from "../../assets/images/Layer 527.png";
import { BsQuestionCircle, BsQuestionCircleFill } from "react-icons/bs";
import { MdError } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const MainSection2 = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  useEffect(() => {
    let sendData = {
      // no: id,
      no: "",
    };

    if (user) {
      sendData.no = user.no;
    }
    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_store_one",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
          setSkeleton(true);
        } else {
        }
      }
    );
  }, []);

  const [brand, setBrand] = useState([]);
  const [skeleton1, setSkeleton1] = useState(false);
  useEffect(() => {
    let sendData = {
      // no: id,
      no: 2,
    };

    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_brand",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setBrand(result.messageinfo.message);
          setSkeleton1(true);
        } else {
        }
      }
    );
  }, []);

  const move_page = () => {
    navigate("/store");
  };

  // const data = [
  //   {
  //     img: store1,
  //     content: "[제주농장] 한라봉 몰랑몰랑 곤약젤리 150g x 10포 x 1박스",
  //     per: 5148,
  //     star: 4.8,
  //     icon1: true,
  //     icon2: false,
  //   },
  //   {
  //     img: store2,
  //     content: "[제주농장] 레몬 몰랑몰랑 곤약젤리 150g x 10포 x 1박스",
  //     per: 4501,
  //     star: 4.5,
  //     icon1: false,
  //     icon2: false,
  //   },
  //   {
  //     img: store3,
  //     content: "[제주농장] 풋귤 몰랑몰랑 곤약젤리 150g x 10포 x 1박스",
  //     per: 4146,
  //     star: 4.5,
  //     icon1: true,
  //     icon2: true,
  //   },
  //   {
  //     img: store4,
  //     content: "[제주농장] 프리미엄 알로에 면역겔 60ml x 14포 x 1박스(2주분)",
  //     per: 13074,
  //     star: 4.8,
  //     icon1: false,
  //     icon2: true,
  //   },
  //   {
  //     img: store5,
  //     content: "[제주농장] 관절튼튼 플러스 젤리 20g x 30포 x 1박스",
  //     per: 3405,
  //     star: 4.4,
  //     icon1: false,
  //     icon2: false,
  //   },
  //   {
  //     img: store6,
  //     content: "[제주농장] 관절튼튼 플러스 젤리 20g x 30포 x 1박스",
  //     per: 8973,
  //     star: 4.6,
  //     icon1: false,
  //     icon2: true,
  //   },
  //   {
  //     img: store1,
  //     content: "[제주농장] 한라봉 몰랑몰랑 곤약젤리 150g x 10포 x 1박스",
  //     per: 5148,
  //     star: 4.8,
  //     icon1: true,
  //     icon2: false,
  //   },
  // ];

  const MainStorekeleton = () => {
    return (
      <div className={main.main_stroe_box_skeleton}>
        <div className={main.store_item_skeleton}>
          <div className={main.store_item_inner_skeleton}></div>
          <div className={main.store_item_cont_skeleton}></div>
        </div>
        <div className={main.store_item_skeleton}>
          {" "}
          <div className={main.store_item_inner_skeleton}></div>
          <div className={main.store_item_cont_skeleton}></div>
        </div>
        <div className={main.store_item_skeleton}>
          {" "}
          <div className={main.store_item_inner_skeleton}></div>
          <div className={main.store_item_cont_skeleton}></div>
        </div>
        <div className={main.store_item_skeleton}>
          {" "}
          <div className={main.store_item_inner_skeleton}></div>
          <div className={main.store_item_cont_skeleton}></div>
        </div>
        <div className={main.store_item_skeleton}>
          {" "}
          <div className={main.store_item_inner_skeleton}></div>
          <div className={main.store_item_cont_skeleton}></div>
        </div>
        <div className={main.store_item_skeleton}>
          {" "}
          <div className={main.store_item_inner_skeleton}></div>
          <div className={main.store_item_cont_skeleton}></div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className={main.main_title_top}>
        <h3 className={main.main_title_h3}>스토어 투자상품</h3>
        {skeleton1 && (
          <span className={main.main_store_more}>
            <em>{brand[0].brand_text1}</em>
            <em>&nbsp;{brand[0].brand_text2}</em>
            <p className={main.main_svg2_before}>
              <BsQuestionCircle />{" "}
            </p>
            <p className={main.main_svg2_after}>
              <BsQuestionCircleFill />
            </p>{" "}
            <div className={main.main_store_open}>
              {" "}
              <a href={`${brand[0].brand_link}`} target="_blank">
                {brand[0].brand_content}
              </a>
            </div>
            {/* <Link to="/notice">더보기</Link>{" "} */}
          </span>
        )}
      </div>

      <span className={main.main_title_sub}>투자의 기회를 잡으세요!</span>
      <div className={main.main_store_box}>
        {!skeleton && <MainStorekeleton />}
        {skeleton && data.length === 0 && (
          <>
            {" "}
            <div className={main.main_not_data_store}>
              {" "}
              <span className={main.main_not_span_store}>
                <MdError style={{ marginRight: "4px" }} /> 상품이 등록되어 있지
                않습니다.
              </span>
            </div>
          </>
        )}
        {skeleton && data && <MainItemSlider data={data} />}
      </div>
      <div className={main.main_store_button}>
        <button>
          <a target="_blank" href="https://meta12.net/store">
            더보기
          </a>
        </button>
      </div>
    </>
  );
};
export default MainSection2;
