import com from "../../../styles/company.module.css";
import { GiFarmTractor } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcStackOfPhotos,
  FcSelfie,
  FcRuler,
} from "react-icons/fc";
import { CommonAxios } from "../../CommonAxios";
// import FundingContentItem from "./FundingContentItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Moment from "react-moment";
import { FaStar } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import SwiperCore, { Virtual, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const CompanyNews = () => {
  const a = [
    { d: "수확 작물 :비트", img: "d" },
    { d: "수확 작물 :당근", img: "d" },
    { d: "수확 작물 :원두", img: "d" },
    { d: "수확 작물 :팥", img: "d" },
    { d: "수확 작물 :토마토", img: "d" },
    { d: "수확 작물 :비트", img: "d" },
    // { d: "수확 작물 :당근", img: img2 },
    // { d: "수확 작물 :원두", img: img3 },
  ];

  const [data, setData] = useState([]);
  useEffect(() => {
    let sendData = "";
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/company_select_press",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);

  const SliderItemList = ({ data }) => {
    return (
      <>
        <div className={com.news_item}>
          {/* <a href="" className={com.news_anchor}> */}
          <a
            href={`${data.press_link}`}
            className={com.news_anchor}
            target="_blank"
          >
            <div
              className={com.news_img}
              style={{
                backgroundImage: `url('/press/${data.no}/${data.press_img}')`,
              }}
            ></div>
            <div className={com.news_text}>
              <p className={com.news_title}>{data.press_title}</p>
              <div className={com.text_box}>
                <span className={com.time}>
                  <Moment format="YYYY-MM-DD">{data.press_write_date}</Moment>{" "}
                </span>
                <span className={com.news_name}>{data.press_agency}</span>
              </div>
            </div>
          </a>
          {/* </a> */}
        </div>
      </>
    );
  };

  const slickRef = useRef(null);

  const previous = useCallback(() => slickRef.current.slickPrev(), []);
  const next = useCallback(() => slickRef.current.slickNext(), []);
  var settings = {
    // centerMode: false,
    // dots: false,
    // infinite: false,
    // slidesToShow: 4,
    // slidesToScroll: 1, dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    afterChange: (current) => {
      setState(current + 1);
    },
    responsive: [
      {
        breakpoint: 970,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [state, setState] = useState(1);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  return (
    <>
      {" "}
      <div className={com.rowgroup2}>
        <section className={com.news}>
          <h2>언론보도</h2>
          <div className={com.news_list}>
            {/* <Slider {...settings} style={{ height: "auto" }} ref={slickRef}> */}
            <Swiper
              className="mySwiper"
              slidesPerView={2}
              ref={setSwiper}
              {...parmss}
              style={{ position: "relative" }}
              // history={{
              //   key: "slide",
              // }}
              // touchMoveStopPropagation={true}clickable
              // touchStartForcePreventDefault={true}
              modules={[Navigation]}
              breakpoints={{
                740: {
                  slidesPerView: 3,
                  // spaceBetween: 20,
                },
                970: {
                  slidesPerView: 4,
                  // spaceBetween: 20,
                },
              }}
            >
              {data.map((data, index) => {
                return (
                  <SwiperSlide>
                    <div>
                      <SliderItemList data={data} />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            {/* </Slider> */}
            <ButtonPrev1
              ref={prevRef}

              // style={{ width: "100px", height: "100px", background: "blue" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MdKeyboardArrowLeft />{" "}
              </div>
            </ButtonPrev1>{" "}
            {/* <div
                ref={prevRef}
                style={{ width: "100px", height: "100px", background: "blue" }}
              ></div> */}
            <ButtonNext1 ref={nextRef}>
              {" "}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MdKeyboardArrowRight />
              </div>
            </ButtonNext1>
          </div>{" "}
        </section>
      </div>
    </>
  );
};
const ButtonPrev1 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 50%;
  left: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonNext1 = styled.div`
  position: absolute;
  z-index: 1;

  width: 35px;
  height: 35px;
  display: block;
  background: #fff;
  top: 50%;
  right: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;

export default CompanyNews;
