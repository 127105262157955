// import "../styles/search.css";

// import noData from "../assets/images/no_data.png";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useCallback, useRef, useEffect, useState } from "react";
import axios, { all } from "axios";
import main from "../../styles/main.module.css";
import SearchItem from "./SearchItem";
import { useSelector } from "react-redux";
const SearchDetail = () => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  //   const { word } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword1 = searchParams.get("keyword");

  const [searchList, setSearchList] = useState([]);
  const [searchList2, setSearchList2] = useState([]);
  const navigate = useNavigate();
  const allCnt = useRef(0);
  const brickCnt = useRef(0);
  const spikeCnt = useRef(0);
  const etcCnt = useRef(0);
  const evCnt = useRef(0);

  let keys = keyword1;
  let keyText = keys.split("");
  if (keyText.length > 10) {
    keys = keys.substring(0, 9) + "...";
  }

  const getSearch = async () => {
    try {
      const options = {
        method: "GET",
        // header: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json;charset=UTP-8",
        // },
        // params: {
        //
        // },
      };

      let dataNo = "";
      if (user) {
        dataNo = user.no;
      }
      await axios
        .get(process.env.REACT_APP_HOSTDONAME + `/api/main_select_search`, {
          params: {
            keyword: keyword1,
            no: dataNo,
          },
        })
        .then((res) => {
          setSearchList(res.data.messageinfo.message);
          setSearchList2(res.data.messageinfo.message_data2);
          allCnt.current =
            res.data.messageinfo.message.length +
            res.data.messageinfo.message_data2.length;
          // allCnt.current = res.data.messageinfo.message_data2[0].cnt;
        });
    } catch (e) {
      // console.log(e);
    }
  };
  useEffect(() => {
    getSearch();
  }, [keyword1]);
  const [searchValue, setSearchValue] = useState("");
  const changeSearch = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);
  const keyEvent = (e) => {
    if (searchValue === "") return;
    if (e.key === "Enter") {
      navigate(`/search?keyword=${searchValue}`);
      // setSearchValue("");
    }
  };

  const changeOrder = (e) => {
    navigate(`/search?keyword=${keyword1}`);
  };
  const changeMode = (modeValue) => {
    // console.log(modeValue);
    navigate(`/search?keyword=${keyword1}`);
  };
  return (
    <div className="common-wrap">
      <div className="common-inner-wrap">
        <div className={main.search_container}>
          <div className={main.search_wrap}>
            <div className={main.search_top}>
              <h2 className={main.search_title}>
                <span>'{keys}'</span> 검색결과 {allCnt.current}건
              </h2>
              <div className={main.search_input_box}>
                <div className={main.search_input_cont}>
                  <input
                    type="text"
                    placeholder="내용을 입력해주세요"
                    onChange={changeSearch}
                    value={searchValue}
                    onKeyPress={keyEvent}
                  />
                  <p className={main.search_xmark}>
                    {/* <FontAwesomeIcon icon={faCircleXmark} /> */}
                  </p>
                </div>

                <button
                  type="button"
                  onClick={() => {
                    if (searchValue === "") return;
                    navigate(`/search?keyword=${searchValue}`);
                    setSearchValue("");
                  }}
                >
                  검색
                </button>
              </div>
            </div>
            <div className={main.search_bottom}>
              {/* <nav className={main.search-menu-box}>
                  <ul>
                    {menus.map((li) => {
                      return (
                        <li
                          key={li.id}
                          className={
                            mode1 === li.mode
                              ? "search-menu-li active"
                              : "search-menu-li"
                          }
                          onClick={() => changeMode(li.mode)}
                        >
                          {li.text}
                          <span className={main.search-detail-cnt}>
                            {li.mode === "all" ? allCnt.current : null}
                            {li.mode === "spike" ? spikeCnt.current : null}
                            {li.mode === "brick" ? brickCnt.current : null}
                            {li.mode === "etc" ? etcCnt.current : null}
                            {li.mode === "ev" ? evCnt.current : null}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </nav> */}
              <div className={main.search_inner}>
                {/* <div className={main.search-order-box}>
                    <select onChange={changeOrder}>
                      <option
                        value="popular"
                        selected={order1 === "popular" ? true : false}
                      >
                        인기순
                      </option>
                      <option
                        value="recent"
                        selected={order1 === "recent" ? true : false}
                      >
                        최신순
                      </option>
                    </select>
                  </div> */}

                <div className={main.search_cont}>
                  <section>
                    {/* <div className={main.wrap}> */}
                    {searchList.length < 1 && searchList2.length < 1 && (
                      <NoData />
                    )}
                    {searchList.length > 0 &&
                      searchList.map((searchList) => (
                        <SearchItem
                          key={searchList.no}
                          id={searchList.no}
                          searchList={searchList}
                        />
                      ))}{" "}
                    {searchList2.length > 0 &&
                      searchList2.map((searchList) => (
                        <SearchItem
                          key={searchList.no}
                          id={searchList.no}
                          searchList={searchList}
                        />
                      ))}
                    {/* </div> */}
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchDetail;
const NoData = () => {
  return <>데이터가 존재하지 않습니다.</>;
};
