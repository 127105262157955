import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import close from "./../assets/images/close.png";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import AdminUserDetail from "../components/admin/detail/AdminUserDetail";
import WithDrawList from "../components/admin/WithDrawList";
import EndList from "../components/admin/EndList";

const AdminEndPage = ({ roomid, onClose }) => {
  return (
    <>
      <EndList />
    </>
  );
};

export default AdminEndPage;
