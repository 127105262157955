import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import close from "./../assets/images/close.png";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import AdminUserDetail from "../components/admin/detail/AdminUserDetail";
import NoticeList from "../components/admin/NoticeList";
import NoticeDetail from "../components/admin/NoticeDetail";
import NoticeWrite from "../components/admin/NoticeWrite";

const AdminNoticeWrite = ({ roomid, onClose }) => {
  return (
    <>
      <NoticeWrite />
    </>
  );
};

export default AdminNoticeWrite;
