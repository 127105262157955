import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
} from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SwiperCore, {
  Virtual,
  Navigation,
  Pagination,
  History,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import styles from "../../../styles/admin.module.css";
import Swal from "sweetalert2";
import { CommonAxios } from "../../CommonAxios";
import { textReplace } from "../../../lib/textReplace";
import axios from "axios";
import { ramdomASCII } from "../../../lib/radomASCII";
function DetailsCustomTable({
  columns,
  data,
  options,
  setOptions,
  options2,
  setOptions2,
  arrayList,
  setArrayList,
  setSendList,
  banSw,
  setBanSw,
  categoryNo,
  setCategoryNo,
  cateogry,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    selectedFlatRows,
    prepareRow,
    //   canPreviousPage,
    //   canNextPage,
    pageOptions,
    pageCount, // 총 페이지
    gotoPage,
    //   nextPage,
    //   previousPage,
    //   setPageSize,
    state: { pageIndex, pageSize }, // pageIndex : 현재 페이지( 0 ~ 총페이지-1 ex) 1페이지 : 0 )
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // getToggleAllPageRowsSelectedProps : 페이지에 보여지는 row 전체 선택
          // getToggleAllRowsSelectedProps : 모든 row 전체 선택
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  //체크박스 옵션
  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            style={{ width: "32px" }}
          />
        </>
      );
    }
  );

  const btn_send = () => {
    // console.log(selectedFlatRows);
  };

  const [versionCheck, setVersionCheck] = useState("p");

  const changeValue = (e) => {
    setVersionCheck(e.target.value);
  };

  const [text1, setText1] = useState("");
  const [text11, setText11] = useState("");

  const [text2, setText2] = useState("");
  const [text22, setText22] = useState("");
  const [text3, setText3] = useState("");
  const [text33, setText33] = useState("");
  const [text4, setText4] = useState("");
  const [text5, setText5] = useState("");
  const [text44, setText44] = useState("");
  const [text55, setText55] = useState("");
  const [text6, setText6] = useState("");
  const [text66, setText66] = useState("");
  const [text7, setText7] = useState("");
  const [text77, setText77] = useState("");
  const [text8, setText8] = useState("");
  const [text88, setText88] = useState("");
  const [text9, setText9] = useState("");
  const [text99, setText99] = useState("");
  const [text0, setText0] = useState("");
  const [text00, setText00] = useState("");
  const [back, setBack] = useState("");
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  useEffect(() => {
    if (data.length > 0) {
      if (options === 5) {
        if (data[0].f_detail_title) {
          setText1(data[0].f_detail_title);
        } else {
          setText1("");
        }
        if (data[0].f_detail_sub_title) {
          setText2(data[0].f_detail_sub_title);
        } else {
          setText2("");
        }
        if (data[0].f_detail_subject_1) {
          setText3(data[0].f_detail_subject_1);
        } else {
          setText3("");
        }
        if (data[0].f_detail_subject_2) {
          setText4(data[0].f_detail_subject_2);
        } else {
          setText4("");
        }
        if (data[0].f_detail_subject_3) {
          setText5(data[0].f_detail_subject_3);
        } else {
          setText5("");
        }
        if (data[0].details_back_img) {
          setBack(data[0].details_back_img);
        } else {
          setBack("");
        }
      }
    }
  }, [banSw]);
  const onText1 = useCallback((e) => {
    setText1(e.target.value);
    setText11(e.target.value);
  }, []);
  const onText2 = useCallback((e) => {
    setText2(e.target.value);
    setText22(e.target.value);
  }, []);
  const onText3 = useCallback((e) => {
    setText3(e.target.value);
    setText33(e.target.value);
  }, []);

  const onText4 = useCallback((e) => {
    setText4(e.target.value);
    setText44(e.target.value);
  }, []);
  const onText5 = useCallback((e) => {
    setText5(e.target.value);
    setText55(e.target.value);
  }, []);
  const onText6 = useCallback((e) => {
    setText6(e.target.value);
    setText66(e.target.value);
  }, []);
  const onText7 = useCallback((e) => {
    setText7(e.target.value);
    setText77(e.target.value);
  }, []);
  const onText8 = useCallback((e) => {
    setText8(e.target.value);
    setText88(e.target.value);
  }, []);
  const onText9 = useCallback((e) => {
    setText9(e.target.value);
    setText99(e.target.value);
  }, []);
  const onText0 = useCallback((e) => {
    setText0(e.target.value);
    setText00(e.target.value);
  }, []);

  const [imageFile, setImageFile] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const [imageFile3, setImageFile3] = useState("");
  const [imageFile4, setImageFile4] = useState("");

  const [imageFileN, setImageFileN] = useState("");
  const [imageFileN2, setImageFileN2] = useState("");
  const [imageFileN3, setImageFileN3] = useState("");
  const [imageFileN4, setImageFileN4] = useState("");

  const [imageFileNBefore, setImageFileNBefore] = useState("");
  const [imageFileN2Before, setImageFileN2Before] = useState("");
  const [imageFileN3Before, setImageFileN3Before] = useState("");
  const [imageFileN4Before, setImageFileN4Before] = useState("");

  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN(`${result}.${imgExtension[1]}`);
 
    setImageFile(file);
  };

  const inpChange2 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN2(`${result}.${imgExtension[1]}`);
   
    setImageFile2(file);
  };

  const inpChange3 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN3(`${result}.${imgExtension[1]}`);
    // if (versionCheck === "p") {
    //   setImageFileN(`banner_${data[0].no}.${imgExtension[1]}`);
    // }

    // if (versionCheck === "m") {
    //   setImageFileN(`banner_m_${data[0].no}.${imgExtension[1]}`);
    // }

    setImageFile3(file);
  };

  const inpChange4 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN4(`${result}.${imgExtension[1]}`);
    // if (versionCheck === "p") {
    //   setImageFileN(`banner_${data[0].no}.${imgExtension[1]}`);
    // }

    // if (versionCheck === "m") {
    //   setImageFileN(`banner_m_${data[0].no}.${imgExtension[1]}`);
    // }

    setImageFile4(file);
  };

  useEffect(() => {
    preview(); // 미리보기
  }, [imageFile]);
  useEffect(() => {
    preview2(); // 미리보기
  }, [imageFile2]);
  useEffect(() => {
    preview3(); // 미리보기
  }, [imageFile3]);
  useEffect(() => {
    preview4(); // 미리보기
  }, [imageFile4]);

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };
  const preview2 = () => {
    if (!imageFile2) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg2(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile2[0]);
  };
  const preview3 = () => {
    if (!imageFile3) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg3(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile3[0]);
  };
  const preview4 = () => {
    if (!imageFile4) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg4(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile4[0]);
  };
  const [bgImg, setBgImg] = useState("");
  const [bgImg2, setBgImg2] = useState("");
  const [bgImg3, setBgImg3] = useState("");
  const [bgImg4, setBgImg4] = useState("");
  const textReset = () => {
    setText1("");
    setText11("");
    setText2("");
    setText22("");
    setText3("");
    setText33("");
    setText4("");
    setText44("");
    setText5("");
    setText55("");
    setText6("");
    setText66("");
    setText7("");
    setText77("");
    setText8("");
    setText88("");
    setText9("");
    setText99("");
    setText0("");
    setText00("");
    setBgImg("");
    setBgImg2("");
    setBgImg3("");
    setBgImg4("");
  };
  const btn_text_save = () => {
    Swal.fire({
      title: "변경 진행",
      text: "위 문구로 변경하시겠습니까?",

      confirmButtonText: "확인",
      cancelButtonText: "취소",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let string = "";
        let tb = "";
        if (options === 1) {
          if (!text11 && !text22 && !text33 && !text44 && !text55 && !text66) {
            return Swal.fire({
              // title: "",
              text: "변경할 문구가 없습니다.",

              confirmButtonText: "확인",
            });
          }
          tb = " meta_detail_one ";
          if (text11) {
            string += `o_detail_title = '${textReplace(text11)}',`;
          }

          if (text22) {
            string += `o_detail_sub_title = '${textReplace(text22)}',`;
          }

          if (text33) {
            string += `o_detail_subject_1 = '${textReplace(text33)}',`;
          }
          if (text44) {
            string += `o_detail_subject_2 = '${textReplace(text44)}',`;
          }
          if (text55) {
            string += `o_detail_subject_3 = '${textReplace(text55)}',`;
          }
          if (text66) {
            string += `o_detail_subject_4 = '${textReplace(text66)}',`;
          }
        }
        if (options === 2) {
          if (
            !text11 &&
            !text22 &&
            !text33 &&
            !text44 &&
            !text55 &&
            !text66 &&
            !text77 &&
            !text88 &&
            !text99 &&
            !text00
          ) {
            return Swal.fire({
              // title: "",
              text: "변경할 문구가 없습니다.",

              confirmButtonText: "확인",
            });
          }
          tb = " meta_detail_two ";
          if (text11) {
            string += `w_detail_title = '${textReplace(text11)}',`;
          }

          if (text22) {
            string += `w_detail_sub_title = '${textReplace(text22)}',`;
          }

          if (text33) {
            string += `w_detail_subject_1 = '${textReplace(text33)}',`;
          }
          if (text44) {
            string += `w_detail_content_1 = '${textReplace(text44)}',`;
          }
          if (text55) {
            string += `w_detail_subject_2 = '${textReplace(text55)}',`;
          }
          if (text66) {
            string += `w_detail_content_2 = '${textReplace(text66)}',`;
          }
          if (text77) {
            string += `w_detail_subject_3 = '${textReplace(text77)}',`;
          }
          if (text88) {
            string += `w_detail_content_3 = '${textReplace(text88)}',`;
          }
          if (text99) {
            string += `w_detail_subject_4 = '${textReplace(text99)}',`;
          }
          if (text00) {
            string += `w_detail_content_4 = '${textReplace(text00)}',`;
          }
        }
        if (options === 3) {
          if (!text11 && !text22 && !text33 && !text44 && !text55 && !text66) {
            return Swal.fire({
              // title: "",
              text: "변경할 문구가 없습니다.",

              confirmButtonText: "확인",
            });
          }
          tb = " meta_detail_three ";
          if (text11) {
            string += `h_detail_title = '${textReplace(text11)}',`;
          }

          if (text22) {
            string += `h_detail_sub_title = '${textReplace(text22)}',`;
          }

          if (text33) {
            string += `h_detail_subject_1 = '${textReplace(text33)}',`;
          }
          if (text44) {
            string += `h_detail_content_1 = '${textReplace(text44)}',`;
          }
          if (text55) {
            string += `h_detail_subject_2 = '${textReplace(text55)}',`;
          }
          if (text66) {
            string += `h_detail_content_2 = '${textReplace(text66)}',`;
          }
        }

        if (options === 4) {
          if (!text11 && !text22 && !text33 && !text44 && !text55) {
            return Swal.fire({
              // title: "",
              text: "변경할 문구가 없습니다.",

              confirmButtonText: "확인",
            });
          }
          tb = " meta_detail_four ";
          if (text11) {
            string += `f_detail_title = '${textReplace(text11)}',`;
          }

          if (text22) {
            string += `f_detail_sub_title = '${textReplace(text22)}',`;
          }

          if (text33) {
            string += `f_detail_subject_1 = '${textReplace(text33)}',`;
          }
          if (text44) {
            string += `f_detail_subject_2 = '${textReplace(text44)}',`;
          }
          if (text55) {
            string += `f_detail_subject_3 = '${textReplace(text55)}',`;
          }
        }

        let sendData = {
          no: data[0].no,
          st: string.slice(0, -1),
          table: tb,
        };

        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/admin_update_detail_text",
          sendData,
          function (result) {
            if (result.messageinfo.state == "ok") {
              let sendData2 = {
                type: options,
                no: categoryNo,
              };
              CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin_select_details",
                sendData2,
                function (result) {
                  if (result.messageinfo.state == "ok") {
                    Swal.fire({
                      title: "변경 완료",
                      // text: "변경할 문구가 없습니다.",

                      confirmButtonText: "확인",
                    });
                    setArrayList(result.messageinfo.message);
                    setBanSw(true);
                  } else {
                    setBanSw(true);
                  }
                }
              );

              // setArrayList(result.messageinfo.message);
              // setBanSw(true);
            } else {
              // setBanSw(true);
            }
          }
        );
      }
    });
  };
  const [imgSw, setImgSw] = useState(false);
  const btn_img_save = () => {
    Swal.fire({
      title: "변경 진행",
      text: "위 이미지로 변경하시겠습니까?",

      confirmButtonText: "확인",
      cancelButtonText: "취소",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (!imageFile[0] && !imageFile2[0]) {
          return Swal.fire({
            // title: "",
            text: "변경할 이미지가 없습니다.",

            confirmButtonText: "확인",
          });
        }

        if (options === 1) {
          let imgIf = "";
          let beforImg = "";
          const formData = new FormData();
          formData.append("no", data[0].no);
          // formData.append("filename", imageFileN);

          // formData.append("fileBefore", beforImg);
          formData.append("folder", "detail_one");
          // formData.append("file", imageFile[0]);

          if (imageFile) {
            formData.append("filename", imageFileN);
            formData.append("fileBefore", data[0].o_detail_img_1);
            formData.append("file", imageFile[0]);
            imgIf += `o_detail_img_1 = '${imageFileN}',`;
          }

          if (imageFile2) {
            formData.append("filename", imageFileN2);
            formData.append("fileBefore", data[0].o_detail_img_2);
            formData.append("file", imageFile2[0]);
            imgIf += `o_detail_img_2 = '${imageFileN2}',`;
          }
          if (imageFile3) {
            formData.append("filename", imageFileN3);
            formData.append("fileBefore", data[0].o_detail_img_3);
            formData.append("file", imageFile3[0]);
            imgIf += `o_detail_img_3 = '${imageFileN3}',`;
          }
          if (imageFile4) {
            formData.append("filename", imageFileN4);
            formData.append("fileBefore", data[0].o_detail_img_4);
            formData.append("file", imageFile4[0]);
            imgIf += `o_detail_img_4 = '${imageFileN4}',`;
          }
          formData.append("fileif", imgIf.slice(0, -1));
          axios
            .post(
              process.env.REACT_APP_HOSTDONAME + "/api/admin_upload_details",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.messageinfo.state === "ok") {
                let sendData2 = {
                  type: options,
                  no: categoryNo,
                };
                CommonAxios(
                  process.env.REACT_APP_HOSTDONAME +
                    "/api/admin_select_details",
                  sendData2,
                  function (result) {
                    if (result.messageinfo.state == "ok") {
                      Swal.fire({
                        title: "변경 완료",
                        // text: "변경할 문구가 없습니다.",

                        confirmButtonText: "확인",
                      });
                      setArrayList(result.messageinfo.message);
                      setBanSw(true);
                    } else {
                      setBanSw(true);
                    }
                  }
                );
                // setUploadImage(
                //   uploadImage.concat({
                //     id: uploadImageRef.current++,
                //     imageUrl: `/classImage/${classinfo}/${chapterNum}/${path}/${fileNum}`,
                //     imagename: fileNum,
                //   })
                // );
                // setImageText(imageText + fileNum + ",");
                // // 응답 처리
                // setSpinnerView(false);
              }
            })
            .catch((error) => {
              // 예외 처리
            });
        }

        if (options === 4) {
          let imgIf = "";
          let beforImg = "";
          const formData = new FormData();
          formData.append("no", data[0].no);
          // formData.append("filename", imageFileN);

          // formData.append("fileBefore", beforImg);
          formData.append("folder", "detail_four");
          // formData.append("file", imageFile[0]);

          if (imageFile) {
            formData.append("filename", imageFileN);
            formData.append("fileBefore", data[0].f_detail_img_1);
            formData.append("file", imageFile[0]);
            imgIf += `f_detail_img_1 = '${imageFileN}',`;
          }

          if (imageFile2) {
            formData.append("filename", imageFileN2);
            formData.append("fileBefore", data[0].f_detail_img_2);
            formData.append("file", imageFile2[0]);
            imgIf += `f_detail_img_2 = '${imageFileN2}',`;
          }
          if (imageFile3) {
            formData.append("filename", imageFileN3);
            formData.append("fileBefore", data[0].f_detail_img_3);
            formData.append("file", imageFile3[0]);
            imgIf += `f_detail_img_3 = '${imageFileN3}',`;
          }
          formData.append("fileif", imgIf.slice(0, -1));
          axios
            .post(
              process.env.REACT_APP_HOSTDONAME + "/api/admin_upload_details1",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.messageinfo.state === "ok") {
                let sendData2 = {
                  type: options,
                  no: categoryNo,
                };
                CommonAxios(
                  process.env.REACT_APP_HOSTDONAME +
                    "/api/admin_select_details",
                  sendData2,
                  function (result) {
                    if (result.messageinfo.state == "ok") {
                      Swal.fire({
                        title: "변경 완료",
                        // text: "변경할 문구가 없습니다.",

                        confirmButtonText: "확인",
                      });
                      setArrayList(result.messageinfo.message);
                      setBanSw(true);
                    } else {
                      setBanSw(true);
                    }
                  }
                );
                // setUploadImage(
                //   uploadImage.concat({
                //     id: uploadImageRef.current++,
                //     imageUrl: `/classImage/${classinfo}/${chapterNum}/${path}/${fileNum}`,
                //     imagename: fileNum,
                //   })
                // );
                // setImageText(imageText + fileNum + ",");
                // // 응답 처리
                // setSpinnerView(false);
              }
            })
            .catch((error) => {
              // 예외 처리
            });
        }
        if (options === 5) {
          let imgIf = "";
          let beforImg = "";
          const formData = new FormData();
          formData.append("no", data[0].no);
          // formData.append("filename", imageFileN);

          // formData.append("fileBefore", beforImg);
          formData.append("folder", "details");
          // formData.append("file", imageFile[0]);

          if (imageFile) {
            formData.append("filename", imageFileN);
            formData.append("fileBefore", data[0].details_back_img);
            formData.append("file", imageFile[0]);
            imgIf += `details_back_img = '${imageFileN}',`;
          }
          if (imageFile2) {
            formData.append("filename", imageFileN2);
            formData.append("fileBefore", data[0].details_img);
            formData.append("file", imageFile2[0]);
            imgIf += `details_img = '${imageFileN2}',`;
          }
          formData.append("fileif", imgIf.slice(0, -1));
          axios
            .post(
              process.env.REACT_APP_HOSTDONAME + "/api/admin_upload_details",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.messageinfo.state === "ok") {
                let sendData2 = {
                  type: options,
                  no: categoryNo,
                };
                CommonAxios(
                  process.env.REACT_APP_HOSTDONAME +
                    "/api/admin_select_details",
                  sendData2,
                  function (result) {
                    if (result.messageinfo.state == "ok") {
                      Swal.fire({
                        title: "변경 완료",
                        // text: "변경할 문구가 없습니다.",

                        confirmButtonText: "확인",
                      });
                      setArrayList(result.messageinfo.message);
                      setBanSw(true);
                    } else {
                      setBanSw(true);
                    }
                  }
                );
                // setUploadImage(
                //   uploadImage.concat({
                //     id: uploadImageRef.current++,
                //     imageUrl: `/classImage/${classinfo}/${chapterNum}/${path}/${fileNum}`,
                //     imagename: fileNum,
                //   })
                // );
                // setImageText(imageText + fileNum + ",");
                // // 응답 처리
                // setSpinnerView(false);
              }
            })
            .catch((error) => {
              // 예외 처리
            });
        }
      }
    });
  };

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  const change_select = (e) => {
    textReset();
    setCategoryNo(e.target.value);
  };
  return (
    <>
      <div className={styles.admin_details_page_top}>
        <label>카테고리 선택</label>
        <select onChange={change_select}>
          {cateogry &&
            cateogry.map((data) => {
              return <option value={data.no}>{data.category_name}</option>;
            })}
        </select>
      </div>
      <div className={styles.user_header} style={{ marginBottom: "4px" }}>
        <div className={styles.user_option}>
          {/* <div
            className={
              options == 1 ? `${styles.all} ${styles.clicked}` : styles.all
            }
            onClick={() => {
              setOptions(1);
              setOptions2(1);
              setVersionCheck("p");
              textReset();
            }}
          >
            첫번째
          </div>
          <div
            className={
              options == 2
                ? `${styles.noraml} ${styles.clicked}`
                : styles.noraml
            }
            onClick={() => {
              setOptions(2);
              setOptions2(4);
              setVersionCheck("p");
              textReset();
            }}
          >
            두번째
          </div>
          <div
            className={
              options == 3
                ? `${styles.develop} ${styles.clicked}`
                : styles.develop
            }
            onClick={() => {
              setOptions(3);
              setOptions2(8);
              setVersionCheck("p");
              textReset();
            }}
          >
            세번째
          </div>{" "}
          <div
            className={
              options == 4
                ? `${styles.develop} ${styles.clicked}`
                : styles.develop
            }
            onClick={() => {
              setOptions(4);
              setOptions2(11);
              setVersionCheck("p");
              textReset();
            }}
          >
            네번째
          </div>{" "} */}
          <div
            className={
              options == 5
                ? `${styles.develop} ${styles.clicked}`
                : styles.develop
            }
            onClick={() => {
              setOptions(5);
              // setOptions2(11);
              // setVersionCheck("p");
              textReset();
            }}
          >
            배경 및 상세이미지
          </div>{" "}
        </div>
      </div>{" "}
      <div className={styles.admin_details_view}>
        <div className={styles.admin_details_inner}>
          {banSw && options === 5 && (
            <>
              {" "}
              <div className={styles.admin_details_content}>
                {bgImg && (
                  <div className={styles.visual_back}>
                    <div className={styles.visual_front}>
                      <div className={styles.visual}>
                        <div
                          className={styles.visual_list}
                          style={{ overflow: "hidden" }}
                        >
                          <div
                            className={styles.visual_item}
                            style={{
                              backgroundImage: bgImg
                                ? `url(${bgImg})`
                                : `url(/details/${data[0].no}/${data[0].details_back_img})`,
                            }}
                          >
                            {" "}
                            <div
                              className="common-inner-wrap"
                              style={{ width: "613.33333333336px" }}
                            >
                              <div className={styles.visual_box}>
                                <span className={styles.visual_sub}>-</span>
                                <div className={styles.visual_title}>-</div>

                                <div className={styles.visual_inner}>
                                  <ul>
                                    <li>
                                      <span>수익률</span>
                                      <div className={styles.num}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="2.5"
                                          stroke="currentColor"
                                          aria-hidden="true"
                                          width="14"
                                          color="#006E4D"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
                                          ></path>
                                        </svg>
                                        0%
                                      </div>
                                    </li>
                                    <li>
                                      <span>가입기간</span>
                                      <em className={styles.num}> 0개월</em>
                                    </li>
                                  </ul>

                                  <div className={styles.line_wrap}>
                                    <progress
                                      className={styles.progress}
                                      value={0}
                                      min="0"
                                      max="100"
                                    ></progress>
                                  </div>

                                  <div className={styles.line_text_box}>
                                    <span
                                      className={`${styles.per} ${styles.n1}`}
                                    >
                                      모집률 0 %
                                    </span>
                                    <span
                                      className={`${styles.per} ${styles.n2}`}
                                    >
                                      0/0원
                                    </span>
                                  </div>
                                </div>
                                <div className={styles.visual_btn}>
                                  <a className={styles.visual_go}>-</a>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                        <div className={styles.visual_dots}></div>
                      </div>
                      {/* </div> */}
                      {/* </div> */}
                    </div>{" "}
                  </div>
                )}
                {!bgImg && data[0].details_back_img && (
                  <div className={styles.visual_back}>
                    <div className={styles.visual_front}>
                      <div className={styles.visual}>
                        <div
                          className={styles.visual_list}
                          style={{ overflow: "hidden" }}
                        >
                          <div
                            className={styles.visual_item}
                            style={{
                              backgroundImage: bgImg
                                ? `url(${bgImg})`
                                : `url(/details/${data[0].no}/${data[0].details_back_img})`,
                            }}
                          >
                            {" "}
                            <div
                              className="common-inner-wrap"
                              style={{ width: "613.33333333336px" }}
                            >
                              <div className={styles.visual_box}>
                                <span className={styles.visual_sub}>-</span>
                                <div className={styles.visual_title}>-</div>

                                <div className={styles.visual_inner}>
                                  <ul>
                                    <li>
                                      <span>수익률</span>
                                      <div className={styles.num}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="2.5"
                                          stroke="currentColor"
                                          aria-hidden="true"
                                          width="14"
                                          color="#006E4D"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
                                          ></path>
                                        </svg>
                                        0%
                                      </div>
                                    </li>
                                    <li>
                                      <span>가입기간</span>
                                      <em className={styles.num}> 0개월</em>
                                    </li>
                                  </ul>

                                  <div className={styles.line_wrap}>
                                    <progress
                                      className={styles.progress}
                                      value={0}
                                      min="0"
                                      max="100"
                                    ></progress>
                                  </div>

                                  <div className={styles.line_text_box}>
                                    <span
                                      className={`${styles.per} ${styles.n1}`}
                                    >
                                      모집률 0 %
                                    </span>
                                    <span
                                      className={`${styles.per} ${styles.n2}`}
                                    >
                                      0/0원
                                    </span>
                                  </div>
                                </div>
                                <div className={styles.visual_btn}>
                                  <a className={styles.visual_go}>-</a>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                        <div className={styles.visual_dots}></div>
                      </div>
                      {/* </div> */}
                      {/* </div> */}
                    </div>{" "}
                  </div>
                )}

                {!data[0].details_back_img && (
                  <div
                    style={{
                      width: "100%",
                      height: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>업로드된 배경 이미지가 없습니다.</span>
                  </div>
                )}
                {bgImg2 && (
                  <div
                    className="common-inner-wrap"
                    style={{ width: "613.33333333336px" }}
                  >
                    <div>
                      {!bgImg2 && (
                        <img
                          src={`/details/${data[0].no}/${data[0].details_img}`}
                        />
                      )}{" "}
                      {bgImg2 && <img src={`${bgImg2}`} />}
                      {/* <div
                      style={{
                        width: "100%",
                        backgroundImage: `url(/details/${data[0].no}/${data[0].details_img})`,
                        backgroundSize: "100%",
                      }}
                    >
                      {" "}
                      상세 페이지
                    </div> */}
                    </div>
                  </div>
                )}
                {!bgImg2 && data[0].details_img && (
                  <div
                    className="common-inner-wrap"
                    style={{ width: "613.33333333336px" }}
                  >
                    <div>
                      {!bgImg2 && (
                        <img
                          src={`/details/${data[0].no}/${data[0].details_img}`}
                        />
                      )}{" "}
                      {bgImg2 && <img src={`${bgImg2}`} />}
                      {/* <div
                      style={{
                        width: "100%",
                        backgroundImage: `url(/details/${data[0].no}/${data[0].details_img})`,
                        backgroundSize: "100%",
                      }}
                    >
                      {" "}
                      상세 페이지
                    </div> */}
                    </div>
                  </div>
                )}
                {!data[0].details_img && (
                  <div
                    style={{
                      width: "100%",
                      height: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>업로드된 상세 이미지가 없습니다.</span>
                  </div>
                )}
              </div>
              <div className={styles.admin_details_change}>
                <div className={styles.admin_details_right}>
                  <h4>이미지 업로드</h4>{" "}
                  <div className={styles.admin_bnner_change_in}>
                    <div className={styles.admin_details_img_box}>
                      <input
                        type="file"
                        onChange={inpChange}
                        id="abc"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        상품 배경 이미지 업로드
                      </label>
                      <input
                        type="file"
                        onChange={inpChange2}
                        id="abc1"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc1" style={{ cursor: "pointer" }}>
                        상세페이지 이미지 업로드
                      </label>
                      <span className={styles.admin_details_img_box_warring}>
                        상세페이지에 올라갈 이미지는 width 1280px 폭에
                        맞춰집니다.
                        <br /> ex) 680px 이미지 업로드시 1280px으로 자동 변환
                      </span>
                    </div>{" "}
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_img_save}>저장</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}{" "}
        </div>
      </div>
    </>
  );
}

export default DetailsCustomTable;
