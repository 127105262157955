import React, { useCallback, useState, useEffect, useRef } from "react";
import { CommonAxios } from "./../../CommonAxios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import Moment from "react-moment";
import styles from "./../../../styles/admin.module.css";
import { useParams } from "react-router-dom";
import { textReplace } from "../../../lib/textReplace";
import { FcEditImage } from "react-icons/fc";
import { textOverCut, getTextTotal } from "../../../lib/stringLenghts";
import { wonComma } from "../../../lib/wonComma";
const ProductWriteCom = () => {
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  const imgNameRef = useRef(); //이미지 파일 이름 REF
  const [imageFile, setImageFile] = useState(""); //이미지 속성정보
  const [getimage, setGetImage] = useState(""); //render.result 값
  const navigate = useNavigate();
  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 1024 * 1;

    if (file[0] === undefined) {
      return;
    }

    // if (
    //   imgExtension[1] !== "jpg" ||
    //   imgExtension[1] !== "png" ||
    //   imgExtension[1] !== "jpeg" ||
    //   imgExtension[1] !== "gif"
    // ) {
    //   Swal.fire({
    //     title: "이미지 업로드 불가",
    //     text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",
    //     icon: "error",
    //     confirmButtonText: "확인",
    //     confirmButtonColor: "#FF0000",
    //   });
    //   return;
    // }
    if (file[0].size >= maxSize) {
      //이미지 사이즈가 500k보다 크다면,
      setImageFile(imageFile);
      Swal.fire({
        title: "이미지 용량 초과",
        html: `1MB 사진을 올려주세요.`,

        confirmButtonText: "확인",
      });
      return;
    } else {
      setImageFile(file);
    }
  };

  useEffect(() => {
    preview(); // 미리보기
  });

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      imageRef.current.src = reader.result;
      setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };
  const newLocal = false;

  const [openValue, setOpenValue] = useState("Y");
  const change_open = (data) => {
    setOpenValue(data);
  };
  const [recru, setRecru] = useState("N");
  const change_recruitment = (data) => {
    setRecru(data);
  };

  const [titleByte, setTitleByte] = useState(0);
  const [titleValue, setTitleValue] = useState("");
  const change_title = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 20);
    let text = textOverCut(e.target.value, 20);

    setTitleValue(text);
    setTitleByte(byte.byte);
  }, []);
  const [contentByte, setContentByte] = useState(0);
  const [contentValue, setContentValue] = useState("");
  const change_content = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 40);
    let text = textOverCut(e.target.value, 40);

    setContentValue(text);
    setContentByte(byte.byte);
  }, []);
  const [amountValue, setAmountValue] = useState("");
  const change_amount = useCallback((e) => {
    setAmountValue(wonComma(e.target.value.replace(/[^\d]+/g, "")));
  }, []);

  const [dateValue, setDateValue] = useState("");
  const changeDate = useCallback((e) => {
    setDateValue(e.target.value);
  }, []);
  const [comByte, setComByte] = useState(0);
  const [comValue, setComValue] = useState("");
  const changeCom = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 40);
    let text = textOverCut(e.target.value, 40);

    setComValue(text);
    setComByte(byte.byte);
  }, []);

  const [linkValue, setLinkValue] = useState("");
  const change_link = useCallback((e) => {
    setLinkValue(e.target.value);
  }, []);
  const [applyValue, setApplyValue] = useState("N");
  const change_apply = (data) => {
    if (data === "N") {
      setLinkValue("");
    }
    setApplyValue(data);
  };
  const [cateValue, setCateValue] = useState("");
  const change_cate = useCallback((e) => {
    setCateValue(e.target.value);
  }, []);
  const [rateValue, setRateValue] = useState("");
  const change_rate = useCallback((e) => {
    setRateValue(e.target.value);
  }, []);
  const [joinValue, setJoinValue] = useState("");
  const change_join = useCallback((e) => {
    setJoinValue(e.target.value);
  }, []);

  // 상품 등록
  const click_product_reg = () => {
    if (
      !imageFile ||
      !titleValue ||
      // !contentValue ||
      !amountValue ||
      !rateValue ||
      !joinValue ||
      !dateValue
    ) {
      return Swal.fire({
        title: "상품 등록 실패",
        html: `빈칸을 채워주세요.`,

        confirmButtonText: "확인",
      });
    }
    if (
      !RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/).test(
        dateValue
      )
    ) {
      return Swal.fire({
        title: "상품 등록 실패",
        html: `YYYY-MM-DD 형식으로 적어주세요.`,

        confirmButtonText: "확인",
      });
    }
    if (
      !/[0-9]|-[0-9]/g.test(Number(rateValue)) ||
      !/[0-9]/g.test(Number(joinValue))
    ) {
      return Swal.fire({
        title: "상품 등록 실패",
        html: `수익률과 가입기간은 숫자로 적어주세요.`,

        confirmButtonText: "확인",
      });
    }

    let sendData = {
      pro_open_yn: openValue,
      pro_end_yn: recru,
      pro_title: textReplace(titleValue),
      // pro_content: textReplace(contentValue),
      pro_content: "",
      pro_target_amount: amountValue.replace(/[^\d]+/g, ""),
      pro_link: textReplace(linkValue),
      pro_link_yn: applyValue,
      pro_category: cateValue,
      pro_rate: rateValue,
      pro_join: joinValue,
      dateValue: moment(dateValue).format("YYYY-MM-DD 23:59:59"),
      pro_admin_end_yn: recru === "Y" ? "Y" : "N",
      filename: "",
      image: "",
      exp: "",
      pro_company: "",
    };

    if (imageFile !== "") {
      var file_info = imageFile[0].name.split(".");
      var file_name = `${Math.floor(Math.random() * 100000)}.${file_info[1]}`;
      sendData.filename = file_name;
      sendData.exp = file_info[1];
      sendData.image = `${getimage}file_name:${file_name}file_name:file_name:/userProfile/`;
    }

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_insert_product",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          Swal.fire({
            title: `등록 완료`,

            confirmButtonText: "확인",
          }).then((result) => {
            navigate("/admin/product");
          });
        }
      }
    );
  };

  const [categoryData, setCategoryData] = useState([]);
  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_product_category",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setCategoryData(result.messageinfo.message);
          if (result.messageinfo.message.length > 0) {
            setCateValue(result.messageinfo.message[0].no);
          }
        }
      }
    );
  }, []);
  return (
    <>
      <div className={styles.product_write_wrap}>
        <div className={styles.product_write_inner}>
          <div className={styles.product_write_title}>
            <h4>투자 상품 등록</h4>
            <div className={styles.product_write_button}>
              <button onClick={click_product_reg}>등록</button>
            </div>
          </div>
          <div className={styles.product_write_content}>
            <div className={styles.proudct_write_left}>
              <div className={`${styles.product_write_step_img}`}>
                <label className={`${styles.product_write_sub}`}>
                  대표 이미지 설정
                </label>
                <div className={styles.product_write_img_div}>
                  {imageFile === "" ? (
                    <div>
                      <FcEditImage />
                    </div>
                  ) : (
                    <img src="" ref={imageRef} />
                  )}
                </div>

                <div className={styles.product_write_img_button}>
                  <label className={styles.product_write_file}>
                    <input
                      type="file"
                      className={styles.product_write_file_inp}
                      accept="image/*"
                      onChange={inpChange}
                    />
                    <span>이미지 업로드</span>
                  </label>
                </div>
              </div>{" "}
              <span>322px x 183px 이미지 사용</span>
              <div className={styles.product_check}>
                {/* <div className={styles.product_open_box}>
                  <h4>공개 여부</h4>
                  <label>
                    <input
                      type="radio"
                      name="open"
                      value="Y"
                      onChange={() => change_open("Y")}
                      checked={openValue === "Y" ? true : false}
                    />{" "}
                    공개
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="open"
                      value="N"
                      onChange={() => change_open("N")}
                      checked={openValue === "N" ? true : false}
                    />{" "}
                    비공개
                  </label>
                </div> */}
                <div className={styles.product_recruitment_box}>
                  <h4>모집 진행 여부</h4>
                  <label>
                    <input
                      type="radio"
                      name="make"
                      value="C"
                      onChange={() => change_recruitment("C")}
                      checked={recru === "C" ? true : false}
                    />{" "}
                    예정
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="make"
                      value="N"
                      onChange={() => change_recruitment("N")}
                      checked={recru === "N" ? true : false}
                    />{" "}
                    진행
                  </label>{" "}
                  <label>
                    <input
                      type="radio"
                      name="make"
                      value="Y"
                      onChange={() => change_recruitment("Y")}
                      checked={recru === "Y" ? true : false}
                    />{" "}
                    마감
                  </label>
                </div>
              </div>
            </div>
            <div className={styles.proudct_write_right}>
              <div className={`${styles.product_write_step}`}>
                <label className={`${styles.product_write_sub}`}>상품명</label>
                <div className={`${styles.product_write_cont}`}>
                  <div className={`${styles.product_write_inpbox}`}>
                    <input
                      type="text"
                      onChange={change_title}
                      value={titleValue}
                    />
                    <span>{titleByte}/20</span>
                  </div>
                </div>
              </div>
              {/* <div className={`${styles.product_write_step}`}>
                <label className={`${styles.product_write_sub}`}>
                  상품설명
                </label>
                <div className={`${styles.product_write_cont}`}>
                  <div className={`${styles.product_write_inpbox}`}>
                    <input
                      type="text"
                      onChange={change_content}
                      value={contentValue}
                    />{" "}
                    <span>{contentByte}/40</span>
                  </div>
                </div>
              </div> */}
              <div className={`${styles.product_write_step}`}>
                {" "}
                <label className={`${styles.product_write_sub}`}>
                  목표금액
                </label>{" "}
                <div className={`${styles.product_write_cont}`}>
                  {" "}
                  <div className={`${styles.product_write_inpbox}`}>
                    {" "}
                    <input
                      type="text"
                      onChange={change_amount}
                      value={amountValue}
                    />
                  </div>
                </div>
              </div>{" "}
              <div className={`${styles.product_write_step}`}>
                {" "}
                <label className={`${styles.product_write_sub}`}>
                  투자종료날짜
                </label>{" "}
                <div className={`${styles.product_write_cont}`}>
                  {" "}
                  <div className={`${styles.product_write_inpbox}`}>
                    {" "}
                    <input
                      type="text"
                      placeholder="0000-00-00 형식으로 작성해주세요."
                      onChange={changeDate}
                      value={dateValue}
                    />
                  </div>
                </div>
              </div>{" "}
              <div className={`${styles.product_write_etc_step}`}>
                <div className={`${styles.product_write_step}`}>
                  {" "}
                  <label className={`${styles.product_write_sub}`}>
                    외부 링크
                  </label>{" "}
                  <div className={`${styles.product_write_cont}`}>
                    {" "}
                    <div className={`${styles.product_write_inpbox}`}>
                      {" "}
                      <input
                        type="text"
                        onChange={change_link}
                        value={linkValue}
                        disabled={applyValue === "Y" ? false : true}
                      />
                    </div>
                  </div>
                </div>{" "}
                <div className={`${styles.product_write_step}`}>
                  {" "}
                  <label className={`${styles.product_write_sub}`}>
                    외부 링크 적용 여부
                  </label>{" "}
                  <div className={`${styles.product_write_cont}`}>
                    <label>
                      <input
                        type="radio"
                        name="apply"
                        value="Y"
                        onChange={() => change_apply("Y")}
                        checked={applyValue === "Y" ? true : false}
                      />{" "}
                      적용
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="apply"
                        value="N"
                        onChange={() => change_apply("N")}
                        checked={applyValue === "N" ? true : false}
                      />{" "}
                      미적용
                    </label>{" "}
                  </div>
                </div>
              </div>
              <div className={`${styles.product_write_etc}`}>
                <div className={`${styles.product_write_step}`}>
                  {" "}
                  <label className={`${styles.product_write_sub}`}>
                    카테고리명
                  </label>
                  <div className={`${styles.product_write_cont}`}>
                    <select onChange={change_cate} value={cateValue}>
                      {categoryData &&
                        categoryData.map((data) => {
                          return (
                            <option value={data.no}>
                              {data.category_name}
                            </option>
                          );
                        })}
                      {/* <option value="스마트팜">스마트팜</option>
                      <option value="ESG">ESG</option>
                      <option value="뷰티/화장품">뷰티/화장품</option>
                      <option value="바이오">바이오</option>
                      <option value="부동산">부동산</option>
                      <option value="컨텐츠산업">컨텐츠산업</option>
                      <option value="수경이채소">수경이채소</option>
                      <option value="허브팩토리">허브팩토리</option>
                      <option value="창산리 M1팜">창산리 M1팜</option>
                      <option value="신흥리 M2팜">신흥리 M2팜</option>
                      <option value="앵천리 M3팜">앵천리 M3팜</option>
                      <option value="제주알로에">제주알로에</option>
                      <option value="제주건강즙">제주건강즙</option>
                      <option value="제주콜라겐">제주콜라겐</option>{" "}
                      <option value="카이피라">카이피라</option>
                      <option value="버터헤드">버터헤드</option>
                      <option value="로메인">로메인</option>
                      <option value="이지리프">이지리프</option>{" "}
                      <option value="프릴아이스">프릴아이스</option> */}
                    </select>{" "}
                    {/* <div className={`${styles.product_write_inpbox}`}>
                    {" "}
                    <input type="text" />
                  </div> */}
                  </div>
                </div>
                <div className={`${styles.product_write_step}`}>
                  {" "}
                  <label className={`${styles.product_write_sub}`}>
                    수익률
                  </label>
                  <div className={`${styles.product_write_cont}`}>
                    {" "}
                    <div
                      className={`${styles.product_write_inpbox} ${styles.product_write_etcbox}`}
                    >
                      {" "}
                      <input
                        type="text"
                        onChange={change_rate}
                        value={rateValue}
                      />
                      <span>%</span>
                    </div>
                  </div>
                </div>
                <div className={`${styles.product_write_step}`}>
                  {" "}
                  <label className={`${styles.product_write_sub}`}>
                    가입기간
                  </label>
                  <div className={`${styles.product_write_cont}`}>
                    {" "}
                    <div
                      className={`${styles.product_write_inpbox} ${styles.product_write_etcbox}`}
                    >
                      {" "}
                      <input
                        type="text"
                        onChange={change_join}
                        value={joinValue}
                      />{" "}
                      <span>개월</span>
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* <div className={`${styles.product_write_step}`}>
                {" "}
                <label className={`${styles.product_write_sub}`}>
                  회사
                </label>{" "}
                <div className={`${styles.product_write_cont}`}>
                  {" "}
                  <div className={`${styles.product_write_cont}`}>
                    <div className={`${styles.product_write_inpbox}`}>
                      <input
                        type="text"
                        onChange={changeCom}
                        value={comValue}
                      />
                      <span>{comByte}/40</span>
                    </div>
                  </div>
                </div>
              </div>{" "} */}
              {/* <div className={`${styles.product_write_step}`}>
                {" "}
                <label className={`${styles.product_write_sub}`}>
                  추천상품 여부
                </label>{" "}
                <div className={`${styles.product_write_cont}`}>
                  <label>
                    <input
                      type="radio"
                      name="re"
                      checked={recommend === "Y" ? true : false}
                      onChange={() => change_open("Y")}
                    />{" "}
                    추천
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="re"
                      checked={recommend === "N" ? truenewLocallse}
                      onChange={() => change_open("N")}
                    />{" "}
                    미추천
                  </label>
                </div>{" "}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductWriteCom;
