import inv from "../../../styles/invest.module.css";

import { GiFarmTractor } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcStackOfPhotos,
  FcSelfie,
  FcRuler,
} from "react-icons/fc";

import React from "react";
import farm_2020 from "../../../assets/images/farm_2020.png";
import farm_2021 from "../../../assets/images/farm_2021.jpg";
import farm_2022 from "../../../assets/images/farm_2022.jpg";
import farm_2023 from "../../../assets/images/farm_2023.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import InvestProductItem from "./InvestProductItem";
import { useState } from "react";
import { wonComma } from "../../../lib/wonComma";
// import FundingContentItem from "./FundingContentItem";
import { CommonAxios } from "../../CommonAxios";
import { numberToKorean } from "../../../lib/numberToKorean";
import { useEffect } from "react";
import InvestGoodsItem from "./InvestGoodsItem";
const InvestContentStatus = () => {
  const { id } = useParams();
  const { id2 } = useParams();
  const [allPlace, setAllPlace] = useState(0);
  const [allOutput, setAllOutPut] = useState(0);
  const [ingPlace, setIngPlace] = useState(0);
  const [makePlace, setMakePlace] = useState(0);
  const [waitPlace, setWaitPlace] = useState(0);
  const [data, setData] = useState([]);
  const [all, setAll] = useState(0);
  const [sw, setSw] = useState(false);
  const [cnt1, setCnt1] = useState(0);
  const [cnt2, setCnt2] = useState(0);
  const [cnt3, setCnt3] = useState(0);
  const [data1, setData1] = useState([]);
  const [field, setField] = useState([]);
  useEffect(() => {
    let sendData = {
      // updateDate: NowDateVer2(),
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/invest_select_farm",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message[0]);
          setData1(result.messageinfo.message[1]);
          setField(result.messageinfo.message[2]);
          setAll(result.messageinfo.message_data2[0].allAmount);
          setCnt1(result.messageinfo.message_data3[0].cnt);
          setCnt2(result.messageinfo.message_data3[2].cnt);
          setCnt3(result.messageinfo.message_data3[1].cnt);
          setSw(true);
        } else {
        }
        // sendResult(result.messageinfo.state);
      }
    );
  }, []);

  return (
    <div className={inv.rowgroup}>
      {" "}
      <div className={inv.status}>
        <div className={inv.status_text}>
          <strong>
            메타12는 이미 <br />
            투자를 이끌고 있습니다.
          </strong>
          <p>
            메타12의 투자현황을 <br />
            실시간으로 확인해보세요.
          </p>
        </div>
        <div className={inv.status_num}>
          <div className={inv.status_first}>
            <ul className={`${inv.status_list} ${inv.length2}`}>
              <li>
                <span>전체 투자 금액</span>
                {/* <em>{wonComma(allPlace)}원</em> */}
                <em>{sw ? wonComma(numberToKorean(all)) : all}원</em>
              </li>
              <li>
                <span></span>
                {/* <em>{wonComma(allOutput)}원</em> */}
                <em></em>
              </li>
            </ul>
          </div>

          <ul className={`${inv.status_list} ${inv.length3}`}>
            <li>
              <span>투자 준비중인 상품</span>
              <em>{sw ? wonComma(cnt1) : cnt1}</em>
              {/* <em>{wonComma(ingPlace)}</em> 평 */}
            </li>
            <li>
              <span>투자 진행중인 상품</span>
              {/* <em>{wonComma(makePlace)}</em> 평 */}
              <em>{sw ? wonComma(cnt3) : cnt3}</em>
            </li>
            <li>
              <span>투자 종료 상품</span>
              {/* <em>{wonComma(waitPlace)}</em> 평 */}
              <em>{sw ? wonComma(cnt2) : cnt2}</em>
            </li>
          </ul>
        </div>
      </div>{" "}
      <h5 className={`${inv.farm_title} ${inv.farm_title_first}`}>
        상품투자현황
      </h5>
      <div className={inv.farm}>
        <div className={inv.farm_list}>
          {data1.length > 0 && field.length > 0 && (
            <InvestGoodsItem data={data1} field={field[0]} />
          )}
        </div>
      </div>
      <h5 className={`${inv.farm_title} ${inv.farm_title_second}`}>
        농장투자현황
      </h5>
      <div className={`${inv.farm} ${inv.farm_list_div}`}>
        <div className={`${inv.farm_list}`}>
          {data.length > 0 && field.length > 0 && (
            <InvestProductItem data={data} field={field[1]} />
          )}
        </div>
      </div>
      {/* ㅇ */}
      {/* ㅇ */}
      {/* <div className={inv.rowgroup2}>
        <div className={inv.campaign_go}>
          <Link to={`/funding`} className={inv.campaign_anchor}>
            <div className={inv.anchor_text}>
              <span>META12의 다음 투자를 소유해보세요</span>
              <em>투자하러 가기</em>
            </div>

            <IoIosArrowForward />
          </Link>
        </div>
      </div> */}
    </div>
  );
};
export default InvestContentStatus;
