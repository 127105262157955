import React, { useState, useEffect, useRef, useCallback } from "react";
import FarmInfo from "../components/admin/details/FarmInfo";
import FarmSubTable from "../components/admin/details/FarmSubTable";
import Moment from "react-moment";
import styles from "../styles/admin.module.css";
import { useParams, useNavigate } from "react-router-dom";
import { CommonAxios } from "../components/CommonAxios";
import { textReplace } from "../lib/textReplace";
import { textOverCut, getTextTotal } from "../lib/stringLenghts";
import { wonComma } from "../lib/wonComma";
import Swal from "sweetalert2";
const FarmDetail = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [swt, setSwt] = useState(false);

  useEffect(() => {
    let sendData = {
      field_type: 2,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_field",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          if (result.messageinfo.message.length > 0) {
            setdata(result.messageinfo.message);

            setSwt(true);
          }
        }
      }
    );
  }, []);
  // 공개여부
  const [openValue, setOpenValue] = useState("Y");
  const change_open = (data) => {
    setOpenValue(data);
  };

  const navigate = useNavigate();

  // 농장명
  const [titleByte, setTitleByte] = useState(0);
  const [titleValue, setTitleValue] = useState("");
  const change_title = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 15);
    let text = textOverCut(e.target.value, 15);

    setTitleValue(text);
    setTitleByte(byte.byte);
  }, []);
  //생산면적

  const [contentValue, setContentValue] = useState("");
  const change_content = useCallback((e) => {
    setContentValue(wonComma(e.target.value.replace(/[^\d]+/g, "")));
  }, []);

  const [amountValue, setAmountValue] = useState("");
  const change_amount = useCallback((e) => {
    setAmountValue(wonComma(e.target.value.replace(/[^\d]+/g, "")));
  }, []);

  const [pro1Byte, setPro1Byte] = useState(0);
  const [proValue1, setProValue1] = useState("");
  const changePro1 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 20);
    let text = textOverCut(e.target.value, 20);

    setProValue1(text);
    setPro1Byte(byte.byte);
  }, []);

  const [pro2Byte, setPro2Byte] = useState(0);
  const [proValue2, setProValue2] = useState("");
  const changePro2 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 10);
    let text = textOverCut(e.target.value, 10);

    setProValue2(text);
    setPro2Byte(byte.byte);
  }, []);

  const [applyValue, setApplyValue] = useState("N");
  const change_apply = (data) => {
    setApplyValue(data);
  };
  const [cateValue, setCateValue] = useState("A타입");
  const change_cate = useCallback((e) => {
    setCateValue(e.target.value);
  }, []);
  const [rateValue, setRateValue] = useState("");
  const change_rate = useCallback((e) => {
    setRateValue(e.target.value);
  }, []);
  const [joinValue, setJoinValue] = useState("");
  const change_join = useCallback((e) => {
    setJoinValue(e.target.value);
  }, []);

  const [progressValue, setProgressValue] = useState("1");
  const change_progress = (data) => {
    setProgressValue(data);
  };
  // 상품 등록
  const click_farm_reg = () => {
    if (!titleValue || !contentValue || !amountValue || !proValue1) {
      return Swal.fire({
        title: "농장 등록 실패",
        html: `빈칸을 채워주세요.`,

        confirmButtonText: "확인",
      });
    }

    let sendData = {
      no: id,
      farm_open: openValue,
      // pro_end_yn: recru,
      farm_title: textReplace(titleValue),
      pro_content: contentValue.replace(/[^\d]+/g, ""),
      farm_estimated_earns: amountValue.replace(/[^\d]+/g, ""),

      farm_state: cateValue === "A타입" ? "N" : "Y",
      farm_crops_1: textReplace(proValue1),
      farm_link: textReplace(proLink),
      farm_progress: progressValue,
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_farm",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          Swal.fire({
            title: "수정 완료",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/admin/farm");
            }
          });
        }
      }
    );
  };
  useEffect(() => {
    let sendData = {
      no: id,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_farm_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setProLink(result.messageinfo.message[0].farm_link);
          setOpenValue(result.messageinfo.message[0].farm_open);
          // setArrayList(result.messageinfo.message);
          setTitleValue(result.messageinfo.message[0].farm_title);

          let byte = getTextTotal(result.messageinfo.message[0].farm_title, 15);
          setTitleByte(byte.byte);

          setContentValue(wonComma(result.messageinfo.message[0].farm_area));
          setAmountValue(
            wonComma(result.messageinfo.message[0].farm_estimated_earns)
          );
          setProgressValue(String(result.messageinfo.message[0].farm_progress));
          setCateValue(
            result.messageinfo.message[0].farm_state === "N" ? "A타입" : "B타입"
          );
          setProValue1(result.messageinfo.message[0].farm_crops_1);

          let byte1 = getTextTotal(
            result.messageinfo.message[0].farm_crops_1,
            20
          );
          setPro1Byte(byte1.byte);
        } else {
        }
      }
    );
  }, []);
  const [proLink, setProLink] = useState("");
  const change_link = useCallback((e) => {
    setProLink(e.target.value);
  }, []);
  return (
    <>
      {" "}
      {swt && (
        <div className={styles.wrap_content_box}>
          <div className={styles.wrap_content_box_header}>
            투자현황 &nbsp;〉 &nbsp;농장투자현황 &nbsp;〉 &nbsp;농장 상세
          </div>{" "}
          <div className={styles.wrap_content_box_body}>
            <div className={styles.farm_write_wrap}>
              <div className={styles.farm_write_inner}>
                <div className={styles.farm_write_title}>
                  <h4>농장 수정</h4>
                  <div className={styles.farm_write_button}>
                    <button onClick={click_farm_reg}>수정</button>
                  </div>
                </div>

                <div className={styles.farm_write_content}>
                  <div className={styles.farm_write_right}>
                    <div className={`${styles.farm_write_step}`}>
                      <label className={`${styles.farm_write_sub}`}>
                        농장명
                      </label>
                      <div className={`${styles.farm_write_cont}`}>
                        <div className={`${styles.farm_write_inpbox}`}>
                          <input
                            type="text"
                            onChange={change_title}
                            value={titleValue}
                          />
                          <span>{titleByte}/15</span>
                        </div>
                      </div>
                    </div>{" "}
                    <div className={`${styles.farm_write_step} `}>
                      {" "}
                      <label className={`${styles.farm_write_sub}`}>
                        {data.length > 0 && data[0].field_title_two}
                      </label>{" "}
                      <div className={`${styles.farm_write_cont}`}>
                        {" "}
                        <div className={`${styles.farm_write_inpbox}`}>
                          {" "}
                          <input
                            type="text"
                            // placeholder="0000-00-00 형식으로 작성해주세요."
                            onChange={changePro1}
                            value={proValue1}
                          />{" "}
                          <span>{pro1Byte}/20</span>
                        </div>
                      </div>
                    </div>{" "}
                    <div className={`${styles.farm_write_step} `}>
                      {" "}
                      <label className={`${styles.farm_write_sub}`}>
                        링크
                      </label>{" "}
                      <div className={`${styles.farm_write_cont}`}>
                        {" "}
                        <div className={`${styles.farm_write_inpbox}`}>
                          {" "}
                          <input
                            type="text"
                            // placeholder="0000-00-00 형식으로 작성해주세요."
                            onChange={change_link}
                            value={proLink}
                          />{" "}
                          {/* <span>{pro1Byte}/20</span> */}
                        </div>
                      </div>
                    </div>{" "}
                    <div className={`${styles.farm_write_etc_step}`}>
                      <div className={`${styles.farm_write_step}`}>
                        <label className={`${styles.farm_write_sub}`}>
                          {data.length > 0 && data[0].field_title_one}
                        </label>
                        <div className={`${styles.farm_write_cont}`}>
                          <div className={`${styles.farm_write_inpbox}`}>
                            <input
                              type="text"
                              onChange={change_content}
                              value={contentValue}
                              style={{ textAlign: "right" }}
                            />{" "}
                            <span>
                              {" "}
                              {data.length > 0 && data[0].field_unit_one}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.farm_write_step}`}>
                        {" "}
                        <label className={`${styles.farm_write_sub}`}>
                          {data.length > 0 && data[0].field_title_three}
                        </label>{" "}
                        <div className={`${styles.farm_write_cont}`}>
                          {" "}
                          <div className={`${styles.farm_write_inpbox}`}>
                            {" "}
                            <input
                              type="text"
                              onChange={change_amount}
                              value={amountValue}
                              style={{ textAlign: "right" }}
                            />
                            <span>
                              {" "}
                              {data.length > 0 && data[0].field_unit_two}
                            </span>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div
                      className={`${styles.farm_write_etc} ${styles.farm_write_etc_warp} `}
                    >
                      <div className={`${styles.farm_write_step}`}>
                        {" "}
                        <label className={`${styles.farm_write_sub}`}>
                          {data.length > 0 && data[0].field_title_four}
                        </label>
                        <div className={`${styles.farm_write_cont}`}>
                          <select onChange={change_cate} value={cateValue}>
                            <option value="A타입">A타입</option>
                            <option value="B타입">B타입</option>
                          </select>{" "}
                          {/* <div className={`${styles.farm_write_inpbox}`}>
                    {" "}
                    <input type="text" />
                  </div> */}
                        </div>
                      </div>
                      {cateValue === "A타입" && (
                        <div className={`${styles.farm_write_step}`}>
                          {" "}
                          <label className={`${styles.farm_write_sub}`}></label>
                          <div className={`${styles.farm_write_cont}`}>
                            <label>
                              <input
                                type="radio"
                                name="data"
                                value="1"
                                onChange={() => change_progress("1")}
                                checked={progressValue === "1" ? true : false}
                              />{" "}
                              {data.length > 0 && data[0].field_A_text_one}
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="data"
                                value="2"
                                onChange={() => change_progress("2")}
                                checked={progressValue === "2" ? true : false}
                              />{" "}
                              {data.length > 0 && data[0].field_A_text_two}
                            </label>{" "}
                            <label>
                              <input
                                type="radio"
                                name="data"
                                value="3"
                                onChange={() => change_progress("3")}
                                checked={progressValue === "3" ? true : false}
                              />{" "}
                              {data.length > 0 && data[0].field_A_text_three}
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="data"
                                value="4"
                                onChange={() => change_progress("4")}
                                checked={progressValue === "4" ? true : false}
                              />{" "}
                              {data.length > 0 && data[0].field_A_text_four}
                            </label>
                          </div>
                        </div>
                      )}{" "}
                      {cateValue === "B타입" && (
                        <div className={`${styles.farm_write_step}`}>
                          {" "}
                          <label className={`${styles.farm_write_sub}`}></label>
                          <div className={`${styles.farm_write_cont}`}>
                            <label>
                              <input
                                type="radio"
                                name="data"
                                value="1"
                                onChange={() => change_progress("1")}
                                checked={progressValue === "1" ? true : false}
                              />{" "}
                              {data.length > 0 && data[0].field_B_text_one}
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="data"
                                value="2"
                                onChange={() => change_progress("2")}
                                checked={progressValue === "2" ? true : false}
                              />{" "}
                              {data.length > 0 && data[0].field_B_text_two}
                            </label>{" "}
                            <label>
                              <input
                                type="radio"
                                name="data"
                                value="3"
                                onChange={() => change_progress("3")}
                                checked={progressValue === "3" ? true : false}
                              />{" "}
                              {data.length > 0 && data[0].field_B_text_three}
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="data"
                                value="4"
                                onChange={() => change_progress("4")}
                                checked={progressValue === "4" ? true : false}
                              />{" "}
                              {data.length > 0 && data[0].field_B_text_four}
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.farm_open_box}>
                      <h4>공개 여부</h4>
                      <label>
                        <input
                          type="radio"
                          name="open"
                          value="Y"
                          onChange={() => change_open("Y")}
                          checked={openValue === "Y" ? true : false}
                        />{" "}
                        공개
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="open"
                          value="N"
                          onChange={() => change_open("N")}
                          checked={openValue === "N" ? true : false}
                        />{" "}
                        비공개
                      </label>
                    </div>
                  </div>{" "}
                </div>
                {/* <div className={`${styles.farm_write_step}`}>
                {" "}
                <label className={`${styles.farm_write_sub}`}>
                  추천상품 여부
                </label>{" "}
                <div className={`${styles.farm_write_cont}`}>
                  <label>
                    <input
                      type="radio"
                      name="re"
                      checked={recommend === "Y" ? true : false}
                      onChange={() => change_open("Y")}
                    />{" "}
                    추천
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="re"
                      checked={recommend === "N" ? truenewLocallse}
                      onChange={() => change_open("N")}
                    />{" "}
                    미추천
                  </label>
                </div>{" "}
              </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FarmDetail;
