import style from "./Footer.css";
import logo from "../../assets/images/Logo.png";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { BsQuestionCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { TbExternalLink } from "react-icons/tb";
import { FaUserCircle, FaGooglePlay, FaApple } from "react-icons/fa";
import { useState, useEffect, useRef } from "react";
import { CommonAxios } from "../CommonAxios";
const Footer = () => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));

  const LoginIcon = () => {
    return (
      <>
        <div className="footer-login-icon-wrap">
          <Link to="/mypage">
            <div className="footer-login-icon">
              <FaUserCircle />
            </div>
          </Link>
        </div>
      </>
    );
  };
  const [pop1, setPop1] = useState(false);
  const popup1 = () => {
    setPop1(true);
  };

  const modalEl = useRef(null); //
  const [isOpen, setOpen] = useState(false);

  const Ex = ({ setPop1 }) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalEl && !modalEl.current.contains(event.target)) setPop1(false);
      };
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
    return (
      <ul className="footer-popup1" ref={modalEl}>
        {" "}
        <li>
          <a
            href={
              pdfArr.length > 0
                ? `/policy/${pdfArr[0].no}/${pdfArr[0].policy_pdf}`
                : ""
            }
            // download
          >
            {" "}
            {pdfArr.length > 0 && pdfArr[0].policy_title} <TbExternalLink />
          </a>
        </li>
        <li>
          <a
            href={
              pdfArr.length > 0
                ? `/policy/${pdfArr[1].no}/${pdfArr[1].policy_pdf}`
                : ""
            }
            // download
          >
            {" "}
            {pdfArr.length > 0 && pdfArr[1].policy_title} <TbExternalLink />
          </a>
        </li>
        <li>
          <a
            href={
              pdfArr.length > 0
                ? `/policy/${pdfArr[2].no}/${pdfArr[2].policy_pdf}`
                : ""
            }
            download
          >
            {" "}
            {pdfArr.length > 0 && pdfArr[2].policy_title} <TbExternalLink />
          </a>
        </li>{" "}
      </ul>
    );
  };

  // const modalE2 = useRef(null); //
  // const [pop2, setPop2] = useState(false);
  // const popup2 = () => {
  //   setPop2(true);
  // };

  // const Ex2 = ({ setPop2 }) => {
  //   useEffect(() => {
  //     const handleClickOutside = (event) => {
  //       if (modalE2 && !modalE2.current.contains(event.target)) setPop2(false);
  //     };
  //     document.addEventListener("mousedown", handleClickOutside);

  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   });
  //   return (
  //     <ul className="footer-popup2" ref={modalE2}>
  //       <li>
  //         <Link to="/info3">
  //           {" "}
  //           개인정보처리방침 <TbExternalLink />
  //         </Link>
  //       </li>
  //     </ul>
  //   );
  // };

  const modalE3 = useRef(null); //
  const [pop3, setPop3] = useState(false);
  const popup3 = () => {
    setPop3(true);
  };

  const Ex3 = ({ setPop3 }) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (modalE3 && !modalE3.current.contains(event.target)) setPop3(false);
      };
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
    return (
      <ul className="footer-popup3" ref={modalE3}>
        <li>asiaro123@naver.com</li>
      </ul>
    );
  };

  // const modalE4 = useRef(null); //
  // const [pop4, setPop4] = useState(false);
  // const popup4 = () => {
  //   setPop4(true);
  // };

  // const Ex4 = ({ setPop4 }) => {
  //   useEffect(() => {
  //     const handleClickOutside = (event) => {
  //       if (modalE4 && !modalE4.current.contains(event.target)) setPop4(false);
  //     };
  //     document.addEventListener("mousedown", handleClickOutside);

  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   });
  //   return (
  //     <ul className="footer-popup4" ref={modalE4}>
  //       <li>
  //         <a target="_blank" href="https://blog.naver.com/asiaro123">
  //           Blog <TbExternalLink />
  //         </a>
  //       </li>{" "}
  //       <li>
  //         <a
  //           target="_blank"
  //           href="https://www.youtube.com/channel/UCSkZor-Z3mBN3RALUwvwqsA"
  //         >
  //           Youtube <TbExternalLink />
  //         </a>
  //       </li>
  //       <li>
  //         <a target="_blank" href="https://www.facebook.com/asiaro123">
  //           Facebook <TbExternalLink />
  //         </a>
  //       </li>{" "}
  //       <li>
  //         <a target="_blank" href="https://www.instagram.com/asiaro1234">
  //           Instagram <TbExternalLink />
  //         </a>
  //       </li>{" "}
  //       <li>
  //         <a target="_blank" href="https://twitter.com/asiaro123">
  //           Twitter <TbExternalLink />
  //         </a>
  //       </li>
  //     </ul>
  //   );
  // };

  const navigate = useNavigate();

  const [pdfArr, setPdfArr] = useState([]);
  const [arr, setArr] = useState([]);
  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_footer_pdf",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setPdfArr(result.messageinfo.message);
          setArr(result.messageinfo.message_data2);
        } else {
          // navigate("/admin/qna");
        }
      }
    );
  }, []);
  return (
    <>
      <div className="common-wrap">
        {/* <div className="common-inner-wrap"> */}
        <div className="footer-wrap">
          <div className="footer-top">
            <div className="footer-box flex">
              <div className="footer-top-left">
                <ul className="footer-box-ul">
                  {" "}
                  <li onClick={popup1} className="ft-li-1">
                    <a>
                      정책·약관 <IoIosArrowDown />
                    </a>
                    {pop1 && <Ex setPop1={setPop1} ref={modalEl} />}
                  </li>{" "}
                  {/* <li onClick={popup2} className="ft-li-2"> */}
                  <li>
                    <a
                      href={
                        pdfArr.length > 0
                          ? `/policy/${pdfArr[3].no}/${pdfArr[3].policy_pdf}`
                          : ""
                      }
                      // download
                    >
                      {pdfArr.length > 0 && pdfArr[3].policy_title}{" "}
                      <TbExternalLink />
                      {/* {pop2 && <Ex2 setPop2={setPop2} ref={modalE2} />} */}
                    </a>
                  </li>
                </ul>
              </div>{" "}
              <div className="footer-top-right">
                {" "}
                <ul className="footer-box-ul">
                  {" "}
                  {/* <li onClick={popup3} className="ft-li-3">
                    <a>
                      제휴문의 <IoIosArrowDown />
                    </a>{" "}
                    {pop3 && <Ex3 setPop3={setPop3} ref={modalE3} />}
                  </li>{" "} */}
                  <li>
                    <Link to="/noticePage">
                      공지사항
                      <TbExternalLink />
                    </Link>
                  </li>{" "}
                  {/* <li>
                    <Link to="/noticePage/pageTwo">
                      보도자료
                      <TbExternalLink />
                    </Link>
                  </li> */}
                  {/* <li onClick={popup4} className="ft-li-4">
                    <a>
                      SNS
                      <IoIosArrowDown />
                    </a>{" "}
                    {pop4 && <Ex4 setPop4={setPop4} ref={modalE4} />}
                  </li> */}
                  {/* <li>
                    <a target="_blank" href="https://blog.naver.com/asiaro123">
                      Blog
                      <TbExternalLink />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UCSkZor-Z3mBN3RALUwvwqsA"
                    >
                      Youtube <TbExternalLink />
                    </a>
                  </li>{" "} */}
                  {/* <li>
                    <a>
                      Language <IoIosArrowDown />
                    </a>
                  </li>{" "} */}
                </ul>
              </div>
              {/* <ul className="footer-box-ul">
                <li>
                  <a>정책·약관</a>
                </li>
                <li>
                  <a>개인정보처리방침</a>
                </li>
                <li>
                  <a>제휴문의</a>
                </li>
                <li>
                  <Link to="/notice">공지사항</Link>
                </li>
              </ul> */}
            </div>
          </div>
          <footer className="footer-box bottom flex">
            <div className="footer-bottom-left">
              <div className="footer-service-box">
                <h4 className="footer-service-title">
                  {/* <BsQuestionCircleFill /> */}
                  메타 12 고객센터
                </h4>
                <ul>
                  <li className="footer-service-li">
                    <span id="custom-button-2" style={{ cursor: "pointer" }}>
                      채팅 상담하기 <IoIosArrowForward />
                    </span>
                  </li>
                  <li className="footer-service-li">
                    {/* <Link to="/mypage/qna"> */}{" "}
                    <span
                      onClick={() => navigate("/mypage/qna")}
                      style={{ cursor: "pointer" }}
                    >
                      1:1 문의하기 <IoIosArrowForward />
                    </span>
                    {/* </Link> */}
                  </li>
                  {/* <li className="footer-service-li">
                    도움말 센터 바로가기 <IoIosArrowForward />
                  </li> */}
                </ul>
              </div>
              <div className="footer-service-date">
                <h5>상담 가능 시간</h5>
                <p>{arr.length > 0 && arr[0].footer_text}</p>
              </div>
            </div>
            <div className="footer-bottom-right">
              <address>
                <ul className="footer-info-top">
                  <li>{arr.length > 0 && arr[1].footer_text}</li>
                  <li>대표이사 {arr.length > 0 && arr[2].footer_text}</li>
                  <li>사업자등록번호 {arr.length > 0 && arr[3].footer_text}</li>
                  <li>
                    통신판매업신고번호 {arr.length > 0 && arr[4].footer_text}
                  </li>
                  <li>{arr.length > 0 && arr[5].footer_text}</li>
                </ul>{" "}
                <ul className="footer-info-bottom">
                  <li>
                    이메일 상담 &nbsp;
                    {/* <a href="mailto:"+{arr.length > 0 && arr[0].footer_text} > */}
                    <a href={`mailto:${arr.length > 0 && arr[6].footer_text}`}>
                      {arr.length > 0 && arr[6].footer_text}
                    </a>
                  </li>
                  <li>
                    유선 상담{" "}
                    <a href={`tel:${arr.length > 0 && arr[7].footer_text}`}>
                      {arr.length > 0 && arr[7].footer_text}
                    </a>
                  </li>
                  <li> {arr.length > 0 && arr[8].footer_text}</li>
                </ul>
              </address>
              <div className="footer-info-p">
                <p>
                  {arr.length > 0 && arr[9].footer_text}{" "}
                  {arr.length > 0 && arr[10].footer_text}
                </p>
                <p>{arr.length > 0 && arr[11].footer_text}</p>
              </div>
              <div className="footer-info-pview">
                <p>{arr.length > 0 && arr[9].footer_text}</p>
                <p>{arr.length > 0 && arr[10].footer_text}</p>
                <p>{arr.length > 0 && arr[11].footer_text}</p>
              </div>
              {/* <div className="footer-info-app">
                <ul>
                  <li>
                    <FaGooglePlay /> Android앱
                  </li>
                  <li>
                    <FaApple />
                    iOS앱
                  </li>
                </ul>
              </div> */}
            </div>
          </footer>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default Footer;
