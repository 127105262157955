import styles from "../../styles/mypage.module.css";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import intro from "../../styles/intro.module.css";
import Intro_1 from "./detail/Intro_1";
import Intro_2 from "./detail/Intro_2";
import Intro_3 from "./detail/Intro_3";
import { useEffect, useState } from "react";
// import intro from "../../styles/intro.module.css";

const IntroduceDetail = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  useEffect(() => {
    let sendData = {
      type: 4,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/main_select_banner",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          // if (result.messageinfo.message.length > 0) {

          // let m = [];
          // let p = [];

          // result.messageinfo.message.map((data) => {
          //   if (data.banner_version === "p") {
          //     p.push(data);
          //   }
          //   if (data.banner_version === "m") {
          //     m.push(data);
          //   }
          // });

          setData(result.messageinfo.message);
          setData1(result.messageinfo.message_data2);
          // }
        } else {
        }
      }
    );
  }, []);
  return (
    <>
      <div className="common-wrap">
        {data.length > 0 && (
          <div
            className={intro.intro_banner_wrap}
            style={{
              background: `url(/banner/${data[0].no}/${data[0].banner_img}) no-repeat `,
              backgroundSize: "cover",
            }}
          >
            {/* <div className="common-inner-wrap"> */}
            <div className={intro.intro_banner_inner}>
              <div className={intro.intro_banner_text}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data[0].banner_text.replaceAll(
                      /(\n|\r\n)/g,
                      "<br/>"
                    ),
                  }}
                ></p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: data[0].banner_text2.replaceAll(
                      /(\n|\r\n)/g,
                      "<br/>"
                    ),
                  }}
                ></span>
              </div>
            </div>
            {/* </div> */}
          </div>
        )}
        {/* <div className="common-inner-wrap"> */}
        <div className={intro.intro_wrap}>
          <Intro_1 data={data1} />
          <Intro_2 data={data1} />
          {/* <Intro_3 /> */}
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default IntroduceDetail;
