import { CommonAxios } from "../../CommonAxios";
import { useState, useEffect, useCallback } from "react";
import ProcessSection_1 from "./ProcessSection_1";
import ProcessSection_3 from "./ProcessSection_3";
import { useParams } from "react-router-dom";
const InvestProDetail = () => {
  const [data, setData] = useState([]);
  const [warringAlert, setWarringAlert] = useState(false);
  const [content, setContent] = useState("");
  const { id } = useParams();
  const [sw, setSw] = useState(false);
  useEffect(() => {
    let sendData = {
      no: id,
    };
    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/invest_select_product",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
          setSw(true);
        } else {
        }
      }
    );
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        {data.length > 0 && data[0].details_img && (
          <img
            src={`/details/${data[0].detailsNo}/${data[0].details_img}`}
            style={{ width: "100%" }}
            // style={{ margin: "0 auto" }}
          />
        )}{" "}
      </div>

      {/* <ProcessSection_1 data={data[0]} /> */}
      {/* <ProcessSection_2 /> */}
      {sw && (
        <ProcessSection_3
          data={data[0]}
          // type={1}
          setWarringAlert={setWarringAlert}
          setContent={setContent}
        />
      )}
    </>
  );
};

export default InvestProDetail;
