import styles from "../../../styles/admin.module.css";
import { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import AdminUserInfo from "./AdminUserInfo";
import ToastEditor from "../editor/ToastEditor";
import { textReplace } from "../../../lib/textReplace";
import { FcEditImage } from "react-icons/fc";
import logo from "../../../assets/images/Artboard 2.png";
import Moment from "react-moment";
import { useSelector, useDispatch } from "react-redux";
import { getQna } from "../../../modules/admin";
const QnaViewDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [content, setContent] = useState("");
  const [qnaTrue, setQnaTrue] = useState(false);
  const { qnaArr } = useSelector(({ admin }) => ({
    qnaArr: admin.qnaArr,
  }));
  const changeContent = useCallback((e) => {
    setContent(e.target.value);
  }, []);
  useEffect(() => {
    let sendData = {
      no: id,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_qna_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);

          if (result.messageinfo.message.length > 0) {
            if (result.messageinfo.message[0].qna_answer_yn === "Y") {
              setContent(result.messageinfo.message[0].qna_answer_content);
            }
          }
          let arr = [];
          qnaArr.map((data) => {
            if (Number(data.no) !== Number(id)) {
              arr.push({ no: data.no });
            }
          });
          dispatch(getQna(arr));
          setQnaTrue(true);
        } else {
          // navigate("/admin/qna");
        }
      }
    );
  }, []);

  const btn_reg = () => {
    let sendData = {
      no: id,
      qna_answer_content: content,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_qna_answer",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          if (result.messageinfo.message.length > 0) {
            if (result.messageinfo.message[0].qna_answer_yn === "Y") {
              setContent(result.messageinfo.message[0].qna_answer_content);
            }
          }

          setBtnState(false);
        } else {
          // navigate("/admin/qna");
        }
      }
    );
  };
  const btn_cancel = () => {
    setBtnState(false);
    if (data[0].qna_answer_yn === "Y") {
      setContent(data[0].qna_answer_content);
    } else {
      setContent("");
    }
  };
  const btn_update = () => {
    navigate(`/admin/qna/update/${id}`);
  };

  const [btnState, setBtnState] = useState(false);
  return (
    <>
      <div className={styles.qna_write_wrap}>
        <div className={styles.qna_write_inner}>
          <div className={styles.qna_content_tit_div}>
            <h3 className={styles.qna_content_title}>1:1 문의 내용</h3>
            {/* <button onClick={() => navigate(-1)}>이전으로</button> */}
          </div>
          <div className={`${styles.qna_content_inner} ${styles.ivest_wrap} `}>
            <div className={styles.qna_content_wrap_view}>
              <div className={styles.qna_content_view}>
                <div className={styles.qna_content_box_view}>
                  <div className={styles.qna_content_list_view}>
                    <div className={styles.qna_list_left_view}>
                      {" "}
                      <p>문의제목</p>
                    </div>
                    <div className={styles.qna_list_right_view}>
                      <div className={styles.qna_right_inpdiv_view}>
                        {qnaTrue && data[0].qna_title}
                        {/* <input type="text" onChange={changeTitle} value={title} /> */}
                      </div>
                    </div>
                  </div>
                  <div className={styles.qna_content_list_view}>
                    <div className={styles.qna_list_left_view}>
                      <p>문의내용</p>
                    </div>
                    <div className={styles.qna_list_right_view}>
                      {" "}
                      <div className={styles.qna_right_inpdiv_view}>
                        {qnaTrue && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data[0].qna_content.replace(
                                /(?:\r\n|\r|\n)/g,
                                "<br/>"
                              ),
                            }}
                          ></div>
                        )}

                        {/* {qnaTrue &&
                        qnaList[0].qna_content.replace(
                          /(?:\r\n|\r|\n)/g,
                          "<br/>"
                        )}
                      <textarea
                        onChange={changeContent}
                        value={qnaList[0].qna_content}
                      ></textarea> */}
                      </div>
                    </div>
                  </div>
                  <div className={styles.qna_content_list_view}>
                    <div className={styles.qna_list_left_view}>
                      {" "}
                      <p>파일첨부</p>
                    </div>
                    <div className={styles.qna_list_right_view}>
                      <ul>
                        {qnaTrue && data[0].qna_file_one && (
                          <li>
                            <a
                              href={`/qna/${data[0].no}/${data[0].qna_file_one}`}
                              download
                            >
                              {" "}
                              {data[0].qna_file_one}{" "}
                            </a>
                          </li>
                        )}
                        {qnaTrue && data[0].qna_file_two && (
                          <li>
                            <a
                              href={`/qna/${data[0].no}/${data[0].qna_file_two}`}
                              download
                            >
                              {" "}
                              {data[0].qna_file_two}{" "}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>{" "}
              </div>{" "}
              <div className={styles.qna_content_view}>
                <div className={styles.qna_content_box_view}>
                  <div className={styles.qna_content_list_view}>
                    <div className={styles.qna_list_left_view}>
                      {" "}
                      <p>답변</p>
                    </div>{" "}
                    <div className={styles.qna_list_right_view}>
                      {" "}
                      <div className={styles.qna_right_inpdiv_view}>
                        {btnState && qnaTrue && (
                          <textarea
                            onChange={changeContent}
                            value={content}
                          ></textarea>
                        )}

                        {!btnState &&
                          qnaTrue &&
                          data[0].qna_answer_yn === "Y" && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: content.replace(
                                  /(?:\r\n|\r|\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></div>
                          )}
                        {!btnState &&
                          qnaTrue &&
                          data[0].qna_answer_yn === "N" && <span>미답변</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={styles.qna_button_div_view}>
              <button className={styles.qna_button_view} onClick={btn_reg}>
                제출
              </button>
            </div> */}
            </div>
          </div>
          {btnState && (
            <div className={styles.qna_right_inpdiv_view_btn}>
              <button className={styles.qna_view_reg} onClick={btn_reg}>
                등록
              </button>
              <button className={styles.qna_view_cancel} onClick={btn_cancel}>
                취소
              </button>
            </div>
          )}{" "}
          {!btnState && (
            <div className={styles.qna_right_inpdiv_view_btn}>
              <button
                className={styles.qna_view_update}
                onClick={() => {
                  setBtnState(true);
                }}
              >
                답변
              </button>
              {/* <button>취소</button> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QnaViewDetail;
