import Carousel from "react-bootstrap/Carousel";
import img_1 from "../../assets/images/img_1.jpg";
import img_2 from "../../assets/images/img_2.jpg";
import img_3 from "../../assets/images/img_3.jpg";
import img_4 from "../../assets/images/Layer 1.png";
import img_5 from "../../assets/images/Layer 2.png";
import img_6 from "../../assets/images/Layer 3.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CommonAxios } from "../CommonAxios";
import main from "../../styles/main.module.css";
import { useCallback, useRef, useState, useEffect } from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "green" }}
      onClick={onClick}
    />
  );
}
const MainBanner = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const a = [
    {
      d: "수확 작물 :비트",
      img: img_1,
      content: ` <p >
    <em >메타12</em>
    은
    <br />
    국내 최대 규모를 자랑하는
    <br />
    담수경식 스마트팜 브랜드 입니다.
  </p>`,
    },
    {
      d: "수확 작물 :당근",
      img: img_2,
      content: ` <p >
    <em >메타12</em>
    은 충청북도 괴산군에
    <br /> 
    허브팩토리, M1팜, M2팜, M3팜 등의 농장을
    <br />
    친환경 스마트팜으로 운영하고 있습니다.
  </p>`,
    },
    {
      d: "수확 작물 :원두",
      img: img_3,
      content: ` <p >
    <em >메타12</em>
    은 유러피안 샐러드 채소인
    <br />
    바타비아, 버터헤드, 로메인, 이지리프, 프릴아이스 등을
    <br />
친환경 스마트팜으로 재배하고 있습니다.  </p>`,
    },
  ];

  const SliderItemList = ({ data }) => {
    return (
      <>
        <div className={main.main_slick_div}>
          <a className={main.main_slick_link}>
            <div
              className={main.main_slick_background}
              style={{
                // background: `url('${data.img}') no-repeat`,
                background: `url(/banner/${data.no}/${data.banner_img}) no-repeat`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className={main.main_slick_content}>
                {data.banner_text && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.banner_text.replaceAll(
                        /(\n|\r\n)/g,
                        "<br/>"
                      ),
                    }}
                    className={main.main_slick_content_text}
                  ></div>
                )}

                {/* <p className={main.main_slick_text_p}>

                  
<em className={main.main_slick_text_em}>메타12</em>
은
<br />
국내 최대 규모를 자랑하는
<br />
담수경식 스마트팜 브랜드 입니다.
</p> */}
              </div>

              {/* <img src={data.img} className="slick-item-img" /> */}
            </div>
          </a>
        </div>
      </>
    );
  };

  const b = [
    {
      img: img_4,
      content: ` <p >
      <em >메타12</em>
      은
      <br />
      국내 최대 규모를 자랑하는
      <br />
      담수경식 스마트팜 브랜드 입니다.
    </p>`,
    },
    {
      img: img_5,
      content: ` <p >
      <em >메타12</em>은<br />
      충청북도 괴산군에 <br/>
      담수경식 스마트팜 농장을<br/> 
      운영하고 있습니다.
      
    </p>`,
    },
    {
      img: img_6,
      content: ` <p >
      <em >메타12</em>
      은
      <br />
      유러피안 샐러드 채소를 <br/>
      친환경 스마트팜으로 <br/>
      재배하고 있습니다.
      
    </p>`,
    },
  ];

  const SliderItemList1 = ({ data }) => {
    return (
      <>
        <div className={main.main_slick_div}>
          <a className={main.main_slick_link}>
            <div
              className={main.main_slick_background}
              style={{
                background: `url(/banner/${data.no}/${data.banner_img_m}) no-repeat`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className={main.main_slick_content}>
                {" "}
                {data.banner_text_m && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.banner_text_m.replaceAll(
                        /(\n|\r\n)/g,
                        "<br/>"
                      ),
                    }}
                    className={main.main_slick_content_text}
                  ></div>
                )}
                {/* <p className={main.main_slick_text_p}>

                  
<em className={main.main_slick_text_em}>메타12</em>
은
<br />
국내 최대 규모를 자랑하는
<br />
담수경식 스마트팜 브랜드 입니다.
</p> */}
              </div>

              {/* <img src={data.img} className="slick-item-img" /> */}
            </div>
          </a>
        </div>
      </>
    );
  };
  const slickRef = useRef(null);

  // state = {
  //   slideIndex: 0,
  //   updateCount: 0,
  // };
  const previous = useCallback(() => slickRef.current.slickPrev(), []);
  const next = useCallback(() => slickRef.current.slickNext(), []);

  const [state, setState] = useState(1);

  var settings = {
    centerMode: false,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,

    afterChange: (current) => {
      setState(current + 1);
    },

    // afterChange: () =>
    //   this.setState((state) => ({ updateCount: state.updateCount + 1 })),
    // beforeChange: (current, next) => this.setState({ slideIndex: next }),

    // appendDots: (dots) => (
    //   <div
    //     style={{

    //       padding: "10px",

    //     }}
    //   >
    //     <div className={main.banner_style_inner}>
    //       <ul style={{ margin: "0px" }} className={main.banner_style_dots}>
    //         {" "}
    //         {dots}{" "}
    //       </ul>
    //     </div>
    //   </div>
    // ),
    // customPaging: (i) => (
    //   <div
    //     style={{
    //       width: "100%",
    //       height: "100%",
    //       fontSize: "0",
    //     }}
    //   >
    //     {i + 1}
    //   </div>
    // ),
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };
  const [data, setData] = useState([]);
  const [datam, setDatam] = useState([]);
  const [bannSw, setBannSw] = useState(false);
  useEffect(() => {
    setBannSw(false);
    let sendData = {
      type: 1,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/main_select_banner",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          // if (result.messageinfo.message.length > 0) {

          setData(result.messageinfo.message);

          setBannSw(true);
          // }
        } else {
        }
      }
    );
  }, []);
  return (
    bannSw && (
      <div
        style={{
          width: "100%",
          // height: "400px",
          overflow: "hidden",
          // position: "relative",
        }}
        className={main.main_banner_wrap}
      >
        {/* <h2>Custom Arrows</h2> */}
        {windowSize >= 660 && (
          <Slider
            {...settings}
            style={{ width: "100%", height: "100%" }}
            ref={slickRef}
            // dotsClass={main.test_css}
          >
            {data &&
              data.map((data) => {
                return (
                  // <div style={{ width: "100%", height: "400px" }}>
                  <SliderItemList data={data} />
                  // </div>
                );
              })}
          </Slider>
        )}
        {windowSize < 660 && (
          <Slider
            {...settings}
            style={{ width: "100%", height: "100%" }}
            ref={slickRef}
            // dotsClass={main.test_css}
          >
            {data &&
              data.map((data) => {
                return (
                  // <div style={{ width: "100%", height: "400px" }}>
                  <SliderItemList1 data={data} />
                  // </div>
                );
              })}
          </Slider>
        )}

        <div className={main.main_banner_button_wrap}>
          <div className={main.main_banner_nav}>
            <div
              className={
                state === 1 || state === 2 || state === 3
                  ? `${main.banner_nav} ${main.banner_active}`
                  : `${main.banner_nav}`
              }
            ></div>
            <div
              className={
                state === 2 || state === 3
                  ? `${main.banner_nav} ${main.banner_active}`
                  : `${main.banner_nav}`
              }
            ></div>
            <div
              className={
                state === 3
                  ? `${main.banner_nav} ${main.banner_active}`
                  : `${main.banner_nav}`
              }
            ></div>
          </div>
          {/* <input
          // onChange={(e) => this.slider.slickGoTo(e.target.value)}
          value={state}
          type="range"
          min={1}
          max={3}
        /> */}
          <div className={main.main_banner_button}>
            <div onClick={previous} className={main.main_banner_btn}>
              <div>
                <MdOutlineKeyboardArrowLeft />
              </div>
            </div>

            <div onClick={next} className={main.main_banner_btn}>
              <div>
                <MdOutlineKeyboardArrowRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

// const MainBanner = () => {
//   return (
//     <Carousel touch={true}>
//       <Carousel.Item style={{ height: "400px", background: "#29A555" }}>
//         <img
//           className="d-block w-100"
//           src={img_1}
//           alt="First slide"
//           style={{
//             objectFit: "cover",
//           }}
//         />
//         <div className="common-inner-wrap">
//           <Carousel.Caption>
//             <h3>나만의 투자</h3>
//             {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
//           </Carousel.Caption>
//         </div>
//       </Carousel.Item>
//       <Carousel.Item style={{ height: "400px", background: "#29A555" }}>
//         <img
//           className="d-block w-100"
//           src={img_2}
//           style={{
//             objectFit: "cover",
//           }}
//         />
//         <div className="common-inner-wrap">
//           <Carousel.Caption>
//             <h3>나만의 투자</h3>
//           </Carousel.Caption>
//         </div>
//       </Carousel.Item>
//       <Carousel.Item style={{ height: "400px", background: "#29A555" }}>
//         <img
//           className="d-block w-100"
//           src={img_3}
//           style={{
//             objectFit: "cover",
//           }}
//         />
//         <div className="common-inner-wrap">
//           <Carousel.Caption>
//             <h3>나만의 투자</h3>
//           </Carousel.Caption>
//         </div>
//       </Carousel.Item>
//     </Carousel>
//   );
// };
export default MainBanner;
