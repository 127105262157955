export function wonComma(value) {
  let splitNum = `${value}`.split(".")[0];
  let stringNum = `${splitNum}`;
  return stringNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function wonCommaFix(value) {
  let splitNum = `${Number(value).toFixed(1)}`.split(".")[0];
  let splitNum2 = `${Number(value).toFixed(1)}`.split(".")[1];
  let stringNum = `${splitNum}`;

  return splitNum === "0"
    ? "0" + (splitNum2 ? `.${splitNum2}` : "")
    : stringNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        (splitNum2 ? `.${splitNum2}` : "");

  return stringNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + splitNum2
    ? `.${splitNum2}`
    : "";
  // let splitNum = `${value}`.split(".")[0];
  // let stringNum = `${splitNum}`;
  // return stringNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
