import styles from "../../styles/mypage.module.css";
import { FaUserCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { FcCloseUpMode } from "react-icons/fc";
import { BsCameraFill } from "react-icons/bs";
import pro from "../../assets/images/profile_info.png";
import { useEffect } from "react";
import { textOverCut, getTextTotal } from "../../lib/stringLenghts";
import { useRef, useState, useCallback } from "react";
import { SuccessAlert, MoveAlert, WarringAlert } from "../alert/Alert";

import { getUsers } from "../../modules/user";
const MypagePassDetail = () => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const dispatch = useDispatch();
  const [uiChange, setUiChange] = useState(true);
  const [warringAlert, setWarringAlert] = useState(false);
  const [usePassword, setUsePassword] = useState("");
  const [usePassword1, setUsePassword1] = useState("");
  const [usePassword2, setUsePassword2] = useState("");
  const [warringCont, setwarringCont] = useState("");
  const [changePassColor, setChangePassColor] = useState(true); //패스워드 오류 메세지
  const [changePass2Color, setChangePass2Color] = useState(true); //패스워드 2차 확인 오류 메세지
  const [moveAlert, setMoveAlert] = useState(false);
  const password_test =
    /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{10,}$/;

  const passChange = useCallback((e) => {
    setUsePassword(e.target.value);
  }, []);

  const passChange1 = useCallback((e) => {
    setUsePassword1(e.target.value);
    if (e.target.value === "" || !password_test.test(e.target.value)) {
      setChangePassColor(false);
    } else {
      setChangePassColor(true);
    }
  }, []);

  const passChange2 = useCallback(
    (e) => {
      setUsePassword2(e.target.value);

      if (e.target.value === "" || e.target.value !== usePassword1) {
        setChangePass2Color(false);
      } else {
        setChangePass2Color(true);
      }
    },
    [usePassword1]
  );

  const btn_save = () => {
    if (!usePassword1 || !usePassword2) {
      setWarringAlert(true);
      setwarringCont("빈 칸을 채워주세요.");
      return;
    }
    if (!changePassColor || !changePass2Color) {
      setWarringAlert(true);
      setwarringCont("변경하실 비밀번호를 다시 확인해주세요.");
      return;
    }

    let sendData = {
      no: user.no,
      password: usePassword1,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/user_usepassword_change",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setMoveAlert(true);
        } else {
        }
      }
    );
  };
  const btn_confirm = () => {
    if (!usePassword) {
      setWarringAlert(true);
      setwarringCont("비밀번호를 입력하세요.");
      return;
    }
    let sendData = {
      no: user.no,
      password: usePassword,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/user_usepassword",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          if (result.messageinfo.message === false) {
            setWarringAlert(true);
            setwarringCont("비밀번호가 일치하지않습니다.");
          } else {
            setUiChange(false);
          }
        } else {
        }
      }
    );
  };

  return (
    <>
      {warringAlert && (
        <WarringAlert content={warringCont} setWarringAlert={setWarringAlert} />
      )}

      {/* {successAlert && (
        <SuccessAlert content={successCont} setSuccessAlert={setSuccessAlert} />
      )} */}
      {moveAlert && (
        <MoveAlert
          content={"비밀번호 변경 완료"}
          setMoveAlert={setMoveAlert}
          movePage={"/mypage"}
        />
      )}
      <div className={styles.mypage_content_right}>
        <h3 className={styles.mypage_content_title}>비밀번호 변경</h3>
        <div className={styles.mypage_content_inner}>
          <div className={styles.mypage_password_wrap}>
            {uiChange && (
              <div className={styles.mypage_password_inner}>
                <h4>현재 비밀번호를 입력하세요.</h4>
                <input
                  type="password"
                  value={usePassword}
                  onChange={passChange}
                />
                <button
                  className={styles.mypage_password_confirm}
                  onClick={btn_confirm}
                >
                  확인
                </button>
              </div>
            )}
            {!uiChange && (
              <div className={styles.mypage_password_inner_1}>
                <div className={styles.mypage_password_section}>
                  <h4>변경할 비밀번호</h4>
                  <input
                    type="password"
                    value={usePassword1}
                    onChange={passChange1}
                  />
                  {!changePassColor ? (
                    <em className={styles.error_em}>
                      영문/ 숫자 / 특수문자 중 2가지 이상 조합 (10자 이상)
                    </em>
                  ) : (
                    <em className={styles.noerror_em}>
                      영문/ 숫자 / 특수문자 중 2가지 이상 조합 (10자 이상)
                    </em>
                  )}
                </div>
                <div className={styles.mypage_password_section}>
                  <h4>변경할 비밀번호 확인</h4>
                  <input
                    type="password"
                    value={usePassword2}
                    onChange={passChange2}
                  />{" "}
                  {!changePass2Color ? (
                    <em className={styles.error_em}>
                      비밀번호가 일치하지않습니다.
                    </em>
                  ) : null}
                </div>
                <button
                  className={styles.mypage_password_confirm}
                  onClick={btn_save}
                >
                  변경하기
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MypagePassDetail;
