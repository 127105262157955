import React, { useMemo, useState, useEffect, useRef } from "react";
import CustomTable from "./CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "../CommonAxios";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/admin.module.css";
import { Link, useNavigate } from "react-router-dom";
import NoticeCustomTable from "./table/NoticeCustomTable";
import QnaCustomTable from "./table/QnaCustomTable";
import BannerCustomTable from "./table/BannerCustomTable";
import CompanyCustomTable from "./table/CompanyCustomTable";

const CompanyList = () => {
  const navigate = useNavigate();

  const TableColumn = useMemo(() => [
    {
      Header: "배너 이미지",
      accessor: "banner_img",

      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            <div
              style={{
                cursor: "pointer",
                width: "300px",
                height: "90px",
                margin: "0 auto",
              }}
              // onClick={() => userDetailInfo(oriNo)}
            >
              {data.value && (
                <img
                  src={"/banner/" + oriNo + "/" + data.value}
                  style={{ cursor: "pointer", width: "260px", height: "80px" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    // {
    //   Header: "구분",
    //   accessor: "notice_type",

    //   Cell: ({ cell: { value } }) => (
    //     <>{value == 1 ? "공지사항" : "보도자료"}</>
    //   ),
    // },
    // {
    //   Header: "공개여부",
    //   accessor: "notice_open",
    //   Cell: ({ cell: { value } }) => <>{value == "Y" ? "공개" : "비공개"}</>,
    // },

    // {
    //   Header: "답변여부",
    //   accessor: "qna_answer_yn",
    //   Cell: ({ cell: { value } }) => (
    //     <>{value === "Y" ? "답변완료" : "답변미완료"}</>
    //   ),
    // },
    // {
    //   Header: "",
    //   accessor: "qna_date",
    //   Cell: ({ cell: { value } }) => <Date value={value} />,
    // },
  ]);
  const Date = ({ value }) => {
    if (value) {
      return <Moment format="YYYY.MM.DD.">{value}</Moment>;
    } else {
      return <>수정기록없음</>;
    }
  };
  const Title = ({ row }) => {
    return (
      <div
        style={{ display: "flex", width: "200px", cursor: "pointer" }}
        // onClick={() => userDetailInfo(row.original.no)}
      >
        <div
          style={{
            height: "40px",
            lineHeight: "40px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.original.qna_title}
        </div>
      </div>
    );
  };

  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState(1);
  const [options2, setOptions2] = useState(1);
  const [banSw, setBanSw] = useState(false);
  useEffect(() => {
    setBanSw(false);
    let sendData = {
      type: options,
      no: options2,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_intro",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
          setBanSw(true);
        } else {
          setBanSw(true);
        }
      }
    );
  }, [options, options2]);

  // useEffect(() => {
  //   if (arrayList.length > 0) {
  //     if (options == "all") {
  //       if (filterWord == null) {
  //         setSendList(arrayList);
  //       } else {
  //         let list = arrayList.filter(
  //           (a) => a.qna_title.includes(filterWord) == true
  //         );
  //         setSendList(list);
  //       }
  //     } else if (options == "normal") {
  //       const norList = arrayList.filter((a) => a.user_kind == 3);
  //       if (filterWord == null) {
  //         setSendList(norList);
  //       } else {
  //         let list = norList.filter(
  //           (a) => a.arrayList.includes(filterWord) == true
  //         );
  //         setSendList(list);
  //       }
  //     } else {
  //       const admnList = arrayList.filter((a) => a.user_kind == 1);
  //       if (filterWord == null) {
  //         setSendList(admnList);
  //       } else {
  //         let list = admnList.filter(
  //           (a) => a.arrayList.includes(filterWord) == true
  //         );
  //         setSendList(list);
  //       }
  //     }
  //   }
  // }, [arrayList, filterWord, options]);

  // const filterUser = (e) => {
  //   setFilterWord(e.target.value);
  // };

  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          회사소개 &nbsp;〉 &nbsp;회사소개 및 자세히보기
        </div>
        <div className={styles.wrap_content_box_body}>
          {/* <div className={styles.user_search}>
            <span className={styles.search_icon}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </span>
            <input
              type="text"
              onChange={(e) => filterUser(e)}
              placeholder="찾고자 하는 제목을 검색하세요."
            />
          </div> */}

          {arrayList.length > 0 && (
            <CompanyCustomTable
              columns={TableColumn}
              data={arrayList}
              options={options}
              setOptions={setOptions}
              options2={options2}
              setOptions2={setOptions2}
              arrayList={arrayList}
              setArrayList={setArrayList}
              setSendList={setSendList}
              banSw={banSw}
              setBanSw={setBanSw}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyList;
