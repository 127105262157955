import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { MoveAlert } from "../../components/alert/Alert";

// Swal.fire({
// 	title: "접근할 수 없는 페이지입니다.",
// 	icon: "error",
// 	confirmButtonText: "확인",
// 	confirmButtonColor: "#FF0000",
// }).then((res) => {
// 	if (res.isConfirmed) {
// 		document.location.href = '/login'
// 	}
// });

function PrivateRouteAdmin({ authenticated, component }) {
  const [moveAlert, setMoveAlert] = useState(false);
  return authenticated !== null ? (
    authenticated.ur_kind === 1 || authenticated.ur_kind === 2 ? (
      component
    ) : (
      <MoveAlert
        content={"관리자 권한"}
        setMoveAlert={setMoveAlert}
        movePage={"/"}
      />
    )
  ) : (
    <MoveAlert
      content={"로그인 필요"}
      setMoveAlert={setMoveAlert}
      movePage={"/login"}
    />
  );
  // : Swal.fire({
  //     title: "로그인 필요",

  //     allowOutsideClick: false,

  //     confirmButtonText: "확인",
  //   }).then((res) => {
  //     if (res.isConfirmed) {
  //       document.location.replace("/login");
  //     }
  //   });
}
// export function PrivateRouteAdmin({ authenticated, component: Component }) {
//   return authenticated
//     ? Component
//     : Swal.fire({
//         title: "로그인 필요",
//         text: "로그인 하시겠습니까?",
//         icon: "error",
//         showDenyButton: true,
//         confirmButtonColor: "#3085d6",
//         confirmButtonText: "확인",
//         denyButtonText: "취소",
//       }).then((res) => {
//         if (res.isConfirmed) {
//           document.location.replace("/login");
//         }
//       });
// }
export default PrivateRouteAdmin;
