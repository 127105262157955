import React, { useState, useEffect, useRef } from "react";
import CustomTable from "./CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "../CommonAxios";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/admin.module.css";
import { Link, useNavigate } from "react-router-dom";
import NoticeCustomTable from "./table/NoticeCustomTable";
import StoryCustomTable from "./table/StoryCustomTable";
import ReviewCustomTable from "./table/ReviewCustomTable";
const ReviewList = () => {
  const navigate = useNavigate();
  const userDetailInfo = (no) => {
    navigate(`/admin/review/detail/${no}`);
  };
  const TableColumn = [
    {
      Header: "제목",
      accessor: "review_content",

      Cell: ({ row }) => <Title row={row} />,
    },

    {
      Header: "이름",
      accessor: "review_user_name",

      Cell: ({ cell: { value } }) => <>{value}</>,
    },
    {
      Header: "연령",
      accessor: "review_user_age",

      Cell: ({ cell: { value } }) => <>{value}대</>,
    },
    // {
    //   Header: "공개여부",
    //   accessor: "review_open",
    //   Cell: ({ cell: { value } }) => <>{value == "Y" ? "공개" : "미공개"}</>,
    // },
    {
      Header: "게시날짜",
      accessor: "review_date",
      Cell: ({ cell: { value } }) => <Date value={value} />,
    },
  ];
  const Date = ({ value }) => {
    if (value) {
      return <Moment format="YYYY.MM.DD.">{value}</Moment>;
    } else {
      return <>수정기록없음</>;
    }
  };
  const Title = ({ row }) => {
    return (
      <div
        style={{ display: "flex", maxWidth: "600px", cursor: "pointer" }}
        onClick={() => userDetailInfo(row.original.no)}
      >
        <div
          style={{
            width: "100%",
            height: "40px",
            lineHeight: "40px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.original.review_content}
        </div>
      </div>
    );
  };
  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState("all");

  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_review",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
    // let sendData = {};

    // CommonAxios.CommonAxios(
    //     process.env.REACT_APP_HOSTDONAME + "/api/select_users",
    //     sendData,
    //     function (result) {
    //       if (result.messageinfo.state == "ok") {
    //         setArrayList(result.messageinfo.message);
    //       } else {
    //           Swal.fire({
    //           title: "조회 실패",
    //           icon: "error",
    //           confirmButtonText: "확인",
    //           }).then((res) => {
    //               if (res.isConfirmed) {
    //               }
    //           });
    //       }
    //     }
    // );
    // setArrayList(UserData);
  }, []);

  useEffect(() => {
    if (arrayList.length > 0) {
      if (options == "all") {
        if (filterWord == null) {
          setSendList(arrayList);
        } else {
          let list = arrayList.filter(
            (a) => a.review_user_name.includes(filterWord) == true
          );
          setSendList(list);
        }
      } else if (options == "normal") {
        const norList = arrayList.filter((a) => a.user_kind == 3);
        if (filterWord == null) {
          setSendList(norList);
        } else {
          let list = norList.filter(
            (a) => a.review_user_name.includes(filterWord) == true
          );
          setSendList(list);
        }
      } else if (options == "develop") {
        const devList = arrayList.filter((a) => a.user_kind == 2);
        if (filterWord == null) {
          setSendList(devList);
        } else {
          let list = devList.filter(
            (a) => a.review_user_name.includes(filterWord) == true
          );
          setSendList(list);
        }
      } else {
        const admnList = arrayList.filter((a) => a.user_kind == 1);
        if (filterWord == null) {
          setSendList(admnList);
        } else {
          let list = admnList.filter(
            (a) => a.review_user_name.includes(filterWord) == true
          );
          setSendList(list);
        }
      }
    }
  }, [arrayList, filterWord, options]);

  const filterUser = (e) => {
    setFilterWord(e.target.value);
  };

  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          게시판 관리 &nbsp;〉 &nbsp;리뷰 목록{" "}
          <span className={styles.header_button}>
            <button
              onClick={() => {
                navigate("/admin/review/write");
              }}
            >
              리뷰 등록
            </button>
          </span>
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.user_search}>
            <span className={styles.search_icon}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </span>
            <input
              type="text"
              onChange={(e) => filterUser(e)}
              placeholder="찾고자 하는 이름을 검색하세요."
            />
          </div>
          {/* 전체사용 일반사용자 */}

          <ReviewCustomTable
            columns={TableColumn}
            data={sendList}
            options={options}
            setOptions={setOptions}
            arrayList={arrayList}
            setArrayList={setArrayList}
            setSendList={setSendList}
          />
        </div>
      </div>
    </>
  );
};

export default ReviewList;
