import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import CustomTable from "./CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "../CommonAxios";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/admin.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import InvestCustomTable from "./table/InvestCustomTable";
import { wonComma } from "../../lib/wonComma";
import { numberToKorean } from "../../lib/numberToKorean";
import AdminDepositOneDetail from "./detail/AdminDepositOneDetail";
import AdminDepositTwoDetail from "./detail/AdminDepositTwoDetail";
import { getJoin } from "../../modules/admin";
import { useSelector, useDispatch } from "react-redux";

const DepositPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { joinArr } = useSelector(({ admin }) => ({
    joinArr: admin.joinArr,
  }));

  useEffect(() => {
    let sendData = {
      no: id,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_deposit_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);

          let arr = [];
          joinArr.map((data) => {
            if (Number(data.no) !== Number(id)) {
              arr.push({ no: data.no });
            }
          });
          dispatch(getJoin(arr));

          // navigate("/mypage/invest");
        } else {
        }
      }
    );
  }, []);

  const getDateDiff = (d1, d2) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);

    const diffDate = date1.getTime() - date2.getTime();

    return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
  };

  const apply = () => {
    // 내일 날짜부터 Start
    let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
    let tomorrowex = moment(
      new Date().setDate(new Date().getDate() + 1)
    ).format("YYYY-MM-DD 00:00:00");

    let endDate = moment(
      tomorrow.toLocaleDateString().replace(/\./g, "").replace(/\s/g, "-")
    )
      .add(data[0].pro_join, "months")
      .format("YYYY-MM-DD 00:00:00");

    let allDay = getDateDiff(tomorrowex, endDate);

    const number =
      (data[0].invest_amount * (data[0].pro_rate_change / 100)) /
      parseInt(allDay);
    const int = number.toString().split(".")[0];
    const twoDecimal = number.toString().split(".")[1].slice(0, 3);
    const toDayInterest = `${int}.${twoDecimal}`;

    // let toDayInterest = (
    //   (data[0].invest_amount * (data[0].invest_rate / 100)) /
    //   parseInt(allDay)
    // ).toFixed(3);

    let sendData = {
      user_id: data[0].invest_user_id,
      product_id: data[0].invest_product_no,
      invest_id: data[0].invest_no,
      toDayInterest: toDayInterest,
      start: tomorrowex,
      end: endDate,
      day: allDay,
      amount: data[0].invest_amount,
      upDown: data[0].invest_rate >= 0 ? "U" : "D",
      timer: timer,
      rate: data[0].invest_rate,
    };
    // let sendData = {
    //   no: id,
    // };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_invest_comfirm_insert",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setModal(false);

          Swal.fire({
            title: "승인 완료",

            confirmButtonText: "확인",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/admin/deposit");
            }
          });
        } else {
        }
      }
    );
  };
  const [modal, setModal] = useState(false);
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);

  const [timer, setTimer] = useState("");
  const [amount, setAmount] = useState("");
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const onChangeTimer = useCallback((e) => {
    setTimer(e.target.value);
  }, []);

  const onChangeAmount = useCallback((e) => {
    setAmount(e.target.value);
  }, []);

  const stepFun = () => {
    if (
      !RegExp(
        /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]5[0-9]):(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]5[0-9])$/
      ).test(timer)
    ) {
      setError1(true);
      return;
    }
    if (!RegExp(/[0-9]/).test(amount)) {
      setError2(true);
      return;
    }

    setStep2(true);
  };
  return (
    <>
      <div className={styles.wrap_content_box}>
        {modal && (
          <>
            <div className={styles.invest_deposit_modal_back}></div>
            <div className={styles.invest_deposit_modal_wrap}>
              {" "}
              <div className={styles.invest_deposit_modal_box}>
                {modal && step1 && !step2 && (
                  <div className={styles.invest_deposit_modal_1}>
                    <div className={styles.invest_deposit_modal1_tit}>
                      승인절차
                    </div>
                    <p>입금시간과 입금금액을 정확히 입력하세요.</p>
                    <div className={styles.invest_deposit_modal1_div}>
                      <div className={styles.invest_deposit_modal1_span}>
                        <div className={styles.invest_deposit_modal1_inpbox}>
                          <label>입금시간</label>
                          <input
                            type="text"
                            placeholder="ex) 0000-00-00 00:00:00"
                            onChange={onChangeTimer}
                            value={timer}
                          />{" "}
                        </div>{" "}
                        {error1 && <span>입금시간을 정확히 입력해주세요.</span>}
                      </div>
                      <div className={styles.invest_deposit_modal1_span}>
                        <div className={styles.invest_deposit_modal1_inpbox}>
                          <label>입금금액</label>
                          <input
                            type="text"
                            placeholder="숫자만 입력하세요."
                            onChange={onChangeAmount}
                            value={amount}
                          />{" "}
                        </div>{" "}
                        {error2 && <span>입금금액을 정확히 입력해주세요.</span>}
                      </div>{" "}
                    </div>
                    <div className={styles.invest_deposit_modal1_button}>
                      <button
                        onClick={() => {
                          stepFun();
                        }}
                      >
                        확인
                      </button>
                      <button
                        onClick={() => {
                          setModal(false);
                          setStep1(false);
                        }}
                      >
                        취소
                      </button>
                    </div>
                  </div>
                )}
                {modal && step1 && step2 && (
                  <div className={styles.invest_deposit_modal_2}>
                    <div className={styles.invest_deposit_modal1_tit}>
                      승인절차
                    </div>
                    <p>입금시간과 입금금액을 정확히 입력하셨습니까?</p>

                    <div className={styles.invest_deposit_modal1_button}>
                      <button
                        onClick={() => {
                          apply();
                        }}
                      >
                        확인
                      </button>
                      <button
                        onClick={() => {
                          setModal(false);
                          setStep1(false);
                          setStep2(false);
                        }}
                      >
                        취소
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className={styles.wrap_content_box_header}>
          투자 가입 신청 &nbsp;〉 &nbsp;가입 상세 내역
        </div>
        <div className={styles.wrap_content_box_body}>
          {data.length > 0 && <AdminDepositOneDetail data={data[0]} />}
          {data.length > 0 && (
            <AdminDepositTwoDetail
              data={data[0]}
              setModal={setModal}
              setStep1={setStep1}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DepositPage;
