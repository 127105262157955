import React, { useState, useEffect, useRef } from "react";
import ProductTable from "../components/admin/details/ProductTable";
import styles from "../styles/admin.module.css";
import { useNavigate } from "react-router-dom";
import { CommonAxios } from "../components/CommonAxios";
const ProductList = () => {
  const navigate = useNavigate();

  const [cateogry, setCategory] = useState([]);
  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME +
        "/api/admin_select_product_category_list",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setCategory(result.messageinfo.message);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
  }, []);
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          펀딩+ &nbsp;〉 &nbsp;투자 상품 목록{" "}
          <span className={styles.header_button}>
            <button
              onClick={() => {
                navigate("/admin/product/write");
              }}
            >
              투자 상품 등록
            </button>
          </span>
        </div>
        <div className={styles.wrap_content_box_body}>
          {cateogry && <ProductTable cateogry={cateogry} />}
        </div>
      </div>
    </>
  );
};

export default ProductList;
