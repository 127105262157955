import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
} from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import styles from "../../../styles/admin.module.css";
import Swal from "sweetalert2";
import { CommonAxios } from "../../CommonAxios";
import { textReplace } from "../../../lib/textReplace";
import axios from "axios";
import { ramdomASCII } from "../../../lib/radomASCII";
function BannerCustomTable({
  columns,
  data,
  options,
  setOptions,
  options2,
  setOptions2,
  arrayList,
  setArrayList,
  setSendList,
  banSw,
  setBanSw,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    selectedFlatRows,
    prepareRow,
    //   canPreviousPage,
    //   canNextPage,
    pageOptions,
    pageCount, // 총 페이지
    gotoPage,
    //   nextPage,
    //   previousPage,
    //   setPageSize,
    state: { pageIndex, pageSize }, // pageIndex : 현재 페이지( 0 ~ 총페이지-1 ex) 1페이지 : 0 )
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // getToggleAllPageRowsSelectedProps : 페이지에 보여지는 row 전체 선택
          // getToggleAllRowsSelectedProps : 모든 row 전체 선택
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  //체크박스 옵션
  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            style={{ width: "32px" }}
          />
        </>
      );
    }
  );

  const btn_send = () => {
    // console.log(selectedFlatRows);
  };

  const [versionCheck, setVersionCheck] = useState("p");

  const changeValue = (e) => {
    setVersionCheck(e.target.value);
  };

  const [text1, setText1] = useState("");
  const [text11, setText11] = useState("");

  const [text2, setText2] = useState("");
  const [text22, setText22] = useState("");
  const [text3, setText3] = useState("");
  const [text33, setText33] = useState("");
  const [text4, setText4] = useState("");
  const [text5, setText5] = useState("");
  const [text44, setText44] = useState("");
  const [text55, setText55] = useState("");
  const [text6, setText6] = useState("");
  const [text66, setText66] = useState("");
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  useEffect(() => {
    if (data.length > 0) {
      if (data[0].banner_page !== "4" && data[0].banner_page !== "5") {
        if (versionCheck === "p") {
          if (data[0].banner_text) {
            setText1(
              data[0].banner_text.replaceAll("<p>", "").replaceAll("</p>", "")
            );
          } else {
            setText1("");
          }
        }
        if (versionCheck === "m") {
          if (data[0].banner_text_m) {
            setText1(
              data[0].banner_text_m.replaceAll("<p>", "").replaceAll("</p>", "")
            );
          } else {
            setText1("");
          }
        }
      } else if (data[0].banner_page === "4") {
        if (data[0].banner_text) {
          setText2(
            data[0].banner_text.replaceAll("<p>", "").replaceAll("</p>", "")
          );
        } else {
          setText2("");
        }
        if (data[0].banner_text2) {
          setText3(
            data[0].banner_text2
              .replaceAll("<span>", "")
              .replaceAll("</span>", "")
          );
        } else {
          setText3("");
        }
      } else {
        if (data[0].banner_text) {
          setText4(data[0].banner_text);
        } else {
          setText4("");
        }
        if (data[0].banner_text2) {
          setText5(data[0].banner_text2);
        } else {
          setText5("");
        }
        if (data[0].banner_link) {
          setText6(data[0].banner_link);
        } else {
          setText6("");
        }
      }
    }
  }, [banSw, versionCheck]);
  const onText1 = useCallback((e) => {
    setText1(e.target.value);
    setText11("<p>" + e.target.value + "</p>");
  }, []);
  const onText2 = useCallback((e) => {
    setText2(e.target.value);
    setText22("<p>" + e.target.value + "</p>");
  }, []);
  const onText3 = useCallback((e) => {
    setText3(e.target.value);
    setText33("<span>" + e.target.value + "</span>");
  }, []);

  const onText4 = useCallback((e) => {
    setText4(e.target.value);
    setText44(e.target.value);
  }, []);
  const onText5 = useCallback((e) => {
    setText5(e.target.value);
    setText55(e.target.value);
  }, []);
  const onText6 = useCallback((e) => {
    setText6(e.target.value);
    setText66(e.target.value);
  }, []);
  const [imageFile, setImageFile] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const [imageFile3, setImageFile3] = useState("");
  const [imageFile4, setImageFile4] = useState("");

  const [imageFileN, setImageFileN] = useState("");
  const [imageFileN2, setImageFileN2] = useState("");
  const [imageFileN3, setImageFileN3] = useState("");
  const [imageFileN4, setImageFileN4] = useState("");

  const [imageFileNBefore, setImageFileNBefore] = useState("");
  const [imageFileN2Before, setImageFileN2Before] = useState("");
  const [imageFileN3Before, setImageFileN3Before] = useState("");
  const [imageFileN4Before, setImageFileN4Before] = useState("");

  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN(`${result}.${imgExtension[1]}`);
    // if (versionCheck === "p") {
    //   setImageFileN(`banner_${data[0].no}.${imgExtension[1]}`);
    // }

    // if (versionCheck === "m") {
    //   setImageFileN(`banner_m_${data[0].no}.${imgExtension[1]}`);
    // }

    setImageFile(file);
  };

  useEffect(() => {
    preview(); // 미리보기
  }, [imageFile]);

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };

  const [bgImg, setBgImg] = useState("");
  const textReset = () => {
    setText1("");
    setText11("");
    setText2("");
    setText22("");
    setText3("");
    setText33("");

    setBgImg("");
  };

  const btn_text_save = () => {
    Swal.fire({
      title: "변경 진행",
      text: "위 문구로 변경하시겠습니까?",

      confirmButtonText: "확인",
      cancelButtonText: "취소",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (!text11 && !text22 && !text33 && !text44 && !text55 && !text66) {
          return Swal.fire({
            // title: "",
            text: "변경할 문구가 없습니다.",

            confirmButtonText: "확인",
          });
        }
        let sendData = {
          no: data[0].no,
          banner_text: "",
          banner_text2: "",
          banner_text_m: "",
          banner_link: "",
        };

        if (options === 4) {
          sendData.banner_text = textReplace(text2);
          sendData.banner_text2 = textReplace(text3);
        } else if (options === 5) {
          sendData.banner_text = textReplace(text4);
          sendData.banner_text2 = textReplace(text5);
          sendData.banner_link = textReplace(text6);
        } else {
          if (versionCheck === "p") {
            sendData.banner_text = textReplace(text11);
          }
          if (versionCheck === "m") {
            sendData.banner_text_m = textReplace(text11);
          }
        }

        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/admin_update_banner_text",
          sendData,
          function (result) {
            if (result.messageinfo.state == "ok") {
              let sendData2 = {
                type: options,
                no: options2,
              };
              CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin_select_banner",
                sendData2,
                function (result) {
                  if (result.messageinfo.state == "ok") {
                    Swal.fire({
                      title: "변경 완료",
                      // text: "변경할 문구가 없습니다.",

                      confirmButtonText: "확인",
                    });
                    setArrayList(result.messageinfo.message);
                    setBanSw(true);
                  } else {
                    setBanSw(true);
                  }
                }
              );

              // setArrayList(result.messageinfo.message);
              // setBanSw(true);
            } else {
              // setBanSw(true);
            }
          }
        );
      }
    });
  };
  const [imgSw, setImgSw] = useState(false);
  const btn_img_save = () => {
    Swal.fire({
      title: "변경 진행",
      text: "위 이미지로 변경하시겠습니까?",

      confirmButtonText: "확인",
      cancelButtonText: "취소",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (!imageFile[0]) {
          return Swal.fire({
            // title: "",
            text: "변경할 이미지가 없습니다.",

            confirmButtonText: "확인",
          });
        }
        let imgIf = "";
        let beforImg = "";

        if (data[0].banner_page !== "4") {
          if (versionCheck === "p") {
            imgIf = "p";
            beforImg = data[0].banner_img;
          }
          if (versionCheck === "m") {
            imgIf = "m";
            beforImg = data[0].banner_img_m;
          }
        } else {
          imgIf = "p";
          beforImg = data[0].banner_img;
        }
        const formData = new FormData();
        formData.append("no", data[0].no);
        formData.append("filename", imageFileN);
        formData.append("fileif", imgIf);
        formData.append("fileBefore", beforImg);
        formData.append("folder", "banner");
        formData.append("file", imageFile[0]);

        axios
          .post(
            process.env.REACT_APP_HOSTDONAME + "/api/admin_upload_banner",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.messageinfo.state === "ok") {
              let sendData2 = {
                type: options,
                no: options2,
              };
              CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin_select_banner",
                sendData2,
                function (result) {
                  if (result.messageinfo.state == "ok") {
                    Swal.fire({
                      title: "변경 완료",
                      // text: "변경할 문구가 없습니다.",

                      confirmButtonText: "확인",
                    });
                    setArrayList(result.messageinfo.message);
                    setBanSw(true);
                  } else {
                    setBanSw(true);
                  }
                }
              );
              // setUploadImage(
              //   uploadImage.concat({
              //     id: uploadImageRef.current++,
              //     imageUrl: `/classImage/${classinfo}/${chapterNum}/${path}/${fileNum}`,
              //     imagename: fileNum,
              //   })
              // );
              // setImageText(imageText + fileNum + ",");
              // // 응답 처리
              // setSpinnerView(false);
            }
          })
          .catch((error) => {
            // 예외 처리
          });
      }
    });
  };
  return (
    <>
      <div className={styles.user_header} style={{ marginBottom: "4px" }}>
        <div className={`${styles.user_option} ${styles.banner_header}`}>
          <div
            className={
              options == 1 ? `${styles.all} ${styles.clicked}` : styles.all
            }
            onClick={() => {
              setOptions(1);
              setOptions2(1);
              setVersionCheck("p");
              textReset();
            }}
          >
            메인
          </div>
          <div
            className={
              options == 2
                ? `${styles.noraml} ${styles.clicked}`
                : styles.noraml
            }
            onClick={() => {
              setOptions(2);
              setOptions2(4);
              setVersionCheck("p");
              textReset();
            }}
          >
            펀딩+
          </div>
          <div
            className={
              options == 6
                ? `${styles.develop} ${styles.clicked}`
                : styles.develop
            }
            onClick={() => {
              setOptions(6);
              setOptions2(13);
              setVersionCheck("p");
              textReset();
            }}
          >
            투자현황
          </div>{" "}
          <div
            className={
              options == 3
                ? `${styles.develop} ${styles.clicked}`
                : styles.develop
            }
            onClick={() => {
              setOptions(3);
              setOptions2(8);
              setVersionCheck("p");
              textReset();
            }}
          >
            스토어
          </div>{" "}
          <div
            className={
              options == 4
                ? `${styles.develop} ${styles.clicked}`
                : styles.develop
            }
            onClick={() => {
              setOptions(4);
              setOptions2(11);
              setVersionCheck("p");
              textReset();
            }}
          >
            자세히 알아보기
          </div>{" "}
          <div
            className={
              options == 5
                ? `${styles.develop} ${styles.clicked}`
                : styles.develop
            }
            onClick={() => {
              setOptions(5);
              setOptions2(12);
              setVersionCheck("p");
              textReset();
            }}
          >
            투자하러가기
          </div>
        </div>
        {/* <div className={styles.user_button}>
          <button className={styles.btn_sns_send} onClick={btn_send}>
            문자전송
          </button>
        </div> */}
      </div>{" "}
      {!banSw && (
        <div className={`${styles.user_header} ${styles.user_header2}`}>
          <div className={styles.user_option}>
            <div className={styles.noraml}></div>
          </div>
        </div>
      )}{" "}
      {banSw && (options == 4 || options == 5) && (
        <div className={`${styles.user_header} ${styles.user_header2}`}>
          <div className={styles.user_option}>
            <div className={styles.noraml}></div>
          </div>
        </div>
      )}
      {banSw && options == 1 && (
        <div className={`${styles.user_header} ${styles.user_header2}`}>
          <div className={styles.user_option}>
            <div
              className={
                options2 == 1
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(1);
                textReset();
              }}
            >
              <span>첫번째</span>
            </div>
            <div
              className={
                options2 == 2
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(2);
                textReset();
              }}
            >
              {" "}
              <span>두번째</span>
            </div>
            <div
              className={
                options2 == 3
                  ? `${styles.develop} ${styles.clicked}`
                  : styles.develop
              }
              onClick={() => {
                setOptions2(3);
                textReset();
              }}
            >
              {" "}
              <span>세번째</span>
            </div>{" "}
          </div>
        </div>
      )}
      {banSw && options == 2 && (
        <div className={`${styles.user_header} ${styles.user_header2}`}>
          <div className={styles.user_option}>
            <div
              className={
                options2 == 4
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(4);
                textReset();
              }}
            >
              <span>첫번째</span>
            </div>
            <div
              className={
                options2 == 5
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(5);
                textReset();
              }}
            >
              {" "}
              <span>두번째</span>
            </div>
            <div
              className={
                options2 == 6
                  ? `${styles.develop} ${styles.clicked}`
                  : styles.develop
              }
              onClick={() => {
                setOptions2(6);
                textReset();
              }}
            >
              {" "}
              <span>세번째</span>
            </div>{" "}
            <div
              className={
                options2 == 7
                  ? `${styles.develop} ${styles.clicked}`
                  : styles.develop
              }
              onClick={() => {
                setOptions2(7);
                textReset();
              }}
            >
              {" "}
              <span>네번째</span>
            </div>
          </div>
        </div>
      )}
      {banSw && options == 3 && (
        <div className={`${styles.user_header} ${styles.user_header2}`}>
          <div className={styles.user_option}>
            <div
              className={
                options2 == 8
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(8);
                textReset();
              }}
            >
              <span>첫번째</span>
            </div>
            <div
              className={
                options2 == 9
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(9);
                textReset();
              }}
            >
              {" "}
              <span>두번째</span>
            </div>
            <div
              className={
                options2 == 10
                  ? `${styles.develop} ${styles.clicked}`
                  : styles.develop
              }
              onClick={() => {
                setOptions2(10);
                textReset();
              }}
            >
              {" "}
              <span>세번째</span>
            </div>{" "}
          </div>
        </div>
      )}
      {banSw && options == 6 && (
        <div className={`${styles.user_header} ${styles.user_header2}`}>
          <div className={styles.user_option}>
            <div
              className={
                options2 == 13
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(13);
                textReset();
              }}
            >
              <span>첫번째</span>
            </div>
            <div
              className={
                options2 == 14
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(14);
                textReset();
              }}
            >
              {" "}
              <span>두번째</span>
            </div>
            <div
              className={
                options2 == 15
                  ? `${styles.develop} ${styles.clicked}`
                  : styles.develop
              }
              onClick={() => {
                setOptions2(15);
                textReset();
              }}
            >
              {" "}
              <span>세번째</span>
            </div>{" "}
          </div>
        </div>
      )}
      <div className={styles.admin_banner_view}>
        <div className={styles.admin_banner_view_check}>
          {options !== 4 && options !== 5 && (
            <div>
              <label style={{ cursor: "pointer" }}>
                <input
                  type="radio"
                  name="version"
                  value="p"
                  onChange={changeValue}
                  checked={versionCheck === "p" ? true : false}
                />
                PC 환경
              </label>{" "}
              <label style={{ cursor: "pointer" }}>
                <input
                  type="radio"
                  name="version"
                  value="m"
                  onChange={changeValue}
                  checked={versionCheck === "m" ? true : false}
                />
                모바일 환경
              </label>
            </div>
          )}
        </div>
        <div className={styles.admin_banner_inner}>
          {banSw && options !== 4 && options !== 5 && versionCheck === "p" && (
            <>
              {" "}
              <div
                className={styles.admin_banner_content}
                style={{
                  background: `url(/banner/${data[0].no}/${data[0].banner_img}) no-repeat center/ cover`,
                }}
              >
                <div className={styles.admin_banner_content_in}>
                  {" "}
                  {data[0].banner_text && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data[0].banner_text.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                      className={styles.main_slick_content_text}
                    ></div>
                  )}
                </div>
              </div>
              <span>[with값 1920px 기준 - 현재 사이트 화면]</span>
              <div
                className={styles.admin_banner_content}
                style={{
                  background: bgImg
                    ? `url(${bgImg}) no-repeat center/ cover`
                    : `url(/banner/${data[0].no}/${data[0].banner_img}) no-repeat center/ cover`,
                }}
              >
                <div className={styles.admin_banner_content_in}>
                  {" "}
                  {text11 && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: text11.replaceAll(/(\n|\r\n)/g, "<br/>"),
                      }}
                      className={styles.main_slick_content_text}
                    ></div>
                  )}{" "}
                  {!text11 && data[0].banner_text && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data[0].banner_text.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                      className={styles.main_slick_content_text}
                    ></div>
                  )}
                </div>
              </div>{" "}
              <span>[with값 1920px 기준 - 미리 보기 화면]</span>
              {/* <div className={styles.admin_banner_change_button}>
                <button>미리보기</button>
              </div> */}
              <div className={styles.admin_banner_change}>
                <div className={styles.admin_banner_left}>
                  <h4>문구 입력</h4>
                  <div className={styles.admin_bnner_change_in}>
                    <textarea onChange={onText1} value={text1}></textarea>
                    <div className={styles.admin_banner_ref}>
                      <h5>입력시 참고사항</h5>
                      <ul>
                        <li>
                          굵은 글씨를 원하시는 한 단어만 em 태그를 이용해주세요.
                          <br />
                          예시: &lt;em &gt; 메타12 &lt;/em &gt;은
                        </li>
                      </ul>
                    </div>
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_text_save}>저장</button>
                    </div>
                  </div>
                </div>
                <div className={styles.admin_banner_right}>
                  <h4>배경 이미지 업로드</h4>{" "}
                  <div className={styles.admin_bnner_change_in}>
                    <div className={styles.admin_banner_img_box}>
                      <input
                        type="file"
                        onChange={inpChange}
                        id="abc"
                        style={{ display: "none" }}
                      />
                      {/* <input
                      className={styles.info_policy_inp}
                      value={imageFileN}
                    /> */}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        이미지 업로드
                      </label>
                    </div>{" "}
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_img_save}>저장</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}{" "}
          {banSw && options !== 4 && options !== 5 && versionCheck === "m" && (
            <>
              <div
                className={styles.admin_banner_content_m}
                style={{
                  background: `url(/banner/${data[0].no}/${data[0].banner_img_m}) no-repeat center /cover`,
                }}
              >
                {" "}
                <div className={styles.admin_banner_content_in_m}>
                  {" "}
                  {data[0].banner_text_m && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data[0].banner_text_m.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                      className={styles.main_slick_content_text_m}
                    ></div>
                  )}
                </div>
              </div>
              <span>[with값 680px 기준 - 현재 사이트 화면]</span>{" "}
              <div
                className={styles.admin_banner_content_m}
                style={{
                  background: bgImg
                    ? `url(${bgImg}) no-repeat center /cover`
                    : `url(/banner/${data[0].no}/${data[0].banner_img_m}) no-repeat center /cover`,
                }}
              >
                {" "}
                <div className={styles.admin_banner_content_in_m}>
                  {" "}
                  {text11 && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: text11.replaceAll(/(\n|\r\n)/g, "<br/>"),
                      }}
                      className={styles.main_slick_content_text_m}
                    ></div>
                  )}{" "}
                  {!text11 && data[0].banner_text_m && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data[0].banner_text_m.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                      className={styles.main_slick_content_text_m}
                    ></div>
                  )}
                </div>
              </div>
              <span>[with값 680px 기준 - 현재 사이트 화면]</span>{" "}
              <div className={styles.admin_banner_change}>
                <div className={styles.admin_banner_left}>
                  <h4>문구 입력</h4>
                  <div className={styles.admin_bnner_change_in}>
                    <textarea onChange={onText1} value={text1}></textarea>
                    <div className={styles.admin_banner_ref}>
                      <h5>입력시 참고사항</h5>
                      <ul>
                        <li>
                          굵은 글씨를 원하시는 한 단어만 em 태그를 이용해주세요.
                          <br />
                          예시: &lt;em &gt; 메타12 &lt;/em &gt;은
                        </li>
                      </ul>
                    </div>
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_text_save}>저장</button>
                    </div>
                  </div>
                </div>
                <div className={styles.admin_banner_right}>
                  <h4>배경 이미지 업로드</h4>{" "}
                  <div className={styles.admin_bnner_change_in}>
                    <div className={styles.admin_banner_img_box}>
                      <input
                        type="file"
                        onChange={inpChange}
                        id="abc"
                        style={{ display: "none" }}
                      />
                      {/* <input
                      className={styles.info_policy_inp}
                      value={imageFileN}
                    /> */}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        이미지 업로드
                      </label>
                    </div>{" "}
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_img_save}>저장</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {banSw && options === 4 && (
            <>
              <div
                className={styles.intro_banner_wrap}
                style={{
                  background: `url(/banner/${data[0].no}/${data[0].banner_img}) no-repeat `,
                  backgroundSize: "cover",
                }}
              >
                {/* <div className="common-inner-wrap"> */}
                <div className={styles.intro_banner_inner}>
                  <div className={styles.intro_banner_text}>
                    {data[0].banner_text && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[0].banner_text.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></p>
                    )}
                    {data[0].banner_text2 && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data[0].banner_text2.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></span>
                    )}
                  </div>
                </div>
                {/* </div> */}
              </div>
              <span>[with값 1920px 기준 - 현재 사이트 화면]</span>
              <div
                className={styles.intro_banner_wrap}
                style={{
                  background: bgImg
                    ? `url(${bgImg}) no-repeat 100%/ cover`
                    : `url(/banner/${data[0].no}/${data[0].banner_img}) no-repeat  `,
                  backgroundSize: "cover",
                  overflow: "hidden",
                }}
              >
                {/* <div className="common-inner-wrap"> */}
                <div className={styles.intro_banner_inner}>
                  <div className={styles.intro_banner_text}>
                    {text2 && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: text2.replaceAll(/(\n|\r\n)/g, "<br/>"),
                        }}
                      ></p>
                    )}{" "}
                    {text3 && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: text3.replaceAll(/(\n|\r\n)/g, "<br/>"),
                        }}
                      ></span>
                    )}{" "}
                  </div>
                </div>
                {/* </div> */}
              </div>
              <span>[with값 1920px 기준 - 미리 보기 화면]</span>{" "}
              <div className={styles.admin_banner_change}>
                <div className={styles.admin_banner_left}>
                  <h4>문구 입력</h4>
                  <div
                    className={`${styles.admin_bnner_change_in} ${styles.admin_bnner_change_detai}`}
                  >
                    <p>큰 타이틀</p>
                    <textarea onChange={onText2} value={text2}></textarea>{" "}
                    <p>설명글</p>
                    <textarea onChange={onText3} value={text3}></textarea>
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_text_save}>저장</button>
                    </div>
                  </div>
                </div>
                <div className={styles.admin_banner_right}>
                  <h4>배경 이미지 업로드</h4>{" "}
                  <div className={styles.admin_bnner_change_in}>
                    <div className={styles.admin_banner_img_box}>
                      <input
                        type="file"
                        onChange={inpChange}
                        id="abc"
                        style={{ display: "none" }}
                      />
                      {/* <input
                      className={styles.info_policy_inp}
                      value={imageFileN}
                    /> */}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        이미지 업로드
                      </label>
                    </div>{" "}
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_img_save}>저장</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}{" "}
          {banSw && options === 5 && (
            <>
              {/* <div
                className={styles.intro_banner_wrap}
                style={{
                  background: `url(/banner/${data[0].no}/${data[0].banner_img}) no-repeat `,
                  backgroundSize: "cover",
                }}
              >
              
                <div className={styles.intro_banner_inner}>
                  <div className={styles.intro_banner_text}>
                    {data[0].banner_text && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[0].banner_text.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></p>
                    )}
                    {data[0].banner_text2 && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data[0].banner_text2.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></span>
                    )}
                  </div>
                </div>
             
              </div> */}{" "}
              <div
                className={styles.main_section3_wrap}
                // onClick={() => btn_move()}
              >
                <div
                  className={styles.main_section3_imgbox}
                  style={{
                    background: `url(/banner/${data[0].no}/${data[0].banner_img}) no-repeat center`,
                  }}
                  // onClick={() => btn_move()}
                >
                  {/* <img src={img_1} /> */}
                  <div className={styles.main_section3_content}>
                    {data[0].banner_text && (
                      <h3
                        className={styles.main_section3_h3}
                        dangerouslySetInnerHTML={{
                          __html: data[0].banner_text.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></h3>
                    )}
                    {data[0].banner_text2 && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[0].banner_text2.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></p>
                    )}

                    <button>투자하러 가기</button>
                  </div>
                </div>
              </div>
              <span>[with값 1920px 기준 - 현재 사이트 화면]</span>
              <div
                className={styles.main_section3_wrap}
                // onClick={() => btn_move()}
              >
                <div
                  className={styles.main_section3_imgbox}
                  style={{
                    background: bgImg
                      ? `url(${bgImg}) no-repeat center/ cover`
                      : `url(/banner/${data[0].no}/${data[0].banner_img}) no-repeat center/ cover`,
                  }}
                  // onClick={() => btn_move()}
                >
                  {/* <img src={img_1} /> */}
                  <div className={styles.main_section3_content}>
                    {text4 && (
                      <h3
                        className={styles.main_section3_h3}
                        dangerouslySetInnerHTML={{
                          __html: text4.replaceAll(/(\n|\r\n)/g, "<br/>"),
                        }}
                      ></h3>
                    )}
                    {text5 && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: text5.replaceAll(/(\n|\r\n)/g, "<br/>"),
                        }}
                      ></p>
                    )}

                    <button>투자하러 가기</button>
                  </div>
                </div>
              </div>
              <span>[with값 1920px 기준 - 미리 보기 화면]</span>{" "}
              <div className={styles.admin_banner_change}>
                <div className={styles.admin_banner_left}>
                  <h4>문구 입력</h4>
                  <div
                    className={`${styles.admin_bnner_change_in} ${styles.admin_bnner_change_detai}`}
                    style={{ minHeight: "400px" }}
                  >
                    <p>큰 타이틀</p>
                    <textarea onChange={onText4} value={text4}></textarea>{" "}
                    <p>설명글</p>
                    <textarea onChange={onText5} value={text5}></textarea>
                    <p>링크</p>
                    <input type="text" onChange={onText6} value={text6} />{" "}
                    <div className={styles.admin_banner_ref}>
                      <h5>입력시 참고사항</h5>
                      <ul>
                        <li>
                          주소는 meta12.net만 가능합니다.
                          <br />
                          입력시 https://meta12.net/funding 이 형식으로
                          입력바랍니다.
                        </li>
                      </ul>
                    </div>
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_text_save}>저장</button>
                    </div>
                  </div>
                </div>
                <div className={styles.admin_banner_right}>
                  <h4>배경 이미지 업로드</h4>{" "}
                  <div
                    className={styles.admin_bnner_change_in}
                    style={{ minHeight: "400px" }}
                  >
                    <div className={styles.admin_banner_img_box}>
                      <input
                        type="file"
                        onChange={inpChange}
                        id="abc"
                        style={{ display: "none" }}
                      />
                      {/* <input
                      className={styles.info_policy_inp}
                      value={imageFileN}
                    /> */}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        이미지 업로드
                      </label>
                    </div>{" "}
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_img_save}>저장</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}{" "}
        </div>
      </div>
    </>
  );
}

export default BannerCustomTable;
