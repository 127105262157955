import React, { useMemo, useState, useEffect, useRef } from "react";
import notice from "../../styles/notice.module.css";
import { CommonAxios } from "../CommonAxios";
import NoticeCustomTable from "./NoticeCustomTable";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/Logo.png";
import Moment from "react-moment";
import ContDetail from "./detail/ContDetail";

const NoticeContDetail = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    let sendData = {
      no: id,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_notice_view",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setData(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);

  return (
    <>
      <div className={`common-wrap ${notice.notice_wrap_color}`}>
        <div className="common-inner-wrap">
          <div className={notice.notice_wrap}>
            <h2>공지사항</h2>

            <div className={notice.notice_content_wrap}>
              {data.length > 0 && <ContDetail data={data} />}
            </div>
            <div className={notice.notice_content_bottom}>
              <button onClick={() => navigate(-1)}>목록으로 돌아가기</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeContDetail;
