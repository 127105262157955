import MypageInfoDetail from "../components/mypage/MypageInfoDetail";

const MypageInfo = () => {
  return (
    <>
      <MypageInfoDetail />
    </>
  );
};

export default MypageInfo;
