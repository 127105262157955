import MypagePassDetail from "../components/mypage/MypagePassDetail";

const MypagePass = () => {
  return (
    <>
      <MypagePassDetail />
    </>
  );
};

export default MypagePass;
