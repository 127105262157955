import inv from "../../../styles/invest.module.css";
import React, { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";

import { MdKeyboardArrowRight } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CommonAxios } from "../../CommonAxios";
import axios from "axios";
import { TfiCreditCard, TfiWorld } from "react-icons/tfi";
import { TbCircleCheckFilled, TbCheck } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import Swal from "sweetalert2";
import { getAmount } from "../../../modules/invest";
const ProcessSection_3 = ({ data, setWarringAlert, setContent, data1 }) => {
  const [method, setMethod] = useState("card");
  const dispatch = useDispatch();
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const navigate = useNavigate();
  const [invCash, setInvCash] = useState(0);
  const [profit, setProfit] = useState(0);
  const [starts, setStarts] = useState(0);

  const btn_join = () => {
    if (!invCash) {
      return alert("금액을 입력하세요.");
    }
    let sendData = {
      user_id: user.no,
      amount: Number(invCash + "0000"),
      pro_id: data.no,
      invest_id: new Date().getTime(),
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/invest_insert_join",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          navigate("/mypage/invest");
        } else {
        }
      }
    );
  };
  useEffect(() => {
    if (invCash) {
      if (invCash == 0) {
      } else if (invCash > 1000) {
        setProfit(invCash * (data.pro_rate / 100));
        setStarts(profit);
        setInvCash(1000);
      } else {
        setProfit(invCash * (data.pro_rate / 100));
        setStarts(profit);
      }
    }
  }, [invCash]);

  const chgCash = (e) => {
    if (!/[0-9]/g.test(Number(e.target.value))) {
      return setInvCash(0);
    }
    if (e.target.value > 1000) {
      setInvCash(Number(1000));
    } else {
      setInvCash(Number(e.target.value));
    }
  };
  const { id } = useParams();
  const { id2 } = useParams();

  const onClickPayment = () => {
    if (!invCash || invCash === 0) {
      setContent("가입금액을 입력하세요.");
      setWarringAlert(true);

      return;
    }

    if (!ch1 || !ch3 || !ch4 || !ch5 || !ch6) {
      setContent("이용약관을 체크해주세요.");
      setWarringAlert(true);

      return;
    }

    // return inspectModal();

    // setClickTime(false);

    // if (user.ur_mobile === "" || user.ur_mobile === null) {
    //   return Swal.fire({
    //     title: "결제 실패",
    //     text: "핸드폰 번호 등록이 필요합니다.",
    //     icon: "error",
    //     confirmButtonColor: "#3085d6",
    //     confirmButtonText: "확인",
    //     allowOutsideClick: false,
    //   });
    // }

    // let localData = JSON.parse(localStorage.getItem("paymentInfo"));

    var today = new Date();
    var hours = today.getHours(); // 시
    var minutes = today.getMinutes(); // 분
    var seconds = today.getSeconds(); // 초
    var milliseconds = today.getMilliseconds();
    var makeMerchantUid = hours + minutes + seconds + milliseconds;
    var newDat = new Date().getTime();

    let viewDate = new Date();
    // let endDate = moment(viewDate)
    //   .add(30 * localData.payment_day, "days")
    //   .format("YYYY-MM-DD");

    let sendData = {
      payment_merchant_uid: `mid_${newDat}`, //주문번호
      payment_name: data.pro_title,
      payment_user_name: user.ur_name, // 이름
      payment_user_mobile: user.ur_mobile, // 번호
      payment_user_email: user.ur_email, // 이메일
      payment_user_id: user.no,
      payment_amount: Number(invCash + "0000"), // 요금
      payment_product_id: data.no,

      payment_join_date: data.pro_termofpolicy,

      payment_rate: data.pro_return_rate,
    };

    // alert(method);
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/payment_insert",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          const { IMP } = window;
          IMP.init(process.env.REACT_APP_IMP_INIT);

          const data = {
            pg: "kcp", // PG사 (필수항목)
            pay_method: method, // 결제수단 (필수항목)
            merchant_uid: `${sendData.payment_merchant_uid}`, // 결제금액 (필수항목)
            name: sendData.payment_name, // 주문명 (필수항목)
            amount: sendData.payment_amount, // 금액 (필수항목)
            buyer_name: user.ur_name, // 구매자 이름
            buyer_tel: user.ur_mobile, // 구매자 전화번호 (필수항목)
            buyer_email: user.ur_email, // 구매자 이메일
            // display: {
            //   card_quota: [2, 6],
            // },
            // // notice_url: "https://futuredesigner.net/api/iamport-webhook",
            // // m_redirect_url: `https://futuredesigner.net${location.pathname}`,
            // // notice_url: "http://yjtest.kr/api/iamport-webhook",
            // m_redirect_url: `http://yjtest.kr${location.pathname}`,
          };
          IMP.request_pay(data, callback);
        } else {
        }
      }
    );
    // }
  };
  const callback = (rsp) => {
    if (rsp.success) {
      axios({
        url: "/api/payments/complete", // 예: https://www.myservice.com/payments/complete
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: {
          imp_uid: rsp.imp_uid,
          merchant_uid: rsp.merchant_uid,
        },
      }).then((data) => {
        // setClickTime(true);
        // 서버 결제 API 성공시 로직
        switch (data.data.status) {
          case "vbankIssued":
            // 가상계좌 발급 시 로직
            break;
          case "success":
            // Swal.fire({
            //   title: "결제 완료",
            //   icon: "success",
            //   confirmButtonColor: "#3085d6",
            //   confirmButtonText: "확인",
            //   allowOutsideClick: false,
            // }).then((res) => {
            //   if (res.isConfirmed) {
            //     navigate(`/mypage`);
            //   }
            // });
            // 결제 성공 시 로직
            break;
        }
      });
    } else {
      // setClickTime(true);
      alert(`결제 실패 : ${rsp.error_msg}`);
    }
  };

  const [ch1, setCh1] = useState(false);
  const [ch2, setCh2] = useState(false);
  const [ch3, setCh3] = useState(false);
  const [ch4, setCh4] = useState(false);
  const [ch5, setCh5] = useState(false);
  const [ch6, setCh6] = useState(false);

  const change_ch1 = () => {
    setCh1(!ch1);
  };

  const change_ch2 = () => {
    setCh2(!ch2);
    if (!ch2) {
      setCh3(true);
      setCh4(true);
      setCh5(true);
      setCh6(true);
    } else {
      setCh3(false);
      setCh4(false);
      setCh5(false);
      setCh6(false);
    }
  };
  const change_ch3 = () => {
    setCh3(!ch3);
  };

  const change_ch4 = () => {
    setCh4(!ch4);
  };
  const change_ch5 = () => {
    setCh5(!ch5);
  };

  const change_ch6 = () => {
    setCh6(!ch6);
  };

  const location = useLocation();
  const move_page = () => {
    if (!user) {
      return Swal.fire({
        title: "로그인 이용 후 가능",

        confirmButtonText: "확인",
      }).then((res) => {
        if (res.isConfirmed) {
          sessionStorage.setItem("savePage", location.pathname);
          navigate(`/login`);
        }
      });
    } else {
      if (!/[0-9]/g.test(Number(invCash))) {
        return Swal.fire({
          // title: "",
          html: `숫자만 입력해주세요.`,
          confirmButtonText: "확인",
        });
      }
      if (invCash < 10) {
        return Swal.fire({
          // title: "",
          html: `가입 금액은 최소 10만원,최대 1000만원입니다.`,
          confirmButtonText: "확인",
        });
      }

      dispatch(getAmount([{ amount: invCash, interest: profit }]));
      navigate(`/invest/${id}/join`);
    }
  };
  return (
    <>
      <div className={inv.income}>
        <div className={inv.income_text}>
          <span>{invCash == 0 ? 0 : invCash}만원 투자하면</span>
          <div>
            <em>
              {invCash == 0 ? (
                0
              ) : (
                <CountUp start={starts} end={profit} duration={2} />
              )}
              만원
            </em>
            을 받아요 (년/세전/최대)
          </div>
        </div>
        <div className={inv.income_input}>
          <span className={inv.front_text}>가입금액</span>
          <div className={inv.text_box}>
            <input
              // placeholder="10만원 ~최대 1000만원"
              type="text"
              className={inv.income_box}
              value={invCash === 0 ? 0 : invCash}
              onChange={chgCash}
            />
            <span className={inv.back_text}>만원</span>
          </div>
        </div>
        <ul className={inv.income_list}>
          <li>
            <button
              type="button"
              className={inv.income_btn}
              onClick={() => setInvCash(Number(invCash) + 10)}
            >
              +10만
            </button>
          </li>
          <li>
            <button
              type="button"
              className={inv.income_btn}
              onClick={() => setInvCash(Number(invCash) + 50)}
            >
              +50만
            </button>
          </li>
          <li>
            <button
              type="button"
              className={inv.income_btn}
              onClick={() => setInvCash(Number(invCash) + 100)}
            >
              +100만
            </button>
          </li>
          <li>
            <button
              type="button"
              className={inv.income_btn}
              onClick={() => setInvCash(Number(invCash) + 1000)}
            >
              +1000만
            </button>
          </li>
        </ul>
        <span className={inv.income_text_span}>
          {" "}
          ＊가입 금액은 최소 10만원 / 최대 1000만원입니다.{" "}
        </span>
      </div>

      <div className={inv.make_product}>
        {/* <Link to="/invest//join"> */}
        <div
          className={inv.make_product_a}
          onClick={() => {
            move_page();
          }}
        >
          <div className={inv.anchor_text}>
            <span>META12의 다음 투자를 소유해보세요</span>
            <em>투자하러 가기</em>
          </div>{" "}
          <IoIosArrowForward />
          {/* </Link> */}
        </div>
      </div>
    </>
  );
};

export default ProcessSection_3;
