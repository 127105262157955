import { Link, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import logo from "../assets/images/Logo.png";
import logo1 from "../assets/images/M12.png";
import naver_icon from "../assets/images/naver_icon.png";
import NaverLogin from "../pages/NaverLogin";
import KakaoLogin from "../pages/KakaoLogin";
// import "../styles/login.css";
import loginStyle from "../styles/login.module.css";
import envelope from "../assets/images/envelope.png";
import { CommonAxios } from "../components/CommonAxios";
import { MoveAlert, WarringAlert } from "../components/alert/Alert";
import { getUsers } from "../modules/user";
import { useDispatch } from "react-redux";
import { encrypt } from "../lib/api/tokenCrypto";
function Login() {
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_APP_KEY}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}&response_type=code`;
  const navigate = useNavigate();
  const handleLogin = () => {
    window.location.href = KAKAO_AUTH_URL; //인가코드를 발급해주는 경로
  };
  const dispatch = useDispatch();
  const [loginId, setLoginId] = useState("");
  const [loginPass, setLoginPass] = useState("");
  const [warringAlert, setWarringAlert] = useState(false);
  const [moveAlert, setMoveAlert] = useState(false);
  const btn_login = () => {
    let sendData = {
      email: loginId,
      password: loginPass,
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/signin",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          localStorage.setItem(
            "meta_info",
            encrypt(result.messageinfo.message, "meta_info")
          );
          dispatch(getUsers(result.messageinfo.message));

          if (sessionStorage.getItem("savePage")) {
            let dataLo = sessionStorage.getItem("savePage");

            navigate(dataLo);
            sessionStorage.removeItem("savePage");
          } else {
            navigate("/");
          }

          // setMoveAlert(true);
        } else {
          if (result.messageinfo.message === "탈퇴한 계정") {
            setContent("탈퇴한 계정입니다. 고객센터에 문의바랍니다.");
            setWarringAlert(true);
          } else {
            setContent("아이디와 비밀번호를 확인하여 주시기 바랍니다.");
            setWarringAlert(true);
          }
        }
      }
    );
  };
  const [content, setContent] = useState("");
  // 이메일 입력시
  const onChange_id = useCallback((e) => {
    setLoginId(e.target.value);
  }, []);

  const onChange_pass = useCallback((e) => {
    setLoginPass(e.target.value);
  }, []);

  return (
    <>
      {" "}
      {warringAlert && (
        <WarringAlert content={content} setWarringAlert={setWarringAlert} />
      )}
      {moveAlert && (
        <MoveAlert
          content={"로그인 완료"}
          setMoveAlert={setMoveAlert}
          movePage={"/"}
        />
      )}
      <div className="common-wrap">
        {/* <div className="common-inner-wrap"> */}
        <div className={loginStyle.login_header_wrap}>
          <div className={loginStyle.login_header}>
            <img
              src={logo1}
              onClick={() => {
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className={loginStyle.login_content_wrap}>
          <div className={loginStyle.login_content}>
            <h3 className={loginStyle.login_h3}>이메일 로그인</h3>
            <div className={loginStyle.login_box}>
              <div className={loginStyle.login_section}>
                <input
                  type="text"
                  placeholder="이메일을 입력하세요."
                  onChange={onChange_id}
                />
                <input
                  type="password"
                  placeholder="비밀번호를 입력하세요."
                  onChange={onChange_pass}
                />
              </div>
              <div className={loginStyle.login_btn_box}>
                <button
                  className={`${loginStyle.login_btn} ${loginStyle.login_nomal}`}
                  onClick={btn_login}
                >
                  로그인
                </button>
              </div>
              <div className={loginStyle.etc_section}>
                <ul className={loginStyle.etc_ul}>
                  <li>
                    로그인 계정이 기억나지 않으시나요?{" "}
                    <span>
                      <Link to="/find">계정찾기</Link>
                    </span>
                  </li>
                  <li>
                    아직 META12 계정이 없으신가요?{" "}
                    <span>
                      {" "}
                      <Link to="/SignUp">회원가입</Link>{" "}
                    </span>
                  </li>
                </ul>
              </div>
              <div
                className={`${loginStyle.login_btn_box} ${loginStyle.sns_login}`}
              >
                <span className={loginStyle.sns_title}>SNS계정으로 로그인</span>
                <KakaoLogin type={true} />
              </div>{" "}
              {/* <div className={`${loginStyle.login_notice}`}>
                <span className={loginStyle.sns_title}>약관 공지</span>
                <KakaoLogin type={true} />
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="login-inner">
            <div className="login-left">
              <div className="login-hd">
                <h1 className="logo-box">
                  <Link
                    to="/"
                    style={{
                      width: "147px",
                    }}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        display: "block",
                        width: "147px",
                        margin: "0 auto",
                        // opacity:"0"
                      }}
                    />
                  </Link>
                </h1>
              </div>
              <div className="login-content login-content-1">
                <h2 className="login-title">로그인</h2>
                <div className="login-box">
                  <KakaoLogin type={true} />

                  <NaverLogin onClick={() => false} />
                  <div
                    className="sns-login naver-login"
                    style={{
                      background: "#03C75A",
                      width: "100%",
                      height: "45px",
                      color: "#fff",
                    }}
                    onClick={() =>
                      document
                        .querySelector("#naverIdLogin_loginButton")
                        .click()
                    }
                  >
                    <img src={naver_icon} alt="naver_icon" />
                    <p>네이버로 시작하기</p>
                  </div>
                  <div
                    className="sns-login etc-login"
                    style={{
                      background: "#fff",
                      width: "100%",
                      height: "45px",
                      color: "#fff",
                    }}
                  >
                    <Link to="/login/email" style={{}}>
                      <img src={envelope} />

                      <p>다른 방법으로 시작하기</p>
                    </Link>
                  </div>
                </div>
              </div>

              <span className="login-span">
                본인은 사이트 이용약관, 개인정보 수집 및 이용, 그리고
                <br />
                개인정보 제공에 대한 내용을 확인하였고, 이에 동의합니다.
              </span>
            </div>
          </div> */}
        {/* </div> */}{" "}
      </div>
    </>
  );
}

export default Login;
