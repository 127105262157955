import React, { useState, useEffect } from "react";
import logo from "../logo.svg";
import "../App.css";

import MyInvestJDetail from "../components/myInvest/MyInvestJDetail";
function MyInvestJPage() {
  const [message, setMessage] = useState("");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <MyInvestJDetail />{" "}
    </>
  );
}

export default MyInvestJPage;
