import React, { useState, useEffect, useRef } from "react";
import { CommonAxios } from "./../../CommonAxios";
import { Link, useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import Moment from "react-moment";
import styles from "./../../../styles/admin.module.css";
import CustomMainTable from "./../CustomMainTable";
import { transMonth } from "../../../lib/date/TransDate";
import { textReplace } from "../../../lib/textReplace";
import { wonComma } from "../../../lib/wonComma";
import { numberToKorean } from "../../../lib/numberToKorean";
import { FaArrowUp } from "react-icons/fa";
import CountUp from "react-countup";
const ProductInvestList2 = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    let sendData = {
      no: id,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/invest_select_product",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);

          // navigate("/mypage/invest");
        } else {
        }
      }
    );
  }, []);
  const wonReplace = () => {
    return numberToKorean(data[0].pro_target_amount);
  };
  const wonReplace1 = () => {
    let won = String(data[0].pro_target_amount);
    // if (won.length === 6) {
    //   return wonComma(data.pro_target_amount);
    // }else if(won.length === 7)

    // return 0;
    if (data[0].amount) {
      return numberToKorean(data[0].amount);
    } else {
      return "0";
    }
  };

  const percent = () => {
    let result = data[0].pro_target_amount;
    let ing = data[0].amount;

    return (ing / result) * 100;
    // return 159;
  };

  return (
    <>
      <div className={styles.invest_wrap}>
        <div className={styles.invest_deposit_section}>
          <div className={styles.invest_deposit_box}>
            <div className={styles.invest_product_item}>
              <h2>투자 상품 현황</h2>
              <div className={styles.invest_product_box}>
                {data.length > 0 && (
                  <>
                    <div className={styles.invest_title}>
                      {data[0].pro_content}
                    </div>
                    <div className={styles.invest_in}>
                      <div className={styles.invest_top}>
                        <div className={styles.invest_text}>
                          {data[0].category_name}
                          <em>{data[0].pro_title}</em>
                        </div>
                      </div>
                      <div className={styles.invest_make_visual}>
                        <ul>
                          <li>
                            <span>수익률</span>
                            <div className={styles.num}>
                              <FaArrowUp /> <em>{data[0].pro_rate}%</em>
                            </div>
                          </li>
                          <li>
                            <span>가입기간</span>
                            <em className={styles.num}>
                              {data[0].pro_join}개월
                            </em>
                          </li>
                          <li>
                            <span>가입금액</span>
                            <em className={styles.num}>100 ~ 3500만원</em>
                          </li>
                        </ul>

                        <div className={styles.line_wrap}>
                          <progress
                            className={styles.progress}
                            value={percent()}
                            min="0"
                            max="100"
                          ></progress>
                        </div>
                        <div className={styles.line_text_box}>
                          <span className={`${styles.per} ${styles.n1}`}>
                            모집률{" "}
                            <CountUp
                              start={0}
                              end={percent()}
                              decimals={1}
                              decimal="."
                              duration={2}
                            />
                            &nbsp; %
                          </span>
                          <span className={`${styles.per} ${styles.n2}`}>
                            {wonReplace1()} / {wonReplace()}원
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductInvestList2;
