import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import styles from "../../styles/admin.module.css";
import Swal from "sweetalert2";
import { CommonAxios } from "../CommonAxios";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";

function CustomFarmTable({
  columns,
  data,
  options,
  setOptions,
  arrayList,
  setArrayList,
  setSendList,
  linkData,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    rows,
    selectedFlatRows,
    //   canPreviousPage,
    //   canNextPage,
    pageOptions,
    pageCount, // 총 페이지
    gotoPage,
    //   nextPage,
    //   previousPage,
    //   setPageSize,
    state: { pageIndex, pageSize }, // pageIndex : 현재 페이지( 0 ~ 총페이지-1 ex) 1페이지 : 0 )
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // getToggleAllPageRowsSelectedProps : 페이지에 보여지는 row 전체 선택
          // getToggleAllRowsSelectedProps : 모든 row 전체 선택
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );
  const sendData = (data) => {
    linkData(data);
  };
  //체크박스 옵션
  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            style={{ width: "32px" }}
          />
        </>
      );
    }
  );
  const btn_delete = () => {
    if (selectedFlatRows.length === 0 || !selectedFlatRows) {
      return Swal.fire({
        title: "삭제 실패",
        html: `삭제할 농장을 선택하세요.`,

        confirmButtonText: "확인",
      });
    }
    let dataArr = [];

    for (let i = 0; i < selectedFlatRows.length; i++) {
      if (selectedFlatRows[i].original.no) {
        dataArr.push(selectedFlatRows[i].original.no);
      }
    }
    Swal.fire({
      title: "농장 삭제",
      html: `정말 삭제하시겠습니까?`,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let sendData = {
          arr: dataArr,
        };
        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/admin_delete_farm",
          sendData,
          function (result) {
            if (result.messageinfo.state == "ok") {
              Swal.fire({
                title: "삭제 완료",
                confirmButtonText: "확인",
              });
              if (result.messageinfo.message.length === 0) {
                setSendList([]);
                setArrayList([]);
              } else {
                setSendList(result.messageinfo.message);
                setArrayList(result.messageinfo.message);
              }
            } else {
              // Swal.fire({
              //   title: "조회 실패",
              //   icon: "error",
              //   confirmButtonText: "확인",
              // }).then((res) => {
              //   if (res.isConfirmed) {
              //   }
              // });
            }
          }
        );
      }
    });
  };
  useEffect(() => {}, [pageIndex]);

  return (
    <>
      <table
        className="table_style"
        {...getTableProps()}
        style={{ margin: "auto" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            background: "rgb(0, 199, 60)",
                            // visibility: "visible",
                            lineHeight: "18px",
                            fontSize: "10px",
                            color: "#fff",
                            borderRadius: "3px",
                          }}
                        >
                          <BiSolidDownArrow style={{ marginBottom: "5px" }} />
                        </i>
                      ) : (
                        <i
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            background: "rgb(0, 199, 60)",
                            // visibility: "visible",
                            lineHeight: "18px",
                            fontSize: "10px",
                            color: "#fff",
                            borderRadius: "3px",
                          }}
                        >
                          <BiSolidUpArrow style={{ marginBottom: "5px" }} />{" "}
                        </i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="table_row">
                {row.cells.map((cell) => (
                  <td
                    style={{ cursor: "pointer" }}
                    {...cell.getCellProps()}
                    // onClick={() => sendData(row.original.no)}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.table_pagination_delete}>
        {" "}
        <button
          className={styles.table_pagination_delete_btn}
          onClick={btn_delete}
        >
          삭제
        </button>
        <div className={styles.table_pagination_delete_box}>
          {pageIndex >= 10 && (
            <button
              onClick={() => gotoPage(Math.floor(pageIndex / 10) * 10 - 1)}
            >
              {"<"}
            </button>
          )}
          {pageOptions.map((page, index) => {
            if (pageIndex < 10 && index < 10) {
              return (
                <button
                  key={index}
                  onClick={() => gotoPage(index)}
                  style={{
                    fontWeight: pageIndex === index ? "bold" : "normal",
                    color: pageIndex === index ? "#fff" : "#000",
                    background:
                      pageIndex === index ? "rgb(0, 199, 60)" : "#f5f5f5",

                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                >
                  {index + 1}
                </button>
              );
            } else if (pageIndex >= 10) {
              const currentPageGroup = Math.floor(pageIndex / 10); // 현재 페이지 그룹 번호 계산
              const startPageIndex = currentPageGroup * 10; // 현재 페이지 그룹의 첫 번째 페이지 인덱스
              const endPageIndex = Math.min(startPageIndex + 10, pageCount); // 현재 페이지 그룹의 마지막 페이지 인덱스
              if (index >= startPageIndex && index < endPageIndex) {
                return (
                  <button
                    key={index}
                    onClick={() => gotoPage(index)}
                    style={{
                      fontWeight: pageIndex === index ? "bold" : "normal",
                      color: pageIndex === index ? "#fff" : "#000",
                      background:
                        pageIndex === index ? "rgb(0, 199, 60)" : "#f5f5f5",

                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                  >
                    {index + 1}
                  </button>
                );
              }
            }
            return null;
          })}
          {Math.floor(pageIndex / 10) < Math.floor(pageCount / 10) && (
            <button
              onClick={() => gotoPage(Math.floor(pageIndex / 10 + 1) * 10)}
            >
              {">"}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default CustomFarmTable;
