import styles from "../../../styles/mypage.module.css";
import { FaWonSign } from "react-icons/fa";
import { BsFillBox2HeartFill, BsFillQuestionSquareFill } from "react-icons/bs";
import { useState, useEffect } from "react";
import Moment from "react-moment";
import InvestCustomTable from "../table/InvestCustomTable";
import { CommonAxios } from "../../CommonAxios";
import { useSelector } from "react-redux";
import { numberToKorean } from "../../../lib/numberToKorean";
import moment from "moment";
import QnaCustomTable from "../table/QnaCustomTable";
const MypageQna = ({
  type,
  startDate,
  endDate,
  data,
  setData,
  setStartDate,
  swi,
  setEndDate,
  setBtnName,
}) => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));

  const TableColumn = [
    {
      Header: "번호",
      accessor: "ro",
      // Cell: ({ row }) => <Title row={row} />,
    },
    {
      Header: "문의 제목",
      accessor: "qna_title",
      Cell: ({ row }) => <Title row={row} />,
    },
    {
      Header: "답변 여부",
      accessor: "qna_answer_yn",
      Cell: ({ cell: { value } }) => (value === "Y" ? "답변완료" : "미답변"),
    },
    {
      Header: "등록 날짜",
      accessor: "qna_date",
      Cell: ({ cell: { value } }) => <Date1 value={value} />,
      // Cell: ({ cell: { value } }) => <>{numberToKorean(value)}원</>,
    },
    // {
    //   Header: "현재 수익률",
    //   accessor: "pro_rate",
    //   Cell: ({ cell: { value } }) => <>{value}%</>,
    // },
  ];

  const Title = ({ row }) => {
    return (
      <div style={{ display: "flex", width: "100%", cursor: "pointer" }}>
        <div
          style={{
            height: "40px",
            lineHeight: "40px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.original.qna_title}
        </div>
      </div>
    );
  };

  const Date1 = ({ value }) => {
    if (value) {
      return <Moment format="YYYY-MM-DD">{value}</Moment>;
    } else {
      return <>수정기록없음</>;
    }
  };

  return (
    <>
      {" "}
      <div className={styles.mypage_qna_table}>
        {type === "1" && swi && (
          <QnaCustomTable columns={TableColumn} data={data} />
        )}
      </div>
    </>
  );
};

export default MypageQna;
