import styles from "../../../styles/admin.module.css";
import { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import AdminUserInfo from "./AdminUserInfo";
import ToastEditor from "../editor/ToastEditor";
import { textReplace } from "../../../lib/textReplace";
import { FcEditImage } from "react-icons/fc";
import logo from "../../../assets/images/Artboard 2.png";
import Moment from "react-moment";
const NoticeViewDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  useEffect(() => {
    let sendData = {
      no: id,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_notice_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
        } else {
          // navigate("/admin/notice");
        }
      }
    );
  }, []);

  const btn_update = () => {
    navigate(`/admin/notice/update/${id}`);
  };
  return (
    <>
      <div className={styles.notice_write_wrap}>
        <div className={styles.notice_write_inner}>
          <div className={styles.notice_write_title}>
            <h4>← 뒤로 가기</h4>
            <div className={styles.notice_write_button}>
              <button onClick={btn_update}>수정</button>
            </div>
          </div>
          <div className={styles.notice_view_content}>
            {data.length > 0 && (
              <div className={styles.notice_view}>
                <div className={styles.notice_view_tite}>
                  {/* <div>
                    <span>
                      <img src={logo} />
                    </span>
                  </div> */}
                  <p>{data[0].notice_title}</p>
                  <span>
                    <Moment format="YY.MM.DD">{data[0].notice_date}</Moment>{" "}
                  </span>
                </div>
                <div className={styles.notice_view_cont}>
                  <div
                    dangerouslySetInnerHTML={{ __html: data[0].notice_content }}
                  ></div>
                </div>
                {/* <div className={styles.notice_view_bottom}></div> */}
              </div>
            )}{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeViewDetail;
