import styles from "../../styles/mypage.module.css";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import MypageActivity from "./detail/MypageActivity";
import { useEffect, useState } from "react";
const MypageDetail = () => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));

  const [data, setData] = useState([]);
  const [dataSw, setDataSw] = useState(false);
  useEffect(() => {
    let sendData = {
      no: user.no,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/mypage_select_activity",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
          setDataSw(true);
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  }, []);

  return (
    <>
      <div className={styles.mypage_content_right}>
        <h3 className={styles.mypage_content_title}>나의 활동</h3>
        <div className={styles.mypage_content_inner}>
          {data && dataSw && <MypageActivity data={data} />}
        </div>
      </div>
    </>
  );
};

export default MypageDetail;
