import React, { useState, useEffect, useRef } from "react";
import CustomTable from "./CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "../CommonAxios";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/admin.module.css";
import { Link, useNavigate } from "react-router-dom";

import StoreCustomTable from "./table/StoreCustomTable";
import { wonComma } from "../../lib/wonComma";
const AdminStoreList = ({ category }) => {
  const navigate = useNavigate();
  const userDetailInfo = (no) => {
    navigate(`/admin/store/detail/${no}`);
  };
  const TableColumn = [
    {
      Header: "대표 이미지",
      accessor: "store_img",
      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            <div
              style={{
                cursor: "pointer",
                width: "50px",
                height: "50px",
                margin: "0 auto",
              }}
              onClick={() => userDetailInfo(oriNo)}
            >
              {data.value && (
                <img
                  src={"/store/" + oriNo + "/" + data.value}
                  style={{ cursor: "pointer", width: "50px", height: "50px" }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "상품 이름",
      accessor: "store_title",
      Cell: ({ row }) => <Title row={row} />,
    },
    {
      Header: "상품 가격",
      accessor: "store_amount",
      Cell: ({ row }) => <Won row={row} />,
      // Cell: ({ cell: { value } }) => <>{value == "Y" ? "공개" : "미공개"}</>,
    },
    {
      Header: "카테고리",
      accessor: "category_name",
      // Cell: ({ cell: { value } }) => <>{value == "Y" ? "공개" : "미공개"}</>,
    },

    {
      Header: "게시날짜",
      accessor: "store_date",
      Cell: ({ cell: { value } }) => <Date value={value} />,
    },
    {
      Header: "클릭횟수",
      accessor: "store_click_cnt",
      Cell: ({ cell: { value } }) => <>{value}번</>,
    },
  ];
  const Date = ({ value }) => {
    if (value) {
      return <Moment format="YYYY.MM.DD.">{value}</Moment>;
    } else {
      return <>수정기록없음</>;
    }
  };
  const Won = ({ row }) => {
    return (
      <div
        // style={{ display: "flex", width: "120px", cursor: "pointer" }}
        onClick={() => userDetailInfo(row.original.no)}
      >
        <div
          style={{
            height: "40px",
            lineHeight: "40px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {wonComma(row.original.store_amount)}원
        </div>
      </div>
    );
  };
  const Title = ({ row }) => {
    return (
      <div
        style={{ display: "flex", width: "200px", cursor: "pointer" }}
        onClick={() => userDetailInfo(row.original.no)}
      >
        <div
          style={{
            height: "40px",
            lineHeight: "40px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.original.store_title}
        </div>
      </div>
    );
  };
  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState("all");

  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_store",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
    // let sendData = {};

    // CommonAxios.CommonAxios(
    //     process.env.REACT_APP_HOSTDONAME + "/api/select_users",
    //     sendData,
    //     function (result) {
    //       if (result.messageinfo.state == "ok") {
    //         setArrayList(result.messageinfo.message);
    //       } else {
    //           Swal.fire({
    //           title: "조회 실패",
    //           icon: "error",
    //           confirmButtonText: "확인",
    //           }).then((res) => {
    //               if (res.isConfirmed) {
    //               }
    //           });
    //       }
    //     }
    // );
    // setArrayList(UserData);
  }, []);

  useEffect(() => {
    if (arrayList.length > 0) {
      if (options == "all") {
        if (filterWord == null) {
          setSendList(arrayList);
        } else {
          let list = arrayList.filter(
            (a) => a.store_title.includes(filterWord) == true
          );
          setSendList(list);
        }
      }
      {
        category &&
          category.map((data) => {
            if (options == data.no) {
              const norList = arrayList.filter((a) => a.cno == data.no);
              if (filterWord == null) {
                setSendList(norList);
              } else {
                let list = norList.filter(
                  (a) => a.store_title.includes(filterWord) == true
                );
                setSendList(list);
              }
            }
          });
      }
    }
  }, [arrayList, filterWord, options]);

  const filterUser = (e) => {
    setFilterWord(e.target.value);
  };
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          상품 관리 &nbsp;〉 &nbsp;스토어 목록
          <span className={styles.header_button}>
            <button
              onClick={() => {
                navigate("/admin/store/write");
              }}
            >
              스토어 등록
            </button>
          </span>
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.user_search}>
            <span className={styles.search_icon}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </span>
            <input
              type="text"
              onChange={(e) => filterUser(e)}
              placeholder="찾고자 하는 스토어 상품명을 검색하세요."
            />
          </div>
          <StoreCustomTable
            columns={TableColumn}
            data={sendList}
            options={options}
            setOptions={setOptions}
            arrayList={arrayList}
            setArrayList={setArrayList}
            setSendList={setSendList}
            category={category}
          />
          {/* <FarmTable /> */}
        </div>
      </div>
    </>
  );
};

export default AdminStoreList;
