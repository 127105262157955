import React, { useState, useEffect, useRef } from "react";

import PressWrite from "../components/admin/PressWrite";

const AdminPressWrite = ({ roomid, onClose }) => {
  return (
    <>
      <PressWrite />
    </>
  );
};

export default AdminPressWrite;
