import React, { useState, useEffect, useRef } from "react";

import GoodsWriteField from "../components/admin/GoodsWriteField";

const GoodsWritePageField = () => {
  return (
    <>
      <GoodsWriteField />
    </>
  );
};

export default GoodsWritePageField;
