import styles from "../../styles/mypage.module.css";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import MypageInvest from "./detail/MypageInvest";
import MypageInvestWait from "./detail/MypageInvestWait";
import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { FaCalendar } from "react-icons/fa";
import { transMonth } from "../../lib/date/TransDate";
import moment from "moment";
import MypageQna from "./detail/MypageQna";
const MypageQnaDetail = () => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const btn_move = (data) => {
    if (data === 1) {
      navigate("/mypage/profile");
    } else {
      if (user.ur_type === 1) {
        navigate("/mypage/password");
      } else {
        return alert("소셜 계정은 이용할 수 없습니다.");
      }
    }
  };

  const [arrayList, setArrayList] = useState([]);

  const [arrayList1, setArrayList1] = useState([]);

  const filters = [
    {
      id: 1,
      text: "이번달",
      name: "no1",
    },
    {
      id: 2,
      text: "2개월",
      name: "no2",
    },
    {
      id: 3,
      text: "3개월",
      name: "no3",
    },
    {
      id: 4,
      text: "전체",
      name: "no4",
    },
  ];
  const [btnName, setBtnName] = useState("no1");
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
  );
  const [startDateDB, setStartDateDB] = useState();
  const [endDateDB, setEndDateDB] = useState();

  const onClickButton = (e) => {
    const { name } = e.target;
    setBtnName(name);
    const today = new Date();
    let data;
    switch (name) {
      case "no1":
        setStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        return;
      case "no2":
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 1, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        return;
      case "no3":
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 2, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        return;
      case "no4":
        setStartDate("");
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        return;
    }
  };
  const [color, setColor] = useState("1");
  const [swi, setSwi] = useState(false);
  const change_li = (data) => {
    setColor(data);
  };

  const btn_reg = () => {
    setSwi(false);
    let sendData = {
      no: user.no,

      start: moment(startDate).format("YYYY-MM-DD 00:00:00"),
      end: moment(endDate).format("YYYY-MM-DD 23:59:59"),
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/mypage_select_qna",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
          setSwi(true);
          // setUserInfo(result.messageinfo.message[0]);
          // localStorage.removeItem("meta_info");
          // document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  };

  useEffect(() => {
    setSwi(false);
    const today = new Date();
    setStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
    setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));

    setBtnName("no1");
    let sendData = {
      no: user.no,

      start: moment(startDate).format("YYYY-MM-DD 00:00:00"),
      end: moment(endDate).format("YYYY-MM-DD 23:59:59"),
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/mypage_select_qna",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
          setSwi(true);

          // setUserInfo(result.messageinfo.message[0]);
          // localStorage.removeItem("meta_info");
          // document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  }, []);

  return (
    <>
      <div className={styles.mypage_content_right}>
        <div className={styles.mypage_content_tit_div}>
          <h3 className={styles.mypage_content_title}>1:1 문의하기</h3>

          <button onClick={() => navigate("/mypage/qna/write")}>
            문의 등록하기
          </button>
        </div>
        <div className={`${styles.mypage_content_inner} ${styles.qna_wrap} `}>
          <div className={styles.mypage_content_qna_wrap}>
            <div className={styles.mypage_content_qna_inner}>
              <div className={styles.mypage_content_qna_box}>
                <div className={styles.mypage_content_qna_top}>
                  <span className={styles.mypage_qna_top_span}> 기간조회</span>
                  <div className={styles.mypage_qna_price_box}>
                    <div>
                      <span className={styles.mypage_qna_datepicker}>
                        <span>
                          <DatePicker
                            dateFormat="yyyy-MM-dd"
                            locale={ko}
                            onChange={(date) => setStartDate(date)}
                            maxDate={endDate}
                            selected={startDate}
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText={"적용시작일"}
                          />
                        </span>
                        <span className={styles.mypage_qna_datepicker_ico}>
                          <FaCalendar />
                        </span>
                      </span>
                      <span>~</span>
                      <span className={styles.mypage_qna_datepicker}>
                        <span>
                          <DatePicker
                            dateFormat="yyyy-MM-dd"
                            locale={ko}
                            onChange={(date) => setEndDate(date)}
                            selected={endDate}
                            maxDate={new Date()}
                            startDate={endDate}
                            endDate={endDate}
                            placeholderText={"적용종료일"}
                          />
                        </span>
                        <span className={styles.mypage_qna_datepicker_ico}>
                          <FaCalendar />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className={styles.mypage_qna_filter_box}>
                    {filters.map((filter) => {
                      return (
                        <>
                          <button
                            className={
                              btnName === filter.name
                                ? `${styles.filter_text} ${styles.filter_active}`
                                : `${styles.filter_text}`
                            }
                            type="button"
                            onClick={onClickButton}
                            name={filter.name}
                            key={filter.id}
                          >
                            {filter.text}
                          </button>
                        </>
                      );
                    })}
                    <button
                      className={`${styles.filter_text} ${styles.filter_active}`}
                      onClick={() => btn_reg()}
                      style={{ background: "#3c3c3c" }}
                    >
                      조회
                    </button>
                  </div>
                </div>
              </div>
              <MypageQna
                data={data}
                setData={setData}
                type={color}
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setBtnName={setBtnName}
                swi={swi}
              />
            </div>
          </div>

          {/* <div className={styles.mypage_content_qna}>
            <h4>진행중인 투자상품</h4>




        
            <Mypageqna arrayList={arrayList} />

          </div> */}
        </div>
      </div>
    </>
  );
};

export default MypageQnaDetail;
