import styles from "../styles/mypage.module.css";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CommonAxios } from "../components/CommonAxios";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import pro from "../assets/images/profile_info.png";
import icon from "../assets/images/notice_default.jpg";
const MypageTemplate = () => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));

  const btn_logout = () => {
    let sendData = "";
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/signout",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          localStorage.removeItem("meta_info");
          document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  };
  return (
    <>
      <div className={styles.mypage_wrap}>
        <div className={styles.mypage_inner_wrap}>
          <div className={styles.mypage_content_wrap}>
            {/* <h2 className={styles.mypage_h2}>마이페이지</h2> */}
            <div className={styles.mypage_content}>
              <div className={styles.mypage_content_left}>
                {/* <h2 className={styles.mypage_h2}>마이페이지</h2> */}
                <div className={styles.mypage_left_info}>
                  <div className={styles.mypage_info_img}>
                    <img src={user.ur_image !== "" ? user.ur_image : pro} />
                  </div>
                  <div className={styles.mypage_left_info_box}>
                    <div className={styles.mypage_info_name}>
                      <Link to="/mypage/profile">
                        <span className={styles.mypage_name_span}>
                          {user.ur_name}
                        </span>
                      </Link>
                      님
                      {user.ur_type === 1 && (
                        <span className={styles.mypage_icon_type}></span>
                      )}
                    </div>
                    <div className={styles.mypage_info_logout}>
                      <button onClick={btn_logout}>로그아웃</button>
                    </div>
                  </div>
                </div>
                <div className={styles.mypage_menu}>
                  <ul>
                    {" "}
                    <li className={styles.mypage_menu_li}>
                      <Link to="/mypage/info">나의 정보변경</Link>
                    </li>
                    <li className={styles.mypage_menu_li}>
                      <Link to="/mypage/invest">나의 투자정보</Link>
                    </li>
                    {/* <li className={styles.mypage_menu_li}>
                      <Link to="/mypage/wait">나의 대기상품</Link>
                    </li> */}
                    <li className={styles.mypage_menu_li}>
                      <Link to="/mypage/interest">나의 관심상품</Link>
                    </li>
                    <li className={styles.mypage_menu_li}>
                      <Link to="/mypage/qna">1:1 문의하기</Link>
                    </li>
                    {/* <li className={styles.mypage_menu_li}>나의 투자정보</li>
                    <li className={styles.mypage_menu_li}>나의 투자정보</li>
                    <li className={styles.mypage_menu_li}>나의 투자정보</li>
                    <li className={styles.mypage_menu_li}>나의 투자정보</li> */}
                  </ul>
                </div>
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MypageTemplate;
