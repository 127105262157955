import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import close from "./../assets/images/close.png";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import AdminUserDetail from "../components/admin/detail/AdminUserDetail";
import InvestList from "../components/admin/InvestList";
import UserAnalyzeList from "../components/admin/UserAnalyzeList";
import InvestAnalyzeList from "../components/admin/InvestAnalyzeList";

const AdminInvestAnalyzePage = ({ roomid, onClose }) => {
  return (
    <>
      <InvestAnalyzeList />
    </>
  );
};

export default AdminInvestAnalyzePage;
