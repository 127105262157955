import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import close from "./../assets/images/close.png";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import AdminUserDetail from "../components/admin/detail/AdminUserDetail";
import InvestList from "../components/admin/InvestList";
import DepositPage from "../components/admin/DepositPage";

const AdminDepositDetail = ({ roomid, onClose }) => {
  return (
    <>
      <DepositPage />
    </>
  );
};

export default AdminDepositDetail;
