import Carousel from "react-bootstrap/Carousel";
import img_1 from "../../assets/images/img_1.jpg";
import img_2 from "../../assets/images/img_2.jpg";
import img_3 from "../../assets/images/img_3.jpg";
import MainItemSlider from "./MainItemSlider";

import main from "../../styles/main.module.css";
import MainNews from "./MainNews";
import MainRank from "./MainRank";
import MainSection2 from "./MainSection2";
import MainSection3 from "./MainSection3";
import MainSection4 from "./MainSection4";
import MainSection5 from "./MainSection5";
import { useEffect, useState } from "react";
const MainSection1 = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <div className={`common-wrap ${main.main_wrap}`}>
      <div className={main.main_section_inner}>
        <div className={main.main_section_wrap}>
          <div className={main.main_section_left}>
            <MainNews />
          </div>
          <div className={main.main_section_right}>
            <MainRank />
          </div>
        </div>
      </div>
      <div
        className={main.main_section2_inner}
        // style={{ display: windowSize >= 1100 ? "block" : "none" }}
      >
        <div className={main.main_section2_wrap}>
          <MainSection2 />{" "}
        </div>
      </div>{" "}
      <div className={main.main_section5_inner} style={{ display: "none" }}>
        <div className={main.main_section5_wrap}>
          <MainSection5 />
        </div>
      </div>
      <div className={main.main_section4_inner}>
        <div className={main.main_section4_wrap}>
          <MainSection4 />{" "}
        </div>
      </div>
      <div className="common-inner-wrap">
        <div className={main.main_section3_wrap}>
          <MainSection3 />
        </div>
      </div>
    </div>
  );
};
export default MainSection1;
