import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import styles from "../../styles/admin.module.css";
import notice from "../../styles/notice.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { MdError } from "react-icons/md";
function NewsCustomTable({ columns, data }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { id2 } = useParams();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    selectedFlatRows,
    prepareRow,
    //   canPreviousPage,
    //   canNextPage,
    pageOptions,
    pageCount, // 총 페이지
    gotoPage,
    //   nextPage,
    //   previousPage,
    //   setPageSize,
    state: { pageIndex, pageSize }, // pageIndex : 현재 페이지( 0 ~ 총페이지-1 ex) 1페이지 : 0 )
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 5 } },
    useSortBy,
    usePagination,
    useRowSelect
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: "selection",

    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //         </div>
    //       ),

    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  //체크박스 옵션
  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            style={{ width: "32px" }}
          />
        </>
      );
    }
  );

  useEffect(() => {}, [pageIndex]);

  const btn_send = () => {
    // console.log(selectedFlatRows);
  };
  const movePage = (data) => {
    navigate(`/notice/${data}`);
  };
  return (
    <>
      <div className={notice.notice_content}>
        {data.length === 0 && (
          <>
            <div className={notice.notice_content_inner}>
              <em>
                <MdError /> 새로운 소식이 없습니다.
              </em>
            </div>
          </>
        )}
        {data && (
          <div
            className={notice.table_style}
            {...getTableProps()}
            style={{ margin: "auto" }}
          >
            <div {...getTableBodyProps()} className={notice.table_tbody}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <div
                    {...row.getRowProps()}
                    className={`${notice.table_tr} ${notice.table_tr_story}`}
                    // onClick={() => {
                    //   movePage(row.original.no);
                    // }}
                  >
                    {" "}
                    <a href={row.original.press_link} target="_blank">
                      {row.cells.map((cell) => (
                        <div
                          {...cell.getCellProps()}
                          className={notice.table_td}
                        >
                          {cell.render("Cell")}
                        </div>
                      ))}{" "}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className={notice.table_pagination} style={{ marginTop: "20px" }}>
        {pageIndex >= 5 && (
          <button onClick={() => gotoPage(Math.floor(pageIndex / 5) * 5 - 1)}>
            {"<"}
          </button>
        )}
        {pageOptions.map((page, index) => {
          if (pageIndex < 5 && index < 5) {
            return (
              <button
                key={index}
                onClick={() => gotoPage(index)}
                style={{
                  fontWeight: pageIndex === index ? "bold" : "normal",
                  color: pageIndex === index ? "#fff" : "#000",
                  background: pageIndex === index ? "#00c73c" : "transparent",
                  width: "30px",
                  height: "30px",
                  borderRadius: "5px",
                }}
              >
                {index + 1}
              </button>
            );
          } else if (pageIndex >= 5) {
            const currentPageGroup = Math.floor(pageIndex / 5); // 현재 페이지 그룹 번호 계산
            const startPageIndex = currentPageGroup * 5; // 현재 페이지 그룹의 첫 번째 페이지 인덱스
            const endPageIndex = Math.min(startPageIndex + 5, pageCount); // 현재 페이지 그룹의 마지막 페이지 인덱스
            if (index >= startPageIndex && index < endPageIndex) {
              return (
                <button
                  key={index}
                  onClick={() => gotoPage(index)}
                  style={{
                    fontWeight: pageIndex === index ? "bold" : "normal",
                    color: pageIndex === index ? "#fff" : "#000",
                    background: pageIndex === index ? "#00c73c" : "transparent",
                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                >
                  {index + 1}
                </button>
              );
            }
          }
          return null;
        })}
        {Math.floor(pageIndex / 5) < Math.floor(pageCount / 5) && (
          <button onClick={() => gotoPage(Math.floor(pageIndex / 5 + 1) * 5)}>
            {">"}
          </button>
        )}
      </div>{" "}
    </>
  );
}

export default NewsCustomTable;
