import { useState, useRef } from "react";
import Logo from "../../assets/images/Logo.png";
import { Link, useLocation } from "react-router-dom";
import { CommonAxios } from "../CommonAxios";
import {
  // faColumns,
  faInfo,
  // faPersonWalking,
  // faUser,
  // faChalkboard,
  // faMagnifyingGlass,
  // faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FiUsers } from "react-icons/fi";
import { SiQuicklook } from "react-icons/si";
import { HiOutlineSquares2X2 } from "react-icons/hi2";
// import { BsCreditCard } from "react-icons/bs";
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import { BsClipboard2Pulse, BsCart4 } from "react-icons/bs";
import { AiOutlineHome, AiOutlineFundProjectionScreen } from "react-icons/ai";
import { MdOutlineContentPaste } from "react-icons/md";
import logo1 from "../../assets/images/Artboard 2.png";
// import { SlNote } from "react-icons/sl";
// import { RiCoupon2Line } from "react-icons/ri";
// import { ImStatsDots } from "react-icons/im";
// import { RiComputerLine } from "react-icons/ri";
import { BiLinkExternal, BiStoreAlt, BiLogOut } from "react-icons/bi";
import styles from "../../styles/admin.module.css";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaChartPie } from "react-icons/fa";
import { useEffect } from "react";
import { RiAdminLine } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { getJoin, getInte, getEnd, getRefu, getQna } from "../../modules/admin";
const Dashboad = () => {
  const { joinArr, inteArr, endArr, refuArr, qnaArr } = useSelector(
    ({ admin }) => ({
      joinArr: admin.joinArr,
      inteArr: admin.inteArr,
      endArr: admin.endArr,
      refuArr: admin.refuArr,
      qnaArr: admin.qnaArr,
    })
  );
  const dispatch = useDispatch();
  const [dashMenu, setDashMenu] = useState(false);

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [userMenuView, setUserMenuView] = useState(false);
  const [productMenuView, setProductMenuView] = useState(false);

  const location = useLocation();

  useEffect(() => {
    let adrs = location.pathname.split("/")[2];
    if (adrs == "users") {
      setUserMenuView(true);
      setSubTitle("users");
    } else if (adrs == "product") {
      setProductMenuView(true);
      setSubTitle("product");
    }
  }, []);

  const DashBoardMenu1 = () => {
    const bgClick = (value) => {
      setSubTitle(value);
      setTitle("회원관리");
    };

    return (
      <>
        <div
          className={
            userMenuView
              ? `${styles.dashboard_subject} ${styles.viewActive}`
              : `${styles.dashboard_subject}`
          }
        >
          <div
            className={
              title === "회원관리"
                ? `${styles.dashboard_content_list} ${styles.admin_active}`
                : `${styles.dashboard_content_list}`
            }
            onClick={() => setUserMenuView(!userMenuView)}
          >
            <span className={styles.icons}>
              <FiUsers />
            </span>
            <span className={styles.text}>회원관리</span>
            {!userMenuView && (
              <span className={styles.span_arrow}>
                <TiArrowSortedDown />
              </span>
            )}
            {userMenuView && (
              <span className={styles.span_arrow}>
                <TiArrowSortedUp />
              </span>
            )}
          </div>{" "}
          <div>
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "회원 분석"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/user_analyze"
                onClick={() => bgClick("회원 분석")}
              >
                <span className={styles.text}>회원 분석</span>
              </Link>
            </div>{" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "users"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/users"
                onClick={() => bgClick("users")}
              >
                <span className={styles.text}>회원 목록</span>
              </Link>
            </div>{" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "delete"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/users_delete"
                onClick={() => bgClick("delete")}
              >
                <span className={styles.text}>탈퇴 회원</span>
              </Link>
            </div>
            {/* <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "회원 탈퇴.삭제 관리"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/remove"
                onClick={() => bgClick("회원 탈퇴.삭제 관리")}
              >
                <span className={styles.text}>회원 탈퇴.삭제 관리</span>
              </Link>
            </div> */}
            {/* <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "계정생성"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/useradd"
                onClick={() => bgClick("계정생성")}
              >
                <span className={styles.text}>계정생성</span>
              </Link>
            </div> */}
            {/* <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "채팅문의"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/chats"
                onClick={() => bgClick("채팅문의")}
              >
                <span className={styles.text}>채팅문의</span>
              </Link>
            </div> */}
          </div>
        </div>
      </>
    );
  };
  const [menuView2, setMenuView2] = useState(false);
  const titleClick2 = (view) => {
    setMenuView2(!view);
  };

  const DashBoardMenu2 = () => {
    const bgClick = (value) => {
      setSubTitle(value);
      setTitle("홈");
    };
    return (
      <>
        <div
          className={
            menuView2
              ? `${styles.dashboard_subject} ${styles.viewActive}`
              : `${styles.dashboard_subject}`
          }
        >
          <div
            className={
              title === "홈"
                ? `${styles.dashboard_content_list} ${styles.admin_active}`
                : `${styles.dashboard_content_list}`
            }
            onClick={() => titleClick2(menuView2)}
          >
            <span className={styles.icons}>
              <AiOutlineHome />
            </span>
            <span className={styles.text}>홈</span>
            {!menuView2 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedDown />
              </span>
            )}
            {menuView2 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedUp />
              </span>
            )}
          </div>
          <div>
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "공지사항"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/notice"
                onClick={() => bgClick("공지사항")}
              >
                <span className={styles.text}>공지사항</span>
              </Link>
            </div>{" "}
            {/* <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "1:1 문의"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/qna"
                onClick={() => bgClick("1:1 문의")}
              >
                <span className={styles.text}>1:1 문의</span>
              </Link>
            </div>{" "} */}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "메인 팝업창"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/popup"
                onClick={() => bgClick("메인 팝업창")}
              >
                <span className={styles.text}>메인 팝업창</span>
              </Link>
            </div>{" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "배너 관리"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/banner"
                onClick={() => bgClick("배너 관리")}
              >
                <span className={styles.text}>배너 관리</span>
              </Link>
            </div>{" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "푸터 관리"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/footer"
                onClick={() => bgClick("푸터 관리")}
              >
                <span className={styles.text}>푸터 관리</span>
              </Link>
            </div>
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "브랜드 관리"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/brand"
                onClick={() => bgClick("브랜드 관리")}
              >
                <span className={styles.text}>브랜드 관리</span>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  };

  const [menuView3, setMenuView3] = useState(false);
  const titleClick3 = (view) => {
    setMenuView3(!view);
  };

  const DashBoardMenu3 = () => {
    const bgClick = (value) => {
      setSubTitle(value);
      setTitle("회사소개");
    };
    return (
      <>
        <div
          className={
            menuView3
              ? `${styles.dashboard_subject} ${styles.viewActive}`
              : `${styles.dashboard_subject}`
          }
        >
          <div
            className={
              title === "회사소개"
                ? `${styles.dashboard_content_list} ${styles.admin_active}`
                : `${styles.dashboard_content_list}`
            }
            onClick={() => titleClick3(menuView3)}
          >
            <span className={styles.icons}>
              <MdOutlineContentPaste />
            </span>
            <span className={styles.text}>회사소개</span>
            {!menuView3 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedDown />
              </span>
            )}
            {menuView3 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedUp />
              </span>
            )}
          </div>{" "}
          <div>
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "회사소개 및 자세히보기"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/introduce"
                onClick={() => bgClick("회사소개 및 자세히보기")}
              >
                <span className={styles.text}>회사소개 및 자세히보기</span>
              </Link>
            </div>
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "언론보도"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/press"
                onClick={() => bgClick("언론보도")}
              >
                <span className={styles.text}>언론보도</span>
              </Link>
            </div>
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "스토리"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/story"
                onClick={() => bgClick("스토리")}
              >
                <span className={styles.text}>스토리</span>
              </Link>
            </div>{" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "리뷰"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/review"
                onClick={() => bgClick("리뷰")}
              >
                <span className={styles.text}>리뷰</span>
              </Link>
            </div>{" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "자주하는 질문"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/fnq"
                onClick={() => bgClick("자주하는 질문")}
              >
                <span className={styles.text}>자주하는 질문</span>
              </Link>
            </div>{" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "파트너사"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/partner"
                onClick={() => bgClick("파트너사")}
              >
                <span className={styles.text}>파트너사</span>
              </Link>
            </div>
            {/* <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "farm"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/farm"
                onClick={() => bgClick("farm")}
              >
                <span className={styles.text}>농장 정보 목록</span>
              </Link>
            </div> */}
          </div>
        </div>
      </>
    );
  };

  const [menuView4, setMenuView4] = useState(false);
  const titleClick4 = (view) => {
    setMenuView4(!view);
  };

  const DashBoardMenu4 = () => {
    const bgClick = (value) => {
      setSubTitle(value);
      setTitle("펀딩+");
    };
    return (
      <>
        <div
          className={
            menuView4
              ? `${styles.dashboard_subject} ${styles.viewActive}`
              : `${styles.dashboard_subject}`
          }
        >
          <div
            className={
              title === "펀딩+"
                ? `${styles.dashboard_content_list} ${styles.admin_active}`
                : `${styles.dashboard_content_list}`
            }
            onClick={() => titleClick4(menuView4)}
          >
            <span className={styles.icons}>
              <FaMoneyBillTrendUp />
            </span>
            <span className={styles.text}>펀딩+</span>
            {!menuView4 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedDown />
              </span>
            )}
            {menuView4 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedUp />
              </span>
            )}
          </div>
          <div>
            {" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "카테고리"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/category"
                onClick={() => bgClick("카테고리")}
              >
                <span className={styles.text}>카테고리</span>
              </Link>
            </div>{" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "상세페이지"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/product_page"
                onClick={() => bgClick("상세페이지")}
              >
                <span className={styles.text}>상세페이지</span>
              </Link>
            </div>
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "규약/규정/설명서"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/product_pdf"
                onClick={() => bgClick("규약/규정/설명서")}
              >
                <span className={styles.text}>규약/규정/설명서</span>
              </Link>
            </div>{" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "가입 신청 안내"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/product_popup"
                onClick={() => bgClick("가입 신청 안내")}
              >
                <span className={styles.text}>가입 신청 안내</span>
              </Link>
            </div>
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "product"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/product"
                onClick={() => bgClick("product")}
              >
                <span className={styles.text}>상품</span>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  };

  const [menuView5, setMenuView5] = useState(false);
  const titleClick5 = (view) => {
    setMenuView5(!view);
  };

  const DashBoardMenu5 = () => {
    const bgClick = (value) => {
      setSubTitle(value);
      setTitle("스토어");
    };
    return (
      <>
        <div
          className={
            menuView5
              ? `${styles.dashboard_subject} ${styles.viewActive}`
              : `${styles.dashboard_subject}`
          }
        >
          <div
            className={
              title === "스토어"
                ? `${styles.dashboard_content_list} ${styles.admin_active}`
                : `${styles.dashboard_content_list}`
            }
            onClick={() => titleClick5(menuView5)}
          >
            <span className={styles.icons}>
              <BiStoreAlt />
            </span>
            <span className={styles.text}>스토어</span>
            {!menuView5 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedDown />
              </span>
            )}
            {menuView5 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedUp />
              </span>
            )}
          </div>{" "}
          <div>
            {" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "카테고리2"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/storeCategory"
                onClick={() => bgClick("카테고리2")}
              >
                <span className={styles.text}>카테고리</span>
              </Link>
            </div>
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "store"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/store"
                onClick={() => bgClick("store")}
              >
                <span className={styles.text}>상품</span>
              </Link>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const [menuView6, setMenuView6] = useState(false);
  const titleClick6 = (view) => {
    setMenuView6(!view);
  };

  const DashBoardMenu6 = () => {
    const bgClick = (value) => {
      setSubTitle(value);
      setTitle("투자관리");
    };
    return (
      <>
        <div
          className={
            menuView6
              ? `${styles.dashboard_subject} ${styles.viewActive}`
              : `${styles.dashboard_subject}`
          }
        >
          <div
            className={
              title === "투자관리"
                ? `${styles.dashboard_content_list} ${styles.admin_active}`
                : `${styles.dashboard_content_list}`
            }
            onClick={() => titleClick6(menuView6)}
          >
            <span className={styles.icons}>
              <AiOutlineFundProjectionScreen />
            </span>
            <span className={styles.text}>투자관리</span>
            {!menuView6 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedDown />
              </span>
            )}
            {menuView6 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedUp />
              </span>
            )}
          </div>
          <div>
            {" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "투자분석"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/invest_analyze"
                onClick={() => bgClick("투자분석")}
              >
                <span className={styles.text}>투자분석</span>
              </Link>
            </div>{" "}
            {/* <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "투자 가입 신청"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/deposit"
                onClick={() => bgClick("투자 가입 신청")}
              >
                <span className={styles.text}>투자 가입 신청</span>
              </Link>
            </div>{" "} */}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "투자 진행 현황"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/ing"
                onClick={() => bgClick("투자 진행 현황")}
              >
                <span className={styles.text}>투자진행 현황</span>
              </Link>
            </div>
            {/* <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "이자 출금 신청"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/withDraw"
                onClick={() => bgClick("이자 출금 신청")}
              >
                <span className={styles.text}>이자 출금 신청</span>
              </Link>
            </div>{" "} */}
            {/* <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "투자 종료 신청"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/investend"
                onClick={() => bgClick("투자 종료 신청")}
              >
                <span className={styles.text}>투자 종료 신청</span>
              </Link>
            </div>
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "환불 및 취소 신청"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/refund"
                onClick={() => bgClick("환불 및 취소 신청")}
              >
                <span className={styles.text}>환불 및 취소 신청</span>
              </Link>
            </div> */}
          </div>
        </div>
      </>
    );
  };
  const [menuView7, setMenuView7] = useState(false);
  const titleClick7 = (view) => {
    setMenuView7(!view);
  };

  const DashBoardMenu7 = () => {
    const bgClick = (value) => {
      setSubTitle(value);
      setTitle("회사정보");
    };
    return (
      <>
        <div
          className={
            menuView7
              ? `${styles.dashboard_subject} ${styles.viewActive}`
              : `${styles.dashboard_subject}`
          }
        >
          <div
            className={
              title === "회사정보"
                ? `${styles.dashboard_content_list} ${styles.admin_active}`
                : `${styles.dashboard_content_list}`
            }
            onClick={() => titleClick7(menuView7)}
          >
            <span className={styles.icons}>
              <FontAwesomeIcon icon={faInfo} />
            </span>
            <span className={styles.text}>회사정보</span>
            {!menuView7 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedDown />
              </span>
            )}
            {menuView7 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedUp />
              </span>
            )}
          </div>
          <div>
            {" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "계좌번호 및 환급 비율"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/investinfo"
                onClick={() => bgClick("계좌번호 및 환급 비율")}
              >
                <span className={styles.text}>계좌번호 및 환급 비율</span>
              </Link>
            </div>{" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "정책 및 약관"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/policy"
                onClick={() => bgClick("정책 및 약관")}
              >
                <span className={styles.text}>정책 및 약관</span>
              </Link>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };

  const [menuView8, setMenuView8] = useState(false);
  const titleClick8 = (view) => {
    setMenuView8(!view);
  };

  const DashBoardMenu8 = () => {
    const bgClick = (value) => {
      setSubTitle(value);
      setTitle("투자현황");
    };
    return (
      <>
        <div
          className={
            menuView8
              ? `${styles.dashboard_subject} ${styles.viewActive}`
              : `${styles.dashboard_subject}`
          }
        >
          <div
            className={
              title === "투자현황"
                ? `${styles.dashboard_content_list} ${styles.admin_active}`
                : `${styles.dashboard_content_list}`
            }
            onClick={() => titleClick8(menuView8)}
          >
            <span className={styles.icons}>
              {/* <GiFarmTractor icon={faInfo} /> */}
              <SiQuicklook />
            </span>
            <span className={styles.text}>투자현황</span>
            {!menuView8 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedDown />
              </span>
            )}
            {menuView8 && (
              <span className={styles.span_arrow}>
                <TiArrowSortedUp />
              </span>
            )}
          </div>
          <div>
            {" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "상품투자현황"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/goods"
                onClick={() => bgClick("상품투자현황")}
              >
                <span className={styles.text}>상품투자현황</span>
              </Link>
            </div>{" "}
            <div className={styles.dashboard_list}>
              <Link
                style={
                  subTitle === "농장투자현황"
                    ? { width: "100%", background: "white", color: "black" }
                    : { width: "100%", color: "#fff" }
                }
                to="/admin/farm"
                onClick={() => bgClick("농장투자현황")}
              >
                <span className={styles.text}>농장투자현황</span>
              </Link>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const btn_logout = () => {
    let sendData = "";
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/signout",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          localStorage.removeItem("meta_info");
          document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  };
  const bgClick2 = (value) => {
    setSubTitle(value);
    setTitle("");
  };

  const arrFilter = (data) => {
    let arr = [];

    data.map((data1) => {
      arr.push(data1.no);
    });
    return arr;
  };

  useEffect(() => {
    let sendData = "";
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_menu_cnt",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          // dispatch(getNews(arrFilter(result.messageinfo.message[0])));
          dispatch(getJoin(result.messageinfo.message[0]));
          dispatch(getInte(result.messageinfo.message[1]));
          dispatch(getEnd(result.messageinfo.message_data2[0]));
          dispatch(getRefu(result.messageinfo.message_data2[1]));
          dispatch(getQna(result.messageinfo.message_data3[0]));
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  }, []);

  // useEffect(() => {
  //   var sendData = {};
  //   dispatch(getNews(sendData));
  // }, []);
  return (
    <>
      <div className={styles.wrap_dashboard_box}>
        <div className={`${styles.dashboard_content_list} ${styles.logo_box}`}>
          <Link style={{ width: "100%" }} to="/">
            <img className={styles.logo_img} src={logo1} />
          </Link>
        </div>
        <div className={styles.horizen}></div>
        <div className={`${styles.dashboard_content_list} ${styles.first}`}>
          <Link
            onClick={() => bgClick2("관리자 정보 변경")}
            style={
              subTitle === "관리자 정보 변경"
                ? { width: "100%", background: "white", color: "black" }
                : { width: "100%", color: "#fff" }
            }
            to="/admin"
          >
            <span className={styles.icons}>
              <RiAdminLine style={{ color: "#fff" }} />
            </span>
            <span
              className={styles.text}
              style={
                subTitle === "관리자 정보 변경"
                  ? { color: "black" }
                  : { color: "#fff" }
              }
            >
              관리자 정보 변경
            </span>
          </Link>
        </div>
        <div className={`${styles.dashboard_content_list} ${styles.first}`}>
          {/* <Link style={{ width: "100%" }} to="/admin"> */}
          <span className={styles.icons}>
            <BiLogOut />
          </span>
          <span className={styles.text} onClick={btn_logout}>
            로그아웃
          </span>
          {/* </Link> */}
        </div>
        <div className={`${styles.dashboard_content_list} ${styles.first} `}>
          <Link
            to="/admin/deposit"
            onClick={() => bgClick2("투자가입 신청")}
            style={
              subTitle === "투자가입 신청"
                ? {
                    width: "100%",
                    background: "white",
                    color: "black",
                    display: "flex",
                    alignItems: "center",

                    // lineHeight: "40px",
                  }
                : {
                    width: "100%",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            <span className={styles.icons}>
              <RiAdminLine style={{ color: "#fff", opacity: "0" }} />
            </span>
            <span
              className={styles.text}
              style={
                subTitle === "투자가입 신청"
                  ? { color: "black", lineHeight: "40px" }
                  : { color: "#fff", lineHeight: "40px" }
              }
            >
              투자가입 신청
            </span>{" "}
            {joinArr && joinArr.length > 0 && (
              <i className={styles.icons_count}>
                <span>{joinArr.length > 10 ? "10" : joinArr.length}</span>
              </i>
            )}
          </Link>
        </div>
        <div className={`${styles.dashboard_content_list} ${styles.first}`}>
          <Link
            to="/admin/withDraw"
            onClick={() => bgClick2("이자출금 신청")}
            style={
              subTitle === "이자출금 신청"
                ? {
                    width: "100%",
                    background: "white",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    width: "100%",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            <span className={styles.icons}>
              <RiAdminLine style={{ color: "#fff", opacity: "0" }} />
            </span>
            <span
              className={styles.text}
              style={
                subTitle === "이자출금 신청"
                  ? { color: "black", lineHeight: "40px" }
                  : { color: "#fff", lineHeight: "40px" }
              }
            >
              이자출금 신청
            </span>{" "}
            {inteArr && inteArr.length > 0 && (
              <i className={styles.icons_count}>
                <span>{inteArr.length > 10 ? "10" : inteArr.length}</span>
              </i>
            )}
          </Link>
        </div>
        <div className={`${styles.dashboard_content_list} ${styles.first}`}>
          <Link
            to="/admin/investend"
            onClick={() => bgClick2("투자종료 신청")}
            style={
              subTitle === "투자종료 신청"
                ? {
                    width: "100%",
                    background: "white",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    width: "100%",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            <span className={styles.icons}>
              <RiAdminLine style={{ color: "#fff", opacity: "0" }} />
            </span>
            <span
              className={styles.text}
              style={
                subTitle === "투자종료 신청"
                  ? { color: "black", lineHeight: "40px" }
                  : { color: "#fff", lineHeight: "40px" }
              }
            >
              투자종료 신청
            </span>{" "}
            {endArr && endArr.length > 0 && (
              <i className={styles.icons_count}>
                <span>{endArr.length > 10 ? "10" : endArr.length}</span>
              </i>
            )}
            {/* <i className={styles.icons_count}>
              <span>10+</span>
            </i> */}
          </Link>
        </div>
        <div className={`${styles.dashboard_content_list} ${styles.first}`}>
          <Link
            to="/admin/refund"
            onClick={() => bgClick2("환불취소 신청")}
            style={
              subTitle === "환불취소 신청"
                ? {
                    width: "100%",
                    background: "white",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    width: "100%",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            <span className={styles.icons}>
              <RiAdminLine style={{ color: "#fff", opacity: "0" }} />
            </span>
            <span
              className={styles.text}
              style={
                subTitle === "환불취소 신청"
                  ? { color: "black", lineHeight: "40px" }
                  : { color: "#fff", lineHeight: "40px" }
              }
            >
              환불취소 신청
            </span>{" "}
            {refuArr && refuArr.length > 0 && (
              <i className={styles.icons_count}>
                <span>{refuArr.length > 10 ? "10" : refuArr.length}</span>
              </i>
            )}
          </Link>
        </div>
        <div className={`${styles.dashboard_content_list} ${styles.first}`}>
          <Link
            to="/admin/qna"
            onClick={() => bgClick2("1:1 문의")}
            style={
              subTitle === "1:1 문의"
                ? {
                    width: "100%",
                    background: "white",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    width: "100%",
                    color: "#fff",
                    display: "flex",
                    alignItems: "center",
                  }
            }
          >
            <span className={styles.icons}>
              <RiAdminLine style={{ color: "#fff", opacity: "0" }} />
            </span>
            <span
              className={styles.text}
              style={
                subTitle === "1:1 문의"
                  ? { color: "black", lineHeight: "40px" }
                  : { color: "#fff", lineHeight: "40px" }
              }
            >
              1:1 문의
            </span>{" "}
            {qnaArr && qnaArr.length > 0 && (
              <i className={styles.icons_count}>
                <span>{qnaArr.length > 10 ? "10" : qnaArr.length}</span>
              </i>
            )}
          </Link>
        </div>
        {/* <div
          className={`${styles.dashboard_content_list} ${styles.middle_text}`}
        >
          사이트 관리
        </div> */}
        <DashBoardMenu6 /> <DashBoardMenu8 />
        <DashBoardMenu1 />
        <DashBoardMenu2 />
        <DashBoardMenu3 />
        <DashBoardMenu4 />
        <DashBoardMenu5 />
        <DashBoardMenu7 />
      </div>
    </>
  );
};

export default Dashboad;
