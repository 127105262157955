import styles from "../../styles/admin.module.css";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../CommonAxios";
import NoticeWrite from "./detail/NoticeWriteDetail";
import NoticeViewDetail from "./detail/NoticeViewDetail";

const NoticeView = () => {
  const [options, setOptions] = useState("all");

  const [userInfo, setUserInfo] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    let sendData = {
      no: id,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_user_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          // setUserInfo(result.messageinfo.message[0]);
          // localStorage.removeItem("meta_info");
          // document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  }, []);

  return (
    <>
      {" "}
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          홈 &nbsp;〉 &nbsp;공지사항 목록〉 &nbsp;공지사항 상세
        </div>
        <div className={styles.wrap_content_box_body}>
          <NoticeViewDetail />
          {/* {userInfo && <AdminUserInfo userInfo={userInfo} />} */}
          {/* <div className={styles.user_header}>
            <div className={styles.user_option}>
              <div
                className={
                  options == "all"
                    ? `${styles.all} ${styles.clicked}`
                    : styles.all
                }
                onClick={() => setOptions("all")}
              >
                전체 사용자 &nbsp;
              </div>
              <div
                className={
                  options == "noraml"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                onClick={() => setOptions("noraml")}
              >
                일반 사용자 &nbsp;{" "}
              </div>
              <div
                className={
                  options == "develop"
                    ? `${styles.develop} ${styles.clicked}`
                    : styles.develop
                }
                onClick={() => setOptions("develop")}
              ></div>
              <div
                className={
                  options == "admin"
                    ? `${styles.admin} ${styles.clicked}`
                    : styles.admin
                }
                onClick={() => setOptions("admin")}
              ></div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default NoticeView;
