import inv from "../../../styles/invest.module.css";
import { GiFarmTractor } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcStackOfPhotos,
  FcSelfie,
  FcRuler,
} from "react-icons/fc";
import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// import FundingContentItem from "./FundingContentItem";

const MyInvestContent = () => {
  const location = useLocation();
  const [locationData, setLocationData] = useState("");
  useEffect(() => {
    setLocationData(location.pathname);
  }, [location]);
  return (
    <div className={inv.rowgroup}>
      {" "}
      <div className={inv.tab}>
        {/* <h2 className={inv.skip}>메뉴 버튼</h2> */}
        <ul className={`${inv.tab_list} ${inv.clearfix}`}>
          <li
            className={
              locationData === "/my/invest/join"
                ? `${inv.tab_item} ${inv.active}`
                : `${inv.tab_item}`
            }
          >
            <Link
              to={`/my/invest/join`}
              className={inv.tab_button}
              type="button"
            >
              <span>가입상품</span>
            </Link>
          </li>
          <li
            className={
              locationData === "/my/invest/end"
                ? `${inv.tab_item} ${inv.active}`
                : `${inv.tab_item}`
            }
          >
            <Link
              to={`/my/invest/end`}
              className={inv.tab_button}
              type="button"
            >
              <span>종료상품</span>
            </Link>
          </li>
          <li
            className={
              locationData === "/my/invest/cancel"
                ? `${inv.tab_item} ${inv.active}`
                : `${inv.tab_item}`
            }
          >
            <Link
              to={`/my/invest/cancel`}
              className={inv.tab_button}
              type="button"
            >
              <span>대기/취소상품</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default MyInvestContent;
