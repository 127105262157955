import React, { useState, useEffect, useRef } from "react";
import FarmTable from "../components/admin/details/FarmTable";
import { useNavigate } from "react-router-dom";
import styles from "../styles/admin.module.css";
import CategoryTable from "../components/admin/details/CategoryTable";
import PartnerTable from "../components/admin/details/PartnerTable";
const PartnerList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const clickMove = () => {
    // alert("개발중");
    // return;
    setOpen(true);
    setOpen2(false);
  };
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          홈 &nbsp;〉 &nbsp;파트너사 목록{" "}
          <span className={styles.header_button}>
            <button onClick={clickMove} style={{ cursor: "pointer" }}>
              파트너사 등록
            </button>
          </span>
        </div>
        <div className={styles.wrap_content_box_body}>
          <PartnerTable
            setOpen={setOpen}
            setOpen2={setOpen2}
            open2={open2}
            open={open}
          />
        </div>
      </div>
    </>
  );
};

export default PartnerList;
