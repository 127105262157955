import React, { useMemo, useState, useEffect, useRef } from "react";
// import qna from "../../styles/styles.module.css";
import styles from "../../styles/mypage.module.css";
import { CommonAxios } from "../CommonAxios";
import Moment from "react-moment";
import { FaExclamation } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { textReplace } from "../../lib/textReplace";
import axios from "axios";
import { useCallback } from "react";
const MypageQnaWriteDetail = () => {
  const [data, setData] = useState([]);
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 1024 * 2;
    // alert(imgExtension[1]);
    if (file[0] === undefined) {
      return;
    }
    if (
      imgExtension[1] !== "jpg" &&
      imgExtension[1] !== "png" &&
      imgExtension[1] !== "jpeg" &&
      imgExtension[1] !== "pdf" &&
      imgExtension[1] !== "gif"
    ) {
      Swal.fire({
        title: "업로드 불가",
        text: "gif,png, jpg, jpeg, pdf 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    if (file[0].size >= maxSize) {
      //이미지 사이즈가 500k보다 크다면,
      setImageFile(imageFile);
      Swal.fire({
        title: "용량 초과",
        html: `2MB 이하로 올려주세요.`,

        confirmButtonText: "확인",
      });
      return;
    } else {
      setImageFile(file);
    }
  };

  const inpChange2 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 1024 * 2;

    if (file[0] === undefined) {
      return;
    }
    if (
      imgExtension[1] !== "jpg" &&
      imgExtension[1] !== "png" &&
      imgExtension[1] !== "jpeg" &&
      imgExtension[1] !== "pdf" &&
      imgExtension[1] !== "gif"
    ) {
      Swal.fire({
        title: "업로드 불가",
        text: "gif,png, jpg, jpeg, pdf 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    if (file[0].size >= maxSize) {
      //이미지 사이즈가 500k보다 크다면,
      setImageFile2(imageFile2);
      Swal.fire({
        title: "용량 초과",
        html: `2MB 이하로 올려주세요.`,

        confirmButtonText: "확인",
      });
      return;
    } else {
      setImageFile2(file);
    }
  };

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const changeTitle = useCallback((e) => {
    setTitle(e.target.value);
  }, []);
  const changeContent = useCallback((e) => {
    setContent(e.target.value);
  }, []);
  const btn_reg = () => {
    if (!title || !content) {
      return Swal.fire({
        title: "빈칸을 입력해주세요.",
        // text: "gif,png, jpg, jpeg, pdf 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
    }
    // Swal.fire({
    //   title: "수정중",
    //   // text: "gif,png, jpg, jpeg, pdf 확장자 파일만 올려주세요.",

    //   confirmButtonText: "확인",
    // });

    // return;
    let sendData = {
      qna_user_no: user.no,
      qna_user_email: user.ur_email,
      qna_title: textReplace(title),
      qna_content: textReplace(content),
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/qna_insert",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          if (imageFile || imageFile2) {
            let dataFileArr = [];
            let dataFileNameArr = [];
            if (imageFile) {
              dataFileArr.push(imageFile[0]);
              dataFileNameArr.push(imageFile[0].name);
            }
            if (imageFile2) {
              dataFileArr.push(imageFile2[0]);
              dataFileNameArr.push(imageFile2[0].name);
            }

            // formData.append("file", img);
            // formData.append("filename", img.name);

            const formData = new FormData();
            formData.append("no", result.messageinfo.message);

            if (imageFile) {
              formData.append("file", imageFile[0]);
              formData.append("filename", imageFile[0].name);
            }

            if (imageFile2) {
              formData.append("file", imageFile2[0]);

              formData.append("filename2", imageFile2[0].name);
            }

            // formData.append("title", "ihl");

            axios
              .post(
                process.env.REACT_APP_HOSTDONAME + "/api/qna_upload_img",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                if (response.data.messageinfo.state === "ok") {
                  Swal.fire({
                    title: "등록 완료",
                    // text: "gif,png, jpg, jpeg, pdf 확장자 파일만 올려주세요.",
                    closeOnClickOutside: false,
                    confirmButtonText: "확인",
                  }).then((res) => {
                    if (res.isConfirmed) {
                      navigate("/mypage/qna");
                    }
                  });
                  // setUploadImage(
                  //   uploadImage.concat({
                  //     id: uploadImageRef.current++,
                  //     imageUrl: `/classImage/${classinfo}/${chapterNum}/${path}/${fileNum}`,
                  //     imagename: fileNum,
                  //   })
                  // );
                  // setImageText(imageText + fileNum + ",");
                  // // 응답 처리
                  // setSpinnerView(false);
                }
              })
              .catch((error) => {
                // 예외 처리
              });
          } else {
            Swal.fire({
              title: "등록 완료",
              // text: "gif,png, jpg, jpeg, pdf 확장자 파일만 올려주세요.",
              closeOnClickOutside: false,
              confirmButtonText: "확인",
            }).then((res) => {
              if (res.isConfirmed) {
                navigate("/mypage/qna");
              }
            });
          }
          // navigate("/admin/notice");
        } else {
          // navigate("/admin/notice");
        }
      }
    );

    // const res = await axios.post("http://localhost:4000/api/upload", formData);
  };
  return (
    <>
      <div className={styles.mypage_content_right}>
        {" "}
        <div className={styles.mypage_content_tit_div}>
          <h3 className={styles.mypage_content_title}>1:1 문의 등록</h3>
          <button onClick={() => navigate(-1)}>이전으로</button>
        </div>
        <div className={`${styles.mypage_content_inner} ${styles.ivest_wrap} `}>
          <div className={styles.qna_content_wrap}>
            <div className={styles.qna_content}>
              <div className={styles.qna_content_box}>
                <div className={styles.qna_content_list}>
                  <div className={styles.qna_list_left}>
                    <p>아이디</p>
                  </div>
                  <div className={styles.qna_list_right}>
                    <span>{user.ur_email}</span>
                  </div>
                </div>
                <div className={styles.qna_content_list}>
                  <div className={styles.qna_list_left}>
                    {" "}
                    <p>문의제목</p>
                  </div>
                  <div className={styles.qna_list_right}>
                    <div className={styles.qna_right_inpdiv}>
                      <input type="text" onChange={changeTitle} value={title} />
                    </div>
                  </div>
                </div>
                <div className={styles.qna_content_list}>
                  <div className={styles.qna_list_left}>
                    <p>문의내용</p>
                  </div>
                  <div className={styles.qna_list_right}>
                    {" "}
                    <div className={styles.qna_right_inpdiv}>
                      <textarea
                        onChange={changeContent}
                        value={content}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className={styles.qna_content_list}>
                  <div className={styles.qna_list_left}>
                    {" "}
                    <p>파일첨부</p>
                  </div>
                  <div className={styles.qna_list_right}>
                    <ul>
                      <li>
                        {" "}
                        <input type="file" onChange={inpChange} />
                      </li>{" "}
                      <li>
                        {" "}
                        <input type="file" onChange={inpChange2} />
                      </li>
                    </ul>
                    <span>
                      파일 첨부는 선택이며, 최대 2개 2MB 이하의 파일만 첨부
                      가능합니다.
                    </span>
                    <span>첨부가능 파일확장자 : gif, png, jpg, jpeg, pdf</span>
                  </div>
                </div>
              </div>{" "}
            </div>{" "}
            <div className={styles.qna_button_div}>
              <button className={styles.qna_button} onClick={btn_reg}>
                제출
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MypageQnaWriteDetail;
