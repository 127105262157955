import React, { useState, useEffect, useRef } from "react";
import ProductInfo from "./../components/admin/details/ProductInfo";
import ProductSubTable from "./../components/admin/details/ProductSubTable";
import ProductInvestList from "../components/admin/details/ProductInvestList";
import Moment from "react-moment";
import styles from "../styles/admin.module.css";
import ProductWrite from "../components/admin/ProductWrite";
import FarmWrite from "../components/admin/FarmWrite";
import GoodsWrite from "../components/admin/GoodsWrite";

const GoodsWritePage = () => {
  return (
    <>
      <GoodsWrite />
    </>
  );
};

export default GoodsWritePage;
