import styles from "../../../styles/mypage.module.css";
import { FaWonSign } from "react-icons/fa";
import { BsFillBox2HeartFill, BsFillQuestionSquareFill } from "react-icons/bs";
import { useState, useEffect } from "react";
import Moment from "react-moment";
import InvestCustomTable from "../table/InvestCustomTable";
import { CommonAxios } from "../../CommonAxios";
import { useSelector } from "react-redux";
import { numberToKorean } from "../../../lib/numberToKorean";
import moment from "moment";
import { wonComma } from "../../../lib/wonComma";
const MypageInvest = ({
  type,
  startDate,
  endDate,
  data,
  setData,
  setStartDate,
  swi,
  setEndDate,
  setBtnName,
}) => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));

  const TableColumn = [
    {
      Header: "상품명",
      accessor: "pro_title",
      Cell: ({ row }) => <Title row={row} />,
    },
    {
      Header: "상품적용일",
      accessor: "invest_start_date",
      Cell: ({ cell: { value } }) => <Date1 value={value} />,
    },
    {
      Header: "상품마감일",
      accessor: "invest_end_date",
      Cell: ({ cell: { value } }) => <Date1 value={value} />,
    },
    {
      Header: "투자금액",
      accessor: "invest_amount",
      Cell: ({ cell: { value } }) => <>{wonComma(numberToKorean(value))}원</>,
    },
    {
      Header: "현재 수익률",
      accessor: "pro_rate",
      Cell: ({ cell: { value } }) => <>{value}%</>,
    },
    {
      Header: "투자진행상황",
      accessor: "invest_interest_withdraw",

      Cell: (row) => {
        let data = row.cell;
        let withdraw = data.row.original.invest_interest_withdraw;
        let ing = data.row.original.invest_confirm_ing;

        const value = (withdraw, ing) => {
          if (withdraw === "N" || withdraw === "Y") {
            if (ing === "N") {
              return <>시작 전</>;
            }
            if (ing === "E") {
              return <>종료 대기</>;
            }
            return <>진행중</>;
          } else if (withdraw === "C") {
            return <>취소 대기</>;
          } else if (withdraw === "R") {
            return <>환불 대기</>;
          } else if (withdraw === "E") {
            return <>종료 대기</>;
          }
        };

        return (
          <>
            {value(withdraw, ing)}
            {/* <div
              style={{ cursor: "pointer" }}
              onClick={() => userDetailInfo(oriNo)}
            >
              {data.value}
            </div> */}
          </>
        );
      },
    },
  ];

  const TableColumn1 = [
    {
      Header: "상품명",
      accessor: "pro_title",
      Cell: ({ row }) => <Title row={row} />,
    },
    {
      Header: "상태",
      accessor: "end_kind",
      Cell: ({ cell: { value } }) => <>{value === "R" ? "환불" : "종료"}</>,
    },
    {
      Header: "종료날짜",
      accessor: "end_confirm_date",
      Cell: ({ cell: { value } }) => <Date1 value={value} />,
    },
    {
      Header: "투자금액",
      accessor: "end_amount",
      Cell: ({ cell: { value } }) => <>{wonComma(numberToKorean(value))}원</>,
    },
    {
      Header: "환급금액",
      accessor: "end_amount_result",
      Cell: ({ cell: { value } }) => <>{wonComma(numberToKorean(value))}원</>,
    },
  ];
  const TableColumn3 = [
    {
      Header: "상품명",
      accessor: "pro_title",
      Cell: ({ row }) => <Title row={row} />,
    },
    {
      Header: "상태",
      accessor: "end_kind",
      Cell: ({ cell: { value } }) => <>{value === "R" ? "환불" : "취소"}</>,
    },
    {
      Header: "환불/취소날짜",
      accessor: "end_confirm_date",
      Cell: ({ cell: { value } }) => <Date1 value={value} />,
    },
    {
      Header: "투자금액",
      accessor: "end_amount",
      Cell: ({ cell: { value } }) => <>{wonComma(numberToKorean(value))}원</>,
    },
    {
      Header: "환급금액",
      accessor: "end_amount_result",
      Cell: ({ cell: { value } }) => <>{wonComma(numberToKorean(value))}원</>,
    },
  ];
  const TableColumn2 = [
    {
      Header: "상품명",
      accessor: "pro_title",
      Cell: ({ row }) => <Title row={row} />,
    },
    {
      Header: "신청일",
      accessor: "with_date",
      Cell: ({ cell: { value } }) => <Date1 value={value} />,
    },
    {
      Header: "금액",
      accessor: "with_invest_amount",
      Cell: ({ cell: { value } }) => <>{wonComma(numberToKorean(value))}원</>,
    },
    {
      Header: "상태",
      accessor: "with_all_yn",
      Cell: ({ cell: { value } }) => (
        <>{value === "C" ? "전액취소" : "이자출금"}</>
      ),
    },
    {
      Header: "승인여부",
      accessor: "with_confirm",
      Cell: ({ cell: { value } }) => <>{value === "Y" ? "승인" : "미승인"}</>,
    },
  ];
  const Title = ({ row }) => {
    return (
      <div style={{ display: "flex", width: "130px" }}>
        <div
          style={{
            height: "40px",
            lineHeight: "40px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.original.pro_title ? row.original.pro_title : "삭제된 상품"}
        </div>
      </div>
    );
  };

  const Date1 = ({ value }) => {
    if (value) {
      return <Moment format="YYYY-MM-DD">{value}</Moment>;
    } else {
      return <>수정기록없음</>;
    }
  };

  return (
    <>
      {" "}
      <div className={styles.mypage_invest_table}>
        {type === "1" && swi && (
          <InvestCustomTable columns={TableColumn} data={data} type={type} />
        )}{" "}
        {type === "2" && swi && (
          <InvestCustomTable columns={TableColumn1} data={data} type={type} />
        )}{" "}
        {type === "3" && swi && (
          <InvestCustomTable columns={TableColumn2} data={data} type={type} />
        )}{" "}
        {type === "4" && swi && (
          <InvestCustomTable columns={TableColumn3} data={data} type={type} />
        )}
      </div>
    </>
  );
};

export default MypageInvest;
