import styles from "../../styles/admin.module.css";
import { useState, useEffect, useCallback, useRef } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../CommonAxios";
import NoticeWrite from "./detail/NoticeWriteDetail";
import NoticeViewDetail from "./detail/NoticeViewDetail";
import NoticeWriteDetail from "./detail/NoticeWriteDetail";
import Swal from "sweetalert2";
import { textReplace } from "../../lib/textReplace";
import { text } from "@fortawesome/fontawesome-svg-core";

const NoticePopupDetail = () => {
  const [options, setOptions] = useState("all");

  const [userInfo, setUserInfo] = useState([]);
  const { id } = useParams();
  const [openValue, setOpenValue] = useState("Y");
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  const imgNameRef = useRef(); //이미지 파일 이름 REF
  const [imageFile, setImageFile] = useState(""); //이미지 속성정보
  const [getimage, setGetImage] = useState(""); //render.result 값

  const [updateImg, setUpdateImg] = useState("");

  const change_text1 = useCallback((e) => {
    setText1(e.target.value);
  }, []);

  const change_text2 = useCallback((e) => {
    setText2(e.target.value);
  }, []);
  const change_text3 = useCallback((e) => {
    setText3(e.target.value);
  }, []);

  const [data, setData] = useState([]);
  const [sw, setSw] = useState(false);
  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_join_popup",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          if (result.messageinfo.message.length > 0) {
            setData(result.messageinfo.message);
            setText1(result.messageinfo.message[0].popup_strong);
            setText2(result.messageinfo.message[0].popup_step_1);
            setText3(result.messageinfo.message[0].popup_step_2);
          }

          setSw(true);
        } else {
        }
      }
    );
  }, []);
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const change_open = (data) => {
    setOpenValue(data);
  };

  const [link, setLink] = useState("");
  const change_link = useCallback((e) => {
    setLink(e.target.value);
  }, []);

  const [linkYn, setLinkYn] = useState("Y");
  const change_link_yn = (data) => {
    setLinkYn(data);
  };

  const btn_popup_reg = () => {
    // alert("개발중");

    let sendData = {
      no: 1,
      popup_strong: textReplace(text1),
      popup_step_1: textReplace(text2),
      popup_step_2: textReplace(text3),
      // popup_open: openValue,
      // popup_link: link,
      // filename: "",
      // image: "",
      // beforeImg: updateImg.split("/")[updateImg.split("/").length - 1],
      // mobile :
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_join_popup",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          Swal.fire({
            title: "수정 완료",
            confirmButtonText: "확인",
          });
        } else {
        }
      }
    );
  };
  return (
    <>
      {" "}
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          펀딩+ &nbsp;〉 &nbsp;가입 신청 안내{" "}
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.popup_write_wrap}>
            <div className={styles.popup_write_inner}>
              <div className={styles.popup_write_title}>
                <h4>가입 신청 안내 설정</h4>
                <div className={styles.popup_write_button}>
                  <button onClick={btn_popup_reg}>설정 완료</button>
                </div>
              </div>
              <div className={styles.popup_write_content}>
                <div className={styles.popup_write_left}>
                  <div className="alert_div_back admin_back">
                    <div className="alert_div_wrap2">
                      {sw && (
                        <div className="alert_div_inner">
                          <div
                            className="alert_div_content"
                            style={{ textAlign: "left" }}
                          >
                            <div
                              style={{ marginBottom: "7px", width: "90%" }}
                              dangerouslySetInnerHTML={{
                                __html: text1.replaceAll(/(\n|\r\n)/g, "<br/>"),
                              }}
                            ></div>

                            <div>
                              {" "}
                              <div
                                // style={{ marginBottom: "7px", width: "90%" }}
                                dangerouslySetInnerHTML={{
                                  __html: text2.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></div>
                              {/* <ol>
                                <li
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].popup_step_1.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></li>
                                <li
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].popup_step_2.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></li>
                                <li
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].popup_step_3.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></li>
                              </ol> */}
                            </div>
                            <div>
                              <div
                                // style={{ marginBottom: "7px", width: "90%" }}
                                dangerouslySetInnerHTML={{
                                  __html: text3.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></div>
                              {/* <span>주의사항 </span>
                              <ol>
                                <li
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].popup_step_4.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></li>
                                <li
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].popup_step_5.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></li>
                                <li
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].popup_step_6.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></li>
                              </ol>{" "} */}
                            </div>
                            <p>가입 신청을 하시겠습니까?</p>
                          </div>{" "}
                          <div className="btn_box">
                            <button className="btn_alert">확인</button>
                            <button className="btn_alert_cancel">취소</button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>{" "}
                <div className={styles.popup_write_right}>
                  <div style={{ width: "100%" }}>
                    <label style={{ fontWeight: "500", marginBottom: "5px" }}>
                      타이틀
                    </label>
                    <textarea
                      style={{
                        width: "100%",
                        resize: "none",
                        // borderRadius: "5px",
                        outline: "none",
                        fontSize: "14px",
                      }}
                      value={text1}
                      onChange={change_text1}
                    ></textarea>
                  </div>{" "}
                  <div style={{ width: "100%" }}>
                    <label style={{ fontWeight: "500", marginBottom: "5px" }}>
                      내용1
                    </label>
                    <textarea
                      style={{
                        width: "100%",
                        resize: "none",
                        // borderRadius: "5px",
                        height: "140px",
                        outline: "none",
                        fontSize: "14px",
                      }}
                      value={text2}
                      onChange={change_text2}
                    ></textarea>
                  </div>{" "}
                  <div style={{ width: "100%" }}>
                    <label style={{ fontWeight: "500", marginBottom: "5px" }}>
                      내용2
                    </label>
                    <textarea
                      style={{
                        width: "100%",
                        resize: "none",
                        // borderRadius: "5px",
                        height: "140px",
                        outline: "none",
                        fontSize: "14px",
                      }}
                      value={text3}
                      onChange={change_text3}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticePopupDetail;
