import React, { useMemo, useState, useEffect, useRef } from "react";
import notice from "../../styles/notice.module.css";
import { CommonAxios } from "../CommonAxios";

import Moment from "react-moment";
import logo from "../../assets/images/notice_default.jpg";
import { FaExclamation } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import NewsCustomTable from "./NewsCustomTable";
const NewsDetail = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    if (id === "pageOne") {
      setUlColor(2);
    } else if (id === "pageTwo") {
      setUlColor(3);
    } else {
      setUlColor(1);
    }
  }, [id]);
  useEffect(() => {
    let sendData = {
      type: id,
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_news_page",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setData(result.messageinfo.message);
        } else {
        }
      }
    );
  }, [id]);
  const TableColumn = useMemo(() => [
    {
      Header: "대표 이미지",
      accessor: "press_img",
      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            {data.value ? (
              <img src={"/press/" + oriNo + "/" + data.value} />
            ) : (
              <img src={logo} />
            )}
          </>
        );
      },
    },
    {
      Header: "제목",
      accessor: "press_title",

      Cell: ({ row }) => <Title row={row} />,
    },
    {
      Header: "출처",
      accessor: "press_agency",
      Cell: (row) => {
        let data = row.cell;
        return <>{data.value}</>;
      },
    },

    {
      Header: "게시날짜",
      accessor: "press_date",
      Cell: ({ cell: { row } }) => <Date row={row} />,
    },
  ]);
  const Date = ({ row }) => {
    if (row.original.notice_important === "Y") {
      return (
        <div className={notice.notice_date_div}>
          {" "}
          {/* <em className={notice.notice_date_em1}>
        
            중요
          </em> */}
          {/* {row.original.notice_type === 1 ? (
            <em className={notice.notice_date_em2}>공지사항</em>
          ) : (
            <em className={notice.notice_date_em2}>보도자료</em>
          )} */}
          <Moment format="YYYY-MM-DD">{row.original.press_date}</Moment>
        </div>
      );
    } else {
      return (
        <div
          className={notice.notice_date_div}
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",

            // fontWeight: "600",
            // fontSize: "14px",
            // marginRight: "4px",
          }}
        >
          {" "}
          {/* {row.original.notice_type === 1 ? (
            <em className={notice.notice_date_em2}>공지사항</em>
          ) : (
            <em className={notice.notice_date_em2}>보도자료</em>
          )} */}
          <Moment format="YYYY-MM-DD">{row.original.notice_date}</Moment>
        </div>
      );
    }
  };
  const Title = ({ row }) => {
    // let oriNo = data.row.original.no;
    return (
      <div
        style={{
          // display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <div className={notice.notice_content_title}>
          {/* {row.original.notice_important==="Y"? row.original.notice_title} */}

          <> {row.original.press_title}</>
        </div>{" "}
      </div>
    );
  };

  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState("all");
  const [ulColor, setUlColor] = useState(1);
  return (
    <>
      <div className={`common-wrap ${notice.notice_wrap_color}`}>
        <div className="common-inner-wrap">
          <div className={notice.notice_wrap}>
            <div className={notice.notice_tit}>
              {" "}
              <h2>언론보도</h2>
              {/* <ul>
                <li>
                  <span
                    onClick={() => {
                      setUlColor(1);
                    }}
                    className={
                      ulColor === 1
                        ? `${notice.notice_tit_span} ${notice.active}`
                        : `${notice.notice_tit_span}`
                    }
                  >
                    <Link to="/noticePage/pageAll">전체</Link>
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      setUlColor(2);
                    }}
                    className={
                      ulColor === 2
                        ? `${notice.notice_tit_span} ${notice.active}`
                        : `${notice.notice_tit_span}`
                    }
                  >
                    {" "}
                    <Link to="/noticePage/pageOne">공지사항</Link>
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => {
                      setUlColor(3);
                    }}
                    className={
                      ulColor === 3
                        ? `${notice.notice_tit_span} ${notice.active}`
                        : `${notice.notice_tit_span}`
                    }
                  >
                    {" "}
                    <Link to="/noticePage/pageTwo">보도자료</Link>
                  </span>
                </li>
              </ul> */}
            </div>

            <div className={notice.notice_content_wrap}>
              <NewsCustomTable columns={TableColumn} data={data} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsDetail;
