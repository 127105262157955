import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CustomMainTable from "./../CustomMainTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "./../../CommonAxios";
import Swal from "sweetalert2";
import moment from "moment";
import Moment from "react-moment";
import styles from "./../../../styles/admin.module.css";
import { numberToKorean } from "../../../lib/numberToKorean";
import CustomFarmTable from "../CustomFarmTable";
import { FcEditImage } from "react-icons/fc";
import CustomCateTable from "../CustomCateTable";
import { textOverCut, getTextTotal } from "../../../lib/stringLenghts";
import { textReplace } from "../../../lib/textReplace";
import CustomPartnerTable from "../CustomPartnerTable";
const PartnerTable = ({ setOpen, open, setOpen2, open2 }) => {
  const navigate = useNavigate();
  const viewFun = (no, name, img, openV, link) => {
    // alert("개발중");
    // return;
    setOpen(false);
    setOpen2(true);
    setNum(no);
    setName(name);
    setIm(img);
    setOpe(link);
  };
  const TableColumn = [
    {
      Header: "이미지",
      accessor: "partner_img",
      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            <div
              style={{
                cursor: "pointer",
                width: "70px",
                height: "40px",
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                borderRadius: "10px",
                overflow: "hidden",
              }}
              onClick={() =>
                viewFun(
                  data.row.original.no,
                  data.row.original.partner_name,
                  data.row.original.partner_img,
                  data.row.original.partner_open,
                  data.row.original.partner_link
                )
              }

              // onClick={() => userDetailInfo(oriNo)}
            >
              {data.value && (
                <img
                  src={"/partner/" + oriNo + "/" + data.value}
                  style={{
                    width: "70px",
                    height: "100%",
                  }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "파트너사명",
      accessor: "partner_name",
      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            <div
              onClick={() =>
                viewFun(
                  data.row.original.no,
                  data.row.original.partner_name,
                  data.row.original.partner_img,
                  data.row.original.partner_open,
                  data.row.original.partner_link
                )
              }

              // onClick={() => userDetailInfo(oriNo)}
            >
              {data.value}
            </div>
          </>
        );
      },
    },

    // {
    //   Header: "공개여부",
    //   accessor: "partner_open",
    //   Cell: ({ cell: { row } }) => <FarmProgess row={row} />,
    // },
  ];
  const Date = ({ value }) => {
    if (value) {
      return <Moment format="YYYY.MM.DD.">{value}</Moment>;
    } else {
      return <>기간 미지정</>;
    }
  };

  const FarmState = ({ value }) => {
    if (value == "Y") {
      return <>생산중</>;
    } else {
      return <>설립중</>;
    }
  };

  const FarmProgess = ({ row }) => {
    if (row.original.partner_open == "Y") {
      return "공개";
    } else {
      return "비공개";
    }
  };
  const FarmWeeks = ({ value }) => {
    if (value == 0) {
      return null;
    } else {
      return value + "주차";
    }
  };

  const NumberFormat = ({ value }) => {
    const numberStr = numberToKorean(value) + "원";

    return numberStr;
  };

  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState("all");

  // 체크 박스의 no 데이터값
  const [chkValue, setChkValue] = useState([]);

  const updateValue = (ubt) => {
    setChkValue(ubt);
  };

  useEffect(() => {}, [chkValue]);

  const linkData = (data) => {
    navigate("/admin/farm/detail/" + data);
  };

  /// 여기까지

  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_partner",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);

  const filterUser = (e) => {
    setFilterWord(e.target.value);
  };

  const CategoryReg = () => {
    const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
    const imgNameRef = useRef(); //이미지 파일 이름 REF
    const [imageFile, setImageFile] = useState(""); //이미지 속성정보
    const [getimage, setGetImage] = useState(""); //render.result 값
    const inpChange = (e) => {
      const file = e.target.files;
      const imgExtension = file[0].name.split(".");
      const maxSize = 1024 * 500;

      if (file[0] === undefined) {
        return;
      }

      // if (
      //   imgExtension[1] !== "jpg" ||
      //   imgExtension[1] !== "png" ||
      //   imgExtension[1] !== "jpeg" ||
      //   imgExtension[1] !== "gif"
      // ) {
      //   Swal.fire({
      //     title: "이미지 업로드 불가",
      //     text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",
      //     icon: "error",
      //     confirmButtonText: "확인",
      //     confirmButtonColor: "#FF0000",
      //   });
      //   return;
      // }
      if (file[0].size >= maxSize) {
        //이미지 사이즈가 500k보다 크다면,
        setImageFile(imageFile);
        Swal.fire({
          title: "이미지 용량 초과",
          html: `5KB 사진을 올려주세요.`,

          confirmButtonText: "확인",
        });
        return;
      } else {
        setImageFile(file);
      }
    };

    useEffect(() => {
      preview(); // 미리보기
    });

    const preview = () => {
      if (!imageFile) return; //이미지 정보가 없다면 리턴

      const reader = new FileReader();

      reader.onload = () => {
        imageRef.current.src = reader.result;
        setGetImage(reader.result);
      };
      reader.readAsDataURL(imageFile[0]);
    };

    const [tit, setTitl] = useState("");
    const [titleByte, setTitleByte] = useState(0);
    const [openValue, setOpenValue] = useState("");
    const changeTitle = useCallback((e) => {
      let byte = getTextTotal(e.target.value, 10);
      let text = textOverCut(e.target.value, 10);

      setTitl(text);
      setTitleByte(byte.byte);
    }, []);

    const [lin, setLin] = useState("");
    const changeLink = useCallback((e) => {
      setLin(e.target.value);
    }, []);
    const onOpen = (data) => {
      setOpenValue(data);
    };

    const reg = () => {
      if (!tit || !imageFile || !lin) {
        return Swal.fire({
          title: "빈 칸을 채워주세요.",

          confirmButtonText: "확인",
        });
      }
      let sendData = {
        partner_name: textReplace(tit),
        partner_link: lin,
        // partner_kind: 1,
        filename: "",
        image: "",
        exp: "",
      };

      if (imageFile !== "") {
        var file_info = imageFile[0].name.split(".");
        var file_name = `${Math.floor(Math.random() * 100000)}.${file_info[1]}`;
        sendData.filename = file_name;
        sendData.exp = file_info[1];
        sendData.image = `${getimage}file_name:${file_name}file_name:file_name:/userProfile/`;
      }
      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/admin_insert_partner",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
            let sendData = {};
            CommonAxios(
              process.env.REACT_APP_HOSTDONAME + "/api/admin_select_partner",
              sendData,
              function (result) {
                if (result.messageinfo.state == "ok") {
                  setArrayList(result.messageinfo.message);
                  setOpen(false);
                  Swal.fire({
                    title: "등록 완료.",

                    confirmButtonText: "확인",
                  });
                } else {
                }
              }
            );
          } else {
          }
        }
      );
    };

    return (
      <>
        <div className={styles.partner_inp_wrap}>
          {" "}
          <div className={styles.partner_inp_box}>
            <div className={styles.partner_inp_title}>
              <h4>파트너사 등록</h4>
              <div>
                <button onClick={() => reg()}>등록</button>
                <button onClick={() => setOpen(false)}>취소</button>
              </div>
            </div>
            <div className={styles.partner_inp_inner}>
              <div>
                {/* <label>카테고리명 </label> */}
                <div className={styles.partner_img}>
                  {imageFile === "" ? (
                    <div>
                      <span className={styles.image_span}>Image</span>
                    </div>
                  ) : (
                    <img src="" ref={imageRef} />
                  )}
                </div>{" "}
                <label className={styles.partner_file}>
                  <input
                    type="file"
                    className={styles.partner_file_inp}
                    accept="image/*"
                    onChange={inpChange}
                  />
                  이미지 업로드
                </label>
              </div>
              <div className={`${styles.partner_inpbox_div}`}>
                <label>파트너사명 : </label>{" "}
                <div className={`${styles.partner_write_inpbox}`}>
                  <input type="text" onChange={changeTitle} value={tit} />{" "}
                  <span>{titleByte}/10</span>{" "}
                </div>
              </div>
              <div className={`${styles.partner_inpbox_div}`}>
                <label>링크 : </label>{" "}
                <div className={`${styles.partner_write_inpbox}`}>
                  <input type="text" onChange={changeLink} value={lin} />{" "}
                  {/* <span>{titleByte}/8</span>{" "} */}
                </div>
              </div>
            </div>
          </div>
          <span>*파트너사 사이즈는 162(px) x 79(px)입니다.</span>
        </div>
      </>
    );
  };
  useEffect(() => {
    if (arrayList.length > 0) {
      if (options == "all") {
        // if (filterWord == null) {
        setSendList(arrayList);
        // } else {
        //   let list = arrayList.filter(
        //     (a) => a.partner_name.includes(filterWord) == true
        //   );
        //   setSendList(list);
        // }
      } else if (options == "normal") {
        const norList = arrayList.filter((a) => a.partner_kind == 1);

        setSendList(norList);
      } else if (options == "develop") {
        const devList = arrayList.filter((a) => a.partner_kind == 2);

        setSendList(devList);
      }
    }
  }, [arrayList, filterWord, options]);

  // const [open2, setOpen2] = useState(false);
  const [num, setNum] = useState("");
  const [name2, setName] = useState("");
  const [im, setIm] = useState("");
  const [ope, setOpe] = useState("");
  const CategoryUpdate = ({ num, name2, im, ope }) => {
    const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
    const imgNameRef = useRef(); //이미지 파일 이름 REF
    const [imageFile, setImageFile] = useState(""); //이미지 속성정보
    const [getimage, setGetImage] = useState(""); //render.result 값

    useEffect(() => {
      let byte = getTextTotal(name2, 10);
      let text = textOverCut(name2, 10);

      setTitleByte(byte.byte);

      setTitl(text);
      setUpdateImg(im);
      setOpenValue("Y");
      setLin(ope);
    }, []);
    const inpChange = (e) => {
      const file = e.target.files;
      const imgExtension = file[0].name.split(".");
      const maxSize = 1024 * 500;

      if (file[0] === undefined) {
        return;
      }

      // if (
      //   imgExtension[1] !== "jpg" ||
      //   imgExtension[1] !== "png" ||
      //   imgExtension[1] !== "jpeg" ||
      //   imgExtension[1] !== "gif"
      // ) {
      //   Swal.fire({
      //     title: "이미지 업로드 불가",
      //     text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",
      //     icon: "error",
      //     confirmButtonText: "확인",
      //     confirmButtonColor: "#FF0000",
      //   });
      //   return;
      // }
      if (file[0].size >= maxSize) {
        //이미지 사이즈가 500k보다 크다면,
        setImageFile(imageFile);
        Swal.fire({
          title: "이미지 용량 초과",
          html: `5KB 사진을 올려주세요.`,

          confirmButtonText: "확인",
        });
        return;
      } else {
        setImageFile(file);
      }
    };

    useEffect(() => {
      preview(); // 미리보기
    });

    const preview = () => {
      if (!imageFile) return; //이미지 정보가 없다면 리턴

      const reader = new FileReader();

      reader.onload = () => {
        imageRef.current.src = reader.result;
        setGetImage(reader.result);
      };
      reader.readAsDataURL(imageFile[0]);
    };

    const [tit, setTitl] = useState("");
    const [titleByte, setTitleByte] = useState(0);
    const [updateImg, setUpdateImg] = useState("");
    const [openValue, setOpenValue] = useState("");
    const changeTitle = useCallback((e) => {
      let byte = getTextTotal(e.target.value, 10);
      let text = textOverCut(e.target.value, 10);

      setTitl(text);
      setTitleByte(byte.byte);
    }, []);
    const [lin, setLin] = useState("");
    const changeLink = useCallback((e) => {
      setLin(e.target.value);
    }, []);
    const onOpen = (data) => {
      setOpenValue(data);
    };

    const btn_story_reg = () => {
      let sendData = {
        no: num,
        partner_name: textReplace(tit),
        partner_link: lin,

        // partner_open: openValue,

        filename: "",
        image: "",
        beforeImg: updateImg,
        // mobile :
      };

      if (imageFile !== "") {
        var file_info = imageFile[0].name.split(".");
        var file_name = `${Math.floor(Math.random() * 100000)}.${file_info[1]}`;
        sendData.filename = file_name;
        sendData.image = `${getimage}file_name:${file_name}file_name:file_name:/userProfile/`;
      }

      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/admin_update_partner",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
            Swal.fire({
              title: "수정 완료",
              confirmButtonText: "확인",
            }).then((result) => {
              if (result.isConfirmed) {
                let sendData = {};
                CommonAxios(
                  process.env.REACT_APP_HOSTDONAME +
                    "/api/admin_select_partner",
                  sendData,
                  function (result) {
                    if (result.messageinfo.state == "ok") {
                      setArrayList(result.messageinfo.message);
                      setOpen2(false);
                    } else {
                    }
                  }
                );
              }
            });
          } else {
          }
        }
      );
    };

    return (
      <>
        <div className={styles.partner_inp_wrap}>
          {" "}
          <div className={styles.partner_inp_box}>
            <div className={styles.partner_inp_title}>
              <h4>파트너 수정</h4>
              <div>
                <button onClick={() => btn_story_reg()}>수정</button>
                <button onClick={() => setOpen2(false)}>취소</button>
              </div>
            </div>
            <div className={styles.partner_inp_inner}>
              <div>
                {/* <label>카테고리명 </label> */}
                <div className={styles.partner_img}>
                  {updateImg === "" ? (
                    !imageFile ? (
                      <div>
                        <span className={styles.image_span}>Image</span>
                      </div>
                    ) : (
                      <img src="" ref={imageRef} />
                    )
                  ) : (
                    <img src={`/partner/${num}/` + updateImg} ref={imageRef} />
                  )}
                </div>{" "}
                <label className={styles.partner_file}>
                  <input
                    type="file"
                    className={styles.partner_file_inp}
                    accept="image/*"
                    onChange={inpChange}
                  />
                  이미지 업로드
                </label>
              </div>
              <div className={`${styles.partner_inpbox_div}`}>
                <label>파트너사명 : </label>{" "}
                <div className={`${styles.partner_write_inpbox}`}>
                  <input type="text" onChange={changeTitle} value={tit} />{" "}
                  <span>{titleByte}/10</span>{" "}
                </div>
              </div>
              <div className={`${styles.partner_inpbox_div}`}>
                <label>링크 : </label>{" "}
                <div className={`${styles.partner_write_inpbox}`}>
                  <input type="text" onChange={changeLink} value={lin} />{" "}
                  {/* <span>{titleByte}/8</span>{" "} */}
                </div>
              </div>
              {/* <div>
                <label>공개여부 : </label>
                <label>
                  <input
                    type="radio"
                    name="o"
                    onChange={() => onOpen("Y")}
                    value="Y"
                    checked={openValue === "Y" ? true : false}
                  />
                  공개
                </label>
                <label>
                  <input
                    type="radio"
                    name="o"
                    onChange={() => onOpen("N")}
                    value="N"
                    checked={openValue === "N" ? true : false}
                  />
                  비공개
                </label>
              </div> */}
            </div>
          </div>
          <span>*카테고리 테두리 사이즈는 70(px) x 40(px)입니다.</span>
        </div>
      </>
    );
  };
  return (
    <>
      {open && <CategoryReg />}
      {open2 && <CategoryUpdate num={num} name2={name2} im={im} ope={ope} />}

      <CustomPartnerTable
        columns={TableColumn}
        data={sendList}
        options={options}
        setOptions={setOptions}
        arrayList={arrayList}
        setArrayList={setArrayList}
        setSendList={setSendList}
        linkData={linkData}
        setOpen={setOpen}
        type={1}
      />
    </>
  );
};

export default PartnerTable;
