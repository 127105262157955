import React, { useState, useEffect, useRef } from "react";

import GoodsWriteField from "../components/admin/GoodsWriteField";
import FarmWriteField from "../components/admin/FarmWriteField";

const FarmWritePageField = () => {
  return (
    <>
      <FarmWriteField />
    </>
  );
};

export default FarmWritePageField;
