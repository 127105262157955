import styles from "../../../styles/admin.module.css";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import pro from "../../../assets/images/profile_info.png";
import Moment from "react-moment";
import { numberToKorean } from "../../../lib/numberToKorean";
import Swal from "sweetalert2";
import { BsCheck } from "react-icons/bs";
import moment from "moment";
import { wonComma } from "../../../lib/wonComma";
const AdminIngTwoDetail = ({ data, setModal, setStep1 }) => {
  const navigate = useNavigate();
  const apply = () => {
    if (data.invest_confirm === "Y") {
      return Swal.fire({
        title: "승인 불가",
        html: `이미 승인하셨습니다.`,

        confirmButtonText: "확인",
      });
    }
    Swal.fire({
      title: `주의`,
      html: `상품 가입 승인 후 당일 날 수익률을 변경하지마세요.`,

      confirmButtonText: "확인",
    }).then((result) => {
      let time = "";
      let amount = "";
      Swal.fire({
        title: "승인절차",
        showCancelButton: true,
        html: `
          
       
          
            <label>입금시간</label>
           <input
           type="text"
           placeholder="ex) 0000-00-00 00:00:00"
         
        
             class="swal2-input"
             id="num-value11">
             <label>입금금액</label>
            <input
            type="text"
            placeholder="숫자만 입력하세요."
            
         
              class="swal2-input"
              id="num-value21"> 
              `,
        time,
        amount,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        preConfirm: (data) => {
          const inputNames =
            Swal.getHtmlContainer().querySelector("#num-value11");
          const inputNames1 =
            Swal.getHtmlContainer().querySelector("#num-value21");

          if (
            !RegExp(
              /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]5[0-9]):(0[0-9]|1[0-9]|2[0-9]|3[0-9]|4[0-9]5[0-9])$/
            ).test(inputNames.value)
          ) {
            Swal.showValidationMessage(`날짜 형식이 맞지않습니다.`);
          } else {
            time = inputNames.value;
          }

          if (!RegExp(/[0-9]/).test(inputNames1.value)) {
            Swal.showValidationMessage(`입금 금액은 숫자만 입력해주세요.`);
          } else {
            amount = inputNames1.value;
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "승인하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
          }).then((result) => {
            if (result.isConfirmed) {
              const getDateDiff = (d1, d2) => {
                const date1 = new Date(d1);
                const date2 = new Date(d2);

                const diffDate = date1.getTime() - date2.getTime();

                return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
              };

              let tomorrow = new Date(
                new Date().setDate(new Date().getDate() + 1)
              );
              let tomorrowex = moment(
                new Date().setDate(new Date().getDate() + 1)
              ).format("YYYY-MM-DD 00:00:00");

              let endDate = moment(
                tomorrow
                  .toLocaleDateString()
                  .replace(/\./g, "")
                  .replace(/\s/g, "-")
              )
                .add(data.pro_join, "months")
                .format("YYYY-MM-DD 00:00:00");

              let allDay = getDateDiff(tomorrowex, endDate);

              const number =
                (data.invest_amount * (data.pro_rate_change / 100)) /
                parseInt(allDay);

              let toDayInterest = "";
              if (!Number.isInteger(number)) {
                const int = number.toString().split(".")[0];
                const twoDecimal = number.toString().split(".")[1].slice(0, 3);
                toDayInterest = `${int}.${twoDecimal}`;
              } else {
                toDayInterest = number;
              }

              // let toDayInterest = (
              //   (data[0].invest_amount * (data[0].invest_rate / 100)) /
              //   parseInt(allDay)
              // ).toFixed(3);

              let sendData = {
                user_id: data.invest_user_id,
                product_id: data.invest_product_no,
                invest_id: data.invest_no,
                toDayInterest: toDayInterest,
                start: tomorrowex,
                end: endDate,
                day: allDay,
                amount: data.invest_amount,
                upDown: data.invest_rate >= 0 ? "U" : "D",
                timer: time,
                rate: data.invest_rate,
              };
              // let sendData = {
              //   no: id,
              // };

              CommonAxios(
                process.env.REACT_APP_HOSTDONAME +
                  "/api/admin_invest_comfirm_insert",
                sendData,
                function (result) {
                  if (result.messageinfo.state === "ok") {
                    Swal.fire({
                      title: "승인 완료",

                      confirmButtonText: "확인",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigate("/admin/deposit");
                      }
                    });
                  } else {
                  }
                }
              );
            }
          });
        }
      });
    });
    // setModal(true);
    // setStep1(true);
  };

  const cancel = () => {
    if (data.invest_confirm === "N") {
      Swal.fire({
        title: "승인 취소",
        html: `아직 승인을 하지 않았습니다.`,

        confirmButtonText: "확인",
      });
    } else {
      let date = new Date(data.invest_confirm_date);

      const diffDate = new Date().getTime() - date.getTime();

      if (Math.abs(diffDate / (1000 * 60 * 60 * 24)) > 3) {
        return Swal.fire({
          title: "승인 취소 불가",
          html: `아래 주의사항 참고바람`,

          confirmButtonText: "확인",
        });
      }
      Swal.fire({
        title: "승인 취소",
        html: `승인을 취소하시겠습니까?`,
        showCancelButton: true,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      }).then((result) => {
        if (result.isConfirmed) {
          let sendData = {
            invest_id: data.invest_no,
          };
          // let sendData = {
          //   no: id,
          // };

          CommonAxios(
            process.env.REACT_APP_HOSTDONAME +
              "/api/admin_invest_comfirm_insert",
            sendData,
            function (result) {
              if (result.messageinfo.state === "ok") {
                Swal.fire({
                  title: "승인 완료",

                  confirmButtonText: "확인",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/admin/deposit");
                  }
                });
              } else {
              }
            }
          );
        }
      });
    }
  };
  return (
    <>
      <div className={styles.invest_wrap}>
        <div className={styles.invest_deposit_section}>
          <div className={styles.invest_deposit_box}>
            <div className={styles.invest_deposit_item}>
              <div className={styles.invest_deposit_title}>
                <h2>사용자 투자 정보</h2>
              </div>
              <div className={styles.invest_deposit_info_box}>
                <div className={styles.invest_deposit_top}>
                  <div className={styles.invest_deposit_top_1}>
                    {" "}
                    <div className={styles.deposit_top}>
                      <label>이름</label>
                      <span>{data.invest_user_name}</span>
                    </div>
                    <div className={styles.deposit_top}>
                      {" "}
                      <label>이메일</label>{" "}
                      <span>{data.invest_user_email}</span>
                    </div>
                    <div className={styles.deposit_top}>
                      {" "}
                      <label>휴대폰 번호</label>
                      <span>{data.user_mobile}</span>
                    </div>
                  </div>{" "}
                  <div className={styles.invest_deposit_top_2}>
                    {" "}
                    <div className={styles.deposit_top}>
                      {" "}
                      <label>연령대</label>
                      <span>{data.user_age}대</span>
                    </div>{" "}
                    <div className={styles.deposit_top}>
                      {" "}
                      <label>지역</label>
                      <span>{data.user_place}</span>
                    </div>{" "}
                    <div className={styles.deposit_top}>
                      {" "}
                      <label>성별</label>
                      <span>{data.user_gender === 1 ? "남" : "여"}</span>
                    </div>
                  </div>{" "}
                </div>
                <div className={styles.invest_deposit_bottom}>
                  <div className={styles.invest_deposit_bottom_1}>
                    {" "}
                    <div className={styles.deposit_bottom}>
                      <label>투자금액</label>
                      <span>
                        {wonComma(numberToKorean(data.invest_amount))}원
                      </span>
                    </div>
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label>수익률(투자신청기준)</label>{" "}
                      <span>{data.invest_rate}%</span>
                    </div>
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label>이자(투자신청기준)</label>
                      <span>
                        {wonComma(
                          numberToKorean(
                            data.invest_amount * (data.invest_rate / 100)
                          )
                        )}
                        원
                      </span>
                    </div>
                  </div>{" "}
                  <div className={styles.invest_deposit_bottom_2}>
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label>투자신청날짜</label>
                      <span>
                        {" "}
                        {data.invest_date ? (
                          <Moment format="YYYY-MM-DD HH:mm:ss">
                            {data.invest_date}
                          </Moment>
                        ) : (
                          "-"
                        )}
                      </span>
                    </div>{" "}
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label>투자승인날짜</label>
                      <span>
                        {data.invest_confirm_date ? (
                          <Moment format="YYYY-MM-DD HH:mm:ss">
                            {data.invest_confirm_date}
                          </Moment>
                        ) : (
                          "-"
                        )}
                      </span>
                    </div>{" "}
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label></label>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button>승인</button> */}
            </div>
          </div>
          <div className={styles.deposit_warring}>
            <h4>주의사항</h4>
            <p>
              <BsCheck />
              이자 및 모집률 등은 승인 허가 당일 다음날부터 적용이 됩니다.
            </p>{" "}
            {/* <div>
              <p>
                <BsCheck />
                승인취소는 적용시작일부터 2일안으로 취소가능합니다. (전액 취소)
              </p>{" "}
              <span>
                ex) ①-27일 승인완료 ②-28일 상품 적용 ③-30일까지 취소가능
              </span>{" "}
            </div> */}
            <p>
              <BsCheck />
              수익률을 변동 다음날 변동시 적용 시작일부터 해당 수익률로 적용이
              됩니다.
            </p>{" "}
            <p>
              <BsCheck />
              상품 가입 승인 후 수익률을 변경하지마세요.
            </p>{" "}
            <div className={styles.deposit_warring_table}>
              <ul className={styles.deposit_warring_th}>
                <li>-</li> <li>27일</li> <li>28일</li>
              </ul>
              <ul className={styles.deposit_warring_td}>
                <li>수익률</li> <li>3%에서 4%로 변동 신청</li>{" "}
                <li>4%로 적용</li>
              </ul>
              <ul className={styles.deposit_warring_td}>
                <li>가입승인</li> <li>승인완료</li>{" "}
                <li>4%로 해당 회원 상품 이자적용</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminIngTwoDetail;
