import styles from "../../styles/mypage.module.css";
import { FaUserCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { FcCloseUpMode } from "react-icons/fc";
import { BsCameraFill } from "react-icons/bs";
import pro from "../../assets/images/profile_info.png";
import { useEffect } from "react";
import { textOverCut, getTextTotal } from "../../lib/stringLenghts";
import { useRef, useState, useCallback } from "react";
import { SuccessAlert, MoveAlert, WarringAlert } from "../alert/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { getUsers } from "../../modules/user";
import MobileModalConfirm from "./detail/MobileModalConfirm";
import Swal from "sweetalert2";
import DaumPostcode from "react-daum-postcode";
import Modal from "react-modal";
const MypageProfileDetail = () => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const dispatch = useDispatch();
  const stringNick = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|@#$%^&*!~_+]+$/; //한글 영문 정규식
  const stringName = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9]+$/; //한글 영문 정규식

  // const [changeNameColor, setChangeNameColor] = useState(true); //이름/닉네임 오류 메세지
  // const [changeNameColor, setChangeNameColor] = useState(true); //이름/닉네임 오류 메세지
  //이름
  const [nameValue, setNameValue] = useState(""); //이름
  const [nameByte, setNameByte] = useState(0);
  const [changeNameColor, setChangeNameColor] = useState(true); //이름/닉네임 오류 메세지
  // 닉네임
  const [nickValue, setNickValue] = useState(""); //이름
  const [nickByte, setNickByte] = useState(0);
  const [changeNickColor, setChangeNickColor] = useState(true); //이름/닉네임 오류 메세지
  const [nickDub, setNickDub] = useState(false); // 중복 메세지
  const [pageSw, setPageSw] = useState(false);

  const [mobileValue, setMobileValue] = useState("");
  const [warringAlert, setWarringAlert] = useState(false);
  const [warringCont, setwarringCont] = useState("");
  const [moveAlert, setMoveAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [successCont, setsuccessCont] = useState("");

  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  const imgNameRef = useRef(); //이미지 파일 이름 REF
  const [imageFile, setImageFile] = useState(""); //이미지 속성정보
  const [getimage, setGetImage] = useState(""); //render.result 값

  const [gender, setGender] = useState(0);
  const [age, setAge] = useState("");
  const [place, setPlace] = useState("");

  const [phoneModal, setPhoneModal] = useState(false);
  useEffect(() => {
    if (user) {
      setNameValue(user.ur_name);
      let byte = getTextTotal(user.ur_name, 20);
      setNameByte(byte.byte);

      setNickValue(user.ur_nick);
      let byte1 = getTextTotal(user.ur_nick, 24);
      setNickByte(byte1.byte);

      setMobileValue(user.ur_mobile);
      let sendData = {
        no: user.no,
      };
      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/mypage_select_user_info",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
            setGender(result.messageinfo.message[0].user_gender);
            setAge(result.messageinfo.message[0].user_age);
            setPlace(result.messageinfo.message[0].user_place);
            setCode(result.messageinfo.message[0].user_address_number);
            setRoad(result.messageinfo.message[0].user_address_road);
            setAddressDetail(result.messageinfo.message[0].user_address_detail);

            let byte2 = getTextTotal(
              result.messageinfo.message[0].user_acName,
              20
            );
            setAccountNameByte(byte2.byte);

            setAccount(result.messageinfo.message[0].user_account);

            let byte3 = getTextTotal(
              result.messageinfo.message[0].user_acBank,
              20
            );
            setAccountBankByte(byte3.byte);

            setAccountName(result.messageinfo.message[0].user_acName);
            setAccountBank(result.messageinfo.message[0].user_acBank);
            setPageSw(true);
          }
        }
      );
    }
  }, [user]);

  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 500;

    if (file[0] === undefined) {
      return;
    }

    // if (
    //   imgExtension[1] !== "jpg" ||
    //   imgExtension[1] !== "png" ||
    //   imgExtension[1] !== "jpeg" ||
    //   imgExtension[1] !== "gif"
    // ) {
    //   Swal.fire({
    //     title: "이미지 업로드 불가",
    //     text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",
    //     icon: "error",
    //     confirmButtonText: "확인",
    //     confirmButtonColor: "#FF0000",
    //   });
    //   return;
    // }
    // setImageFile(file);

    if (
      imgExtension[1] !== "jpg" &&
      imgExtension[1] !== "png" &&
      imgExtension[1] !== "jpeg" &&
      imgExtension[1] !== "gif"
    ) {
      return Swal.fire({
        title: "이미지 업로드 불가",
        text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
        confirmButtonColor: "#FF0000",
      });
    }
    if (file[0].size >= maxSize) {
      //이미지 사이즈가 500k보다 크다면,
      setImageFile(imageFile);
      Swal.fire({
        title: "이미지 용량 초과",
        text: "다른 이미지를 업로드해주세요 500kb 이하로 업로드해주세요.",

        confirmButtonText: "확인",
        confirmButtonColor: "#FF0000",
      });
      return;
    } else {
      setImageFile(file);
    }
  };

  useEffect(() => {
    preview(); // 미리보기
  });

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      imageRef.current.src = reader.result;
      setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };

  const onChangeName = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 20);
    let text = textOverCut(e.target.value, 20);

    setNameValue(text);
    setNameByte(byte.byte);
  }, []);

  const nameBlur = () => {
    if (!stringName.test(nameValue)) {
      setChangeNameColor(false);
    } else {
      setChangeNameColor(true);
    }
  };

  const onChangeNick = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 20);
    let text = textOverCut(e.target.value, 20);

    setNickValue(text);
    setNickByte(byte.byte);
  }, []);

  const [accountName, setAccountName] = useState("");
  const [accountNameByte, setAccountNameByte] = useState(0);
  const onChangeAcName = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 20);
    let text = textOverCut(e.target.value, 20);

    setAccountName(text);
    setAccountNameByte(byte.byte);
  }, []);

  const [accountBank, setAccountBank] = useState("");
  const [accountBankByte, setAccountBankByte] = useState(0);
  const onChangeBank = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 20);
    let text = textOverCut(e.target.value, 20);

    setAccountBank(text);
    setAccountBankByte(byte.byte);
  }, []);

  const [account, setAccount] = useState("");

  const [acString, setAcString] = useState("");
  let string = "";
  const accountFun = (data) => {
    // let string = "";
    let beforeSt = "";

    if (/[0-9]/g.test(data[data.length - 1])) {
      string = data;

      return string;
    } else {
      return string;
    }
    return;
  };
  const onChangeAccount = useCallback((e) => {
    let a = accountFun(e.target.value);
    // console.log(a);
    setAccount(a);
  }, []);

  const nickBlur = () => {
    if (!stringNick.test(nickValue)) {
      setChangeNickColor(false);
    } else {
      setChangeNickColor(true);
    }
  };
  // 중복
  // const btn_duplication = () => {
  //   if (nickValue) {
  //     let sendData = {
  //       nick: nickValue,
  //       no: user.no,
  //     };
  //     CommonAxios(
  //       process.env.REACT_APP_HOSTDONAME + "/api/nick_duplication",
  //       sendData,
  //       function (result) {
  //         if (result.messageinfo.state === "ok") {
  //           if (result.messageinfo.message === "중복") {
  //             setwarringCont("중복된 닉네임입니다.");
  //             setWarringAlert(true);
  //             setNickDub(true);
  //           } else {
  //             setsuccessCont("사용 가능");
  //             setSuccessAlert(true);
  //             setNickDub(false);
  //           }
  //         } else {
  //         }
  //       }
  //     );
  //   } else {
  //     setwarringCont("변경할 닉네임을 입력하세요.");
  //     setWarringAlert(true);
  //   }
  // };

  const btn_save = () => {
    if (gender === 0 || !age || !place) {
      setWarringAlert(true);
      setwarringCont("성별/연령/지역을 선택해주세요.");
      return;
    }
    if (!account || !accountName || !accountBank) {
      setWarringAlert(true);
      setwarringCont("계좌 정보를 입력해주세요.");
      return;
    }
    // if (!/[0-9,\-]{3,6}\-[0-9,\-]{2,6}\-[0-9,\-]/g.test(account)) {
    //   setWarringAlert(true);
    //   setwarringCont("계좌번호가 올바르지 않습니다.");
    //   return;
    // }

    if (!changeNameColor) {
      return;
    }
    // if (user.ur_nick !== nickValue) {
    //   if (!nickDub) {
    //     setWarringAlert(true);
    //     setwarringCont("닉네임 중복확인해주세요.");
    //     return;
    //   }
    // }

    let sendData = {
      no: user.no,
      name: nameValue ? nameValue : user.ur_name,
      // nick: nickValue ? nickValue : user.ur_nick,
      gender: gender,
      age: age,
      place: place,
      mobile: mobileValue ? mobileValue : user.ur_mobile,
      filename: "",
      image: "",
      acName: accountName,
      acBank: accountBank,
      account: account,
      code: code,
      road: road,
      detail: addressDetail,
    };

    if (imageFile !== "") {
      var file_info = imageFile[0].name.split(".");
      var file_name = `${user.no}_profile.${file_info[1]}`;
      sendData.filename = file_name;
      sendData.image = `${getimage}file_name:${file_name}file_name:file_name:/userProfile/`;
    }
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/update_user",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          dispatch(getUsers(result.messageinfo.message));
          setMoveAlert(true);
        } else {
        }
      }
    );
  };
  const change_gender = (data) => {
    setGender(data);
  };
  const change_age = (e) => {
    setAge(e.target.value);
  };
  const change_place = (e) => {
    setPlace(e.target.value);
  };

  const [inpMobile, setInpMobile] = useState(""); //휴대전화 값
  const [btnState, setBtnState] = useState(false); //인증 요청 버튼 상태
  const [mobileView, setMobileView] = useState(false); //인증번호
  const [oneError, setOneError] = useState(true); //유효하지 않는 번호 (인증 요청 눌렀을 시)
  const [twoError, setTwoError] = useState(true); //인증 요청을 안하고 저장 눌렀을 시
  const [threeError, setThreeError] = useState(true); //인층 확인 안하고 저장
  const [mobileGetNum, setMobileGetNum] = useState(""); //핸드폰 인증 번호
  const [mobileConfirmTest, setMobileConfirmTest] = useState(null); // 인증번호 일치 여부
  const [mobileBtn, setMobileBtn] = useState(true); //인증 요청 버튼 style
  const time = useRef(180);
  const [mobileConfirmValue, setMobileConfirmValue] = useState(""); //인증 요청 input 값
  const [inputDisable, setInputDisable] = useState(false);
  const [min, setMin] = useState(3);
  const [sec, setSec] = useState(0);

  const [timeSwitch, setTimeSwitch] = useState(false);
  const timerId = useRef(null);
  const resetEvent = () => {
    // setModalView(false);
    setMobileView(null);
    setInpMobile("");
    setOneError(true);
    setTwoError(true);
    setBtnState(false);
    setMobileConfirmTest(null);
    setThreeError(true);
    setInputDisable(false);
    setMobileBtn(false);
    setPhoneModal(false);
    setMin(3);
    setSec(0);
    setMobileConfirmValue("");
    setTimeSwitch(false);
  };
  useEffect(() => {
    if (mobileView) {
      setTimeSwitch(false);
      timerId.current = setInterval(() => {
        setMin(parseInt(time.current / 60));
        setSec(time.current % 60);

        time.current -= 1;
      }, 1000);
      return () => clearInterval(timerId.current);
    }
  }, [mobileView, timeSwitch]);

  useEffect(() => {
    if (time.current < 0) {
      clearInterval(timerId.current);
      setMin(0);
      setSec(0);
      // setMobileGetNum("");
      // props.setMobileView(false);
    }
  }, [sec, time.current]);

  const mobileChange = useCallback((e) => {
    // if (!/^[0-9]+$/.test(e.target.value)) return;
    let value = e.target.value;
    if (value !== "") {
      setOneError(true);
      setTwoError(true);
      setMobileBtn(false);
    } else {
      setMobileBtn(true);
    }
    // console.log(value);
    setInpMobile(value);
  }, []);

  useEffect(() => {
    if (mobileBtn === true) {
      // 인증 요청 BTN 시 9초 뒤 재요청 가능
      setTimeout(() => setMobileBtn(false), 10000);
    }
  }, [mobileBtn]);

  const sendSms = (e) => {
    e.preventDefault();
    if (
      inpMobile === "" ||
      !/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(inpMobile)
    ) {
      setOneError(false);
      setTwoError(true);
      return;
    } // e.preventDefault();
    time.current = 180;
    setMin(3);
    setSec(0);
    setMobileConfirmTest(null);
    setBtnState(true);
    setMobileBtn(true);
    setTimeSwitch(true);
    setMobileView(true);
    setMobileBtn(true);
    setTwoError(true);
    setOneError(true);

    var sendData = {
      mobileNum: inpMobile,
    };
    // setMobileGetNum("123456");
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/send_sms",
      sendData,
      function (result) {
        // console.log(result);
        if (result.messageinfo.state == "ok") {
          setMobileGetNum(result.messageinfo.message);
        } else {
        }
      }
    );
  };

  const mobileSave = () => {
    if (inpMobile === "") {
      setTwoError(false);
      setOneError(true);
      return;
    }
    if (!mobileView) {
      setTwoError(false);
      return;
      // setTwoError(false)
    }
    if (mobileConfirmValue === "") {
      setThreeError(false);
      setMobileConfirmTest(null);
      return;
    }

    if (!threeError) {
      return;
    }
    if (!mobileConfirmTest || mobileConfirmTest === null) {
      return;
    }

    // if()

    setMobileValue(
      inpMobile.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
    );
    // console.log(mobileConfirmValue);
    // return;
    // inpMobileRef.current.value = inpMobile.replace(
    //   /^(\d{2,3})(\d{3,4})(\d{4})$/,
    //   `$1-$2-$3`
    // );
    resetEvent();
  };

  const mobileConfirm = () => {
    // if (props.mobileGetNum !== "") {
    if (mobileConfirmValue == mobileGetNum) {
      setMobileConfirmTest(true);
      setThreeError(true);
      setInputDisable(true);
      setMobileBtn(true);
      clearInterval(timerId.current);

      //   console.log("성공");
    } else {
      setMobileConfirmTest(false);
      setThreeError(true);
    }
    // }
  };
  const onChangeMobileConfirm = useCallback((e) => {
    setMobileConfirmValue(e.target.value);
    // console.log(e.target.value);
  }, []);

  const [visible, setVisible] = useState(false); // 우편번호 컴포넌트의 노출여부 상태
  const [isOpen, setIsOpen] = useState(false); //추가
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: "1000",
    },
    content: {
      left: "0",
      margin: "auto",
      width: "500px",
      height: "400px",
      padding: "0",
      overflow: "",
      // overflow: "hidden",
    },
  };
  const [code, setCode] = useState("");
  const [road, setRoad] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    // if (data.addressType === "R") {
    //   if (data.bname !== "") {
    //     extraAddress += data.bname;
    //   }
    //   if (data.buildingName !== "") {
    //     extraAddress +=
    //       extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
    //   }
    //   fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    // }
    // setWriteInfo({ ...writeInfo, address: fullAddress });
    setIsOpen(false);
    setCode(data.zonecode);
    setRoad(data.address);
  };

  const change_detail = useCallback((e) => {
    setAddressDetail(e.target.value);
  }, []);

  const movePageWhere = () => {
    if (sessionStorage.getItem("savePage")) {
      let data = sessionStorage.getItem("savePage");
      sessionStorage.removeItem("savePage");
      return data;
    } else {
      return "/mypage";
    }
  };
  return (
    <>
      {warringAlert && (
        <WarringAlert content={warringCont} setWarringAlert={setWarringAlert} />
      )}

      {successAlert && (
        <SuccessAlert content={successCont} setSuccessAlert={setSuccessAlert} />
      )}
      {moveAlert && (
        <MoveAlert
          content={"프로필 편집 완료"}
          setMoveAlert={setMoveAlert}
          movePage={movePageWhere()}
        />
      )}
      <div className={`${styles.mypage_content_right} ${styles.right_profile}`}>
        {phoneModal && (
          <>
            <div className={styles.phone_modal_wrap}>
              <div className={styles.phone_modal_box}>
                <div className={styles.info_modal_content}>
                  <div
                    className={styles.info_modal_close}
                    // onClick={resetEvent}
                    onClick={resetEvent}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                  <span className={styles.info_modal_img}></span>
                  <h3>휴대폰 번호 변경</h3>
                  <div className={styles.modal_mobile_updatebox}>
                    <div className={styles.modal_step_1}>
                      <div className={styles.modal_step1_1}>
                        <input
                          type="text"
                          className={styles.inp_mobile_number}
                          placeholder="하이픈(-)없이 입력해주세요"
                          onChange={mobileChange}
                          // maxLength="11"
                          value={inpMobile}
                          disabled={inputDisable}
                        />
                        {btnState ? (
                          <BtnMobileSend
                            type="button"
                            className="btn_sendNumber"
                            onClick={sendSms}
                            mobileBtn={mobileBtn}
                            disabled={mobileBtn}
                          >
                            인증 재요청
                          </BtnMobileSend>
                        ) : (
                          <BtnMobileSend
                            type="button"
                            className="btn_sendNumber"
                            onClick={sendSms}
                            mobileBtn={mobileBtn}
                            disabled={mobileBtn}
                          >
                            인증 요청
                          </BtnMobileSend>
                        )}{" "}
                      </div>

                      {oneError ? null : (
                        <p className={styles.error_modal_p}>
                          유효하지 않는 번호입니다.
                        </p>
                      )}
                      {twoError ? null : (
                        <p className={styles.error_modal_p}>
                          휴대폰 인증을 진행하지 않았습니다.
                        </p>
                      )}
                    </div>

                    {mobileView ? (
                      <div className={styles.modal_step_2}>
                        <div className={styles.modal_step2_1}>
                          <div className={styles.modal_step2_inp}>
                            <input
                              type="text"
                              className={styles.inp_mobile_step2}
                              onChange={onChangeMobileConfirm}
                              disabled={
                                min === 0 && sec === 0
                                  ? true
                                  : mobileConfirmTest === true
                                  ? true
                                  : false
                              }
                              // onChange={mobileChange}

                              // value={inpMobile}
                              // disabled={inputDisable}
                            />
                            <span
                              className={
                                min === 0 && sec === 0
                                  ? `${styles.modal_step2_span} ${styles.step2_span_color}`
                                  : mobileConfirmTest === true
                                  ? `${styles.modal_step2_span} ${styles.step2_span_color}`
                                  : `${styles.modal_step2_span}`
                              }
                            >
                              {min}:{sec > 9 ? `${sec}` : `0${sec}`}
                            </span>
                          </div>
                          <BtnValueSend
                            min={min}
                            sec={sec}
                            mobileConfirmTest={mobileConfirmTest}
                            type="button"
                            className="btn_confirm"
                            onClick={mobileConfirm}
                            disabled={
                              min === 0 && sec === 0
                                ? true
                                : mobileConfirmTest === true
                                ? true
                                : false
                            }
                          >
                            인증 확인
                          </BtnValueSend>
                        </div>
                        {mobileConfirmTest ===
                        null ? null : mobileConfirmTest ? (
                          <p
                            className={styles.error_modal_p}
                            style={{ color: "#476cd3" }}
                          >
                            인증번호가 일치합니다.
                          </p>
                        ) : (
                          <p className={styles.error_modal_p}>
                            인증번호가 일치하지 않습니다.
                          </p>
                        )}
                        {threeError ? null : (
                          <p className={styles.error_modal_p}>
                            인증번호를 입력하지 않았습니다.
                          </p>
                        )}
                      </div>
                    ) : null}

                    {/* <div className=""></div> */}
                    <div className={styles.info_modal_btn}>
                      <button
                        type="button"
                        className={styles.btn_mobile_cencel}
                        onClick={resetEvent}
                      >
                        취소
                      </button>
                      <button
                        type="button"
                        className={styles.btn_mobile_save}
                        onClick={mobileSave}
                      >
                        저장
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {pageSw && (
          <>
            <h3 className={styles.mypage_content_title}>프로필 편집</h3>

            <div
              className={`${styles.mypage_content_inner} ${styles.profile_wrap}`}
            >
              <div className={styles.mypage_profile_wrap}>
                <div
                  className={`${styles.profile_section} ${styles.profile_section_1}`}
                >
                  <div className={styles.profile_img_div}>
                    <img
                      src={user.ur_image !== "" ? user.ur_image : pro}
                      ref={imageRef}
                    />
                  </div>
                  <label className={styles.profile_camera}>
                    <BsCameraFill />
                    <input
                      type="file"
                      // id="select_image"
                      accept="image/*"
                      onChange={inpChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
                <div
                  className={`${styles.profile_section} ${styles.profile_section_2}`}
                >
                  <label>이름</label>
                  <div className={styles.profile_section_right}>
                    <div className={styles.profile_input_div}>
                      <div className={styles.profile_inp_box}>
                        <input
                          className={styles.profile_inp}
                          onChange={onChangeName}
                          autoComplete="off"
                          value={nameValue}
                          onBlur={nameBlur}
                        />
                        <span>{nameByte}/20</span>
                      </div>
                    </div>
                    {!changeNameColor ? (
                      <em className={styles.error_em}>
                        한글/숫자/영문자만 가능합니다.(공백금지)
                      </em>
                    ) : null}
                  </div>
                </div>
                {/* <div
                  className={`${styles.profile_section} ${styles.profile_section_3}`}
                >
                  <label>닉네임</label>
                  <div className={styles.profile_section_right}>
                    <div className={styles.profile_input_div}>
                      <div className={styles.profile_inp_box}>
                        <input
                          className={styles.profile_inp}
                          onChange={onChangeNick}
                          autoComplete="off"
                          value={nickValue}
                          onBlur={nickBlur}
                        />
                        <span>{nickByte}/24</span>
                      </div>
                      <button onClick={btn_duplication}>중복확인</button>
                    </div>
                    {!changeNickColor ? (
                      <em className={styles.error_em}>
                        한글/숫자/영문자/특수문자(@#$%^&*!~_+)만
                        사용가능합니다.(공백금지)
                      </em>
                    ) : null}
                    {nickDub ? (
                      <em className={styles.error_em}>
                        이미 사용하고 있는 닉네임입니다.
                      </em>
                    ) : null}
                  </div>
                </div> */}
                <div
                  className={`${styles.profile_section} ${styles.profile_section_4}`}
                >
                  <label>휴대폰 번호</label>
                  <div className={styles.profile_section_right}>
                    <div className={styles.profile_input_div}>
                      <div className={styles.profile_inp_box}>
                        <input
                          className={styles.profile_inp}
                          disabled
                          value={mobileValue}
                        />
                      </div>
                      <button onClick={() => setPhoneModal(true)}>인증</button>
                    </div>
                    {/* <div className={styles.profile_input_div}>
                      <div className={styles.profile_inp_box}>
                        <input className={styles.profile_inp} />
                      </div>
                      <button>확인</button>
                    </div> */}
                  </div>
                </div>{" "}
                <div
                  className={`${styles.profile_section_span} ${styles.profile_phone_span}`}
                >
                  <label></label>{" "}
                  <span>*수정하시려면 인증 버튼을 누르시길 바랍니다.</span>
                </div>
                <div
                  className={`${styles.profile_section} ${styles.profile_section_7}`}
                >
                  {" "}
                  <label>주소</label>
                  <Modal
                    isOpen={isOpen}
                    ariaHideApp={false}
                    style={customStyles}
                  >
                    <div
                      className={`${styles.mypage_address_btn}`}
                      onClick={() => setIsOpen(false)}
                    >
                      닫기
                    </div>
                    <DaumPostcode onComplete={handleComplete} height="100%" />
                  </Modal>
                  {/* {visible && (
                    <div>
                      <DaumPostcode
                        onComplete={handleComplete}
                        // style={addressStyle}
                        height={700}
                      />
                    </div>
                  )} */}
                  {/* <DaumPostcode
                    style={postCodeStyle}
                    onComplete={onCompletePost}
                  />{" "} */}
                  <div className={styles.profile_section_right}>
                    {" "}
                    <div
                      className={`${styles.profile_input_address} ${styles.profile_input_address_1}`}
                    >
                      <input
                        className={styles.profile_inp}
                        value={code}
                        disabled
                        // value={mobileValue}
                      />
                      {/* <ZipCodeInput
                        // onChange={handleZipCode}
                        value={inputZipCodeValue}
                        placeholder="우편번호"
                        type={"text"}
                      ></ZipCodeInput>{" "} */}
                      <button onClick={() => setIsOpen(true)}>주소찾기</button>
                    </div>
                    <div
                      className={`${styles.profile_input_address} ${styles.profile_input_address_2}`}
                    >
                      <input
                        className={styles.profile_inp}
                        value={road}
                        disabled
                        // value={mobileValue}
                      />

                      {/* <ZipCodeInput
                        // onChange={handleZipCode}
                        value={inputZipCodeValue}
                        placeholder="우편번호"
                        type={"text"}
                      ></ZipCodeInput>{" "} */}
                    </div>{" "}
                    <div
                      className={`${styles.profile_input_address} ${styles.profile_input_address_3}`}
                    >
                      {" "}
                      <input
                        className={styles.profile_inp}
                        onChange={change_detail}
                        value={addressDetail}
                        // disabled
                        // value={mobileValue}
                      />
                      {/* <ZipCodeInput
                        // onChange={handleZipCode}
                        value={inputZipCodeValue}
                        placeholder="우편번호"
                        type={"text"}
                      ></ZipCodeInput>{" "} */}
                    </div>
                  </div>
                </div>
                <div className={`${styles.profile_section_span}`}>
                  <label></label>{" "}
                  <span>
                    *주소를 입력해 주시면 투자시 소정의 선물을 보내 드립니다{" "}
                  </span>
                </div>
                <div
                  className={`${styles.profile_section} ${styles.profile_section_5}`}
                >
                  <span className={styles.profile_info_span}>
                    투자시 필요 정보
                  </span>
                  <div className={styles.profile_info_div}>
                    <span>성별</span>
                    <div className={styles.profile_info_gender}>
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          onChange={() => change_gender(1)}
                          checked={gender === 1 ? true : false}
                        />
                        남{" "}
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="gender"
                          onChange={() => change_gender(2)}
                          checked={gender === 2 ? true : false}
                        />
                        여{" "}
                      </label>
                    </div>
                  </div>
                  <div className={styles.profile_info_div}>
                    {" "}
                    <span>연령대</span>
                    <div className={styles.profile_info_age}>
                      <select
                        onChange={change_age}
                        value={age === 0 ? "none" : age}
                      >
                        <option value="none">선택</option>
                        <option value="20">20대</option>
                        <option value="30">30대</option>
                        <option value="40">40대</option>
                        <option value="50">50대</option>
                        <option value="60">60대</option>
                        <option value="70">70대</option>
                        <option value="80">80대</option>
                      </select>
                    </div>
                  </div>
                  <div className={styles.profile_info_div}>
                    <span>지역</span>
                    <div className={styles.profile_info_place}>
                      <select
                        onChange={change_place}
                        value={place === "" ? "none" : place}
                      >
                        <option value="none">선택</option>
                        <option value="서울">서울</option>
                        <option value="인천">인천</option>
                        <option value="대구">대구</option>
                        <option value="부산">부산</option>
                        <option value="대전">대전</option>
                        <option value="울산">울산</option>
                        <option value="광주">광주</option>
                        <option value="제주도">제주도</option>
                        <option value="경기도">경기도</option>
                        <option value="강원도">강원도</option>
                        <option value="충청북도">충청북도</option>
                        <option value="충청남도">충청남도</option>
                        <option value="경상북도">경상북도</option>
                        <option value="경상남도">경상남도</option>
                        <option value="전라북도">전라북도</option>
                        <option value="전라남도">전라남도</option>
                        <option value="기타">기타</option>
                      </select>
                    </div>
                  </div>
                </div>{" "}
                <div
                  className={`${styles.profile_section} ${styles.profile_section_6}`}
                >
                  {" "}
                  <span className={styles.profile_info_span}>
                    투자시 계좌 정보
                  </span>{" "}
                  <div
                    className={`${styles.profile_account_div} ${styles.top_box}`}
                  >
                    <div className={styles.profile_account}>
                      <span>예금주</span>
                      <div className={styles.profile_account_inp}>
                        <input
                          type="text"
                          onChange={onChangeAcName}
                          value={accountName}
                        />
                        <span>{accountNameByte}/20</span>
                      </div>
                    </div>{" "}
                    <div className={styles.profile_account}>
                      <span>은행명</span>
                      <div className={styles.profile_account_inp}>
                        <input
                          type="text"
                          onChange={onChangeBank}
                          value={accountBank}
                        />{" "}
                        <span>{accountBankByte}/20</span>
                      </div>
                    </div>{" "}
                  </div>{" "}
                  <div
                    className={`${styles.profile_account_div} ${styles.bottom_box}`}
                  >
                    <div className={styles.profile_account}>
                      <span>계좌번호</span>
                      <div className={styles.profile_account_inp}>
                        <input
                          type="text"
                          onChange={onChangeAccount}
                          value={account}
                          placeholder="숫자만 입력하실 수 있습니다."
                        />
                      </div>
                    </div>{" "}
                  </div>
                </div>
                <div className={styles.btn_box}>
                  <button onClick={btn_save}>저장</button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
const BtnMobileSend = styled.button`
  cursor: ${(props) => (props.mobileBtn ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.mobileBtn ? "#93c8ec" : "#3498db")};
`;
const BtnValueSend = styled.button`
  cursor: ${(props) =>
    props.min === 0 && props.sec === 0
      ? "not-allowed"
      : props.mobileConfirmTest === true
      ? "not-allowed"
      : "pointer"};
  background-color: ${(props) =>
    props.min === 0 && props.sec === 0
      ? "#ebebeb"
      : props.mobileConfirmTest === true
      ? "#ebebeb"
      : "#d6d6d6"};
`;

const ZipCodeInput = styled.input``;
export default MypageProfileDetail;
