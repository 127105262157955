import intro from "../../../styles/intro.module.css";
// import img from "../../../assets/images/img_1.jpg";
import img1 from "../../../assets/images/intro_img1.jpg";
import img2 from "../../../assets/images/intro_img2.jpg";
import img3 from "../../../assets/images/intro_img3.jpg";
import img4 from "../../../assets/images/intro_img4.jpg";
import customer1 from "../../../assets/images/partner/amourart.png";
import customer2 from "../../../assets/images/partner/ahagoodmarket.png";
import customer3 from "../../../assets/images/partner/americantray.png";
import { useState, useEffect } from "react";
import { CommonAxios } from "../../CommonAxios";
const Intro_2 = ({ data }) => {
  const clickImg = (imgsrc, pageName) => {
    // alert(imgsrc);
    var imageWin = new Image();
    imageWin = window.open("", "", "width=4032px, height=3024px");
    imageWin.document.write("<html><body style='margin:0'>");
    imageWin.document.write("<img src='" + imgsrc + "' border=0 width='100%'>");
    imageWin.document.write("</body><html>");
    imageWin.document.title = pageName;
  };

  const [arrayList, setArrayList] = useState([]);
  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_partner",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);

  return (
    data.length > 0 && (
      <>
        <div className={intro.intro_common_wrap}>
          <div
            className={`${intro.intro_common} ${intro.intro_bottom_section}`}
          >
            <div className={intro.intro_content}>
              <div className={intro.content_1}>
                <div className={intro.content_bottom_detail}>
                  {data[6].intro_subject_1 && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data[6].intro_subject_1.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></span>
                  )}

                  {data[6].intro_content_1 && (
                    <em
                      dangerouslySetInnerHTML={{
                        __html: data[6].intro_content_1.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></em>
                  )}
                </div>
                <div
                  className={intro.intro_img_box}
                  onClick={() =>
                    clickImg(
                      `/introduce/${data[6].no}/${data[6].intro_img_1}`,
                      `${data[6].intro_subject_1}`
                    )
                  }
                >
                  {data[6].intro_img_1 && (
                    <img
                      src={`/introduce/${data[6].no}/${data[6].intro_img_1}`}
                    />
                  )}
                </div>
              </div>
              <div className={intro.content_2}>
                <div className={intro.content_bottom_detail}>
                  {data[6].intro_subject_2 && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data[6].intro_subject_2.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></span>
                  )}

                  {data[6].intro_content_2 && (
                    <em
                      dangerouslySetInnerHTML={{
                        __html: data[6].intro_content_2.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></em>
                  )}
                </div>
                <div
                  className={intro.intro_img_box}
                  onClick={() =>
                    clickImg(
                      `/introduce/${data[6].no}/${data[6].intro_img_2}`,
                      `${data[6].intro_subject_2}`
                    )
                  }
                >
                  {data[6].intro_img_2 && (
                    <img
                      src={`/introduce/${data[6].no}/${data[6].intro_img_2}`}
                    />
                  )}
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
        <div className={intro.intro_common_wrap}>
          {" "}
          <div
            className={`${intro.intro_common} ${intro.intro_bottom_section}`}
          >
            <div className={intro.intro_content}>
              <div className={intro.content_1}>
                <div className={intro.content_bottom_detail}>
                  {data[7].intro_subject_1 && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data[7].intro_subject_1.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></span>
                  )}

                  {data[7].intro_content_1 && (
                    <em
                      dangerouslySetInnerHTML={{
                        __html: data[7].intro_content_1.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></em>
                  )}
                </div>
                <div
                  className={intro.intro_img_box}
                  onClick={() =>
                    clickImg(
                      `/introduce/${data[7].no}/${data[7].intro_img_1}`,
                      `${data[7].intro_subject_1}`
                    )
                  }
                >
                  {data[7].intro_img_1 && (
                    <img
                      src={`/introduce/${data[7].no}/${data[7].intro_img_1}`}
                    />
                  )}
                </div>
              </div>
              <div className={intro.content_2}>
                {" "}
                <div className={intro.content_bottom_detail}>
                  {data[7].intro_subject_2 && (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data[7].intro_subject_2.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></span>
                  )}

                  {data[7].intro_content_2 && (
                    <em
                      dangerouslySetInnerHTML={{
                        __html: data[7].intro_content_2.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></em>
                  )}
                </div>
                <div
                  className={intro.intro_img_box}
                  onClick={() =>
                    clickImg(
                      `/introduce/${data[7].no}/${data[7].intro_img_2}`,
                      `${data[7].intro_subject_2}`
                    )
                  }
                >
                  {data[6].intro_img_2 && (
                    <img
                      src={`/introduce/${data[7].no}/${data[7].intro_img_2}`}
                    />
                  )}
                </div>
              </div>{" "}
            </div>
          </div>{" "}
        </div>{" "}
        <div
          className={`${intro.intro_common_wrap} ${intro.intro_common_last}`}
        >
          <div className={`${intro.intro_common} ${intro.intro_com_last}`}>
            {data[8].intro_title && (
              <h2
                dangerouslySetInnerHTML={{
                  __html: data[8].intro_title.replaceAll(/(\n|\r\n)/g, "<br/>"),
                }}
                className={intro.title}
              ></h2>
            )}{" "}
            <div
              className={`${intro.intro_content} ${intro.intro_content_last}`}
            >
              <div className={intro.content_1}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data[8].intro_strong.replaceAll(
                      /(\n|\r\n)/g,
                      "<br/>"
                    ),
                  }}
                ></p>
              </div>
              <div className={intro.content_2}>
                {data[8].intro_text && (
                  <div
                    className={intro.content_text}
                    dangerouslySetInnerHTML={{
                      __html: data[8].intro_text.replaceAll(
                        /(\n|\r\n)/g,
                        "<br/>"
                      ),
                    }}
                  ></div>
                )}
                <div className={intro.intro_customer_img_box}>
                  {arrayList.length > 0 &&
                    arrayList.map((data) => {
                      return (
                        <>
                          <a href={data.partner_link} target="_blank">
                            {" "}
                            <img
                              src={`/partner/${data.no}/${data.partner_img}`}
                              className={intro.intro_img1}
                            />
                          </a>
                        </>
                      );
                    })}
                </div>
                {/* <a href="http://www.amourart.co.kr/" target="_blank">
                  {" "}
                  <img src={customer1} className={intro.intro_img1} />
                </a>
                <a
                  href="https://smartstore.naver.com/ahagoodmarket"
                  target="_blank"
                >
                  {" "}
                  <img src={customer2} className={intro.intro_img2} />
                </a>
                <a href="http://americantray.com/" target="_blank">
                  {" "}
                  <img src={customer3} className={intro.intro_img3} />
                </a> */}
                {/* </div> */}
              </div>{" "}
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Intro_2;
