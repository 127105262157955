import inv from "../../../styles/invest.module.css";
import { MdError } from "react-icons/md";
import { GiFarmTractor } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcStackOfPhotos,
  FcSelfie,
  FcRuler,
} from "react-icons/fc";
import { RiLoginBoxLine } from "react-icons/ri";
import React from "react";
import farm_2020 from "../../../assets/images/farm_2020.png";
import farm_2021 from "../../../assets/images/farm_2021.jpg";
import farm_2022 from "../../../assets/images/farm_2022.jpg";
import farm_2023 from "../../../assets/images/farm_2023.jpg";
import { useSelector } from "react-redux";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import SwiperCore, { Virtual, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import Swal from "sweetalert2";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import InvestProductItem from "./InvestProductItem";

import Moment from "react-moment";
import { wonComma } from "../../../lib/wonComma";
import { CommonAxios } from "../../CommonAxios";
// import FundingContentItem from "./FundingContentItem";

const MyinvestCContent = ({ user }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [account, setAccount] = useState([]);
  const [data1, setData1] = useState([]);
  const SliderItemList = ({ data }) => {
    const product_delete = () => {
      if (data.invest_confirm === "Y") {
        return Swal.fire({
          title: "삭제 실패",
          html: `승인 대기 중에는 삭제가 불가능합니다.`,
          // icon: "success",

          confirmButtonText: "확인",
          allowOutsideClick: false,
        });
      }
      let sendData = {
        no: user.no,
        id: data.invest_no,
      };

      // dispatch(selectInvest(sendData));
      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/myInvest_delete_etc",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
            Swal.fire({
              title: "삭제 완료",
              // icon: "success",

              confirmButtonText: "확인",
              allowOutsideClick: false,
            });
            setData(result.messageinfo.message);

            setAccount(result.messageinfo.message_data2);
          } else {
          }
        }
      );
    };
    return (
      <>
        <div className={inv.invest_myList_item}>
          <div className={inv.invest_myList_title}>
            {" "}
            {data.pro_title ? data.pro_title : "삭제된 상품"}
          </div>
          <div className={inv.invest_myList_join}>
            신청일 <Moment format="YYYY-MM-DD HH:mm">{data.invest_date}</Moment>
          </div>
          <ul>
            <li>
              <span>투자금액</span>
              <span className={inv.myList_value}>
                {wonComma(data.invest_amount)}원
              </span>
            </li>{" "}
            <li>
              <span>금리</span>
              <span className={inv.myList_value}>{data.invest_rate}%</span>
            </li>{" "}
            <li>
              <span>승인</span>
              <span className={inv.myList_value}>
                {data.invest_confirm === "N" && "입금전"}{" "}
                {data.invest_confirm === "Y" && "대기중"}{" "}
              </span>
            </li>
          </ul>
          <div className={inv.myList_account_box}>
            {" "}
            <ul>
              <li>
                <span>은행명</span>
                <span className={inv.myList_value}>{account[0].acc_bank}</span>
              </li>{" "}
              <li>
                <span>예금주</span>
                <span className={inv.myList_value}>{account[0].acc_name}</span>
              </li>{" "}
              <li>
                <span>계좌번호</span>
                <span className={inv.myList_value}>
                  {account[0].acc_number}
                </span>
              </li>{" "}
            </ul>
            <button
              onClick={() => {
                navigator.clipboard.writeText(account[0].acc_number);
                Swal.fire({
                  title: "계좌 복사 완료",
                  // icon: "success",

                  confirmButtonText: "확인",
                  allowOutsideClick: false,
                });
              }}
            >
              계좌번호 복사
            </button>{" "}
            <button
              onClick={() => {
                product_delete();
              }}
            >
              상품 삭제
            </button>
          </div>
        </div>
      </>
    );
  };
  const SliderItemList1 = ({ data }) => {
    return (
      <>
        <div className={inv.invest_myList_item}>
          <div className={inv.invest_myList_title}>
            {data.pro_title ? data.pro_title : "삭제된 상품"}
          </div>
          <div className={inv.invest_myList_join}>
            취소/환불날짜{" "}
            <Moment format="YYYY-MM-DD HH:mm">{data.end_confirm_date}</Moment>
          </div>
          <ul>
            <li>
              <span>상태</span>
              <span className={inv.myList_value}>
                {data.end_kind === "C" && "취소"}{" "}
                {data.end_kind === "R" && "환불"}
              </span>
            </li>{" "}
            <li>
              <span>투자금액</span>
              <span className={inv.myList_value}>
                {wonComma(data.end_amount)}원
              </span>
            </li>{" "}
            <li>
              <span>받을금액</span>
              <span className={inv.myList_value}>
                {" "}
                {wonComma(data.end_amount_result)}원
              </span>
            </li>{" "}
          </ul>
        </div>
      </>
    );
  };
  const SliderItemList2 = ({ data }) => {
    return (
      <>
        <div className={inv.invest_myList_item}>
          <div className={inv.invest_myList_title}>{data.pro_title}</div>
          <div className={inv.invest_myList_join}>
            취소/환불날짜{" "}
            <Moment format="YYYY-MM-DD HH:mm">{data.invest_cancel_date}</Moment>
          </div>
          <ul>
            <li>
              <span>상태</span>
              <span className={inv.myList_value}>
                {data.invest_cancel === "Y" && "취소"}{" "}
                {data.invest_cancel === "R" && "환불"}
              </span>
            </li>{" "}
            <li>
              <span>투자금액</span>
              <span className={inv.myList_value}>
                {wonComma(data.invest_amount)}원
              </span>
            </li>{" "}
            <li>
              <span>받을금액</span>
              <span className={inv.myList_value}>
                {" "}
                {wonComma(data.invest_amount_result)}원
              </span>
            </li>{" "}
          </ul>
        </div>
      </>
    );
  };
  const slickRef = useRef(null);

  const [state, setState] = useState(1);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  const prevRef1 = useRef(null);
  const nextRef1 = useRef(null);
  const [swiper1, setSwiper1] = useState(null);
  const [mainImageIndx1, setMainImageIndx1] = useState(0);
  const parmss1 = {
    navigation: { prevEl: prevRef1.current, nextEl: nextRef1.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef1.current;
      swiper.params.navigation.nextEl = nextRef1.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper1,
    onSlideChange: (e) => setMainImageIndx1(e.activeIndex),
  };

  // const prevRef2 = useRef(null);
  // const nextRef2 = useRef(null);
  // const [swiper2, setSwiper2] = useState(null);
  // const [mainImageIndx2, setMainImageIndx2] = useState(0);
  // const parmss2 = {
  //   navigation: { prevEl: prevRef2.current, nextEl: nextRef2.current },
  //   onBeforeInit: (swiper) => {
  //     swiper.params.navigation.prevEl = prevRef2.current;
  //     swiper.params.navigation.nextEl = nextRef2.current;
  //     swiper.activeIndex = mainImageIndx;
  //     swiper.navigation.update();
  //   },
  //   onSwiper: setSwiper2,
  //   onSlideChange: (e) => setMainImageIndx2(e.activeIndex),
  // };

  useEffect(() => {
    let sendData = {
      // no: id,
      no: user.no,
    };

    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/myInvest_select_etc",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);

          setAccount(result.messageinfo.message_data2);
          setData1(result.messageinfo.message_data3);
        } else {
        }
      }
    );
  }, []);
  return (
    <div className={inv.invest_my_wrap}>
      {" "}
      <div
        className={`${inv.invest_my_content} ${inv.invest_my_content_cancel}`}
      >
        {" "}
        <div className="common-inner-wrap">
          <div className={inv.invest_my_top}>
            <h4>
              <em>{user.ur_name}</em>님의
              <br />
              대기/취소상품 내역입니다.
            </h4>
          </div>{" "}
        </div>
        <div
          className={inv.invest_my_bottom}
          style={{
            border: "2.5px solid #e5e7eb",

            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <h4>대기상품</h4>

          <div className={inv.invest_my_list_box}>
            {" "}
            {data.length === 0 && (
              <>
                {/* <div className={inv.invest_my_data_end_not}>
                  <h4 className={inv.invest_my_data_end_not_h4}>
                    <MdError style={{ marginRight: "4px" }} /> 대기 상품이
                    없습니다.
                  </h4>
                </div> */}
              </>
            )}
            {data.length > 0 && (
              <>
                <Swiper
                  className="mySwiper"
                  slidesPerView={1}
                  ref={setSwiper}
                  {...parmss}
                  style={{ position: "relative" }}
                  // history={{
                  //   key: "slide",
                  // }}
                  modules={[Navigation]}
                  breakpoints={{
                    520: {
                      slidesPerView: 2,
                      // spaceBetween: 20,
                    },

                    970: {
                      slidesPerView: 3,
                      // spaceBetween: 20,
                    },
                  }}
                >
                  {data.map((data) => {
                    return (
                      <SwiperSlide>
                        <div>
                          <SliderItemList data={data} />
                        </div>{" "}
                      </SwiperSlide>
                    );
                  })}
                  {/* </div>
                );
              })} */}
                </Swiper>{" "}
                <ButtonPrev2
                  ref={prevRef}

                  // style={{ width: "100px", height: "100px", background: "blue" }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdKeyboardArrowLeft />{" "}
                  </div>
                </ButtonPrev2>{" "}
                {/* <div
                ref={prevRef}
                style={{ width: "100px", height: "100px", background: "blue" }}
              ></div> */}
                <ButtonNext2 ref={nextRef}>
                  {" "}
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdKeyboardArrowRight />
                  </div>
                </ButtonNext2>
              </>
            )}
          </div>
        </div>
        <div
          className={inv.invest_my_bottom}
          style={{
            border: "2.5px solid #e5e7eb",

            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <h4>취소상품</h4>

          <div className={inv.invest_my_list_box}>
            {" "}
            {data1.length === 0 && (
              <>
                {/* <div className={inv.invest_my_data_end_not}>
                  <h4 className={inv.invest_my_data_end_not_h4}>
                    <MdError style={{ marginRight: "4px" }} /> 취소 상품이
                    없습니다.
                  </h4>
                </div> */}
              </>
            )}
            {data1.length > 0 && (
              <>
                {" "}
                <Swiper
                  className="mySwiper"
                  slidesPerView={2}
                  ref={setSwiper1}
                  {...parmss1}
                  style={{ position: "relative" }}
                  // history={{
                  //   key: "slide",
                  // }}
                  modules={[Navigation]}
                  breakpoints={{
                    740: {
                      slidesPerView: 3,
                      // spaceBetween: 20,
                    },
                    970: {
                      slidesPerView: 4,
                      // spaceBetween: 20,
                    },
                  }}
                >
                  {/* {a.map((data) => {
                return (
                  <div> */}
                  {data1.map((data) => {
                    return (
                      <SwiperSlide>
                        <div>
                          <SliderItemList1 data={data} />
                        </div>{" "}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>{" "}
                <ButtonPrev3 ref={prevRef1}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdKeyboardArrowLeft />{" "}
                  </div>
                </ButtonPrev3>{" "}
                <ButtonNext3 ref={nextRef1}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdKeyboardArrowRight />
                  </div>
                </ButtonNext3>
              </>
            )}
            {/* </div>
                );
              })} */}
          </div>
        </div>{" "}
      </div>
    </div>
  );
};
const ButtonPrev2 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 50%;
  left: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonNext2 = styled.div`
  position: absolute;
  z-index: 1;

  width: 35px;
  height: 35px;
  display: block;
  background: #fff;
  top: 50%;
  right: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonPrev3 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 50%;
  left: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonNext3 = styled.div`
  position: absolute;
  z-index: 1;

  width: 35px;
  height: 35px;
  display: block;
  background: #fff;
  top: 50%;
  right: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonPrev4 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 50%;
  left: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonNext4 = styled.div`
  position: absolute;
  z-index: 1;

  width: 35px;
  height: 35px;
  display: block;
  background: #fff;
  top: 50%;
  right: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
export default MyinvestCContent;
