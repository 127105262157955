import styles from "../../../styles/admin.module.css";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import pro from "../../../assets/images/profile_info.png";
import Moment from "react-moment";
import { numberToKorean } from "../../../lib/numberToKorean";
import Swal from "sweetalert2";
import { BsCheck } from "react-icons/bs";
import moment from "moment";
import { wonComma } from "../../../lib/wonComma";
const AdminRefundTwoDetail = ({ data, data1, setModal, setStep1 }) => {
  const navigate = useNavigate();
  const apply = () => {
    if (data.end_confirm === "Y") {
      return Swal.fire({
        title: "승인 불가",
        html: `이미 승인하셨습니다.`,

        confirmButtonText: "확인",
      });
    }

    Swal.fire({
      title: "승인절차",
      html: `정말 승인하시겠습니까?`,

      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        let sendData = {
          no: data.no,
          end_user_id: data.end_user_id,
          end_invest_no: data.end_invest_no,
          end_mobile: data.end_mobile,
          end_amount: wonComma(data.end_amount_result),
          send_amount: wonComma(data1.invest_amount),
          where: data.end_kind,
          end_push_date: "",
          end_user_name: data.user_name,
          pro_title: data.pro_title,
          user_email: data.user_email,
        };

        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/admin_update_end",
          sendData,
          function (result) {
            if (result.messageinfo.state == "ok") {
              CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin_update_end_send",
                sendData,
                function (result) {}
              );

              Swal.fire({
                title: "승인완료",

                confirmButtonText: "확인",
              }).then((result) => {
                navigate("/admin/refund");
              });
              // setArrayList(result.messageinfo.message);
            } else {
              // Swal.fire({
              //   title: "조회 실패",
              //   icon: "error",
              //   confirmButtonText: "확인",
              // }).then((res) => {
              //   if (res.isConfirmed) {
              //   }
              // });
            }
          }
        );
      }
    });
  };

  const cancel = () => {
    if (data.invest_confirm === "N") {
      Swal.fire({
        title: "승인 취소",
        html: `아직 승인을 하지 않았습니다.`,

        confirmButtonText: "확인",
      });
    } else {
      let date = new Date(data.invest_confirm_date);

      const diffDate = new Date().getTime() - date.getTime();

      if (Math.abs(diffDate / (1000 * 60 * 60 * 24)) > 3) {
        return Swal.fire({
          title: "승인 취소 불가",
          html: `아래 주의사항 참고바람`,

          confirmButtonText: "확인",
        });
      }
    }
  };
  return (
    <>
      <div className={styles.invest_wrap}>
        <div className={styles.invest_deposit_section}>
          <div className={styles.invest_deposit_box}>
            <div className={styles.invest_deposit_item}>
              <div className={styles.invest_deposit_title}>
                <h2>사용자 환불/취소 신청</h2>
                <div className={styles.invest_deposit_btn}>
                  <button
                    onClick={() => {
                      apply();
                    }}
                  >
                    승인
                  </button>
                </div>
              </div>
              <div className={styles.invest_deposit_info_box}>
                <div className={styles.invest_deposit_top}>
                  <div className={styles.invest_deposit_top_1}>
                    {" "}
                    <div className={styles.deposit_top}>
                      <label>신청인</label>
                      <span>{data.user_name}</span>
                    </div>
                    <div className={styles.deposit_top}>
                      {" "}
                      <label>이메일</label> <span>{data.user_email}</span>
                    </div>
                    <div className={styles.deposit_top}>
                      {" "}
                      <label>휴대폰 번호</label>
                      <span>{data.end_mobile}</span>
                    </div>
                  </div>{" "}
                  <div className={styles.invest_deposit_top_2}>
                    {" "}
                    <div className={styles.deposit_top}>
                      {" "}
                      <label>환불/취소 신청날짜</label>
                      <span>
                        {" "}
                        {data.end_date ? (
                          <Moment format="YYYY-MM-DD HH:mm:ss">
                            {data.end_date}
                          </Moment>
                        ) : (
                          "-"
                        )}
                      </span>
                    </div>{" "}
                    <div className={styles.deposit_top}>
                      {" "}
                      <label>환불/취소 승인날짜</label>
                      <span>
                        {data.end_confirm_date ? (
                          <Moment format="YYYY-MM-DD HH:mm:ss">
                            {data.end_confirm_date}
                          </Moment>
                        ) : (
                          "-"
                        )}
                      </span>
                    </div>{" "}
                    <div className={styles.deposit_top}>
                      {" "}
                      <label>환불/취소 승인여부</label>
                      <span>
                        {data.end_confirm === "Y" ? "승인" : "미승인"}
                      </span>
                    </div>
                  </div>{" "}
                </div>
                <div className={styles.invest_deposit_bottom}>
                  <div className={styles.invest_deposit_bottom_1}>
                    {" "}
                    <div className={styles.deposit_bottom}>
                      <label>예금주</label>
                      <span>{data.end_name}</span>
                    </div>
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label>은행</label>
                      <span>{data.end_bank}</span>
                    </div>{" "}
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label>계좌번호</label>
                      <span>{data.end_account}</span>
                    </div>
                  </div>{" "}
                  <div className={styles.invest_deposit_bottom_2}>
                    <div className={styles.deposit_bottom}>
                      <label>종류</label>
                      <span>{data.end_kind === "R" ? "환불" : "취소"}</span>
                    </div>
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label></label> <span></span>
                    </div>
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label></label>
                      <span></span>
                    </div>
                  </div>
                </div>{" "}
                <div className={styles.invest_deposit_bottom}>
                  <div className={styles.invest_deposit_bottom_1}>
                    {" "}
                    <div className={styles.deposit_bottom}>
                      <label>투자원금</label>
                      <span>{wonComma(numberToKorean(data.end_amount))}원</span>
                    </div>
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label>이자</label>
                      <span>
                        {wonComma(numberToKorean(data.end_interest_result))}원
                      </span>
                    </div>{" "}
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label>최종금액</label>
                      <span>
                        {wonComma(numberToKorean(data.end_amount_result))}원
                      </span>
                    </div>
                  </div>{" "}
                  <div className={styles.invest_deposit_bottom_2}>
                    <div className={styles.deposit_bottom}>
                      <label>수익률(신청당일)</label>
                      <span>{data.end_rate}%</span>
                    </div>
                    <div className={styles.deposit_bottom}>
                      <label>해지수수료(신청당일-환불에만 포함)</label>
                      <span>{data.end_percent}%</span>
                    </div>
                    <div className={styles.deposit_bottom}>
                      {" "}
                      <label></label>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button>승인</button> */}
            </div>
          </div>
          <div className={styles.deposit_warring}>
            <h4>주의사항</h4>
            <p>
              <BsCheck />
              세전 입금 후 승인 완료를 눌러주시길 바랍니다.
            </p>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminRefundTwoDetail;
