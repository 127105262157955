import styles from "../../../styles/admin.module.css";
import { useState, useEffect, useCallback, useRef } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import { useSelector, useDispatch } from "react-redux";
import { BsCameraFill } from "react-icons/bs";
import Swal from "sweetalert2";

import { encrypt } from "../../../lib/api/tokenCrypto";
import pro from "../../../assets/images/profile_info.png";
import { getUsers } from "../../../modules/user";
const AdminDetail = () => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const dispatch = useDispatch();
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  const imgNameRef = useRef(); //이미지 파일 이름 REF
  const [imageFile, setImageFile] = useState(""); //이미지 속성정보
  const [getimage, setGetImage] = useState(""); //render.result 값
  const [existingPassword, setExistingPassword] = useState("");
  const [passwordValue, setPasswordValue] = useState(""); //비밀번호
  const [confirmValue, setConfirmValue] = useState(""); //비밀번호 확인
  const [changePassColor, setChangePassColor] = useState(true); //패스워드 오류 메세지
  const [changePass2Color, setChangePass2Color] = useState(true); //패스워드 2차 확인 오류 메세지
  const password_test =
    /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{10,}$/;
  const email_test =
    /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState(true);
  const [mValue, setMValue] = useState("");
  const [mError, setMError] = useState(true);
  useEffect(() => {
    if (user) {
      setEmailValue(user.ur_email);
      setMValue(user.ur_mobile);
    }
  }, []);
  const onChangeEmail = useCallback((e) => {
    setEmailValue(e.target.value);

    if (e.target.value === "" || !email_test.test(e.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  }, []);
  const onChangeM = useCallback((e) => {
    setMValue(e.target.value);

    if (
      e.target.value === "" ||
      !/^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/.test(e.target.value)
    ) {
      setMError(false);
    } else {
      setMError(true);
    }
  }, []);

  const onChangeExisting = useCallback((e) => {
    setExistingPassword(e.target.value);
  }, []);

  const onChangePassword = useCallback((e) => {
    setPasswordValue(e.target.value);
    if (e.target.value === "" || !password_test.test(e.target.value)) {
      setChangePassColor(false);
    } else {
      setChangePassColor(true);
    }
  }, []);

  const onChangeConfirm = useCallback(
    (e) => {
      setConfirmValue(e.target.value);
      if (e.target.value === "" || e.target.value !== passwordValue) {
        setChangePass2Color(false);
      } else {
        setChangePass2Color(true);
      }
    },
    [passwordValue]
  );

  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 500;

    if (file[0] === undefined) {
      return;
    }

    // if (
    //   imgExtension[1] !== "jpg" ||
    //   imgExtension[1] !== "png" ||
    //   imgExtension[1] !== "jpeg" ||
    //   imgExtension[1] !== "gif"
    // ) {
    //   Swal.fire({
    //     title: "이미지 업로드 불가",
    //     text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",
    //     icon: "error",
    //     confirmButtonText: "확인",
    //     confirmButtonColor: "#FF0000",
    //   });
    //   return;
    // }
    // setImageFile(file);

    if (
      imgExtension[1] !== "jpg" &&
      imgExtension[1] !== "png" &&
      imgExtension[1] !== "jpeg" &&
      imgExtension[1] !== "gif"
    ) {
      return Swal.fire({
        title: "이미지 업로드 불가",
        text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
        confirmButtonColor: "#FF0000",
      });
    }
    if (file[0].size >= maxSize) {
      //이미지 사이즈가 500k보다 크다면,
      setImageFile(imageFile);
      Swal.fire({
        title: "이미지 용량 초과",
        text: "다른 이미지를 업로드해주세요 500kb 이하로 업로드해주세요.",

        confirmButtonText: "확인",
        confirmButtonColor: "#FF0000",
      });
      return;
    } else {
      setImageFile(file);
    }
  };

  useEffect(() => {
    preview(); // 미리보기
  });

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      imageRef.current.src = reader.result;
      setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };

  const btn_save = () => {
    if (existingPassword || passwordValue || confirmValue) {
      if (!existingPassword || !passwordValue || !confirmValue) {
        return Swal.fire({
          title: "입력된 값이 없습니다.",

          confirmButtonText: "확인",
        });
      }
      let sendData = {
        no: user.no,

        bPass: "",
        chPass: "",
        email: emailValue,
        mobile: mValue,
      };
      if (existingPassword !== "") {
        sendData.bPass = existingPassword;
        sendData.chPass = passwordValue;
      }
      if (!emailError || !mError) {
        return Swal.fire({
          title: "입력된 값을 확인해주세요.",

          confirmButtonText: "확인",
        });
      }

      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/admin_manage_update",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
            if (result.messageinfo.message === "비밀번호가 다릅니다.") {
              return Swal.fire({
                title: "현재 비밀번호가 일치하지 않습니다.",

                confirmButtonText: "확인",
              });
            } else {
              localStorage.setItem(
                "meta_info",
                encrypt(result.messageinfo.message, "meta_info")
              );
              dispatch(getUsers(result.messageinfo.message));
              return Swal.fire({
                title: "수정 완료",

                confirmButtonText: "확인",
              });
            }
            // setMoveAlert(true);
          } else {
          }
        }
      );
    } else {
      if (!emailError || !mError) {
        return Swal.fire({
          title: "입력된 값을 확인해주세요.",

          confirmButtonText: "확인",
        });
      }
      let sendData = {
        no: user.no,

        email: emailValue,
        mobile: mValue,
      };

      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/admin_manage_update2",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
            localStorage.setItem(
              "meta_info",
              encrypt(result.messageinfo.message, "meta_info")
            );
            dispatch(getUsers(result.messageinfo.message));
            return Swal.fire({
              title: "수정 완료",

              confirmButtonText: "확인",
            });
            // setMoveAlert(true);
          } else {
          }
        }
      );
    }
  };
  return (
    <>
      {" "}
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>관리자 정보</div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.mange_info_wrap}>
            {" "}
            <div className={styles.mange_info_box}>
              {" "}
              <div
                className={`${styles.profile_section} ${styles.profile_section_1}`}
              >
                <div className={styles.profile_img_div}>
                  <img src={pro} ref={imageRef} />
                </div>
                {/* <label className={styles.profile_camera}>
                  <BsCameraFill />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={inpChange}
                    style={{ display: "none" }}
                  />
                </label> */}
              </div>{" "}
              <div
                className={`${styles.profile_section} ${styles.profile_section_2}`}
              >
                <label>이름</label>
                <div className={styles.profile_section_right}>
                  <div className={styles.profile_input_div}>
                    <div className={styles.profile_inp_box}>
                      <input
                        className={styles.profile_inp}
                        autoComplete="off"
                        value={user.ur_name}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div
                className={`${styles.profile_section} ${styles.profile_section_2}`}
              >
                <label>이메일</label>
                <input
                  type="text"
                  onChange={onChangeEmail}
                  value={emailValue}
                />
                {/* <button
                  className={styles.mypage_password_confirm}
                  onClick={btn_confirm}
                >
                  확인
                </button> */}{" "}
                {emailError ? null : (
                  <em className={styles.error_em}>
                    이메일형식이 맞지 않습니다.
                  </em>
                )}
              </div>{" "}
              <div
                className={`${styles.profile_section} ${styles.profile_section_2}`}
              >
                <label>핸드폰번호</label>
                <input type="text" onChange={onChangeM} value={mValue} />
                {/* <button
                  className={styles.mypage_password_confirm}
                  onClick={btn_confirm}
                >
                  확인
                </button> */}{" "}
                {mError ? null : (
                  <em className={styles.error_em}>
                    핸드폰 번호 형식이 맞지 않습니다. 하이픈(-)입력 요망
                  </em>
                )}
              </div>
              <div
                className={`${styles.profile_section} ${styles.profile_section_2}`}
              >
                <label>현재 비밀번호</label>
                <input
                  type="password"
                  onChange={onChangeExisting}
                  value={existingPassword}
                />
                {/* <button
                  className={styles.mypage_password_confirm}
                  onClick={btn_confirm}
                >
                  확인
                </button> */}
              </div>
              <div
                className={`${styles.profile_section} ${styles.profile_section_2}`}
              >
                <div className={styles.mypage_password_section}>
                  <label>변경할 비밀번호</label>
                  <input
                    type="password"
                    onChange={onChangePassword}
                    value={passwordValue}
                  />
                  {!changePassColor ? (
                    <em className={styles.error_em}>
                      영문/ 숫자 / 특수문자 중 2가지 이상 조합 (10자 이상)
                    </em>
                  ) : (
                    <em className={styles.noerror_em}>
                      영문/ 숫자 / 특수문자 중 2가지 이상 조합 (10자 이상)
                    </em>
                  )}
                </div>
                <div
                  className={`${styles.profile_section} ${styles.profile_section_2}`}
                >
                  <label>변경할 비밀번호 확인</label>
                  <input
                    type="password"
                    onChange={onChangeConfirm}
                    value={confirmValue}
                  />{" "}
                  {!changePass2Color ? (
                    <em className={styles.error_em}>
                      비밀번호가 일치하지않습니다.
                    </em>
                  ) : null}
                </div>
                <div className={styles.admin_info_flex}>
                  <button
                    className={styles.mypage_password_confirm}
                    onClick={btn_save}
                  >
                    변경하기
                  </button>
                </div>{" "}
                <em className={styles.error_em}>
                  참고
                  <br />
                  발신자의 휴대폰번호와 이메일 주소는 변경되지 않습니다.
                  <br />
                  발신자의 휴대폰번호와 이메일 주소 변경을 원하신다면 개발
                  업체에 문의바랍니다.
                  <br />
                </em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDetail;
