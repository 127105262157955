import styles from "../../../styles/admin.module.css";
import { useState, useEffect, useCallback } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import pro from "../../../assets/images/profile_info.png";
import Swal from "sweetalert2";
import Moment from "react-moment";
import { getTextTotal, textOverCut } from "../../../lib/stringLenghts";
import { textReplace } from "../../../lib/textReplace";
const AdminUserInfo = ({ userInfo }) => {
  const btn_create = () => {
    Swal.fire({
      title: "임시 비밀번호 생성",
      html: `비밀번호를 초기화 하신 후 임시 비밀번호를 생성하시겠습니까?
      </br> 임시 비밀번호는 해당 계정 이메일로 발송됩니다.`,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let sendData = {
          no: userInfo.no,
          user_email: userInfo.user_email,
          // user_memo: textReplace(memo),
        };
        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/admin_update_password",
          sendData,
          function (result) {
            if (result.messageinfo.state == "ok") {
              Swal.fire({
                title: "임시 비밀번호 전송 완료",
                confirmButtonText: "확인",
              });
            }
          }
        );
      }
    });
  };
  const [memo, setMemo] = useState("");
  const [memoByte, setMemoByte] = useState(0);

  const changeArea = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 300);
    let text = textOverCut(e.target.value, 300);

    setMemo(text);
    setMemoByte(byte.byte);
  }, []);

  const btn_memo = () => {
    let sendData = {
      no: userInfo.no,
      user_memo: textReplace(memo),
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_memo",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          Swal.fire({
            title: "메모 작성 완료",

            confirmButtonText: "확인",
          });
        }
      }
    );
  };

  useEffect(() => {
    if (userInfo.user_memo) {
      let byte = getTextTotal(userInfo.user_memo, 300);

      setMemo(userInfo.user_memo);
      setMemoByte(byte.byte);
    }
  }, [userInfo]);
  return (
    <>
      <div className={`${styles.admin_user_box}  ${styles.user_box} `}>
        <div className={styles.user_box_inner}>
          {" "}
          <div className={styles.admin_user_left}>
            <div
              className={`${styles.admin_user_step} ${styles.admin_user_step_1} `}
            >
              <div className={styles.admin_user_img}>
                <img src={userInfo.user_image ? userInfo.user_image : pro} />
              </div>

              <div className={styles.admin_user_name}>
                <span className={styles.admin_user_name}>
                  {userInfo.user_name}
                </span>
              </div>
              <div className={styles.admin_user_kind}>
                <span className={styles.admin_user_kind}>
                  [{userInfo.user_kind === 1 ? "관리자" : "일반"}]
                </span>
              </div>
            </div>
            <div
              className={`${styles.admin_user_step} ${styles.admin_user_step_2} `}
            >
              <span className={styles.admin_user_title}>성별 : </span>
              <span className={styles.admin_user_cont}>
                {userInfo.user_gender === 1 ? "남자" : "여자"}
              </span>
            </div>
            <div
              className={`${styles.admin_user_step} ${styles.admin_user_step_2} `}
            >
              <span className={styles.admin_user_title}>나이 : </span>
              <span className={styles.admin_user_cont}>
                {userInfo.user_age ? userInfo.user_age + " 대" : "미입력"}
              </span>
            </div>
            <div
              className={`${styles.admin_user_step} ${styles.admin_user_step_2} `}
            >
              <span className={styles.admin_user_title}>지역 : </span>
              <span className={styles.admin_user_cont}>
                {userInfo.user_place ? userInfo.user_place : "미입력"}
              </span>
            </div>{" "}
            <div
              className={`${styles.admin_user_step} ${styles.admin_user_step_2} `}
            >
              <span className={styles.admin_user_title}>핸드폰 번호 : </span>
              <span className={styles.admin_user_cont}>
                {userInfo.user_mobile ? userInfo.user_mobile : "미등록"}
              </span>
            </div>
            <div
              className={`${styles.admin_user_step} ${styles.admin_user_step_2} `}
            >
              <span className={styles.admin_user_title}>생성 날짜 : </span>
              <span className={styles.admin_user_cont}>
                <Moment format="YYYY-MM-DD HH:mm">
                  {userInfo.user_create_date}
                </Moment>
              </span>
            </div>
            <div
              className={`${styles.admin_user_step} ${styles.admin_user_step_2} `}
            >
              <span className={styles.admin_user_title}>방문 날짜 : </span>
              <span className={styles.admin_user_cont}>
                <Moment format="YYYY-MM-DD HH:mm">
                  {userInfo.user_visit_date}
                </Moment>
              </span>
            </div>{" "}
            <div
              className={`${styles.admin_user_step} ${styles.admin_user_step_2} `}
            >
              <span className={styles.admin_user_title}>이메일 : </span>
              <span className={styles.admin_user_cont}>
                {userInfo.user_email}
              </span>
            </div>
            <div
              className={`${styles.admin_user_step} ${styles.admin_user_step_2} `}
            >
              <span className={styles.admin_user_title}>임시 비밀번호 : </span>
              <span
                className={`${styles.admin_user_cont} ${styles.admin_user_cont_btn}`}
              >
                <button onClick={btn_create}> 초기화 및 생성</button>
              </span>
            </div>
          </div>
          <div className={styles.admin_user_right}>
            <div className={styles.admin_user_right_1}>
              <div className={`${styles.admin_user_address}`}>
                <span className={styles.admin_address_title}>주소지</span>
                {userInfo.user_address_number && (
                  <ul>
                    <li>
                      <label>우편번호 :</label> {userInfo.user_address_number}
                    </li>
                    <li>
                      <label>기본 주소 :</label> {userInfo.user_address_road}
                    </li>
                    <li>
                      <label>상세 주소:</label> {userInfo.user_address_detail}
                    </li>
                  </ul>
                )}

                {!userInfo.user_address_number && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "150px",
                      color: "#898989",
                      fontSize: "15px",
                    }}
                  >
                    <span>미입력</span>
                  </div>
                )}
              </div>
            </div>{" "}
            <div className={styles.admin_user_right_2}>
              <div className={`${styles.admin_user_address}`}>
                <span className={styles.admin_address_title}>계좌정보</span>{" "}
                {userInfo.user_account && (
                  <ul>
                    <li>
                      <label>예금주 :</label> {userInfo.user_acName}
                    </li>
                    <li>
                      <label>은행명 :</label> {userInfo.user_acBank}
                    </li>
                    <li>
                      <label>계좌번호:</label> {userInfo.user_account}
                    </li>
                  </ul>
                )}
                {!userInfo.user_account && (
                  <>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "150px",
                        color: "#898989",
                        fontSize: "15px",
                      }}
                    >
                      <span>미입력</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.admin_user_rigth}>
          <div className={styles.admin_memo_title}>
            메모<button onClick={btn_memo}>작성</button>
          </div>
          <div className={styles.admin_memo_div}>
            <textarea
              className={styles.admin_memo_textarea}
              onChange={changeArea}
              value={memo}
            ></textarea>
          </div>
          <span>{memoByte}/100자</span>
        </div>
      </div>{" "}
    </>
  );
};

export default AdminUserInfo;
