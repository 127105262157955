// import "../styles/search.css";

// import noData from "../assets/images/no_data.png";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useCallback, useRef, useEffect, useState } from "react";
import axios from "axios";
import MainBanner from "../components/main/MainBanner";
import SearchDetail from "../components/search/SearchDetail";
// import SearchItem from "../components/search/SearchItem";
// import { tr } from "date-fns/locale";

const SearchPage = () => {
  //   const { word } = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const keyword1 = searchParams.get("keyword");
  const order1 = searchParams.get("order");
  const mode1 = searchParams.get("mode");
  const [classList, setClassList] = useState([]);
  const navigate = useNavigate();
  const allCnt = useRef(0);
  const brickCnt = useRef(0);
  const spikeCnt = useRef(0);
  const etcCnt = useRef(0);
  const evCnt = useRef(0);

  let keys = keyword1;
  let keyText = keys.split("");
  if (keyText.length > 10) {
    keys = keys.substring(0, 9) + "...";
  }

  const menus = [
    {
      id: 1,
      text: "전체",
      mode: "all",
    },
    {
      id: 2,
      text: "스파이크™",
      mode: "spike",
    },
    {
      id: 3,
      text: "브릭큐모션",
      mode: "brick",
    },
    {
      id: 4,
      text: "기타",
      mode: "etc",
    },
    {
      id: 5,
      text: "EV3 마인드스톰",
      mode: "ev",
    },
  ];

  const getSearch = async () => {
    try {
      const options = {
        method: "GET",
        // header: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json;charset=UTP-8",
        // },
        // params: {
        //
        // },
      };
      await axios
        .get(process.env.REACT_APP_HOSTDONAME + `/api/select_main_search`, {
          params: {
            keyword: keyword1,
            order: order1,
            mode: mode1,
          },
        })
        .then((res) => {
          allCnt.current = 0;
          brickCnt.current = 0;
          spikeCnt.current = 0;
          etcCnt.current = 0;
          evCnt.current = 0;

          setClassList(res.data.messageinfo.message);
          res.data.messageinfo.message_data2.map((item) => {
            if (item.class_category_one === "스파이크™") {
              spikeCnt.current = item.cnt;
            }
            if (item.class_category_one === "브릭큐 모션") {
              brickCnt.current = item.cnt;
            }
            if (item.class_category_one === "기타") {
              etcCnt.current = item.cnt;
            }
            if (item.class_category_one === "EV3 마인드스톰") {
              evCnt.current = item.cnt;
            }
            allCnt.current += item.cnt;
          });
        });
    } catch (e) {
      // console.log(e);
    }
  };
  useEffect(() => {
    getSearch();
  }, [keyword1, order1, mode1]);
  const [searchValue, setSearchValue] = useState("");
  const changeSearch = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);
  const keyEvent = (e) => {
    if (searchValue === "") return;
    if (e.key === "Enter") {
      navigate(`/search?keyword=${searchValue}&order=popular&mode=all`);
      // setSearchValue("");
    }
  };

  const changeOrder = (e) => {
    navigate(`/search?keyword=${keyword1}&order=${e.target.value}&mode=all`);
  };
  const changeMode = (modeValue) => {
    // console.log(modeValue);
    navigate(`/search?keyword=${keyword1}&order=${order1}&mode=${modeValue}`);
  };

  // const [ ciw, setCiw ] = useState();
  // useEffect(() => {
  //   var ciwidth = document.querySelector(".search-container").clientWidth;
  //   // console.log(ciwidth);
  //   setCiw(ciwidth);
  // }, [])
  // if(ciw < 440) {
  //   menus[4].text = "마인드스톰";
  // } else {
  //   menus[4].text = "EV3 마인드스톰";
  // }
  // console.log(ciwidth);
  // if(ciwidth){

  return (
    <>
      <div id="container">
        <MainBanner />

        <SearchDetail />
      </div>
    </>
  );
};

export default SearchPage;
// const NoData = () => {
//   return (
//     <>
//       <div
//         style={{
//           width: "100%",
//           // height: "800px",
//           padding: "30px 0",
//           textAlign: "center",
//         }}
//       >
//         <img
//           src={noData}
//           style={{
//             width: "100%",
//             // height: "800px",
//           }}
//         />
//       </div>
//     </>
//   );
// };
