import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
// import Portal from "./portal";
import img from "../assets/images/pop.png";
import { imgLoadView } from "./imgLoadView";
import { CommonAxios } from "../components/CommonAxios";
import moment from "moment";
function Modal({ className, onClose, maskClosable, closable, visible, data }) {
  const [link, setLink] = useState("");
  const [imged, setImged] = useState();

  const onMaskClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  // 이전방문 날짜
  const VISITED_BEFORE_DATE = localStorage.getItem("aWDFESQddcxc");
  // 현재 날짜
  const VISITED_NOW_DATE = moment(new Date()).format("YYYY-MM-DD");

  // console.log(VISITED_BEFORE_DATE)
  // console.log(VISITED_NOW_DATE)
  // localStorage.removeItem('VisitCookie')

  useEffect(() => {
    // 팝업 오늘 하루닫기 체크
    if (VISITED_BEFORE_DATE !== null) {
      // 날짜가 같을경우 노출
      if (VISITED_BEFORE_DATE === VISITED_NOW_DATE) {
        onClose(false);
      }
      // 날짜가 다를경우 비노출
      else {
        localStorage.removeItem("aWDFESQddcxc");
        onClose(true);
      }
    }
  }, [VISITED_BEFORE_DATE]);

  // 하루동안 팝업 닫기
  const Dayclose = (e) => {
    if (onClose) {
      onClose(e);

      const expiry = new Date();
      localStorage.setItem("aWDFESQddcxc", moment(expiry).format("YYYY-MM-DD"));
      // +1일 계산
      // const expiryDate = expiry.getDate() + 1;
      // 로컬스토리지 저장
      // localStorage.setItem("meta_visit", expiryDate);
    }
  };

  const close = (e) => {
    if (onClose) {
      onClose(e);
    }
  };

  // const imgRef = useRef();
  // const imgLoad = imgLoadView(imgRef);
  const imgRef = useRef(null);

  // 완전히 load 된 상태인지를 담고 있는 useState
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [hei, setHei] = useState(0);
  const imgRef1 = useRef(null);
  const [imgSw, setImgSw] = useState(false);
  // ref를 바라보며, 변경이 생길 때마다 load status를 업데이트 하기위한 useEffect
  useEffect(() => {
    if (visible) {
      // console.log(visible);
      if (!imgRef.current) {
        return;
      }

      // complete와 naturalHeight를 이용해 완전한 load를 판단하는 함수
      const updateStatus = (img) => {
        if (img) {
          const isLoaded = img.naturalHeight !== 0;

          // setHei(imgRef.current?.offsetHeight);

          setIsImgLoaded(isLoaded);
          setImgSw(true);
        } else {
        }
      };

      imgRef.current.addEventListener(
        "load",
        () => updateStatus(imgRef.current)
        // { once: true }
      );
    }
  }, [imgRef]);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowSize(window.innerWidth);

    // if (window.innerWidth <= 1100 && window.innerWidth > 800) {
    //   setHei(imgRef.current?.offsetHeight);
    // }
    // if (window.innerWidth <= 800) {
    //   setHei(imgRef.current?.offsetHeight);
    // }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (imgSw) {
      if (window.innerWidth <= 1100 && window.innerWidth > 800) {
        setHei(imgRef.current?.offsetHeight);
      }
      if (window.innerWidth <= 800) {
        setHei(imgRef.current?.offsetHeight);
      }
    }
  }, [imgSw]);
  return (
    // <Portal elementId="modal-root">
    <>
      {/* <ModalOverlay visible={visible} /> */}

      <ModalWrapper
        className={className}
        hei={hei}
        ref={imgRef1}
        // onClick={maskClosable ? onMaskClick : null}
        tabIndex="-1"
        visible={visible}
        isImgLoaded={isImgLoaded}
      >
        <ModalInner tabIndex="0" className="modal-inner">
          <ModalIn>
            <ModalIn2>
              <ModalInner2>
                <ImgStyle>
                  <a
                    href={data.popup_link ? data.popup_link : ""}
                    rel="noopener noreferrer"
                    target={"_blank"}
                    cursor="pointer"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <img
                      src={`/popup/${data.no}/${data.popup_img}`}
                      style={{ width: "100%", height: "100%" }}
                      alt=""
                      ref={imgRef}
                    />
                  </a>
                </ImgStyle>
                {closable && (
                  <CloseStyle>
                    <Close className="modal-close" onClick={Dayclose}>
                      오늘 하루 닫기
                    </Close>
                    <Close className="modal-close" onClick={close}>
                      닫기
                    </Close>
                  </CloseStyle>
                )}
              </ModalInner2>{" "}
            </ModalIn2>
          </ModalIn>
        </ModalInner>
      </ModalWrapper>

      {/* // </Portal> */}
    </>
  );
}

// Modal.propTypes = {
//   visible: PropTypes.bool,
// // };
// const ModalWrapper = styled.div`
//   box-sizing: border-box;
//   display: ${(props) =>
//     props.visible && props.isImgLoaded ? "block" : "none"};
//   position: fixed;
//   // background: red;
//   top: 10%;
//   left: 5%;
//   width: 360px;
//   min-height: 240px;
//   // top: 0;
//   // right: 0;
//   // bottom: 0;
//   // left: 0;
//   z-index: 1000;
//   // overflow: hidden;
//   outline: 0;
//   // background: red;
//   @media screen and (max-width: 1100px) {
//     display: ${(props) =>
//       props.visible && props.isImgLoaded ? "block" : "none"};
//     top: 50%;
//     left: 50%;
//     margin-left: -180px;
//     margin-top: ${(props) => -(props.hei / 2)}px;
//   }
//   @media screen and (max-width: 800px) {
//     display: ${(props) =>
//       props.visible && props.isImgLoaded ? "block" : "none"};
//     // opacity: ${(props) => (props.hei === 0 ? "1" : "0")};
//     opacity: 1;
//     width: 300px;
//     top: 50%;
//     left: 50%;
//     margin-left: -150px;
//     margin-top: ${(props) => -(props.hei / 2)}px;
//   }
// `;

// const ModalInner2 = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
// `;

// const ImgStyle = styled.div`
//   width: 100%;
//   max-height: 540px;
//   border: 2px solid #cacaca;
//   overflow: auto;
// `;

// const CloseStyle = styled.div`
//   display: flex;
//   justify-content: space-between;
//   // background-color: #282828;
//   background-color: #00c73c;
//   width: 100%;
//   padding: 15px;
//   border-radius: 0 0 15px 15px;
//   color: #ffffff;
// `;

// const Close = styled.span`
//   cursor: pointer;
// `;

// const ModalOverlay = styled.div`
//   box-sizing: border-box;
//   display: ${(props) => (props.visible ? "block" : "none")};
//   position: fixed;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   background-color: rgba(0, 0, 0, 0.6);
//   z-index: 999;
// `;

// const ModalInner = styled.div`
//   box-sizing: border-box;
//   position: relative;
//   // box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
//   // background-color: #fff;
//   // border-radius: 10px;
//   width: 360px;
//   // border: 1px solid #cacaca;
//   // max-width: 360px;
//   // top: 3%;
//   // left: -30%;
//   // transform: translateY(-50%);
//   margin: 0 auto;
//   // padding: 40px 20px;
//   // background: red;

//   @media screen and (max-width: 800px) {
//     width: 300px;
//   }
// `;

const ModalWrapper = styled.div`
  box-sizing: border-box;
  display: ${(props) =>
    props.visible && props.isImgLoaded ? "block" : "none"};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;
  background: rgba(35, 48, 61, 0.6);
  outline: 0;
`;

const ModalInner2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ImgStyle = styled.div`
  width: 100%;
  max-height: 540px;
  // border: 2px solid #cacaca;
  // overflow: auto;
`;

const CloseStyle = styled.div`
  display: flex;
  justify-content: space-between;
  // background-color: #282828;
  background-color: #00c73c;
  width: 100%;
  padding: 15px;
  border-radius: 0 0 15px 15px;
  color: #ffffff;
`;

const Close = styled.span`
  cursor: pointer;
`;

const ModalOverlay = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

const ModalInner = styled.div`
  width: 100%;
  height: 100%;
  // display: flex;
  // position: relative;
  // top: 10%;
  // left: 5%;
  // justify-content: center;
  // align-items: center;

  @media screen and (max-width: 1100px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ModalIn = styled.div`
  position: relative;
  top: 10%;
  left: 5%;
  width: 360px;
  @media screen and (max-width: 1100px) {
    position: static;
  }
  @media screen and (max-width: 800px) {
    width: 300px;
  }
`;
const ModalIn2 = styled.div`
  width: 360px;
  min-height: 240px;
  @media screen and (max-width: 800px) {
    width: 300px;
  }
`;
export default React.memo(Modal);
