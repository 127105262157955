import styles from "../../../styles/admin.module.css";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import AdminUserInfo from "./AdminUserInfo";
import ProductRefundList from "../details/ProductRefundList";
import ProductInterestList from "../details/ProductInterestList";
import ProductInvestList from "../details/ProductInvestList";
import UserInvestList from "../details/UserInvestList";
import UserRefundList from "../details/UserRefundList";
import UserInterestList from "../details/UserInterestList";
import AdminUserPassword from "./AdminUserPassword";
import UserEndList from "../details/UserEndList";

const AdminUserDetail = () => {
  const [options, setOptions] = useState("all");

  const [userInfo, setUserInfo] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    let sendData = {
      no: id,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_user_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setUserInfo(result.messageinfo.message[0]);
          // localStorage.removeItem("meta_info");
          // document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  }, []);

  return (
    <>
      {" "}
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          회원관리 &nbsp;〉 &nbsp;회원 목록〉 &nbsp;회원 상세
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.user_header}>
            <div className={styles.user_option}>
              <div
                className={
                  options == "all"
                    ? `${styles.all} ${styles.clicked}`
                    : styles.all
                }
                onClick={() => setOptions("all")}
              >
                정보
              </div>
              <div
                className={
                  options == "list"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                onClick={() => setOptions("list")}
              >
                투자진행내역
              </div>{" "}
              <div
                className={
                  options == "list2"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                onClick={() => setOptions("list2")}
              >
                이자출금내역
              </div>{" "}
              <div
                className={
                  options == "list1"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                onClick={() => setOptions("list1")}
              >
                투자종료내역
              </div>{" "}
              <div
                className={
                  options == "list3"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                onClick={() => setOptions("list3")}
              >
                투자환불/취소내역
              </div>{" "}
              {/* <div
                className={
                  options == "list3"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                onClick={() => setOptions("list3")}
              >
                임시비밀번호 생성
              </div> */}
            </div>
            {/* <div className={styles.header_btns}>
              <button onClick={() => chgRecommend()}>추천변경</button>
            </div> */}
          </div>
          {userInfo && options == "all" && (
            <AdminUserInfo userInfo={userInfo} />
          )}{" "}
          {options === "list" && <UserInvestList />}{" "}
          {options === "list1" && <UserEndList />}{" "}
          {options === "list2" && <UserInterestList />}{" "}
          {options == "list3" && <UserRefundList />}{" "}
          {/* <div className={styles.user_header}>
            <div className={styles.user_option}>
              <div
                className={
                  options == "all"
                    ? `${styles.all} ${styles.clicked}`
                    : styles.all
                }
                onClick={() => setOptions("all")}
              >
                전체 사용자 &nbsp;
              </div>
              <div
                className={
                  options == "noraml"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                onClick={() => setOptions("noraml")}
              >
                일반 사용자 &nbsp;{" "}
              </div>
              <div
                className={
                  options == "develop"
                    ? `${styles.develop} ${styles.clicked}`
                    : styles.develop
                }
                onClick={() => setOptions("develop")}
              ></div>
              <div
                className={
                  options == "admin"
                    ? `${styles.admin} ${styles.clicked}`
                    : styles.admin
                }
                onClick={() => setOptions("admin")}
              ></div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default AdminUserDetail;
