import styles from "../../../styles/admin.module.css";
import { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import AdminUserInfo from "./AdminUserInfo";
import ToastEditor from "../editor/ToastEditor";
import { textReplace } from "../../../lib/textReplace";
import { FcEditImage } from "react-icons/fc";
import Swal from "sweetalert2";
const NoticeWriteDetail = ({ type }) => {
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  const imgNameRef = useRef(); //이미지 파일 이름 REF
  const [imageFile, setImageFile] = useState(""); //이미지 속성정보
  const [getimage, setGetImage] = useState(""); //render.result 값
  const [openValue, setOpenValue] = useState("Y");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [important, setImportant] = useState("Y");
  const [typed, setType] = useState("1");
  const [data, setData] = useState([]);

  const [previewContent, setPreviewContent] = useState("");
  const changeTitle = useCallback((e) => {
    setTitle(e.target.value);
  }, []);
  const changeTitlePre = useCallback((e) => {
    setPreviewContent(e.target.value);
  }, []);
  const navigate = useNavigate();
  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 1024 * 1;

    if (file[0] === undefined) {
      return;
    }

    // if (
    //   imgExtension[1] !== "jpg" ||
    //   imgExtension[1] !== "png" ||
    //   imgExtension[1] !== "jpeg" ||
    //   imgExtension[1] !== "gif"
    // ) {
    //   Swal.fire({
    //     title: "이미지 업로드 불가",
    //     text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",
    //     icon: "error",
    //     confirmButtonText: "확인",
    //     confirmButtonColor: "#FF0000",
    //   });
    //   return;
    // }
    if (file[0].size >= maxSize) {
      //이미지 사이즈가 500k보다 크다면,
      setImageFile(imageFile);
      Swal.fire({
        title: "이미지 용량 초과",
        html: `1MB 사진을 올려주세요.`,

        confirmButtonText: "확인",
      });
      return;
    } else {
      setImageFile(file);
    }
  };

  useEffect(() => {
    preview(); // 미리보기
  });

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      imageRef.current.src = reader.result;
      setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };

  const [imgArr, setImgArr] = useState([]);
  const btn_notice_reg = () => {
    if (!title || !openValue || !content || !typed) {
      return alert("체크 필수");
    }

    let sendData = {
      title: textReplace(title),
      content: content,
      open: openValue,
      previewContent: textReplace(previewContent),

      filename: "",
      image: "",
      copyImg: "",
      important: important,
      exp: "",
      type: typed,
      // mobile :
    };

    if (imageFile !== "") {
      var file_info = imageFile[0].name.split(".");
      var file_name = `notice_.${file_info[1]}`;
      sendData.filename = file_name;
      sendData.exp = file_info[1];
      sendData.image = `${getimage}file_name:${file_name}file_name:file_name:/userProfile/`;
    }

    let resultImg = [];

    if (imgArr.length > 0) {
      for (let a = 0; a < imgArr.length; a++) {
        if (content.indexOf(imgArr[a]) !== -1) {
          resultImg.push(imgArr[a]);
        }
      }
      sendData.copyImg = resultImg;
    }

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_insert_notice",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          navigate("/admin/notice");
        } else {
          navigate("/admin/notice");
        }
      }
    );
  };
  const change_open = (data) => {
    setOpenValue(data);
  };

  const change_important = (data) => {
    setImportant(data);
  };

  const change_type = (data) => {
    setType(data);
  };
  const { id } = useParams();
  const [updateImg, setUpdateImg] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  useEffect(() => {
    if (type === "update") {
      let sendData = {
        no: id,
      };
      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/admin_select_notice_detail",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
            setData(result.messageinfo.message);
            setTitle(result.messageinfo.message[0].notice_title);
            setPreviewContent(result.messageinfo.message[0].notice_preview);
            // console.log(result.messageinfo.message[0].notice_content);
            setContent(result.messageinfo.message[0].notice_content);
            setOpenValue(result.messageinfo.message[0].notice_open);
            setImportant(result.messageinfo.message[0].notice_important);
            setType(result.messageinfo.message[0].notice_type);

            if (result.messageinfo.message[0].notice_thumbnail) {
              setUpdateImg(
                `/notice/${id}/${result.messageinfo.message[0].notice_thumbnail}`
              );
              setThumbnail(result.messageinfo.message[0].notice_thumbnail);
            }

            if (result.messageinfo.message[0].notice_img) {
              let arr = result.messageinfo.message[0].notice_img.split(",");

              let newArr = [];

              for (let a = 0; a < arr.length; a++) {
                newArr.push(arr[a]);
              }
              setImgArr(newArr);
            }
          } else {
            // navigate("/admin/notice");
          }
        }
      );
    }
  }, []);

  const btn_notice_reg1 = () => {
    if (!title || !openValue || !content || !typed) {
      return alert("체크 필수");
    }

    let sendData = {
      title: textReplace(title),
      content: textReplace(content),
      previewContent: textReplace(previewContent),
      open: openValue,
      no: id,
      filename: "",
      image: "",
      copyImg: "",
      important: important,
      exp: "",
      th: "",
      cancelImg: "",
      type: typed,
      // mobile :
    };

    if (imageFile !== "") {
      var file_info = imageFile[0].name.split(".");
      var file_name = `notice_${id}.${file_info[1]}`;
      sendData.filename = file_name;
      sendData.exp = file_info[1];
      sendData.image = `${getimage}file_name:${file_name}file_name:file_name:/userProfile/`;
    } else {
      sendData.th = "Y";
    }
    let resultImg = [];
    let resultImg2 = [];
    if (imgArr.length > 0) {
      for (let a = 0; a < imgArr.length; a++) {
        if (content.indexOf(imgArr[a]) !== -1) {
          resultImg.push(imgArr[a]);
        } else {
          resultImg2.push(imgArr[a]);
        }
      }
      sendData.copyImg = resultImg;
      sendData.cancelImg = resultImg2;
    }

    // let resultImg = [];

    // if (imgArr.length > 0) {
    //   for (let a = 0; a < imgArr.length; a++) {
    //     if (content.indexOf(imgArr[a]) !== -1) {
    //       resultImg.push(imgArr[a]);
    //     }
    //   }
    //   sendData.copyImg = resultImg;
    // }

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_notice",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          navigate("/admin/notice");
        } else {
          navigate("/admin/notice");
        }
      }
    );
  };

  return (
    <>
      {type === "write" && (
        <div className={styles.notice_write_wrap}>
          <div className={styles.notice_write_inner}>
            <div className={styles.notice_write_title}>
              <h4>공지사항 작성</h4>
              <div className={styles.notice_write_button}>
                <button onClick={btn_notice_reg}>등록</button>
              </div>
            </div>
            <div className={styles.notice_write_content}>
              <div className={styles.notice_write_left}>
                <input
                  className={styles.notice_write_inp}
                  placeholder="제목을 입력해주세요."
                  onChange={changeTitle}
                  value={title}
                  // onChange={onChangeTitle}
                  // value={title}
                />
                <input
                  className={styles.notice_write_inp}
                  placeholder="미리 보이는 본문을 입력하세요"
                  value={previewContent}
                  onChange={changeTitlePre}
                  // onChange={onChangeTitle}
                  // value={title}
                />
                {/* <div className={styles.notice_write_prview_div}>
                  <textarea
                    className={styles.notice_write_prview}
                    placeholder="미리보이는 본문을 입력하세요"
                    value={previewContent}
                    onChange={changeTitlePre}
                  ></textarea>
                  <span>30</span>
                </div> */}

                <ToastEditor
                  setImgArr={setImgArr}
                  imgArr={imgArr}
                  setContent={setContent}
                  content={content}
                />
              </div>{" "}
              <div className={styles.notice_write_right}>
                {" "}
                <div className={styles.notice_file_box}>
                  <h4>대표 이미지 업로드</h4>
                  <div className={styles.notice_img_box}>
                    {imageFile === "" ? (
                      <div>
                        <span className={styles.image_span}>Image</span>
                      </div>
                    ) : (
                      <img src="" ref={imageRef} />
                    )}
                  </div>
                  <label className={styles.notice_file}>
                    <input
                      type="file"
                      className={styles.notice_file_inp}
                      accept="image/*"
                      onChange={inpChange}
                    />
                    이미지 업로드
                  </label>
                </div>{" "}
                <p className={styles.notice_warning}>
                  {" "}
                  사이즈는 134(px) x 100(px) 사이즈로 업로드바랍니다.{" "}
                </p>
                {/* <div className={styles.notice_open_box}>
                  <h4>공개 여부</h4>
                  <label>
                    <input
                      type="radio"
                      name="open"
                      value="Y"
                      onChange={() => change_open("Y")}
                    />{" "}
                    공개
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="open"
                      value="N"
                      onChange={() => change_open("N")}
                    />{" "}
                    비공개
                  </label>
                </div> */}
                {/* <div className={styles.notice_open_box}>
                  <h4>중요 표시</h4>
                  <label>
                    <input
                      type="radio"
                      name="important"
                      value="Y"
                      onChange={() => change_important("Y")}
                    />{" "}
                    중요함
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="important"
                      value="N"
                      onChange={() => change_important("N")}
                    />{" "}
                    중요안함
                  </label>
                </div> */}
                {/* <div className={styles.notice_open_box}>
                  <h4>게시 필드</h4>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="1"
                      onChange={() => change_type(1)}
                      checked={typed === 1 ? true : false}
                    />{" "}
                    공지사항
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="2"
                      onChange={() => change_type(2)}
                      checked={typed === 2 ? true : false}
                    />{" "}
                    보도자료
                  </label>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {type === "update" && (
        <div className={styles.notice_write_wrap}>
          <div className={styles.notice_write_inner}>
            <div className={styles.notice_write_title}>
              <h4>공지사항 수정</h4>
              <div className={styles.notice_write_button}>
                <button onClick={btn_notice_reg1}>수정완료</button>
              </div>
            </div>
            <div className={styles.notice_write_content}>
              <div className={styles.notice_write_left}>
                <input
                  className={styles.notice_write_inp}
                  placeholder="제목을 입력해주세요."
                  onChange={changeTitle}
                  value={title}
                  // onChange={onChangeTitle}
                  // value={title}
                />{" "}
                <input
                  className={styles.notice_write_inp}
                  placeholder="미리 보이는 본문을 입력하세요"
                  value={previewContent}
                  onChange={changeTitlePre}
                  // onChange={onChangeTitle}
                  // value={title}
                />
                {content && (
                  <ToastEditor
                    setImgArr={setImgArr}
                    imgArr={imgArr}
                    setContent={setContent}
                    content={content}
                  />
                )}
              </div>{" "}
              <div className={styles.notice_write_right}>
                {" "}
                <div className={styles.notice_file_box}>
                  <h4>대표 이미지 업로드</h4>
                  <div className={styles.notice_img_box}>
                    {updateImg === "" ? (
                      !imageFile ? (
                        <div>
                          <span className={styles.image_span}> Image</span>
                        </div>
                      ) : (
                        <img src="" ref={imageRef} />
                      )
                    ) : (
                      <img src={updateImg} ref={imageRef} />
                    )}
                  </div>
                  <label className={styles.notice_file}>
                    <input
                      type="file"
                      className={styles.notice_file_inp}
                      accept="image/*"
                      onChange={inpChange}
                    />
                    이미지 업로드
                  </label>
                  {/* <button>썸네일제거</button> */}
                </div>{" "}
                <p className={styles.notice_warning}>
                  {" "}
                  사이즈는 134(px) x 100(px) 사이즈로 업로드바랍니다.{" "}
                </p>
                {/* <div className={styles.notice_open_box}>
                  <h4>공개 여부</h4>
                  <label>
                    <input
                      type="radio"
                      name="open"
                      value="Y"
                      onChange={() => change_open("Y")}
                      checked={openValue === "Y" ? true : false}
                    />{" "}
                    공개
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="open"
                      value="N"
                      onChange={() => change_open("N")}
                      checked={openValue === "N" ? true : false}
                    />{" "}
                    비공개
                  </label>
                </div>
                <div className={styles.notice_open_box}>
                  <h4>중요 표시</h4>
                  <label>
                    <input
                      type="radio"
                      name="important"
                      value="Y"
                      onChange={() => change_important("Y")}
                      checked={important === "Y" ? true : false}
                    />{" "}
                    중요함
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="important"
                      value="N"
                      onChange={() => change_important("N")}
                      checked={important === "N" ? true : false}
                    />{" "}
                    중요안함
                  </label>
                </div>{" "} */}
                {/* <div className={styles.notice_open_box}>
                  <h4>게시 필드</h4>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="1"
                      onChange={() => change_type(1)}
                      checked={typed === 1 ? true : false}
                    />{" "}
                    공지사항
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="2"
                      onChange={() => change_type(2)}
                      checked={typed === 2 ? true : false}
                    />{" "}
                    보도자료
                  </label>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NoticeWriteDetail;
