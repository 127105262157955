import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import CustomTable from "./CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "../CommonAxios";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/admin.module.css";
import { Link, useNavigate } from "react-router-dom";
import InvestCustomTable from "./table/InvestCustomTable";
import { wonComma } from "../../lib/wonComma";
import { numberToKorean } from "../../lib/numberToKorean";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { FaCalendar } from "react-icons/fa";
import { transMonth } from "../../lib/date/TransDate";
import AnalyzeUserCustomTable from "./table/AnalyzeUserCustomTable";
import { textReplace } from "../../lib/textReplace";
const UserAnalyzeList = () => {
  const TableColumn = [
    {
      Header: "이메일",
      accessor: "user_email",
    },
    {
      Header: "이름",
      accessor: "user_name",
    },

    {
      Header: "성별",
      accessor: "user_gender",
      Cell: ({ cell: { value } }) => (
        <>{value ? (value === 1 ? "남성" : "여성") : "미입력"}</>
      ),
    },
    {
      Header: "연령대",
      accessor: "user_age",
      Cell: ({ cell: { value } }) => <>{value ? value + "대" : "미입력"}</>,
    },
    {
      Header: "지역",
      accessor: "user_place",
      Cell: ({ cell: { value } }) => <>{value ? value : "미입력"}</>,
    },
    {
      Header: "투자건수",
      accessor: "cnt",
      Cell: ({ cell: { value } }) => <>{value ? value + "건" : "0건"}</>,
    },
    {
      Header: "투자금액",
      accessor: "amount",
      Cell: ({ cell: { value } }) => (
        <>{value ? wonComma(numberToKorean(value)) + "원" : "0원"}</>
      ),
    },
  ];
  // const Date = ({ value }) => {
  //   if (value) {
  //     return <Moment format="YYYY.MM.DD.">{value}</Moment>;
  //   } else {
  //     return <>수정기록없음</>;
  //   }
  // };

  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState("all");
  useEffect(() => {
    let sendData = {
      end: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_analyzeUser",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
  }, []);
  const filters = [
    {
      id: 1,
      text: "이번달",
      name: "no1",
    },
    {
      id: 2,
      text: "2개월",
      name: "no2",
    },
    {
      id: 3,
      text: "3개월",
      name: "no3",
    },
    {
      id: 4,
      text: "6개월",
      name: "no4",
    },
    {
      id: 5,
      text: "1년",
      name: "no5",
    },
    {
      id: 6,
      text: "전체",
      name: "no6",
    },
  ];
  const [btnName, setBtnName] = useState("no1");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startDateDB, setStartDateDB] = useState();
  const [endDateDB, setEndDateDB] = useState();

  const transDate = (props) => {
    let text = props + "";
    let data = text.split(" ");
    let year = data[3];
    let month = transMonth(data[1]);
    let dayNumber = data[2];

    data = year + "-" + month + "-" + dayNumber;
    return data;
  };

  const onClickButton = (e) => {
    const { name } = e.target;
    setBtnName(name);
    const today = new Date();
    let data;
    switch (name) {
      case "no1":
        setStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        return;
      case "no2":
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 1, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        return;
      case "no3":
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 2, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        return;
      case "no4":
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 5, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        return;
      case "no5":
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 11, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        return;
      case "no6":
        setStartDate("");
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        return;
    }
  };
  const [gender, setGender] = useState("all");
  const [age, setAge] = useState("all");
  const [place, setPlace] = useState("all");
  const [invest, setInvest] = useState("all");
  const [name, setName] = useState("");
  const change_gender = (e) => {
    setGender(e.target.value);
  };
  const change_age = (e) => {
    setAge(e.target.value);
  };
  const change_place = (e) => {
    setPlace(e.target.value);
  };
  const change_invest = (e) => {
    setInvest(e.target.value);
  };
  const change_name = useCallback((e) => {
    setName(e.target.value);
  }, []);
  const btn_search = () => {
    let investArr = [
      [1, 5],
      [6, 10],
      [11, 15],
      [16, 20],
      [21, 25],
      [26, 30],
      [31, 35],
      [36, 40],
      [41, 45],
      [46, 50],
      [51],
    ];
    let sendData = {
      start: startDate ? moment(startDate).format("YYYY-MM-DD 00:00:00") : "",
      end: moment(endDate).format("YYYY-MM-DD 23:59:59"),
      user_gender: gender === "all" ? "" : gender,
      user_age: age === "all" ? "" : age,
      user_place: place === "all" ? "" : place,
      cnt1:
        invest === "all"
          ? ""
          : invest === "11"
          ? investArr[Number(invest) - 1][0]
          : investArr[Number(invest) - 1][0],
      cnt2:
        invest === "all"
          ? ""
          : invest === "11"
          ? ""
          : investArr[Number(invest) - 1][1],

      name: textReplace(name),
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_analyzeUser_search",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
  };
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          회원관리 &nbsp;〉 &nbsp;회원 분석{" "}
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.analyzeUser_search_wrap}>
            <div className={styles.analyzeUser_search_inner}>
              <div className={styles.analyzeUser_search_box}>
                <div className={styles.analyze_search}>
                  <div className={`${styles.analyze_search_top}`}>
                    <span className={styles.analyze_search_text}>
                      {" "}
                      기간검색
                    </span>

                    <div className={styles.price_box}>
                      <div>
                        <span className={styles.datepicker}>
                          <span>
                            <DatePicker
                              dateFormat="yyyy-MM-dd"
                              locale={ko}
                              onChange={(date) => setStartDate(date)}
                              maxDate={endDate}
                              selected={startDate}
                              startDate={startDate}
                              endDate={endDate}
                              placeholderText={"적용시작일"}
                            />
                          </span>
                          <span className={styles.datepicker_ico}>
                            <FaCalendar />
                          </span>
                        </span>
                        <span>~</span>
                        <span className={styles.datepicker}>
                          <span>
                            <DatePicker
                              dateFormat="yyyy-MM-dd"
                              locale={ko}
                              onChange={(date) => setEndDate(date)}
                              selected={endDate}
                              maxDate={new Date()}
                              startDate={endDate}
                              endDate={endDate}
                              placeholderText={"적용종료일"}
                            />
                          </span>
                          <span className={styles.datepicker_ico}>
                            <FaCalendar />
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className={styles.filter_box}>
                      {filters.map((filter) => {
                        return (
                          <>
                            <button
                              className={
                                btnName === filter.name
                                  ? `${styles.filter_text} ${styles.filter_active}`
                                  : `${styles.filter_text}`
                              }
                              type="button"
                              onClick={onClickButton}
                              name={filter.name}
                              key={filter.id}
                            >
                              {filter.text}
                            </button>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className={styles.analyze_search}>
                  <div className={`${styles.analyze_search_middle}`}>
                    <div className={styles.analyze_search_field}>
                      <label className={styles.analyze_search_text}>성별</label>
                      <div className={styles.analyze_search_select}>
                        <select onChange={change_gender} value={gender}>
                          {" "}
                          <option value="all">전체</option>
                          <option value="1">남성</option>
                          <option value="2">여성</option>
                        </select>
                      </div>
                    </div>{" "}
                    <div className={styles.analyze_search_field}>
                      <label className={styles.analyze_search_text}>
                        연령대
                      </label>
                      <div className={styles.analyze_search_select}>
                        <select onChange={change_age} value={age}>
                          <option value="all">전체</option>
                          <option value="20">20대</option>
                          <option value="30">30대</option>
                          <option value="40">40대</option>
                          <option value="50">50대</option>
                          <option value="60">60대</option>
                          <option value="70">70대</option>
                          <option value="80">80대</option>
                        </select>
                      </div>
                    </div>{" "}
                    <div className={styles.analyze_search_field}>
                      <label className={styles.analyze_search_text}>지역</label>
                      <div className={styles.analyze_search_select}>
                        <select onChange={change_place} value={place}>
                          <option value="all">전체</option>
                          <option value="서울">서울</option>
                          <option value="인천">인천</option>
                          <option value="대구">대구</option>
                          <option value="부산">부산</option>
                          <option value="대전">대전</option>
                          <option value="울산">울산</option>
                          <option value="광주">광주</option>
                          <option value="제주도">제주도</option>
                          <option value="경기도">경기도</option>
                          <option value="강원도">강원도</option>
                          <option value="충청북도">충청북도</option>
                          <option value="충청남도">충청남도</option>
                          <option value="경상북도">경상북도</option>
                          <option value="경상남도">경상남도</option>
                          <option value="전라북도">전라북도</option>
                          <option value="전라남도">전라남도</option>
                          <option value="기타">기타</option>
                        </select>
                      </div>
                    </div>{" "}
                    <div className={styles.analyze_search_field}>
                      <label className={styles.analyze_search_text}>
                        투자건수
                      </label>
                      <div className={styles.analyze_search_select}>
                        <select onChange={change_invest} value={invest}>
                          <option value="all">전체</option>
                          <option value="1">1~5건</option>
                          <option value="2">6~10건</option>
                          <option value="3">11~15건</option>
                          <option value="4">16~20건</option>
                          <option value="5">21~25건</option>
                          <option value="6">26~30건</option>
                          <option value="7">31~35건</option>
                          <option value="8">36~40건</option>
                          <option value="9">41~45건</option>
                          <option value="10">46~50건</option>{" "}
                          <option value="11">51건 이상</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <div className={styles.analyze_search}>
                  <div className={`${styles.analyze_search_bottom}`}>
                    <div className={styles.analyze_search_field}>
                      <label className={styles.analyze_search_text}>
                        이메일/이름
                      </label>
                      <div className={styles.analyze_searh_inp}>
                        <input
                          type="text"
                          onChange={change_name}
                          value={name}
                        />
                      </div>
                    </div>
                    {/* <div className={styles.analyze_search_field}>
                      <label className={styles.analyze_search_label}>
                        <input type="checkbox" /> 투자건수 검색 포함
                      </label>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.analyze_search_down}>
            <button onClick={btn_search}>검색</button>
          </div>
          <div className={styles.analyzeUser_list_wrap}>
            {" "}
            <div className={styles.analyzeUser_list_inner}>
              <AnalyzeUserCustomTable
                columns={TableColumn}
                data={arrayList}
                options={options}
                setOptions={setOptions}
                arrayList={arrayList}
              />
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default UserAnalyzeList;
