import styles from "../../styles/mypage.module.css";
import { FaUserCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { FcCloseUpMode } from "react-icons/fc";
import { BsCameraFill } from "react-icons/bs";
import pro from "../../assets/images/profile_info.png";
import { useEffect } from "react";
import { textOverCut, getTextTotal } from "../../lib/stringLenghts";
import { useRef, useState, useCallback } from "react";
import { SuccessAlert, MoveAlert, WarringAlert } from "../alert/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { getUsers } from "../../modules/user";
import MobileModalConfirm from "./detail/MobileModalConfirm";
import { textReplace } from "../../lib/textReplace";
import Swal from "sweetalert2";
import { HiSpeakerphone } from "react-icons/hi";
import { BsExclamationLg } from "react-icons/bs";
import { PiNumberOneFill, PiNumberTwoFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
const MypageDeleteDetail = () => {
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const navigate = useNavigate();

  const [ch1, setCh1] = useState(false);
  const [ch2, setCh2] = useState(false);
  const [ch3, setCh3] = useState(false);
  const [ch4, setCh4] = useState(false);
  const [ch5, setCh5] = useState(false);
  const [ch6, setCh6] = useState(false);
  const [ch7, setCh7] = useState(false);
  const [text, setText] = useState("");
  const [pass, setPass] = useState("");
  const changeReson = (chf) => {
    // alert(chf);
    setCh1(chf.value);
    const checkboxes = document.getElementsByName("ch");
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i] !== chf) {
        checkboxes[i].checked = false;
      }
    }
    if (ch1 !== "4") {
      setText("");
    }

    // setCh1(e.target.value);
    // if (dataNum === 1) {
    //   setCh1(!ch1);
    // }
    // if (dataNum === 2) {
    //   setCh2(!ch2);
    // }
    // if (dataNum === 3) {
    //   setCh3(!ch3);
    // }
    // if (dataNum === 4) {
    //   setCh4(!ch4);
    // }
    // if (dataNum === 5) {
    //   setCh5(!ch5);
    // }
    // if (dataNum === 6) {
    //   setCh6(!ch6);
    // }
    // if (dataNum === 7) {
    //   setCh7(!ch7);
    // }
  };
  const onChangeText = useCallback((e) => {
    setText(e.target.value);
  }, []);

  const [checkYn, setCheckYn] = useState(false);
  const onChangeCheck = () => {
    setCheckYn(!checkYn);
  };
  const onChangePass = useCallback((e) => {
    setPass(e.target.value);
  }, []);
  const btn_delete = () => {
    // alert("개발 진행중");

    // return;
    Swal.fire({
      title: "탈퇴를 하시겠습니까?",
      text: "회원탈퇴 안내 내용 확인해주세요.",

      confirmButtonText: "확인",
      cancelButtonText: "취소",
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        if (!ch1) {
          return Swal.fire({
            title: "탈퇴 불가",

            text: "탈퇴사유를 한가지 선택해주세요.",

            confirmButtonText: "확인",
          });
        }
        if (ch1 === "4" && text === "") {
          return Swal.fire({
            title: "탈퇴 불가",

            text: "기타 사유를 입력해주세요.",

            confirmButtonText: "확인",
          });
        }
        if (
          user.ur_type !== "kakao" &&
          user.ur_type !== "naver" &&
          pass === ""
        ) {
          return Swal.fire({
            title: "탈퇴 불가",

            text: "비밀번호를 입력해주세요.",

            confirmButtonText: "확인",
          });
        }

        if (!checkYn) {
          return Swal.fire({
            title: "탈퇴 불가",

            text: "안내사항 확인 동의를 체크해주세요.",

            confirmButtonText: "확인",
          });
        }

        let sendData = {
          reson: ch1,
          text: text === "" ? "" : textReplace(text),
          password: "",
          mode: "",
          user_id: user.no,
        };
        if (user.ur_type !== "kakao" && user.ur_type !== "naver") {
          sendData.password = pass;
          sendData.mode = "일반";
        }
        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/user_delete_apply",
          sendData,
          function (result) {
            if (result.messageinfo.state === "ok") {
              if (result.messageinfo.message === "실패") {
                return Swal.fire({
                  title: "탈퇴 불가",

                  text: "비밀번호가 틀립니다.",

                  confirmButtonText: "확인",
                });
              }
              if (result.messageinfo.message === "쿠키가 삭제되었습니다.") {
                localStorage.removeItem("meta_info");
                Swal.fire({
                  title: "탈퇴 완료",

                  text: "그동안 멘타12를 이용해주셔서 감사합니다",

                  confirmButtonText: "확인",
                });
                document.location.href = "/login";
              }
            }
          }
        );
      }
    });
  };

  const timeR = useRef(180); // 타이머 시작
  const [mailNum, setMailNum] = useState(""); //메일 인증 번호
  const EmailConfirm = ({ timeR }) => {
    const [min, setMin] = useState(3);
    const [sec, setSec] = useState(0);
    const timerId = useRef(null);
    useEffect(() => {
      timerId.current = setInterval(() => {
        setMin(parseInt(timeR.current / 60));
        setSec(timeR.current % 60);

        timeR.current -= 1;
      }, 1000);
      return () => clearInterval(timerId.current);
    }, [timeR.current]);

    useEffect(() => {
      if (timeR.current < 0) {
        clearInterval(timerId.current);
        setMailNum("");
      }
    }, [sec, timeR.current]);

    return (
      <>
        {" "}
        <p>
          {/* 가입하신 계정 이메일 :  */}
          <div className={styles.delete_email_inp}>
            <input type="text" />

            <span></span>
          </div>
          <button>인증확인</button>
        </p>
      </>
    );
  };

  const sendEmail = () => {
    // setEmailBtn(true);
    // setBtnState(true);
    // // e.preventdefault();
    const sendData = {
      email: user.ur_email,
    };
    // setConfirmView(true);
    // setConfirmValueTest(true);
    timeR.current = 180;
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/mail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setMailNum(result.messageinfo.message);
        } else {
          return Swal.fire({
            title: "이메일 발송 오류",
            text: "고객센터에 문의해주세요.",
            icon: "error",
            confirmButtonColor: "#FF0000",
          });
        }
      }
    );
  };

  return (
    <>
      <div className={`${styles.mypage_content_right} ${styles.right_profile}`}>
        <>
          <h3 className={styles.mypage_content_title}>회원 탈퇴</h3>

          <div
            className={`${styles.mypage_content_inner} ${styles.profile_wrap}`}
          >
            <div className={styles.my_delete_section_2}>
              <div className={styles.delete_section2_wrap}>
                <div className={styles.mypage_delete_top}>
                  <h5>
                    <HiSpeakerphone /> 메타 12 탈퇴 안내 사항
                  </h5>
                  <div className={styles.delete_top_box}>
                    <p>- 탈퇴 후 개인정보는 1년 보관됩니다.</p>
                    <p>- 탈퇴 후 개인정보 외에 모든 기록은 삭제됩니다.</p>
                    <p>
                      - 탈퇴 후 진행중인 상품에 대해 환불이 어려우니, 투자 진행
                      상품이 있는지 확인바랍니다.
                    </p>
                  </div>
                </div>
                <div className={styles.mypage_delete_bottom}>
                  <div className={styles.delete_bottom_box}>
                    <h5>
                      <PiNumberOneFill /> 탈퇴 사유
                    </h5>{" "}
                    <div className={styles.delete_reson}>
                      <ul>
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              name="ch"
                              value="1"
                              onClick={(e) => changeReson(e.target)}
                            />
                            고객 서비스의 불만
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              name="ch"
                              value="2"
                              onClick={(e) => changeReson(e.target)}
                            />
                            방문 빈도가 낮음
                          </label>
                        </li>

                        <li>
                          <label>
                            <input
                              type="checkbox"
                              name="ch"
                              value="3"
                              onClick={(e) => changeReson(e.target)}
                            />
                            개인 정보유출 우려
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              name="ch"
                              value="4"
                              onClick={(e) => changeReson(e.target)}
                            />
                            기타
                          </label>
                          {ch1 === "4" && (
                            <div className={styles.delete_reson_inp}>
                              <span>사유: &nbsp;&nbsp;</span>
                              <input
                                type="text"
                                onChange={onChangeText}
                                value={text}
                              />
                            </div>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>{" "}
                  {/* <div className={styles.delete_bottom_box}>
                    <h5>
                      <PiNumberTwoFill /> 이메일 본인 확인
                    </h5>{" "}
                    <div className={styles.delete_confirm}>
                      <p>
                        <span>{user.ur_email}</span> <button>인증하기</button>
                      </p>{" "}
                      <EmailConfirm timeR={timeR} setMailNum={setMailNum} />
                    </div>
                  </div> */}
                  <div className={styles.delete_bottom_box}>
                    <h5>
                      <PiNumberTwoFill />
                      계정 비밀번호 입력
                    </h5>{" "}
                    <div className={styles.delete_confirm}>
                      <p>
                        <input
                          type="password"
                          onChange={onChangePass}
                          value={pass}
                        />
                        {/* <span>{user.ur_email}</span> <button>인증하기</button> */}
                      </p>{" "}
                      {/* <EmailConfirm timeR={timeR} setMailNum={setMailNum} /> */}
                    </div>
                  </div>
                </div>
                <div className={styles.mypage_delete_check}>
                  <label>
                    <input
                      type="checkbox"
                      onChange={onChangeCheck}
                      checked={checkYn}
                    />{" "}
                    위 안내사항을 확인하였으며, 이에 동의합니다.
                  </label>
                </div>
                <div className={styles.mypage_delete_button}>
                  <button onClick={() => navigate(-1)}>이전으로</button>
                  <button onClick={btn_delete}>탈퇴하기</button>
                </div>
                {/* <div className={styles.delete_user_1}>
                  <div className={styles.delete_user_title}>
                    탈퇴사유
                    <br />
                  </div>
                  <div className={styles.delete_user_content}>
                    <ul>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            onClick={() => changeReson(3)}
                          />
                          홈페이지 신뢰도 불만
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            onClick={() => changeReson(4)}
                          />
                          방문 빈도가 낮음
                        </label>
                      </li>

                      <li>
                        <label>
                          <input
                            type="checkbox"
                            onClick={() => changeReson(6)}
                          />
                          개인 정보유출 우려
                        </label>
                      </li>
                      <li>
                        <label>
                          <input
                            type="checkbox"
                            onClick={() => changeReson(7)}
                          />
                          기타
                        </label>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* <div className={styles.delete_user_2}>
                  <div className={styles.delete_user_title}>남기실 말씀</div>
                  <div className={styles.delete_user_content}>
                    <textarea onChange={onChangeText} value={text}></textarea>
                  </div>
                </div> */}
                {/* {user.ur_type !== "kakao" && user.ur_type !== "naver" ? (
                  <div className={styles.delete_user_3}>
                    <div className={styles.delete_user_title}>비밀번호</div>
                    <div className={styles.delete_user_content}>
                      <input
                        type="password"
                        onChange={onChangePass}
                        value={pass}
                        placeholder="비밀번호를 입력해주세요."
                      />
                    </div>
                  </div>
                ) : null} */}
              </div>

              <div className={styles.btn_box}>
                {/* <button onClick={btn_save}>저장</button> */}
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};
const BtnMobileSend = styled.button`
  cursor: ${(props) => (props.mobileBtn ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.mobileBtn ? "#93c8ec" : "#3498db")};
`;
const BtnValueSend = styled.button`
  cursor: ${(props) =>
    props.min === 0 && props.sec === 0
      ? "not-allowed"
      : props.mobileConfirmTest === true
      ? "not-allowed"
      : "pointer"};
  background-color: ${(props) =>
    props.min === 0 && props.sec === 0
      ? "#ebebeb"
      : props.mobileConfirmTest === true
      ? "#ebebeb"
      : "#d6d6d6"};
`;
export default MypageDeleteDetail;
