import style from "../styles/wadiz.module.css";
import { useEffect } from "react";
const Wadiz3 = () => {
  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "instant" });
  // }, []);
  const scrollEvt = (data) => {
    const targetNo1 = document.getElementById("target_no1");
    const targetNo2 = document.getElementById("target_no2");
    const targetNo3 = document.getElementById("target_no3");
    const targetNo4 = document.getElementById("target_no4");
    const targetNo5 = document.getElementById("target_no5");
    const targetNo6 = document.getElementById("target_no6");
    const targetNo7 = document.getElementById("target_no7");
    const targetNo8 = document.getElementById("target_no8");
    const targetNo9 = document.getElementById("target_no9");
    const targetNo10 = document.getElementById("target_no10");
    const targetNo11 = document.getElementById("target_no11");
    const targetNo12 = document.getElementById("target_no12");
    const targetNo13 = document.getElementById("target_no13");
    const targetNo14 = document.getElementById("target_no14");

    if (data == "no1" && targetNo1) {
      targetNo1.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no2" && targetNo2) {
      targetNo2.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no3" && targetNo3) {
      targetNo3.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no4" && targetNo4) {
      targetNo4.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no5" && targetNo5) {
      targetNo5.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no6" && targetNo6) {
      targetNo6.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no7" && targetNo7) {
      targetNo7.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no8" && targetNo8) {
      targetNo8.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no9" && targetNo9) {
      targetNo9.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no10" && targetNo10) {
      targetNo10.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no11" && targetNo11) {
      targetNo11.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no12" && targetNo12) {
      targetNo12.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no13" && targetNo13) {
      targetNo13.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no14" && targetNo14) {
      targetNo14.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  return (
    <div className={`common-wrap`} style={{ borderTop: "1px solid #b6b6b6b5" }}>
      {" "}
      <div className="common-inner-wrap">
        <div className={style.policy_wrap}>
          <h1>개인정보처리방침</h1>
          <p>아시아로(주)는 이용자들의 개인정보를 소중히 다루고 있습니다.</p>
          <div className={style.policy_update_div}>
            <h5>업데이트 노트</h5>
            <ul>
              {/* <li>본 이용약관은 2023년 8월 1일부터 시행됩니다.</li> */}
              {/* <li>시행 2023.08.01</li> */}
            </ul>
          </div>
          <ol className={style.policy_subtitle}>
            <li onClick={() => scrollEvt("no1")}>
              1. 개인정보처리방침의 기본방향
            </li>
            <li onClick={() => scrollEvt("no2")}>2. 목차</li>
            <li onClick={() => scrollEvt("no3")}>
              3. 이용자 및 법정대리인의 권리 및 행사방법
            </li>
            <li onClick={() => scrollEvt("no4")}>
              4. 개인정보 보호책임자 성명 및 부서
            </li>
            <li onClick={() => scrollEvt("no5")}>
              5. 개인정보의 자동수집 장치 설치·운영거부 사항
            </li>
            <li onClick={() => scrollEvt("no6")}>
              6. 개인정보 수집·이용 목적·수집하는 개인정보 항목 및 수집 방법
            </li>
            <li onClick={() => scrollEvt("no7")}>7. 제3자 제공 현황</li>
            <li onClick={() => scrollEvt("no8")}>
              8. 개인정보의 보유 및 이용기간, 파기 절차 및 파기 방법
            </li>
            <li onClick={() => scrollEvt("no9")}>
              9. 장기 미이용 회원 개인정보 분리 보관
            </li>
            <li onClick={() => scrollEvt("no10")}>10. 권익침해 구제방법</li>
            <li onClick={() => scrollEvt("no11")}>
              11. 수집한 개인정보 처리 위탁
            </li>
            <li onClick={() => scrollEvt("no12")}>
              12. 개인정보 보호에 대한 기술적 관리적 대책
            </li>
            <li onClick={() => scrollEvt("no13")}>13. 광고성 정보전송</li>
            <li onClick={() => scrollEvt("no14")}>
              14. 개인정보처리방침의 변경
            </li>
          </ol>
          <hr />

          <h3 id="target_no1">1. 개인정보처리방침의 기본방향</h3>
          <p>
            아시아로(주)(이하 "회사")는 서비스 이용을 위해 회원이 제공하신 개인
            정보를 매우 중요하게 여기고, 개인정보처리방침을 수립하여 이를 엄격히
            준수하고 있습니다. 회사 의 개인정보처리방침은 ‘개인정보보호법’ 및
            관련 법령을 준수하고 있으며, 개인정보가 유출되어 피해를 입지 않도록
            개인정보 보호에 최선을 다할 것입니다. 회원 여러분은 언제든지 이
            처리방침을 열람하여 확인하실 수 있으며, 개인정보처리방침은 법령 및
            정부지침의 변경과 보다 나은 서비스 제공을 위해서 그 내용이 변경될 수
            있습니다.
          </p>
          <h3 id="target_no2">2. 목차</h3>
          <p>회사의 개인정보처리방침은 다음과 같은 내용을 담고 있습니다.</p>

          <ul>
            <li>이용자 및 법정대리인의 권리 및 행사방법</li>
            <li>개인정보 보호책임자 성명 및 부서</li>
            <li>개인정보의 자동수집 장치 설치・운영거부 사항</li>
            <li>개인정보 수집·이용 목적·수집하는 개인정보 항목 및 수집 방법</li>
            <li>제3자 제공 현황</li>
            <li>개인정보의 보유 및 이용기간, 파기 절차 및 파기 방법</li>{" "}
            <li>장기 미이용 회원 개인정보 분리 보관</li>
            <li>권익침해 구제방법</li>
            <li>수집한 개인정보 처리 위탁</li>
            <li>개인정보 보호에 대한 기술적 관리적 대책</li>
            <li>광고성 정보전송</li>
            <li>개인정보처리방침의 변경</li>
          </ul>

          <h3 id="target_no3">이용자 및 법정대리인의 권리 및 행사방법</h3>
          <ol>
            <li>
              이용자는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련
              권리를 행 사할 수 있습니다.
              <ul>
                <li>개인정보 열람요구</li>
                <li>오류 등이 있을 경우 정정 요구</li>
                <li>삭제요구</li>
              </ul>
            </li>
            <li>처리정지 요구</li>
            <ul>
              <li>
                제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편,
                모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해
                지체없이 조치하겠습니다.
              </li>
              <li>
                이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
                경우에는 회사 는 정정 또는 삭제를 완료할 때까지 당해 개인정보를
                이용하거나 제공하지 않 습니다.
              </li>
              <li>
                이용자는 정보통신망법, 개인정보보호법 등 관계법령을 위반하여
                회사가 처리 하고 있는 이용자 본인이나 타인의 개인정보 및
                사생활을 침해하여서는 아니 됩니다.
              </li>
            </ul>
          </ol>

          <h3 id="target_no4">4. 개인정보 보호책임자 성명 및 부서</h3>
          <p>
            회사는 개인정보에 대한 의견수렴 및 불만처리를 위해 개인정보
            보호책임자가 지정돼 있습니다.
          </p>

          <h3 id="target_no5">
            5. 개인정보의 자동수집 장치 설치·운영거부 사항
          </h3>
          <ol>
            <li>
              회사는 회원에 대한 정보를 저장하고, 수시로 찾아내는
              “쿠키(Cookie)"를 사용 합니다. 쿠키는 홈페이지가 회원의 컴퓨터
              브라우저로 전송하는 소량의 정보입 니다. 회원께서 홈페이지에 접속을
              하면 본 회사의 컴퓨터는 회원의 브라우저 에 있는 쿠키의 내용을
              읽고, 회원의 추가정보를 회원의 컴퓨터에서 찾아 접속 에 따른 성명,
              홈페이지 주소 등의 추가입력 없이 서비스를 제공할 수 있도록 하고
              있습니다.
            </li>
            <li>
              쿠키는 회원의 컴퓨터는 식별하지만 회원을 개인적으로 식별하지는
              않습니다. 또한 회원께서는 웹브라우저의 옵션을 조정함으로써 모든
              쿠키를 다 받아들이 거나, 쿠키가 설치될 때 통지를 보내도록 하거나,
              아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.
            </li>
            <li>
              웹브라우저의 설정을 통해 쿠키 저장을 거부할 수 있습니다. (단, 쿠키
              저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
              있습니다)
            </li>{" "}
            <ul>
              <li>
                Internet Explorer 의 경우 웹 브라우저 상단의 “도구” 메뉴 {">"}
                “인터넷 옵션” 메 뉴 {">"} “개인정보” 탭 {">"} 직접 설정
              </li>
              <li>
                Mircosoft Edge의 경우 웹 브라우저 우측 상단의 “…” {">"} “설정”{" "}
                {">"}
                좌측 상단의 “≡” {">"}“개인정보, 검색 및 서비스”{">"}{" "}
                「추적방지」 섹션에서 ‘추적방지’ 여부 및 수준 선택,
                「개인정보」섹션에서 ‘추적 안함 요청 보내기’ 여부 선택
              </li>
              <li>
                Chrome의 경우 웹 브라우저 우측 상단의 아이콘 “선택” {">"} “설정"
                선택{">"} 화면 하단의 “고급 설정 표시” 선택 {">"} 개인정보
                섹션의 “콘텐츠 설정" 버튼 {">"} 쿠키 섹션에서 직접 설정
              </li>
            </ul>
          </ol>

          <h3 id="target_no6">
            6. 개인정보 수집·이용 목적,수집하는 개인정보 항목 및 수집 방법
          </h3>
          <ol>
            <li>수집·이용 목적</li>{" "}
            <ul>
              <li>
                홈페이지 회원관리: 회원제 서비스 이용 등에 따른 본인확인,
                개인식별, 불량회 원의 부정 이용방지와 비인가 사용방지, 가입의사
                확인, 가입 및 가입횟수 제 한, 고지사항 전달, 상담처리 등
              </li>
              <li>회사가 제공하는 서비스 이용</li>
              <li>설문조사와 이벤트를 위한 이용</li>{" "}
              <li>
                컨텐츠 및 회원 맞춤형 서비스 제공, 서비스 구매 및 요금결제,
                금융거래 본인 인증 및 금융 서비스, 상품 주문에 따른 배송 서비스
              </li>
              <li>
                신규서비스(콘텐츠) 개발 및 특화, 이벤트 등 광고성 정보 전달,
                통계학적 특성 에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악,
                회원의 서비스 이용에 대한 통계
              </li>
              <li>금융사고 조사, 기타 법령상 의무이행 등</li>
            </ul>
            <li>수집 항목</li>
            <ul>
              <li>
                회사는 서비스 가입 및 이용 등을 위해 아래와 같은 개인정보를
                수집합니다.
              </li>
              <li>
                모바일 기기의 카메라를 활용한 동영상 촬영 시, 서비스 제공을 위해
                이용자의 동영상(또는 갤러리)에 접근할 수는 있지만, 이용자의
                동영상을 복사하거나 서 버로 전송하지 않습니다.
              </li>
              <li>
                회사는 회원의 기본적인 인권 침해 우려가 있는 민감한 개인정보는
                수집하지 않음. 다만 이용자의 적법한 동의가 있거나 법령의 규정에
                의한 경우는 예외
              </li>
            </ul>
            <li>수집방법</li>
            <ul>
              <li>
                회사는 적법하고 공정한 절차와 이용자의 동의를 받은 개인정보를
                수집
              </li>
              <li>
                홈페이지 / 모바일앱을 통한 회원 가입 및 변경, 문의 메일, 전화
                상담 등
              </li>
            </ul>
            <li>
              회원이 제공한 개인정보는 당해 회원의 동의 없이는 회사가 이미 고지
              또는 명시한 목적범위 이외로 이용하거나 제3자에게 제공할 수
              없습니다. 다만, 아 래의 경우에는 예외로 합니다.
            </li>
            <ul>
              <li>
                수사를 목적으로 법령에 정해진 절차와 방법에 따라 수사기관 등의
                요구가 있 는 경우
              </li>
              <li>
                금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에
                관한 법률, 전기통신기본법, 전기통신사업법, 지방세법,
                소비자보호법, 한국은행법, 형사소송 법 등 법령에 특별한 규정이
                있는 경우
              </li>
              <li>
                법령의 규정에 근거하여 통계목적, 학술연구, 시장조사 등에 특정
                개인을 식별 할 수 없는 형태로 개인정보를 제공하는 경우
              </li>
            </ul>
          </ol>

          <h3 id="target_no7">7. 제3자 제공 현황</h3>
          <p>
            회사는 제3자 서비스와의 연결을 위해서 이용자의 동의를 받아 다음과
            같이 개인정보 를 제공하고 있습니다.
          </p>
          <ol>
            <li>회사가 제3자에게 제공하는 이용자 개인정보</li>
            <ul>
              <li>개인정보 제3자 제공 현황 자세히 보기 {">"}</li>
            </ul>
            <li>동의없이 제공하는 예외 사항</li>
            <ul>
              <li>
                {" "}
                관련 통계법 등에 따라 통계작성·학술연구 또는 시장조사를 위하여
                필요한 경 우로서 특정 개인을 알아볼 수 없는 형태로 가공하여
                제공하는 경우
              </li>
              <li>
                {" "}
                금융실명거래및비밀보장에관한법률,
                신용정보의이용및보호에관한법률, 전기통신 기본법, 전기통신사업법,
                지방세법, 소비자보호법, 한국은행법, 형사소송법 등 법 률에 특별한
                규정이 있는 경우
              </li>
              <li>
                {" "}
                정부기관(준정부기관 포함)이나 법원의 명령에 의하여 개인정보를
                제공하는 경 우
              </li>
              <li> 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우</li>
            </ul>
          </ol>
          <p>
            회사는 예외 사항의 경우에도 정보 제공 내용을 이용자에게 고지하는
            것을 원칙으로 하며 본래 수집과 이용 목적에 반하여 무분별하게
            제공되지 않도록 최대한 노력하겠 습니다.
          </p>
          <h3 id="target_no8">
            8. 개인정보의 보유 및 이용기간, 파기 절차 및 파기 방법
          </h3>
          <p>
            이용자는 회원가입시 개인정보의 수집·이용 및 제공에 대해 동의한
            내용은 언제든지 회원탈퇴 등의 방법을 통해 철회하실 수 있습니다.
            또한, 수집 및 이용목적이 달성되 거나 동의를 얻은 개인정보의
            보유·이용 기간이 종료한 경우 지체 없이 파기하는 것 을 원칙으로
            합니다. 다만, ‘개인정보보호법’ 및 관련 법령에 따라 개인정보를
            보존해야 하는 경우에는 그러하지 아니하며, 귀하가 회원가입 등을 위해
            입력하신 정보는 내부 해당 내용 관련 법 조항 보존 기간 표시/광고에
            관한 기록 전자상거래등에서의 소 비자보호에 관한 법률 6개월 계약 또는
            청약철회 등 에 관한 기록 전자상거래등에서의 소 비자보호에 관한 법률
            5년 대금결제 및 재화 등의 공급에 관한 기록 전자상거래등에서의 소
            비자보호에 관한 법률 5년 소비자의 불만 또는 분 쟁처리에 관한 기록
            전자상거래등에서의 소 비자보호에 관한 법률 3년 전자금융 거래에 대한
            기록 전자금융거래법 5년 웹 사이트 방문에 관한 기록 통신비밀보호법
            3개월 방침 및 기타 관련법령에 따라 일정기간 저장 후 파기될 수
            있습니다. 이때 일정기간 저장된 개인정보는 법률에 의한 경우가
            아니고서는 동의 받지 아니한 다른 목적으로 이용되지 않습니다.
          </p>
          <ol>
            <li>회사 내부 방침에 의한 정보보유 사유</li>
            <ul>
              <li>
                이메일 주소는 서비스 부정이용을 위한 재가입 방지를 위하여 90일간
                보관 후 파기함
              </li>
              <li>
                위와 별도로 이메일 주소, 휴대전화 번호 및 광고성정보 수신 동의
                여부는 분 쟁 발생 시 감독기관 소명을 위하여 1년 보관 후 파기함
              </li>
            </ul>
            <li>관계법령에 의한 정보보유 사유</li>
            <li>개인정보 파기절차 및 방법</li>
            <ul>
              회사는 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
              파기하고 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수
              없는 기술적 방법을 사용하여 삭제하며 전자적 저장매체에 저장된
              개인정보는 영구삭제 장치를 사용하여 파기합니다.
            </ul>
          </ol>

          <h3 id="target_no9">9. 장기 미이용 회원 개인정보 분리 보관</h3>
          <ol>
            <li>
              관련 법령에 의거하여 장기 미이용 회원의 계정을 휴면 상태로
              전환하고 개인 정보를 암호화하여 분리 보관합니다.
            </li>
            <ul>
              <li>
                관련 법령: 개인정보보호법제39조의6 및 동법 시행령 제48조의5
              </li>{" "}
              <li>
                휴면 회원: 서비스를 1년이상 이용하지 않음에 따라 휴면 상태로
                전환된 회원
              </li>
            </ul>
            <li>
              아래의 경우에 해당하는 경우 회원의 권익보호를 위하여 휴면 상태로
              전환되 지 않습니다.
            </li>{" "}
            <ul>
              <li>펀딩에 참여하여 META12의 서비스를 제공받고 있는 회원</li>{" "}
              <li>
                회사가 제공하는 각종 멤버십 서비스에 가입하여 이용기간이
                종료되지 않은 회원
              </li>
            </ul>
            <li>
              휴면 상태 전환 30일 전까지 휴면 예정 회원에게 휴면 전환 예정일,
              별도 분 리 보관되는 사실 및 개인정보 항목이 이메일을 통해 사전
              안내됩니다. 해당 통지 수단에 대한 정보가 부재 또는 오류인 경우에는
              홈페이지 공지사항 게시 로 대체됩니다.
            </li>
            <li>
              휴면 예정 회원이 사전 안내된 휴면 전환 예정일 이전에 로그인 하시는
              경우 에는 휴면 상태로 전환되지 않고 기존과 같이 서비스를 이용하실
              수 있습니다.
            </li>
            <li>
              휴면 상태로 전환된 사실은 메일을 통해 휴면 회원에게 안내됩니다.
            </li>{" "}
            <li>
              휴면 회원은 사용했던 계정으로 로그인하여 서비스를 재이용함으로써
              일반회원 으로 전환될 수 있습니다.
            </li>{" "}
            <li>휴면 회원의 개인정보는 4년간 별도 분리 보관 후 삭제됩니다.</li>
          </ol>

          <h3 id="target_no10">10. 권익침해 구제방법</h3>
          <p>
            정보주체는 개인정보침해로 인한 구제를 받기 위하여
            개인정보분쟁조정위원회, 한국인 터넷진흥원 개인정보침해신고센터 등에
            분쟁해결이나 상담을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의
            신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
          </p>
          <ul>
            <li>
              개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
            </li>
            <li>개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
            <li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
            <li>경찰청 : (국번없이) 1182 (ecrm.police.go.kr)</li>
          </ul>

          <h3 id="target_no11"> 11. 수집한 개인정보 처리 위탁</h3>
          <p>
            회사는 보다 나은 서비스 제공과 이용자의 편의 제공 등 업무수행을
            원활하게 하기 위해 이용자의 개인정보 처리업무를 다음과 같이 위탁하고
            있으며, 위탁업무의 내용이 나 수탁자가 변경되는 경우 지체없이 본
            개인정보처리방침을 통하여 공개하겠습니다.
          </p>
          <ol>
            <li>
              서비스 제공을 위해 개인정보의 처리를 위탁하는 현황은 다음과
              같습니다.{" "}
              <ul>
                <li>개인정보처리 위탁 현황 자세히 보기 </li>
              </ul>
            </li>
          </ol>

          <h3 id="target_no12">12. 개인정보 보호에 대한 기술적 관리적 대책</h3>
          <ol>
            <li>
              회사는 개인정보가 유출되지 않도록 기술적•관리적 대책을 강구하는 데
              최선을 다합니다.
            </li>
            <li>
              회사는 이용자의 개인정보를 처리함에 있어서 개인정보가 분실, 도난,
              유출, 변 조 또는 훼손되지 않도록 안전성 확보를 위하여 최선을
              다하고 있습니다. 이를 위하여 비밀번호 등을 이용한 보안장치, 백신
              프로그램을 이용한 컴퓨터바이러 스 방지장치, 암호알고리즘 등을
              이용하여 네트워크 상에 개인정보를 안전하게 전송할 수 있는
              보안장치, 침입차단시스템 등을 이용한 접근통제장치, 기타 안 전성
              확보를 위하여 필요한 기술적 장치를 확보하는 등의 기술적 대책을
              강구 하고 있습니다.
            </li>
            <li>
              회사는 개인정보 접근이 가능한 직원을 최소한으로 제한하고, 본
              정책의 준수 및 기타 개인정보 보호를 위한 지속적인 교육을 실시하고
              있습니다. 또한 개인 정보 접근을 위한 운영자 비밀번호를 정기적으로
              갱신하여 불법적인 정보유출 을 막기 위해 최선을 다하고 있습니다.
            </li>
          </ol>

          <h3 id="target_no13">13. 광고성 정보전송</h3>
          <p>
            회사는 회원의 명시적인 수신거부의사에 반하여 영리목적의 광고성
            정보를 전송하지 않습니다. 회사 메일링 서비스는 보다 나은 서비스
            제공을 위해 뉴스레터나 서비스 정 보 안내, 설문조사와 이벤트를 실시할
            수 있으며 수신거부의 의사표현을 용이하게 할 수 있도록 하단에
            표시하고 있습니다. 이때 통계 및 경품 발송을 위하여 수집한 최소 한의
            개인정보는 경품 발송/반송(종료 후 1개월) 이후에 폐기합니다.
          </p>

          <h3 id="target_no14">14. 개인정보처리방침의 변경</h3>
          <p>이 개인정보처리방침은 2023년 7월 26일부터 적용됩니다.</p>
        </div>{" "}
      </div>
    </div>
  );
};

export default Wadiz3;
