import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import close from "./../assets/images/close.png";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import AdminUserDetail from "../components/admin/detail/AdminUserDetail";
import NoticeList from "../components/admin/NoticeList";
import NoticeDetail from "../components/admin/NoticeDetail";
import NoticeView from "../components/admin/NoticeView";
import QnaView from "../components/admin/QnaView";

const AdminQnaView = ({ roomid, onClose }) => {
  return (
    <>
      <QnaView />
    </>
  );
};

export default AdminQnaView;
