import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import PrivateRoute from "./lib/route/PrivateRoute";
import PrivateRouteAdmin from "./lib/route/PrivateRouteAdmin";
import "./App.css";
import Main from "./pages/Main";
import Chat from "./pages/Chat";
import CountNum from "./pages/CountNum";
import NoLayout from "./layout/NoLayout";

import Login from "./pages/Login";
import NaverLogin from "./pages/NaverLogin";
import EmailLogin from "./pages/EmailLogin";
import Signup from "./pages/SignUp";
import "./styles/common.css";
// import ChatList from "./pages/ChatList";
import Mypage from "./pages/Mypage";

import ChatList from "./pages/ChatList";

import UserList from "./components/admin/UserList";

import ProductList from "./pages/ProductList";
import ProductDetail from "./pages/ProductDetail";
import FarmList from "./pages/FarmList";

import FarmDetail from "./pages/FarmDetail";

import AdminLayout from "./layout/AdminLayout";
import Introduce from "./pages/Introduce";
import FundingPage from "./pages/FundingPage";
import MypageTemplate from "./pages/MypageTemplate";

import InvestStatusPage from "./pages/InvestStatusPage";

import MypageInfo from "./pages/MypageInfo";
import MypageProfile from "./pages/MypageProfile";
import MypagePass from "./pages/MypagePass";
import AdminUserDetail from "./components/admin/detail/AdminUserDetail";
import AdminUserPage from "./pages/AdminUserPage";
import ScrollToTop from "./lib/scrollToTop";
import AdminNoticePage from "./pages/AdminNoticePage";
import AdminPressPage from "./pages/AdminPressPage";
import AdminNoticeDetail from "./pages/AdminNoticeDetail";
import AdminStoryPage from "./pages/AdminStoryPage";
import AdminNoticeWrite from "./pages/AdminNoticeWrite";
import AdminStoryWrite from "./pages/AdminStoryWrite";

import NoticePage from "./pages/NoticePage";
import NoticeContPage from "./pages/NoticeContPage";
import AdminNoticeView from "./pages/AdminNoticeView";
import AdminNoticeUpdate from "./pages/AdminNoticeUpdate";

import ChannelService from "./ChannelService";
import InvestProcessPage from "./pages/InvestProcessPage";
import MypageInvestInfo from "./pages/MypageInvestInfo";
import MypageWaitInfo from "./pages/MypageWaitInfo";
import MypageInterestInfo from "./pages/MypageInterestInfo";
import MypageInvestPage from "./pages/MypageInvestPage";
import Search from "./pages/SearchPage";
import InvestJoinPage from "./pages/InvestJoinPage";
import AdminWithDrawPage from "./pages/AdminWithDrawPage";
import AdminInvestPage from "./pages/AdminInvestPage";
import ProductWritePage from "./pages/ProductWritePage";
import AdminFnqPage from "./pages/AdminFnqPage";
import AdminReviewPage from "./pages/AdminReviewPage";
import CompanyPage from "./pages/CompanyPage";
import AdminStorePage from "./pages/AdminStorePage";
// import MyInvestPage from "./pages/MyInvestPage";
import MyInvestJPage from "./pages/MyInvestJPage";
import MyInvestEPage from "./pages/MyInvestEPage";
import MyInvestCPage from "./pages/MyInvestCPage";
import PressList from "./components/admin/PressList";
import AdminPressWrite from "./pages/AdminPressWrite";
import AdminFnqWrite from "./pages/AdminFnqWrite";
import AdminReviewWrite from "./pages/AdminReviewWrite";
import AdminDepositDetail from "./pages/AdminDepositDetail";
import AdminWithdrawDetail from "./pages/AdminWithdrawDetail";
import FarmWritePage from "./pages/FarmWritePage";
import AdminUserAnalyzePage from "./pages/AdminUserAnalyzePage";
import AdminInvestIngPage from "./pages/AdminInvestIngPage";
import AdminEndPage from "./pages/AdminEndPage";
import AdminEndDetail from "./pages/AdminEndDetail";
import AdminInvestAnalyzePage from "./pages/AdminInvestAnalyzePage";
import AdminStoreWrite from "./pages/AdminStoreWrite";
import StoreDetail from "./pages/StoreDetail";
import FnqDetail from "./pages/FnqDetail";
import ReviewDetail from "./pages/ReviewDetail";
import PressDetail from "./pages/PressDetail";
import StoryDetail from "./pages/StoryDetail";
import AdminIngDetail from "./pages/AdminIngDetail";
import Wadiz from "./pages/Wadiz";
import Wadiz2 from "./pages/Wadiz2";
import FindAcount from "./pages/FindAcount";
import { useSelector } from "react-redux";
import Wadiz3 from "./pages/Wadiz3";
import AdminInvestInfoPage from "./pages/AdminInvestInfoPage";
import CategoryList from "./pages/CategoryList";
import AdminPage from "./pages/AdminPage";
import StorePage from "./pages/StorePage";
import MypageDelete from "./pages/MypageDelete";
import StoreCategoryList from "./pages/StoreCategoryList";
import QnaPage from "./pages/QnaPage";
import MypageQna from "./pages/MypageQna";
import MypageQnaWrite from "./pages/MypageQnaWrite";
import MypageQnaView from "./pages/MypageQnaView";
import DeleteUserList from "./components/admin/DeleteUserList";
import AdminQnaPage from "./pages/AdminQnaPage";
import AdminQnaView from "./pages/AdminQnaView";
import PartnerList from "./pages/PartnerList";
import AdminPopupPage from "./pages/AdminPopupPage";
import AdminRefundPage from "./pages/AdminRefundPage";
import AdminRefundDetail from "./pages/AdminRefundDetail";
import AdminPolicyPage from "./pages/AdminPolicyPage";
import AdminBannerPage from "./pages/AdminBannerPage";
import AdminCompanyPage from "./pages/AdminCompanyPage";
import AdminFooterPage from "./pages/AdminFooterPage";
import GoodsList from "./pages/GoodsList";
import GoodsWritePage from "./pages/GoodsWritePage";
import GoodsDetail from "./pages/GoodsDetail";
import AdminBrandPage from "./pages/AdminBrandPage";
import AdminDetailsPage from "./pages/AdminDetailsPage";
import AdminPdfPage from "./pages/AdminPdfPage";
import AdminNoticePopPage from "./pages/AdminNoticePopPage";
import StoryPage from "./pages/StoryPage";
import NewsPage from "./pages/NewsPage";
import GoodsWritePageField from "./pages/GoodsWritePageField";
import FarmWritePageField from "./pages/FarmWritePageField";

function App() {
  // const ChannlTok = () => {
  //   ChannelService.loadScript();

  //   //3. 부트하기
  //   ChannelService.boot({
  //     pluginKey: "fill your plugin key",
  //   });

  //   return null;
  // };
  ChannelService.loadScript();
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  //3. 부트하기
  ChannelService.boot({
    pluginKey: "fb80f633-57d9-48c6-a3eb-cc968f9dcde9",
  });

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, [pathname]);

    return null;
  };

  const ScrollRestoration = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      const handleScrollRestoration = () => {
        window.scrollTo(0, 0);
      };

      // 이전 스크롤 위치 초기화
      window.history.scrollRestoration = "manual";
      window.addEventListener("popstate", handleScrollRestoration);

      return () => {
        window.removeEventListener("popstate", handleScrollRestoration);
      };
    }, [pathname]);

    return null;
  };

  return (
    <BrowserRouter>
      {/* <ScrollToTop /> */}
      {/* <ChannlTok /> */}
      <ScrollRestoration />

      <Routes>
        <Route path="" element={<NoLayout />}>
          <Route path="/info" element={<Wadiz />} />
          <Route path="/info2" element={<Wadiz2 />} />
          <Route path="/info3" element={<Wadiz3 />} />
          <Route path="/" element={<Main />} />{" "}
          <Route path="/noticePage" element={<NoticePage />} />
          <Route path="/storyPage" element={<StoryPage />} />
          <Route path="/newsPage" element={<NewsPage />} />
          <Route
            path="/qna"
            element={
              <PrivateRoute authenticated={user} component={<QnaPage />} />
            }
          />
          {/* <Route path="/noticePage/:id" element={<NoticePage />} /> */}
          <Route path="/notice/:id" element={<NoticeContPage />} />
          <Route path="/search" element={<Search />} />
          {/* <Route
            path=""
            element={
              <PrivateRoute
                authenticated={user}
                component={<MypageTemplate />}
              />
            }

          > */}
          {/* <Route element={<PrivateRoute user={user} />}> */}
          {/* <Route path="" element={<MypageTemplate />}> */}
          <Route
            path="/"
            element={
              <PrivateRoute
                authenticated={user}
                component={<MypageTemplate />}
              />
            }
          >
            <Route path="/mypage" element={<Mypage />} />
            <Route path="/mypage/info" element={<MypageInfo />} />{" "}
            <Route path="/mypage/delete" element={<MypageDelete />} />
            <Route path="/mypage/profile" element={<MypageProfile />} />
            <Route path="/mypage/password" element={<MypagePass />} />
            <Route path="/mypage/invest" element={<MypageInvestInfo />} />{" "}
            <Route path="/mypage/invest/:id" element={<MypageInvestPage />} />
            {/* <Route path="/mypage/wait" element={<MypageWaitInfo />} />{" "} */}
            <Route path="/mypage/interest" element={<MypageInterestInfo />} />{" "}
            <Route path="/mypage/qna" element={<MypageQna />} />
            <Route path="/mypage/qna/write" element={<MypageQnaWrite />} />
            <Route
              path="/mypage/qna/viewpage/:id"
              element={<MypageQnaView />}
            />
          </Route>
          <Route path="/company" element={<CompanyPage />} />
          <Route path="/introduce" element={<Introduce />} />
          <Route path="/funding" element={<FundingPage />} />
          <Route path="/store" element={<StorePage />} />
          <Route path="/my/invest/join" element={<MyInvestJPage />} />
          <Route path="/my/invest/end" element={<MyInvestEPage />} />
          <Route path="/my/invest/cancel" element={<MyInvestCPage />} />
          <Route path="/invest" element={<InvestStatusPage />} />
          <Route path="/invest/:id/process" element={<InvestProcessPage />} />
          <Route
            path="/invest/:id/join"
            element={
              <PrivateRoute
                authenticated={user}
                component={<InvestJoinPage />}
              />
            }
          />
        </Route>
        {/* </Route> */}

        {/* <Route path="/chat" element={<Chat />} /> */}
        <Route path="/count" element={<CountNum />} />
        <Route path="/login" element={<Login />} />
        <Route path="/find" element={<FindAcount />} />
        <Route path="/login/:id" element={<EmailLogin />} />
        <Route path="/login/Naver" element={<NaverLogin />} />
        <Route path="/signup" element={<Signup />} />
        {/* <Route path="/chats" element={<ChatList />} /> */}
        <Route
          path=""
          element={
            <PrivateRouteAdmin
              authenticated={user}
              component={<AdminLayout />}
            />
          }
        >
          {/* <Route path="" element={<AdminLayout />}> */}
          {/* <Route path="/admin/chats" element={<ChatList />} /> */}
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/admin/users" element={<UserList />} />
          <Route path="/admin/users_delete" element={<DeleteUserList />} />

          <Route path="/admin/user/:id" element={<AdminUserPage />} />
          <Route path="/admin/notice" element={<AdminNoticePage />} />
          <Route path="/admin/qna" element={<AdminQnaPage />} />
          <Route path="/admin/banner" element={<AdminBannerPage />} />
          <Route path="/admin/footer" element={<AdminFooterPage />} />
          <Route path="/admin/brand" element={<AdminBrandPage />} />
          <Route path="/admin/introduce" element={<AdminCompanyPage />} />
          <Route path="/admin/notice/:id" element={<AdminNoticeView />} />
          <Route path="/admin/qna/:id" element={<AdminQnaView />} />
          <Route
            path="/admin/notice/update/:id"
            element={<AdminNoticeUpdate />}
          />
          <Route path="/admin/notice/write" element={<AdminNoticeWrite />} />
          <Route path="/admin/popup" element={<AdminPopupPage />} />
          <Route path="/admin/press" element={<PressList />} />
          <Route path="/admin/press/write" element={<AdminPressWrite />} />
          <Route path="/admin/press/detail/:id" element={<PressDetail />} />
          <Route path="/admin/story" element={<AdminStoryPage />} />

          <Route path="/admin/story/write" element={<AdminStoryWrite />} />
          <Route path="/admin/Story/detail/:id" element={<StoryDetail />} />
          <Route path="/admin/product_page" element={<AdminDetailsPage />} />
          <Route path="/admin/product_pdf" element={<AdminPdfPage />} />
          <Route path="/admin/product_popup" element={<AdminNoticePopPage />} />

          <Route path="/admin/product" element={<ProductList />} />
          <Route path="/admin/product/write" element={<ProductWritePage />} />
          <Route
            path="/admin/products/detail/:id"
            element={<ProductDetail />}
          />
          <Route path="/admin/category" element={<CategoryList />} />
          <Route path="/admin/partner" element={<PartnerList />} />
          <Route path="/admin/storeCategory" element={<StoreCategoryList />} />
          <Route path="/admin/farm" element={<FarmList />} />
          <Route path="/admin/goods" element={<GoodsList />} />
          <Route path="/admin/farm/detail/:id" element={<FarmDetail />} />
          <Route path="/admin/goods/detail/:id" element={<GoodsDetail />} />
          <Route path="/admin/farm/write" element={<FarmWritePage />} />
          <Route path="/admin/farm/field" element={<FarmWritePageField />} />
          <Route path="/admin/goods/write" element={<GoodsWritePage />} />
          <Route path="/admin/goods/field" element={<GoodsWritePageField />} />

          <Route path="/admin/store" element={<AdminStorePage />} />
          <Route path="/admin/store/write" element={<AdminStoreWrite />} />
          <Route path="/admin/store/detail/:id" element={<StoreDetail />} />
          <Route path="/admin/investinfo" element={<AdminInvestInfoPage />} />
          <Route path="/admin/policy" element={<AdminPolicyPage />} />
          <Route path="/admin/deposit" element={<AdminInvestPage />} />
          <Route path="/admin/deposit/:id" element={<AdminDepositDetail />} />
          <Route path="/admin/ing" element={<AdminInvestIngPage />} />
          <Route path="/admin/ing/:id" element={<AdminIngDetail />} />
          <Route path="/admin/withDraw" element={<AdminWithDrawPage />} />
          <Route
            path="/admin/widthDraw/:id"
            element={<AdminWithdrawDetail />}
          />

          <Route path="/admin/investend" element={<AdminEndPage />} />
          <Route path="/admin/refund" element={<AdminRefundPage />} />
          <Route path="/admin/investend/:id" element={<AdminEndDetail />} />
          <Route path="/admin/refund/:id" element={<AdminRefundDetail />} />
          <Route path="/admin/fnq" element={<AdminFnqPage />} />
          <Route path="/admin/fnq/write" element={<AdminFnqWrite />} />
          <Route path="/admin/fnq/detail/:id" element={<FnqDetail />} />
          <Route path="/admin/review" element={<AdminReviewPage />} />
          <Route path="/admin/review/write" element={<AdminReviewWrite />} />
          <Route path="/admin/review/detail/:id" element={<ReviewDetail />} />
          <Route
            path="/admin/user_analyze"
            element={<AdminUserAnalyzePage />}
          />
          <Route
            path="/admin/invest_analyze"
            element={<AdminInvestAnalyzePage />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
