import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import InvestContentStatus from "./detail/InvestContentStatus";
import InvestTopSilder from "./detail/InvestTopSilder";
import InvestQna from "./detail/InvestQna";
import InvestNews from "./detail/InvestNews";
import InvestStory from "./detail/InvestStory";
import InvestReview from "./detail/InvestReview";
import ProcessSection_1 from "./detail/ProcessSection_1";
import ProcessSection_2 from "./detail/ProcessSection_2";
import ProcessSection_3 from "./detail/ProcessSection_3";
import ProcessSection_4 from "./detail/ProcessSection_4";
import { useState, useEffect } from "react";
import { WarringAlert } from "../alert/Alert";
import InvestProDetail from "./detail/InvestProDetail";

import { useParams } from "react-router";
import InvestTopSilderTwo from "./detail/InvestTopSilderTwo";
const InvestProcessDetail = () => {
  const [data, setData] = useState([]);
  const [warringAlert, setWarringAlert] = useState(false);
  const [content, setContent] = useState("");
  const { id } = useParams();
  useEffect(() => {
    let sendData = {
      no: id,
    };
    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/invest_select_product",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);
  return (
    <>
      {/* {warringAlert && (
        <WarringAlert content={content} setWarringAlert={setWarringAlert} />
      )} */}
      <div className="common-wrap" style={{ backgroundColor: "#fff" }}>
        <InvestTopSilderTwo data={data[0]} />
        <div className="common-inner-wrap">
          <InvestProDetail data={data[0]} />

          {/* <ProcessSection_4 /> */}
        </div>
      </div>
    </>
  );
};

export default InvestProcessDetail;
