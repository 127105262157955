import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CustomMainTable from "./../CustomMainTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "./../../CommonAxios";
import Swal from "sweetalert2";
import moment from "moment";
import Moment from "react-moment";
import styles from "./../../../styles/admin.module.css";
import { numberToKorean } from "../../../lib/numberToKorean";
import CustomFarmTable from "../CustomFarmTable";
import { wonComma } from "../../../lib/wonComma";
const FarmTable = () => {
  const navigate = useNavigate();
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [field3, setField3] = useState("");
  const TableColumn = [
    {
      Header: "농장명",
      accessor: "farm_title",
      Cell: ({ cell: { row } }) => <Title row={row} />,
    },
    // {
    //   Header: "농장현황",
    //   accessor: "farm_state",
    //   Cell: ({ cell: { row } }) => <FarmState row={row} />,
    // },
    {
      Header: field3,
      accessor: "farm_progress",
      Cell: ({ cell: { row } }) => <FarmProgess row={row} />,
    },
    {
      Header: field2,
      accessor: "farm_area",
      Cell: ({ cell: { value } }) => {
        return <div>{value}평</div>;
      },
    },
    // {
    //   Header: "생산 품종",
    //   accessor: "farm_crops",
    // },
    {
      Header: field1,
      accessor: "farm_estimated_earns",
      Cell: ({ cell: { value } }) => (
        <>
          <NumberFormat value={value} />
        </>
      ),
    },
  ];
  const Title = ({ row }) => {
    return (
      <div onClick={() => linkData(row.original.no)}>
        {row.original.farm_title}
      </div>
    );
  };
  const Date = ({ value }) => {
    if (value) {
      return <Moment format="YYYY.MM.DD.">{value}</Moment>;
    } else {
      return <>기간 미지정</>;
    }
  };

  const FarmState = ({ row }) => {
    if (row.original.farm_state == "Y") {
      return <div onClick={() => linkData(row.original.no)}>A타입</div>;
    } else {
      return <div onClick={() => linkData(row.original.no)}>B타입</div>;
    }
  };

  const FarmProgess = ({ row }) => {
    if (row.original.farm_state == "Y") {
      if (row.original.farm_progress == 1) {
        return (
          <div onClick={() => linkData(row.original.no)}>
            {data[0].field_B_text_one}
          </div>
        );
      } else if (row.original.farm_progress == 2) {
        return (
          <div onClick={() => linkData(row.original.no)}>
            {" "}
            {data[0].field_B_text_two}
          </div>
        );
      } else if (row.original.farm_progress == 3) {
        return (
          <div onClick={() => linkData(row.original.no)}>
            {" "}
            {data[0].field_B_text_three}
          </div>
        );
      } else if (row.original.farm_progress == 4) {
        return (
          <div onClick={() => linkData(row.original.no)}>
            {" "}
            {data[0].field_B_text_four}
          </div>
        );
      }
    } else {
      if (row.original.farm_progress == 1) {
        return (
          <div onClick={() => linkData(row.original.no)}>
            {data[0].field_A_text_one}
          </div>
        );
      } else if (row.original.farm_progress == 2) {
        return (
          <div onClick={() => linkData(row.original.no)}>
            {" "}
            {data[0].field_A_text_two}
          </div>
        );
      } else if (row.original.farm_progress == 3) {
        return (
          <div onClick={() => linkData(row.original.no)}>
            {" "}
            {data[0].field_A_text_three}
          </div>
        );
      } else if (row.original.farm_progress == 4) {
        return (
          <div onClick={() => linkData(row.original.no)}>
            {" "}
            {data[0].field_A_text_four}
          </div>
        );
      }
    }
  };
  const FarmWeeks = ({ value }) => {
    if (value == 0) {
      return null;
    } else {
      return value + "주차";
    }
  };

  const NumberFormat = ({ value }) => {
    const numberStr = wonComma(numberToKorean(value)) + "원";

    return numberStr;
  };

  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState("all");

  // 체크 박스의 no 데이터값
  const [chkValue, setChkValue] = useState([]);

  const updateValue = (ubt) => {
    setChkValue(ubt);
  };

  useEffect(() => {}, [chkValue]);

  const linkData = (data) => {
    navigate("/admin/farm/detail/" + data);
  };

  /// 여기까지
  const [data, setdata] = useState([]);
  const [swt, setSwt] = useState(false);
  /// 여기까지
  useEffect(() => {
    let sendData = {
      field_type: 2,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_field",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          if (result.messageinfo.message.length > 0) {
            setdata(result.messageinfo.message);

            setField1(result.messageinfo.message[0].field_title_three);
            setField2(result.messageinfo.message[0].field_title_one);
            setField3(result.messageinfo.message[0].field_title_four);
            setSwt(true);
          }
        }
      }
    );
  }, []);
  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_farm",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
  }, []);

  useEffect(() => {
    if (arrayList.length > 0) {
      if (options == "all") {
        if (filterWord == null) {
          setSendList(arrayList);
        } else {
          let list = arrayList.filter(
            (a) =>
              a.farm_title.includes(filterWord) == true ||
              a.farm_crops.includes(filterWord) == true
          );
          setSendList(list);
        }
      } else if (options == "produce") {
        const norList = arrayList.filter((a) => a.farm_state == "Y");
        if (filterWord == null) {
          setSendList(norList);
        } else {
          let list = norList.filter(
            (a) =>
              a.farm_title.includes(filterWord) == true ||
              a.farm_crops.includes(filterWord) == true
          );
          setSendList(list);
        }
      } else if (options == "establish") {
        const norList = arrayList.filter((a) => a.farm_state == "N");
        if (filterWord == null) {
          setSendList(norList);
        } else {
          let list = norList.filter(
            (a) =>
              a.farm_title.includes(filterWord) == true ||
              a.farm_crops.includes(filterWord) == true
          );
          setSendList(list);
        }
      }
    }
  }, [arrayList, filterWord, options]);

  const filterUser = (e) => {
    setFilterWord(e.target.value);
  };

  return (
    <>
      <div className={styles.user_search}>
        <span className={styles.search_icon}>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </span>
        <input
          type="text"
          onChange={(e) => filterUser(e)}
          placeholder="찾고자 하는 농장명을 검색하세요."
        />
      </div>
      <div className={styles.user_header}>
        <div className={styles.user_option}>
          <div
            className={
              options == "all" ? `${styles.all} ${styles.clicked}` : styles.all
            }
            onClick={() => setOptions("all")}
          >
            모든 농장 &nbsp; {arrayList.length}
          </div>{" "}
          <div
            className={
              options == "establish"
                ? `${styles.noraml} ${styles.clicked}`
                : styles.noraml
            }
            onClick={() => setOptions("establish")}
          >
            A타입 &nbsp; {arrayList.filter((a) => a.farm_state == "N").length}
          </div>
          <div
            className={
              options == "produce"
                ? `${styles.noraml} ${styles.clicked}`
                : styles.noraml
            }
            onClick={() => setOptions("produce")}
          >
            B타입 &nbsp; {arrayList.filter((a) => a.farm_state == "Y").length}
          </div>
        </div>
        {/* <div className={styles.header_btns}>
          <button onClick={() => chgRecommend()}>추천변경</button>
        </div> */}
      </div>{" "}
      {swt && (
        <CustomFarmTable
          columns={TableColumn}
          data={sendList}
          options={options}
          setOptions={setOptions}
          arrayList={arrayList}
          setArrayList={setArrayList}
          setSendList={setSendList}
          linkData={linkData}
        />
      )}
    </>
  );
};

export default FarmTable;
