// import "../styles/search.css";

// import noData from "../assets/images/no_data.png";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import React, { useCallback, useRef, useEffect, useState } from "react";
import axios from "axios";
import main from "../../styles/main.module.css";
import { Link } from "react-router-dom";
import { RiHeart3Line, RiHeart3Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import { AiFillHeart, AiOutlineYoutube } from "react-icons/ai";
import { wonComma, wonCommaFix } from "../../lib/wonComma";
import Swal from "sweetalert2";
const SearchItem = ({ searchList }) => {
  const navigate = useNavigate();
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const btn_move = (yn, link, num) => {
    if (yn === "N") {
      navigate(`/invest/${num}/process`);
    } else {
      window.open(link);
    }
    // navigate(`/notice/${no}`);
  };

  const btn_move2 = (link) => {
    window.open(link);
    // navigate(`/notice/${no}`);
  };

  const [mouse, setMouse] = useState(
    searchList.sw ? (searchList.sw === "true" ? true : false) : false
  );

  const [timer, setTimer] = useState(0);

  const onCLikcZzim = async () => {
    if (user === null) {
      return Swal.fire({
        title: "이용불가",
        html: `로그인시 이용가능합니다.`,
        confirmButtonText: "확인",
      }).then((result) => {
        navigate("/login");
      });
    }

    setMouse(!mouse);
    if (timer) {
      clearTimeout(timer);
    }
    const dibisHandle = () => {
      setMouse(!mouse);

      return mouse;
    };

    const newTimer = setTimeout(async () => {
      try {
        const dibisBoolean = dibisHandle();

        let sendData = {
          no: user.no,
          bool: !dibisBoolean,
          dataNo: searchList.no,
          kind: searchList.pro_img ? 1 : 2,
        };
        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/main_like_boolean",
          sendData,
          function (result) {
            if (result.messageinfo.state === "ok") {
            } else {
              //   alert(result.messageinfo.message);
            }
          }
        );

        // console.log(dibisBoolean);
        // if (dibisBoolean === false) {
        // } else {
        //   setMouse(false);
        // }
        // await CommonGetToken(myDibis(dibisBoolean));

        // await myDibis();
      } catch (e) {
        console.error("error", e);
      }
    }, 500);
    setTimer(newTimer);
  };
  return (
    <React.Fragment>
      <div className={main.funding_inner_item}>
        <div className={main.funding_inner_imgbox}>
          {/* <div className={main.funding_heart}>
            <AiFillHeart />
          </div> */}
          {!mouse && (
            <div className={main.funding_heart} onClick={onCLikcZzim}>
              <AiFillHeart />
            </div>
          )}
          {mouse && (
            <div className={main.funding_heart_color} onClick={onCLikcZzim}>
              <AiFillHeart />
            </div>
          )}
          {searchList.pro_img ? (
            <img
              className={main.funding_inner_img}
              src={`/product/${searchList.no}/${searchList.pro_img}`}
              onClick={() =>
                btn_move(
                  searchList.pro_link_yn,
                  searchList.pro_link,
                  searchList.no
                )
              }
            />
          ) : (
            <img
              className={main.funding_inner_img}
              src={`/store/${searchList.no}/${searchList.store_img}`}
              onClick={() => btn_move2(searchList.store_link)}
            />
          )}

          {Number(searchList.endDate) < 3 &&
            Number(searchList.endDate) >= 0 &&
            searchList.pro_admin_end_yn !== "Y" && (
              <div className={main.funding_detail_one}>
                <span>마감 임박</span>
              </div>
            )}
          {(Number(searchList.endDate) < 0 ||
            searchList.pro_admin_end_yn === "Y") && (
            <div className={main.funding_detail_one}>
              <span>마감</span>
            </div>
          )}
          <div className={main.funding_detail_four}>
            <span>{searchList.pro_img ? "펀딩+" : "스토어"}</span>
          </div>
          {/* {searchList.type && (
              <div className={main.funding_detail_two}>
                <div className={main.funding_detail_back}>
                  <span>event</span>
                </div>
              </div>
            )}
            {!searchList.type && (
              <div className={main.funding_detail_three}>
                <span>
                  meta
                  <br />
                  farm
                </span>
              </div>
            )} */}
        </div>
        <div
          className={main.funding_inner_cont}
          onClick={() => btn_move(searchList.pro_link_yn, searchList.pro_link)}
        >
          <div className={main.funding_inner_top}>
            {searchList.pro_link_yn === "Y" && searchList.pro_img && (
              <>
                <div className={main.funding_inner_left}>
                  <span className={main.funding_span_green}>
                    <em>0%</em>달성
                  </span>
                  <span className={main.funding_span_default}>0원</span>
                </div>
                <p className={main.funding_inner_right}>
                  <span>링크</span>
                </p>
              </>
            )}
            {searchList.pro_link_yn === "N" && searchList.pro_img && (
              <>
                <div className={main.funding_inner_left}>
                  <span className={main.funding_span_green}>
                    {searchList.per && searchList.pro_end_yn !== "Y" && (
                      <em>{wonCommaFix(searchList.per)}%</em>
                    )}
                    {!searchList.per && searchList.pro_admin_end_yn === "Y" && (
                      <em>100%</em>
                    )}
                    {!searchList.per && searchList.pro_admin_end_yn !== "Y" && (
                      <em>0%</em>
                    )}
                    달성
                  </span>
                  <span className={main.funding_span_default}>
                    {searchList.per &&
                      searchList.pro_admin_end_yn !== "Y" &&
                      wonComma(searchList.amount) + "원"}{" "}
                    {searchList.per &&
                      searchList.pro_admin_end_yn === "Y" &&
                      wonComma(searchList.pro_target_amount) + "원"}{" "}
                    {!searchList.per &&
                      searchList.pro_admin_end_yn === "Y" &&
                      wonComma(searchList.pro_target_amount) + "원"}{" "}
                    {!searchList.per &&
                      searchList.pro_admin_end_yn !== "Y" &&
                      "0원"}{" "}
                  </span>
                </div>
                <p className={main.funding_inner_right}>
                  {searchList.endDate === 0 &&
                    searchList.pro_end_yn !== "Y" && (
                      <span>오늘 자정 마감</span>
                    )}
                  {searchList.endDate > 0 &&
                    searchList.pro_end_yn !== "Y" &&
                    searchList.pro_admin_end_yn !== "Y" && (
                      <span>{searchList.endDate}일 남음</span>
                    )}{" "}
                  {(searchList.endDate < 0 ||
                    searchList.pro_end_yn === "Y" ||
                    searchList.pro_admin_end_yn === "Y") && <span>마감</span>}
                </p>
              </>
            )}
            {!searchList.pro_img && (
              <div
                className={main.funding_inner_left}
                style={{ color: "#00c73c" }}
              >
                <span>{wonComma(searchList.store_amount)}원</span>
              </div>
            )}
          </div>
          {searchList.pro_img && (
            <>
              {" "}
              <div className={main.funding_inner_middle}>
                <span>
                  [{searchList.category_name}] {searchList.pro_title}
                </span>
              </div>
              {/* <div className={main.funding_inner_bottom}>
                <span>{searchList.pro_company}</span>
              </div> */}
            </>
          )}

          {!searchList.pro_img && (
            <>
              {" "}
              <div className={main.funding_inner_middle}>
                <span>
                  [{searchList.category_name}] {searchList.store_title}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchItem;
