import React, { useState, useEffect } from "react";

import StoryDetail from "../components/story/StoryDetail";
function StoryPage() {
  const [message, setMessage] = useState("");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  return (
    <>
      <StoryDetail />
    </>
  );
}

export default StoryPage;
