import React, { useState, useEffect, useRef } from "react";

import PressWrite from "../components/admin/PressWrite";
import FnqWrite from "../components/admin/FnqWrite";

const AdminFnqWrite = ({ roomid, onClose }) => {
  return (
    <>
      <FnqWrite />
    </>
  );
};

export default AdminFnqWrite;
