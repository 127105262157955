import img1 from "../../assets/images/1.jpg";
import img2 from "../../assets/images/2.jpg";
import img3 from "../../assets/images/3.jpg";
import img4 from "../../assets/images/4.jpg";
import img5 from "../../assets/images/5.jpg";
import React, { useEffect, useState, useRef, useCallback } from "react";

import main from "../../styles/main.module.css";
import { RiHeart3Line, RiHeart3Fill } from "react-icons/ri";
import ready from "../../assets/images/ready.png";
import { useSelector } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import { Link } from "react-router-dom";
import { wonComma } from "../../lib/wonComma";
import { FaStar } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import SwiperCore, {
  Virtual,
  Navigation,
  Pagination,
  History,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "red" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    ></div>
  );
}

const MainItemSlider = ({ data }) => {
  const ItemView = ({ data1 }) => {
    return (
      <>
        <div className={main.store_item}>
          <a target="_blank" href={data1.store_link}>
            <div className={main.store_item_inner}>
              <div className={main.store_item_img}>
                <img src={`/store/${data1.no}/${data1.store_img}`} />
              </div>
              <div className={main.store_item_cont}>
                <div className={main.store_item_content}>
                  {data1.store_title}
                </div>
                <div className={main.store_item_detail}>
                  <p>{wonComma(data1.store_amount)}원</p>
                  {/* <p>
                    <FaStar /> {data1.store_grade}
                  </p> */}
                </div>
                <div className={main.store_item_type}>
                  {/* {data1.icon1 && <span>무료배송</span>}
                  {data1.icon2 && <span>와배송</span>} */}
                </div>
              </div>
            </div>
          </a>
        </div>
      </>
    );
  };
  const slickRef = useRef(null);

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  const [state, setState] = useState(1);
  return (
    <>
      <div>
        <Swiper
          className="mySwiper"
          slidesPerView={2}
          ref={setSwiper}
          {...parmss}
          style={{ position: "relative" }}
          // history={{
          //   key: "slide",
          // }}
          modules={[Navigation]}
          breakpoints={{
            460: {
              slidesPerView: 3,
              // spaceBetween: 20,
            },
            550: {
              slidesPerView: 4,
              // spaceBetween: 20,
            },
            800: {
              slidesPerView: 5,
              // spaceBetween: 20,
            },
            1100: {
              slidesPerView: 6,
              // spaceBetween: 20,
            },
          }}
        >
          {data &&
            data.map((data1) => {
              return (
                <SwiperSlide>
                  <ItemView data1={data1} />
                </SwiperSlide>
              );
            })}
        </Swiper>{" "}
        <ButtonPrev
          ref={prevRef}

          // style={{ width: "100px", height: "100px", background: "blue" }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MdKeyboardArrowLeft />{" "}
          </div>
        </ButtonPrev>{" "}
        {/* <div
                ref={prevRef}
                style={{ width: "100px", height: "100px", background: "blue" }}
              ></div> */}
        <ButtonNext ref={nextRef}>
          {" "}
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MdKeyboardArrowRight />
          </div>
        </ButtonNext>
      </div>
    </>
  );
};
const ButtonPrev = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 50%;
  left: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
  cursor: pointer;
`;
const ButtonNext = styled.div`
  position: absolute;
  z-index: 1;

  width: 35px;
  height: 35px;
  display: block;
  background: #fff;
  top: 50%;
  right: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
  cursor: pointer;
`;
export default MainItemSlider;
