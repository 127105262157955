import styles from "../../../styles/admin.module.css";
import { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import AdminUserInfo from "./AdminUserInfo";
import ToastEditor from "../editor/ToastEditor";
import { textReplace } from "../../../lib/textReplace";
import { FcEditImage } from "react-icons/fc";
import Swal from "sweetalert2";
import { getTextTotal, textOverCut } from "../../../lib/stringLenghts";
const PressWriteDetail = () => {
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  const imgNameRef = useRef(); //이미지 파일 이름 REF
  const [imageFile, setImageFile] = useState(""); //이미지 속성정보
  const [getimage, setGetImage] = useState(""); //render.result 값
  const [openValue, setOpenValue] = useState("Y");
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [agency, setAgency] = useState("");
  const [dateValue, setDateValue] = useState("");
  const changeTitle = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 50);
    let text = textOverCut(e.target.value, 50);

    setTitle(text);
    setTitleByte(byte.byte);
  }, []);
  const [titleByte, setTitleByte] = useState(0);
  const [agByte, setAgByte] = useState(0);
  const changeLink = useCallback((e) => {
    setLink(e.target.value);
  }, []);
  const changeAgency = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 10);
    let text = textOverCut(e.target.value, 10);

    setAgency(text);
    setAgByte(byte.byte);
  }, []);

  const changeDate = useCallback((e) => {
    setDateValue(e.target.value);
  }, []);

  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 1024 * 1;

    if (file[0] === undefined) {
      return;
    }

    // if (
    //   imgExtension[1] !== "jpg" ||
    //   imgExtension[1] !== "png" ||
    //   imgExtension[1] !== "jpeg" ||
    //   imgExtension[1] !== "gif"
    // ) {
    //   Swal.fire({
    //     title: "이미지 업로드 불가",
    //     text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",
    //     icon: "error",
    //     confirmButtonText: "확인",
    //     confirmButtonColor: "#FF0000",
    //   });
    //   return;
    // }
    if (file[0].size >= maxSize) {
      //이미지 사이즈가 500k보다 크다면,
      setImageFile(imageFile);
      Swal.fire({
        title: "이미지 용량 초과",
        html: `1MB 사진을 올려주세요.`,

        confirmButtonText: "확인",
      });
      return;
    } else {
      setImageFile(file);
    }
  };

  useEffect(() => {
    preview(); // 미리보기
  });

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      imageRef.current.src = reader.result;
      setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };

  // const checkOnlyOne = (element) => {
  //   const checkboxes = document.getElementsByName("open");

  //   checkboxes.forEach((cb) => {
  //     cb.checked = false;
  //   });

  //   element.checked = true;
  // };
  const navigate = useNavigate();
  const [imgArr, setImgArr] = useState([]);
  const btn_story_reg = () => {
    if (!title || !openValue || !link || !imageFile || !dateValue) {
      return Swal.fire({
        title: "빈 칸을 채워주세요.",

        confirmButtonText: "확인",
      });
    }
    if (
      !RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/).test(
        dateValue
      )
    ) {
      return Swal.fire({
        title: "날짜 형식으로 입력하세요.",

        confirmButtonText: "확인",
      });
    }
    let sendData = {
      title: textReplace(title),
      link: textReplace(link),
      open: openValue,
      agency: textReplace(agency),
      dateValue: dateValue,
      filename: "",
      image: "",

      // mobile :
    };

    if (imageFile !== "") {
      var file_info = imageFile[0].name.split(".");
      var file_name = `${Math.floor(Math.random() * 100000)}.${file_info[1]}`;
      sendData.filename = file_name;
      sendData.image = `${getimage}file_name:${file_name}file_name:file_name:/userProfile/`;
    }

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_insert_press",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          navigate("/admin/press");
        } else {
          navigate("/admin/press");
        }
      }
    );
  };
  const change_open = (data) => {
    setOpenValue(data);
  };
  return (
    <>
      <div className={styles.story_write_wrap}>
        <div className={styles.story_write_inner}>
          <div className={styles.story_write_title}>
            <h4>언론보도 작성</h4>
            <div className={styles.story_write_button}>
              <button onClick={btn_story_reg}>등록</button>
            </div>
          </div>
          <div className={styles.story_write_content}>
            <div className={styles.story_write_top}>
              {" "}
              <div className={styles.story_file_box}>
                <h4>대표 이미지 업로드</h4>
                <div className={styles.story_img_box}>
                  {imageFile === "" ? (
                    <div>
                      <span className={styles.image_span}>Image</span>
                    </div>
                  ) : (
                    <img src="" ref={imageRef} />
                  )}
                </div>
                <label className={styles.story_file}>
                  <input
                    type="file"
                    className={styles.story_file_inp}
                    accept="image/*"
                    onChange={inpChange}
                  />
                  이미지 업로드
                </label>
              </div>
              <p className={styles.story_warning}>
                사이즈는 295(px) x 160(px) 사이즈로 업로드바랍니다.{" "}
              </p>
              {/* <div className={styles.story_open_box}>
                <h4>공개 여부</h4>
                <label>
                  <input
                    type="radio"
                    name="open"
                    value="Y"
                    onChange={() => change_open("Y")}
                  />{" "}
                  공개
                </label>
                <label>
                  <input
                    type="radio"
                    name="open"
                    value="N"
                    onChange={() => change_open("N")}
                  />{" "}
                  비공개
                </label>
              </div> */}
            </div>
            <div className={styles.story_write_bottom}>
              <div>
                <label>제목</label>
                <div className={styles.story_write_inpbox}>
                  <input
                    className={styles.story_write_inp}
                    placeholder="제목을 입력해주세요."
                    onChange={changeTitle}
                    value={title}
                    // onChange={onChangeTitle}
                    // value={title}
                  />
                  <span>{titleByte}/50</span>
                </div>
              </div>
              <div>
                {" "}
                <label>링크</label>{" "}
                <div className={styles.story_write_inpbox}>
                  <input
                    className={styles.story_write_inp}
                    placeholder="링크를 입력해주세요."
                    onChange={changeLink}
                    value={link}
                    // onChange={onChangeTitle}
                    // value={title}
                  />{" "}
                </div>
              </div>
              <div className={styles.story_inp_last}>
                <div>
                  <label>출처 및 기관</label>{" "}
                  <div className={styles.story_write_inpbox}>
                    <input
                      className={styles.story_write_inp}
                      placeholder="출처 및 기관을 입력해주세요."
                      onChange={changeAgency}
                      value={agency}
                      // onChange={onChangeTitle}
                      // value={title}
                    />{" "}
                    <span>{agByte}/10</span>
                  </div>
                </div>{" "}
                <div>
                  <label>원본글 작성 날짜</label>{" "}
                  <div className={styles.story_write_inpbox}>
                    <input
                      className={styles.story_write_inp}
                      placeholder="0000-00-00 형식으로 작성해주세요."
                      onChange={changeDate}
                      value={dateValue}
                      // onChange={onChangeTitle}
                      // value={title}
                    />{" "}
                  </div>
                </div>
              </div>
              {/* <textarea></textarea> */}
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default PressWriteDetail;
