import React, { useState, useEffect } from "react";
import logo from "../logo.svg";
import "../App.css";
import MainBanner from "../components/main/MainBanner";
import MainSection1 from "../components/main/MainSection1";
import SuccessAlert from "../components/alert/Alert";
import FundingDetail from "../components/funding/FundingDetail";
import FundingBanner from "../components/funding/FundingBanner";
import StoreBanner from "../components/funding/StoreBanner";
import StoreDetail from "../components/funding/StoreDetail";
function StorePage() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      {" "}
      <div id="container">
        <StoreBanner />
        <StoreDetail />
      </div>
      {/* <MainSection2 /> */}
    </>
  );
}

export default StorePage;
