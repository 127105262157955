import inv from "../../../styles/invest.module.css";
import React, { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CommonAxios } from "../../CommonAxios";
import axios from "axios";
import { TfiCreditCard, TfiWorld } from "react-icons/tfi";
import { TbCircleCheckFilled, TbCheck } from "react-icons/tb";
import { IoIosArrowForward } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa";
import { wonComma } from "../../../lib/wonComma";
import { numberToKorean } from "../../../lib/numberToKorean";
import Swal from "sweetalert2";
import { investReset } from "../../../modules/invest";
import { useLocation } from "react-router-dom";
const ProcessInvest = ({
  popupResult,
  setInvestPop,
  data,
  data1,
  type,
  setWarringAlert,
  setContent,
  setMoveAlert,
  setMoveData1,
  setPopupResult,
  setMoveData2,
}) => {
  const [method, setMethod] = useState("card");
  const dispatch = useDispatch();
  const { user, amount } = useSelector(({ user, invest }) => ({
    user: user.user,
    amount: invest.amount,
  }));
  const navigate = useNavigate();
  const [invCash, setInvCash] = useState(0);
  const [profit, setProfit] = useState(0);
  const [starts, setStarts] = useState(0);
  const location = useLocation();
  const btn_join = () => {
    if (!invCash) {
      return alert("금액을 입력하세요.");
    }
    let sendData = {
      user_id: user.no,
      amount: Number(invCash + "0000"),
      pro_id: data.no,
      invest_id: new Date().getTime(),
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/invest_insert_join",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          navigate("/mypage/invest");
        } else {
        }
      }
    );
  };
  useEffect(() => {
    if (data) {
      if (invCash) {
        if (invCash == 0) {
        } else if (invCash > 1000) {
          setProfit(invCash * (data.pro_rate / 100));
          setStarts(profit);
          setInvCash(1000);
        } else {
          setProfit(invCash * (data.pro_rate / 100));
          setStarts(profit);
        }
      }
    }
  }, [invCash]);

  const chgCash = (e) => {
    if (!/[0-9]/g.test(Number(e.target.value))) {
      return setInvCash(0);
    }
    if (e.target.value > 1000) {
      setInvCash(Number(1000));
    } else {
      setInvCash(Number(e.target.value));
    }
  };
  const { id } = useParams();
  const { id2 } = useParams();

  const onClickPayment = () => {
    if (data.pro_end_yn === "Y") {
      setContent("해당 상품은 모집 마감하였습니다.");
      setWarringAlert(true);
      return;
    }
    if (data.pro_end_yn === "C") {
      setContent("해당 상품은 모집 예정중입니다.");
      setWarringAlert(true);
      return;
    }
    if (invCash < 10) {
      setContent("가입 금액은 최소 10만원입니다.");
      setWarringAlert(true);

      return;
    }
    if (!invCash || invCash === 0) {
      setContent("가입금액을 입력하세요.");
      setWarringAlert(true);

      return;
    }

    if (!ch1 || !ch4 || !ch5 || !ch6) {
      setContent("이용약관을 체크해주세요.");
      setWarringAlert(true);

      return;
    }

    setInvestPop(true);
  };

  useEffect(() => {
    if (popupResult) {
      if (popupResult) {
        var today = new Date();
        var hours = today.getHours(); // 시
        var minutes = today.getMinutes(); // 분
        var seconds = today.getSeconds(); // 초
        var milliseconds = today.getMilliseconds();
        var makeMerchantUid = hours + minutes + seconds + milliseconds;
        var newDat = new Date().getTime();

        let viewDate = new Date();

        let sendData = {
          payment_merchant_uid: `inv_${newDat}`, //주문번호
          payment_name: data.pro_title,
          payment_user_name: user.ur_name, // 이름
          payment_user_mobile: user.ur_mobile, // 번호
          payment_user_email: user.ur_email, // 이메일
          payment_user_id: user.no,
          payment_amount: Number(invCash + "0000"), // 요금
          payment_product_id: data.no,

          payment_join_date: data.pro_join,

          payment_rate: data.pro_rate,
        };

        setMoveData1(wonComma(invCash));
        setMoveData2(wonComma(profit));
        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/user_invest_insert",
          sendData,
          function (result) {
            if (result.messageinfo.state == "ok") {
              let sendData2 = {
                payment_user_name: user.ur_name, // 이름
                payment_user_email: user.ur_email, // 이메일 payment_user_mobile: user.ur_mobile, // 번호
                payment_name: data.pro_title,
                payment_user_mobile: user.ur_mobile, // 번호
                payment_amount: wonComma(Number(invCash + "0000")), // 요금
                bank: result.messageinfo.message[0].acc_bank,
                name: result.messageinfo.message[0].acc_name,
                number: result.messageinfo.message[0].acc_number,
              };
              CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/user_invest_send",
                sendData2,
                function (result) {
                  if (result.messageinfo.state == "ok") {
                  }
                }
              );
              setPopupResult(false);
              setMoveAlert(true);
              // navigate("/my/invest/cancel");
              //       const { IMP } = window;
              //       IMP.init(process.env.REACT_APP_IMP_INIT);
              //       const data = {
              //         pg: "kcp", // PG사 (필수항목)
              //         pay_method: method, // 결제수단 (필수항목)
              //         merchant_uid: `${sendData.payment_merchant_uid}`, // 결제금액 (필수항목)
              //         name: sendData.payment_name, // 주문명 (필수항목)
              //         amount: sendData.payment_amount, // 금액 (필수항목)
              //         buyer_name: user.ur_name, // 구매자 이름
              //         buyer_tel: user.ur_mobile, // 구매자 전화번호 (필수항목)
              //         buyer_email: user.ur_email, // 구매자 이메일
              //       };
              //       IMP.request_pay(data, callback);
            } else {
              setPopupResult(false);
              Swal.fire({
                title: "투자정보 인력안내",
                html: `이름,성별,연령,나이대,핸드폰,
            <br/>계좌정보를 등록해주세요.`,

                confirmButtonText: "확인",
              }).then((result) => {
                if (result.isConfirmed) {
                  sessionStorage.setItem("savePage", location.pathname);
                  navigate("/mypage/profile");
                }
              });
            }
          }
        );
      }
    }
  }, [popupResult]);
  const callback = (rsp) => {
    if (rsp.success) {
      axios({
        url: "/api/payments/complete", // 예: https://www.myservice.com/payments/complete
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: {
          imp_uid: rsp.imp_uid,
          merchant_uid: rsp.merchant_uid,
        },
      }).then((data) => {
        // setClickTime(true);
        // 서버 결제 API 성공시 로직
        switch (data.data.status) {
          case "vbankIssued":
            // 가상계좌 발급 시 로직
            break;
          case "success":
            // Swal.fire({
            //   title: "결제 완료",
            //   icon: "success",
            //   confirmButtonColor: "#3085d6",
            //   confirmButtonText: "확인",
            //   allowOutsideClick: false,
            // }).then((res) => {
            //   if (res.isConfirmed) {
            //     navigate(`/mypage`);
            //   }
            // });
            // 결제 성공 시 로직
            break;
        }
      });
    } else {
      // setClickTime(true);
      alert(`결제 실패 : ${rsp.error_msg}`);
    }
  };

  const [ch1, setCh1] = useState(false);
  const [ch2, setCh2] = useState(false);
  const [ch3, setCh3] = useState(false);
  const [ch4, setCh4] = useState(false);
  const [ch5, setCh5] = useState(false);
  const [ch6, setCh6] = useState(false);

  const change_ch1 = () => {
    setCh1(!ch1);
  };

  const change_ch2 = () => {
    setCh2(!ch2);
    if (!ch2) {
      // setCh3(true);
      setCh4(true);
      setCh5(true);
      setCh6(true);
    } else {
      // setCh3(false);
      setCh4(false);
      setCh5(false);
      setCh6(false);
    }
  };
  const change_ch3 = () => {
    setCh3(!ch3);
  };

  const change_ch4 = () => {
    setCh4(!ch4);
  };
  const change_ch5 = () => {
    setCh5(!ch5);
  };

  const change_ch6 = () => {
    setCh6(!ch6);
  };
  const wonReplace = () => {
    let won = String(data.pro_target_amount);
    if (won.length === 6) {
      return wonComma(data.pro_target_amount);
    } else if (won.length === 7) return numberToKorean(data.pro_target_amount);
    // return numberToKorean(580000000);
  };
  const wonReplace1 = () => {
    if (data.amount) {
      return numberToKorean(data.amount);
    } else {
      return "0";
    }
  };

  const percent = () => {
    let result = data.pro_target_amount;
    let ing = data.amount;

    return (ing / result) * 100;
    // return 159;
  };

  useEffect(() => {
    if (amount) {
      if (amount[0].amount) {
        setInvCash(amount[0].amount);
      }
      if (amount[0].interest) {
        setProfit(amount[0].interest);
      }
    }

    return () => dispatch(investReset());
  }, [amount]);
  return (
    <>
      {/* <div className={inv.invest}>
        {data && (
          <>
            <div className={inv.invest_in}>
              <div className={inv.invest_top}>
                <div className={inv.invest_text}>
                  {data.pro_category}
                  <em>{data.pro_content}</em>
                </div>
              </div>
              <div className={inv.invest_make_visual}>
                <ul>
                  <li>
                    <span>수익률</span>
                    <div className={inv.num}>
                      <FaArrowUp /> <em>{data.pro_rate}%</em>
                    </div>
                  </li>
                  <li>
                    <span>가입기간</span>
                    <em className={inv.num}>{data.pro_join}개월</em>
                  </li>
                  <li>
                    <span>가입금액</span>
                    <em className={inv.num}>100 ~ 3500만원</em>
                  </li>
                </ul>

                <div className={inv.line_wrap}>
                  <progress
                    className={inv.progress}
                    value={percent()}
                    min="0"
                    max="100"
                  ></progress>
                </div>
                <div className={inv.line_text_box}>
                  <span className={`${inv.per} ${inv.n1}`}>
                    모집률 <CountUp start={0} end={percent()} duration={2} />%
                  </span>
                  <span className={`${inv.per} ${inv.n2}`}>
                    {wonReplace1()} / {wonReplace()}원
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div> */}
      <div className={inv.payment_agree_wrap}>
        <div className={inv.payment_agree_box}>
          <em className={inv.payment_agree_title}>이용약관</em>
          <div className={inv.payment_agree_content}>
            <div className={inv.payment_agree_step}>
              <label
                className={
                  ch1
                    ? `${inv.label_public_box} ${inv.label_color}`
                    : `${inv.label_public_box}`
                }
              >
                <div
                  className={
                    ch1
                      ? `${inv.check_public_box} ${inv.check_color}`
                      : `${inv.check_public_box}`
                  }
                >
                  <TbCircleCheckFilled />
                  <input
                    type="checkbox"
                    style={{ display: "none" }}
                    onClick={change_ch1}
                  />
                </div>

                <span>서비스 이용동의(필수)</span>
              </label>
            </div>
            <div className={inv.payment_agree_step}>
              <label
                className={
                  ch2
                    ? `${inv.label_public_box} ${inv.label_color}`
                    : `${inv.label_public_box}`
                }
              >
                <div
                  className={
                    ch2
                      ? `${inv.check_public_box} ${inv.check_color}`
                      : `${inv.check_public_box}`
                  }
                >
                  <TbCircleCheckFilled />
                  <input
                    type="checkbox"
                    style={{ display: "none" }}
                    onClick={change_ch2}
                  />
                </div>
                <span>전체 선택</span>
              </label>
            </div>
            <div className={inv.payment_agree_step_box}>
              <div className={inv.payment_agree_step_2}>
                {" "}
                <label
                  className={
                    ch4
                      ? `${inv.label_public_box} ${inv.label_color}`
                      : `${inv.label_public_box}`
                  }
                >
                  <div
                    className={
                      ch4
                        ? `${inv.check_public_box} ${inv.check_color}`
                        : `${inv.check_public_box}`
                    }
                  >
                    <TbCheck />
                    <input
                      type="checkbox"
                      style={{ display: "none" }}
                      onClick={change_ch4}
                    />
                  </div>
                  <span>{data1.length > 0 && data1[0].policy_title}</span>
                </label>{" "}
                <a
                  href={
                    data1.length > 0
                      ? `https://meta12.net/policy/${data1[0].no}/${data1[0].policy_pdf}`
                      : ""
                  }
                  target="_blank"
                >
                  보기
                </a>
              </div>
              <div className={inv.payment_agree_step_3}>
                {" "}
                <label
                  className={
                    ch5
                      ? `${inv.label_public_box} ${inv.label_color}`
                      : `${inv.label_public_box}`
                  }
                >
                  <div
                    className={
                      ch5
                        ? `${inv.check_public_box} ${inv.check_color}`
                        : `${inv.check_public_box}`
                    }
                  >
                    <TbCheck />
                    <input
                      type="checkbox"
                      style={{ display: "none" }}
                      onClick={change_ch5}
                    />
                  </div>
                  <span>{data1.length > 0 && data1[1].policy_title}</span>
                </label>{" "}
                <a
                  href={
                    data1.length > 0
                      ? `https://meta12.net/policy/${data1[1].no}/${data1[1].policy_pdf}`
                      : ""
                  }
                  target="_blank"
                >
                  보기
                </a>
              </div>
              <div className={inv.payment_agree_step_4}>
                {" "}
                <label
                  className={
                    ch6
                      ? `${inv.label_public_box} ${inv.label_color}`
                      : `${inv.label_public_box}`
                  }
                >
                  <div
                    className={
                      ch6
                        ? `${inv.check_public_box} ${inv.check_color}`
                        : `${inv.check_public_box}`
                    }
                  >
                    <TbCheck />
                    <input
                      type="checkbox"
                      style={{ display: "none" }}
                      onClick={change_ch6}
                    />
                  </div>
                  <span>{data.details_pdf_title}</span>
                </label>{" "}
                <a
                  href={`https://meta12.net/details/${data.detailsNo}/${data.details_pdf}`}
                  target="_blank"
                >
                  보기
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className={inv.income}>
        <h4>
          가입 금액 입력{" "}
          <span> ＊가입 금액은 최소 10만원 / 최대 1000만원입니다. </span>
        </h4>
        {/* <div className={inv.income_text}>
          <span>{invCash == 0 ? 0 : invCash}만원 투자하면</span>
          <div>
            <em>
              {invCash == 0 ? (
                0
              ) : (
                <CountUp start={starts} end={profit} duration={2} />
              )}
              만원
            </em>
            을 받아요 (세전, 최대)
          </div>
        </div> */}
        <div className={inv.income_input}>
          <span className={inv.front_text}>가입금액</span>
          <div className={inv.text_box}>
            <input
              // placeholder="10만원 ~최대 1000만원"
              type="text"
              className={inv.income_box}
              value={invCash == 0 ? 0 : wonComma(invCash)}
              onChange={chgCash}
            />
            <span className={inv.back_text}>만원</span>
          </div>
        </div>
        <ul className={inv.income_list}>
          <li>
            <button
              type="button"
              className={inv.income_btn}
              onClick={() => setInvCash(Number(invCash) + 10)}
            >
              +10만
            </button>
          </li>
          <li>
            <button
              type="button"
              className={inv.income_btn}
              onClick={() => setInvCash(Number(invCash) + 50)}
            >
              +50만
            </button>
          </li>
          <li>
            <button
              type="button"
              className={inv.income_btn}
              onClick={() => setInvCash(Number(invCash) + 100)}
            >
              +100만
            </button>
          </li>
          <li>
            <button
              type="button"
              className={inv.income_btn}
              onClick={() => setInvCash(Number(invCash) + 1000)}
            >
              +1000만
            </button>
          </li>
        </ul>
        <div className={inv.income_bottom}>
          {" "}
          <div>
            <em>
              {invCash == 0 ? (
                0
              ) : (
                <CountUp start={starts} end={profit} duration={2} />
              )}
              만원
            </em>
            (세전/최대/년)
          </div>
        </div>
      </div>
      <div
        className={inv.make_product}
        style={{ cursor: "pointer" }}
        onClick={onClickPayment}
      >
        {/* <Link to={`/invest/${id}/${id2}/join`}> */}
        <div className={inv.make_product_a}>
          <em className={inv.em_white}>
            가입 진행하기
            <span>
              <MdKeyboardArrowRight />
            </span>
          </em>
          {/* </Link> */}{" "}
        </div>
      </div>
    </>
  );
};

export default ProcessInvest;
