import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../modules/user";
import { useSelector, useDispatch } from "react-redux";
import { encrypt } from "../lib/api/tokenCrypto";
import Swal from "sweetalert2";
import { CommonAxios } from "../components/CommonAxios";
import loginStyle from "../styles/login.module.css";
// export default KakaoLogin;
import { ramdomASCIIDate } from "../lib/radomASCII";
function KakaoLogin(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { user } = useSelector(({ user }) => ({
  //   user: user.user,
  // }));

  const { Kakao } = window;
  const initKakao = () => {
    const jsKey = process.env.REACT_APP_KAKAO_APP_KEY;
    const Kakao = window.Kakao;
    if (Kakao && !Kakao.isInitialized()) {
      Kakao.init(jsKey);
      // console.log(Kakao.isInitialized());
    }
  };

  useEffect(() => {
    initKakao();
  }, []);

  const kakaoLogin = async () => {
    await Kakao.Auth.login({
      success(res) {
        // console.log(res.email);
        Kakao.Auth.setAccessToken(res.access_token);

        Kakao.API.request({
          url: "/v2/user/me",
          success(res) {
            const kakaoAccount = res.kakao_account;
            // console.log(res);

            // console.log(window.Kakao.Auth.getAccessToken());
            // console.log(kakaoAccount);
            let moblieNum = "";
            if (
              kakaoAccount.phone_number !== "" &&
              kakaoAccount.phone_number !== undefined
            ) {
              let mobileSp = kakaoAccount.phone_number.split(" ");
              moblieNum = "0" + mobileSp[1];
            }

            let sendData = {
              id: "kakao" + res.id,
              name: kakaoAccount.profile.nickname,
              email: kakaoAccount.email,
              mobile: moblieNum,
              recomendcode: ramdomASCIIDate(),

              type: 2,
            };

            CommonAxios(
              process.env.REACT_APP_HOSTDONAME + "/api/sns_signin",
              sendData,
              function (result) {
                if (result.messageinfo.state == "ok") {
                  localStorage.setItem(
                    "meta_info",
                    encrypt(result.messageinfo.message, "meta_info")
                  );
                  dispatch(getUsers(result.messageinfo.message));
                  navigate("/");
                } else {
                }
              }
            );
          },
          fail(error) {
            console.log(error);
          },
        });
      },
      fail(error) {
        console.log(error);
      },
    });
  };

  // useEffect(() => {
  //   const error2 = "탈퇴한 계정";

  //   if (user !== null && user === error2) {
  //     Swal.fire({
  //       title: "로그인 실패",
  //       text: "탈퇴한 계정입니다.(고객센터 문의)",
  //       icon: "error",
  //       confirmButtonText: "확인",
  //       confirmButtonColor: "#FF0000",
  //       allowOutsideClick: false,
  //     });
  //     dispatch(logout());
  //   } else if (user !== null && user !== error2) {
  //     localStorage.setItem("serveInfo", encrypt(user, "serveInfo"));
  //     const url = process.env.REACT_APP_HOSTDONAME + "/api/googleMobile";
  //     let config = {
  //       user,
  //     };
  //     axios.post(url, config).then((res) => {
  //       if (res.data.messageinfo.message[0].user_mobile !== "") {
  //         navigate("/");
  //       } else {
  //         Swal.fire({
  //           title: "핸드폰 인증이 필요합니다.",
  //           icon: "error",
  //           confirmButtonText: "확인",
  //           confirmButtonColor: "#FF0000",
  //         }).then((res) => {
  //           if (res.isConfirmed) {
  //             document.location.replace("/myinfo");
  //           }
  //         });
  //       }
  //     });
  //   }
  // }, [navigate, user]);

  return (
    <>
      <div
        className={`${loginStyle.login_btn} ${loginStyle.login_kakao}`}
        onClick={kakaoLogin}
        style={{ cursor: "pointer" }}
      >
        <img
          src={require("../assets/images/kakao_icon.svg").default}
          alt="kakao_icon"
        />
        <p>카카오로 시작하기</p>
      </div>

      {/* <div
        className={`${loginStyle.login_btn} ${loginStyle.login_kakao}`}
        onClick={kakaoLogin}
      >
        <img
          src={require("../assets/images/kakao_icon.svg").default}
          alt="kakao_icon"
        />
      </div> */}
    </>
  );
}

export default KakaoLogin;
