import main from "../../styles/main.module.css";
import MainNewsDetail from "./detail/MainNewsDetail";
import React from "react";
import { CommonAxios } from "../CommonAxios";
import { useState } from "react";
import { Link } from "react-router-dom";
import ex1 from "../../assets/images/Layer 508.png";
import ex2 from "../../assets/images/Layer 509.png";
import ex3 from "../../assets/images/Layer 510.png";
import ex4 from "../../assets/images/Layer 511.png";
import ex5 from "../../assets/images/Layer 512.png";
import ex6 from "../../assets/images/Layer 513.png";
import { BsQuestionCircle, BsQuestionCircleFill } from "react-icons/bs";
import { useEffect } from "react";
import MainNewsSkeleton from "./detail/MainNewsSkeleton";
import { MdError } from "react-icons/md";
import { AiOutlineQuestionCircle, AiFillQuestionCircle } from "react-icons/ai";
import { RiQuestionFill, RiQuestionLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
const MainNews = () => {
  const [mainNews, setMainNews] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  const navigate = useNavigate();
  // CommonAxios(
  //   process.env.REACT_APP_HOSTDONAME + "/api/select_main_notice",
  //   sendData,
  //   function (result) {
  //     if (result.messageinfo.state === "ok") {
  //       setMainNews(result.messageinfo.message);
  //       setSkeleton(true);
  //     } else {
  //       setSkeleton(true);
  //     }
  //   }
  // );

  const data = [
    {
      title: "[허브팩토리] 담수경 수경재배 농장과 함께하는 성장의 즐거움",
      img: ex1,
      per: 38,
      c: "(주)메타그린",
    },
    {
      title: "[창산리 M1팜] 스마트팜 수경재배한 프리미엄 유러피안 채소",
      img: ex2,
      per: 62,
      c: "(주)메타그린",
    },
    {
      title: "[신흥리 M2팜] 스마트팜 수경재배한 프리미엄 유러피안 채소",
      img: ex3,
      per: 36,
      c: "(주)메타그린",
    },
    {
      title: "[카이피라] 무농약 친환경으로 재배한 프리미엄 유러피안 채소",
      img: ex4,
      per: 25,
      c: "(주)메타그린",
    },
    {
      title:
        "[버터헤드] 무농약 친환경으로 재배한 유러피안 채소로 건강에 투자하세요.",
      img: ex5,
      per: 53,
      c: "(주)메타그린",
    },
    {
      title: "[로메인] 무농약 스마트팜 수경재배한 프리미엄 유러피안 채소",
      img: ex6,
      per: 27,
      c: "(주)메타그린",
    },
  ];

  const [item1, setItem1] = useState([]);
  const [item2, setItem2] = useState([]);
  const [item3, setItem3] = useState([]);
  const [count, setCount] = useState(0);
  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_product",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          let data = result.messageinfo.message;
          let dataCnt = result.messageinfo.message.length;

          setMainNews(result.messageinfo.message);
          setSkeleton(true);
          // setUserInfo(result.messageinfo.message[0]);
          // localStorage.removeItem("meta_info");
          // document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  }, []);
  const [brand, setBrand] = useState([]);
  const [skeleton1, setSkeleton1] = useState(false);
  useEffect(() => {
    let sendData = {
      // no: id,
      no: 1,
    };

    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_brand",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setBrand(result.messageinfo.message);
          setSkeleton1(true);
        } else {
        }
      }
    );
  }, []);
  const move_page = () => {
    navigate("/funding");
  };
  return (
    <>
      <h3 className={main.main_title_h3}>새로운 투자상품</h3>

      <div className={main.main_title_div}>
        {" "}
        <span className={main.main_title_sub}>
          성공의 기회를 잡으세요!
        </span>{" "}
        {skeleton1 && (
          <span className={main.main_news_more}>
            <em>{brand[0].brand_text1}</em>
            <em>&nbsp;{brand[0].brand_text2}</em>
            <p className={main.main_svg1_before}>
              <BsQuestionCircle />{" "}
            </p>
            <p className={main.main_svg1_after}>
              <BsQuestionCircleFill />
            </p>
            <div className={main.main_news_open}>
              <a href={`${brand[0].brand_link}`} target="_blank">
                {brand[0].brand_content}
              </a>
            </div>
            {/* <Link to="/notice">더보기</Link>{" "} */}
          </span>
        )}
      </div>

      <div className={main.main_news_wrap}>
        {!skeleton && <MainNewsSkeleton />}
        {skeleton && mainNews.length === 0 && (
          <div className={main.main_not_data}>
            {" "}
            <span className={main.main_not_span}>
              <MdError style={{ marginRight: "4px" }} /> 상품이 등록되어 있지
              않습니다.
            </span>
          </div>
        )}
        {skeleton &&
          mainNews.length > 0 &&
          mainNews.map((data1) => {
            return (
              <React.Fragment>
                <MainNewsDetail data={data1} />
              </React.Fragment>
            );
          })}
        {/* {skeleton && mainNews.length === 0 && (
          <div className="none_view">
            <p>등록된 공지사항이 없습니다.</p>
          </div>
        )} */}
        {/* {!skeleton && (
          <>
            <div
              className={`${main.main_news_item} ${main.news_skeleton}`}
            ></div>
            <div
              className={`${main.main_news_item} ${main.news_skeleton}`}
            ></div>{" "}
            <div
              className={`${main.main_news_item} ${main.news_skeleton}`}
            ></div>
          </>
        )} */}
        <div className={main.main_news_button}>
          <button>
            <a target="_blank" href="https://meta12.net/funding">
              더보기
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default MainNews;
