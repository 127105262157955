import Company from "../components/company/Company";
import { useEffect } from "react";
const CompanyPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return <Company />;
};

export default CompanyPage;
