import fund from "../../../styles/store.module.css";
import { GiFarmTractor, GiLipstick } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcBiotech,
  FcSelfie,
  FcFilmReel,
} from "react-icons/fc";

import SwiperCore, { Virtual, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import img_1 from "../../../assets/images/smart.jpg";
import img_2 from "../../../assets/images/hardware.jpg";
import img_3 from "../../../assets/images/customized.jpg";
import img_4 from "../../../assets/images/franchisee.jpg";
import img_5 from "../../../assets/images/industry.jpg";
import { useState, useEffect, useCallback, useRef } from "react";
import hub from "../../../assets/images/hub.jpg";
import m1 from "../../../assets/images/m1.jpg";
import su1 from "../../../assets/images/su1.png";
import su2 from "../../../assets/images/su2.png";
import kai from "../../../assets/images/kai.png";
import butter from "../../../assets/images/butter.png";
import p from "../../../assets/images/p.png";
import u from "../../../assets/images/u.png";
import easy from "../../../assets/images/easy.png";
import fa from "../../../assets/images/fa.png";
import fa2 from "../../../assets/images/fa2.png";
import fa3 from "../../../assets/images/fa3.png";
import { wonComma } from "../../../lib/wonComma";
import icon1 from "../../../assets/images/Layer 22.png";
import icon2 from "../../../assets/images/Layer 23.png";
import icon3 from "../../../assets/images/Layer 24.png";
import icon4 from "../../../assets/images/Layer 25.png";
import icon5 from "../../../assets/images/Layer 26.png";
import icon6 from "../../../assets/images/Layer 27.png";
import icon7 from "../../../assets/images/Layer 28.png";
import icon8 from "../../../assets/images/Layer 29.png";
import icon9 from "../../../assets/images/Layer 30.png";
import { MdBiotech, MdOutlineHomeWork } from "react-icons/md";
import { AiFillHeart, AiOutlineYoutube } from "react-icons/ai";
import { CiCoffeeCup } from "react-icons/ci";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { CommonAxios } from "../../CommonAxios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaStar } from "react-icons/fa";

import $ from "jquery";
const StoreSlider = ({ cate }) => {
  const slickRef = useRef(null);

  const previous = useCallback(() => slickRef.current.slickPrev(), []);
  const next = useCallback(() => slickRef.current.slickNext(), []);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 11,
    slidesToScroll: 11,
    // swipeToSlide: true,
    initialSlide: 0,
    arrows: false,
  };
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));

  const navigate = useNavigate();
  const FundItem = ({ data1 }) => {
    const [mouse, setMouse] = useState(
      data1.sw ? (data1.sw === "true" ? true : false) : false
    );
    const [timer, setTimer] = useState(0);

    const onCLikcZzim = async () => {
      if (user === null) {
        return;
      }

      setMouse(!mouse);
      if (timer) {
        clearTimeout(timer);
      }
      const dibisHandle = () => {
        setMouse(!mouse);

        return mouse;
      };

      const newTimer = setTimeout(async () => {
        try {
          const dibisBoolean = dibisHandle();

          let sendData = {
            no: user.no,
            bool: !dibisBoolean,
            dataNo: data1.no,
            kind: 2,
          };
          CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/main_like_boolean",
            sendData,
            function (result) {
              if (result.messageinfo.state === "ok") {
              } else {
                //   alert(result.messageinfo.message);
              }
            }
          );

          // console.log(dibisBoolean);
          // if (dibisBoolean === false) {
          // } else {
          //   setMouse(false);
          // }
          // await CommonGetToken(myDibis(dibisBoolean));

          // await myDibis();
        } catch (e) {
          console.error("error", e);
        }
      }, 500);
      setTimer(newTimer);
    };

    const btn_move = (link, no) => {
      let sendData = {
        no: no,
      };
      CommonAxios(
        process.env.REACT_APP_HOSTDONAME + "/api/main_store_click",
        sendData,
        function (result) {
          if (result.messageinfo.state === "ok") {
          } else {
            //   alert(result.messageinfo.message);
          }
        }
      );

      window.open(link);
      // navigate(`/notice/${no}`);
    };

    return (
      <>
        <div className={fund.funding_inner_item}>
          {/* <a href={data1.store_link} target="_blank"> */}
          <div className={fund.funding_inner_imgbox}>
            {" "}
            {!mouse && (
              <div className={fund.funding_heart} onClick={onCLikcZzim}>
                <AiFillHeart />
              </div>
            )}
            {mouse && (
              <div className={fund.funding_heart_color} onClick={onCLikcZzim}>
                <AiFillHeart />
              </div>
            )}
            <img
              className={fund.funding_inner_img}
              src={`/store/${data1.no}/${data1.store_img}`}
              onClick={() => btn_move(data1.store_link, data1.no)}
            />
          </div>
          <div
            className={fund.funding_inner_cont}
            onClick={() => btn_move(data1.store_link)}
          >
            <div className={fund.funding_inner_top}>
              <div className={fund.funding_inner_left}>
                <span>{wonComma(data1.store_amount)}원</span>
              </div>
              {/* <p className={fund.funding_inner_right}>
                  <span>
                    {" "}
                    <FaStar /> {data1.store_grade}
                  </span>
                </p> */}
            </div>
            <div className={fund.funding_inner_middle}>
              <span>
                [{data1.category_name}] {data1.store_title}
              </span>
            </div>
            {/* <div className={fund.funding_inner_bottom}>
                <p>{wonComma(data1.store_people)}명 인증</p>
                <p>
                  <FaStar /> {data1.store_grade}
                </p>
              </div> */}
          </div>
          {/* </a> */}
        </div>
      </>
    );
  };
  const FundSkeleton = () => {
    return (
      <>
        <div className={fund.funding_inner_item}>
          <div
            className={`${fund.funding_inner_imgbox} ${fund.fund_skeleton_img}`}
          ></div>
          <div className={fund.funding_inner_cont}>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div className={fund.funding_inner_bottom}></div>
          </div>
        </div>{" "}
        <div className={fund.funding_inner_item}>
          <div
            className={`${fund.funding_inner_imgbox} ${fund.fund_skeleton_img}`}
          ></div>
          <div className={fund.funding_inner_cont}>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div className={fund.funding_inner_bottom}></div>
          </div>
        </div>{" "}
        <div className={fund.funding_inner_item}>
          <div
            className={`${fund.funding_inner_imgbox} ${fund.fund_skeleton_img}`}
          ></div>
          <div className={fund.funding_inner_cont}>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div className={fund.funding_inner_bottom}></div>
          </div>
        </div>{" "}
        <div className={fund.funding_inner_item}>
          <div
            className={`${fund.funding_inner_imgbox} ${fund.fund_skeleton_img}`}
          ></div>
          <div className={fund.funding_inner_cont}>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div className={fund.funding_inner_bottom}></div>
          </div>
        </div>{" "}
        <div className={fund.funding_inner_item}>
          <div
            className={`${fund.funding_inner_imgbox} ${fund.fund_skeleton_img}`}
          ></div>
          <div className={fund.funding_inner_cont}>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div className={fund.funding_inner_bottom}></div>
          </div>
        </div>{" "}
        <div className={fund.funding_inner_item}>
          <div
            className={`${fund.funding_inner_imgbox} ${fund.fund_skeleton_img}`}
          ></div>
          <div className={fund.funding_inner_cont}>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div
              className={`${fund.funding_inner_top} ${fund.fund_skeleton_div}`}
            ></div>
            <div className={fund.funding_inner_bottom}></div>
          </div>
        </div>
      </>
    );
  };

  const [liClick, setLiClick] = useState("전체");
  const [order, setOrder] = useState("1");
  const [data, setData] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  useEffect(() => {
    setSkeleton(false);
    let sendData = {
      no: "",
      data: liClick,
      order: order,
    };
    if (user) {
      sendData.no = user.no;
    }
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_store_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
          setSkeleton(true);
          // setUserInfo(result.messageinfo.message[0]);
          // localStorage.removeItem("meta_info");
          // document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  }, [liClick, order]);
  const click_menu = (data) => {
    setOrder(data);
  };

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  // var snbSwiperItem = $(".mySwiper .swiper-wrapper .swiper-slide a");
  // snbSwiperItem.click(function () {
  //   var target = $(this).parent();
  //   snbSwiperItem.parent().removeClass("on");
  //   target.addClass("on");
  //   muCenter(target);
  // });

  // function muCenter(target) {
  //   console.log("?");
  //   var snbwrap = $(".mySwiper .swiper-wrapper");
  //   var targetPos = target.position();
  //   var box = $(".mySwiper");
  //   var boxHarf = box.width() / 2;
  //   var pos;
  //   var listWidth = 0;

  //   snbwrap.find(".swiper-slide").each(function () {
  //     listWidth += $(this).outerWidth();
  //   });

  //   var selectTargetPos = targetPos.left + target.outerWidth() / 2;
  //   if (selectTargetPos <= boxHarf) {
  //     // left
  //     pos = 0;
  //   } else if (listWidth - selectTargetPos <= boxHarf) {
  //     //right
  //     pos = listWidth - box.width();
  //   } else {
  //     pos = selectTargetPos - boxHarf;
  //   }

  //   setTimeout(function () {
  //     snbwrap.css({
  //       transform: "translate3d(" + pos * -1 + "px, 0, 0)",
  //       "transition-duration": "500ms",
  //     });
  //   }, 200);
  // }

  return (
    <div className={fund.funding_wrap}>
      <div className={fund.funding_category_section}>
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          width="27"
          height="27"
          viewBox="0 0 27 27"
        >
          <image
            id="Layer_11"
            data-name="Layer 11"
            width="27"
            height="27"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAABHNCSVQICAgIfAhkiAAAAd1JREFUSEvdVTlLg0EQTSwUD/QXiIUW3geKByja+VM8sBG0srC30EIUC/+Ggo2IFyoS74uABu3UQsTSJL4ns7Dst0nGBL6ACy+7mZ23M7Oz30w0EoksA1OAZjxCqR94E+VVzBMaInQeovi5AlqVBKrR2LHo32Ju0nJpLAZ0CeEU8wdAOUda1n2Yq0TG9YmsLzC3ix4d+PJw6Vwl8O0asw+yHT7Hn44sxlLYawTinijNzQWMDUL5wEMwEXDLFxmNtQG8VnuYNLX4IhuCcD9PY7zOG4dbgv+XQPGNdcMLPhh3nEHQmSNn9dhPeLiMjFccyNkchHeAeY2Gu4hFXRZjfLXTwLPD5TlLQK0xZiff41hANADJkUivJR8aXpKW9wC+Qu1oluipfwjQuGakaawXmAXMR5uJyOe9CbBE8do4+BnMKLkbbm40HuatE7qxUrg6ArB+metxvadTSYCfwIu1WYb1sJIb4yErwKTybhKSp1fRX8M8puTGi9pituHlu8fTUchqrBfoazFb2P90uAyG3GogUEEKKVcNOPDJ42jGcvV/q36okRWSsz+3mHkk+N6T5AXIcrUY1lf7g+cx7NTkht5iUmG2mF9jzNM4UJGj7LDF7ALrgKmhPcItV3B3fgCC3bNjKQghfwAAAABJRU5ErkJggg=="
          />
        </svg> */}
        <nav className={fund.funding_category_content}>
          <Swiper
            className="mySwiper"
            slidesPerView={4}
            ref={setSwiper}
            {...parmss}
            style={{ position: "relative" }}
            // history={{
            //   key: "slide",
            // }}
            // touchEventsTarget={"wrapper"}
            // touchStartForcePreventDefault={true}
            // clickable={true}
            preventClicks={true}
            preventClicksPropagation={false}
            observer={true}
            observeParents={true}
            modules={[Navigation]}
            breakpoints={{
              430: {
                slidesPerView: 5,
              },
              500: {
                slidesPerView: 6,
              },
              650: {
                slidesPerView: 7,
              },
              720: {
                slidesPerView: 9,
              },
              800: {
                slidesPerView: 10,
              },
              1100: {
                slidesPerView: 11,
              },
            }}
          >
            {cate &&
              cate.map((data) => {
                return (
                  <SwiperSlide>
                    <div
                      className={fund.funding_category_li}
                      onClick={() => {
                        setLiClick(data.category_name);
                      }}
                    >
                      <div
                        className={
                          liClick === data.category_name
                            ? `${fund.funding_category_img} ${fund.active}`
                            : `${fund.funding_category_img}`
                        }
                      >
                        <img
                          src={`/category/${data.no}/${data.category_img}`}
                        />
                      </div>
                      <h3 className={fund.funding_category_h3}>
                        {data.category_name}
                      </h3>
                    </div>{" "}
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </nav>
        <ButtonPrev1
          ref={prevRef}

          // style={{ width: "100px", height: "100px", background: "blue" }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MdKeyboardArrowLeft />{" "}
          </div>
        </ButtonPrev1>{" "}
        {/* <div
                ref={prevRef}
                style={{ width: "100px", height: "100px", background: "blue" }}
              ></div> */}
        <ButtonNext1 ref={nextRef}>
          {" "}
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MdKeyboardArrowRight />
          </div>
        </ButtonNext1>
      </div>
      <div className={fund.funding_content_section}>
        <div className={fund.funding_content_top}>
          <p className={fund.funding_content_title}>{liClick}</p>
          <nav className={fund.funding_content_nav}>
            <ul className={fund.funding_content_ul}>
              <li
                className={
                  order === "1"
                    ? `${fund.funding_content_li} ${fund.funding_content_li_active}`
                    : `${fund.funding_content_li}`
                }
                onClick={() => click_menu("1")}
              >
                최신순
              </li>
              <li
                className={
                  order === "2"
                    ? `${fund.funding_content_li} ${fund.funding_content_li_active}`
                    : `${fund.funding_content_li}`
                }
                onClick={() => click_menu("2")}
              >
                최고가
              </li>
              <li
                className={
                  order === "3"
                    ? `${fund.funding_content_li} ${fund.funding_content_li_active}`
                    : `${fund.funding_content_li}`
                }
                onClick={() => click_menu("3")}
              >
                최저가
              </li>
            </ul>
          </nav>
        </div>{" "}
        {!skeleton && (
          <div className={`${fund.funding_inner_list}`}>
            {" "}
            <FundSkeleton />{" "}
          </div>
        )}{" "}
        {skeleton && data.length === 0 && (
          <div className={`${fund.funding_inner_list} ${fund.content_not}`}>
            <span>상품이 존재하지 않습니다.</span>
          </div>
        )}
        {skeleton && data.length > 0 && (
          <div className={fund.funding_content_inner}>
            <div className={`${fund.funding_inner_list}`}>
              {data &&
                data.map((data1) => {
                  return <FundItem data1={data1} />;
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const ButtonPrev1 = styled.div`
  left: 0;
  position: absolute;
  z-index: 1;
  top: 24px;
  font-size: 24px;
  cursor: pointer;
`;
const ButtonNext1 = styled.div`
  right: 0;
  position: absolute;
  z-index: 1;
  top: 24px;
  font-size: 24px;
  cursor: pointer;
`;

export default StoreSlider;
