import styles from "../../../styles/mypage.module.css";
import { FaWonSign } from "react-icons/fa";
import { BsFillBox2HeartFill, BsFillQuestionSquareFill } from "react-icons/bs";
import { useState, useEffect, useCallback } from "react";
import Moment from "react-moment";
import InvestCustomTable from "../table/InvestCustomTable";
import { CommonAxios } from "../../CommonAxios";
import { useSelector } from "react-redux";
import { numberToKorean } from "../../../lib/numberToKorean";
import { wonComma } from "../../../lib/wonComma";
import CountUp from "react-countup";
import moment from "moment";
import { IoIosCopy } from "react-icons/io";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const InvestDetailCom = ({ data, yn, info, myAccount }) => {
  const navigate = useNavigate();
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const [getInterest, setGetInterest] = useState("");
  const [mobileGetNum, setMobileGetNum] = useState("");

  const changeAge = useCallback((e) => {
    setGetInterest(e.target.value);
  }, []);

  const click_interest = () => {
    if (endCount === 0) {
      return;
    }
    if (data.invest_interest_withdraw !== "N") {
      return Swal.fire({
        title: "출금 신청 실패",
        html: `현재 ${
          data.invest_interest_withdraw === "Y"
            ? "이자"
            : data.invest_interest_withdraw === "C"
            ? "취소"
            : "환불"
        } 신청 진행을 하였습니다. 승인 후 이용가능합니다.
          `,

        confirmButtonText: "확인",
      });
    }
    if (endCount < 5000) {
      return Swal.fire({
        title: "이자 출금 신청",
        text: "이자 출금은 5000원 이상부터만 가능합니다.",
        confirmButtonText: "확인",
      });
    } else {
      let inputNum = "";
      let inputName = "";
      let inputBank = "";
      let inputAccount = "";
      let mobile = "";
      let mobileN = "";

      Swal.fire({
        title: "이자 출금 신청",
        showCancelButton: true,
        html: `아래 계좌로 입금 신청하시겠습니까?.<br/>
            <div style=" color:#00c73c; font-size:14px; "> (계좌 정보가 틀렸다면 마이페이지에서 변경하세요.)</div>

          



            <p style="text-align:center; margin-top:2px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">예금주</label><br/> ${myAccount[0].user_acName}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">은행 : </label> <br/>${myAccount[0].user_acBank}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">계좌번호 : </label><br/> ${myAccount[0].user_account}<p>
            <p style="text-align:center; margin-top:2px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">출금할 이자</label><br/>
               <input
                 type="text"
                 placeholder="숫자만 입력하세요"
                 class="swal2-input"
                 id="num-value4"></p>`,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
        didOpen: () => {
          const inputNumber = Swal.getPopup().querySelector("#num-value4");
          inputNumber.addEventListener("input", (e) => {
            inputNumber.value = wonComma(e.target.value.replace(/[^\d]+/g, ""));
          });
        },
        preConfirm: (data) => {
          const inputNumber =
            Swal.getHtmlContainer().querySelector("#num-value4");

          if (!/[0-9]/g.test(inputNumber.value.replace(/[^\d]+/g, ""))) {
            Swal.showValidationMessage(`숫자만 입력하세요.`);
          } else if (inputNumber.value.replace(/[^\d]+/g, "") > endCount) {
            Swal.showValidationMessage(`범위내에서만 입력하세요.`);
          } else if (inputNumber.value.replace(/[^\d]+/g, "") < 5000) {
            Swal.showValidationMessage(`5,000원 이상만 출금 가능합니다.`);
          } else {
            inputNum = inputNumber.value.replace(/[^\d]+/g, "");
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "이자 출금 신청",
            showCancelButton: true,
            // icon: "error",
            html: ` <p style="text-align:center; font-size:14px;">
              본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
              <label>핸드폰 번호</label>
              <input type="text" placeholder="하이픈(-)없이 입력해주세요" class="swal2-input" id="num-value5" />  
               `,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
            preConfirm: (data) => {
              const mobileNum =
                Swal.getHtmlContainer().querySelector("#num-value5");

              if (!/[0-9]/g.test(mobileNum.value)) {
                Swal.showValidationMessage(`숫자만 입력하세요.`);
              } else {
                mobile = mobileNum.value;
                let sendData = {
                  mobileNum: mobileNum.value,
                };
                CommonAxios(
                  process.env.REACT_APP_HOSTDONAME + "/api/send_sms",
                  sendData,
                  function (result) {
                    // console.log(result);
                    if (result.messageinfo.state == "ok") {
                      mobileN = result.messageinfo.message;
                    } else {
                      Swal.showValidationMessage(`숫자만 입력하세요.`);
                    }
                  }
                );
              }
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: "이자 출금 신청",
                showCancelButton: true,
                // icon: "error",
                html: ` <p style="text-align:center; font-size:14px;">
                  본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
                  <label>인증 번호</label>
                  <input type="text" value='${mobileN}' class="swal2-input" id="num-value6" />  
                   `,
                confirmButtonText: "확인",
                cancelButtonText: "취소",
                preConfirm: (data) => {
                  const mobileNumA =
                    Swal.getHtmlContainer().querySelector("#num-value6");

                  if (!/[0-9]/g.test(mobileNumA.value)) {
                    Swal.showValidationMessage(`숫자만 입력하세요.`);
                  } else {
                    if (Number(mobileN) === Number(mobileNumA.value)) {
                    } else {
                      Swal.showValidationMessage(
                        `인증번호가 일치하지 않습니다.`
                      );
                    }
                  }
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  var sendData = {
                    with_user_id: user.no,
                    with_invest_no: data.invest_wait_id,
                    with_product_id: data.invest_product_no,
                    with_account: myAccount[0].user_account,
                    with_bank: myAccount[0].user_acBank,
                    with_name: myAccount[0].user_acName,
                    with_mobile: mobile.replace(
                      /^(\d{2,3})(\d{3,4})(\d{4})$/,
                      `$1-$2-$3`
                    ),
                    with_invest_amount: inputNum,
                    with_all_yn: "Y",
                  };
                  // setMobileGetNum("123456");
                  CommonAxios(
                    process.env.REACT_APP_HOSTDONAME +
                      "/api/myInvest_insert_withdraw",
                    sendData,
                    function (result) {
                      // console.log(result);
                      if (result.messageinfo.state == "ok") {
                        let sendData2 = {
                          mobile: myAccount[0].user_mobile,
                          email: myAccount[0].user_email,
                          getamount: wonComma(inputNum),
                          price: wonComma(data.invest_amount),
                          type: "이자",
                          name: myAccount[0].user_name,
                        };
                        CommonAxios(
                          process.env.REACT_APP_HOSTDONAME +
                            "/api/user_invest_comfirm_send",
                          sendData2,
                          function (result) {
                            if (result.messageinfo.state === "ok") {
                            }
                          }
                        );
                        Swal.fire({
                          title: "이자 출금 신청 완료",

                          confirmButtonText: "확인",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            navigate("/mypage/invest");
                          }
                        });
                      } else {
                      }
                    }
                  );
                }
              });
            }
          });
        }
      });
      // Swal.fire({
      //   title: "이자 출금 신청",
      //   showCancelButton: true,
      //   html: `출금할 이자를 입력하세요<br/>
      //   <p style="text-align:center; font-size:14px; margin-top:5px; color:#00c73c; font-weight:500;"><label style="color:black;">현재 이자 : </label> ${wonComma(
      //     endCount
      //   )} 원<p>`,
      //   input: "text",
      //   inputPlaceholder: "숫자만 입력하세요.",
      //   confirmButtonText: "확인",
      //   cancelButtonText: "취소",
      //   formFields: [
      //     { id: "name", placeholder: "Name Field" },
      //     { id: "nickname", placeholder: "Add a cool nickname" },
      //   ],
      //   preConfirm: (data) => {
      //     if (!/[0-9]/g.test(data)) {
      //       Swal.showValidationMessage(`숫자만 입력하세요.`);
      //     }
      //     if (data > endCount) {
      //       Swal.showValidationMessage(`범위내에서만 입력하세요.`);
      //     }
      //   },
      //   allowOutsideClick: () => !Swal.isLoading(),
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     Swal.fire({
      //       title: "이자 출금 신청",
      //       showCancelButton: true,
      //       html: `본인 확인을 위해 휴대폰 번호 인증을 해주세요.<br/>
      //       <p style="text-align:center; font-size:14px; margin-top:5px; color:#00c73c; font-weight:500;"><label style="color:black;">현재 이자 : </label> ${wonComma(
      //         endCount
      //       )} 원<p>`,
      //       input: "text",
      //       inputPlaceholder: "숫자만 입력하세요.",
      //       confirmButtonText: "확인",
      //       cancelButtonText: "취소",
      //     });
      //   }
      // });
    }
  };

  const click_all_interest = () => {
    if (endCount === 0) {
      return;
    }
    if (data.invest_interest_withdraw !== "N") {
      return Swal.fire({
        title: "출금 신청 실패",
        html: `현재 ${
          data.invest_interest_withdraw === "Y"
            ? "이자"
            : data.invest_interest_withdraw === "C"
            ? "취소"
            : "환불"
        } 신청 진행을 하였습니다. 승인 후 이용가능합니다.
          `,

        confirmButtonText: "확인",
      });
    }
    Swal.fire({
      title: "전액 출금 신청",
      showCancelButton: true,
      // icon: "error",
      html: `전액 중도 출금시 상품 해지와 동시에 ${
        info[0].acc_refund_percent
      }%의 위약금이 붙습니다.<br/>
        해지하시겠습니까?<br/>
        <p style="text-align:center; margin-top:5px; color:#00c73c; font-weight:500;"><label style="color:black;">투자금 : </label> ${wonComma(
          data.invest_amount
        )} 원<p>
        <p style="text-align:center; margin-top:5px; color:#00c73c; font-weight:500;"><label style="color:black;">현재 이자 : </label> ${wonComma(
          endCount
        )} 원<p>
        <p style="text-align:center; margin-top:5px; color:#00c73c; font-weight:500;"><label style="color:black;">수수료 제외한 비용 : </label> ${wonComma(
          data.invest_amount +
            Math.floor(endCount) -
            (data.invest_amount + Math.floor(endCount)) *
              (info[0].acc_refund_percent / 100)
        )}원 <p>`,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((res) => {
      if (res.isConfirmed) {
        let inputNum = "";
        let inputName = "";
        let inputBank = "";
        let inputAccount = "";
        let mobile = "";
        let mobileN = "";
        Swal.fire({
          title: "전액 출금 신청",
          showCancelButton: true,
          html: `아래 계좌로 입금 신청하시겠습니까?.<br/>
            <div style=" color:#00c73c; font-size:14px; "> (계좌 정보가 틀렸다면 마이페이지에서 변경하세요.)</div>

          



            <p style="text-align:center; margin-top:2px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">예금주</label><br/> ${myAccount[0].user_acName}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">은행 : </label> <br/>${myAccount[0].user_acBank}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">계좌번호 : </label><br/> ${myAccount[0].user_account}<p>`,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "전액 출금 신청",
              showCancelButton: true,
              // icon: "error",
              html: ` <p style="text-align:center; font-size:14px;">
              본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
              <label>핸드폰 번호</label>
              <input type="text" placeholder="하이픈(-)없이 입력해주세요" class="swal2-input" id="num-value5" />  
               `,
              confirmButtonText: "확인",
              cancelButtonText: "취소",
              preConfirm: (data) => {
                const mobileNum =
                  Swal.getHtmlContainer().querySelector("#num-value5");

                if (!/[0-9]/g.test(mobileNum.value)) {
                  Swal.showValidationMessage(`숫자만 입력하세요.`);
                } else {
                  mobile = mobileNum.value;
                  let sendData = {
                    mobileNum: mobileNum.value,
                  };
                  CommonAxios(
                    process.env.REACT_APP_HOSTDONAME + "/api/send_sms",
                    sendData,
                    function (result) {
                      // console.log(result);
                      if (result.messageinfo.state == "ok") {
                        mobileN = result.messageinfo.message;
                      } else {
                        Swal.showValidationMessage(`숫자만 입력하세요.`);
                      }
                    }
                  );
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire({
                  title: "전액 출금 신청",
                  showCancelButton: true,
                  // icon: "error",
                  html: ` <p style="text-align:center; font-size:14px;">
                  본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
                  <label>인증 번호</label>
                  <input type="text" value='${mobileN}'  class="swal2-input" id="num-value6" />  
                   `,
                  confirmButtonText: "확인",
                  cancelButtonText: "취소",
                  preConfirm: (data) => {
                    const mobileNumA =
                      Swal.getHtmlContainer().querySelector("#num-value6");

                    if (!/[0-9]/g.test(mobileNumA.value)) {
                      Swal.showValidationMessage(`숫자만 입력하세요.`);
                    } else {
                      if (Number(mobileN) === Number(mobileNumA.value)) {
                      } else {
                        Swal.showValidationMessage(
                          `인증번호가 일치하지 않습니다.`
                        );
                      }
                    }
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    var sendData = {
                      end_user_id: user.no,
                      end_invest_no: data.invest_wait_id,
                      end_product_id: data.invest_product_no,
                      end_account: myAccount[0].user_account,
                      end_bank: myAccount[0].user_acBank,
                      end_name: myAccount[0].user_acName,
                      end_mobile: mobile.replace(
                        /^(\d{2,3})(\d{3,4})(\d{4})$/,
                        `$1-$2-$3`
                      ),
                      end_amount: data.invest_amount,
                      end_interest_result: Math.floor(endCount),
                      end_rate: data.pro_rate,
                      end_percent: info[0].acc_refund_percent,
                      end_amount_result:
                        data.invest_amount +
                        Math.floor(endCount) -
                        (data.invest_amount + Math.floor(endCount)) *
                          (info[0].acc_refund_percent / 100),
                      end_kind: "R",
                    };
                    // setMobileGetNum("123456");
                    CommonAxios(
                      process.env.REACT_APP_HOSTDONAME +
                        "/api/myInvest_insert_end",
                      sendData,
                      function (result) {
                        // console.log(result);
                        if (result.messageinfo.state == "ok") {
                          let sendData2 = {
                            mobile: myAccount[0].user_mobile,
                            email: myAccount[0].user_email,
                            getamount: wonComma(
                              data.invest_amount +
                                Math.floor(endCount) -
                                (data.invest_amount + Math.floor(endCount)) *
                                  (info[0].acc_refund_percent / 100)
                            ),
                            price: wonComma(data.invest_amount),
                            type: "환불",
                            name: myAccount[0].user_name,
                          };
                          CommonAxios(
                            process.env.REACT_APP_HOSTDONAME +
                              "/api/user_invest_comfirm_send",
                            sendData2,
                            function (result) {
                              if (result.messageinfo.state === "ok") {
                              }
                            }
                          );

                          Swal.fire({
                            title: "전액 출금(환불) 신청 완료",

                            confirmButtonText: "확인",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              // let sendData = {
                              //   no: user.no,
                              // };
                              // // dispatch(selectInvest(sendData));
                              // CommonAxios(
                              //   process.env.REACT_APP_HOSTDONAME +
                              //     "/api/myInvest_select_join",
                              //   sendData,
                              //   function (result) {
                              //     if (result.messageinfo.state === "ok") {
                              //       setData(result.messageinfo.message);
                              //       setInfo(result.messageinfo.message_data2);
                              //       setMyAccount(
                              //         result.messageinfo.message_data3
                              //       );
                              //       setSkeleton(true);
                              //     } else {
                              //     }
                              //   }
                              // );
                              // let sendData = {
                              //   // no: id,
                              //   no: user.no,
                              // };
                              // // dispatch(selectInvest(sendData));
                              // CommonAxios(
                              //   process.env.REACT_APP_HOSTDONAME +
                              //     "/api/myInvest_select_join",
                              //   sendData,
                              //   function (result) {
                              //     if (result.messageinfo.state === "ok") {
                              //       setData(result.messageinfo.message);
                              //       setSkeleton(true);
                              //     } else {
                              //     }
                              //   }
                              // );
                            }
                          });
                        } else {
                        }
                      }
                    );
                  }
                });
              }
            });
          }
        });
      }
    });
  };
  const totalSeconds = 86400;
  const [count, setCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [switchs, setSwtichs] = useState(true);

  useEffect(() => {
    const startSecond = new Date(
      moment().format("YYYY-MM-DD 00:00:00")
    ).getTime();

    const tenSecUp = (data.invest_interest_ing / totalSeconds) * 1; // 10초당 올라갈 숫자
    const interval = setInterval(() => {
      // console.log(tenSecUp);
      const elapsedMSec = (new Date().getTime() - startSecond) / 1000;

      const currentCount = (elapsedMSec / 1) * tenSecUp; // 현재 숫자 계산
      const endCounts = (elapsedMSec / 1 + 1) * tenSecUp; // 다음 숫자 계산

      setCount(data.invest_interest_sum + currentCount);

      setEndCount(data.invest_interest_sum + endCounts);
    }, 1000); // 10초마다 count 값 갱신

    return () => {
      clearInterval(interval);
    };
  }, []);

  const click_cancel = () => {
    if (endCount === 0) {
      return;
    }
    if (data.invest_interest_withdraw !== "N") {
      return Swal.fire({
        title: "취소 신청 실패",
        html: `현재 이미 ${
          data.invest_interest_withdraw === "Y"
            ? "이자"
            : data.invest_interest_withdraw === "C"
            ? "취소"
            : "환불"
        } 신청 진행을 하였습니다.
          `,

        confirmButtonText: "확인",
      });
    }
    Swal.fire({
      title: "투자 취소하기",
      showCancelButton: true,
      // icon: "error",
      html: `투자를 취소하시겠습니까?<br/>
    
       `,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((res) => {
      if (res.isConfirmed) {
        let date = new Date(data.invest_start_date);

        const diffDate = new Date().getTime() - date.getTime();

        if (Math.abs(diffDate / (1000 * 60 * 60 * 24)) > 3) {
          return Swal.fire({
            title: "투자 취소 불가",
            html: `이틀이 지났습니다.`,

            confirmButtonText: "확인",
          });
        }

        let inputNum = "";
        let inputName = "";
        let inputBank = "";
        let inputAccount = "";
        let mobile = "";
        let mobileN = "";
        Swal.fire({
          title: "투자 취소 신청",
          showCancelButton: true,
          html: `아래 계좌로 입금 신청하시겠습니까?.<br/>
            <div style=" color:#00c73c; font-size:14px; "> (계좌 정보가 틀렸다면 마이페이지에서 변경하세요.)</div>

          



            <p style="text-align:center; margin-top:2px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">예금주</label><br/> ${myAccount[0].user_acName}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">은행 : </label> <br/>${myAccount[0].user_acBank}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">계좌번호 : </label><br/> ${myAccount[0].user_account}<p>`,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "투자 취소 신청",
              showCancelButton: true,
              // icon: "error",
              html: ` <p style="text-align:center; font-size:14px;">
                본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
                <label>핸드폰 번호</label>
                <input type="text" placeholder="하이픈(-)없이 입력해주세요" class="swal2-input" id="num-value5" />  
                 `,
              confirmButtonText: "확인",
              cancelButtonText: "취소",
              preConfirm: (data) => {
                const mobileNum =
                  Swal.getHtmlContainer().querySelector("#num-value5");

                if (!/[0-9]/g.test(mobileNum.value)) {
                  Swal.showValidationMessage(`숫자만 입력하세요.`);
                } else {
                  mobile = mobileNum.value;
                  let sendData = {
                    mobileNum: mobileNum.value,
                  };
                  CommonAxios(
                    process.env.REACT_APP_HOSTDONAME + "/api/send_sms",
                    sendData,
                    function (result) {
                      // console.log(result);
                      if (result.messageinfo.state == "ok") {
                        mobileN = result.messageinfo.message;
                      } else {
                        Swal.showValidationMessage(`숫자만 입력하세요.`);
                      }
                    }
                  );
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire({
                  title: "투자 취소 신청",
                  showCancelButton: true,
                  // icon: "error",
                  html: ` <p style="text-align:center; font-size:14px;">
                    본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
                    <label>인증 번호</label>
                    <input type="text" value='${mobileN}'  class="swal2-input" id="num-value6" />  
                     `,
                  confirmButtonText: "확인",
                  cancelButtonText: "취소",
                  preConfirm: (data) => {
                    const mobileNumA =
                      Swal.getHtmlContainer().querySelector("#num-value6");

                    if (!/[0-9]/g.test(mobileNumA.value)) {
                      Swal.showValidationMessage(`숫자만 입력하세요.`);
                    } else {
                      if (Number(mobileN) === Number(mobileNumA.value)) {
                      } else {
                        Swal.showValidationMessage(
                          `인증번호가 일치하지 않습니다.`
                        );
                      }
                    }
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    var sendData = {
                      // with_user_id: user.no,
                      // with_invest_no: data.invest_wait_id,
                      // with_product_id: data.invest_product_no,
                      // with_account: myAccount[0].user_account,
                      // with_bank: myAccount[0].user_acBank,
                      // with_name: myAccount[0].user_acName,
                      // with_mobile: mobile.replace(
                      //   /^(\d{2,3})(\d{3,4})(\d{4})$/,
                      //   `$1-$2-$3`
                      // ),
                      // with_invest_amount: data.invest_amount,
                      // with_all_yn: "C",.

                      end_user_id: user.no,
                      end_invest_no: data.invest_wait_id,
                      end_product_id: data.invest_product_no,
                      end_account: myAccount[0].user_account,
                      end_bank: myAccount[0].user_acBank,
                      end_name: myAccount[0].user_acName,
                      end_mobile: mobile.replace(
                        /^(\d{2,3})(\d{3,4})(\d{4})$/,
                        `$1-$2-$3`
                      ),
                      end_amount: data.invest_amount,
                      end_interest_result: 0,
                      end_rate: data.pro_rate,
                      end_percent: info[0].acc_refund_percent,
                      end_amount_result: data.invest_amount,
                      end_kind: "C",
                    };
                    // setMobileGetNum("123456");
                    CommonAxios(
                      process.env.REACT_APP_HOSTDONAME +
                        "/api/myInvest_insert_end",
                      sendData,
                      function (result) {
                        // console.log(result);
                        if (result.messageinfo.state == "ok") {
                          let sendData2 = {
                            mobile: myAccount[0].user_mobile,
                            email: myAccount[0].user_email,
                            getamount: wonComma(data.invest_amount),
                            price: wonComma(data.invest_amount),
                            type: "취소",
                            name: myAccount[0].user_name,
                          };
                          CommonAxios(
                            process.env.REACT_APP_HOSTDONAME +
                              "/api/user_invest_comfirm_send",
                            sendData2,
                            function (result) {
                              if (result.messageinfo.state === "ok") {
                              }
                            }
                          );
                          Swal.fire({
                            title: "투자 취소 신청 완료",

                            confirmButtonText: "확인",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              // setData(result.messageinfo.message);
                              // let sendData = {
                              //   no: user.no,
                              // };
                              // // dispatch(selectInvest(sendData));
                              // CommonAxios(
                              //   process.env.REACT_APP_HOSTDONAME +
                              //     "/api/myInvest_select_join",
                              //   sendData,
                              //   function (result) {
                              //     if (result.messageinfo.state === "ok") {
                              //       setData(result.messageinfo.message);
                              //       setInfo(result.messageinfo.message_data2);
                              //       setMyAccount(
                              //         result.messageinfo.message_data3
                              //       );
                              //       setSkeleton(true);
                              //     } else {
                              //     }
                              //   }
                              // );
                            }
                          });
                        } else {
                        }
                      }
                    );
                  }
                });
              }
            });
          }
        });
      }
    });
  };

  const click_interest1 = () => {
    if (data.invest_interest_withdraw !== "N") {
      return Swal.fire({
        title: "종료 신청 실패",
        html: `현재 종료 신청 진행을 하였습니다.
    `,

        confirmButtonText: "확인",
      });
    }
    if (false) {
      return Swal.fire({
        title: "이자 출금 신청",
        text: "이자 출금은 10만원 이상부터만 가능합니다.",
        confirmButtonText: "확인",
      });
    } else {
      let inputNum = "";
      let inputName = "";
      let inputBank = "";
      let inputAccount = "";
      let mobile = "";
      let mobileN = "";
      Swal.fire({
        title: "종료 신청",
        showCancelButton: true,
        html: `아래 계좌로 입금 신청하시겠습니까?.<br/>
          <div style=" color:#00c73c; font-size:14px; "> (계좌 정보가 틀렸다면 마이페이지에서 변경하세요.)</div>

        



          <p style="text-align:center; margin-top:2px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">예금주</label><br/> ${myAccount[0].user_acName}<p>
          <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">은행 : </label> <br/>${myAccount[0].user_acBank}<p>
          <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">계좌번호 : </label><br/> ${myAccount[0].user_account}<p>`,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "종료 신청",
            showCancelButton: true,
            // icon: "error",
            html: ` <p style="text-align:center; font-size:14px;">
            본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
            <label>핸드폰 번호</label>
            <input type="text" placeholder="하이픈(-)없이 입력해주세요" class="swal2-input" id="num-value5" />  
             `,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
            preConfirm: (data) => {
              const mobileNum =
                Swal.getHtmlContainer().querySelector("#num-value5");

              if (!/[0-9]/g.test(mobileNum.value)) {
                Swal.showValidationMessage(`숫자만 입력하세요.`);
              } else {
                mobile = mobileNum.value;
                let sendData = {
                  mobileNum: mobileNum.value,
                };
                CommonAxios(
                  process.env.REACT_APP_HOSTDONAME + "/api/send_sms",
                  sendData,
                  function (result) {
                    // console.log(result);
                    if (result.messageinfo.state == "ok") {
                      mobileN = result.messageinfo.message;
                    } else {
                      Swal.showValidationMessage(`숫자만 입력하세요.`);
                    }
                  }
                );
              }
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: "종료 신청",
                showCancelButton: true,
                // icon: "error",
                html: ` <p style="text-align:center; font-size:14px;">
                본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
                <label>인증 번호</label>
                <input type="text" value='${mobileN}'  class="swal2-input" id="num-value6" />  
                 `,
                confirmButtonText: "확인",
                cancelButtonText: "취소",
                preConfirm: (data) => {
                  const mobileNumA =
                    Swal.getHtmlContainer().querySelector("#num-value6");

                  if (!/[0-9]/g.test(mobileNumA.value)) {
                    Swal.showValidationMessage(`숫자만 입력하세요.`);
                  } else {
                    if (Number(mobileN) === Number(mobileNumA.value)) {
                    } else {
                      Swal.showValidationMessage(
                        `인증번호가 일치하지 않습니다.`
                      );
                    }
                  }
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  var sendData = {
                    end_user_id: user.no,
                    end_invest_no: data.invest_wait_id,
                    end_product_id: data.invest_product_no,
                    end_account: myAccount[0].user_account,
                    end_bank: myAccount[0].user_acBank,
                    end_name: myAccount[0].user_acName,
                    end_mobile: mobile.replace(
                      /^(\d{2,3})(\d{3,4})(\d{4})$/,
                      `$1-$2-$3`
                    ),
                    end_amount: data.invest_amount,
                    end_interest_result: Math.floor(data.invest_interest_sum),
                    end_rate: data.pro_rate,
                    end_percent: 0,
                    end_amount_result:
                      data.invest_amount + Math.floor(data.invest_interest_sum),
                    end_kind: "E",
                  };
                  // setMobileGetNum("123456");
                  CommonAxios(
                    process.env.REACT_APP_HOSTDONAME +
                      "/api/myInvest_insert_end",
                    sendData,
                    function (result) {
                      // console.log(result);
                      if (result.messageinfo.state == "ok") {
                        let sendData2 = {
                          mobile: myAccount[0].user_mobile,
                          email: myAccount[0].user_email,
                          getamount: wonComma(
                            data.invest_amount +
                              Math.floor(data.invest_interest_sum)
                          ),
                          price: wonComma(data.invest_amount),
                          type: "종료",
                          name: myAccount[0].user_name,
                        };
                        CommonAxios(
                          process.env.REACT_APP_HOSTDONAME +
                            "/api/user_invest_comfirm_send",
                          sendData2,
                          function (result) {
                            if (result.messageinfo.state === "ok") {
                            }
                          }
                        );

                        Swal.fire({
                          title: "종료 신청 완료",

                          confirmButtonText: "확인",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            // setData(result.messageinfo.message);
                            // let sendData = {
                            //   no: user.no,
                            // };
                            // // dispatch(selectInvest(sendData));
                            // CommonAxios(
                            //   process.env.REACT_APP_HOSTDONAME +
                            //     "/api/myInvest_select_end",
                            //   sendData,
                            //   function (result) {
                            //     if (result.messageinfo.state === "ok") {
                            //       setData(result.messageinfo.message);
                            //       setData1(result.messageinfo.message_data2);
                            //       setMyAccount(
                            //         result.messageinfo.message_data3
                            //       );
                            //     } else {
                            //     }
                            //   }
                            // );
                          }
                        });
                      } else {
                      }
                    }
                  );
                }
              });
            }
          });
        }
      });
    }
  };

  return (
    <>
      {yn !== "EE" && yn !== "E" && yn !== "R" && yn !== "C" && (
        <div className={styles.invest_my_data_box}>
          <div className={styles.invest_my_data_top}>
            <p> {data.pro_title ? data.pro_title : "삭제된 상품"}</p>
            <div className={styles.invest_my_data_top_div}>
              <div>
                {" "}
                <span>
                  시작일 :{" "}
                  <Moment format="YYYY-MM-DD 00:00">
                    {data.invest_start_date}
                  </Moment>
                </span>{" "}
                <span>
                  마감일 :{" "}
                  <Moment format="YYYY-MM-DD 00:00">
                    {data.invest_end_date}
                  </Moment>
                </span>
              </div>

              <span>수익률 : {data.pro_rate}%</span>
            </div>
          </div>
          <div className={styles.invest_my_data_box_1}>
            <span>투자금액</span>
            <p>
              <em>{wonComma(data.invest_amount)}</em>원
            </p>
          </div>{" "}
          <div className={styles.invest_my_data_box_2}>
            <span>지금까지 쌓인 이자</span>

            {data.invest_confirm_ing === "Y" &&
              data.invest_interest_withdraw !== "C" &&
              data.invest_interest_withdraw !== "R" && (
                <p>
                  <em>
                    {" "}
                    <CountUp
                      start={count}
                      end={endCount}
                      duration={1}
                      separator={","}
                      decimals={3}
                      // suffix={"원"}
                    />
                  </em>
                  원 <br />
                  &nbsp;
                  {data.invest_interest_withdraw === "Y" &&
                    "(이자 출금 대기중)"}
                </p>
              )}
            {(data.invest_interest_withdraw === "N" ||
              data.invest_interest_withdraw === "C") &&
              data.invest_confirm_ing === "N" && (
                <p>
                  <em> 대기 상품</em> <br />
                  {data.invest_interest_withdraw === "C" && "(취소 대기중)"}
                  {data.invest_interest_withdraw === "N" && <>&nbsp;</>}
                  {/* <em> 0</em>원 */}
                </p>
              )}

            {data.invest_interest_withdraw === "R" && (
              <p>
                <em> {wonComma(data.invest_refund_stop)}</em>원 <br />
                (환불 대기중)
              </p>
            )}
          </div>
          {data.invest_confirm_ing === "Y" && (
            <div className={styles.invest_my_data_btn}>
              <button onClick={click_interest}>이자 출금하기</button>{" "}
              <button onClick={click_all_interest}>전액 환불하기</button>
              {/* <button onClick={click_cancel}>투자 취소하기</button> */}
            </div>
          )}{" "}
          {(data.invest_interest_withdraw === "N" ||
            data.invest_interest_withdraw === "C") &&
            data.invest_confirm_ing === "N" && (
              <div className={styles.invest_my_data_btn}>
                <button style={{ background: "#00c73c" }}></button>{" "}
                {/* <button>투자 취소하기</button> */}
                <button onClick={click_cancel}>투자 취소하기</button>
              </div>
            )}
        </div>
      )}{" "}
      {yn === "E" && (
        <div className={styles.invest_myList_end_item}>
          <div className={styles.invest_myList_end_title}>{data.pro_title}</div>
          <div className={styles.invest_myList_end_join}>
            만기일{" "}
            <Moment format="YYYY-MM-DD HH:mm">{data.invest_end_date}</Moment>
          </div>
          <ul>
            <li>
              <span>투자금액</span>
              <span className={styles.myList_end_value}>
                {wonComma(data.invest_amount)}원
              </span>
            </li>{" "}
            <li>
              <span>금리(종료당일)</span>
              <span className={styles.myList_end_value}>
                {data.invest_rate}%
              </span>
            </li>{" "}
            <li>
              <span>총 이자</span>
              <span className={styles.myList_end_value}>
                {" "}
                {wonComma(data.invest_interest_sum)}원
              </span>
            </li>
          </ul>
          <button onClick={click_interest1}>종료 신청</button>
        </div>
      )}
      {(yn === "R" || yn === "C") && (
        <div className={styles.invest_myList_item}>
          <div className={styles.invest_myList_title}>{data.pro_title}</div>
          <div className={styles.invest_myList_join}>
            취소/환불날짜{" "}
            <Moment format="YYYY-MM-DD HH:mm">{data.end_confirm_date}</Moment>
          </div>
          <ul>
            <li>
              <span>상태</span>
              <span className={styles.myList_value}>
                {data.end_kind === "C" && "취소"}{" "}
                {data.end_kind === "R" && "환불"}
              </span>
            </li>{" "}
            <li>
              <span>투자금액</span>
              <span className={styles.myList_value}>
                {wonComma(data.end_amount)}원
              </span>
            </li>{" "}
            <li>
              <span>받을금액</span>
              <span className={styles.myList_value}>
                {" "}
                {wonComma(data.end_amount_result)}원
              </span>
            </li>{" "}
          </ul>
        </div>
      )}
    </>
  );
};

export default InvestDetailCom;
