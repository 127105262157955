import React, { useCallback, useState, useEffect, useRef } from "react";
import { CommonAxios } from "./../../CommonAxios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";
import Moment from "react-moment";
import styles from "./../../../styles/admin.module.css";
import { useParams } from "react-router-dom";
import { textReplace } from "../../../lib/textReplace";
import { FcEditImage } from "react-icons/fc";
import { textOverCut, getTextTotal } from "../../../lib/stringLenghts";
import { wonComma } from "../../../lib/wonComma";
import icon_1 from "../../../assets/images/icon_1.png";
import icon_2 from "../../../assets/images/icon_2.png";
import icon_3 from "../../../assets/images/icon_3.png";
import icon_4 from "../../../assets/images/icon_4_not.png";
import icon_2_not from "../../../assets/images/icon_2_not.png";
import icon_3_not from "../../../assets/images/icon_3_not.png";
import icon_4_not from "../../../assets/images/icon_4_not.png";
import icon_5 from "../../../assets/images/icon_5.png";
import icon_6 from "../../../assets/images/icon_6.png";
import icon_7 from "../../../assets/images/icon_7.png";
import icon_8 from "../../../assets/images/icon_8.png";
import { ramdomASCII } from "../../../lib/radomASCII";
import axios from "axios";
const FarmWriteComField = () => {
  const [data, setdata] = useState([]);
  const [swt, setSwt] = useState(false);
  useEffect(() => {
    let sendData = {
      field_type: 2,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_field",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          if (result.messageinfo.message.length > 0) {
            setdata(result.messageinfo.message);
            setText1(result.messageinfo.message[0].field_title_one);
            let byte = getTextTotal(
              result.messageinfo.message[0].field_title_one,
              8
            );
            setText1Byte(byte.byte);
            setText2(result.messageinfo.message[0].field_title_two);
            let byte2 = getTextTotal(
              result.messageinfo.message[0].field_title_two,
              8
            );
            setText2Byte(byte2.byte);

            setText3(result.messageinfo.message[0].field_title_three);
            let byte3 = getTextTotal(
              result.messageinfo.message[0].field_title_three,
              8
            );
            setText3Byte(byte3.byte);
            setText4(result.messageinfo.message[0].field_title_four);
            let byte4 = getTextTotal(
              result.messageinfo.message[0].field_title_four,
              8
            );
            setText4Byte(byte4.byte);

            setText5(result.messageinfo.message[0].field_unit_one);
            let byte13 = getTextTotal(
              result.messageinfo.message[0].field_unit_one,
              6
            );
            setText5Byte(byte13.byte);
            setText6(result.messageinfo.message[0].field_unit_two);
            let byte14 = getTextTotal(
              result.messageinfo.message[0].field_unit_two,
              6
            );
            setText6Byte(byte14.byte);

            setImgA1(result.messageinfo.message[0].field_A_text_one);
            setImgAs1(result.messageinfo.message[0].field_A_text_one);
            let byte5 = getTextTotal(
              result.messageinfo.message[0].field_A_text_one,
              8
            );
            setImgA1Byte(byte5.byte);

            setImgA2(result.messageinfo.message[0].field_A_text_two);
            setImgAs2(result.messageinfo.message[0].field_A_text_two);
            let byte6 = getTextTotal(
              result.messageinfo.message[0].field_A_text_two,
              8
            );
            setImgA2Byte(byte6.byte);
            setImgA3(result.messageinfo.message[0].field_A_text_three);
            setImgAs3(result.messageinfo.message[0].field_A_text_three);
            let byte7 = getTextTotal(
              result.messageinfo.message[0].field_A_text_three,
              8
            );
            setImgA3Byte(byte7.byte);
            setImgA4(result.messageinfo.message[0].field_A_text_four);
            setImgAs4(result.messageinfo.message[0].field_A_text_four);
            let byte8 = getTextTotal(
              result.messageinfo.message[0].field_A_text_four,
              8
            );
            setImgA4Byte(byte8.byte);
            setImgB1(result.messageinfo.message[0].field_B_text_one);
            setImgBs1(result.messageinfo.message[0].field_B_text_one);
            let byte9 = getTextTotal(
              result.messageinfo.message[0].field_B_text_one,
              8
            );
            setImgB1Byte(byte9.byte);
            setImgB2(result.messageinfo.message[0].field_B_text_two);
            setImgBs2(result.messageinfo.message[0].field_B_text_two);
            let byte10 = getTextTotal(
              result.messageinfo.message[0].field_B_text_two,
              8
            );
            setImgB2Byte(byte10.byte);
            setImgB3(result.messageinfo.message[0].field_B_text_three);
            setImgBs3(result.messageinfo.message[0].field_B_text_three);
            let byte11 = getTextTotal(
              result.messageinfo.message[0].field_B_text_three,
              8
            );
            setImgB3Byte(byte11.byte);
            setImgB4(result.messageinfo.message[0].field_B_text_four);
            setImgBs4(result.messageinfo.message[0].field_B_text_four);
            let byte12 = getTextTotal(
              result.messageinfo.message[0].field_B_text_four,
              8
            );
            setImgB4Byte(byte12.byte);
            setSwt(true);
          }
        }
      }
    );
  }, []);
  // 공개여부
  const [openValue, setOpenValue] = useState("Y");
  const change_open = (data) => {
    setOpenValue(data);
  };

  const navigate = useNavigate();

  // 농장명
  const [text1Byte, setText1Byte] = useState(0);
  const [text1, setText1] = useState("");

  const change_text1 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 8);
    let text = textOverCut(e.target.value, 8);

    setText1(text);
    setText1Byte(byte.byte);
  }, []);
  const [text2Byte, setText2Byte] = useState(0);
  const [text2, setText2] = useState("");
  const change_text2 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 8);
    let text = textOverCut(e.target.value, 8);

    setText2(text);
    setText2Byte(byte.byte);
  }, []);
  const [text3Byte, setText3Byte] = useState(0);
  const [text3, setText3] = useState("");
  const change_text3 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 8);
    let text = textOverCut(e.target.value, 8);

    setText3(text);
    setText3Byte(byte.byte);
  }, []);
  const [text4Byte, setText4Byte] = useState(0);
  const [text4, setText4] = useState("");
  const change_text4 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 8);
    let text = textOverCut(e.target.value, 8);

    setText4(text);
    setText4Byte(byte.byte);
  }, []);

  const [text5Byte, setText5Byte] = useState(0);
  const [text5, setText5] = useState("");
  const change_text5 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 6);
    let text = textOverCut(e.target.value, 6);

    setText5(text);
    setText5Byte(byte.byte);
  }, []);

  const [text6Byte, setText6Byte] = useState(0);
  const [text6, setText6] = useState("");
  const change_text6 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 6);
    let text = textOverCut(e.target.value, 6);

    setText6(text);
    setText6Byte(byte.byte);
  }, []);

  const [imgA1Byte, setImgA1Byte] = useState(0);
  const [imgA1, setImgA1] = useState("");
  const [imgAs1, setImgAs1] = useState("");
  const change_imgA1 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 4);
    let text = textOverCut(e.target.value, 4);

    setImgA1(text);
    setImgA1Byte(byte.byte);
  }, []);
  const [imgB1Byte, setImgB1Byte] = useState(0);
  const [imgB1, setImgB1] = useState("");
  const [imgBs1, setImgBs1] = useState("");
  const change_imgB1 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 4);
    let text = textOverCut(e.target.value, 4);

    setImgB1(text);
    setImgB1Byte(byte.byte);
  }, []);
  const [imgA2Byte, setImgA2Byte] = useState(0);
  const [imgA2, setImgA2] = useState("");
  const [imgAs2, setImgAs2] = useState("");
  const change_imgA2 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 4);
    let text = textOverCut(e.target.value, 4);

    setImgA2(text);
    setImgA2Byte(byte.byte);
  }, []);
  const [imgB2Byte, setImgB2Byte] = useState(0);
  const [imgB2, setImgB2] = useState("");
  const [imgBs2, setImgBs2] = useState("");
  const change_imgB2 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 4);
    let text = textOverCut(e.target.value, 4);

    setImgB2(text);
    setImgB2Byte(byte.byte);
  }, []);
  const [imgA3Byte, setImgA3Byte] = useState(0);
  const [imgA3, setImgA3] = useState("");
  const [imgAs3, setImgAs3] = useState("");
  const change_imgA3 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 4);
    let text = textOverCut(e.target.value, 4);

    setImgA3(text);
    setImgA3Byte(byte.byte);
  }, []);
  const [imgB3Byte, setImgB3Byte] = useState(0);
  const [imgB3, setImgB3] = useState("");
  const [imgBs3, setImgBs3] = useState("");
  const change_imgB3 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 8);
    let text = textOverCut(e.target.value, 8);

    setImgB3(text);
    setImgB3Byte(byte.byte);
  }, []);
  const [imgA4Byte, setImgA4Byte] = useState(0);
  const [imgA4, setImgA4] = useState("");
  const [imgAs4, setImgAs4] = useState("");
  const change_imgA4 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 4);
    let text = textOverCut(e.target.value, 4);

    setImgA4(text);
    setImgA4Byte(byte.byte);
  }, []);
  const [imgB4Byte, setImgB4Byte] = useState(0);
  const [imgB4, setImgB4] = useState("");
  const [imgBs4, setImgBs4] = useState("");
  const change_imgB4 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 4);
    let text = textOverCut(e.target.value, 4);

    setImgB4(text);
    setImgB4Byte(byte.byte);
  }, []);
  //생산면적

  const [contentValue, setContentValue] = useState("");
  const change_content = useCallback((e) => {
    setContentValue(wonComma(e.target.value.replace(/[^\d]+/g, "")));
  }, []);

  const [amountValue, setAmountValue] = useState("");
  const change_amount = useCallback((e) => {
    setAmountValue(wonComma(e.target.value.replace(/[^\d]+/g, "")));
  }, []);

  const [pro1Byte, setPro1Byte] = useState(0);
  const [proValue1, setProValue1] = useState("");
  const changePro1 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 10);
    let text = textOverCut(e.target.value, 10);

    setProValue1(text);
    setPro1Byte(byte.byte);
  }, []);

  const [pro2Byte, setPro2Byte] = useState(0);
  const [proValue2, setProValue2] = useState("");
  const changePro2 = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 10);
    let text = textOverCut(e.target.value, 10);

    setProValue2(text);
    setPro2Byte(byte.byte);
  }, []);

  const [applyValue, setApplyValue] = useState("N");
  const change_apply = (data) => {
    setApplyValue(data);
  };
  const [cateValue, setCateValue] = useState("설립");
  const change_cate = useCallback((e) => {
    setCateValue(e.target.value);
  }, []);
  const [rateValue, setRateValue] = useState("");
  const change_rate = useCallback((e) => {
    setRateValue(e.target.value);
  }, []);
  const [joinValue, setJoinValue] = useState("");
  const change_join = useCallback((e) => {
    setJoinValue(e.target.value);
  }, []);

  const [progressValue, setProgressValue] = useState("1");
  const change_progress = (data) => {
    setProgressValue(data);
  };
  // 상품 등록
  const click_farm_reg = () => {
    if (
      // !titleValue ||
      !contentValue ||
      !amountValue ||
      !proValue1 ||
      !proValue2
    ) {
      return Swal.fire({
        title: "상품 등록 실패",
        html: `빈칸을 채워주세요.`,

        confirmButtonText: "확인",
      });
    }

    if (
      !/[0-9]|-[0-9]/g.test(Number(rateValue)) ||
      !/[0-9]/g.test(Number(joinValue))
    ) {
      return Swal.fire({
        title: "상품 등록 실패",
        html: `수익률과 가입기간은 숫자로 적어주세요.`,

        confirmButtonText: "확인",
      });
    }

    let sendData = {
      goods_open: openValue,
      // pro_end_yn: recru,
      // goods_title: textReplace(titleValue),
      pro_content: contentValue.replace(/[^\d]+/g, ""),
      goods_estimated_earns: amountValue.replace(/[^\d]+/g, ""),

      goods_state: cateValue === "설립" ? "N" : "Y",
      goods_crops_1: textReplace(proValue1),
      goods_crops_2: textReplace(proValue2),
      goods_progress: progressValue,
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_insert_goods",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          Swal.fire({
            title: "등록 완료",

            confirmButtonText: "확인",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/admin/goods");
            }
          });
        }
      }
    );
  };

  const [imageFile, setImageFile] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const [imageFile3, setImageFile3] = useState("");
  const [imageFile4, setImageFile4] = useState("");
  const [imageFile5, setImageFile5] = useState("");
  const [imageFile6, setImageFile6] = useState("");
  const [imageFile7, setImageFile7] = useState("");
  const [imageFile8, setImageFile8] = useState("");

  const [imageFile9, setImageFile9] = useState("");
  const [imageFile10, setImageFile10] = useState("");
  const [imageFile11, setImageFile11] = useState("");
  const [imageFile12, setImageFile12] = useState("");
  const [imageFile13, setImageFile13] = useState("");
  const [imageFile14, setImageFile14] = useState("");
  const [imageFile15, setImageFile15] = useState("");
  const [imageFile16, setImageFile16] = useState("");

  const [imageFileN, setImageFileN] = useState("");
  const [imageFileN2, setImageFileN2] = useState("");
  const [imageFileN3, setImageFileN3] = useState("");
  const [imageFileN4, setImageFileN4] = useState("");
  const [imageFileN5, setImageFileN5] = useState("");
  const [imageFileN6, setImageFileN6] = useState("");
  const [imageFileN7, setImageFileN7] = useState("");
  const [imageFileN8, setImageFileN8] = useState("");
  const [imageFileN9, setImageFileN9] = useState("");
  const [imageFileN10, setImageFileN10] = useState("");
  const [imageFileN11, setImageFileN11] = useState("");
  const [imageFileN12, setImageFileN12] = useState("");
  const [imageFileN13, setImageFileN13] = useState("");
  const [imageFileN14, setImageFileN14] = useState("");
  const [imageFileN15, setImageFileN15] = useState("");
  const [imageFileN16, setImageFileN16] = useState("");
  // const [imageFileNBefore, setImageFileNBefore] = useState("");
  // const [imageFileN2Before, setImageFileN2Before] = useState("");
  // const [imageFileN3Before, setImageFileN3Before] = useState("");
  // const [imageFileN4Before, setImageFileN4Before] = useState("");
  const [bgImg, setBgImg] = useState("");
  const [bgImg2, setBgImg2] = useState("");
  const [bgImg3, setBgImg3] = useState("");
  const [bgImg4, setBgImg4] = useState("");
  const [bgImg5, setBgImg5] = useState("");
  const [bgImg6, setBgImg6] = useState("");
  const [bgImg7, setBgImg7] = useState("");
  const [bgImg8, setBgImg8] = useState("");
  const [bgImg9, setBgImg9] = useState("");
  const [bgImg10, setBgImg10] = useState("");
  const [bgImg11, setBgImg11] = useState("");
  const [bgImg12, setBgImg12] = useState("");
  const [bgImg13, setBgImg13] = useState("");
  const [bgImg14, setBgImg14] = useState("");
  const [bgImg15, setBgImg15] = useState("");
  const [bgImg16, setBgImg16] = useState("");
  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN(`${result}.${imgExtension[1]}`);

    setImageFile(file);
  };

  const inpChange2 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN2(`${result}.${imgExtension[1]}`);

    setImageFile2(file);
  };
  const inpChange3 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN3(`${result}.${imgExtension[1]}`);

    setImageFile3(file);
  };

  const inpChange4 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN4(`${result}.${imgExtension[1]}`);

    setImageFile4(file);
  };

  const inpChange5 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN5(`${result}.${imgExtension[1]}`);

    setImageFile5(file);
  };

  const inpChange6 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN6(`${result}.${imgExtension[1]}`);

    setImageFile6(file);
  };
  const inpChange7 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN7(`${result}.${imgExtension[1]}`);

    setImageFile7(file);
  };

  const inpChange8 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN8(`${result}.${imgExtension[1]}`);

    setImageFile8(file);
  };

  const inpChange9 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN9(`${result}.${imgExtension[1]}`);

    setImageFile9(file);
  };

  const inpChange10 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN10(`${result}.${imgExtension[1]}`);

    setImageFile10(file);
  };
  const inpChange11 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN11(`${result}.${imgExtension[1]}`);

    setImageFile11(file);
  };

  const inpChange12 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN12(`${result}.${imgExtension[1]}`);

    setImageFile12(file);
  };

  const inpChange13 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN13(`${result}.${imgExtension[1]}`);

    setImageFile13(file);
  };

  const inpChange14 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN14(`${result}.${imgExtension[1]}`);

    setImageFile14(file);
  };
  const inpChange15 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN15(`${result}.${imgExtension[1]}`);

    setImageFile15(file);
  };

  const inpChange16 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN16(`${result}.${imgExtension[1]}`);

    setImageFile16(file);
  };
  useEffect(() => {
    preview(); // 미리보기
  }, [imageFile]);
  useEffect(() => {
    preview2(); // 미리보기
  }, [imageFile2]);
  useEffect(() => {
    preview3(); // 미리보기
  }, [imageFile3]);
  useEffect(() => {
    preview4(); // 미리보기
  }, [imageFile4]);
  useEffect(() => {
    preview5(); // 미리보기
  }, [imageFile5]);
  useEffect(() => {
    preview6(); // 미리보기
  }, [imageFile6]);
  useEffect(() => {
    preview7(); // 미리보기
  }, [imageFile7]);
  useEffect(() => {
    preview8(); // 미리보기
  }, [imageFile8]);

  useEffect(() => {
    preview9(); // 미리보기
  }, [imageFile9]);
  useEffect(() => {
    preview10(); // 미리보기
  }, [imageFile10]);
  useEffect(() => {
    preview11(); // 미리보기
  }, [imageFile11]);
  useEffect(() => {
    preview12(); // 미리보기
  }, [imageFile12]);
  useEffect(() => {
    preview13(); // 미리보기
  }, [imageFile13]);
  useEffect(() => {
    preview14(); // 미리보기
  }, [imageFile14]);
  useEffect(() => {
    preview15(); // 미리보기
  }, [imageFile15]);
  useEffect(() => {
    preview16(); // 미리보기
  }, [imageFile16]);

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };
  const preview2 = () => {
    if (!imageFile2) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg2(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile2[0]);
  };
  const preview3 = () => {
    if (!imageFile3) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg3(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile3[0]);
  };
  const preview4 = () => {
    if (!imageFile4) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg4(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile4[0]);
  };

  const preview5 = () => {
    if (!imageFile5) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg5(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile5[0]);
  };
  const preview6 = () => {
    if (!imageFile6) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg6(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile6[0]);
  };
  const preview7 = () => {
    if (!imageFile7) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg7(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile7[0]);
  };
  const preview8 = () => {
    if (!imageFile8) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg8(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile8[0]);
  };

  const preview9 = () => {
    if (!imageFile9) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg9(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile9[0]);
  };
  const preview10 = () => {
    if (!imageFile10) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg10(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile10[0]);
  };
  const preview11 = () => {
    if (!imageFile11) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg11(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile11[0]);
  };
  const preview12 = () => {
    if (!imageFile12) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg12(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile12[0]);
  };

  const preview13 = () => {
    if (!imageFile13) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg13(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile13[0]);
  };
  const preview14 = () => {
    if (!imageFile14) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg14(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile14[0]);
  };
  const preview15 = () => {
    if (!imageFile15) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg15(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile15[0]);
  };
  const preview16 = () => {
    if (!imageFile16) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg16(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile16[0]);
  };
  const btn_img_save3 = () => {
    Swal.fire({
      title: "변경 진행",
      text: "변경을 진행하시겠습니까?",

      confirmButtonText: "확인",
      cancelButtonText: "취소",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let imgIf = "";

        if (text1) {
          imgIf += `field_title_one = '${textReplace(text1)}',`;
        }
        if (text2) {
          imgIf += `field_title_two = '${textReplace(text2)}',`;
        }
        if (text3) {
          imgIf += `field_title_three = '${textReplace(text3)}',`;
        }
        if (text4) {
          imgIf += `field_title_four = '${textReplace(text4)}',`;
        }
        if (text5) {
          imgIf += `field_unit_one = '${textReplace(text5)}',`;
        }
        if (text6) {
          imgIf += `field_unit_two = '${textReplace(text6)}',`;
        }
        let sendData = {
          // type: options,
          no: data[0].no,
          imgIf: imgIf.slice(0, -1),
        };
        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/admin_update_field",
          sendData,
          function (result) {
            if (result.messageinfo.state == "ok") {
              Swal.fire({
                title: "변경 완료",
                // text: "변경할 문구가 없습니다.",
                confirmButtonText: "확인",
              });
            } else {
            }
          }
        );
      }

      // let sendData2 = {
      //   type: options,
      //   no: categoryNo,
      // };
      // CommonAxios(
      //   process.env.REACT_APP_HOSTDONAME + "/api/admin_select_details",
      //   sendData2,
      //   function (result) {
      //     if (result.messageinfo.state == "ok") {
      //       Swal.fire({
      //         title: "변경 완료",
      //         // text: "변경할 문구가 없습니다.",
      //         confirmButtonText: "확인",
      //       });
      //     } else {
      //     }
      //   }
      // );
    });
  };

  const btn_img_save = () => {
    Swal.fire({
      title: "변경 진행",
      text: "변경을 진행하시겠습니까?",

      confirmButtonText: "확인",
      cancelButtonText: "취소",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let imgIf = "";
        let beforImg = "";
        const formData = new FormData();
        formData.append("no", data[0].no);
        // formData.append("filename", imageFileN);

        // formData.append("fileBefore", beforImg);
        formData.append("folder", "field");
        // formData.append("file", imageFile[0]);

        if (imageFile) {
          formData.append("filename", imageFileN);
          formData.append("fileBefore", data[0].field_A_img_one);
          formData.append("file", imageFile[0]);
          imgIf += `field_A_img_one = '${imageFileN}',`;
        }
        if (imgA1 !== imgAs1) {
          imgIf += `field_A_text_one = '${imgA1}',`;
        }

        if (imageFile2) {
          formData.append("filename", imageFileN2);
          formData.append("fileBefore", data[0].field_A_img_two);
          formData.append("file", imageFile2[0]);
          imgIf += `field_A_img_two = '${imageFileN2}',`;
        }
        if (imgA2 !== imgAs2) {
          imgIf += `field_A_text_two = '${imgA2}',`;
        }
        if (imageFile3) {
          formData.append("filename", imageFileN3);
          formData.append("fileBefore", data[0].field_A_img_three);
          formData.append("file", imageFile3[0]);
          imgIf += `field_A_img_three = '${imageFileN3}',`;
        }
        if (imgA3 !== imgAs3) {
          imgIf += `field_A_text_three = '${imgA3}',`;
        }
        if (imageFile4) {
          formData.append("filename", imageFileN4);
          formData.append("fileBefore", data[0].field_A_img_four);
          formData.append("file", imageFile4[0]);
          imgIf += `field_A_img_four = '${imageFileN4}',`;
        }
        if (imgA4 !== imgAs4) {
          imgIf += `field_A_text_four = '${imgA4}',`;
        }
        if (imageFile5) {
          formData.append("filename", imageFileN5);
          formData.append("fileBefore", data[0].field_A_img_one_not);
          formData.append("file", imageFile5[0]);
          imgIf += `field_A_img_one = '${imageFileN5}',`;
        }

        if (imageFile6) {
          formData.append("filename", imageFileN6);
          formData.append("fileBefore", data[0].field_A_img_two_not);
          formData.append("file", imageFile6[0]);
          imgIf += `field_A_img_two = '${imageFileN6}',`;
        }
        if (imageFile7) {
          formData.append("filename", imageFileN7);
          formData.append("fileBefore", data[0].field_A_img_three_not);
          formData.append("file", imageFile7[0]);
          imgIf += `field_A_img_three = '${imageFileN7}',`;
        }
        if (imageFile8) {
          formData.append("filename", imageFileN8);
          formData.append("fileBefore", data[0].field_A_img_four_not);
          formData.append("file", imageFile8[0]);
          imgIf += `field_A_img_four = '${imageFileN8}',`;
        }

        formData.append("fileif", imgIf.slice(0, -1));
        axios
          .post(
            process.env.REACT_APP_HOSTDONAME + "/api/admin_upload_field_one",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.messageinfo.state === "ok") {
              // let sendData2 = {
              //   type: options,
              //   no: categoryNo,
              // };
              // CommonAxios(
              //   process.env.REACT_APP_HOSTDONAME + "/api/admin_select_details",
              //   sendData2,
              //   function (result) {
              //     if (result.messageinfo.state == "ok") {
              //       Swal.fire({
              //         title: "변경 완료",
              //         // text: "변경할 문구가 없습니다.",
              //         confirmButtonText: "확인",
              //       });
              //     } else {
              //     }
              //   }
              // );
            }
          })
          .catch((error) => {
            // 예외 처리
          });
      }
    });
  };

  const btn_img_save2 = () => {
    Swal.fire({
      title: "변경 진행",
      text: "변경을 진행하시겠습니까?",

      confirmButtonText: "확인",
      cancelButtonText: "취소",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let imgIf = "";
        let beforImg = "";
        const formData = new FormData();
        formData.append("no", data[0].no);
        // formData.append("filename", imageFileN);

        // formData.append("fileBefore", beforImg);
        formData.append("folder", "field");
        // formData.append("file", imageFile[0]);

        if (imageFile9) {
          formData.append("filename", imageFileN9);
          formData.append("fileBefore", data[0].field_B_img_one);
          formData.append("file", imageFile9[0]);
          imgIf += `field_B_img_one = '${imageFileN9}',`;
        }
        if (imgB1 !== imgBs1) {
          imgIf += `field_B_text_one = '${imgB1}',`;
        }

        if (imageFile10) {
          formData.append("filename", imageFileN10);
          formData.append("fileBefore", data[0].field_B_img_two);
          formData.append("file", imageFile10[0]);
          imgIf += `field_B_img_two = '${imageFileN10}',`;
        }
        if (imgB2 !== imgBs2) {
          imgIf += `field_B_text_two = '${imgB2}',`;
        }
        if (imageFile11) {
          formData.append("filename", imageFileN11);
          formData.append("fileBefore", data[0].field_B_img_three);
          formData.append("file", imageFile11[0]);
          imgIf += `field_B_img_three = '${imageFileN11}',`;
        }
        if (imgB3 !== imgBs3) {
          imgIf += `field_B_text_three = '${imgB3}',`;
        }
        if (imageFile12) {
          formData.append("filename", imageFileN12);
          formData.append("fileBefore", data[0].field_B_img_four);
          formData.append("file", imageFile12[0]);
          imgIf += `field_A_img_four = '${imageFileN12}',`;
        }
        if (imgB4 !== imgBs4) {
          imgIf += `field_B_text_four = '${imgB4}',`;
        }
        if (imageFile13) {
          formData.append("filename", imageFileN13);
          formData.append("fileBefore", data[0].field_B_img_one_not);
          formData.append("file", imageFile13[0]);
          imgIf += `field_A_img_one = '${imageFileN13}',`;
        }

        if (imageFile14) {
          formData.append("filename", imageFileN14);
          formData.append("fileBefore", data[0].field_B_img_two_not);
          formData.append("file", imageFile14[0]);
          imgIf += `field_A_img_two = '${imageFileN14}',`;
        }
        if (imageFile15) {
          formData.append("filename", imageFileN15);
          formData.append("fileBefore", data[0].field_B_img_three_not);
          formData.append("file", imageFile15[0]);
          imgIf += `field_A_img_three = '${imageFileN15}',`;
        }
        if (imageFile16) {
          formData.append("filename", imageFileN16);
          formData.append("fileBefore", data[0].field_B_img_four_not);
          formData.append("file", imageFile16[0]);
          imgIf += `field_A_img_four = '${imageFileN16}',`;
        }

        formData.append("fileif", imgIf.slice(0, -1));
        axios
          .post(
            process.env.REACT_APP_HOSTDONAME + "/api/admin_upload_field_one",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.messageinfo.state === "ok") {
              // let sendData2 = {
              //   type: options,
              //   no: categoryNo,
              // };
              // CommonAxios(
              //   process.env.REACT_APP_HOSTDONAME + "/api/admin_select_details",
              //   sendData2,
              //   function (result) {
              //     if (result.messageinfo.state == "ok") {
              //       Swal.fire({
              //         title: "변경 완료",
              //         // text: "변경할 문구가 없습니다.",
              //         confirmButtonText: "확인",
              //       });
              //     } else {
              //     }
              //   }
              // );
            }
          })
          .catch((error) => {
            // 예외 처리
          });
      }
    });
  };

  return (
    <>
      {swt && (
        <div className={`${styles.farm_write_wrap} ${styles.field_wrap}`}>
          <div className={styles.farm_write_inner}>
            <div className={styles.farm_write_title}>
              <h4>농장 필드 수정</h4>
              {/* <div className={styles.farm_write_button}>
              <button onClick={click_farm_reg}>수정</button>
            </div> */}
            </div>
            <div className={styles.field_write_content}>
              <div>
                <div className={styles.field_write_left}>
                  <div className={styles.field_write_title}>(예시)</div>{" "}
                  <div className={styles.farm_item}>
                    <div className={styles.farm_item_box}>
                      <div className={styles.farm_top}>
                        <p className={styles.title}>농장 이름</p>
                      </div>
                      <div className={styles.farm_text}>
                        <ul>
                          <li>
                            <span className={styles.title}>생산 면적</span>
                            <span className={styles.text}>
                              평 {/* {wonComma(data.farm_area)}평 */}
                            </span>
                          </li>
                          <li>
                            <span className={styles.title}>생산 품종</span>
                            <span className={styles.text}></span>
                          </li>
                          <li>
                            <span className={styles.title}>예상 매출액</span>
                            <span className={styles.text}>
                              <em className={styles.em_green}>
                                {/* {wonComma(data.farm_estimated_earns)} */}
                              </em>{" "}
                              원
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className={styles.farm_now}>
                        <div className={styles.now_title}>실시간 현황</div>
                        <ul className={styles.now_list}>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              {" "}
                              <img src={icon_1} />
                            </div>

                            <span style={{ color: "#00c73c" }}>생산</span>
                          </li>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img src={icon_2} />
                            </div>

                            <span style={{ color: "#00c73c" }}>수확</span>
                          </li>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img src={icon_3_not} />
                            </div>

                            <span> 납품</span>
                          </li>{" "}
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img src={icon_4_not} />
                            </div>
                            <span>배당</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.field_write_right}>
                  {" "}
                  <div className={styles.field_write_title}>
                    (미리보기)
                  </div>{" "}
                  <div className={styles.farm_item}>
                    <div className={styles.farm_item_box}>
                      <div className={styles.farm_top}>
                        <p className={styles.title}>농장 이름</p>
                      </div>
                      <div className={styles.farm_text}>
                        <ul>
                          <li>
                            <span className={styles.title}>{text1}</span>
                            <span className={styles.text}>
                              {text5} {/* {wonComma(data.farm_area)}평 */}
                            </span>
                          </li>
                          <li>
                            <span className={styles.title}>{text2}</span>
                            <span className={styles.text}></span>
                          </li>
                          <li>
                            <span className={styles.title}>{text3}</span>
                            <span className={styles.text}>
                              <em className={styles.em_green}>
                                {" "}
                                {/* {wonComma(data.farm_estimated_earns)} */}
                              </em>{" "}
                              {text6}
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className={styles.farm_now}>
                        <div className={styles.now_title}>
                          {text4} (A타입 - 비활성화)
                        </div>
                        <ul className={styles.now_list}>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              {" "}
                              <img
                                src={
                                  bgImg
                                    ? `${bgImg}`
                                    : `/field/${data[0].no}/${data[0].field_A_img_one}`
                                }
                              />
                            </div>

                            <span>{imgA1}</span>
                          </li>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg2
                                    ? `${bgImg2}`
                                    : `/field/${data[0].no}/${data[0].field_A_img_two}`
                                }
                              />
                            </div>

                            <span>{imgA2}</span>
                          </li>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg3
                                    ? `${bgImg3}`
                                    : `/field/${data[0].no}/${data[0].field_A_img_three}`
                                }
                              />
                            </div>

                            <span>{imgA3}</span>
                          </li>{" "}
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg4
                                    ? `${bgImg4}`
                                    : `/field/${data[0].no}/${data[0].field_A_img_four}`
                                }
                              />
                            </div>
                            <span>{imgA4}</span>
                          </li>
                        </ul>{" "}
                        <div className={styles.now_title}>
                          {text4} (A타입 - 활성화)
                        </div>
                        <ul className={styles.now_list}>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              {" "}
                              <img
                                src={
                                  bgImg5
                                    ? `${bgImg5}`
                                    : `/field/${data[0].no}/${data[0].field_A_img_one_not}`
                                }
                              />
                            </div>

                            <span style={{ color: "#00c73c" }}>{imgA1}</span>
                          </li>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg6
                                    ? `${bgImg6}`
                                    : `/field/${data[0].no}/${data[0].field_A_img_two_not}`
                                }
                              />
                            </div>

                            <span style={{ color: "#00c73c" }}>{imgA2}</span>
                          </li>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg7
                                    ? `${bgImg7}`
                                    : `/field/${data[0].no}/${data[0].field_A_img_three_not}`
                                }
                              />
                            </div>

                            <span style={{ color: "#00c73c" }}>{imgA3}</span>
                          </li>{" "}
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg8
                                    ? `${bgImg8}`
                                    : `/field/${data[0].no}/${data[0].field_A_img_four_not}`
                                }
                              />
                            </div>
                            <span style={{ color: "#00c73c" }}>{imgA4}</span>
                          </li>
                        </ul>
                        <div className={styles.now_title}>
                          {text4} (B타입 - 비활성화)
                        </div>
                        <ul className={styles.now_list}>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              {" "}
                              <img
                                src={
                                  bgImg9
                                    ? `${bgImg9}`
                                    : `/field/${data[0].no}/${data[0].field_B_img_one}`
                                }
                              />
                            </div>

                            <span>{imgB1}</span>
                          </li>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg10
                                    ? `${bgImg10}`
                                    : `/field/${data[0].no}/${data[0].field_B_img_two}`
                                }
                              />
                            </div>

                            <span>{imgB2}</span>
                          </li>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg11
                                    ? `${bgImg11}`
                                    : `/field/${data[0].no}/${data[0].field_B_img_three}`
                                }
                              />
                            </div>

                            <span>{imgB3}</span>
                          </li>{" "}
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg12
                                    ? `${bgImg12}`
                                    : `/field/${data[0].no}/${data[0].field_B_img_four}`
                                }
                              />
                            </div>
                            <span>{imgB4}</span>
                          </li>
                        </ul>{" "}
                        <div className={styles.now_title}>
                          {text4} (B타입 - 활성화)
                        </div>
                        <ul className={styles.now_list}>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              {" "}
                              <img
                                src={
                                  bgImg13
                                    ? `${bgImg13}`
                                    : `/field/${data[0].no}/${data[0].field_B_img_one_not}`
                                }
                              />
                            </div>

                            <span style={{ color: "#00c73c" }}>{imgB1}</span>
                          </li>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg14
                                    ? `${bgImg14}`
                                    : `/field/${data[0].no}/${data[0].field_B_img_two_not}`
                                }
                              />
                            </div>

                            <span style={{ color: "#00c73c" }}>{imgB2}</span>
                          </li>
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg15
                                    ? `${bgImg15}`
                                    : `/field/${data[0].no}/${data[0].field_B_img_three_not}`
                                }
                              />
                            </div>

                            <span style={{ color: "#00c73c" }}>{imgB3}</span>
                          </li>{" "}
                          <li>
                            <div className={`${styles.now_icon} `}>
                              <img
                                src={
                                  bgImg16
                                    ? `${bgImg16}`
                                    : `/field/${data[0].no}/${data[0].field_B_img_four_not}`
                                }
                              />
                            </div>
                            <span style={{ color: "#00c73c" }}>{imgB4}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className={styles.field_write_bottom}>
            <div className={styles.field_write_bottom_left}>
              <div className={styles.field_write_bottom_inner}>
                <ul>
                  <li>
                    <label>필드1</label>
                    <div className={styles.field_write_bottom_inner_div}>
                      <input
                        type="text"
                        onChange={change_text1}
                        value={text1}
                      />
                      <span>{text1Byte}/8</span>
                    </div>
                  </li>
                  <li>
                    <label>필드2</label>{" "}
                    <div className={styles.field_write_bottom_inner_div}>
                      <input
                        type="text"
                        onChange={change_text2}
                        value={text2}
                      />{" "}
                      <span>{text2Byte}/8</span>
                    </div>
                  </li>
                  <li>
                    <label>필드3</label>{" "}
                    <div className={styles.field_write_bottom_inner_div}>
                      <input
                        type="text"
                        onChange={change_text3}
                        value={text3}
                      />{" "}
                      <span>{text3Byte}/8</span>
                    </div>
                  </li>{" "}
                  <li>
                    <label>필드4</label>{" "}
                    <div className={styles.field_write_bottom_inner_div}>
                      <input
                        type="text"
                        onChange={change_text4}
                        value={text4}
                      />{" "}
                      <span>{text3Byte}/8</span>
                    </div>
                  </li>{" "}
                  <li>
                    <div className={styles.field_write_bottom_inner_flex}>
                      <div>
                        <label>단위(필드1)</label>{" "}
                        <div className={styles.field_write_bottom_inner_div}>
                          <input
                            type="text"
                            onChange={change_text5}
                            value={text5}
                          />{" "}
                          <span>{text5Byte}/6</span>
                        </div>
                      </div>{" "}
                      <div>
                        <label>단위(필드3)</label>{" "}
                        <div className={styles.field_write_bottom_inner_div}>
                          <input
                            type="text"
                            onChange={change_text6}
                            value={text6}
                          />{" "}
                          <span>{text6Byte}/6</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className={styles.field_write_bottom_save}>
                  <button onClick={btn_img_save3}>저장하기</button>
                </div>
              </div>
            </div>{" "}
            <div className={styles.field_write_bottom_right}>
              <div className={styles.field_write_bottom_inner}>
                <h4>A타입</h4>
                <h5>(이미지 사이즈: 26px x 26px )</h5>
                <ul>
                  <li>
                    <label>이미지1</label>{" "}
                    <div className={styles.field_right_inp}>
                      <input
                        type="text"
                        onChange={change_imgA1}
                        value={imgA1}
                      />{" "}
                      <span>{imgA1Byte}/4</span>
                    </div>{" "}
                    <div className={styles.field_right_img}>
                      <input
                        type="file"
                        onChange={inpChange}
                        id="abc"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        비활성화 업로드
                      </label>
                      <input
                        type="file"
                        onChange={inpChange2}
                        id="abc1"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc1" style={{ cursor: "pointer" }}>
                        활성화 업로드
                      </label>
                    </div>
                  </li>
                  <li>
                    <label>이미지2</label>{" "}
                    <div className={styles.field_right_inp}>
                      <input
                        type="text"
                        onChange={change_imgA2}
                        value={imgA2}
                      />{" "}
                      <span>{imgA2Byte}/4</span>
                    </div>
                    <div className={styles.field_right_img}>
                      <input
                        type="file"
                        onChange={inpChange3}
                        id="abc"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        비활성화 업로드
                      </label>
                      <input
                        type="file"
                        onChange={inpChange4}
                        id="abc1"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc1" style={{ cursor: "pointer" }}>
                        활성화 업로드
                      </label>
                    </div>
                  </li>
                  <li>
                    <label>이미지3</label>{" "}
                    <div className={styles.field_right_inp}>
                      <input
                        type="text"
                        onChange={change_imgA3}
                        value={imgA3}
                      />{" "}
                      <span>{imgA3Byte}/4</span>
                    </div>
                    <div className={styles.field_right_img}>
                      <input
                        type="file"
                        onChange={inpChange5}
                        id="abc"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        비활성화 업로드
                      </label>
                      <input
                        type="file"
                        onChange={inpChange6}
                        id="abc1"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc1" style={{ cursor: "pointer" }}>
                        활성화 업로드
                      </label>
                    </div>
                  </li>
                  <li>
                    <label>이미지4</label>{" "}
                    <div className={styles.field_right_inp}>
                      <input
                        type="text"
                        onChange={change_imgA4}
                        value={imgA4}
                      />{" "}
                      <span>{imgA4Byte}/4</span>
                    </div>
                    <div className={styles.field_right_img}>
                      <input
                        type="file"
                        onChange={inpChange7}
                        id="abc"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        비활성화 업로드
                      </label>
                      <input
                        type="file"
                        onChange={inpChange8}
                        id="abc1"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc1" style={{ cursor: "pointer" }}>
                        활성화 업로드
                      </label>
                    </div>
                  </li>
                </ul>
                <div>
                  <button onClick={btn_img_save}>저장하기</button>
                </div>
              </div>{" "}
              <div className={styles.field_write_bottom_inner}>
                <h4>B타입</h4> <h5>(이미지 사이즈: 26px x 26px )</h5>
                <ul>
                  <li>
                    <label>이미지1</label>{" "}
                    <div className={styles.field_right_inp}>
                      <input
                        type="text"
                        onChange={change_imgB1}
                        value={imgB1}
                      />{" "}
                      <span>{imgB1Byte}/4</span>
                    </div>{" "}
                    <div className={styles.field_right_img}>
                      <input
                        type="file"
                        onChange={inpChange9}
                        id="abc"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        비활성화 업로드
                      </label>
                      <input
                        type="file"
                        onChange={inpChange10}
                        id="abc1"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc1" style={{ cursor: "pointer" }}>
                        활성화 업로드
                      </label>
                    </div>
                  </li>
                  <li>
                    <label>이미지2</label>{" "}
                    <div className={styles.field_right_inp}>
                      <input
                        type="text"
                        onChange={change_imgB2}
                        value={imgB2}
                      />{" "}
                      <span>{imgB2Byte}/4</span>
                    </div>
                    <div className={styles.field_right_img}>
                      <input
                        type="file"
                        onChange={inpChange11}
                        id="abc"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        비활성화 업로드
                      </label>
                      <input
                        type="file"
                        onChange={inpChange12}
                        id="abc1"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc1" style={{ cursor: "pointer" }}>
                        활성화 업로드
                      </label>
                    </div>
                  </li>
                  <li>
                    <label>이미지3</label>{" "}
                    <div className={styles.field_right_inp}>
                      <input
                        type="text"
                        onChange={change_imgB3}
                        value={imgB3}
                      />{" "}
                      <span>{imgB3Byte}/4</span>
                    </div>
                    <div className={styles.field_right_img}>
                      <input
                        type="file"
                        onChange={inpChange13}
                        id="abc"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        비활성화 업로드
                      </label>
                      <input
                        type="file"
                        onChange={inpChange14}
                        id="abc1"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc1" style={{ cursor: "pointer" }}>
                        활성화 업로드
                      </label>
                    </div>
                  </li>
                  <li>
                    <label>이미지4</label>{" "}
                    <div className={styles.field_right_inp}>
                      <input
                        type="text"
                        onChange={change_imgB4}
                        value={imgB4}
                      />{" "}
                      <span>{imgB4Byte}/4</span>
                    </div>
                    <div className={styles.field_right_img}>
                      <input
                        type="file"
                        onChange={inpChange15}
                        id="abc"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc" style={{ cursor: "pointer" }}>
                        비활성화 업로드
                      </label>
                      <input
                        type="file"
                        onChange={inpChange16}
                        id="abc1"
                        style={{ display: "none" }}
                      />{" "}
                      <label for="abc1" style={{ cursor: "pointer" }}>
                        활성화 업로드
                      </label>
                    </div>
                  </li>
                </ul>
                <div>
                  <button onClick={btn_img_save2}>저장하기</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FarmWriteComField;
