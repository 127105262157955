import { Link, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import logo from "../assets/images/Logo.png";
import logo1 from "../assets/images/M12.png";
import naver_icon from "../assets/images/naver_icon.png";
import NaverLogin from "../pages/NaverLogin";
import KakaoLogin from "../pages/KakaoLogin";
// import "../styles/login.css";
import loginStyle from "../styles/login.module.css";
import envelope from "../assets/images/envelope.png";
import { CommonAxios } from "../components/CommonAxios";
import { MoveAlert, WarringAlert } from "../components/alert/Alert";
import { getUsers } from "../modules/user";
import { useDispatch } from "react-redux";
import { encrypt } from "../lib/api/tokenCrypto";
import Swal from "sweetalert2";
function FindAcount() {
  const email_test =
    /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  const password_test =
    /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{10,}$/;

  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_APP_KEY}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}&response_type=code`;
  const navigate = useNavigate();
  const handleLogin = () => {
    window.location.href = KAKAO_AUTH_URL; //인가코드를 발급해주는 경로
  };
  const dispatch = useDispatch();
  const [loginId, setLoginId] = useState("");
  const [loginPass, setLoginPass] = useState("");
  const [warringAlert, setWarringAlert] = useState(false);
  const [moveAlert, setMoveAlert] = useState(false);
  const [getConValue, setGetConValue] = useState("");
  const [num, setNum] = useState(1);

  const [change, setChange] = useState(true);
  const [passwordValue, setPasswordValue] = useState(""); //비밀번호
  const [confirmValue, setConfirmValue] = useState(""); //비밀번호 확인
  const btn_next1 = () => {
    if (loginId === "" || !email_test.test(loginId)) {
      return Swal.fire({
        title: "올바른 이메일 형식이 아닙니다.",
        // text: "PNG , JPG 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
    }

    const sendData = {
      email: loginId,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/password_find_email",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/mailpass",
            sendData,
            function (result) {
              if (result.messageinfo.state === "ok") {
                setNum(2);
                setGetConValue(result.messageinfo.message);
                Swal.fire({
                  title: "해당 계정이 있습니다.",
                  text: "해당 메일로 인증번호를 보냈습니다. 입력바랍니다.",
                  confirmButtonText: "확인",
                });
              } else {
                return Swal.fire({
                  title: "이메일 발송 오류",
                  text: "고객센터에 문의해주세요.",
                  icon: "error",
                  confirmButtonColor: "#FF0000",
                });
              }
            }
          );
        } else {
          return Swal.fire({
            title: "등록되지 않은 계정입니다.",
            // text: "고객센터에 문의해주세요.",
            icon: "error",
            confirmButtonColor: "#FF0000",
          });
        }
      }
    );
  };
  // 이메일 입력시
  const onChange_id = useCallback((e) => {
    setLoginId(e.target.value);
  }, []);

  const onChange_pass = useCallback((e) => {
    setLoginPass(e.target.value);
  }, []);
  // 비밀번호 입력시
  const onChangePassword = useCallback((e) => {
    setPasswordValue(e.target.value);
    // if (e.target.value === "" || !password_test.test(e.target.value)) {
    //   setChangePassColor(false);
    // } else {
    //   setChangePassColor(true);
    // }
  }, []);

  // 비밀번호 확인 입력시
  const onChangeConfirm = useCallback(
    (e) => {
      setConfirmValue(e.target.value);

      // if (e.target.value === "" || e.target.value !== passwordValue) {
      //   setChangePass2Color(false);
      // } else {
      //   setChangePass2Color(true);
      // }
    },
    [passwordValue]
  );

  const btn_next2 = () => {
    if (String(getConValue) !== String(loginPass)) {
      return Swal.fire({
        title: "인증번호가 틀렸습니다.",
        // text: "PNG , JPG 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
    } else {
      setChange(false);
    }
  };

  const btn_change_pw = () => {
    if (passwordValue !== confirmValue) {
      return Swal.fire({
        title: "비밀번호가 일치하지 않습니다.",
        // text: "PNG , JPG 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
    }
    if (
      !password_test.test(passwordValue) ||
      !password_test.test(confirmValue)
    ) {
      return Swal.fire({
        title: "비밀번호 형식이 올바르지 않습니다.",
        // text: "PNG , JPG 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
    }

    const sendData = {
      email: loginId,
      password: passwordValue,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/password_find_change",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          Swal.fire({
            title: "비밀번호 변경완료",

            confirmButtonText: "확인",
            allowOutsideClick: false,
          }).then((res) => {
            if (res.isConfirmed) {
              navigate(`/login`);
            }
          });
        } else {
        }
      }
    );
  };
  return (
    <>
      <div className="common-wrap">
        {/* <div className="common-inner-wrap"> */}
        <div className={loginStyle.login_header_wrap}>
          <div className={loginStyle.login_header}>
            <img
              src={logo1}
              onClick={() => {
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        {change && (
          <div className={loginStyle.login_content_wrap}>
            <div className={loginStyle.login_content}>
              <h3
                className={`${loginStyle.login_h3} ${loginStyle.login_find_h3} `}
              >
                계정 찾기
              </h3>
              <span className={loginStyle.login_ex}>
                이메일을 입력해보세요.
              </span>
              <div className={loginStyle.login_box}>
                <div className={loginStyle.login_section}>
                  <input
                    type="text"
                    placeholder="이메일을 입력하세요."
                    onChange={onChange_id}
                  />
                  {num === 2 && (
                    <input
                      type="text"
                      placeholder="인증번호를 입력하세요."
                      onChange={onChange_pass}
                    />
                  )}
                </div>
                <div className={loginStyle.login_btn_box}>
                  {num === 1 ? (
                    <button
                      className={`${loginStyle.login_btn} ${loginStyle.login_nomal}`}
                      onClick={btn_next1}
                    >
                      찾기
                    </button>
                  ) : (
                    <button
                      className={`${loginStyle.login_btn} ${loginStyle.login_nomal}`}
                      onClick={btn_next2}
                    >
                      확인
                    </button>
                  )}
                </div>

                {/* <div
                className={`${loginStyle.login_btn_box} ${loginStyle.sns_login}`}
              >
                <span className={loginStyle.sns_title}>SNS계정으로 로그인</span>
                <KakaoLogin type={true} />

                <button
                  className={`${loginStyle.login_btn} ${loginStyle.login_naver}`}
                >
                  <img src={naver_icon} alt="naver_icon" />
                </button>
              </div> */}
              </div>
            </div>
          </div>
        )}{" "}
        {!change && (
          <div className={loginStyle.login_content_wrap}>
            <div className={loginStyle.login_content}>
              <h3
                className={`${loginStyle.login_h3} ${loginStyle.login_find_h3} `}
              >
                비밀번호 변경하기
              </h3>
              <span className={loginStyle.login_ex}>
                영문/ 숫자 / 특수문자 중 2가지 이상 조합 (10자 이상)
              </span>
              <div className={loginStyle.login_box}>
                <div className={loginStyle.login_section}>
                  <input
                    type="password"
                    placeholder="비밀번호를 입력하세요."
                    onChange={onChangePassword}
                  />

                  <input
                    type="password"
                    placeholder="비밀번호를 한번 더 입력하세요."
                    onChange={onChangeConfirm}
                  />
                </div>
                <div className={loginStyle.login_btn_box}>
                  <button
                    className={`${loginStyle.login_btn} ${loginStyle.login_nomal}`}
                    onClick={btn_change_pw}
                  >
                    변경
                  </button>
                </div>

                {/* <div
                className={`${loginStyle.login_btn_box} ${loginStyle.sns_login}`}
              >
                <span className={loginStyle.sns_title}>SNS계정으로 로그인</span>
                <KakaoLogin type={true} />

                <button
                  className={`${loginStyle.login_btn} ${loginStyle.login_naver}`}
                >
                  <img src={naver_icon} alt="naver_icon" />
                </button>
              </div> */}
              </div>
            </div>
          </div>
        )}
        {/* <div className="login-inner">
            <div className="login-left">
              <div className="login-hd">
                <h1 className="logo-box">
                  <Link
                    to="/"
                    style={{
                      width: "147px",
                    }}
                  >
                    <img
                      src={logo}
                      alt="logo"
                      style={{
                        display: "block",
                        width: "147px",
                        margin: "0 auto",
                        // opacity:"0"
                      }}
                    />
                  </Link>
                </h1>
              </div>
              <div className="login-content login-content-1">
                <h2 className="login-title">로그인</h2>
                <div className="login-box">
                  <KakaoLogin type={true} />

                  <NaverLogin onClick={() => false} />
                  <div
                    className="sns-login naver-login"
                    style={{
                      background: "#03C75A",
                      width: "100%",
                      height: "45px",
                      color: "#fff",
                    }}
                    onClick={() =>
                      document
                        .querySelector("#naverIdLogin_loginButton")
                        .click()
                    }
                  >
                    <img src={naver_icon} alt="naver_icon" />
                    <p>네이버로 시작하기</p>
                  </div>
                  <div
                    className="sns-login etc-login"
                    style={{
                      background: "#fff",
                      width: "100%",
                      height: "45px",
                      color: "#fff",
                    }}
                  >
                    <Link to="/login/email" style={{}}>
                      <img src={envelope} />

                      <p>다른 방법으로 시작하기</p>
                    </Link>
                  </div>
                </div>
              </div>

              <span className="login-span">
                본인은 사이트 이용약관, 개인정보 수집 및 이용, 그리고
                <br />
                개인정보 제공에 대한 내용을 확인하였고, 이에 동의합니다.
              </span>
            </div>
          </div> */}
        {/* </div> */}{" "}
      </div>
    </>
  );
}

export default FindAcount;
