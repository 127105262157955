import { combineReducers } from "redux";
import user from "./user";
import loading from "./loading";
import invest from "./invest";
import admin from "./admin";
const rootReducer = combineReducers({
  loading,
  user,
  invest,
  admin,
});

export default rootReducer;
