import { useRef, useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import loginStyle from "../styles/login.module.css";
const EmailConfirm = ({
  timeR,
  setMailNum,
  mailNum,
  setEmailConfirmTest,
  emailConfirmTest,
  setEmailBtn,
  setInputEmailDisable,
}) => {
  //이메일 인증 타이머

  const [min, setMin] = useState(3);
  const [sec, setSec] = useState(0);
  const time = useRef(180);
  const timerId = useRef(null);
  const [emailConfirmValue, setEmailConfirmValue] = useState("");
  const [emailConfirmDisable, setEmailConfirmDisable] = useState(false); //이메일 인증 disable
  const [emailConfirmBtn, setEmailConfirmBtn] = useState(false); //이메일 인증 버튼 Disalbe

  useEffect(() => {
    timerId.current = setInterval(() => {
      setMin(parseInt(timeR.current / 60));
      setSec(timeR.current % 60);

      timeR.current -= 1;
    }, 1000);
    return () => clearInterval(timerId.current);
  }, [timeR.current]);

  useEffect(() => {
    if (timeR.current < 0) {
      clearInterval(timerId.current);
      setMailNum("");
    }
  }, [sec, timeR.current]);
  // 이메일 인증번호 입력시
  const onChangeEmailConfirm = useCallback((e) => {
    setEmailConfirmValue(e.target.value);
  }, []);

  // 이메일 확인 버튼
  function emailConfirm() {
    // console.log(props.mailNum);
    // console.log(emailConfirmValue);
    if (mailNum !== "") {
      if (emailConfirmValue == mailNum) {
        setEmailConfirmTest(true);
        setEmailBtn(true);
        setInputEmailDisable(true);
        setEmailConfirmDisable(true);
        setEmailConfirmBtn(true);
        clearInterval(timerId.current);
      } else {
        setEmailConfirmTest(false);
      }
    }
  }

  return (
    <>
      <div className={`${loginStyle.signup_email_box} ${loginStyle.box2}`}>
        <Divcitation
          className={loginStyle.signup_email_inp2}
          min={min}
          sec={sec}
          emailConfirmTest={emailConfirmTest}
        >
          <input
            type="text"
            className="inp_number"
            placeholder="인증번호 6자리"
            onChange={onChangeEmailConfirm}
            disabled={
              min === 0 && sec === 0
                ? true
                : emailConfirmDisable === true
                ? true
                : false
            }
            // mobileView={props.mobileView}
          />

          <span>
            {min}:{sec > 9 ? `${sec}` : `0${sec}`}
          </span>
        </Divcitation>

        <BtnEmailConfirmSend
          type="button"
          onClick={emailConfirm}
          emailConfirmBtn={emailConfirmBtn}
          disabled={
            min === 0 && sec === 0
              ? true
              : emailConfirmBtn === true
              ? true
              : false
          }
          min={min}
          sec={sec}
        >
          인증하기
        </BtnEmailConfirmSend>
      </div>
      {emailConfirmTest === null ? null : emailConfirmTest ? (
        <em className={loginStyle.noerror_em}>인증번호가 일치합니다.</em>
      ) : (
        <em className={loginStyle.error_em}>인증번호가 일치하지않습니다.</em>
      )}
    </>
  );
};
const BtnEmailConfirmSend = styled.button`
  cursor: ${(props) =>
    props.min === 0 && props.sec === 0
      ? "not-allowed"
      : props.emailConfirmBtn
      ? "not-allowed"
      : "pointer"};
  background-color: #00c73c;
`;

const Divcitation = styled.div`
  background-color: ${(props) =>
    props.min === 0 && props.sec === 0
      ? "#ebebeb"
      : props.emailConfirmTest === true
      ? "#ebebeb"
      : "#fff"};
  border: ${(props) =>
    props.emailConfirmTest ? " 1px solid #a5a5a5d8" : "2px solid #e69999"};
`;
export default EmailConfirm;
