import { useRef, useState, useCallback, useEffect } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CommonAxios } from "../../CommonAxios";
import styled from "styled-components";

function MobileModalConfirm(props) {
  const [min, setMin] = useState(3);
  const [sec, setSec] = useState(0);
  // const [mobileConfirmValue, setMobileConfirmValue] = useState("");
  const timerId = useRef(null);
  // const [emailConfirmValue, setEmailConfirmValue] = useState("");

  useEffect(() => {
    timerId.current = setInterval(() => {
      setMin(parseInt(props.time.current / 60));
      setSec(props.time.current % 60);

      props.time.current -= 1;
    }, 1000);
    return () => clearInterval(timerId.current);
  }, [props.time.current, props.mobileView]);

  useEffect(() => {
    if (props.time.current < 1) {
      clearInterval(timerId.current);
      props.setMobileGetNum("");
      props.setMobileView(false);
    }
  }, [sec, props.time.current]);

  // // 휴대폰 인증번호 입력시
  const onChangeMobileConfirm = useCallback((e) => {
    props.setMobileConfirmValue(e.target.value);
    // console.log(e.target.value);
  }, []);

  function mobileConfirm() {
    // if (props.mobileGetNum !== "") {
    if (props.mobileConfirmValue == props.mobileGetNum) {
      props.setMobileConfirmTest(true);
      // props.setThreeError(false);
      props.setInputDisable(true);
      props.setMobileBtn(true);
      clearInterval(timerId.current);

      //   console.log("성공");
    } else {
      props.setMobileConfirmTest(false);
    }
    // }
  }

  return (
    <>
      <div className="modal-mobile-citationbox">
        <div className="modal-mobile-box">
          <InpConfirmValue
            type="text"
            className="inp_number"
            placeholder="인증번호 6자리"
            onChange={onChangeMobileConfirm}
            disabled={
              min === 0 && sec === 0
                ? true
                : props.mobileConfirmTest === true
                ? true
                : false
            }
            // mobileView={props.mobileView}
          />

          <SpanTime
            min={min}
            sec={sec}
            mobileConfirmTest={props.mobileConfirmTest}
          >
            {min}:{sec > 9 ? `${sec}` : `0${sec}`}
          </SpanTime>
        </div>
        <BtnValueSend
          min={min}
          sec={sec}
          mobileConfirmTest={props.mobileConfirmTest}
          type="button"
          className="btn_confirm"
          onClick={mobileConfirm}
          disabled={
            min === 0 && sec === 0
              ? true
              : props.mobileConfirmTest === true
              ? true
              : false
          }
        >
          인증 확인
        </BtnValueSend>
      </div>
      {props.mobileConfirmTest === null ? null : props.mobileConfirmTest ? (
        <p style={{ color: "#476cd3" }}>인증번호가 일치합니다.</p>
      ) : (
        <p>인증번호가 일치하지 않습니다.</p>
      )}
      {props.threeError === null ? null : props.threeError ? null : (
        <p>인증번호를 입력하지 않았습니다.</p>
      )}
    </>
  );
}

const BtnValueSend = styled.button`
  cursor: ${(props) =>
    props.min === 0 && props.sec === 0
      ? "not-allowed"
      : props.mobileConfirmTest === true
      ? "not-allowed"
      : "pointer"};
  background-color: ${(props) =>
    props.min === 0 && props.sec === 0
      ? "#ebebeb"
      : props.mobileConfirmTest === true
      ? "#ebebeb"
      : "#d6d6d6"};
`;
const InpConfirmValue = styled.input``;
const SpanTime = styled.span`
  background-color: ${(props) =>
    props.min === 0 && props.sec === 0
      ? "#ebebeb"
      : props.mobileConfirmTest === true
      ? "#ebebeb"
      : "#fff"};
`;
export default MobileModalConfirm;
