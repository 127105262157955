import styles from "../../styles/admin.module.css";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../CommonAxios";
import ProductWriteCom from "./details/ProductWriteCom";
import FarmWriteCom from "./details/FarmWriteCom";
import GoodsWriteCom from "./details/GoodsWriteCom";
import GoodsWriteComField from "./details/GoodsWriteComField";
import FarmWriteComField from "./details/FarmWriteComField";

// import AdminUserInfo from "./AdminUserInfo";
const FarmWriteField = () => {
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          투자현황 &nbsp;〉 &nbsp;상품투자현황 &nbsp;〉 &nbsp;농장 필드 수정{" "}
        </div>
        <div className={styles.wrap_content_box_body}>
          <FarmWriteComField />
        </div>
      </div>
    </>
  );
};

export default FarmWriteField;
