import style from "./Header.css";
import logo from "../../assets/images/Logo.png";
import logo1 from "../../assets/images/M12.png";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaUserCircle } from "react-icons/fa";
import { useEffect, useState, useCallback, useRef } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import pro from "../../assets/images/profile_info.png";
import { CommonAxios } from "../CommonAxios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BiMenu } from "react-icons/bi";
import { FiFacebook } from "react-icons/fi";
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { localeData } from "moment";
import { icons } from "react-icons";
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [locationData, setLocationData] = useState("");
  const [moreView, setMoreView] = useState(false);
  const slickRef = useRef(null);

  // const previous = useCallback(() => slickRef.current.slickPrev(), []);
  // const next = useCallback(() => slickRef.current.slickNext(), []);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 10,
    initialSlide: 0,
    // afterChange: (current) => {
    //   setState(current + 1);
    // },
    dotsClass: `header_custom_class_name`,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          initialSlide: 0,
          rows: 2,
          // slidesPerRow: 2,
        },
      },
    ],

    // arrows: ,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };
  const [state, setState] = useState(1);
  useEffect(() => {
    setMoreView(false);
    setLocationData(location.pathname);
  }, [location]);
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const keyEvent = (e) => {
    if (searchValue === "") return;
    if (e.key === "Enter") {
      navigate(`/search?keyword=${searchValue}`);
      setSearchValue("");
    }
  };
  const btn_logout = () => {
    let sendData = "";
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/signout",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          localStorage.removeItem("meta_info");
          document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  };
  const searchBtn = () => {
    if (searchValue === "") return;

    navigate(`/search?keyword=${searchValue}`);
    setSearchValue("");
  };
  const [searchValue, setSearchValue] = useState("");
  const LoginIcon = () => {
    return (
      <>
        <div className="header-login-icon-wrap">
          <Link to="/mypage">
            <div className="header-login-icon">
              <img src={user.ur_image !== "" ? user.ur_image : pro} />
            </div>
          </Link>
        </div>{" "}
      </>
    );
  };
  const changeSearch = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const move = () => {
    navigate("/mypage");
  };
  const move1 = () => {
    navigate("/login");
  };
  const move2 = () => {
    navigate("/SignUp");
  };
  const move3 = () => {
    navigate("/admin/users");
  };
  return (
    <>
      <div
        className={moreView ? "header-more-back backview" : "header-more-back"}
        onClick={() => setMoreView(false)}
      ></div>
      <div className="common-wrap">
        {/* <div className="common-inner-wrap"> */}
        <div className="header-m-box">
          <div className="header-wrap">
            <header className="header-box">
              <div className="header-left">
                <h1 className="header-logo">
                  <Link to="/">
                    <img src={logo1} />
                  </Link>
                </h1>
              </div>{" "}
              <div className="header-right">
                <div className="header-left-m">
                  <h1 className="header-logo">
                    <Link to="/">
                      <img src={logo1} />
                    </Link>
                  </h1>
                </div>{" "}
                <nav className="header-menu-wrap">
                  <ul>
                    <li className="hd_menu">
                      <Link
                        to="/company"
                        style={{
                          color:
                            locationData === "/company" ||
                            locationData === "/introduce"
                              ? "#00c73c"
                              : "black",
                          fontWeight:
                            locationData === "/company" ||
                            locationData === "/introduce"
                              ? "700"
                              : "500",
                        }}
                      >
                        회사소개
                      </Link>
                    </li>
                    <li className="hd_menu">
                      {" "}
                      <Link
                        to="/funding"
                        style={{
                          color:
                            locationData === "/funding" ? "#00c73c" : "black",
                          fontWeight:
                            locationData === "/funding" ? "700" : "500",
                        }}
                      >
                        펀딩+
                      </Link>
                    </li>
                    <li className="hd_menu">
                      <Link
                        to="/invest"
                        style={{
                          color:
                            locationData === "/invest" ? "#00c73c" : "black",
                          fontWeight:
                            locationData === "/invest" ? "700" : "500",
                        }}
                      >
                        투자현황
                      </Link>
                    </li>
                    <li className="hd_menu">
                      {" "}
                      <Link
                        to="/store"
                        style={{
                          color:
                            locationData === "/store" ? "#00c73c" : "black",
                          fontWeight: locationData === "/store" ? "700" : "500",
                        }}
                      >
                        스토어
                      </Link>
                    </li>
                    <li
                      className="hd_menu"
                      style={{
                        cursor: "pointer",
                        color: moreView ? "#00c73c" : "black",
                        fontWeight: moreView ? "700" : "500",
                      }}
                      onClick={() => setMoreView(!moreView)}
                    >
                      {" "}
                      {/* <a
                      href="https://smartstore.naver.com/metagreen"
                      target="_blank"
                    > */}{" "}
                      더보기 {moreView && <IoIosArrowUp />}
                      {!moreView && <IoIosArrowDown />}
                      {/* </a> */}
                    </li>
                    {/* <li className="hd_menu">
                    {" "}
                    <a href="https://www.metagreen.biz/" target="_blank">
                      메타그린{" "}
                    </a>
                  </li> */}
                  </ul>
                </nav>
                {locationData === "/company" && (
                  <div className="header-title-m">
                    <span>회사소개</span>
                  </div>
                )}{" "}
                {locationData === "/store" && (
                  <div className="header-title-m">
                    <span>스토어</span>
                  </div>
                )}{" "}
                {locationData === "/storyPage" && (
                  <div className="header-title-m">
                    <span>스토리</span>
                  </div>
                )}{" "}
                {locationData === "/newsPage" && (
                  <div className="header-title-m">
                    <span>언론보도</span>
                  </div>
                )}{" "}
                {locationData === "/qna" && (
                  <div className="header-title-m">
                    <span>1:1 문의하기</span>
                  </div>
                )}{" "}
                {locationData === "/introduce" && (
                  <div className="header-title-m">
                    <span>회사 자세히 보기</span>
                  </div>
                )}{" "}
                {locationData === "/funding" && (
                  <div className="header-title-m">
                    <span>펀딩+</span>
                  </div>
                )}{" "}
                {locationData === "/invest" && (
                  <div className="header-title-m">
                    <span>투자현황</span>
                  </div>
                )}{" "}
                {locationData === "/noticePage" && (
                  <div className="header-title-m">
                    <span>공지사항</span>
                  </div>
                )}{" "}
                {locationData !== "/noticePage" &&
                  locationData.indexOf("/notice") !== -1 && (
                    <div className="header-title-m">
                      <span>공지사항</span>
                    </div>
                  )}{" "}
                {locationData.indexOf("/mypage") !== -1 && (
                  <div className="header-title-m">
                    <span>마이페이지</span>
                  </div>
                )}{" "}
                {locationData.indexOf("/process") !== -1 && (
                  <div className="header-title-m">
                    <span>상품 정보</span>
                  </div>
                )}{" "}
                {locationData.indexOf("/join") !== -1 &&
                  locationData.indexOf("/my") === -1 && (
                    <div className="header-title-m">
                      <span>상품 가입</span>
                    </div>
                  )}{" "}
                {locationData === "/search" && (
                  <div className="header-title-m">
                    <span>검색 결과</span>
                  </div>
                )}
                {(locationData === "/my/invest/join" ||
                  locationData === "/my/invest/end" ||
                  locationData === "/my/invest/cancel") && (
                  <div className="header-title-m">
                    <span>나의 투자</span>
                  </div>
                )}
                {locationData === "/" && (
                  <div className="header-search-wrap-mbox">
                    <div className="header-search-wrap-m">
                      <input
                        type="text"
                        placeholder="투자상품 검색"
                        onChange={changeSearch}
                        value={searchValue}
                        onKeyPress={keyEvent}
                      />
                      <button onClick={searchBtn} style={{ cursor: "pointer" }}>
                        <BiSearchAlt2 />
                      </button>
                    </div>
                  </div>
                )}
                <div className="header-search-wrap">
                  <input
                    type="text"
                    placeholder="새로운 투자상품이 필요하신가요?"
                    onChange={changeSearch}
                    value={searchValue}
                    onKeyPress={keyEvent}
                  />
                  <button onClick={searchBtn} style={{ cursor: "pointer" }}>
                    <BiSearchAlt2 />
                  </button>
                </div>
                <div
                  className={
                    user ? "header-sub-menu userview" : "header-sub-menu"
                  }
                >
                  <div className="header-login">
                    {!user && (
                      <>
                        <span>
                          <Link to="login">로그인</Link>
                        </span>
                        <span>
                          <Link to="SignUp">회원가입</Link>
                        </span>
                      </>
                    )}
                    {user && user.ur_kind !== 1 && (
                      <>
                        <span>
                          <Link to="/mypage">마이페이지</Link>
                        </span>
                        <span
                          onClick={btn_logout}
                          style={{ cursor: "pointer" }}
                        >
                          로그아웃
                        </span>
                      </>
                    )}

                    {user && user.ur_kind === 1 && (
                      <>
                        {" "}
                        <span>
                          <Link to="/admin/users">관리자페이지</Link>{" "}
                        </span>
                        <span
                          onClick={btn_logout}
                          style={{ cursor: "pointer" }}
                        >
                          로그아웃
                        </span>
                      </>
                    )}
                  </div>

                  <div
                    className="header-move-sub"
                    style={{
                      background:
                        locationData === "/my/invest/join" ||
                        locationData === "/my/invest/end" ||
                        locationData === "/my/invest/cancel"
                          ? "black"
                          : "#00c73c",
                    }}
                  >
                    <Link to="/my/invest/join">나의 투자 보기</Link>
                  </div>
                </div>
                <div
                  className="header-mobile-icon"
                  onClick={() => setMoreView(true)}
                >
                  {/* <img src={m_con_21} /> */}
                  <BiMenu />
                </div>
              </div>
              {/* <div className="header-right">
              <nav className="header-menu-wrap">
                <ul>
                  <li className="hd_menu">
                    <Link to="/introduce">메타12</Link>
                  </li>
                  <li className="hd_menu">
                    {" "}
                    <Link to="/funding">펀딩</Link>
                  </li>
                  <li className="hd_menu">
                    {" "}
                    <a
                      href="https://smartstore.naver.com/metagreen"
                      target="_blank"
                    >
                      {" "}
                      스토어
                    </a>
                  </li>
                  <li className="hd_menu">
                    {" "}
                    <a href="https://www.metagreen.biz/" target="_blank">
                      메타그린{" "}
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="header-search-wrap">
                <input
                  type="text"
                  placeholder="어떤 것을 찾으시나요?"
                  onChange={changeSearch}
                  value={searchValue}
                  onKeyPress={keyEvent}
                />
                <button onClick={searchBtn} style={{ cursor: "pointer" }}>
                  <BiSearchAlt2 />
                </button>
              </div>
              <div className="header-sub-menu">
                <div className="header-move-sub">
                  <Link to="/invest/smartfarm/farm"> 투자하러가기</Link>
                </div>
                <div className="header-login">
                  {!user && <Link to="login">로그인</Link>}
                  {user && <LoginIcon />}
                </div>
              </div>
            </div> */}
              {/* <div className="header-mobile-slider">
              <ul className="header-mobile-sliderul">
                <Slider
                  {...settings}
                  ref={slickRef}
                  className="header-mobile-sl"
                >
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_1} />
                        </div>
                      </div>
                      <span>회사소개</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_6} />
                        </div>{" "}
                      </div>
                      <span>전체상품</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_2} />
                        </div>{" "}
                      </div>
                      <span>펀딩+</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_7} />
                        </div>{" "}
                      </div>
                      <span>NEW상품</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_3} />
                        </div>{" "}
                      </div>
                      <span>투자상품</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_8} />
                        </div>{" "}
                      </div>
                      <span>BEST상품</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_4} />
                        </div>{" "}
                      </div>
                      <span>스토어</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_9} />
                        </div>{" "}
                      </div>
                      <span>Q&A</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_5} />
                        </div>{" "}
                      </div>
                      <span>이용안내</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_10} />
                        </div>{" "}
                      </div>
                      <span>공지사항</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_11} />
                        </div>{" "}
                      </div>
                      <span>수경이채소</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_16} />
                        </div>{" "}
                      </div>
                      <span>제주알로에</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_12} />
                        </div>{" "}
                      </div>
                      <span>허브팩토리</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_17} />
                        </div>{" "}
                      </div>
                      <span>제주건강즙</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_13} />
                        </div>{" "}
                      </div>
                      <span>창산리M1팜</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_18} />
                        </div>{" "}
                      </div>
                      <span>제주콜라겐</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_14} />
                        </div>{" "}
                      </div>
                      <span>신흥리M2팜</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_19} />
                        </div>{" "}
                      </div>
                      <span>양배추즙</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_15} />
                        </div>{" "}
                      </div>
                      <span>앵천리M3팜</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_20} />
                        </div>{" "}
                      </div>
                      <span>더보기</span>
                    </a>
                  </li>
                </Slider>
              </ul>{" "}
              <div className="header-slider-dot"></div>
            </div> */}
              {/* <div className="header-mobile-slider">
              <ul className="header-mobile-sliderul">
                <Slider
                  {...settings}
                  ref={slickRef}
                  className="header-mobile-sl"
                >
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_1} />
                        </div>
                      </div>
                      <span>회사소개</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_6} />
                        </div>{" "}
                      </div>
                      <span>전체상품</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_2} />
                        </div>{" "}
                      </div>
                      <span>펀딩+</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_7} />
                        </div>{" "}
                      </div>
                      <span>NEW상품</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_3} />
                        </div>{" "}
                      </div>
                      <span>투자상품</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_8} />
                        </div>{" "}
                      </div>
                      <span>BEST상품</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_4} />
                        </div>{" "}
                      </div>
                      <span>스토어</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_9} />
                        </div>{" "}
                      </div>
                      <span>Q&A</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_5} />
                        </div>{" "}
                      </div>
                      <span>이용안내</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_10} />
                        </div>{" "}
                      </div>
                      <span>공지사항</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_11} />
                        </div>{" "}
                      </div>
                      <span>수경이채소</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_16} />
                        </div>{" "}
                      </div>
                      <span>제주알로에</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_12} />
                        </div>{" "}
                      </div>
                      <span>허브팩토리</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_17} />
                        </div>{" "}
                      </div>
                      <span>제주건강즙</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_13} />
                        </div>{" "}
                      </div>
                      <span>창산리M1팜</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_18} />
                        </div>{" "}
                      </div>
                      <span>제주콜라겐</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_14} />
                        </div>{" "}
                      </div>
                      <span>신흥리M2팜</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_19} />
                        </div>{" "}
                      </div>
                      <span>양배추즙</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_15} />
                        </div>{" "}
                      </div>
                      <span>앵천리M3팜</span>
                    </a>
                  </li>{" "}
                  <li className="header-mobile-sliderli">
                    <a href="#">
                      {" "}
                      <div className="header-slider-img-box">
                        <div className="header-slider-img">
                          <img src={m_con_20} />
                        </div>{" "}
                      </div>
                      <span>더보기</span>
                    </a>
                  </li>
                </Slider>
              </ul>{" "}
              <div className="header-slider-dot"></div>
            </div> */}
            </header>

            <div
              className={
                moreView ? "header-more-wrap view" : "header-more-wrap"
              }
            >
              <div className="header-more-box">
                <div className="header-more-content">
                  {/* <ul className="header-more-ul ul-one">
                    <li>
                      <a
                        target="_blank"
                        href="https://blog.naver.com/asiaro123"
                      >
                        <span className="header-li-back">
                          <span className="header-li-img"></span>
                        </span>{" "}
                        블로그
                      </a>
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@ASIAROTV"
                      >
                        <span className="header-li-back">
                          <span className="header-li-img"></span>
                        </span>{" "}
                        아시아로TV{" "}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        href="https://www.facebook.com/asiaro123"
                      >
                        <span className="header-li-back">
                          <span className="header-li-img">
                            <FiFacebook />{" "}
                          </span>
                        </span>{" "}
                        페이스북
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        href="https://www.instagram.com/asiaro1234"
                      >
                        <span className="header-li-back">
                          <span className="header-li-img">
                            <AiOutlineInstagram />{" "}
                          </span>
                        </span>{" "}
                        인스타그램
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a target="_blank" href="https://twitter.com/asiaro123">
                        <span className="header-li-back">
                          <span className="header-li-img">
                            <AiOutlineTwitter />{" "}
                          </span>
                        </span>{" "}
                        트위터
                      </a>
                    </li>
                  </ul> */}
                  <ul
                    className="header-more-ul"
                    style={{ display: windowSize >= 1100 ? "block" : "none" }}
                  >
                    <li className="li-more-title">바로가기</li>
                    <li>
                      <Link to="/company">회사소개</Link>
                    </li>
                    {/* <li>
                      {" "}
                      <Link to="/introduce">회사 자세히 보기</Link>
                    </li> */}
                    <li>
                      {" "}
                      <Link to="/funding">펀딩+</Link>
                    </li>
                    <li>
                      <Link to="/invest">투자현황</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/store">스토어</Link>
                    </li>
                    {/* <li>
                    {" "}
                    <Link to="/invest">도움말 센터</Link>
                  </li> */}
                    {/* <li>서퍼터즈 지원하기</li> */}
                  </ul>
                  <ul
                    className="header-more-ul"
                    style={{ display: windowSize < 1100 ? "block" : "none" }}
                  >
                    {/* <li className="li-more-title">바로가기</li> */}
                    <li>
                      <Link to="/company">회사소개</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/funding">펀딩+</Link>
                    </li>
                    <li>
                      <Link to="/invest">투자현황</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/store">스토어</Link>
                    </li>

                    {/* <li>
                    {" "}
                    <Link to="/invest">도움말 센터</Link>
                  </li> */}
                    {/* <li>서퍼터즈 지원하기</li> */}
                  </ul>
                  {/* <ul className="header-more-ul">
                  <li className="li-more-title">메타12소개</li>
                  <li>메타12 브랜드</li>
                  <li>메타12 이야기</li>
                  <li>메타12 공간</li>
                  <li>메타12 피플</li>
                  <li>메타12 프로그램</li>
                  <li>메타12 투어</li>
                </ul> */}
                  <ul className="header-more-ul">
                    <li className="li-more-title">공지</li>
                    <li>
                      {" "}
                      <Link to="/noticePage">공지사항</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/newsPage">언론보도</Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/storyPage">스토리</Link>
                    </li>
                    {/* <li>이벤트</li> */}
                    {/* <li>
                      
                      <Link to="/noticePage/pageTwo">보도 자료</Link>
                    </li> */}
                    {/* <li>채용</li> */}
                  </ul>{" "}
                  {!user && (
                    <ul
                      className="header-more-ul"
                      style={{ display: windowSize < 1100 ? "block" : "none" }}
                    >
                      {" "}
                      <li className="li-more-title sub" onClick={() => move1()}>
                        로그인
                      </li>
                      <li className="li-more-title sub" onClick={() => move2()}>
                        회원가입
                      </li>
                      {/* <li>
                    {" "}
                    <Link to="/invest">도움말 센터</Link>
                  </li> */}
                      {/* <li>서퍼터즈 지원하기</li> */}
                    </ul>
                  )}{" "}
                  {user && (
                    <ul
                      className="header-more-ul"
                      style={{ display: windowSize < 1100 ? "block" : "none" }}
                    >
                      {" "}
                      {user.ur_kind === 1 && (
                        <li
                          className="li-more-title sub"
                          onClick={() => move3()}
                        >
                          관리자 페이지
                        </li>
                      )}
                      {user.ur_kind !== 1 && (
                        <li
                          className="li-more-title sub"
                          onClick={() => move()}
                        >
                          마이페이지
                        </li>
                      )}
                      <li className="li-more-title" onClick={btn_logout}>
                        로그아웃
                      </li>
                      {/* <li>
                    {" "}
                    <Link to="/invest">도움말 센터</Link>
                  </li> */}
                      {/* <li>서퍼터즈 지원하기</li> */}
                    </ul>
                  )}
                  <ul
                    className="header-more-ul"
                    style={{ display: windowSize >= 1100 ? "block" : "none" }}
                  >
                    <li className="li-more-title">관련 사이트</li>
                    <li>
                      {" "}
                      <a href="http://asiaro.net/" target="_blank">
                        (주)아시아로
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="https://www.metagreen.biz/" target="_blank">
                        (주)메타그린
                      </a>
                    </li>{" "}
                    {/* <li>
                      <a href="https://www.farmjeju.co.kr/" target="_blank">
                        (주)제주농장
                      </a>
                    </li> */}
                  </ul>{" "}
                  <ul className="header-more-ul ul-one">
                    <li>
                      <a
                        target="_blank"
                        href="https://blog.naver.com/asiaro123"
                      >
                        <span className="header-li-back">
                          <span className="header-li-img"></span>
                        </span>{" "}
                        블로그
                      </a>
                    </li>{" "}
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@ASIAROTV"
                      >
                        <span className="header-li-back">
                          <span className="header-li-img"></span>
                        </span>{" "}
                        아시아로TV{" "}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        href="https://www.facebook.com/asiaro123"
                      >
                        <span className="header-li-back">
                          <span className="header-li-img">
                            <FiFacebook />{" "}
                          </span>
                        </span>{" "}
                        페이스북
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        target="_blank"
                        href="https://www.instagram.com/asiaro1234"
                      >
                        <span className="header-li-back">
                          <span className="header-li-img">
                            <AiOutlineInstagram />{" "}
                          </span>
                        </span>{" "}
                        인스타그램
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a target="_blank" href="https://twitter.com/asiaro123">
                        <span className="header-li-back">
                          <span className="header-li-img">
                            <AiOutlineTwitter />{" "}
                          </span>
                        </span>{" "}
                        트위터
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default Header;
