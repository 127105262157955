import React, { useState, useEffect, useRef } from "react";
import ProductInfo from "./../components/admin/details/ProductInfo";
import ProductSubTable from "./../components/admin/details/ProductSubTable";
import ProductInvestList from "../components/admin/details/ProductInvestList";
import Moment from "react-moment";
import styles from "../styles/admin.module.css";
import ProductRefundList from "../components/admin/details/ProductRefundList";
import ProductInterestList from "../components/admin/details/ProductInterestList";
import ProductInvestList2 from "../components/admin/details/ProductInvestList2";
import ProductEndList from "../components/admin/details/ProductEndList";

const ProductDetail = () => {
  const [options, setOptions] = useState("all");

  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          펀딩+ &nbsp;〉 &nbsp;투자 상품 목록〉 &nbsp;상품 상세
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.user_header}>
            <div className={styles.user_option} style={{ width: "780px" }}>
              <div
                className={
                  options == "all"
                    ? `${styles.all} ${styles.clicked}`
                    : styles.all
                }
                style={{ width: "130px" }}
                onClick={() => setOptions("all")}
              >
                정보
              </div>
              <div
                className={
                  options == "recent"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                style={{ width: "130px" }}
                onClick={() => setOptions("recent")}
              >
                상품현황
              </div>
              <div
                className={
                  options == "list"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                style={{ width: "130px" }}
                onClick={() => setOptions("list")}
              >
                투자진행내역
              </div>{" "}
              <div
                className={
                  options == "list2"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                style={{ width: "130px" }}
                onClick={() => setOptions("list2")}
              >
                이자출금내역
              </div>{" "}
              <div
                className={
                  options == "list1"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                style={{ width: "130px" }}
                onClick={() => setOptions("list1")}
              >
                투자종료내역
              </div>{" "}
              <div
                className={
                  options == "list3"
                    ? `${styles.noraml} ${styles.clicked}`
                    : styles.noraml
                }
                style={{ width: "130px" }}
                onClick={() => setOptions("list3")}
              >
                투자환불/취소내역
              </div>{" "}
            </div>
            {/* <div className={styles.header_btns}>
              <button onClick={() => chgRecommend()}>추천변경</button>
            </div> */}
          </div>
          {options == "all" ? (
            <div className={styles.wrap_cont_box_area}>
              <ProductInfo />
              {/* <ProductSubTable /> */}
            </div>
          ) : null}{" "}
          {options === "recent" && <ProductInvestList2 />}
          {options === "list" && <ProductInvestList />}{" "}
          {options === "list1" && <ProductEndList />}{" "}
          {options === "list2" && <ProductInterestList />}
          {options === "list3" && <ProductRefundList />}
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
