import styles from "../../../styles/admin.module.css";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import pro from "../../../assets/images/profile_info.png";
import Moment from "react-moment";
import CountUp from "react-countup";
import { numberToKorean } from "../../../lib/numberToKorean";
import { wonComma } from "../../../lib/wonComma";
import { FaArrowUp } from "react-icons/fa";
import moment from "moment";
const AdminRefundOneDetail = ({ data, datadata }) => {
  const wonReplace = () => {
    let won = String(data.pro_target_amount);
    if (won.length === 6) {
      return wonComma(data.pro_target_amount);
    } else if (won.length === 7) return numberToKorean(data.pro_target_amount);
    // return numberToKorean(580000000);
  };
  const wonReplace1 = () => {
    let won = String(data.pro_target_amount);
    if (won.length === 6) {
      return wonComma(data.pro_target_amount);
    } else if (won.length === 7)
      // return numberToKorean(926350000);
      return numberToKorean(data.amount);
  };

  const percent = () => {
    let result = data.pro_target_amount;
    let ing = data.amount;

    return (ing / result) * 100;
    // return 159;
  };
  const totalSeconds = 86400;
  const [count, setCount] = useState(0);
  const [endCount, setEndCount] = useState(0);
  const [switchs, setSwtichs] = useState(true);

  useEffect(() => {
    const startSecond = new Date(
      moment().format("YYYY-MM-DD 00:00:00")
    ).getTime();

    const tenSecUp = (data.invest_interest_ing / totalSeconds) * 1; // 10초당 올라갈 숫자
    const interval = setInterval(() => {
      // console.log(tenSecUp);
      const elapsedMSec = (new Date().getTime() - startSecond) / 1000;

      const currentCount = (elapsedMSec / 1) * tenSecUp; // 현재 숫자 계산
      const endCounts = (elapsedMSec / 1 + 1) * tenSecUp; // 다음 숫자 계산

      setCount(data.invest_interest_sum + currentCount);

      setEndCount(data.invest_interest_sum + endCounts);
    }, 1000); // 10초마다 count 값 갱신

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <div className={styles.invest_wrap}>
        <div className={styles.invest_withdraw_section}>
          <div className={styles.invest_withdraw_box}>
            <div className={styles.invest_product_item}>
              <h2>투자 상품 현황</h2>
              <div className={styles.invest_product_box}>
                {data && (
                  <>
                    {" "}
                    <div className={styles.invest_my_data_box}>
                      <div className={styles.invest_my_data_top}>
                        <p>{data.pro_title}</p>
                        <div className={styles.invest_my_data_top_div}>
                          <div>
                            {" "}
                            <span>
                              시작일 :{" "}
                              <Moment format="YYYY-MM-DD 00:00:00">
                                {data.invest_start_date}
                              </Moment>
                            </span>{" "}
                            <span>
                              마감일 :{" "}
                              <Moment format="YYYY-MM-DD 23:59:59">
                                {data.invest_end_date}
                              </Moment>
                            </span>
                          </div>

                          <span>수익률 : {data.pro_rate}%</span>
                        </div>
                      </div>
                      <div className={styles.invest_my_data_box_1}>
                        <span>투자금액</span>
                        <p>
                          <em>{wonComma(data.invest_amount)}</em>원
                        </p>
                      </div>{" "}
                      <div className={styles.invest_my_data_box_2}>
                        <span>지금까지 쌓인 이자</span>
                        <p>
                          <em>
                            {" "}
                            {wonComma(data.invest_refund_stop)}
                            {
                              // data.invest_confirm === "E"
                              //   ? wonComma(data.invest_interest_sum)
                              //   : wonComma(datadata.end_interest_result)
                              // <CountUp
                              //   start={count}
                              //   end={endCount}
                              //   duration={1}
                              //   separator={","}
                              //   decimals={3}
                              //   // suffix={"원"}
                              // />
                            }
                          </em>
                          원
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminRefundOneDetail;
