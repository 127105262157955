import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import styles from "../../../styles/admin.module.css";
import Swal from "sweetalert2";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";

import { CommonAxios } from "../../CommonAxios";
function AnalyzeUserCustomTable({
  columns,
  data,
  options,
  setOptions,
  arrayList,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    selectedFlatRows,
    prepareRow,
    //   canPreviousPage,
    //   canNextPage,
    pageOptions,
    pageCount, // 총 페이지
    gotoPage,
    //   nextPage,
    //   previousPage,
    //   setPageSize,
    state: { pageIndex, pageSize }, // pageIndex : 현재 페이지( 0 ~ 총페이지-1 ex) 1페이지 : 0 )
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // getToggleAllPageRowsSelectedProps : 페이지에 보여지는 row 전체 선택
          // getToggleAllRowsSelec.tedProps : 모든 row 전체 선택
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height: "44px",
                // background: "red",
                paddingTop: "20px",
              }}
            >
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  //체크박스 옵션
  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            style={{ width: "32px" }}
          />
        </>
      );
    }
  );

  useEffect(() => {}, [pageIndex]);

  const btn_send = () => {
    // console.log(selectedFlatRows);
  };

  const btn_message = () => {
    if (selectedFlatRows.length === 0 || !selectedFlatRows) {
      return Swal.fire({
        title: "전송 실패",
        html: `전송할 회원을 체크해주세요.`,

        confirmButtonText: "확인",
      });
    }

    let mobileArr = [];
    let mobileSt = "";
    for (let i = 0; i < selectedFlatRows.length; i++) {
      if (selectedFlatRows[i].original.user_mobile) {
        mobileArr.push(selectedFlatRows[i].original.user_mobile);
        mobileSt += selectedFlatRows[i].original.user_mobile + ",";
      }
    }
    mobileSt = mobileSt.slice(0, -1);
    if (mobileArr.length !== selectedFlatRows.length) {
      return Swal.fire({
        title: "전송 실패",
        html: `핸드폰 미등록된 회원이 존재합니다. 확인바랍니다`,

        confirmButtonText: "확인",
      });
    } else {
      let content = "";
      Swal.fire({
        title: "문자 전송",
        showCancelButton: true,
        html: `전송할 메시지를 입력하세요<br/>
        <textarea style="width:200px; height:90px; resize:none; margin:0 auto; margin-top:5px; border:1px solid #dadde6;" id="num-value1"></textarea>
        <p styles="font-size:10px";"margin-top:4px";> <a href="https://solapi.com/" target="_blank" style="font-weight:bold";> 문자 충전 및 정보 클릭 </a></p>
       `,
        confirmButtonText: "수신",
        cancelButtonText: "취소",
        preConfirm: (data) => {
          const inputNames =
            Swal.getHtmlContainer().querySelector("#num-value1");
          if (inputNames.value) {
            content = inputNames.value;
          } else {
            Swal.showValidationMessage(`빈칸을 채우세요.`);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          var sendData = {
            mobile: mobileArr,
            content: content,
          };
          // setMobileGetNum("123456");
          CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin_send_sms",
            sendData,
            function (result) {
              // console.log(result);
              if (result.messageinfo.state == "ok") {
                Swal.fire({
                  title: "전송 완료",

                  confirmButtonText: "확인",
                });
              } else {
              }
            }
          );
        }
      });
    }
  };
  return (
    <>
      <div className={styles.table_style_analyze} {...getTableProps()}>
        <div className={styles.table_style_theader}>
          {headerGroups.map((headerGroup) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className={styles.table_style_tr}
            >
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            background: "rgb(0, 199, 60)",
                            // visibility: "visible",
                            lineHeight: "18px",
                            fontSize: "10px",
                            color: "#fff",
                            borderRadius: "3px",
                          }}
                        >
                          <BiSolidDownArrow style={{ marginBottom: "5px" }} />
                        </i>
                      ) : (
                        <i
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            background: "rgb(0, 199, 60)",
                            // visibility: "visible",
                            lineHeight: "18px",
                            fontSize: "10px",
                            color: "#fff",
                            borderRadius: "3px",
                          }}
                        >
                          <BiSolidUpArrow style={{ marginBottom: "5px" }} />{" "}
                        </i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className={styles.table_style_tbody}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className={styles.table_style_tr}>
                {row.cells.map((cell) => (
                  <div {...cell.getCellProps()}>{cell.render("Cell")}</div>
                ))}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.table_pagination_analyze}>
        <button
          className={styles.table_pagination_analyze_btn}
          onClick={btn_message}
        >
          문자전송
        </button>
        <div className={styles.table_pagination_analyze_box}>
          {pageIndex >= 10 && (
            <button
              onClick={() => gotoPage(Math.floor(pageIndex / 10) * 10 - 1)}
            >
              {"<"}
            </button>
          )}
          {pageOptions.map((page, index) => {
            if (pageIndex < 10 && index < 10) {
              return (
                <button
                  key={index}
                  onClick={() => gotoPage(index)}
                  style={{
                    fontWeight: pageIndex === index ? "bold" : "normal",
                    color: pageIndex === index ? "#fff" : "#000",
                    background:
                      pageIndex === index ? "rgb(0, 199, 60)" : "#f5f5f5",

                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                >
                  {index + 1}
                </button>
              );
            } else if (pageIndex >= 10) {
              const currentPageGroup = Math.floor(pageIndex / 10); // 현재 페이지 그룹 번호 계산
              const startPageIndex = currentPageGroup * 10; // 현재 페이지 그룹의 첫 번째 페이지 인덱스
              const endPageIndex = Math.min(startPageIndex + 10, pageCount); // 현재 페이지 그룹의 마지막 페이지 인덱스
              if (index >= startPageIndex && index < endPageIndex) {
                return (
                  <button
                    key={index}
                    onClick={() => gotoPage(index)}
                    style={{
                      fontWeight: pageIndex === index ? "bold" : "normal",
                      color: pageIndex === index ? "#fff" : "#000",
                      background:
                        pageIndex === index ? "rgb(0, 199, 60)" : "#f5f5f5",

                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                    }}
                  >
                    {index + 1}
                  </button>
                );
              }
            }
            return null;
          })}
          {Math.floor(pageIndex / 10) < Math.floor(pageCount / 10) && (
            <button
              onClick={() => gotoPage(Math.floor(pageIndex / 10 + 1) * 10)}
            >
              {">"}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default AnalyzeUserCustomTable;
