import MypageProfileDetail from "../components/mypage/MypageProfileDetail";

const MypageInfoProfile = () => {
  return (
    <>
      <MypageProfileDetail />
    </>
  );
};

export default MypageInfoProfile;
