import React, { useState, useEffect, useRef } from "react";
import FarmTable from "../components/admin/details/FarmTable";
import { useNavigate } from "react-router-dom";
import styles from "../styles/admin.module.css";
import GoodsTable from "../components/admin/details/GoodsTable";
const GoodsList = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          투자현황 &nbsp;〉 &nbsp;상품투자현황{" "}
          <div>
            <span className={styles.header_button}>
              {" "}
              <button
                onClick={() => {
                  navigate("/admin/goods/field");
                }}
              >
                필드 수정
              </button>
            </span>{" "}
            <span className={styles.header_button}>
              {" "}
              <button
                onClick={() => {
                  navigate("/admin/goods/write");
                }}
              >
                상품 등록
              </button>
            </span>
          </div>
        </div>
        <div className={styles.wrap_content_box_body}>
          <GoodsTable />
        </div>
      </div>
    </>
  );
};

export default GoodsList;
