import React, { useState, useEffect, useRef, useCallback } from "react";
import ProductInfo from "./../components/admin/details/ProductInfo";
import ProductSubTable from "./../components/admin/details/ProductSubTable";
import ProductInvestList from "../components/admin/details/ProductInvestList";
import Moment from "react-moment";
import styles from "../styles/admin.module.css";
import ProductRefundList from "../components/admin/details/ProductRefundList";
import ProductInterestList from "../components/admin/details/ProductInterestList";
import ProductInvestList2 from "../components/admin/details/ProductInvestList2";
import { useNavigate, useParams } from "react-router-dom";
import { textReplace } from "../lib/textReplace";
import { CommonAxios } from "../components/CommonAxios";
import Swal from "sweetalert2";
const FnqDetail = () => {
  const [options, setOptions] = useState("all");
  const navigate = useNavigate();
  const [openValue, setOpenValue] = useState("Y");
  const [qu, setQu] = useState("");
  const [an, setAn] = useState("");
  const [agency, setAgency] = useState("");
  const [dateValue, setDateValue] = useState("");
  const changeQu = useCallback((e) => {
    setQu(e.target.value);
  }, []);

  const changeAn = useCallback((e) => {
    setAn(e.target.value);
  }, []);
  const changeAgency = useCallback((e) => {
    setAgency(e.target.value);
  }, []);

  const changeDate = useCallback((e) => {
    setDateValue(e.target.value);
  }, []);

  const { id } = useParams();

  useEffect(() => {
    let sendData = {
      no: id,
      // mobile :
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_fnq_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setOpenValue(result.messageinfo.message[0].fnq_open);
          setQu(result.messageinfo.message[0].fnq_question);
          setAn(result.messageinfo.message[0].fnq_answer);
        } else {
        }
      }
    );
  }, []);
  const btn_fnq_reg = () => {
    if (!qu || !openValue || !an) {
      return Swal.fire({
        title: "빈 칸을 채워주세요.",

        confirmButtonText: "확인",
      });
    }

    let sendData = {
      qu: textReplace(qu),
      an: textReplace(an),
      open: openValue,
      no: id,
      // mobile :
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_fnq",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          Swal.fire({
            title: "수정 완료",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/admin/fnq");
            }
          });
        } else {
          navigate("/admin/fnq");
        }
      }
    );
  };
  const change_open = (data) => {
    setOpenValue(data);
  };
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          게시판 관리 &nbsp;〉 &nbsp;자주하는 질문 목록〉 &nbsp;자주하는 질문
          상세
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.fnq_write_wrap}>
            <div className={styles.fnq_write_inner}>
              <div className={styles.fnq_write_title}>
                <h4>자주하는 질문 수정</h4>
                <div className={styles.fnq_write_button}>
                  <button onClick={btn_fnq_reg}>수정</button>
                </div>
              </div>
              <div className={styles.fnq_write_content}>
                <div className={styles.fnq_write_bottom}>
                  <div>
                    <label>질문</label>
                    <input
                      className={styles.fnq_write_inp}
                      placeholder="질문을 입력해주세요."
                      onChange={changeQu}
                      value={qu}
                      // onChange={onChangeTitle}
                      // value={title}
                    />
                  </div>
                  <div>
                    {" "}
                    <label>답변</label>
                    <textarea
                      className={styles.fnq_write_inp_area}
                      onChange={changeAn}
                      value={an}
                      placeholder="답변을 입력해주세요."
                    ></textarea>
                  </div>
                  {/* <div className={styles.fnq_open_box}>
                    <h4>공개 여부</h4>
                    <label>
                      <input
                        type="radio"
                        name="open"
                        value="Y"
                        onChange={() => change_open("Y")}
                        checked={openValue === "Y" ? true : false}
                      />{" "}
                      공개
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="open"
                        value="N"
                        onChange={() => change_open("N")}
                        checked={openValue === "N" ? true : false}
                      />{" "}
                      비공개
                    </label>
                  </div> */}

                  {/* <textarea></textarea> */}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FnqDetail;
