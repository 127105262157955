import styles from "../../styles/admin.module.css";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../CommonAxios";
import NoticeWriteDetail from "./detail/NoticeWriteDetail";
// import AdminUserInfo from "./AdminUserInfo";
const NoticeWrite = () => {
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          게시판 관리 &nbsp;〉 &nbsp;공지사항 등록{" "}
        </div>
        <div className={styles.wrap_content_box_body}>
          <NoticeWriteDetail type={"write"} />
        </div>
      </div>
    </>
  );
};

export default NoticeWrite;
