import React, { useState, useEffect, useRef } from "react";
import FarmTable from "../components/admin/details/FarmTable";
import { useNavigate } from "react-router-dom";
import styles from "../styles/admin.module.css";
import CategoryTable from "../components/admin/details/CategoryTable";
const CategoryList = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const clickMove = () => {
    setOpen(true);
    setOpen2(false);
  };
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          펀딩+ &nbsp;〉 &nbsp;카테고리{" "}
          <span className={styles.header_button}>
            <button onClick={clickMove} style={{ cursor: "pointer" }}>
              카테고리 등록
            </button>
          </span>
        </div>
        <div className={styles.wrap_content_box_body}>
          <CategoryTable
            setOpen={setOpen}
            setOpen2={setOpen2}
            open2={open2}
            open={open}
          />
        </div>
      </div>
    </>
  );
};

export default CategoryList;
