import React, { useState, useEffect, useRef } from "react";

import logo from "../logo.svg";
// import "../App.css";
import { useSelector } from "react-redux";

import MainBanner from "../components/main/MainBanner";
import MainSection1 from "../components/main/MainSection1";
import SuccessAlert from "../components/alert/Alert";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { CommonAxios } from "../components/CommonAxios";
import Modal from "../lib/Modal";
function Main() {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("meta_visit")) {
      localStorage.removeItem("meta_visit");
    }

    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/main_select_popup",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          if (result.messageinfo.message.length > 0) {
            setData(result.messageinfo.message[0]);
            setModalVisible(true);
          }
        } else {
        }
      }
    );
    return () => {
      setModalVisible(false);
    };
  }, []);
  const [modalVisible, setModalVisible] = useState(false);

  // const openModal = () => {
  //     setModalVisible(true)
  // }
  const closeModal = () => {
    setModalVisible(false);
  };
  const [message, setMessage] = useState("");

  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    const getToken = () => {
      const url = process.env.REACT_APP_HOSTDONAME + "/api/tokenEnd";

      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "x-www-form-urlencoded",
        },
      };
      const FAIL = "쿠키가 삭제되었습니다.";
      axios.post(url, config).then((res) => {
        if (res.data.messageinfo.message === FAIL) {
          Swal.fire({
            title: "로그인 만료",
            text: "로그인 페이지로 이동합니다.",
            allowOutsideClick: false,

            confirmButtonText: "확인",
          }).then((res) => {
            if (res.isConfirmed) {
              localStorage.removeItem("meta_info");
              document.location.href = "/login";
            }
          });
        } else {
          if (user !== null) {
          }
        }
      });
    };
    if (localStorage.getItem("meta_info")) {
      getToken();
    } else {
    }
  }, []);

  return (
    <>
      {/* <div className="main_admin_user">
        {" "}
        <Link to="/admin/users">관리자 페이지로 이동</Link>
      </div> */}
      {modalVisible && (
        <Modal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          data={data}
          onClose={closeModal}
        ></Modal>
      )}
      <MainBanner />
      <MainSection1 />
      {/* <MainSection2 /> */}
    </>
  );
}

export default Main;
