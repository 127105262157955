import styles from "../../../styles/admin.module.css";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import pro from "../../../assets/images/profile_info.png";
import Moment from "react-moment";
import CountUp from "react-countup";
import { numberToKorean } from "../../../lib/numberToKorean";
import { wonComma } from "../../../lib/wonComma";
import { FaArrowUp } from "react-icons/fa";
const AdminDepositOneDetail = ({ data }) => {
  const wonReplace = () => {
    return numberToKorean(data.pro_target_amount);
  };
  const wonReplace1 = () => {
    let won = String(data.pro_target_amount);
    // if (won.length === 6) {
    //   return wonComma(data.pro_target_amount);
    // }else if(won.length === 7)

    // return 0;
    if (data.amount) {
      return numberToKorean(data.amount);
    } else {
      return "0";
    }
  };

  const percent = () => {
    let result = data.pro_target_amount;
    let ing = data.amount;

    return (ing / result) * 100;
    // return 159;
  };

  return (
    <>
      <div className={styles.invest_wrap}>
        <div className={styles.invest_deposit_section}>
          <div className={styles.invest_deposit_box}>
            <div className={styles.invest_product_item}>
              <h2>투자 상품 현황</h2>
              <div className={styles.invest_product_box}>
                {data && (
                  <>
                    {/* <div className={styles.invest_title}>
                      {data.pro_content}
                    </div> */}
                    <div className={styles.invest_in}>
                      <div className={styles.invest_top}>
                        <div className={styles.invest_text}>
                          {data.category_name}
                          <em>{data.pro_title}</em>
                        </div>
                      </div>
                      <div className={styles.invest_make_visual}>
                        <ul>
                          <li>
                            <span>수익률</span>
                            <div className={styles.num}>
                              <FaArrowUp /> <em>{data.pro_rate}%</em>
                            </div>
                          </li>
                          <li>
                            <span>가입기간</span>
                            <em className={styles.num}>{data.pro_join}개월</em>
                          </li>
                          <li>
                            <span>가입금액</span>
                            <em className={styles.num}>100 ~ 3500만원</em>
                          </li>
                        </ul>

                        <div className={styles.line_wrap}>
                          <progress
                            className={styles.progress}
                            value={percent()}
                            min="0"
                            max="100"
                          ></progress>
                        </div>
                        <div className={styles.line_text_box}>
                          <span className={`${styles.per} ${styles.n1}`}>
                            모집률{" "}
                            <CountUp
                              start={0}
                              end={percent()}
                              decimals={1}
                              decimal="."
                              duration={2}
                            />
                            %
                          </span>
                          <span className={`${styles.per} ${styles.n2}`}>
                            {wonReplace1()} / {wonReplace()}원
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDepositOneDetail;
