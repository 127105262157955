import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import close from "./../assets/images/close.png";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import AdminUserDetail from "../components/admin/detail/AdminUserDetail";
import StoryList from "../components/admin/StoryList";
import FnqList from "../components/admin/FnqList";

const AdminFnqPage = ({ roomid, onClose }) => {
  return (
    <>
      <FnqList />
    </>
  );
};

export default AdminFnqPage;
