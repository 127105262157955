import style from "../styles/wadiz.module.css";
import { useEffect } from "react";

const Wadiz = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  const scrollEvt = (data) => {
    const targetNo1 = document.getElementById("target_no1");
    const targetNo2 = document.getElementById("target_no2");
    const targetNo3 = document.getElementById("target_no3");
    const targetNo4 = document.getElementById("target_no4");

    if (data == "no1" && targetNo1) {
      targetNo1.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no2" && targetNo2) {
      targetNo2.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no3" && targetNo3) {
      targetNo3.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no4" && targetNo4) {
      targetNo4.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className={`common-wrap`} style={{ borderTop: "1px solid #b6b6b6b5" }}>
      {" "}
      <div className="common-inner-wrap">
        {" "}
        <div className={style.policy_wrap}>
          <h1>서포터 이용약관</h1>
          <p>아시아로(주)의 서포터 이용약관을 규정합니다.</p>
          <div className={style.policy_update_div}>
            <h5>업데이트 노트</h5>
            <ul>
              <li>본 이용약관은 2023년 7월 26일부터 시행됩니다.</li>
              <li>개정 2023.07.26</li>
            </ul>
          </div>
          <ol className={style.policy_subtitle}>
            <li onClick={() => scrollEvt("no1")}>제1장 공통</li>
            <li onClick={() => scrollEvt("no2")}>제2장 펀딩 서비스</li>
            <li onClick={() => scrollEvt("no3")}>제3장 프리오더 서비스</li>
            <li onClick={() => scrollEvt("no4")}>제4장 스토어 서비스</li>
          </ol>
          <hr />

          <h2 id="target_no1">제1장 공통</h2>
          <h3>제1조 (목적)</h3>
          <p>
            본 약관은 아시아로 주식회사(이하 "회사")와 회사가 제공하는
            크라우드펀딩, 통신판매 중개 서비스 및 통신판매 서비스를 이용하는
            회원 간의 서비스 이용에 관한 권리·의무 관련 사항을 정함을 그
            목적으로 합니다.
          </p>
          <h3>제2조 (정의)</h3>
          <p>
            본 약관에서 사용하는 용어의 정의는 다음 각 호와 같습니다. 본 조에서
            정의되지 않은 용어는 개별 장의 정의를 따르며, 개별 장에서 정의되지
            않는 용어는 일반 거래 관행에 따라 정의된 의미를 가집니다.
          </p>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              “서비스”란 회원가입약관에 따른 회원이 전자적 시스템에 접속하여
              사이트에서 온라인상의 거래 등을 할 수 있도록 본 약관에 따라 회사가
              제공하는 크라우드펀딩, 통신판매중개 서비스 및 통신판매 서비스를
              말합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              "사이트"란 회사가 재화 또는 용역 등(일정한 시설을 이용하거나
              용역을 제공받을 수 있는 권리를 포함)을 회원에게 제공하기 위하여
              컴퓨터 등 정보통신설비를 이용하여 상품 등을 거래 등을 할 수 있도록
              설정한 가상의 영업장(https://www.meta12.net 등 회사가 운영하는
              웹페이지 및 모바일 웹, 앱 등을 모두 포함)을 의미합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              “회원”이란 회원가입약관에 따라 회사와 서비스이용계약을 체결하고
              홈페이지를 통하여 서비스를 이용할 수 있는 자격을 부여받은 자를
              말합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              “메이커”란 회사의 회원가입약관에 따른 회원 중에서 회사와 별도로
              체결하는 메이커 이용약관에 따라 사이트에 프로젝트를 개설하고
              서포터들 대상으로 펀딩을 받거나 리워드 또는 상품을 판매하려는 자를
              말합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              “서포터”란 회사의 회원가입약관에 따른 회원 중에서 회사에서
              제공하는 서비스를 통해 메이커가 개설한 프로젝트에 펀딩을 하거나
              리워드 또는 상품을 구매하려는 자를 말합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              “펀딩”이란 메이커가 META12 사이트를 통해 재화 또는 용역 개발,
              생산, 기부 등 특정한 자금조달의 목적을 밝히고 그 목적달성을 위해
              필요한 자금을 제공한 서포터들에게 약속한 재화 또는 용역을 제공하는
              서비스를 말합니다. 단, 기부를 목적으로 자금을 조달 하는 경우에는
              서포터들에게 재화 또는 용역 등의 제공을 약속하지 않을 수 있습니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              “프리오더”란 메이커가 META12 사이트를 통해 전자상거래 등에서의
              소비자보호에 관한 법률(이하 “전자상거래법”)이 적용되는 예약판매의
              형식으로 서포터들에게 약속한 재화 또는 용역 등을 제공하는 서비스를
              말합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              “스토어”란 메이커가 성공 종료한 펀딩 또는 프리오더 프로젝트의
              리워드를 전자상거래법이 적용되는 상시판매의 형식으로 서포터들에게
              상품을 판매하는 서비스를 말합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              “성공”이란 펀딩 또는 프리오더 프로젝트 진행 기간의 종료시점을
              기준으로 서포터의 펀딩 또는 예약구매 신청에 따라 모집된 결제예약금
              및 결제금의 합계액이 메이커가 설정한 목표 금액 이상인 경우를
              의미합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              “실패”란 펀딩 또는 프리오더 프로젝트 진행 기간의 종료시점을
              기준으로 서포터의 펀딩 또는 예약구매 신청에 따라 모집된 결제예약금
              및 결제금의 합계액이 메이커가 설정한 목표 금액 미만인 경우를
              의미합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              “리워드”란 메이커가 펀딩 또는 프리오더 프로젝트를 통해
              서포터로부터 자금을 모집 하거나 예약판매 하면서 서포터에게
              제공하기로 약속한 유형의 제품 또는 무형의 서비스를 의미합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              “상품”이란 메이커가 회사의 서비스를 통하여 고객에게 제공 및
              판매하는 각종 재화 및 용역 등을 총칭하여 말하며, 회사에서 정한
              일정 자격 요건을 충족하여야 합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              “프로젝트”란 메이커가 서포터에게 펀딩을 받거나 리워드 또는 상품을
              판매하기 위해 서포터에게 약속한 내용을 의미합니다.
            </li>
          </ol>

          <h3>제3조 (약관의 효력)</h3>
          <ol>
            <li>
              회사는 본 약관에 규정되지 않은 세부적인 내용에 대해 회사가 수시로
              개정할 수 있는 개별 정책 등(이하 "정책"이라 함)을 제정하여 운영할
              수 있으며, 해당 내용을 사이트를 통하여 게시합니다. 정책은 본
              약관과 더불어 서비스 이용계약(이하 "이용계약"이라 함)의 일부를
              구성합니다. 회원이 본 약관 및 정책에 동의하는 것은 양 당사자의
              인감을 날인하여 이용계약을 체결하는 것과 동일한 효력을 갖습니다.
            </li>
            <li>
              회사는 서비스 중 특정 서비스에 관한 약관(이하 "개별약관"이라 함)을
              별도로 제정할 수 있으며, 회원이 개별약관에 동의한 경우 개별약관은
              이용계약의 일부를 구성하고 개별약관에 본 약관과 상충하는 내용이
              있을 경우 개별 약관이 우선적으로 적용됩니다.
            </li>
          </ol>

          <h3>제4조 (약관의 게시 및 개정)</h3>
          <ol>
            <li>
              회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 사이트를 통하여
              게시합니다.
            </li>
            <li>
              회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관
              및 정책을 개정할 수 있으며, 이 경우 개정내용과 적용일자를 명시하여
              사이트를 통해 그 적용일자 7일 전부터 적용일자 전일까지 공지합니다.
              다만, 변경 내용이 회원에게 불리한 변경의 경우에는 개정약관 및
              정책의 적용일자 30일 전부터 적용일자까지 공지합니다.
            </li>
            <li>
              관계법령의 제·개정에 따른 제도변경 등으로 약관 또는 운영정책이
              변경되는 경우 등 본문에 따라 안내하기가 어려운 급박하고 부득이한
              사정이 있는 경우에는 변경내용을 제2항과 같은 방법으로 개정 약관
              또는 운영정책의 시행일 전에 게시합니다.
            </li>
            <li>
              회원이 개정약관 및 정책에 동의하지 않는 경우에는 개정약관 및
              정책의 적용일 이전에 거부 의사를 표시하고 본 약관 및 정책에 의한
              이용계약을 해지할 수 있습니다.
            </li>
            <li>
              회사가 본 조 제2항에 따라 개정약관 및 정책을 공지 또는 통지하면서
              회원에게 적용일 전까지 의사표시를 하지 않으면 의사표시가 표명된
              것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이
              명시적으로 거부의사를 표명하지 아니한 경우 개정약관 및 정책에
              동의한 것으로 봅니다.
            </li>
            <li>
              회사는 본 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소,
              전화번호, 전자우편주소, 사업자등록번호 등을 회원이 쉽게 확인할 수
              있도록 사이트의 초기 서비스 화면에 게시하며 회원이 약관을 확인하고
              다운로드(화면출력 포함)받을 수 있도록 합니다. 다만, 약관의 구체적
              내용은 회원이 사이트 내 링크로 연결된 화면을 통하여 볼 수 있도록
              할 수 있습니다.
            </li>
          </ol>

          <h3>제5조 (대리행위 및 보증의 부인)</h3>
          <ol>
            <li>
              회사는 서포터와 메이커간의 리워드의 크라우드펀딩 또는 통신판매중개
              시스템의 운영 및 관리 책임만을 부담하며, 이와 관련하여 회사의
              어떠한 행위도 메이커 또는 서포터를 대리하는 행위로 간주되지
              않습니다.
            </li>
            <li>
              회사는 서비스를 통하여 이루어지는 중개 서비스와 관련하여 청약
              의사의 존부 및 진정성, 리워드의 품질, 완전성, 안전성, 적법성 및
              타인의 권리에 대한 비침해성, 메이커가 입력하는 정보 및 그 정보를
              통하여 링크된 URL에 게재된 자료의 진실성 등 일체에 대하여 보증하지
              아니하며, 이와 관련한 일체의 위험과 책임은 관련 당사자가 부담해야
              합니다.
            </li>
          </ol>

          <h3>제6조 (서비스의 제공 및 중단)</h3>
          <ol>
            <li>
              회사가 본 약관에 따라 제공하는 서비스의 종류는 다음 각 호와
              같습니다.
              <ol>
                <li>펀딩 서비스 및 이에 수반되는 기타 서비스</li>
                <li>통신판매 서비스 및 이에 수반되는 기타 서비스</li>
                <li>통신판매중개 서비스 및 이에 수반되는 기타 서비스</li>
                <li>
                  위 각 호의 서비스 제공을 위해 필요하거나 이에 부수하는 서비스
                </li>
              </ol>
            </li>
            <li>
              서비스의 이용은 1일 24시간, 연중무휴로 가능한 것을 원칙으로
              합니다. 다만, 업무 또는 기술상 장애 또는 기술적 보완 및 사이트의
              개선을 이유로 하는 경우 등에는 서비스가 일시중지 되거나 이체서비스
              이용시간의 제한 등의 사유로 인하여 서비스 이용시간이 변경되는 경우
              이와 같은 사유를 사이트를 통하여 공지합니다.
            </li>
            <li>
              제2항에도 불구하고 회사는 서비스를 일정 범위로 분할하여 각 범위
              별로 이용가능시간을 별도로 지정할 수 있습니다. 이 경우 그 내용을
              사이트를 통하여 공지합니다.
            </li>
            <li>
              회사는 본 약관에 따라 펀딩 또는 구매를 하는 서포터에게 별도의
              수수료를 징수하지 않습니다.
            </li>
            <li>
              사업 종목의 전환, 사업의 종료 또는 포기, 회사의 폐업, 합병, 분할,
              영업양도 등의 이유로 서비스를 제공할 수 없게 되는 경우에는
              관계법령의 규정을 따르며, 관계법령에 특별한 규정이 없는 한
              회원에게 별도의 보상을 하지 않습니다.
            </li>
          </ol>

          <h3>제7조 (서비스의 이용)</h3>
          <ol>
            <li>
              회원은 본 약관 및 회사가 서비스 화면에서 고지하는 내용을
              준수하여야 하고, 본 약관 및 고지 내용을 위반하거나 이행하지 않아
              발생하는 모든 손해에 대하여 책임을 집니다.
            </li>
            <li>
              회원은 회사 또는 메이커와 펀딩 또는 매매 등의 청약 절차에서 분쟁이
              발생하면 분쟁의 해결을 위하여 성실히 임해야 하며, 분쟁해결의
              불성실로 회사 및 제3자에 손해가 발생하면 그에 대한 모든 책임을
              부담합니다.
            </li>
            <li>
              회원은 결제와 관련하여 서포터가 입력한 정보 및 그 정보와 관련하여
              발생하는 제반 문제에 대한 모든 책임을 부담합니다. 단, 회사의 고의
              또는 과실이 있는 경우에는 그러하지 아니합니다.
            </li>
            <li>
              회사는 회원의 결제 시 해당 결제수단에 대하여 정당한 사용권한이
              있는지 확인할 수 있고, 이에 대한 확인이 완료될 때까지 해당 거래의
              진행을 중지할 수 있습니다.
            </li>
            <li>
              회사는 회원에게 서비스가 안전하게 제공될 수 있도록 각종 설비와
              자료를 관리하고, 서비스가 제공 목적에 맞게 이용되고 있는지 여부를
              확인합니다. 만약 회원이 이용 목적을 위반한 것으로 확인되면 회사는
              그에 대한 소명을 회원에게 요청할 수 있고, 펀딩 또는
              구매(예약구매를 포함) 취소 등 필요한 조치를 할 수 있습니다.
            </li>
            <li>
              미성년자가 사이트를 통하여 상품 등을 펀딩 또는 구매하는 경우에
              법정대리인이 해당 계약에 대하여 동의하지 아니하면, 미성년자 본인
              또는 법정대리인은 그 계약을 취소할 수 있습니다.
            </li>
          </ol>

          <h3>제8조 (회사의 의무)</h3>
          <ol>
            <li>
              회사는 본 약관이 금지하거나 관련 법령 기타 공공의 질서와 선량한
              풍속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라
              지속적이고, 안정적으로 서비스를 제공하는 데 최선을 다합니다.
            </li>
            <li>
              회사는 서비스 제공과 관련하여 알고 있는 회원 정보를 본인의 동의
              없이 제3자에게 제공하지 않습니다.
            </li>
            <li>
              회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의
              개인정보(신용정보 포함)보호를 위한 보안시스템을 갖추어야 하며,
              개인정보처리방침을 공시하고 준수합니다.
            </li>
            <li>
              회사는 회원이 동의하지 않는 영리목적의 광고성 전자우편, 앱 푸쉬
              등을 발송하지 않습니다.
            </li>
            <li>
              회사는 회원으로부터 제기되는 의견이나 불만이 객관적으로 정당하다고
              인정될 경우에는 회원의 불만을 해결하기 위해 노력하여야 하며,
              신속한 처리가 곤란한 경우 그 사유와 처리 일정을 서비스 화면에
              게재하거나 전자우편 등을 통하여 회원에게 통지합니다.
            </li>
          </ol>

          <h3>제9조 (회원의 의무)</h3>
          <ol>
            <li>
              회원은 회사의 약관, 규정, 회사의 이용정책, 이용안내 사항 및 관계
              법령 등을 준수하여야 하며, 기타 회사 업무에 방해되는 행위를
              하여서는 안 됩니다.
            </li>
            <li>
              회원은 다음 각 호의 행위를 하여서는 안 됩니다.
              <ol>
                <li>
                  회사가 제공하는 서비스를 이용하지 아니하고 메이커와 직접
                  거래하는 행위(이하 “직거래”) 또는 이를 유도하는 행위
                </li>
                <li>
                  회사 또는 메이커의 활동을 방해하거나 사이트를 이용하여 부당한
                  이득을 취하는 등 통상적인 거래 관행에 부합하지 않는 거래상의
                  부정 행위
                </li>
                <li>
                  리워드의 펀딩을 가장한 현금 융통 등 “여신전문금융업법”,
                  “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 및 기타 관계
                  법령에 위배되거나 비정상적인 결제 행위
                </li>
                <li>
                  회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인
                  방법으로 서비스를 이용하거나 회사의 정보처리시스템에 접근하는
                  행위
                </li>
                <li>
                  회사가 제공하는 서비스와 관련 없거나 비합리적인 요청, 공공의
                  질서와 선량한 풍속에 반하는 행위 등으로 회사의 업무를 방해하는
                  행위
                </li>
                <li>
                  기타 위 각 호에 준하여 회사 또는 제3자의 권리를 침해 또는
                  침해할 우려가 있거나, 상거래 질서를 위협하는 행위
                </li>
              </ol>
            </li>
            <li>
              회사는 제2항을 위반한 회원에 대하여 이용계약 해제, 쿠폰의 환수,
              회원 자격의 제한, 카드 결제 등 결제 취소 또는 취소 보류 등의 조치
              및 민∙형사 상의 법적 조치 등을 취할 수 있으며, 회사와 계약한 PG사
              등에 의하여 동일 또는 유사한 조치가 이루어 질 수 있습니다.
            </li>
          </ol>

          <h3>제10조 (할인쿠폰)</h3>
          <ol>
            <li>
              할인쿠폰은 발행대상, 발행경로, 사용대상 등에 따라 구분될 수
              있으며, 할인쿠폰의 세부구분, 할인금액(할인율), 사용방법, 사용기한
              및 제한에 대한 사항은 할인쿠폰 또는 서비스 화면에 표시됩니다.
              할인쿠폰의 종류 및 내용과 발급 여부는 회사의 서비스 정책에 따라
              변경될 수 있습니다.
            </li>
            <li>
              할인쿠폰은 현금으로 출금될 수 없으며, 할인쿠폰에 표시된 유효기간이
              만료되거나 회원자격이 상실되면 소멸합니다.
            </li>
            <li>
              할인쿠폰은 회사에서 별도로 명시한 경우를 제외하고는 타인에게
              양도할 수 없으며, 부정한 목적이나 용도로 사용할 수 없습니다. 이를
              위반한 경우 회사는 할인쿠폰을 소멸시키거나 회원자격을 제한 또는
              상실시킬 수 있습니다.
            </li>
            <li>
              회원이 부정한 방법으로 할인쿠폰을 획득한 사실이 확인될 경우,
              회사는 이를 회수하고, 회원자격 상실 및 민∙형사 상의 법적 조치 등을
              취할 수 있습니다.
            </li>
          </ol>

          <h3>제11조 (개인정보의 제공)</h3>
          <ol>
            <li>
              회사는 메이커가 계약의 이행을 위해 필요한 행위를 하기 위하여
              필요한 개인 정보를 회사의 개인정보처리방침에 따라 서포터로부터
              확인 및 동의를 받은 후 메이커에게 제공합니다.
            </li>
            <li>
              메이커는 개인정보를 제공 받은 목적 외의 목적을 밝히고 별도로
              동의를 받지 않는 이상 제공받은 서포터의 정보를 제1항의 동의를 받은
              목적 외의 용도로 제공받은 정보를 수집 이용하여서는 안 되며, 이를
              위반하여 발생한 개인정보 노출 사고 등에 대한 모든 책임은 회사의
              고의 또는 과실이 없는 이상 메이커가 단독으로 부담하여야 합니다.
            </li>
          </ol>

          <h3>제12조 (권리의 귀속 등)</h3>
          <p>
            회사가 제공하는 서비스(데이터, 소프트웨어, 게시물의 저작권 등)에
            관한 권리는 회사에게 귀속됩니다.
          </p>

          <h3>제13조 (회사의 면책)</h3>
          <ol>
            <li>
              회사는 사이트를 통하여 플랫폼을 제공하는 방식으로 온라인상의
              계약을 중개하는 중개자이며 거래의 당사자가 아닙니다. 따라서 회사는
              아래 각 호의 사항을 포함하여 본 약정에 따른 서포터와 메이커 간
              계약과 관련하여 발생하는 분쟁에 대한 책임을 지지 않습니다. 단,
              중개 과정에서 회사의 고의 또는 중대한 과실이 인정되거나, 제4장
              제2절의 통신판매 서비스 등 회사가 단순 중개가 아닌 회원에게 직접
              서비스를 제공하는 경우는 그러하지 아니합니다.
              <ol>
                <li>
                  메이커가 프로젝트 페이지에 게재한 정보, 자료, 사실 등의
                  진실성, 정확성, 신뢰성 및 게시된 자료의 활용으로 인하여
                  야기되는 일체의 결과
                </li>
                <li>회원 간 및 회원과 제3자간에 서비스를 매개로 발생한 분쟁</li>
                <li>
                  메이커가 변경금지사항을 임의로 변경하거나, 변경한 내용을
                  공지하지 아니한 경우에 해당 프로젝트의 서포터와의 관계에서
                  발생할 수 있는 법적 분쟁과 책임 및 불이익
                </li>
                <li>
                  메이커가 본 약관에 따라 전달받은 금원을 약속한 용도로 사용하지
                  않아 발생하는 불이익 및 법적 책임
                </li>
                <li>
                  메이커가 고의 또는 과실로 약속한 내용의 일부 또는 전부를
                  이행하지 않거나, 다르게 이행하는 경우 발생할 수 있는 모든 결과
                </li>
              </ol>
            </li>
            <li>
              회사는 회사의 고의 또는 과실이 없는 한 아래 각 호의 사항으로부터
              발생하는 손해에 대하여 책임을 지지 아니합니다.
              <ol>
                <li>
                  회사가 본 약관 제6조 제2항 등 본 약관 또는 그의 위임을 받은
                  하위 정책에서 정하는 방식으로 서비스 제공을 제한 또는 중지한
                  경우
                </li>
                <li>
                  회원이 전자우편 등 개인정보를 부실하게 기재하거나, 본 약관에
                  따라 입력하여야 하는 정보의 최신화의무를 다하지 아니하여
                  발생한 손해
                </li>
                <li>
                  서비스에 대한 접속 및 이용과 관련하여 회원의 고의 또는 과실로
                  인하여 회원서포터 또는 제3자에게 발생한 손해
                </li>
                <li>
                  천재지변, 전시·사변 또는 이에 준하는 불가항력이라고 인정되는
                  사유로 인하여 회원에게 발생하는 손해
                </li>
              </ol>
            </li>
          </ol>

          <h3>제14조 (분쟁조정)</h3>
          <ol>
            <li>
              회사는 서포터와 메이커 간 분쟁이 발생하는 경우에 이를 합리적이고
              원활히 조정하기 위한 고객센터를 운영합니다.
            </li>
            <li>
              회원은 분쟁조정센터의 조정에 신의칙에 입각하여 성실히 응하여야
              합니다.
            </li>
          </ol>

          <h3>제15조 (준거법 등)</h3>
          <ol>
            <li>이용계약의 준거법은 대한민국 법률로 합니다.</li>
            <li>
              이용계약과 관련하여 회사와 서포터 간에 발생하는 모든 분쟁/소송은
              민사소송법 상의 관할법원에서 해결합니다.
            </li>
          </ol>

          <h2 id="target_no2">제2장 펀딩 서비스</h2>
          <h3>제16조 (펀딩 용어의 정의)</h3>
          <p>
            본 장에서 사용하는 용어의 정의는 다음 각 호와 같습니다. 본 장에서
            정의되지 않은 용어는 본 약관의 다른 장 및 일반 거래 관행에 따라
            정의된 의미를 가집니다.
          </p>
          <ol>
            <li>
              “후원 펀딩”이란 개인 메이커가 자아 실현 또는 사회적 가치 창출 등을
              위해 불특정 다수로부터 후원을 받을 목적으로 개설할 수 있는 펀딩
              프로젝트를 말합니다.
            </li>
            <li>
              “기부 펀딩”이란 기부금품의 모집 및 사용에 관한 법률(이하
              “기부금품법”)에서 요구하는 기부금품의 모집등록을 완료하고,
              기획재정부로부터 지정된 세제적격단체(공익법인 및 공익단체)인
              메이커가 불특정 다수로부터 기부를 받을 목적으로 개설할 수 있는
              펀딩 프로젝트를 말합니다.
            </li>
            <li>
              “펀딩기간”이란 메이커가 게시한 프로젝트를 통해 서포터로부터 자금을
              모을 수 있는 기간으로서 프로젝트를 오픈하여 펀딩금 모집이 가능한
              시점부터 프로젝트 종료일까지의 기간을 의미합니다.
            </li>
            <li>
              “펀딩의 신청”이란 서포터가 프로젝트의 펀딩기간 내에 회사가 정한
              방법에 따라 펀딩의 의사표시를 하는 것을 말합니다.
            </li>
            <li>
              “펀딩의 취소”란 서포터가 프로젝트의 펀딩기간 내에 펀딩의
              의사표시를 철회하는 것을 말합니다.
            </li>
            <li>
              “펀딩 달성률”이란 서포터가 목표한 금액을 기준으로 펀딩의 신청
              접수가 완료된 결제예약금 및 결제금이 차지하는 비율을 말합니다.
            </li>
            <li>
              “결제예약금”이란 서포터가 펀딩의 신청에 따라 결제를 예약한 금액을
              의미합니다.
            </li>
            <li>
              “결제 취소”란 서포터가 프로젝트 종료 후 리워드의 발송 또는 제공
              전까지 회사가 정한 특별한 사유가 있는 경우에 한하여 카드결제 등의
              결제 취소를 요청함으로써 펀딩의 의사표시를 철회하는 것을 말합니다.
            </li>
            <li>
              “펀딩금”이란 서포터의 펀딩의 신청에 따라 프로젝트 종료 시점에
              모집된 결제금 및 결제예약금 중 결제 기간(프로젝트 종료일의
              익일로부터 4영업일) 종료 시 결제가 되지 않거나 취소된 금액을
              제외하고 최종 결제가 완료된 금원을 말합니다.
            </li>
          </ol>

          <h3>제17조 (펀딩의 신청 전 유의사항)</h3>
          <ol>
            <li>
              서포터는 본 약관에 따른 펀딩을 신청하기 전에 사이트를 통해
              제공되는 정보 뿐만 아니라 프로젝트 펀딩기간, 리워드 제공의 조건 등
              프로젝트의 내용을 충분히 숙지한 후 펀딩을 신청하여야 합니다.
            </li>
            <li>
              회사는 크라우드펀딩 중개서비스와 관련하여 펀딩 중개자로서 서포터와
              메이커 간의 자유로운 펀딩을 위한 시스템을 운영 및 관리, 제공할
              뿐이므로 서포터는 리워드를 펀딩하기 전에 반드시 메이커가 프로젝트
              상세 페이지 내에 작성한 리워드의 상세 내용과 펀딩 조건을 정확하게
              확인해야 합니다. 펀딩하려는 리워드의 내용과 조건을 확인하지 않고
              구매하여 발생한 모든 손해는 서포터 본인이 부담합니다.
            </li>
            <li>
              서포터는 회사가 서포터의 서비스 이용 편의를 위하여 메이커
              등으로부터 제공 받은 리워드 관련 정보 기타 콘텐츠를 사이트를
              통하여 제공하는 경우에도 리워드의 펀딩 여부는 전적인 서포터 본인의
              판단과 책임으로 결정하여야 합니다. 이 경우 회사는 고의 또는
              중과실이 있는 경우를 제외하고 서포터의 펀딩 결정에 대하여 책임을
              부담하지 아니 합니다.
            </li>
            <li>
              메이커가 프로젝트 개시 전 펀딩 신청의 시기 등 유형별로 리워드
              제공의 조건과 기준, 방법 등을 다르게 설정할 수 있도록 회사에
              요청할 수 있으며, 회사는 그 조건과 기준이 합리적이고 명확한 기준에
              의한 것인 경우 그 내용을 사이트에 게시합니다. 이 경우 서포터가 위
              내용에 따라 리워드를 제공받을 때 제한이 있을 수 있습니다.
            </li>
          </ol>

          <h3>제18조 (펀딩 서포터들의 의견교환 등)</h3>
          <ol>
            <li>
              서포터는 사이트에 개설된 게시판을 통해 프로젝트에 대한 질의 또는
              답변을 게시하는 방법으로 메이커와 의견을 교환할 수 있습니다.
            </li>
            <li>
              회사는 사이트에 게시된 서포터들의 의견을 임의로 삭제하거나
              수정하지 않습니다. 다만, 다른 법률에 근거가 있거나 게시된 글이
              회원가입약관 및 기타 커뮤니티 정책에 따라 삭제될 수 있는 경우에는
              그러하지 아니합니다.
            </li>
          </ol>

          <h3>제19조 (펀딩의 신청 방법)</h3>
          <ol>
            <li>
              서포터는 펀딩하려는 프로젝트의 페이지에서 회사가 제공하는 양식에
              따라 펀딩내용(결제예약금 및 결제금, 리워드의 내용, 수량, 추가
              결제예약금 및 결제금 등)과 결제방법을 입력하여 결제를 예약하거나
              즉시 결제를 진행하면 회사에 대한 펀딩의 신청이 이루어지며, 이에
              대하여 회사에 접수되면 펀딩의 신청이 완료됩니다.
            </li>
            <li>
              회사는 메이커가 합리적이고 명확한 기준에 따라 서포터의 자격 또는
              결제예약금 및 결제금의 액수에 제한을 두기를 요청한 경우 그에 따라
              펀딩의 신청을 제한할 수 있습니다.
            </li>
            <li>
              서포터는 각 프로젝트별로 회사가 제공하는 결제수단(신용카드,
              체크카드를 통한 예약결제, 가상계좌 등)을 통하여 사이트 내에서 펀딩
              신청에 따른 결제예약 또는 결제를 할 수 있습니다.
            </li>
            <li>
              회사는 정확하고 안전한 결제 처리를 위해 관계법령에 따라 아래와
              같은 서포터의 정보를 결제 대행사에 위탁하게 됩니다.
            </li>
          </ol>

          {/* <div className={style.policy_table}>
            <table style={{ minWidth: "500px", width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>
                    결제와 관련한 정보를 제공 받는 자
                  </th>
                  <th style={{ width: "50%" }}>위탁되는 정보</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul>
                      <li>주식회사 케이지이니시스</li>
                      <li>나이스페이먼츠 주식회사</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>신용카드(체크카드)의 번호, 유효기간</li>
                      <li>신용카드(체크카드) 소유자의 성명</li>
                      <li>계좌번호, 은행, 예금주 성명</li>
                      <li>이메일, 휴대폰번호 등 결제 확인을 위한 연락처</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}

          <ol>
            <li>
              서포터가 1개의 프로젝트에 1회 펀딩을 신청할 때 최소 1,000원 이상을
              입력하여야 합니다. 서포터는 1개의 프로젝트에 중복하여 펀딩을
              신청할 수 있습니다.
            </li>
            <li>
              서포터는 펀딩 신청의 접수 결과는 전자우편 또는 문자메시지 등을
              통해 통보 받게 됩니다.
            </li>
          </ol>

          <h3>제20조 (펀딩의 취소)</h3>
          <ol>
            <li>
              펀딩의 취소는 본 약관 및 사이트의 이용안내에 규정된 사항에
              따릅니다.
            </li>
            <li>
              서포터는 펀딩기간의 종료 전(본 약관에서 정한 사유로 펀딩기간이
              연장된 경우에는 연장된 펀딩기간의 종료 전)까지만 펀딩을 취소할 수
              있습니다. 단, 메이커가 펀딩의 취소에 대해 본 약관에서 정한 것보다
              서포터에게 더 유리하도록 정한 것이 있는 경우에는 그에 따릅니다.
            </li>
            <li>
              서포터가 펀딩을 취소하는 경우, 서포터가 신청한 결제예약은 취소되며
              결제가 이루어진 경우에는 카드결제 취소 등의 방법으로 결제가
              취소됩니다.
            </li>
            <li>
              이미 펀딩을 신청한 서포터가 결제예약금 또는 결제금을 수정하고 싶은
              경우에는 추가로 펀딩을 신청하거나 이전 펀딩 결제예약 또는 결제를
              취소한 후 다시 펀딩을 신청하여야 합니다.
            </li>
            <li>
              회사는 다음 각 호의 사유가 발생한 경우 해당 사유가 발생한 날로부터
              지체없이 펀딩기간 종료 시까지 모집된 결제예약금 및 결제금을
              일괄적으로 취소합니다.
              <ol>
                <li>프로젝트가 펀딩기간 중에 중도 철회 또는 취소된 경우</li>
                <li>제2조에 따라 펀딩결과가 “실패”인 경우</li>
              </ol>
            </li>
            <li>
              본 조에 따른 펀딩의 취소의 경우 메이커는 위 취소된 결제예약금 및
              결제금에 대하여 어떠한 권리도 주장할 수 없습니다.
            </li>
          </ol>

          <h3>제21조 (펀딩 달성률의 게시)</h3>
          <ol>
            <li>
              펀딩의 신청이 접수되면 사이트에 프로젝트의 펀딩 달성률이
              게시됩니다. 다만, 본 약관에 따라 펀딩의 취소 또는 결제 취소가
              있거나 펀딩의 신청 효력이 상실되는 경우에는 펀딩 달성률은 변동될
              수 있습니다.
            </li>
            <li>
              제1항에도 불구하고 제23조에 따라 전달되는 펀딩금의 액수에는
              결제기간 중 결제 실패 또는 결제 취소 금액이 추가로 반영될 수 있어
              펀딩 기간 중의 결제예약금 및 결제금과 차이가 있을 수 있습니다.
            </li>
          </ol>

          <h3>제22조 (펀딩 결과의 게시 및 통보 등)</h3>
          <p>
            펀딩기간이 종료되면 회사는 펀딩결과를 제2조에 따라 "성공" 또는
            "실패"로 구분하여 사이트에 게시합니다.
          </p>

          <h3>제23조 (펀딩금의 전달)</h3>
          <ol>
            <li>
              제2조에 따라 펀딩결과가 “성공”인 경우 펀딩금은 메이커 이용약관에서
              정한 바에 따라 메이커에게 전달됩니다. 다만, 프로젝트의 특성에 따라
              메이커 이용 약관에서 달리 약정한 경우에는 조건부 또는 분할하여
              지급될 수 있습니다.
            </li>
            <li>
              회사는 특별한 사정이 없는 한 메이커 이용약관에서 정한 바에 따라
              메이커에 대한 확인절차가 완료된 경우에 한하여 위 완료된 날로부터
              15영업일 이내에 메이커에게 수수료 등을 공제한 펀딩금의 전액(일시
              지급하기로 한 경우) 또는 일부(분할 지급하기로 한 경우)를
              전달합니다.
            </li>
            <li>
              제1항, 제2항에도 불구하고 메이커가 프로젝트를 이행할 수 없거나
              서포터에게 리워드를 제공할 수 없음이 분명한 경우 또는 메이커
              서비스 이용약관상 펀딩금을 정산할 수 없다고 규정된 경우에는 회사는
              펀딩금의 지급을 유보하거나 전달하지 아니할 수 있습니다.
            </li>
            <li>
              펀딩금 지급 도중 제3항과 같은 사유가 발생한 경우에는 회사는
              지급하지 않은 잔여 펀딩금의 지급을 유보할 수 있고, 필요한 경우
              이미 지급받은 펀딩금에 대하여도 메이커가 서포터들에게 환불하도록
              요청할 수 있습니다.
            </li>
          </ol>

          <h3>제24조 (결제 취소)</h3>
          <ol>
            <li>
              서포터는 프로젝트 종료 후 리워드의 발송 또는 제공 전에 다음 각
              호에서 정한 사유에 근거하여 회사에 결제 취소를 요청할 수 있습니다.
              단, 후원 펀딩의 경우 아래 c호의 적용이 배제됩니다.
              <ol>
                <li>
                  동일한 리워드를 중복적으로 결제한 경우 및 동일 프로젝트의 여러
                  리워드 구성을 중복적으로 결제한 경우
                </li>
                <li>
                  펀딩에 참여한 리워드가 타인의 지식재산권을 침해한 것으로
                  의심되는 합리적인 사유가 있는 경우
                </li>
                <li>
                  펀딩에 참여한 리워드가 발송되기 전 국내외 타 크라우드펀딩
                  플랫폼 또는 타 유통채널을 통해 펀딩 또는 판매된 것으로
                  의심되는 합리적인 사유가 있는 경우
                </li>
                <li>
                  펀딩에 참여한 프로젝트 스토리 내에 허위 사실이 포함된 것으로
                  의심되는 합리적인 사유가 있는 경우
                </li>
                <li>
                  리워드의 배송이 지연될 것이 예정되어 서포터가 적시에 리워드를
                  수령할 수 없게 되는 등, 기타 서포터 보호를 위해 그 필요성이
                  현저히 인정되는 경우
                </li>
              </ol>
            </li>
            <li>
              회사는 서포터의 결제 취소 요청이 제1항의 결제 취소 사유에
              해당한다고 판단되는 경우에는 결제 취소를 위한 조치를 취합니다.
            </li>
            <li>
              결제 취소 처리에 따른 환불은 취소 절차가 완료된 즉시 결제 시
              사용한 카드 또는 결제 수단을 통해 이뤄집니다.
            </li>
            <li>
              결제 시 할인쿠폰을 사용한 경우에는, 해당 할인쿠폰의 사용이
              취소됩니다. 단, 유효기간이 지난 경우 환불 즉시 쿠폰은 소멸됩니다.
            </li>
          </ol>

          <h3>제25조 (펀딩금의 환불)</h3>
          <ol>
            <li>
              서포터는 다음 각 호에서 정한 사유로 펀딩금의 일부 또는 전부에
              대하여 회사가 환불 정책에서 정한 방식과 절차에 따라 환불 신청을 할
              수 있습니다. 회사는 메이커가 서포터가 펀딩금 환불 신청을 한
              날로부터 14일 이내에 펀딩금 환불 신청을 승인한 경우 카드결제 등
              결제를 취소하여 서포터에게 펀딩금을 환불합니다.
              <ol>
                <li>리워드의 유통 및 제작에 법적으로 문제가 있는 경우</li>
                <li>
                  리워드가 프로젝트 페이지에 표시∙광고된 내용과 현저하게 다른
                  경우
                </li>
                <li>
                  메이커가 수거 접수 지연 등 고지한 교환∙AS 등의 절차를 정당한
                  사유 없이 지연하는 경우
                </li>
                <li>
                  회사의 환불 정책상 펀딩금 개별 반환 사유에 해당하는 경우
                </li>
              </ol>
            </li>
            <li>
              회사는 다음 각 호에서 정한 사유가 발생한 경우 직접 펀딩금 환불
              신청을 승인하고 카드결제 취소 등의 방법을 통하여 서포터에게
              펀딩금을 환불할 수 있습니다. 단, 제a호 및 제b호의 사유 발생 시
              환불신청을 한 서포터에 한정하여 해당 서포터에게 펀딩금을 환불하고,
              제c호는 사유발생 시 서포터들 전체에게 일괄하여 각 펀딩금을
              환불합니다.
              <ol>
                <li>
                  본 조 제1항에 따른 펀딩금 환불 사유가 인정됨에도 불구하고
                  메이커가 펀딩금 환불을 거부하는 경우
                </li>
                <li>
                  메이커가 본 조 제1항에 따른 서포터의 펀딩금 환불 신청이 접수된
                  날로부터 14일 이내에 펀딩금 환불을 승인하지 않는다는 의사를
                  회사에게 표시하지 않은 경우
                </li>
                <li>
                  회사의 환불 정책상 펀딩금 일괄 반환 사유에 해당하는 경우
                </li>
              </ol>
            </li>
          </ol>

          <h3>제26조 (후원 펀딩에 관한 특례)</h3>
          <ol>
            <li>
              후원 펀딩 프로젝트에서는 기성품 또는 타 유통채널에서 판매되는 제품
              및 서비스가 리워드로 제공될 수 있습니다.
            </li>
            <li>
              후원 펀딩 프로젝트의 경우 총 결제예약금 및 결제금의 합계액이 1천만
              원에 도달하게 되는 결제예약 및 결제 시도 건부터 결제예약 및 결제
              진행이 불가능합니다.
            </li>
          </ol>

          <h2 id="target_no3">제3장 프리오더 서비스</h2>
          <h3>제27조 (프리오더 용어의 정의)</h3>
          <p>
            본 장에서 사용하는 용어의 정의는 다음 각 호와 같습니다. 본 장에서
            정의되지 않은 용어는 본 약관의 다른 장 및 일반 거래 관행에 따라
            정의된 의미를 가집니다.
          </p>
          <ol>
            <li>
              “예약구매 기간”이라 함은 메이커가 게시한 프로젝트를 통해
              서포터에게 예약판매 할 수 있는 기간으로서 프로젝트를 오픈하여
              예약구매자 모집이 가능한 시점부터 프로젝트 종료일까지의 기간을
              의미합니다.
            </li>
            <li>
              “예약구매의 신청”이란 서포터가 프로젝트의 예약구매 기간 내에
              회사가 정한 방법에 따라 예약구매의 의사표시를 하는 것을
              의미합니다.
            </li>
            <li>
              "예약구매의 취소"란 서포터가 프로젝트의 예약구매 기간 내에
              예약구매의 의사표시를 철회하는 것을 의미합니다
            </li>
            <li>
              “결제예약금”이란 서포터가 예약구매의 신청에 따라 결제를 예약한
              금액을 의미합니다.
            </li>
            <li>
              “결제완료금”이란 서포터의 예약구매의 신청에 따라 프로젝트 종료
              시점에 모집된 결제금 및 결제예약금 중 결제 기간(프로젝트 종료일의
              익일로부터 4영업일) 종료 시 결제가 되지 않거나 취소된 금액을
              제외하고 최종 결제가 완료된 금원을 말합니다.
            </li>
            <li>
              “결제대금예치 서비스”라 함은 매매계약이 체결되고 서포터가 대금을
              결제하는 경우에, 서포터의 결제대금 보호를 위하여 메이커가 일정
              기간 동안 결제대금을 예치하는 서비스를 말합니다.
            </li>
          </ol>

          <h3>제28조 (예약구매의 신청 전 유의사항)</h3>
          <ol>
            <li>
              서포터는 본 약관에 따른 예약구매를 신청하기 전에 사이트를 통해
              제공되는 정보뿐만 아니라 프로젝트 예약구매 기간, 리워드 제공의
              조건 등 프로젝트의 내용을 충분히 숙지한 후 예약구매를 신청하여야
              합니다.
            </li>
            <li>
              회사는 통신판매중개 서비스와 관련하여 통신판매 중개자로서 서포터와
              메이커 간의 자유로운 리워드의 거래를 위한 시스템을 운영 및 관리,
              제공할 뿐이므로, 서포터는 리워드를 예약구매하기 전에 반드시
              메이커가 프로젝트 상세 페이지 내에 작성한 리워드의 상세 내용과
              거래의 조건을 정확하게 확인해야 합니다. 구매하려는 리워드의 내용과
              거래의 조건을 확인하지 않고 구매하여 발생한 모든 손해는 서포터
              본인이 부담합니다.
            </li>
            <li>
              서포터는 회사가 서포터의 서비스 이용 편의를 위하여 메이커
              등으로부터 제공 받은 리워드 관련 정보 기타 콘텐츠를 사이트를
              통하여 제공하는 경우에도 리워드의 구매 여부는 전적인 서포터 본인의
              판단과 책임으로 결정하여야 합니다. 이 경우 회사는 고의 또는
              중과실이 있는 경우를 제외하고 서포터의 구매 결정에 대하여 책임을
              부담하지 아니 합니다.
            </li>
            <li>
              메이커가 프로젝트 개시 전 예약구매 신청의 시기 등 유형별로 리워드
              제공의 조건과 기준, 방법 등을 다르게 설정할 수 있도록 회사에
              요청할 수 있으며, 회사는 그 조건과 기준이 합리적이고 명확한 기준에
              의한 것인 경우 그 내용을 사이트에 게시합니다. 이 경우 서포터가 위
              내용에 따라 리워드를 제공받을 때 제한이 있을 수 있습니다.
            </li>
          </ol>

          <h3>제29조 (프리오더 서포터들의 의견교환 등)</h3>
          <ol>
            <li>
              서포터는 사이트에 개설된 게시판을 통해 프로젝트에 대한 질의 또는
              답변을 게시하는 방법으로 메이커와 의견을 교환할 수 있습니다.
            </li>
            <li>
              회사는 사이트에 게시된 서포터들의 의견을 임의로 삭제하거나
              수정하지 않습니다. 다만, 다른 법률에 근거가 있거나 게시된 글이
              회원가입약관 및 기타 커뮤니티 정책에 따라 삭제될 수 있는 경우에는
              그러하지 아니합니다.
            </li>
          </ol>

          <h3>제30조 (예약구매의 신청 방법)</h3>
          <ol>
            <li>
              서포터는 예약구매 하려는 프로젝트의 페이지에서 회사가 제공하는
              양식에 따라 구매내용(결제예약금 및 결제금, 리워드의 내용, 수량,
              추가 결제예약금 및 결제금 등)과 결제방법을 입력하여 결제를
              예약하거나 즉시 결제를 진행하면 회사에 대한 예약구매의 신청이
              이루어지며, 이에 대하여 회사에 접수되면 예약구매의 신청이
              완료됩니다.
            </li>
            <li>
              회사는 메이커가 합리적이고 명확한 기준에 따라 서포터의 자격 또는
              결제예약금 및 결제금의 액수에 제한을 두기를 요청한 경우 그에 따라
              예약구매의 신청을 제한할 수 있습니다.
            </li>
            <li>
              서포터는 각 프로젝트별로 회사가 제공하는 결제수단(신용카드,
              체크카드를 통한 예약결제, 가상계좌 등)을 통하여 사이트 내에서
              예약구매 신청에 따른 결제예약 또는 결제를 할 수 있습니다.
            </li>
            <li>
              회사는 정확하고 안전한 결제 처리를 위해 관계법령에 따라 아래와
              같은 서포터의 정보를 결제 대행사에 위탁하게 됩니다.
            </li>
          </ol>

          {/* <div className={style.policy_table}>
            <table style={{ minWidth: "500px", width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>
                    결제와 관련한 정보를 제공 받는 자
                  </th>
                  <th style={{ width: "50%" }}>위탁되는 정보</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul>
                      <li>주식회사 케이지이니시스</li>
                      <li>나이스페이먼츠 주식회사</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>신용카드(체크카드)의 번호, 유효기간</li>
                      <li>신용카드(체크카드) 소유자의 성명</li>
                      <li>계좌번호, 은행, 예금주 성명</li>
                      <li>이메일, 휴대폰번호 등 결제 확인을 위한 연락처</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}

          <ol>
            <li>
              서포터가 1개의 프로젝트에 1회 구매 신청할 때 최소 1,000원 이상을
              입력하여야 합니다. 서포터는 1개의 프로젝트에 중복하여 구매를
              신청할 수 있습니다.
            </li>
            <li>
              서포터는 예약구매 신청의 접수 결과는 전자우편 또는 문자메시지 등을
              통해 통보 받게 됩니다.
            </li>
            <li>
              서포터는 본 약관 및 회사가 정한 규정에 따라 아래와 같거나 유사한
              방법으로 구매를 신청하며, 회사는 서포터가 구매신청을 함에 있어서
              다음의 각 내용을 알기 쉽게 제공하여야 합니다.
              <ol>
                <li>재화 등의 검색 및 선택</li>
                <li>
                  받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는
                  이동전화번호) 등의 입력
                </li>
                <li>
                  약관내용, 청약 철회권이 제한되는 서비스, 배송료/설치비 등의
                  비용부담과 관련한 내용에 대한 확인
                </li>
                <li>
                  재화 등의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한
                  동의
                </li>
                <li>결제방법의 선택</li>
              </ol>
            </li>
            <li>
              회사 또는 메이커는 제1항과 같은 구매신청에 대하여 다음 각 호에
              해당하면 승낙하지 않을 수 있습니다.
              <ol>
                <li>신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                <li>
                  미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및
                  용역을 구매하는 경우
                </li>
                <li>
                  기타 구매신청에 승낙하는 것이 회사의 기술상 현저히 지장이
                  있다고 판단하는 경우
                </li>
              </ol>
            </li>
          </ol>

          <h3>제31조 (서포터 수의 게시)</h3>
          <ol>
            <li>
              예약구매의 신청이 접수되거나 프로젝트에 대한 지지서명이 이루어
              지면 사이트에 프로젝트의 서포터 수가 게시됩니다. 다만, 본 약관에
              따라 결제 예약 또는 결제의 취소가 있거나 예약구매의 신청 효력이
              상실되는 경우에는 서포터 수는 변동될 수 있습니다.
            </li>
            <li>
              제1항의 서포터 수에도 불구하고 제33조에 따라 전달되는 결제금의
              액수에는 결제기간 중 결제 실패 또는 결제 취소 금액이 추가로 반영될
              수 있어 예약구매 기간 내의 결제예약금 및 결제금과 차이가 있을 수
              있습니다.
            </li>
          </ol>

          <h3>제32조 (프리오더 결과의 게시 및 통보 등)</h3>
          <p>
            예약구매 기간이 종료되면 회사는 프리오더 결과를 제2조에 따라 "성공"
            또는 "실패"로 구분하여 사이트에 게시합니다.
          </p>

          <h3>제33조 (결제금의 전달)</h3>
          <ol>
            <li>
              제2조에 따라 프리오더 결과가 “성공”이고, 리워드 배송 후 반품, 교환
              및 환불 등의 절차가 모두 종료된 경우 결제완료금은 메이커
              이용약관에서 정한 바에 따라 메이커에게 전달됩니다. 다만,
              프로젝트의 특성에 따라 메이커 이용약관에서 달리 약정한 경우에는
              조건부 또는 분할하여 지급될 수 있습니다.
            </li>
            <li>
              회사는 특별한 사정이 없는 한 메이커 이용약관에서 정한 바에 따라
              메이커에 대한 확인절차가 완료된 경우에 한하여 위 완료된 날로부터
              15영업일 이내에 메이커에게 수수료 등을 공제한 결제완료금의
              전액(일시 지급하기로 한 경우) 또는 일부(분할 지급하기로 한 경우)를
              전달합니다.
            </li>
            <li>
              제1항, 제2항에도 불구하고 메이커가 프로젝트를 이행할 수 없거나
              서포터에게 리워드를 제공할 수 없음이 분명한 경우 또는 메이커
              이용약관상 결제완료금을 정산할 수 없다고 규정된 경우에는 회사는
              결제완료금의 지급을 유보하거나 전달하지 아니할 수 있습니다.
            </li>
            <li>
              결제완료금 지급 도중 제3항과 같은 사유가 발생한 경우에는 회사는
              지급하지 않은 잔여 결제완료금의 지급을 유보할 수 있고, 필요한 경우
              이미 지급받은 결제완료금에 대하여도 메이커가 서포터들에게
              환불하도록 요청할 수 있습니다.
            </li>
          </ol>

          <h3>제34조 (프리오더 결제대금예치 서비스 이용)</h3>
          <ol>
            <li>
              회사는 서포터와 회사 또는 메이커 간에 이루어지는 상품 등 매매의
              안전성과 신뢰성을 높이고 상품 등을 인수하기 전에 대금을 결제해야
              하는 서포터를 보호하기 위하여, 메이커로 하여금 서포터에게
              결제대금예치 서비스를 제공하도록 합니다.
            </li>
            <li>
              회사는 결제대금예치 서비스의 제공과 관련하여 메이커 또는 서포터를
              대리하는 것이 아님은 물론, 상품 등의 매매와 관련하여 메이커 또는
              서포터의 의무를 대행하는 것도 아닙니다.
            </li>
            <li>
              서포터는 결제대금예치 서비스를 통하여 구매대금을 결제하는 과정에서
              발생하는 결제대금예치 서비스 송금수수료 등을 부담하여야 합니다.
            </li>
            <li>
              서포터는 메이커가 결제대금예치 서비스를 제공하는 과정에서 발생하는
              이자 등에 대하여 회사 또는 메이커에게 그 환불을 청구할 수
              없습니다.
            </li>
            <li>결제대금예치 서비스는 구매확정 된 경우에 종료됩니다.</li>
            <li>
              서포터는 결제대금예치 서비스가 종료된 이후에 해당 구매 건에 대하여
              청약철회, 취소, 해제, 무효 등의 사유가 발생한 경우 회사 또는
              메이커와 직접 청약철회, 취소, 해제 및 환불 등에 관한 절차를
              진행해야 합니다.
            </li>
          </ol>

          <h3>제35조 (예약구매의 취소)</h3>
          <ol>
            <li>
              서포터는 구매한 리워드가 발송되기 전까지 예약구매 또는 예약구매의
              신청을 취소할 수 있으며, 발송처리가 완료된 경우에는 취소가 아닌
              환불 절차에 따라 처리됩니다.
            </li>
            <li>
              회원이 결제를 완료한 후 리워드 발송 전 상태에서는 취소신청 접수 시
              특별한 사정이 없는 한 즉시 취소처리를 위한 조치가 완료됩니다.
            </li>
            <li>
              리워드 발송 대기 중 또는 배송 중 상태에서 취소 신청한 때에 이미
              리워드가 발송이 된 경우에는 발송된 리워드의 왕복배송비를 서포터가
              부담하는 것을 원칙으로 하며, 취소가 아닌 환불 절차에 따라
              처리됩니다.
            </li>
            <li>
              예약구매의 취소 처리에 따른 환불은 취소 절차가 완료된 즉시 결제 시
              사용한 카드 또는 결제 수단을 통해 이뤄집니다.
            </li>
            <li>
              결제 시 할인쿠폰을 사용한 경우에는, 해당 할인쿠폰의 사용이
              취소됩니다. 단, 유효기간이 지난 경우 환불 즉시 쿠폰은 소멸됩니다.
            </li>
            <li>
              회사는 다음 각 호의 사유가 발생한 경우 해당 사유가 발생한 날로부터
              지체없이 예약구매 기간 종료 시까지 모집된 결제예약금 및 결제금을
              일괄적으로 취소합니다.
              <ol>
                <li>
                  프로젝트가 예약구매 기간 도중에 중도 철회 또는 취소된 경우
                </li>
                <li>제2조에 따라 예약구매 결과가 "실패"인 경우</li>
              </ol>
            </li>
            <li>
              본 조에 따른 예약구매의 취소의 경우 메이커는 위 취소된 결제예약금
              및 결제금에 대하여 어떠한 권리도 주장할 수 없습니다.
            </li>
          </ol>

          <h3>제36조 (결제금의 환불)</h3>
          <ol>
            <li>
              메이커와 리워드의 구매에 관한 계약을 체결한 서포터는 전자상거래법
              등 관련 법령에 따라 리워드를 배송 받은 날로부터 7일 이내에 반품
              또는 교환을 신청할 수 있습니다. 다만, 청약철회에 관하여
              전자상거래법에 달리 정함이 있는 경우에는 동법 규정에 따릅니다.
            </li>
            <li>
              서포터는 제1항에도 불구하고 다음 각 호에 해당하는 경우에는
              반품이나 교환을 요청할 수 없습니다.
              <ol>
                <li>
                  서포터의 책임 있는 사유로 리워드가 멸실 또는 훼손된 경우(다만,
                  리워드의 내용을 확인하기 위하여 포장 등을 훼손한 경우는
                  제외함)
                </li>
                <li>
                  서포터의 사용 또는 일부 소비로 인하여 리워드의 가치가 현저히
                  감소한 경우
                </li>
                <li>
                  시간의 경과에 의하여 재판매가 곤란할 정도로 리워드의 가치가
                  현저히 감소한 경우
                </li>
                <li>복제 가능한 리워드의 포장을 훼손한 경우</li>
                <li>
                  용역 또는 “문화산업진흥 기본법” 제2조 제5호의 디지털콘텐츠의
                  제공이 개시된 경우(다만, 가분적 용역 또는 가분적
                  디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한
                  부분에 대하여는 제외함)
                </li>
                <li>
                  주문에 따라 개별적으로 생산되는 리워드 등 그에 대하여 청약철회
                  등을 인정할 경우 메이커에게 회복할 수 없는 중대한 피해가
                  예상되는 경우로서, 사전에 해당 거래에 대하여 별도로 그 사실을
                  고지하고 서포터의 서면(전자문서를 포함)에 의한 동의를 받은
                  경우
                </li>
                <li>그 밖에 관련 법령에 따른 반품 제한 사유에 해당되는 경우</li>
              </ol>
            </li>
            <li>
              서포터는 제1항 및 제2항에도 불구하고 리워드의 내용이 표시∙광고
              내용과 다르거나 계약내용과 다르게 이행된 경우에는 해당 리워드를
              수령한 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터
              30일 이내에 반품 또는 교환을 신청할 수 있습니다.
            </li>
            <li>
              회사는 서포터로부터 반품 또는 교환의 의사표시를 받은 경우에는 즉시
              그 사실을 메이커에게 통보합니다.
            </li>
            <li>
              서포터는 제1항 또는 제3항에 따라 교환 신청을 하더라도 메이커에게
              교환할 리워드의 재고가 없는 경우에는 해당 리워드의 교환 신청을
              받을 수 없습니다. 이 경우 메이커는 서포터의 의향을 확인하여 해당
              교환 신청을 반품으로 처리할 수 있습니다.
            </li>
            <li>
              반품이나 교환에 필요한 배송비 및 기타 비용은 귀책사유가 있는
              당사자가 부담합니다. 이 경우 리워드의 하자 또는 오배송으로 인한
              반품이나 교환의 경우에는 메이커가 그 비용을 부담하나, 서포터의
              단순 변심으로 인한 반품이나 교환의 경우에는 서포터가 그 비용을
              부담합니다.
            </li>
            <li>
              메이커는 서포터가 배송상의 문제로 손해를 입은 경우에는 그에 대한
              책임을 부담합니다.
            </li>
            <li>
              서포터의 반품 또는 교환 신청을 메이커가 거절할 경우 해당 주문은
              구매확정으로 자동 전환됩니다. 반품 및 교환 과정에 분쟁이 발생할
              경우 서포터는 메이커와 개별적으로 분쟁을 해결합니다.
            </li>
            <li>
              회사는 메이커가 서포터의 결제완료에 대한 통지를 받은 이후에도 상당
              기간 배송 절차 등을 이행하지 아니하여 서포터가 청약철회 등을
              하거나 ‘발송 준비 중’ 상태에서 상당한 기간 동안 아무런 조치를
              취하지 않을 경우, 회사는 직권으로 서포터의 결제를 취소하고 예치
              중인 결제대금을 서포터에게 환불할 수 있습니다.
            </li>
            <li>
              메이커가 서포터의 교환 또는 반품 신청에 대하여 적극적으로 임하지
              않거나 이를 지연하는 경우, 회사는 서포터에게 그 교환 또는 반품
              신청의 사유를 파악하여 정당한 사유가 있는 경우에는 해당 거래를
              취소하는 한편, 예치 중인 결제대금을 서포터에게 환불할 수 있습니다.
              다만 서포터의 교환 또는 반품신청에 정당한 사유가 없는 경우에는
              그러하지 아니합니다.
            </li>
            <li>
              서포터의 반품 요청 후 7영업일이 경과할 때까지 메이커가 이를
              처리하기 위한 아무런 조치를 취하지 않을 경우 서포터의 반품요청은
              자동으로 승인되어 8영업일 째에 자동으로 환불처리 됩니다. 카드로
              결제가 이루어진 경우 환불은 해당 카드 결제 취소를 통해서만
              가능합니다.
            </li>
          </ol>

          <h2 id="target_no4">제4장 스토어 서비스</h2>
          <h3>제1절 총칙</h3>

          <h3>제37조 (스토어 용어의 정의)</h3>
          <p>
            본 장에서 사용하는 용어의 정의는 본 약관의 다른 장 및 일반 거래
            관행에 따라 정의된 의미를 가집니다.
          </p>

          <h3>제38조 (구매신청)</h3>
          <ol>
            <li>
              서포터는 본 약관 및 회사가 정한 규정에 따라 아래와 같거나 유사한
              방법으로 구매를 신청하며, 회사는 서포터가 구매신청을 함에 있어서
              다음의 각 내용을 알기 쉽게 제공하여야 합니다.
              <ol>
                <li>재화 등의 검색 및 선택</li>
                <li>
                  받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는
                  이동전화번호) 등의 입력
                </li>
                <li>
                  약관내용, 청약 철회권이 제한되는 서비스, 배송료/설치비 등의
                  비용부담과 관련한 내용에 대한 확인
                </li>
                <li>
                  재화 등의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한
                  동의
                </li>
                <li>결제방법의 선택</li>
              </ol>
            </li>
            <li>
              회사 또는 메이커는 제1항과 같은 구매신청에 대하여 다음 각 호에
              해당하면 승낙하지 않을 수 있습니다.
              <ol>
                <li>신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                <li>
                  미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및
                  용역을 구매하는 경우
                </li>
                <li>
                  기타 구매신청에 승낙하는 것이 회사의 기술상 현저히 지장이
                  있다고 판단하는 경우
                </li>
              </ol>
            </li>
          </ol>

          <h3>제39조 (매매계약의 체결 및 대금 결제)</h3>
          <ol>
            <li>
              상품의 매매계약은 서포터가 회사 또는 메이커가 제시한 상품의 판매
              조건에 응하여 청약의 의사표시를 하고 이에 대하여 회사 또는
              메이커가 승낙의 의사표시를 함으로써 체결됩니다.
            </li>
            <li>
              회사는 서포터에게 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
              및 기타 회사가 추가 지정하는 결제 수단의 방법으로 매매 대금을
              결제할 수 있는 방법을 제공합니다.
            </li>
            <li>
              회사는 정확하고 안전한 결제 처리를 위해 관계법령에 따라 아래와
              같은 서포터의 정보를 서포터의 동의를 받아 결제 대행사에 제공하게
              됩니다.
            </li>
          </ol>

          {/* <div className={style.policy_table}>
            <table style={{ minWidth: "500px", width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>
                    결제와 관련한 정보를 제공 받는 자
                  </th>
                  <th style={{ width: "50%" }}>위탁되는 정보</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul>
                      <li>주식회사 케이지이니시스</li>
                      <li>나이스페이먼츠 주식회사</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>신용카드(체크카드)의 번호, 유효기간</li>
                      <li>신용카드(체크카드) 소유자의 성명</li>
                      <li>계좌번호, 은행, 예금주 성명</li>
                      <li>이메일, 휴대폰번호 등 결제 확인을 위한 연락처</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}

          <ol>
            <li>
              서포터가 매매대금의 결제와 관련하여 입력한 정보 및 그 정보와
              관련하여 발생한 책임과 불이익은 전적으로 서포터가 부담합니다.
            </li>
            <li>
              상품을 주문한 후 일정 기간 내에 매매대금을 결제하지 않을 경우
              회사는 당해 주문을 서포터의 동의 없이 취소할 수 있습니다.
            </li>
            <li>
              회사는 서포터의 상품 매매계약 체결 내용을 사이트를 통해 확인할 수
              있도록 조치하며, 매매계약의 취소 방법 및 절차를 안내합니다.
            </li>
            <li>
              회사는 서포터가 매매대금 결제 시 사용한 결제수단에 대해 정당한
              사용권한을 가지고 있는지의 여부를 확인할 수 있으며, 이에 대한
              확인이 완료될 때까지 거래진행을 중지하거나, 확인이 불가한
              해당거래를 취소할 수 있습니다.
            </li>
            <li>
              서포터가 실제로 결제하는 금액은 회사 또는 메이커가 정한 공급원가,
              기본이용료, 상품에 적용된 할인쿠폰, 배송비, 옵션상품의 옵션내역
              등이 적용된 금액(실구매액)이며 서포터에게 발행되는
              구매증빙서(현금영수증, 세금계산서, 신용카드매출전표 등)는
              실구매액으로 발행됩니다.
            </li>
          </ol>

          <h3>제40조 (스토어 결제대금예치 서비스 이용)</h3>
          <ol>
            <li>
              회사는 서포터와 회사 또는 메이커 간에 이루어지는 상품 등 매매의
              안전성과 신뢰성을 높이고 상품 등을 인수하기 전에 대금을 결제해야
              하는 서포터를 보호하기 위하여, 메이커로 하여금 서포터에게
              결제대금예치 서비스를 제공하도록 합니다.
            </li>
            <li>
              회사는 결제대금예치 서비스의 제공과 관련하여 메이커 또는 서포터를
              대리하는 것이 아님은 물론, 상품 등의 매매와 관련하여 메이커 또는
              서포터의 의무를 대행하는 것도 아닙니다.
            </li>
            <li>
              서포터는 결제대금예치 서비스를 통하여 구매대금을 결제하는 과정에서
              발생하는 결제대금예치 서비스 송금수수료 등을 부담하여야 합니다.
            </li>
            <li>
              서포터는 메이커가 결제대금예치 서비스를 제공하는 과정에서 발생하는
              이자 등에 대하여 회사 또는 메이커에게 그 환불을 청구할 수
              없습니다.
            </li>
            <li>결제대금예치 서비스는 구매확정 된 경우에 종료됩니다.</li>
            <li>
              서포터는 결제대금예치 서비스가 종료된 이후에 해당 구매 건에 대하여
              청약철회, 취소, 해제, 무효 등의 사유가 발생한 경우 회사 또는
              메이커와 직접 청약철회, 취소, 해제 및 환불 등에 관한 절차를
              진행해야 합니다.
            </li>
          </ol>

          <h3>제41조 (구매취소)</h3>
          <ol>
            <li>
              서포터는 구매한 상품이 발송되기 전까지 구매를 취소할 수 있으며,
              발송처리가 완료된 경우에는 취소가 아닌 반품절차에 따라 처리됩니다.
            </li>
            <li>
              회원이 결제를 완료한 후 상품 준비 중 상태에서는 취소신청 접수 시
              특별한 사정이 없는 한 즉시 취소처리를 위한 조치가 완료됩니다.
            </li>
            <li>
              배송 대기 중 또는 배송 중 상태에서 취소 신청한 때에 이미 상품이
              발송이 된 경우에는 발송된 상품의 왕복배송비를 서포터가 부담하는
              것을 원칙으로 하며, 취소가 아닌 반품절차에 따라 처리됩니다.
            </li>
            <li>
              구매취소 처리에 따른 환불은 취소 절차가 완료된 즉시 결제 시 사용한
              카드 또는 결제 수단을 통해 이뤄집니다.
            </li>
            <li>
              결제 시 할인쿠폰을 사용한 경우에는, 해당 할인쿠폰의 사용이
              취소됩니다. 단, 유효기간이 지난 경우 환불 즉시 쿠폰은 소멸됩니다.
            </li>
          </ol>

          <h3>제2절 통신판매 서비스</h3>

          <h3>제42조 (적용범위)</h3>
          <p>
            본 절의 경우 회사가 제공하는 본 장의 서비스 중 통신판매 서비스 및
            이에 수반되는 기타 서비스에 한정하여 적용됩니다.
          </p>

          <h3>제43조 (배송 및 거래완료)</h3>
          <ol>
            <li>
              회사는 서포터의 입금 또는 대금결제에 대한 확인통지를 받은 날로부터
              3영업일 이내에 배송에 필요한 조치를 취합니다. 단, 배송기간에 대해
              메이커와 서포터 간 달리 정함이 있는 경우에는 그에 따릅니다.
            </li>
            <li>
              서포터는 구매한 상품 등의 배송이 완료되었을 때로부터 일정한 기간
              내(7일)에 회사에 대하여 구매확정, 교환 또는 반품의 의사표시를
              하여야 합니다.
            </li>
            <li>
              회사는 제2항의 구매확정 기간 내에 구매확정, 교환 또는 반품에 대한
              서포터의 의사표시가 없는 경우, 해당 거래에 대하여 서포터의
              구매확정 의사표시가 있는 것으로 간주하여 자동구매확정으로 처리할
              수 있습니다.
            </li>
          </ol>

          <h3>제44조 (청약철회 등)</h3>
          <ol>
            <li>
              회사와 상품 등의 구매에 관한 계약을 체결한 서포터는 전자상거래법
              등 관련 법령에 따라 상품 등을 배송 받은 날로부터 7일 이내에 반품
              또는 교환을 신청할 수 있습니다. 다만, 청약철회에 관하여
              전자상거래법에 달리 정함이 있는 경우에는 동법 규정에 따릅니다.
            </li>
            <li>
              서포터는 제1항에도 불구하고 다음 각 호에 해당하는 경우에는
              반품이나 교환을 요청할 수 없습니다.
              <ol>
                <li>
                  서포터의 책임 있는 사유로 상품 등이 멸실 또는 훼손된
                  경우(다만, 상품 등의 내용을 확인하기 위하여 포장 등을 훼손한
                  경우는 제외함)
                </li>
                <li>
                  서포터의 사용 또는 일부 소비로 인하여 상품 등의 가치가 현저히
                  감소한 경우
                </li>
                <li>
                  시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가
                  현저히 감소한 경우
                </li>
                <li>복제 가능한 상품 등의 포장을 훼손한 경우</li>
                <li>
                  용역 또는 “문화산업진흥 기본법” 제2조 제5호의 디지털콘텐츠의
                  제공이 개시된 경우(다만, 가분적 용역 또는 가분적
                  디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한
                  부분에 대하여는 제외함)
                </li>
                <li>
                  주문에 따라 개별적으로 생산되는 상품 등 그에 대하여 청약철회
                  등을 인정할 경우 메이커에게 회복할 수 없는 중대한 피해가
                  예상되는 경우로서, 사전에 해당 거래에 대하여 별도로 그 사실을
                  고지하고 서포터의 서면(전자문서를 포함)에 의한 동의를 받은
                  경우
                </li>
                <li>그 밖에 관련 법령에 따른 반품 제한 사유에 해당되는 경우</li>
              </ol>
            </li>
            <li>
              서포터는 제1항 및 제2항에도 불구하고 상품의 내용이 표시∙광고
              내용과 다르거나 계약내용과 다르게 이행된 경우에는 해당 상품 등을
              공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던
              날부터 30일 이내에 반품 또는 교환을 신청할 수 있습니다.
            </li>
            <li>
              서포터는 제1항 또는 제3항에 따라 교환 신청을 하더라도 회사에게
              교환할 상품의 재고가 없는 경우에는 해당 상품 등의 교환을 받을 수
              없습니다. 이 경우 메이커는 서포터의 의향을 확인하여 해당 교환
              신청을 반품으로 처리할 수 있습니다.
            </li>
            <li>
              반품이나 교환에 필요한 배송비 및 기타 비용은 귀책사유가 있는
              당사자가 부담합니다. 이 경우 상품 등의 하자 또는 오배송으로 인한
              반품이나 교환의 경우에는 메이커가 그 비용을 부담하나, 서포터의
              단순 변심으로 인한 반품이나 교환의 경우에는 서포터가 그 비용을
              부담합니다.
            </li>
            <li>
              회사는 회사와 상품 등의 구매에 관한 계약을 체결한 서포터가
              배송상의 문제로 손해를 입은 경우에는 그에 대한 책임을 부담합니다.
            </li>
            <li>
              서포터의 반품 또는 교환 신청을 회사가 거절할 경우 해당 주문은
              구매확정으로 자동 전환됩니다.
            </li>
          </ol>

          <h3>제3절 통신판매중개 서비스</h3>

          <h3>제45조 (적용범위)</h3>
          <p>
            본 절의 경우 회사가 제공하는 본 장의 서비스 중 통신판매중개 서비스
            및 이에 수반되는 기타 서비스에 한정하여 적용됩니다.
          </p>

          <h3>제46조 (통신판매중개 서비스의 이용)</h3>
          <ol>
            <li>
              회사는 통신판매중개 서비스와 관련하여 통신판매 중개자로서 서포터와
              메이커 간의 자유로운 상품 등의 거래를 위한 시스템을 운영 및 관리,
              제공할 뿐이므로, 서포터는 상품 등을 구매하기 전에 반드시 메이커가
              판매 페이지 내에 작성한 상품 등의 상세 내용과 거래의 조건을
              정확하게 확인해야 합니다. 구매하려는 상품 등의 내용과 거래의
              조건을 확인하지 않고 구매하여 발생한 모든 손해는 서포터 본인이
              부담합니다.
            </li>
            <li>
              회원은 메이커와 상품 등 매매 절차에서 분쟁이 발생하면 분쟁의
              해결을 위하여 성실히 임해야 하며, 분쟁해결의 불성실로 회사 및
              제3자에 손해가 발생하면 그에 대한 모든 책임을 부담합니다.
            </li>
            <li>
              서포터는 회사가 서포터의 서비스 이용 편의를 위하여 메이커
              등으로부터 제공 받은 상품 등 관련 정보 기타 콘텐츠를 사이트를
              통하여 제공하는 경우에도 상품 등의 구매 여부는 전적인 서포터
              본인의 판단과 책임으로 결정하여야 합니다. 이 경우 회사는 고의 또는
              중과실이 있는 경우를 제외하고 서포터의 구매 결정에 대하여 책임을
              부담하지 아니 합니다.
            </li>
          </ol>

          <h3>제47조 (배송 및 거래완료)</h3>
          <ol>
            <li>
              회사는 상품 등의 배송과 관련하여 메이커, 서포터, 배송업체 등 관련
              당사자 사이에 분쟁 등이 발생하는 경우에는 이에 관여하지 않으며
              어떠한 책임도 부담하지 아니합니다. 이 경우 해당 분쟁 등은 관련
              당사자가 직접 해결하여야 합니다.
            </li>
            <li>
              회사는 메이커에게 회사로부터 서포터의 입금 또는 대금결제에 대한
              확인통지를 받은 후 3영업일 이내에 배송에 필요한 조치를 취하도록
              안내합니다. 단, 배송기간에 대해 메이커와 서포터 간 달리 정함이
              있는 경우에는 그에 따릅니다.
            </li>
            <li>
              서포터는 구매한 상품 등의 배송이 완료되었을 때로부터 일정한 기간
              내(7일)에 회사에 대하여 구매확정, 교환 또는 반품의 의사표시를
              하여야 합니다.
            </li>
            <li>
              회사는 제3항의 구매확정 기간 내에 구매확정, 교환 또는 반품에 대한
              서포터의 의사표시가 없는 경우, 해당 거래에 대하여 서포터의
              구매확정 의사표시가 있는 것으로 간주하여 자동구매확정으로 처리할
              수 있습니다.
            </li>
            <li>
              서포터는 구매확정이 이루어진 경우에는 회사에 대하여 상품 등의
              하자, 미수령, 반품 등의 사유로 이의를 제기할 수 없습니다. 구매확정
              이후에 발생하는 환불, 교환 기타 메이커와 서포터 간의 모든 문제는
              전자상거래법에 따라 거래 당사자인 메이커와 서포터가 직접 해결해야
              하며, 회사는 이에 개입하지 아니합니다.
            </li>
          </ol>

          <h3>제48조 (청약철회 등)</h3>
          <ol>
            <li>
              메이커와 상품 등의 구매에 관한 계약을 체결한 서포터는 전자상거래법
              등 관련 법령에 따라 상품 등을 배송 받은 날로부터 7일 이내에 반품
              또는 교환을 신청할 수 있습니다. 다만, 청약철회에 관하여
              전자상거래법에 달리 정함이 있는 경우에는 동법 규정에 따릅니다.
            </li>
            <li>
              서포터는 제1항에도 불구하고 다음 각 호에 해당하는 경우에는
              반품이나 교환을 요청할 수 없습니다.
              <ol>
                <li>
                  서포터의 책임 있는 사유로 상품 등이 멸실 또는 훼손된
                  경우(다만, 상품 등의 내용을 확인하기 위하여 포장 등을 훼손한
                  경우는 제외함)
                </li>
                <li>
                  서포터의 사용 또는 일부 소비로 인하여 상품 등의 가치가 현저히
                  감소한 경우
                </li>
                <li>
                  시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가
                  현저히 감소한 경우
                </li>
                <li>복제 가능한 상품 등의 포장을 훼손한 경우</li>
                <li>
                  용역 또는 “문화산업진흥 기본법” 제2조 제5호의 디지털콘텐츠의
                  제공이 개시된 경우(다만, 가분적 용역 또는 가분적
                  디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한
                  부분에 대하여는 제외함)
                </li>
                <li>
                  주문에 따라 개별적으로 생산되는 상품 등 그에 대하여 청약철회
                  등을 인정할 경우 메이커에게 회복할 수 없는 중대한 피해가
                  예상되는 경우로서, 사전에 해당 거래에 대하여 별도로 그 사실을
                  고지하고 서포터의 서면(전자문서를 포함)에 의한 동의를 받은
                  경우
                </li>
                <li>그 밖에 관련 법령에 따른 반품 제한 사유에 해당되는 경우</li>
              </ol>
            </li>
            <li>
              서포터는 제1항 및 제2항에도 불구하고 상품의 내용이 표시∙광고
              내용과 다르거나 계약내용과 다르게 이행된 경우에는 해당 상품 등을
              공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던
              날부터 30일 이내에 반품 또는 교환을 신청할 수 있습니다.
            </li>
            <li>
              회사는 서포터로부터 반품 또는 교환의 의사표시를 받은 경우에는 즉시
              그 사실을 메이커에게 통보합니다.
            </li>
            <li>
              서포터는 제1항 또는 제3항에 따라 교환 신청을 하더라도 메이커에게
              교환할 상품의 재고가 없는 경우에는 해당 상품 등의 교환을 받을 수
              없습니다. 이 경우 메이커는 서포터의 의향을 확인하여 해당 교환
              신청을 반품으로 처리할 수 있습니다.
            </li>
            <li>
              반품이나 교환에 필요한 배송비 및 기타 비용은 귀책사유가 있는
              당사자가 부담합니다. 이 경우 상품 등의 하자 또는 오배송으로 인한
              반품이나 교환의 경우에는 메이커가 그 비용을 부담하나, 서포터의
              단순 변심으로 인한 반품이나 교환의 경우에는 서포터가 그 비용을
              부담합니다.
            </li>
            <li>
              메이커는 서포터가 배송상의 문제로 손해를 입은 경우에는 그에 대한
              책임을 부담합니다.
            </li>
            <li>
              서포터의 반품 또는 교환 신청을 메이커가 거절할 경우 해당 주문은
              구매확정으로 자동 전환됩니다. 반품 및 교환 과정에 분쟁이 발생할
              경우 서포터는 메이커와 개별적으로 분쟁을 해결합니다.
            </li>
            <li>
              회사는 메이커가 서포터의 결제완료에 대한 통지를 받은 이후에도 상당
              기간 배송 절차 등을 이행하지 아니하여 서포터가 청약철회 등을
              하거나 ‘상품 준비 중’ 상태에서 90일 동안 아무런 조치를 취하지 않을
              경우, 회사는 직권으로 서포터의 결제를 취소하고 결제대금예치 서비스
              이용 중인 결제대금을 서포터에게 환불할 수 있습니다.
            </li>
            <li>
              메이커가 서포터의 교환 또는 반품 신청에 대하여 적극적으로 임하지
              않거나 이를 지연하는 경우, 회사는 서포터에게 그 교환 또는 반품
              신청의 사유를 파악하여 정당한 사유가 있는 경우에는 해당 거래를
              취소하는 한편, 예치 중인 결제대금을 서포터에게 환불할 수 있습니다.
              다만 서포터의 교환 또는 반품신청에 정당한 사유가 없는 경우에는
              그러하지 아니합니다.
            </li>
            <li>
              서포터의 반품 요청 후 7영업일이 경과할 때까지 메이커가 이를
              처리하기 위한 아무런 조치를 취하지 않을 경우 서포터의 반품요청은
              자동으로 승인되어 8영업일 째에 자동으로 환불처리 됩니다. 카드로
              결제가 이루어진 경우 환불은 해당 카드 결제 취소를 통해서만
              가능합니다.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Wadiz;
