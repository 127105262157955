import React from "react";

import main from "../../../styles/main.module.css";
import ex from "../../../assets/images/img_6.jpg";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/basicImg.png";
import { CommonAxios } from "../../CommonAxios";
const MainNewsDetail = ({ data }) => {
  const {
    no,
    pro_title,
    pro_img,
    pro_rate,
    pro_link_yn,
    pro_link,
    // pro_content,
    pro_company,
  } = data;

  const navigate = useNavigate();

  const btn_move = (yn, link, num) => {
    let sendData = {
      no: num,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/main_funding_click",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );

    if (yn === "N") {
      navigate(`/invest/${num}/process`);
    } else {
      window.open(link);
    }
    // na
    // navigate(`/notice/${no}`);
  };
  return (
    <React.Fragment>
      <div
        className={main.main_news_item}
        onClick={() => btn_move(pro_link_yn, pro_link, no)}
      >
        <div className={main.main_news_inner}>
          <div className={main.main_news_img}>
            <img src={`/product/${no}/${pro_img}`} />
          </div>
          <div className={main.main_news_content}>
            {/* <h4 className={`${main.main_news_title}`}>
              <span className={main.ellipsis}>{title}</span>
            </h4> */}

            <p className={`${main.main_news_cont}`}>{pro_title}</p>
            {/* <p className={`${main.main_news_cont} ${main.two_ellipsis}`}>
              {title}
            </p> */}
            <span className={main.main_news_date}>
              <em>{pro_rate}%</em>
              {/* {c} */}
              {/* {pro_company} */}
              {/* <Moment format="YYYY-MM-DD">{notice_date}</Moment> */}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainNewsDetail;
