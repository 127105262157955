import React, { useState, useEffect } from "react";
import logo from "../logo.svg";
import "../App.css";
import MainBanner from "../components/main/MainBanner";
import MainSection1 from "../components/main/MainSection1";
import SuccessAlert from "../components/alert/Alert";
// import FundingDetail from "../components/funding/FundingDetail";
import InvestStatusDatail from "../components/invest/InvestStatusDatail";
function InvestStatusPage() {
  const [message, setMessage] = useState("");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <InvestStatusDatail />
    </>
  );
}

export default InvestStatusPage;
