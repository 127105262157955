import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import CustomTable from "./CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "../CommonAxios";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/admin.module.css";
import { Link, useNavigate } from "react-router-dom";
import InvestCustomTable from "./table/InvestCustomTable";
import { wonComma } from "../../lib/wonComma";
import { numberToKorean } from "../../lib/numberToKorean";
import { textReplace } from "../../lib/textReplace";
const InvestInfo = () => {
  useEffect(() => {
    let sendData = {};
    // let sendData = {
    //   no: id,
    // };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_invest_info",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setName(result.messageinfo.message[0].acc_name);
          setBank(result.messageinfo.message[0].acc_bank);

          setAccount(result.messageinfo.message[0].acc_number);
          // setPercent(result.messageinfo.message[0].acc_refund_percent);

          setToday(result.messageinfo.message[0].acc_refund_today);
          setOne(result.messageinfo.message[0].acc_refund_one_week);

          setTwo(result.messageinfo.message[0].acc_refund_two_weeks);
          setThree(result.messageinfo.message[0].acc_refund_three);
        } else {
        }
      }
    );
  }, []);
  const [name, setName] = useState("");
  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [percent, setPercent] = useState("");
  const onChangeName = useCallback((e) => {
    setName(e.target.value);
  }, []);
  const onChangeBank = useCallback((e) => {
    setBank(e.target.value);
  }, []);
  const onChangeAccount = useCallback((e) => {
    setAccount(e.target.value);
  }, []);
  const onChangePercent = useCallback((e) => {
    setPercent(e.target.value);
  }, []);

  const [today, setToday] = useState(0);
  const [one, setOne] = useState(0);
  const [two, setTwo] = useState(0);
  const [three, setThree] = useState(0);
  const onChangeToday = useCallback((e) => {
    setToday(e.target.value);
  }, []);
  const onChangeOne = useCallback((e) => {
    setOne(e.target.value);
  }, []);
  const onChangeTwo = useCallback((e) => {
    setTwo(e.target.value);
  }, []);
  const onChangeThree = useCallback((e) => {
    setThree(e.target.value);
  }, []);

  const btn_change_name = (data) => {
    updateAxios("acc_name", textReplace(data), function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };
  const btn_change_bank = (data) => {
    updateAxios("acc_bank", textReplace(data), function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };
  const btn_change_account = (data) => {
    updateAxios("acc_number", data, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };
  const btn_change_percent = (data) => {
    updateAxios("acc_refund_percent", data, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };

  const btn_change_today = (data) => {
    if (!/[0-9]/g.test(Number(data))) {
      return Swal.fire({
        title: "변경 실패",
        html: `숫자만 입력할 수 있습니다.`,

        confirmButtonText: "확인",
      });
    }

    updateAxios("acc_refund_today", data, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };
  const btn_change_one = (data) => {
    if (!/[0-9]/g.test(Number(data))) {
      return Swal.fire({
        title: "변경 실패",
        html: `숫자만 입력할 수 있습니다.`,

        confirmButtonText: "확인",
      });
    }

    updateAxios("acc_refund_one_week", data, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };
  const btn_change_two = (data) => {
    if (!/[0-9]/g.test(Number(data))) {
      return Swal.fire({
        title: "변경 실패",
        html: `숫자만 입력할 수 있습니다.`,

        confirmButtonText: "확인",
      });
    }

    updateAxios("acc_refund_two_weeks", data, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };
  const btn_change_three = (data) => {
    if (!/[0-9]/g.test(Number(data))) {
      return Swal.fire({
        title: "변경 실패",
        html: `숫자만 입력할 수 있습니다.`,

        confirmButtonText: "확인",
      });
    }

    updateAxios("acc_refund_three", data, function (result) {
      if (result === "ok") {
        return Swal.fire({
          title: "변경 완료",

          confirmButtonText: "확인",
        });
        // setSaveDate(new Date());
      }
    });
  };
  const updateAxios = (sendKey, sendValue, sendResult) => {
    let sendData = {
      field: sendKey,
      value: sendValue,
      // updateDate: NowDateVer2(),
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_invest_info",
      sendData,
      function (result) {
        sendResult(result.messageinfo.state);
      }
    );
  };

  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          회사정보 &nbsp;〉 &nbsp;계좌번호 및 환급 비율{" "}
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.info_write_wrap}>
            <div className={styles.info_write_inner}>
              <div className={styles.info_write_title}>
                <h4>회사 계좌번호 설정</h4>
              </div>
              <div className={styles.info_write_content}>
                <div>
                  <label>예금주</label>

                  <input type="text" onChange={onChangeName} value={name} />
                  <button onClick={() => btn_change_name(name)}>변경</button>
                </div>
                <div>
                  <label>은행</label>

                  <input type="text" onChange={onChangeBank} value={bank} />
                  <button onClick={() => btn_change_bank(bank)}>변경</button>
                </div>
                <div>
                  <label>계좌번호</label>

                  <input
                    type="text"
                    placeholder="-(하이픈)포함"
                    onChange={onChangeAccount}
                    value={account}
                  />
                  <button onClick={() => btn_change_account(account)}>
                    변경
                  </button>
                </div>
                {/* <div>
                  <label>위약금</label>

                  <input
                    type="text"
                    placeholder="단위는 %입니다."
                    onChange={onChangePercent}
                    value={percent}
                  />
                  <button onClick={() => btn_change_percent(percent)}>
                    변경
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className={styles.info_write_wrap} style={{ marginTop: "10px" }}>
            <div className={`${styles.info_write_inner} ${styles.info_refund}`}>
              <div className={styles.info_write_title}>
                <h4>투자 환급 비율 설정</h4>
              </div>
              <div className={styles.info_write_content}>
                <div>
                  <label>투자 당일(자정까지)</label>

                  <input type="text" onChange={onChangeToday} value={today} />
                  <span>%</span>
                  <button onClick={() => btn_change_today(today)}>변경</button>
                </div>
                <div>
                  <label>익일 ~ 일주일 미만</label>
                  <input type="text" onChange={onChangeOne} value={one} />{" "}
                  <span>%</span>
                  <button onClick={() => btn_change_one(one)}>변경</button>
                </div>
                <div>
                  <label>일주일 ~ 이주일 미만</label>
                  <input
                    type="text"
                    placeholder="-(하이픈)포함"
                    onChange={onChangeTwo}
                    value={two}
                  />{" "}
                  <span>%</span>
                  <button onClick={() => btn_change_two(two)}>변경</button>
                </div>
                <div>
                  <label>이주일 이상</label>
                  <input
                    type="text"
                    placeholder="단위는 %입니다."
                    onChange={onChangeThree}
                    value={three}
                  />{" "}
                  <span>%</span>
                  <button onClick={() => btn_change_three(three)}>변경</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvestInfo;
