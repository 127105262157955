import { FaUserCircle } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import MyInvestContent from "./detail/MyInvestContent";
import InvestTopSilder from "./detail/InvestTopSilder";
import InvestQna from "./detail/InvestQna";
import InvestNews from "./detail/InvestNews";
import InvestStory from "./detail/InvestStory";
import InvestReview from "./detail/InvestReview";
import { useEffect } from "react";
import inv from "../../styles/invest.module.css";
import { Link, useParams } from "react-router-dom";
import MyinvestNot from "./detail/MyinvestNot";
import MyinvestEContent from "./detail/MyinvestEContent";

const MyInvestEDetail = () => {
  const { id } = useParams();
  const { id2 } = useParams();
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  return (
    <>
      <div id="container">
        <div className="common-wrap">
          {" "}
          <div className={inv.common_invest_wrap}>
            <InvestTopSilder />
            <div className="common-inner-wrap">
              <MyInvestContent />
              {!user && <MyinvestNot />}{" "}
              {user && <MyinvestEContent user={user} />}{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyInvestEDetail;
