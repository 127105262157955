import com from "../../../styles/company.module.css";
import { GiFarmTractor } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcStackOfPhotos,
  FcSelfie,
  FcRuler,
} from "react-icons/fc";
import { CommonAxios } from "../../CommonAxios";
// import FundingContentItem from "./FundingContentItem";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Moment from "react-moment";
import { FaStar } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import SwiperCore, {
  Virtual,
  Navigation,
  Pagination,
  History,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
const CompanyStory = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    let sendData = "";
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/company_select_story",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);
  const SliderItemList = ({ data }) => {
    return (
      <>
        <div className={com.news_item}>
          {/* <a href="" className={com.news_anchor}> */}
          <a
            href={`${data.story_link}`}
            className={com.news_anchor}
            target="_blank"
          >
            <div
              className={com.news_img}
              style={{
                backgroundImage: `url('/story/${data.no}/${data.story_img}')`,
              }}
            ></div>
            <div className={com.news_text}>
              <p className={com.news_title}>{data.story_title}</p>
              <div className={com.text_box}>
                <span className={com.time}>
                  <Moment format="YYYY-MM-DD">{data.story_write_date}</Moment>{" "}
                </span>
                <span className={com.news_name}>{data.story_agency}</span>
              </div>
            </div>
          </a>
          {/* </a> */}
        </div>
      </>
    );
  };
  const slickRef = useRef(null);

  const previous = useCallback(() => slickRef.current.slickPrev(), []);
  const next = useCallback(() => slickRef.current.slickNext(), []);
  var settings = {
    centerMode: false,
    // dots: false,
    // infinite: false,
    // slidesToShow: 4,
    // slidesToScroll: 1, dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    afterChange: (current) => {
      setState(current + 1);
    },
    responsive: [
      {
        breakpoint: 970,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: false,
          infinite: true,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  // const [swiperRef, setSwiperRef] = useState(null);
  // const appendNumber = useRef(500);
  // const prependNumber = useRef(1);
  const [state, setState] = useState(1);
  // const [slides, setSlides] = useState(
  //   Array.from({ length: 500 }).map((_, index) => `Slide ${index + 1}`)
  // );

  // const prepend = () => {
  //   setSlides([
  //     `Slide ${prependNumber.current - 2}`,
  //     `Slide ${prependNumber.current - 1}`,
  //     ...slides,
  //   ]);
  //   prependNumber.current = prependNumber.current - 2;
  //   swiperRef.slideTo(swiperRef.activeIndex + 2, 0);
  // };
  // const append = () => {
  //   setSlides([...slides, "Slide " + ++appendNumber.current]);
  // };

  // const slideTo = (index) => {
  //   swiperRef.slideTo(index - 1, 0);
  // };
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  // const [prev, setPrev] = useState(false);
  // const [next1, setNext1] = useState(true);
  // useEffect(() => {
  //   console.log(prevRef.current.className.indexOf("swiper-button-disabled"));

  //   // if(prevRef.current.className)

  //   if (prevRef.current.className.indexOf("swiper-button-disabled") != -1) {
  //     console.log("dd");
  //     setPrev(true);
  //   } else {
  //     setPrev(false);
  //   }
  //   if (nextRef.current.className.indexOf("swiper-button-disabled") !== -1) {
  //     setNext1(true);
  //   } else {
  //     setPrev(false);
  //   }
  // }, [mainImageIndx, swiper]);
  return (
    <>
      {" "}
      <div className={com.rowgroup3}>
        <section className={`${com.news} ${com.n2}`}>
          <h2>스토리</h2>
          <div className={com.news_list}>
            {/* <Slider
              {...settings}
              style={{ width: "100%", height: "auto" }}
              ref={slickRef}
            >
           
              {data.map((data) => {
                return (
                  <div>
                    <SliderItemList data={data} />
                  </div>
                );
              })}
              
            </Slider> */}{" "}
            <Swiper
              className="mySwiper"
              slidesPerView={2}
              ref={setSwiper}
              {...parmss}
              style={{ position: "relative" }}
              // history={{
              //   key: "slide",
              // }}
              modules={[Navigation]}
              breakpoints={{
                740: {
                  slidesPerView: 3,
                  // spaceBetween: 20,
                },
                970: {
                  slidesPerView: 4,
                  // spaceBetween: 20,
                },
              }}
            >
              {data.map((data, index) => {
                return (
                  <SwiperSlide>
                    <div>
                      <SliderItemList data={data} />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>{" "}
            <ButtonPrev
              ref={prevRef}

              // style={{ width: "100px", height: "100px", background: "blue" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MdKeyboardArrowLeft />{" "}
              </div>
            </ButtonPrev>{" "}
            {/* <div
                ref={prevRef}
                style={{ width: "100px", height: "100px", background: "blue" }}
              ></div> */}
            <ButtonNext ref={nextRef}>
              {" "}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MdKeyboardArrowRight />
              </div>
            </ButtonNext>
            {/* <div
              className={com.story_arrow_left}
              onClick={previous}
              style={{ opacity: state === 1 ? "0" : "1" }}
            >
              <MdKeyboardArrowLeft />
            </div>
            <div
              className={com.story_arrow_right}
              onClick={next}
              style={{ opacity: state === 2 ? "0" : "1" }}
            >
              <MdKeyboardArrowRight />
            </div> */}
          </div>
        </section>
      </div>
    </>
  );
};
const ButtonPrev = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 50%;
  left: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonNext = styled.div`
  position: absolute;
  z-index: 1;

  width: 35px;
  height: 35px;
  display: block;
  background: #fff;
  top: 50%;
  right: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;

export default CompanyStory;
