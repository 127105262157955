import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
} from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import styles from "../../../styles/admin.module.css";
import Swal from "sweetalert2";
import { CommonAxios } from "../../CommonAxios";
import { textReplace } from "../../../lib/textReplace";
import { ramdomASCII } from "../../../lib/radomASCII";
import axios from "axios";
function CompanyCustomTable({
  columns,
  data,
  options,
  setOptions,
  options2,
  setOptions2,
  arrayList,
  setArrayList,
  setSendList,
  banSw,
  setBanSw,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    selectedFlatRows,
    prepareRow,
    //   canPreviousPage,
    //   canNextPage,
    pageOptions,
    pageCount, // 총 페이지
    gotoPage,
    //   nextPage,
    //   previousPage,
    //   setPageSize,
    state: { pageIndex, pageSize }, // pageIndex : 현재 페이지( 0 ~ 총페이지-1 ex) 1페이지 : 0 )
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // getToggleAllPageRowsSelectedProps : 페이지에 보여지는 row 전체 선택
          // getToggleAllRowsSelectedProps : 모든 row 전체 선택
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  //체크박스 옵션
  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            style={{ width: "32px" }}
          />
        </>
      );
    }
  );

  const btn_send = () => {
    // console.log(selectedFlatRows);
  };

  const [versionCheck, setVersionCheck] = useState("p");

  const changeValue = (e) => {
    setVersionCheck(e.target.value);
  };

  const [text1, setText1] = useState("");
  const [text11, setText11] = useState("");

  const [text2, setText2] = useState("");
  const [text22, setText22] = useState("");
  const [text3, setText3] = useState("");
  const [text33, setText33] = useState("");
  const [text4, setText4] = useState("");
  const [text44, setText44] = useState("");

  const [text5, setText5] = useState("");
  const [text55, setText55] = useState("");
  const [text6, setText6] = useState("");
  const [text66, setText66] = useState("");

  const [text7, setText7] = useState("");
  const [text77, setText77] = useState("");
  const [text8, setText8] = useState("");
  const [text88, setText88] = useState("");

  const [checkYn, setCheckYn] = useState("");
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  useEffect(() => {
    if (data.length > 0) {
      if (data[0].intro_page !== "4") {
        if (data[0].intro_img_yn) {
          setCheckYn(data[0].intro_img_yn);
        }
        if (data[0].intro_strong) {
          setText1(data[0].intro_strong);
        }
        if (data[0].intro_text) {
          setText2(data[0].intro_text);
        }
        if (data[0].intro_subject_1) {
          setText3(data[0].intro_subject_1);
        }
        if (data[0].intro_content_1) {
          setText4(data[0].intro_content_1);
        }
        if (data[0].intro_subject_2) {
          setText5(data[0].intro_subject_2);
        }
        if (data[0].intro_content_2) {
          setText6(data[0].intro_content_2);
        }
        if (data[0].intro_title) {
          setText7(data[0].intro_title);
        }
      } else {
        if (data[0].intro_img_yn) {
          setCheckYn(data[0].intro_img_yn);
        }
        if (data[0].banner_text) {
          setText2(
            data[0].banner_text.replaceAll("<p>", "").replaceAll("</p>", "")
          );
        } else {
          setText2("");
        }
        if (data[0].banner_text2) {
          setText3(
            data[0].banner_text2
              .replaceAll("<span>", "")
              .replaceAll("</span>", "")
          );
        } else {
          setText3("");
        }
      }
    }
  }, [banSw, versionCheck]);
  const onText1 = useCallback((e) => {
    setText1(e.target.value);
    setText11(e.target.value);
  }, []);
  const onText2 = useCallback((e) => {
    setText2(e.target.value);
    setText22(e.target.value);
  }, []);
  const onText3 = useCallback((e) => {
    setText3(e.target.value);
    setText33(e.target.value);
  }, []);
  const onText4 = useCallback((e) => {
    setText4(e.target.value);
    setText44(e.target.value);
  }, []);

  const onText5 = useCallback((e) => {
    setText5(e.target.value);
    setText55(e.target.value);
  }, []);
  const onText6 = useCallback((e) => {
    setText6(e.target.value);
    setText66(e.target.value);
  }, []);
  const onText7 = useCallback((e) => {
    setText7(e.target.value);
    setText77(e.target.value);
  }, []);
  const [imageFile, setImageFile] = useState("");
  const [imageFile2, setImageFile2] = useState("");
  const [imageFile3, setImageFile3] = useState("");
  const [imageFile4, setImageFile4] = useState("");

  const [imageFileN, setImageFileN] = useState("");
  const [imageFileN2, setImageFileN2] = useState("");
  const [imageFileN3, setImageFileN3] = useState("");
  const [imageFileN4, setImageFileN4] = useState("");

  const [imageFileNBefore, setImageFileNBefore] = useState("");
  const [imageFileN2Before, setImageFileN2Before] = useState("");
  const [imageFileN3Before, setImageFileN3Before] = useState("");
  const [imageFileN4Before, setImageFileN4Before] = useState("");

  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN(`${result}.${imgExtension[1]}`);
    // if (versionCheck === "p") {
    //   setImageFileN(`banner_${data[0].no}.${imgExtension[1]}`);
    // }

    // if (versionCheck === "m") {
    //   setImageFileN(`banner_m_${data[0].no}.${imgExtension[1]}`);
    // }

    setImageFile(file);
  };

  const inpChange2 = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");

    if (file[0] === undefined) {
      return;
    }
    if (imgExtension[1] !== "png" && imgExtension[1] !== "jpg") {
      Swal.fire({
        title: "업로드 불가",
        text: "png,jpg 확장자 파일만 올려주세요.",

        confirmButtonText: "확인",
      });
      return;
    }

    const { result } = ramdomASCII(10);
    setImageFileN2(`${result}.${imgExtension[1]}`);
    // if (versionCheck === "p") {
    //   setImageFileN(`banner_${data[0].no}.${imgExtension[1]}`);
    // }

    // if (versionCheck === "m") {
    //   setImageFileN(`banner_m_${data[0].no}.${imgExtension[1]}`);
    // }

    setImageFile2(file);
  };

  useEffect(() => {
    preview(); // 미리보기
  }, [imageFile]);
  useEffect(() => {
    preview2(); // 미리보기
  }, [imageFile2]);

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };
  const preview2 = () => {
    if (!imageFile2) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      setBgImg2(reader.result);
      // setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile2[0]);
  };
  const [bgImg, setBgImg] = useState("");
  const [bgImg2, setBgImg2] = useState("");
  const textReset = () => {
    setText1("");
    setText11("");
    setText2("");
    setText22("");
    setText3("");
    setText33("");
    setText4("");
    setText44("");
    setText5("");
    setText55("");
    setText6("");
    setText66("");
    setText7("");
    setText77("");
    setBgImg("");
    setBgImg2("");
    setCheckYn("");
  };

  const btn_text_save = () => {
    Swal.fire({
      title: "변경 진행",
      text: "위 문구로 변경하시겠습니까?",

      confirmButtonText: "확인",
      cancelButtonText: "취소",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (options === 3) {
          return Swal.fire({
            title: "작성 불가",
            text: "스토어는 문구를 작성하실 수 없습니다.",

            confirmButtonText: "확인",
          });
        }

        if (options2 === 1) {
          if (!text11 && !text22 && !text33 && !text44 && !text55 && !text66) {
            return Swal.fire({
              // title: "",
              text: "변경할 문구가 없습니다.",

              confirmButtonText: "확인",
            });
          }
        }
        if (options2 === 2) {
          if (!text11 && !text22 && !text33 && !text44 && !text55 && !checkYn) {
            return Swal.fire({
              // title: "",
              text: "변경할 문구가 없습니다.",

              confirmButtonText: "확인",
            });
          }
        }
        if (options2 === 3) {
          if (
            !text11 &&
            !text22 &&
            !text33 &&
            !text44 &&
            !text55 &&
            !text66 &&
            !text77 &&
            !checkYn
          ) {
            return Swal.fire({
              // title: "",
              text: "변경할 문구가 없습니다.",

              confirmButtonText: "확인",
            });
          }
        }
        if (options2 === 4) {
          if (!text11 && !text22 && !text33 && !text44 && !text77 && !checkYn) {
            return Swal.fire({
              // title: "",
              text: "변경할 문구가 없습니다.",

              confirmButtonText: "확인",
            });
          }
        }
        if (options2 === 5 || options2 === 6 || options2 === 9) {
          if (!text11 && !text22 && !text77) {
            return Swal.fire({
              // title: "",
              text: "변경할 문구가 없습니다.",

              confirmButtonText: "확인",
            });
          }
        }
        if (options2 === 7 || options2 === 8) {
          if (!text33 && !text44 && !text55 && !text66) {
            return Swal.fire({
              // title: "",
              text: "변경할 문구가 없습니다.",

              confirmButtonText: "확인",
            });
          }
        }

        if (options2 === 2 || options2 === 3) {
          if (checkYn !== "N") {
            return Swal.fire({
              // title: "",
              text: "텍스트 방식으로 체크해주시길 바랍니다.",

              confirmButtonText: "확인",
            });
          }
        }
        let string = "";

        if (text11) {
          string += `intro_strong = '${textReplace(text11)}',`;
        }

        if (text22) {
          string += `intro_text = '${textReplace(text22)}',`;
        }

        if (text33) {
          string += `intro_subject_1 = '${textReplace(text33)}',`;
        }
        if (text44) {
          string += `intro_content_1 = '${textReplace(text44)}',`;
        }
        if (text55) {
          string += `intro_subject_2 = '${textReplace(text55)}',`;
        }
        if (text66) {
          string += `intro_content_2 = '${textReplace(text66)}',`;
        }
        if (text77) {
          string += `intro_title = '${textReplace(text77)}',`;
        }
        string += `intro_img_yn = 'N',`;
        let sendData = {
          no: data[0].no,
          st: string.slice(0, -1),
          // intro_strong: "",
          // intro_text: "",
          // intro_subject_1: "",
          // intro_content_1: "",
          // intro_subject_2: "",
          // intro_content_2: "",
        };

        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/admin_update_intro_text",
          sendData,
          function (result) {
            if (result.messageinfo.state == "ok") {
              let sendData2 = {
                type: options,
                no: options2,
              };
              CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin_select_intro",
                sendData2,
                function (result) {
                  if (result.messageinfo.state == "ok") {
                    Swal.fire({
                      title: "변경 완료",
                      // text: "변경할 문구가 없습니다.",

                      confirmButtonText: "확인",
                    });
                    setArrayList(result.messageinfo.message);
                    setBanSw(true);
                  } else {
                    setBanSw(true);
                  }
                }
              );

              // setArrayList(result.messageinfo.message);
              // setBanSw(true);
            } else {
              // setBanSw(true);
            }
          }
        );
      }
    });
  };
  const [imgSw, setImgSw] = useState(false);
  const btn_img_save = () => {
    if (
      options2 !== 1 &&
      options2 !== 2 &&
      options2 !== 3 &&
      options2 !== 4 &&
      options2 !== 5 &&
      options2 !== 6 &&
      options2 !== 7 &&
      options2 !== 8
    ) {
      return Swal.fire({
        // title: "",
        text: "이미지 변경이 불가능한 페이지입니다.",

        confirmButtonText: "확인",
      });
    }

    if (
      options2 === 2 ||
      options2 === 3 ||
      options2 === 4 ||
      options2 === 5 ||
      options2 === 6
    ) {
      if (checkYn !== "Y") {
        return Swal.fire({
          // title: "",
          text: "이미지 방식으로 체크해주시길 바랍니다.",

          confirmButtonText: "확인",
        });
      }
    }
    Swal.fire({
      title: "변경 진행",
      text: "위 이미지로 변경하시겠습니까?",

      confirmButtonText: "확인",
      cancelButtonText: "취소",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (
          options2 === 2 ||
          options2 === 3 ||
          options2 === 4 ||
          options2 === 5 ||
          options2 === 6
        ) {
          if (!imageFile[0] && !imageFile2[0] && !checkYn) {
            return Swal.fire({
              // title: "",
              text: "변경할 이미지가 없습니다.",

              confirmButtonText: "확인",
            });
          }
        } else {
          if (!imageFile[0] && !imageFile2[0]) {
            return Swal.fire({
              // title: "",
              text: "변경할 이미지가 없습니다.",

              confirmButtonText: "확인",
            });
          }
        }
        let imgIf = "";
        let beforImg = "";
        let beforImg2 = "";

        const formData = new FormData();
        formData.append("no", data[0].no);
        // formData.append("filename", imageFileN);

        // formData.append("fileBefore", beforImg);
        formData.append("folder", "introduce");
        // formData.append("file", imageFile[0]);

        if (imageFile) {
          formData.append("filename", imageFileN);
          formData.append("fileBefore", data[0].intro_img_1);
          formData.append("file", imageFile[0]);
          imgIf += `intro_img_1 = '${imageFileN}',`;
        }

        if (imageFile2) {
          formData.append("filename", imageFileN2);
          formData.append("fileBefore", data[0].intro_img_2);
          formData.append("file", imageFile2[0]);
          imgIf += `intro_img_2 = '${imageFileN2}',`;
        }
        imgIf += `intro_img_yn = 'Y',`;
        formData.append("fileif", imgIf.slice(0, -1));

        axios
          .post(
            process.env.REACT_APP_HOSTDONAME + "/api/admin_upload_intro",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            if (response.data.messageinfo.state === "ok") {
              let sendData2 = {
                type: options,
                no: options2,
              };
              CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin_select_intro",
                sendData2,
                function (result) {
                  if (result.messageinfo.state == "ok") {
                    Swal.fire({
                      title: "변경 완료",
                      // text: "변경할 문구가 없습니다.",

                      confirmButtonText: "확인",
                    });
                    setArrayList(result.messageinfo.message);
                    setBanSw(true);
                  } else {
                    setBanSw(true);
                  }
                }
              );
              // setUploadImage(
              //   uploadImage.concat({
              //     id: uploadImageRef.current++,
              //     imageUrl: `/classImage/${classinfo}/${chapterNum}/${path}/${fileNum}`,
              //     imagename: fileNum,
              //   })
              // );
              // setImageText(imageText + fileNum + ",");
              // // 응답 처리
              // setSpinnerView(false);
            }
          })
          .catch((error) => {
            // 예외 처리
          });
      }
    });
  };

  const change_img_yn = (data) => {
    setCheckYn(data);
  };
  return (
    <>
      <div className={styles.user_header} style={{ marginBottom: "4px" }}>
        <div className={styles.user_option}>
          <div
            className={
              options == 1 ? `${styles.all} ${styles.clicked}` : styles.all
            }
            onClick={() => {
              setOptions(1);
              setOptions2(1);
              textReset();
            }}
          >
            회사 소개
          </div>
          <div
            className={
              options == 2
                ? `${styles.noraml} ${styles.clicked}`
                : styles.noraml
            }
            onClick={() => {
              setOptions(2);
              setOptions2(3);
              textReset();
            }}
          >
            자세히 알아보기
          </div>
        </div>
        {/* <div className={styles.user_button}>
          <button className={styles.btn_sns_send} onClick={btn_send}>
            문자전송
          </button>
        </div> */}
      </div>{" "}
      {!banSw && (
        <div className={`${styles.user_header} ${styles.user_header2}`}>
          <div className={styles.user_option}>
            <div className={styles.noraml}></div>
          </div>
        </div>
      )}{" "}
      {banSw && options == 4 && (
        <div className={`${styles.user_header} ${styles.user_header2}`}>
          <div className={styles.user_option}>
            <div className={styles.noraml}></div>
          </div>
        </div>
      )}
      {banSw && options == 1 && (
        <div className={`${styles.user_header} ${styles.user_header2}`}>
          <div className={styles.user_option}>
            <div
              className={
                options2 == 1
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(1);
                textReset();
              }}
            >
              <span>첫번째 영역</span>
            </div>
            <div
              className={
                options2 == 2
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(2);
                textReset();
              }}
            >
              {" "}
              <span>두번째 영역</span>
            </div>
          </div>
          {/* <div className={styles.user_button}>
          <button className={styles.btn_sns_send} onClick={btn_send}>
            문자전송
          </button>
        </div> */}
        </div>
      )}
      {banSw && options == 2 && (
        <div className={`${styles.user_header} ${styles.user_header2}`}>
          <div className={styles.user_option}>
            <div
              className={
                options2 == 3
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(3);
                textReset();
              }}
            >
              <span>첫번째 영역</span>
            </div>
            <div
              className={
                options2 == 4
                  ? `${styles.noraml} ${styles.clicked}`
                  : styles.noraml
              }
              onClick={() => {
                setOptions2(4);
                textReset();
              }}
            >
              {" "}
              <span>두번째 영역</span>
            </div>
            <div
              className={
                options2 == 5
                  ? `${styles.develop} ${styles.clicked}`
                  : styles.develop
              }
              onClick={() => {
                setOptions2(5);
                textReset();
              }}
            >
              {" "}
              <span>세번째 영역</span>
            </div>{" "}
            <div
              className={
                options2 == 6
                  ? `${styles.develop} ${styles.clicked}`
                  : styles.develop
              }
              onClick={() => {
                setOptions2(6);
                textReset();
              }}
            >
              {" "}
              <span>네번째 영역</span>
            </div>
            <div
              className={
                options2 == 7
                  ? `${styles.develop} ${styles.clicked}`
                  : styles.develop
              }
              onClick={() => {
                setOptions2(7);
                textReset();
              }}
            >
              {" "}
              <span>다섯번째 영역</span>
            </div>
            <div
              className={
                options2 == 8
                  ? `${styles.develop} ${styles.clicked}`
                  : styles.develop
              }
              onClick={() => {
                setOptions2(8);
                textReset();
              }}
            >
              {" "}
              <span>여섯번째 영역</span>
            </div>
            <div
              className={
                options2 == 9
                  ? `${styles.develop} ${styles.clicked}`
                  : styles.develop
              }
              onClick={() => {
                setOptions2(9);
                textReset();
              }}
            >
              {" "}
              <span>일곱번째 영역</span>
            </div>
          </div>
          {/* <div className={styles.user_button}>
          <button className={styles.btn_sns_send} onClick={btn_send}>
            문자전송
          </button>
        </div> */}
        </div>
      )}{" "}
      <div className={styles.admin_banner_view}>
        <div className={styles.admin_banner_inner}>
          {banSw && options !== 2 && options2 === 1 && (
            <>
              <div
                className={styles.company_back_wrap}
                style={{
                  background: bgImg
                    ? `url(${bgImg}) no-repeat 100%`
                    : `url(/introduce/${data[0].no}/${data[0].intro_img_1}) no-repeat  `,
                  backgroundSize: "100%",
                  overflow: "hidden",
                }}
              >
                <div style={{ width: "613.33333333336px", margin: "0 auto" }}>
                  <div>
                    <section className={styles.infomation}>
                      <div className={styles.info_text}>
                        {data[0].intro_strong && (
                          <strong
                            dangerouslySetInnerHTML={{
                              __html: data[0].intro_strong.replaceAll(
                                /(\n|\r\n)/g,
                                "<br/>"
                              ),
                            }}
                          ></strong>
                        )}
                        {data[0].intro_text && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data[0].intro_text.replaceAll(
                                /(\n|\r\n)/g,
                                "<br/>"
                              ),
                            }}
                          ></p>
                        )}
                      </div>
                      <div className={styles.info_num}>
                        <ul>
                          <li>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_subject_1.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></span>
                            <em
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_content_1.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></em>
                          </li>{" "}
                          <li>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_subject_2.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></span>
                            <em
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_content_2.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></em>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <span>[with값 1920px 기준 - 현재 사이트 화면]</span>
              <div
                className={styles.company_back_wrap}
                style={{
                  background: bgImg
                    ? `url(${bgImg}) no-repeat 100%`
                    : `url(/introduce/${data[0].no}/${data[0].intro_img_1}) no-repeat  `,
                  backgroundSize: "100%",
                  overflow: "hidden",
                }}
              >
                <div style={{ width: "613.33333333336px", margin: "0 auto" }}>
                  <div>
                    <section className={styles.infomation}>
                      <div className={styles.info_text}>
                        {text11 && (
                          <strong
                            dangerouslySetInnerHTML={{
                              __html: text11.replaceAll(/(\n|\r\n)/g, "<br/>"),
                            }}
                          ></strong>
                        )}
                        {!text11 && data[0].intro_strong && (
                          <strong
                            dangerouslySetInnerHTML={{
                              __html: data[0].intro_strong.replaceAll(
                                /(\n|\r\n)/g,
                                "<br/>"
                              ),
                            }}
                          ></strong>
                        )}{" "}
                        {text22 && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: text22.replaceAll(/(\n|\r\n)/g, "<br/>"),
                            }}
                          ></p>
                        )}
                        {!text22 && data[0].intro_text && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data[0].intro_text.replaceAll(
                                /(\n|\r\n)/g,
                                "<br/>"
                              ),
                            }}
                          ></p>
                        )}
                      </div>
                      <div className={styles.info_num}>
                        <ul>
                          <li>
                            {text33 && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: text33.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></span>
                            )}
                            {!text33 && data[0].intro_subject_1 && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_subject_1.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></span>
                            )}
                            {text44 && (
                              <em
                                dangerouslySetInnerHTML={{
                                  __html: text44.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></em>
                            )}{" "}
                            {!text44 && data[0].intro_content_1 && (
                              <em
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_content_1.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></em>
                            )}
                          </li>{" "}
                          <li>
                            {text55 && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: text55.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></span>
                            )}
                            {!text55 && data[0].intro_subject_2 && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_subject_2.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></span>
                            )}
                            {text66 && (
                              <em
                                dangerouslySetInnerHTML={{
                                  __html: text66.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></em>
                            )}{" "}
                            {!text66 && data[0].intro_content_2 && (
                              <em
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_content_2.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></em>
                            )}
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>{" "}
                </div>{" "}
              </div>{" "}
              <span>[with값 1920px 기준 - 미리 보기 화면]</span>
              {/* <div className={styles.admin_banner_change_button}>
              <button>미리보기</button>
            </div> */}
              <div
                className={`${styles.admin_banner_change} ${styles.admin_intro_change}`}
              >
                <div className={styles.admin_banner_left}>
                  <h4>문구 입력</h4>
                  <div
                    className={`${styles.admin_bnner_change_in} ${styles.admin_bnner_change_detai}`}
                  >
                    <p>큰 타이틀</p>
                    <textarea onChange={onText1} value={text1}></textarea>{" "}
                    <p>설명글</p>
                    <textarea onChange={onText2} value={text2}></textarea>
                    <div className={styles.admin_intro_input_div}>
                      <div>
                        <p>서브 타이틀 1</p>
                        <input type="text" onChange={onText3} value={text3} />
                      </div>
                      <div>
                        <p>서브 내용 1</p>
                        <input
                          type="text"
                          onChange={onText4}
                          value={text4}
                        />{" "}
                      </div>
                    </div>
                    {options2 === 1 && (
                      <div className={styles.admin_intro_input_div}>
                        <div>
                          <p>서브 타이틀 2</p>
                          <input type="text" onChange={onText5} value={text5} />
                        </div>
                        <div>
                          <p>서브 내용 2</p>
                          <input
                            type="text"
                            onChange={onText6}
                            value={text6}
                          />{" "}
                        </div>
                      </div>
                    )}
                    {options2 !== 1 && (
                      <div className={styles.admin_intro_input_div}>
                        <div>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                        <div>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    )}
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_text_save}>저장</button>
                    </div>
                  </div>
                </div>
                <div className={styles.admin_banner_right}>
                  <h4>배경 이미지 업로드</h4>{" "}
                  <div className={styles.admin_bnner_change_in}>
                    <div className={styles.admin_banner_img_box}>
                      <input
                        type="file"
                        onChange={inpChange}
                        id="abc"
                        style={{ display: "none" }}
                      />

                      {/* <input
                    className={styles.info_policy_inp}
                    value={imageFileN}
                  /> */}
                      {options2 === 1 && (
                        <label for="abc" style={{ cursor: "pointer" }}>
                          이미지 업로드
                        </label>
                      )}
                      {options2 !== 1 && (
                        <label style={{ cursor: "pointer" }}>
                          이미지 업로드 불가
                        </label>
                      )}
                    </div>{" "}
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_img_save}>저장</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}{" "}
          {banSw && options !== 2 && options2 === 2 && (
            <>
              <div
                className={styles.company_back_wrap}
                style={{
                  overflow: "hidden",
                  height: data[0].intro_img_yn === "Y" ? "auto" : "355px",
                }}
              >
                <div style={{ width: "613.33333333336px", margin: "0 auto" }}>
                  {data[0].intro_img_yn === "N" && (
                    <div>
                      <section className={styles.infomation}>
                        <div className={styles.info_text}>
                          {data[0].intro_strong && (
                            <strong
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_strong.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></strong>
                          )}
                          {data[0].intro_text && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_text.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></p>
                          )}
                        </div>
                        <div className={styles.info_num}>
                          <ul>
                            <li>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_subject_1.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></span>
                              <em
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_content_1.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></em>
                            </li>{" "}
                            <li>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_subject_2.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></span>
                              <em
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_content_2.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></em>
                            </li>
                          </ul>
                        </div>
                      </section>
                    </div>
                  )}{" "}
                  {data[0].intro_img_yn === "Y" && (
                    <div style={{ width: "100%" }}>
                      <img
                        src={`/introduce/${data[0].no}/${data[0].intro_img_1}`}
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                </div>{" "}
              </div>{" "}
              <span>[with값 1920px 기준 - 현재 사이트 화면]</span>
              <div
                className={styles.company_back_wrap}
                style={{
                  overflow: "hidden",
                  height: checkYn === "Y" ? "auto" : "355px",
                }}
              >
                <div style={{ width: "613.33333333336px", margin: "0 auto" }}>
                  {" "}
                  {checkYn === "N" && (
                    <div>
                      <section className={styles.infomation}>
                        <div className={styles.info_text}>
                          {text11 && (
                            <strong
                              dangerouslySetInnerHTML={{
                                __html: text11.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></strong>
                          )}
                          {!text11 && data[0].intro_strong && (
                            <strong
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_strong.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></strong>
                          )}{" "}
                          {text22 && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: text22.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></p>
                          )}
                          {!text22 && data[0].intro_text && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_text.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></p>
                          )}
                        </div>
                        <div className={styles.info_num}>
                          <ul>
                            <li>
                              {text33 && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: text33.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></span>
                              )}
                              {!text33 && data[0].intro_subject_1 && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_subject_1.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></span>
                              )}
                              {text44 && (
                                <em
                                  dangerouslySetInnerHTML={{
                                    __html: text44.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></em>
                              )}{" "}
                              {!text44 && data[0].intro_content_1 && (
                                <em
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_content_1.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></em>
                              )}
                            </li>{" "}
                            <li>
                              {text55 && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: text55.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></span>
                              )}
                              {!text55 && data[0].intro_subject_2 && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_subject_2.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></span>
                              )}
                              {text66 && (
                                <em
                                  dangerouslySetInnerHTML={{
                                    __html: text66.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></em>
                              )}{" "}
                              {!text66 && data[0].intro_content_2 && (
                                <em
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_content_2.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></em>
                              )}
                            </li>
                          </ul>
                        </div>
                      </section>
                    </div>
                  )}
                  {checkYn === "Y" && (
                    <div style={{ width: "100%" }}>
                      <img
                        src={
                          bgImg
                            ? bgImg
                            : `/introduce/${data[0].no}/${data[0].intro_img_1}`
                        }
                        style={{ width: "100%" }}
                      />
                    </div>
                  )}
                </div>{" "}
              </div>{" "}
              <span>[with값 1920px 기준 - 미리 보기 화면]</span>
              {/* <div className={styles.admin_banner_change_button}>
              <button>미리보기</button>
            </div> */}
              <div className={styles.admin_intro_select_bottom}>
                <div className={styles.admin_intro_select_bottom_in}>
                  <h4>방식 선택</h4>
                  <div>
                    <label>
                      {" "}
                      <input
                        type="radio"
                        checked={checkYn === "N" ? true : false}
                        name="imgData"
                        onChange={() => change_img_yn("N")}
                      />{" "}
                      텍스트 방식
                    </label>
                    <label>
                      {" "}
                      <input
                        type="radio"
                        checked={checkYn === "Y" ? true : false}
                        name="imgData"
                        onChange={() => change_img_yn("Y")}
                      />{" "}
                      이미지 방식
                    </label>
                  </div>{" "}
                </div>
              </div>
              <div
                className={`${styles.admin_banner_change} ${styles.admin_intro_change}`}
              >
                <div className={styles.admin_banner_left}>
                  <h4>문구 입력</h4>
                  <div
                    className={`${styles.admin_bnner_change_in} ${styles.admin_bnner_change_detai}`}
                  >
                    <p>큰 타이틀</p>
                    <textarea onChange={onText1} value={text1}></textarea>{" "}
                    <p>설명글</p>
                    <textarea onChange={onText2} value={text2}></textarea>
                    <div className={styles.admin_intro_input_div}>
                      <div>
                        <p>서브 타이틀 1</p>
                        <input type="text" onChange={onText3} value={text3} />
                      </div>
                      <div>
                        <p>서브 내용 1</p>
                        <input
                          type="text"
                          onChange={onText4}
                          value={text4}
                        />{" "}
                      </div>
                    </div>
                    {options2 === 1 && (
                      <div className={styles.admin_intro_input_div}>
                        <div>
                          <p>서브 타이틀 2</p>
                          <input type="text" onChange={onText5} value={text5} />
                        </div>
                        <div>
                          <p>서브 내용 2</p>
                          <input
                            type="text"
                            onChange={onText6}
                            value={text6}
                          />{" "}
                        </div>
                      </div>
                    )}
                    {options2 !== 1 && (
                      <div className={styles.admin_intro_input_div}>
                        <div>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                        <div>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    )}
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_text_save}>저장</button>
                    </div>
                  </div>
                </div>
                <div className={styles.admin_banner_right}>
                  <h4>이미지 업로드</h4>{" "}
                  <div className={styles.admin_bnner_change_in}>
                    <div className={styles.admin_banner_img_box}>
                      <input
                        type="file"
                        onChange={inpChange}
                        id="abc"
                        style={{ display: "none" }}
                      />

                      {/* <input
                    className={styles.info_policy_inp}
                    value={imageFileN}
                  /> */}

                      <label for="abc" style={{ cursor: "pointer" }}>
                        이미지 업로드
                      </label>
                    </div>{" "}
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_img_save}>저장</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}{" "}
          {banSw && options !== 1 && (
            <>
              <div className={styles.intro_common_wrap}>
                {options2 !== 7 && options2 !== 8 && (
                  <div className={styles.intro_common}>
                    {data[0].intro_img_yn === "N" && (
                      <>
                        {/* <h2 className={styles.title}>Our Vision</h2> */}
                        {data[0].intro_title && (
                          <h2
                            dangerouslySetInnerHTML={{
                              __html: data[0].intro_title.replaceAll(
                                /(\n|\r\n)/g,
                                "<br/>"
                              ),
                            }}
                            className={styles.title}
                          ></h2>
                        )}{" "}
                        <div className={styles.intro_content}>
                          <div className={styles.content_1}>
                            {data[0].intro_strong && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_strong.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></p>
                            )}
                          </div>
                          <div className={styles.content_2}>
                            {data[0].intro_text && (
                              <div
                                className={styles.content_text}
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_text.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></div>
                            )}
                            <div className={styles.content_text_detail}>
                              {data[0].intro_subject_1 && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_subject_1.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></span>
                              )}

                              {data[0].intro_content_1 && (
                                <em
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_content_1.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></em>
                              )}
                            </div>
                            <div className={styles.content_text_detail}>
                              {data[0].intro_subject_2 && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_subject_2.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></span>
                              )}

                              {data[0].intro_content_2 && (
                                <em
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_content_2.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></em>
                              )}
                            </div>
                          </div>{" "}
                        </div>
                      </>
                    )}{" "}
                    {data[0].intro_img_yn === "Y" && (
                      <img
                        src={`/introduce/${data[0].no}/${data[0].intro_img_1}`}
                      />
                    )}
                  </div>
                )}

                {(options2 === 7 || options2 === 8) && (
                  <div
                    className={`${styles.intro_common} ${styles.intro_bottom_section}`}
                  >
                    <div className={styles.intro_content}>
                      <div className={styles.content_1}>
                        <div className={styles.content_bottom_detail}>
                          {data[0].intro_subject_1 && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_subject_1.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></span>
                          )}

                          {data[0].intro_content_1 && (
                            <em
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_content_1.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></em>
                          )}
                        </div>
                        <div
                          className={styles.intro_img_box}
                          // onClick={() => clickImg(img1, "허브팩토리 (앵천리)")}
                        >
                          {data[0].intro_img_1 && (
                            <img
                              src={`/introduce/${data[0].no}/${data[0].intro_img_1}`}
                            />
                          )}
                        </div>
                      </div>
                      <div className={styles.content_2}>
                        <div className={styles.content_bottom_detail}>
                          {data[0].intro_subject_2 && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_subject_2.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></span>
                          )}

                          {data[0].intro_content_2 && (
                            <em
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_content_2.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></em>
                          )}
                        </div>
                        <div
                          className={styles.intro_img_box}
                          // onClick={() => clickImg(img2, "M1팜 (창산리)")}
                        >
                          {" "}
                          {data[0].intro_img_2 && (
                            <img
                              src={`/introduce/${data[0].no}/${data[0].intro_img_2}`}
                            />
                          )}
                          {/* <img src={img2} /> */}
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                )}
              </div>
              <span>[with값 1920px 기준 - 현재 사이트 화면]</span>
              <div className={styles.intro_common_wrap}>
                {" "}
                {options2 !== 7 && options2 !== 8 && (
                  <div className={styles.intro_common}>
                    {checkYn === "N" && (
                      <>
                        {/* <h2 className={styles.title}>Our Vision</h2> */}
                        {text77 && (
                          <h2
                            dangerouslySetInnerHTML={{
                              __html: text77.replaceAll(/(\n|\r\n)/g, "<br/>"),
                            }}
                            className={styles.title}
                          ></h2>
                        )}{" "}
                        {!text77 && data[0].intro_title && (
                          <h2
                            dangerouslySetInnerHTML={{
                              __html: data[0].intro_title.replaceAll(
                                /(\n|\r\n)/g,
                                "<br/>"
                              ),
                            }}
                            className={styles.title}
                          ></h2>
                        )}{" "}
                        <div className={styles.intro_content}>
                          <div className={styles.content_1}>
                            {text11 && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: text11.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></p>
                            )}{" "}
                            {!text11 && data[0].intro_strong && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_strong.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></p>
                            )}
                          </div>
                          <div className={styles.content_2}>
                            {text22 && (
                              <div
                                className={styles.content_text}
                                dangerouslySetInnerHTML={{
                                  __html: text22.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></div>
                            )}{" "}
                            {!text22 && data[0].intro_text && (
                              <div
                                className={styles.content_text}
                                dangerouslySetInnerHTML={{
                                  __html: data[0].intro_text.replaceAll(
                                    /(\n|\r\n)/g,
                                    "<br/>"
                                  ),
                                }}
                              ></div>
                            )}
                            <div className={styles.content_text_detail}>
                              {text33 && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: text33.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></span>
                              )}
                              {!text33 && data[0].intro_subject_1 && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_subject_1.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></span>
                              )}

                              {text44 && (
                                <em
                                  dangerouslySetInnerHTML={{
                                    __html: text44.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></em>
                              )}
                              {!text44 && data[0].intro_content_1 && (
                                <em
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_content_1.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></em>
                              )}
                            </div>
                            <div className={styles.content_text_detail}>
                              {text55 && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: text55.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></span>
                              )}
                              {!text55 && data[0].intro_subject_2 && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_subject_2.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></span>
                              )}

                              {text66 && (
                                <em
                                  dangerouslySetInnerHTML={{
                                    __html: text66.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></em>
                              )}
                              {!text66 && data[0].intro_content_2 && (
                                <em
                                  dangerouslySetInnerHTML={{
                                    __html: data[0].intro_content_2.replaceAll(
                                      /(\n|\r\n)/g,
                                      "<br/>"
                                    ),
                                  }}
                                ></em>
                              )}
                            </div>
                          </div>{" "}
                        </div>
                      </>
                    )}{" "}
                    {checkYn === "Y" && (
                      <img
                        src={
                          bgImg
                            ? bgImg
                            : `/introduce/${data[0].no}/${data[0].intro_img_1}`
                        }
                        style={{ width: "100%" }}
                      />
                    )}
                  </div>
                )}
                {(options2 === 7 || options2 === 8) && (
                  <div
                    className={`${styles.intro_common} ${styles.intro_bottom_section}`}
                  >
                    <div className={styles.intro_content}>
                      <div className={styles.content_1}>
                        <div className={styles.content_bottom_detail}>
                          {text33 && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: text33.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></span>
                          )}
                          {!text33 && data[0].intro_subject_1 && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_subject_1.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></span>
                          )}
                          {text44 && (
                            <em
                              dangerouslySetInnerHTML={{
                                __html: text44.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></em>
                          )}
                          {!text44 && data[0].intro_content_1 && (
                            <em
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_content_1.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></em>
                          )}
                        </div>
                        <div
                          className={styles.intro_img_box}
                          // onClick={() => clickImg(img1, "허브팩토리 (앵천리)")}
                        >
                          {bgImg && <img src={bgImg} />}
                          {!bgImg && data[0].intro_img_1 && (
                            <img
                              src={`/introduce/${data[0].no}/${data[0].intro_img_1}`}
                            />
                          )}
                        </div>
                      </div>
                      <div className={styles.content_2}>
                        <div className={styles.content_bottom_detail}>
                          {text55 && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: text55.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></span>
                          )}{" "}
                          {!text55 && data[0].intro_subject_2 && (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_subject_2.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></span>
                          )}
                          {text66 && (
                            <em
                              dangerouslySetInnerHTML={{
                                __html: text66.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></em>
                          )}{" "}
                          {!text66 && data[0].intro_content_2 && (
                            <em
                              dangerouslySetInnerHTML={{
                                __html: data[0].intro_content_2.replaceAll(
                                  /(\n|\r\n)/g,
                                  "<br/>"
                                ),
                              }}
                            ></em>
                          )}
                        </div>
                        <div
                          className={styles.intro_img_box}
                          // onClick={() => clickImg(img2, "M1팜 (창산리)")}
                        >
                          {" "}
                          {bgImg2 && <img src={bgImg2} />}
                          {!bgImg2 && data[0].intro_img_2 && (
                            <img
                              src={`/introduce/${data[0].no}/${data[0].intro_img_2}`}
                            />
                          )}
                          {/* <img src={img2} /> */}
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                )}
              </div>
              <span>[with값 1920px 기준 - 미리 보기 화면]</span>
              {/* <div className={styles.admin_banner_change_button}>
              <button>미리보기</button>
            </div> */}{" "}
              {options2 !== 7 && options2 !== 8 && options2 !== 9 && (
                <div className={styles.admin_intro_select_bottom}>
                  <div className={styles.admin_intro_select_bottom_in}>
                    <h4>방식 선택</h4>
                    <div>
                      <label>
                        {" "}
                        <input
                          type="radio"
                          checked={checkYn === "N" ? true : false}
                          name="imgData"
                          onChange={() => change_img_yn("N")}
                        />{" "}
                        텍스트 방식
                      </label>
                      <label>
                        {" "}
                        <input
                          type="radio"
                          checked={checkYn === "Y" ? true : false}
                          name="imgData"
                          onChange={() => change_img_yn("Y")}
                        />{" "}
                        이미지 방식
                      </label>
                    </div>{" "}
                  </div>
                </div>
              )}
              <div
                className={`${styles.admin_banner_change} ${styles.admin_intro_change}`}
              >
                <div className={styles.admin_banner_left}>
                  <h4>문구 입력</h4>
                  <div
                    className={`${styles.admin_bnner_change_in} ${styles.admin_bnner_change_detai}`}
                  >
                    {" "}
                    {options2 !== 7 && options2 !== 8 && (
                      <>
                        <p>영역 타이틀</p>
                        <input type="text" onChange={onText7} value={text7} />
                        <p>큰 타이틀</p>
                        <textarea
                          onChange={onText1}
                          value={text1}
                        ></textarea>{" "}
                        <p>설명글</p>
                        <textarea
                          onChange={onText2}
                          value={text2}
                        ></textarea>{" "}
                      </>
                    )}{" "}
                    {(options2 === 7 || options2 === 8) && (
                      <>
                        <div className={styles.admin_intro_input_div}>
                          <div>
                            <p>서브 타이틀 1</p>
                            <input
                              type="text"
                              onChange={onText3}
                              value={text3}
                            />
                          </div>
                          <div>
                            <p>서브 내용 1</p>
                            <input
                              type="text"
                              onChange={onText4}
                              value={text4}
                            />{" "}
                          </div>
                        </div>{" "}
                        <div className={styles.admin_intro_input_div}>
                          <div>
                            <p>서브 타이틀 2</p>
                            <input
                              type="text"
                              onChange={onText5}
                              value={text5}
                            />
                          </div>
                          <div>
                            <p>서브 내용 2</p>
                            <input
                              type="text"
                              onChange={onText6}
                              value={text6}
                            />{" "}
                          </div>
                        </div>{" "}
                        <div className={styles.admin_intro_input_div}>
                          <div>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                          </div>
                          <div>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                          </div>
                        </div>{" "}
                        <div className={styles.admin_intro_input_div}>
                          <div>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                          </div>
                          <div>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                          </div>
                        </div>{" "}
                        <div className={styles.admin_intro_input_div}>
                          <div>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                          </div>
                          <div>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                          </div>
                        </div>
                      </>
                    )}
                    {(options2 === 3 || options2 === 4) && (
                      <div className={styles.admin_intro_input_div}>
                        <div>
                          <p>서브 타이틀 1</p>
                          <input type="text" onChange={onText3} value={text3} />
                        </div>
                        <div>
                          <p>서브 내용 1</p>
                          <input
                            type="text"
                            onChange={onText4}
                            value={text4}
                          />{" "}
                        </div>
                      </div>
                    )}{" "}
                    {options2 !== 3 && options2 !== 4 && (
                      <div className={styles.admin_intro_input_div}>
                        <div>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                        <div>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    )}
                    {options2 === 3 && (
                      <div className={styles.admin_intro_input_div}>
                        <div>
                          <p>서브 타이틀 2</p>
                          <input type="text" onChange={onText5} value={text5} />
                        </div>
                        <div>
                          <p>서브 내용 2</p>
                          <input
                            type="text"
                            onChange={onText6}
                            value={text6}
                          />{" "}
                        </div>
                      </div>
                    )}{" "}
                    {options2 !== 3 && (
                      <div className={styles.admin_intro_input_div}>
                        <div>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                        <div>
                          <p>&nbsp;</p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    )}
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_text_save}>저장</button>
                    </div>
                  </div>
                </div>
                <div className={styles.admin_banner_right}>
                  <h4>이미지 업로드</h4>{" "}
                  <div className={styles.admin_bnner_change_in}>
                    <div className={styles.admin_banner_img_box}>
                      <input
                        type="file"
                        onChange={inpChange}
                        id="abc"
                        style={{ display: "none" }}
                      />{" "}
                      <input
                        type="file"
                        onChange={inpChange2}
                        id="abc2"
                        style={{ display: "none" }}
                      />
                      {/* <input
                    className={styles.info_policy_inp}
                    value={imageFileN}
                  /> */}
                      {options2 === 1 && (
                        <label for="abc" style={{ cursor: "pointer" }}>
                          이미지 업로드
                        </label>
                      )}{" "}
                      {options2 === 9 && (
                        <label style={{ cursor: "pointer" }}>
                          이미지 업로드 불가
                        </label>
                      )}
                      {options2 !== 1 &&
                        options2 !== 7 &&
                        options2 !== 8 &&
                        options2 !== 9 && (
                          <label for="abc" style={{ cursor: "pointer" }}>
                            이미지 업로드
                          </label>
                        )}{" "}
                      {(options2 === 7 || options2 === 8) && (
                        <>
                          <label for="abc" style={{ cursor: "pointer" }}>
                            첫번째 이미지 업로드
                          </label>
                          <label for="abc2" style={{ cursor: "pointer" }}>
                            두번째 이미지 업로드
                          </label>
                        </>
                      )}
                    </div>{" "}
                    <div className={styles.btn_change}>
                      {" "}
                      <button onClick={btn_img_save}>저장</button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}{" "}
        </div>
      </div>
    </>
  );
}

export default CompanyCustomTable;
