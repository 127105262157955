import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import InvestContentStatus from "./detail/InvestContentStatus";
import InvestTopSilder from "./detail/InvestTopSilder";
import InvestQna from "./detail/InvestQna";
import InvestNews from "./detail/InvestNews";
import InvestStory from "./detail/InvestStory";
import InvestReview from "./detail/InvestReview";
import ProcessSection_1 from "./detail/ProcessSection_1";
import ProcessSection_2 from "./detail/ProcessSection_2";
import ProcessSection_3 from "./detail/ProcessSection_3";
import ProcessSection_4 from "./detail/ProcessSection_4";
import { useState, useEffect } from "react";
import { MoveAlert, MoveAlert2, WarringAlert } from "../alert/Alert";
import { useParams } from "react-router";
import ProcessInvest from "./detail/ProcessInvest";
import InvestTopSilderTwo from "./detail/InvestTopSilderTwo";
const InvestJoinDetail = () => {
  const [warringAlert, setWarringAlert] = useState(false);
  const [content, setContent] = useState("");
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const { id } = useParams();
  const [sw, setSw] = useState(false);
  const [popup, setPopup] = useState([]);
  useEffect(() => {
    let sendData = {
      no: id,
    };
    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/invest_select_product",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
          setData1(result.messageinfo.message_data2);
          setPopup(result.messageinfo.message_data3);
          setSw(true);
        } else {
        }
      }
    );
  }, []);

  const [investPop, setInvestPop] = useState(false);
  const [popupResult, setPopupResult] = useState(false);
  const InvestPopup = ({ setInvestPop, setPopupResult }) => {
    const btn_alert_yes = () => {
      setPopupResult(true);
      setInvestPop(false);
      // setWarringAlert(false);
    };
    const btn_alert_cancelF = () => {
      setInvestPop(false);
    };
    return (
      <>
        {" "}
        <div className="alert_div_back_back"></div>
        <div className="alert_div_back">
          <div className="alert_div_wrap2">
            <div className="alert_div_inner">
              <div className="alert_div_content" style={{ textAlign: "left" }}>
                <div
                  style={{ marginBottom: "7px", width: "90%" }}
                  dangerouslySetInnerHTML={{
                    __html: popup[0].popup_strong.replaceAll(
                      /(\n|\r\n)/g,
                      "<br/>"
                    ),
                  }}
                ></div>
                <div>
                  <div
                    // style={{ marginBottom: "7px", width: "90%" }}
                    dangerouslySetInnerHTML={{
                      __html: popup[0].popup_step_1.replaceAll(
                        /(\n|\r\n)/g,
                        "<br/>"
                      ),
                    }}
                  ></div>
                </div>
                <div>
                  <div
                    // style={{ marginBottom: "7px", width: "90%" }}
                    dangerouslySetInnerHTML={{
                      __html: popup[0].popup_step_2.replaceAll(
                        /(\n|\r\n)/g,
                        "<br/>"
                      ),
                    }}
                  ></div>
                </div>
                <p>가입 신청을 하시겠습니까?</p>
              </div>{" "}
              <div className="btn_box">
                <button className="btn_alert" onClick={btn_alert_yes}>
                  확인
                </button>
                <button
                  className="btn_alert_cancel"
                  onClick={btn_alert_cancelF}
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const [moveAlert, setMoveAlert] = useState(false);
  const [movePage, setMovePage] = useState("");
  const [moveTitle, setMoveTitle] = useState("");
  const [moveData1, setMoveData1] = useState(0);
  const [moveData2, setMoveData2] = useState(0);
  return (
    <>
      {" "}
      {sw && investPop && (
        <InvestPopup
          setInvestPop={setInvestPop}
          setPopupResult={setPopupResult}
        />
      )}
      {moveAlert && (
        <MoveAlert2
          moveTitle={"신청 안내"}
          setMoveAlert={setMoveAlert}
          movePage={"/my/invest/cancel"}
          moveData1={moveData1}
          moveData2={moveData2}
        />
      )}
      {warringAlert && (
        <WarringAlert content={content} setWarringAlert={setWarringAlert} />
      )}
      <InvestTopSilderTwo data={data[0]} />
      <div className="common-wrap">
        <div className="common-inner-wrap">
          {/* <ProcessSection_1 data={data[0]} /> */}
          {sw && (
            <ProcessInvest
              data={data[0]}
              data1={data1}
              popupResult={popupResult}
              setWarringAlert={setWarringAlert}
              setInvestPop={setInvestPop}
              setContent={setContent}
              setMoveAlert={setMoveAlert}
              setMoveData1={setMoveData1}
              setMoveData2={setMoveData2}
              setPopupResult={setPopupResult}
            />
          )}
          {/* <ProcessSection_3
            data={data[0]}
            setWarringAlert={setWarringAlert}
            setContent={setContent}
          /> */}
          {/* <ProcessSection_2 />
          <ProcessSection_3 data={data[0]} />
          <ProcessSection_4 /> */}
        </div>
      </div>
    </>
  );
};

export default InvestJoinDetail;
