import styles from "../../styles/mypage.module.css";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { AiFillHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { RiHeart3Line, RiHeart3Fill } from "react-icons/ri";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "swiper/css/pagination";
import { useRef } from "react";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import SwiperCore, {
  Virtual,
  Navigation,
  Grid,
  Pagination,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { wonComma } from "../../lib/wonComma";

const MypageInterestDetail = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const [state, setState] = useState(1);
  const navigate = useNavigate();

  const btn_move = (data) => {
    if (data === 1) {
      navigate("/mypage/profile");
    } else {
      if (user.ur_type === 1) {
        navigate("/mypage/password");
      } else {
        return alert("소셜 계정은 이용할 수 없습니다.");
      }
    }
  };
  const [arrayList, setArrayList] = useState([]);
  const [arrayList2, setArrayList2] = useState([]);
  const [allCnt, setAllCnt] = useState(0);
  useEffect(() => {
    let sendData = {
      no: user.no,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/mypage_select_interest",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
          setArrayList2(result.messageinfo.message_data2);

          setAllCnt(
            result.messageinfo.message.length +
              result.messageinfo.message_data2.length
          );
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
  }, []);
  const SliderItemList = ({ data }) => {
    const btn_move = (yn, link, num) => {
      if (yn === "N") {
        navigate(`/invest/${num}/process`);
      } else {
        window.open(link);
      }

      // na
      // navigate(`/notice/${no}`);
    };
    const btn_move2 = (link) => {
      window.open(link);
      // navigate(`/notice/${no}`);
    };
    return (
      <>
        <div
          className={styles.main_news_item}
          onClick={() => {
            data.pro_img
              ? btn_move(data.pro_link_yn, data.pro_link, data.no)
              : btn_move2(data.store_link);
          }}
        >
          <div className={styles.main_news_inner}>
            <div className={styles.main_news_img}>
              {data.pro_img && (
                <img src={`/product/${data.no}/${data.pro_img}`} />
              )}{" "}
              {!data.pro_img && (
                <img src={`/store/${data.no}/${data.store_img}`} />
              )}
            </div>

            {data.pro_img && (
              <div className={styles.main_news_content}>
                <p className={`${styles.main_news_cont}`}>{data.pro_content}</p>

                <span className={styles.main_news_date}>
                  <em>{data.pro_rate}%</em>
                  {/* {c} */}
                  {data.pro_company}
                </span>
              </div>
            )}
            {!data.pro_img && (
              <div className={styles.main_news_content}>
                <p className={`${styles.main_news_cont}`}>{data.store_title}</p>

                <span className={styles.main_news_date}>
                  <em>{wonComma(data.store_amount)}원</em>
                  {/* {c} */}
                </span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  const slickRef = useRef(null);

  const previous = useCallback(() => slickRef.current.slickPrev(), []);
  const next = useCallback(() => slickRef.current.slickNext(), []);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    vertical: true,
    verticalSwiping: true,
    responsive: [
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // slidesPerRow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // slidesPerRow: 2,
        },
      },
    ],
    // arrows: ,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  return (
    <>
      <div className={styles.mypage_content_right}>
        <h3 className={styles.mypage_content_title}>나의 관심상품</h3>
        <div className={styles.mypage_content_inner}>
          <div className={styles.mypage_interest_wrap}>
            <div className={styles.mypage_interest_box}>
              {/* <Slider {...settings} ref={slickRef}>
                {arrayList.map((data) => {
                  return (
                    <div>
                      <SliderItemList data={data} />
                    </div>
                  );
                })}
                {arrayList2.map((data) => {
                  return (
                    <div>
                      <SliderItemList data={data} />
                    </div>
                  );
                })}
              </Slider>
              <div className={styles.store_arrow_left} onClick={previous}>
                <MdKeyboardArrowLeft />
              </div>

              <div className={styles.store_arrow_right} onClick={next}>
                <MdKeyboardArrowRight />
              </div> */}
              {allCnt > 0 && (
                <>
                  <Swiper
                    className={
                      windowSize > 650
                        ? allCnt > 2
                          ? `${styles.mySwiper} ${styles.mySwiper_three}`
                          : allCnt === 2
                          ? `${styles.mySwiper} ${styles.mySwiper_two}`
                          : `${styles.mySwiper}`
                        : allCnt > 1
                        ? `${styles.mySwiper} ${styles.mySwiper_two}`
                        : `${styles.mySwiper}`
                    }
                    slidesPerView={allCnt > 1 ? 2 : 1}
                    direction="vertical"
                    ref={setSwiper}
                    {...parmss}
                    // style={{ position: "relative", height: "100%" }}
                    modules={[Navigation]}
                    breakpoints={{
                      // 740: {
                      //   slidesPerView: 3,
                      // },
                      650: {
                        slidesPerView: allCnt > 2 ? 3 : allCnt === 2 ? 2 : 1,
                      },
                    }}
                  >
                    {arrayList &&
                      arrayList.map((data) => {
                        return (
                          <SwiperSlide>
                            <div>
                              <SliderItemList data={data} />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    {arrayList2 &&
                      arrayList2.map((data) => {
                        return (
                          <SwiperSlide>
                            <div>
                              <SliderItemList data={data} />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                </>
              )}{" "}
              {allCnt === 0 && (
                <p className={styles.not_heart}>
                  <AiFillHeart style={{ marginRight: "4px" }} />
                  찜한 상품이 없습니다.
                </p>
              )}
              {allCnt > 0 && (
                <>
                  {" "}
                  <ButtonPrev1
                    ref={prevRef}

                    // style={{ width: "100px", height: "100px", background: "blue" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MdKeyboardArrowUp />{" "}
                    </div>
                  </ButtonPrev1>{" "}
                  <ButtonNext1 ref={nextRef}>
                    {" "}
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MdKeyboardArrowDown />
                    </div>
                  </ButtonNext1>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ButtonPrev1 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 35px;
  height: 35px;
  background: #fff;
  top: -39px;
  left: 50%;
  margin-left: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonNext1 = styled.div`
  position: absolute;
  z-index: 1;

  width: 35px;
  height: 35px;
  display: block;
  background: #fff;
  bottom: -30px;
  left: 50%;
  margin-left: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;

export default MypageInterestDetail;
