import MypageDetail from "../components/mypage/MypageDetail";

const Mypage = () => {
  return (
    <>
      <MypageDetail />
    </>
  );
};

export default Mypage;
