import styles from "../../../styles/mypage.module.css";
import { FaWonSign } from "react-icons/fa";
import { BsFillBox2HeartFill, BsFillQuestionSquareFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { AiOutlineRise, AiFillHeart } from "react-icons/ai";
const MypageActivity = ({ data }) => {
  const navigate = useNavigate();
  const btn_move = (data) => {
    if (data === 1) {
      navigate("/mypage/invest");
    } else {
      navigate("/mypage/interest");
    }
  };
  return (
    <>
      <div className={styles.mypage_activity}>
        <ul>
          <li
            style={{ cursor: "pointer" }}
            onClick={() => {
              btn_move(1);
            }}
          >
            <div className={styles.mypage_activity_icon}>
              <AiOutlineRise />
            </div>
            <p className={styles.mypage_activity_title}>투자상품</p>
            <p className={styles.mypage_activity_cnt}>
              {data[0].investCnt ? data[0].investCnt : "0"}
            </p>
          </li>
          <li
            style={{ cursor: "pointer" }}
            onClick={() => {
              btn_move(2);
            }}
          >
            <div className={styles.mypage_activity_icon}>
              <AiFillHeart />
            </div>
            <p className={styles.mypage_activity_title}>관심상품</p>
            <p className={styles.mypage_activity_cnt}>
              {" "}
              {data[0].likeCnt ? data[0].likeCnt : "0"}
            </p>
          </li>
          {/* <li>
            <div className={styles.mypage_activity_icon}>
              <BsFillQuestionSquareFill />
            </div>
            <p className={styles.mypage_activity_title}>문의내역</p>
            <p className={styles.mypage_activity_cnt}>3</p>
          </li> */}
        </ul>

        {/* <div className={styles.mypage_activity_1}>
          <h5>나의 투자 리스트</h5>
          <div className={styles.mypage_activity_box}></div>
        </div>
        <div className={styles.mypage_activity_2}>
          <h5>나의 관심 리스트</h5>{" "}
          <div className={styles.mypage_activity_box}></div>
        </div> */}
      </div>
    </>
  );
};

export default MypageActivity;
