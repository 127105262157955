import React, { useMemo, useState, useEffect, useRef } from "react";
import CustomTable from "./CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "../CommonAxios";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/admin.module.css";
import { Link, useNavigate } from "react-router-dom";
import { textReplace } from "../../lib/textReplace";
import { useCallback } from "react";

const BrandList = () => {
  const navigate = useNavigate();

  // {
  //   Header: "구분",
  //   accessor: "notice_type",

  //   Cell: ({ cell: { value } }) => (
  //     <>{value == 1 ? "공지사항" : "보도자료"}</>
  //   ),
  // },
  // {
  //   Header: "공개여부",
  //   accessor: "notice_open",
  //   Cell: ({ cell: { value } }) => <>{value == "Y" ? "공개" : "비공개"}</>,
  // },

  // {
  //   Header: "답변여부",
  //   accessor: "qna_answer_yn",
  //   Cell: ({ cell: { value } }) => (
  //     <>{value === "Y" ? "답변완료" : "답변미완료"}</>
  //   ),
  // },
  // {
  //   Header: "",
  //   accessor: "qna_date",
  //   Cell: ({ cell: { value } }) => <Date value={value} />,

  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState(1);
  const [options2, setOptions2] = useState(1);
  const [banSw, setBanSw] = useState(false);

  const [a1, setA1] = useState("");
  const [a2, setA2] = useState("");
  const [a3, setA3] = useState("");
  const [a4, setA4] = useState("");
  const [a5, setA5] = useState("");
  const [a6, setA6] = useState("");
  const [a7, setA7] = useState("");
  const [a8, setA8] = useState("");
  const [a9, setA9] = useState("");
  const [a10, setA10] = useState("");
  const [a11, setA11] = useState("");
  const [a12, setA12] = useState("");

  const [left1, setLeft1] = useState("");
  const [right1, setRight1] = useState("");
  const [left2, setLeft2] = useState("");
  const [right2, setRight2] = useState("");
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [content1, setContent1] = useState("");
  const [content2, setContent2] = useState("");
  useEffect(() => {
    setBanSw(false);
    let sendData = {
      type: options,
      no: options2,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_brand",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          // setArrayList(result.messageinfo.message);
          if (result.messageinfo.message.length > 0) {
            setArrayList(result.messageinfo.message);
            setLeft1(result.messageinfo.message[0].brand_text1);
            setLeft2(result.messageinfo.message[1].brand_text1);
            setRight1(result.messageinfo.message[0].brand_text2);
            setRight2(result.messageinfo.message[1].brand_text2);
            setLink1(result.messageinfo.message[0].brand_link);
            setLink2(result.messageinfo.message[1].brand_link);
            setContent1(result.messageinfo.message[0].brand_content);
            setContent2(result.messageinfo.message[1].brand_content);
          }
          setBanSw(true);
        } else {
          setBanSw(true);
        }
      }
    );
  }, [options, options2]);

  const changeCancel1 = () => {
    setLeft1(arrayList[0].brand_text1);

    setRight1(arrayList[0].brand_text2);

    setLink1(arrayList[0].brand_link);

    setContent1(arrayList[0].brand_content);

    setChange1(false);
  };
  const changeCancel2 = () => {
    setLeft2(arrayList[1].brand_text1);

    setRight2(arrayList[1].brand_text2);

    setLink2(arrayList[1].brand_link);

    setContent2(arrayList[1].brand_content);
    setChange2(false);
  };
  const axiosFun = () => {
    let sendData = {
      brand_text1: textReplace(left1),
      brand_text2: textReplace(right1),
      brand_content: textReplace(content1),
      brand_link: textReplace(link1),
      no: 1,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_brand_text",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          Swal.fire({
            title: "변경 완료",

            confirmButtonText: "확인",
          });
          setChange1(false);
          // setArrayList(result.messageinfo.message);
        }
      }
    );
  };
  const axiosFun1 = (data, no) => {
    let sendData = {
      brand_text1: textReplace(left2),
      brand_text2: textReplace(right2),
      brand_content: textReplace(content2),
      brand_link: textReplace(link2),
      no: 2,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_brand_text",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          Swal.fire({
            title: "변경 완료",

            confirmButtonText: "확인",
          });
          setChange2(false);
          // setArrayList(result.messageinfo.message);
        }
      }
    );
  };
  const change_left1 = useCallback((e) => {
    setLeft1(e.target.value);
  }, []);
  const change_right1 = useCallback((e) => {
    setRight1(e.target.value);
  }, []);
  const change_content1 = useCallback((e) => {
    setContent1(e.target.value);
  }, []);
  const change_link1 = useCallback((e) => {
    setLink1(e.target.value);
  }, []);
  const change_left2 = useCallback((e) => {
    setLeft2(e.target.value);
  }, []);
  const change_right2 = useCallback((e) => {
    setRight2(e.target.value);
  }, []);
  const change_content2 = useCallback((e) => {
    setContent2(e.target.value);
  }, []);
  const change_link2 = useCallback((e) => {
    setLink2(e.target.value);
  }, []);

  const [change1, setChange1] = useState(false);
  const [change2, setChange2] = useState(false);
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          홈 &nbsp;〉 &nbsp;브랜드 관리{" "}
        </div>
        {arrayList.length > 0 && (
          <div
            className={`${styles.wrap_content_box_body} ${styles.brand_flex}`}
          >
            <div className={`${styles.info_write_wrap} ${styles.brand_wrap}`}>
              <div className={`${styles.info_write_inner}`}>
                <div className={styles.info_write_content}>
                  <ol>
                    {!change1 && (
                      <li className={`${styles.brand_li}`}>
                        {" "}
                        <div>
                          <span className={styles.main_store_more}>
                            {" "}
                            <em>{arrayList[0].brand_text1}</em>
                            <em>&nbsp;{arrayList[0].brand_text2}</em>
                          </span>
                          <div className={styles.info_brand_div}>
                            <p>{arrayList[0].brand_content}</p>
                            <p>{arrayList[0].brand_link}</p>
                          </div>
                        </div>{" "}
                        <button onClick={() => setChange1(true)}>수정</button>
                      </li>
                    )}{" "}
                    {change1 && (
                      <li className={`${styles.brand_li2}`}>
                        {" "}
                        <div>
                          <ul>
                            <li>
                              <label>이름</label>
                              <span>
                                {" "}
                                <input
                                  type="text"
                                  value={left1}
                                  onChange={change_left1}
                                />
                              </span>
                              <span>
                                {" "}
                                <input
                                  type="text"
                                  value={right1}
                                  onChange={change_right1}
                                />
                              </span>
                            </li>
                            <li>
                              {" "}
                              <label>설명글</label>
                              <input
                                type="text"
                                value={content1}
                                onChange={change_content1}
                              />
                            </li>
                            <li>
                              {" "}
                              <label>링크</label>
                              <input
                                type="text"
                                value={link1}
                                onChange={change_link1}
                              />
                            </li>
                          </ul>
                        </div>{" "}
                        <div>
                          <button onClick={() => axiosFun()}>변경</button>{" "}
                          <button onClick={() => changeCancel1()}>취소</button>
                        </div>
                      </li>
                    )}
                  </ol>
                </div>
              </div>
            </div>{" "}
            <div className={`${styles.info_write_wrap} ${styles.brand_wrap}`}>
              <div className={`${styles.info_write_inner}`}>
                <div className={styles.info_write_content}>
                  <ol>
                    {" "}
                    {change2 && (
                      <li className={`${styles.brand_li2}`}>
                        {" "}
                        <div>
                          <ul>
                            <li>
                              <label>이름</label>
                              <span>
                                {" "}
                                <input
                                  type="text"
                                  value={left2}
                                  onChange={change_left2}
                                />
                              </span>
                              <span>
                                {" "}
                                <input
                                  type="text"
                                  value={right2}
                                  onChange={change_right2}
                                />
                              </span>
                            </li>
                            <li>
                              {" "}
                              <label>설명글</label>
                              <input
                                type="text"
                                value={content2}
                                onChange={change_content2}
                              />
                            </li>
                            <li>
                              {" "}
                              <label>링크</label>
                              <input
                                type="text"
                                value={link2}
                                onChange={change_link2}
                              />
                            </li>
                          </ul>
                        </div>{" "}
                        <div>
                          <button onClick={() => axiosFun1()}>변경</button>{" "}
                          <button onClick={() => changeCancel2()}>취소</button>
                        </div>
                      </li>
                    )}{" "}
                    {!change2 && (
                      <li className={`${styles.brand_li}`}>
                        {" "}
                        <div>
                          <span className={styles.main_store_more}>
                            {" "}
                            <em>{left2}</em>
                            <em>&nbsp;{right2}</em>
                          </span>
                          <div className={styles.info_brand_div}>
                            <p>{content2}</p>
                            <p>{link2}</p>
                          </div>
                        </div>{" "}
                        <button onClick={() => setChange2(true)}>수정</button>
                      </li>
                    )}
                  </ol>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BrandList;
