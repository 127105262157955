import icon1 from "../../assets/images/fix_logo_on.png";
import icon2 from "../../assets/images/fix_invest_off.png";
import icon3 from "../../assets/images/fix_plus.png";
import icon4 from "../../assets/images/fix_heart_off.png";
import icon5 from "../../assets/images/fix_my_off.png";
import { Link } from "react-router-dom";
const MobileFix = () => {
  return (
    <div className="mobile_fix_wrap">
      <ul className="mobile_fix_ul">
        <li>
          <Link to="/">
            <div className="fix_li_imgbox">
              <div className="fix_li_img">
                <img src={icon1} />{" "}
              </div>{" "}
            </div>
            <span>홈</span>
          </Link>
        </li>
        <li>
          <Link to="/funding">
            <div className="fix_li_imgbox">
              <div className="fix_li_img">
                <img src={icon2} />{" "}
              </div>{" "}
            </div>
            <span>투자하기</span>
          </Link>
        </li>
        {/* <li>
          <a href="#">
            <div className="fix_li_imgbox">
              <div className="fix_li_img">
                <img src={icon3} />{" "}
              </div>{" "}
            </div>
          </a>
        </li> */}
        <li>
          <Link to="/mypage/interest">
            <div className="fix_li_imgbox">
              <div className="fix_li_img">
                <img src={icon4} />{" "}
              </div>{" "}
            </div>
            <span>찜하기</span>
          </Link>
        </li>
        <li>
          <Link to="/my/invest/join">
            <div className="fix_li_imgbox">
              <div className="fix_li_img">
                <img src={icon5} />{" "}
              </div>{" "}
            </div>
            <span>나의 투자</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default MobileFix;
