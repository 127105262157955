import styles from "../../styles/admin.module.css";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../CommonAxios";
import StoryWriteDetail from "./detail/StoryWriteDetail";
import PressWriteDetail from "./detail/PressWriteDetail";
import FnqWriteDetail from "./detail/FnqWriteDetail";
// import AdminUserInfo from "./AdminUserInfo";
const FnqWrite = () => {
  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          게시판 관리 &nbsp;〉 &nbsp;자주하는 질문 목록 〉&nbsp;자주하는 질문
          등록{" "}
        </div>
        <div className={styles.wrap_content_box_body}>
          <FnqWriteDetail />
        </div>
      </div>
    </>
  );
};

export default FnqWrite;
