// import "../styles/signup.css";
import naver_icon from "../assets/images/naver_icon.png";
// import google_icon from "../assets/images/google_icon.png";
import { useRef, useState, useCallback, useEffect } from "react";
import { CommonAxios } from "../components/CommonAxios";
import styled from "styled-components";
import Swal from "sweetalert2";
import logo from "../assets/images/Logo.png";
import logo1 from "../assets/images/M12.png";
import EmailConfirm from "../components/EmailConfirm";
import NaverLogin from "../pages/NaverLogin";
import KakaoLogin from "../pages/KakaoLogin";
import loginStyle from "../styles/login.module.css";
import { textReplace } from "../lib/textReplace";
import { getTextTotal, textOverCut } from "../lib/stringLenghts";
import { ramdomASCIIDate } from "../lib/radomASCII";
import { MoveAlert, WarringAlert } from "../components/alert/Alert";
import { encrypt } from "../lib/api/tokenCrypto";
import { useDispatch } from "react-redux";
import { getUsers } from "../modules/user";
import { useNavigate } from "react-router-dom";
import DaumPostcode from "react-daum-postcode";

function Signup() {
  const dispatch = useDispatch();
  // const stringreg = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|@#$%^&*!~_+]+$/; //한글 영문 정규식
  const stringreg = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9]+$/; //한글 영문 정규식
  const email_test =
    /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  const password_test =
    /^(?!((?:[A-Za-z]+)|(?:[~!@#$%^&*()_+=]+)|(?:[0-9]+))$)[A-Za-z\d~!@#$%^&*()_+=]{10,}$/;

  const [changeNameColor, setChangeNameColor] = useState(true); //이름/닉네임 오류 메세지
  const [changePassColor, setChangePassColor] = useState(true); //패스워드 오류 메세지
  const [changePass2Color, setChangePass2Color] = useState(true); //패스워드 2차 확인 오류 메세지
  const [mailNum, setMailNum] = useState(""); //메일 인증 번호
  const [emailBtn, setEmailBtn] = useState(true); //이메일 버튼 style
  const [btnState, setBtnState] = useState(false); //인증btn / 재발송 btn 변환
  const [confirmView, setConfirmView] = useState(false); //이메일 인증하기 UI 생성
  const [confirmViewTest, setConfirmValueTest] = useState(true); //메일 인증번호 전송 누르지 않았을 시

  const [emailConfirmTest, setEmailConfirmTest] = useState(null); //이메일 인증 에러 메세지

  const [inputEmailDisable, setInputEmailDisable] = useState(false); //이메일 input disable

  const timeR = useRef(180); // 타이머 시작
  // const dispatch = useDispatch();

  // input Value state
  const [nameValue, setNameValue] = useState(""); //이름
  const [nameByte, setNameByte] = useState(0);
  const [emailValue, setEmailValue] = useState(""); //이메일
  const [passwordValue, setPasswordValue] = useState(""); //비밀번호
  const [confirmValue, setConfirmValue] = useState(""); //비밀번호 확인

  // 메시지 state
  const [nameError, setNameError] = useState(true); // 이름 에러메세지

  const [emailError, setEmailError] = useState(true); // 이메일 에러메세지
  const [passwordError, setPasswordError] = useState(null); // 비밀번호 에러메세지
  const [confirmError, setConfirmError] = useState(true); // 비밀번호 확인 에러메세지

  const [signSwitch, setSignSwitch] = useState(false);
  const [warringAlert, setWarringAlert] = useState(false);
  const [moveAlert, setMoveAlert] = useState(false);
  var pattern = /\s/g;
  // const [email]

  // useEffect(() => {}, [emailBtn]);
  // 이름 입력시
  const onChangeName = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 20);
    let text = textOverCut(e.target.value, 20);

    setNameValue(text);
    setNameByte(byte.byte);
  }, []);

  const nameBlur = () => {
    if (!stringreg.test(nameValue)) {
      setChangeNameColor(false);
    } else {
      setChangeNameColor(true);
    }
  };

  // 이메일 입력시
  const onChangeEmail = useCallback((e) => {
    setEmailValue(e.target.value);
    // 이메일 값이 없거나 이메일 형식이 아니라면
    if (e.target.value === "" || !email_test.test(e.target.value)) {
      setEmailError(false);
      setEmailBtn(true);
    } else {
      setEmailError(true);
      setEmailBtn(false);
    }
  }, []);

  // 비밀번호 입력시
  const onChangePassword = useCallback((e) => {
    setPasswordValue(e.target.value);
    if (e.target.value === "" || !password_test.test(e.target.value)) {
      setChangePassColor(false);
    } else {
      setChangePassColor(true);
    }
  }, []);

  // 비밀번호 확인 입력시
  const onChangeConfirm = useCallback(
    (e) => {
      setConfirmValue(e.target.value);

      if (e.target.value === "" || e.target.value !== passwordValue) {
        setChangePass2Color(false);
      } else {
        setChangePass2Color(true);
      }
    },
    [passwordValue]
  );

  useEffect(() => {
    // 이메일 형식 일치 + 이메일 인증창 O + 이메일 인증 에러메세지가 true 가 아닐때

    if (
      emailError === true &&
      confirmView === true &&
      emailConfirmTest !== true
    ) {
      setTimeout(() => setEmailBtn(false), 10000);
    }
  }, [emailError, confirmView, emailConfirmTest]);

  // 이메일 보내는 함수
  function sendEmail() {
    setEmailBtn(true);
    setBtnState(true);
    // e.preventdefault();
    const sendData = {
      email: emailValue,
    };
    setConfirmView(true);
    // setConfirmValueTest(true);
    timeR.current = 180;
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/mail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setMailNum(result.messageinfo.message);
        } else {
          return Swal.fire({
            title: "이메일 발송 오류",
            text: "고객센터에 문의해주세요.",
            icon: "error",
            confirmButtonColor: "#FF0000",
          });
        }
      }
    );
  }

  // 오류 메세지 있는지 없는지 확인
  const inpConfirm = () => {
    let count = 0;
    // 이름이 입력되지 않았다면
    if (nameValue === "" || nameError === false) {
      setNameError(false);
      count++;
    }

    // 이메일이 입력되지 않았다면
    if (emailValue === "" || emailError === false) {
      setEmailError(false);
      count++;
    }

    // 이메일 입력 인증 true === 이메일 값 O  === 이메일 인증 창 X 일때
    if (confirmView === false && emailError === true && emailValue !== "") {
      setConfirmValueTest(false); //인증을 진행하지 않았습니다.
      count++;
    }
    // 이메일 인증 창 O === 이메일 인증번호 값을 입력하지 않았을때
    if (
      (confirmView === true && emailConfirmTest === null) ||
      emailConfirmTest === false
    ) {
      setEmailConfirmTest(false);
      count++;
    }
    if (passwordValue === "" || passwordError === false) {
      setPasswordError(false);
      count++;
    }
    if (confirmValue === "" || confirmError === false) {
      setConfirmError(false);
      count++;
    }
    return count;
  };

  // 회원가입버튼
  function signUpClcik() {
    // const confirmResult = inpConfirm();
    // if (confirmResult > 0) return;

    let sendData = {
      name: nameValue,
      email: emailValue,
      password: passwordValue,
      recomendcode: ramdomASCIIDate(),
      // mobile: "",
      // image: "",
      // type: "funers",
      // nickname: "funers",
      // mode: "일반",
      // recomendcode: ramdomASCIIDate(),
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/signup_user",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          let sendData1 = {
            email: sendData.email,
            password: sendData.password,
          };
          CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/signin",
            sendData,
            function (result) {
              if (result.messageinfo.state === "ok") {
                setMoveAlert(true);
                localStorage.setItem(
                  "meta_info",
                  encrypt(result.messageinfo.message, "meta_info")
                );

                if (sessionStorage.getItem("savePage")) {
                  let dataLo = sessionStorage.getItem("savePage");

                  navigate(dataLo);
                  sessionStorage.removeItem("savePage");
                } else {
                  navigate("/");
                }

                dispatch(getUsers(result.messageinfo.message));
              }
            }
          );
        } else {
          if (result.messageinfo.message === "등록된 이메일 계정입니다.") {
            setWarringAlert(true);
          }
        }
      }
    );
  }

  // CommonAxios(
  //   process.env.REACT_APP_HOSTDONAME + "/api/signup_user",
  //   sendData,
  //   function (result) {
  //     if (result.messageinfo.state === "ok") {
  //       dispatch(getUsers(sendData));

  //       Swal.fire({
  //         title: "가입을 축하합니다.",
  //         icon: "success",
  //         confirmButtonText: "확인",
  //         allowOutsideClick: false,
  //       }).then((res) => {
  //         if (res.isConfirmed) {
  //           Swal.fire({
  //             title: "핸드폰 인증이 필요합니다.",
  //             icon: "error",
  //             confirmButtonText: "확인",
  //             confirmButtonColor: "#FF0000",
  //             allowOutsideClick: false,
  //           }).then((res) => {
  //             if (res.isConfirmed) {
  //               document.location.replace("/myinfo");
  //             }
  //           });
  //         }
  //       });
  //     } else {
  //       Swal.fire({
  //         title: "등록된 계정입니다.",
  //         icon: "error",
  //         confirmButtonText: "확인",
  //         confirmButtonColor: "#FF0000",
  //         allowOutsideClick: false,
  //       });
  //     }
  //   }
  // );

  useEffect(() => {
    if (
      nameValue !== "" &&
      changeNameColor &&
      inputEmailDisable &&
      changePassColor &&
      passwordValue !== "" &&
      changePass2Color &&
      confirmValue !== ""
    ) {
      setSignSwitch(true);
    } else {
      setSignSwitch(false);
    }
  }, [
    inputEmailDisable,
    changeNameColor,
    nameValue,
    changePassColor,
    changePass2Color,
    passwordValue,
    confirmValue,
  ]);

  const [modalState, setModalState] = useState(false);
  const [d, setInputAddressValue] = useState("");
  const [df, setInputZipCodeValue] = useState("");
  const postCodeStyle = {
    width: "400px",
    height: "400px",
    display: modalState ? "block" : "none",
  };
  // const onCompletePost = (data) => {
  //   let fullAddress = data.address;
  //   let extraAddress = "";

  //   if (data.addressType === "R") {
  //     if (data.bname !== "") {
  //       extraAddress += data.bname;
  //     }
  //     if (data.buildingName !== "") {
  //       extraAddress +=
  //         extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
  //     }
  //     fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
  //   }
  //   console.log(data);
  //   console.log(fullAddress);
  //   console.log(data.zonecode);
  // };
  const navigate = useNavigate();
  return (
    <>
      {warringAlert && (
        <WarringAlert
          content={"이미 가입된 계정입니다"}
          setWarringAlert={setWarringAlert}
        />
      )}
      {moveAlert && (
        <MoveAlert
          content={"가입을 축하합니다!"}
          setMoveAlert={setMoveAlert}
          movePage={"/"}
        />
      )}
      <div className="common-wrap">
        {/* <div className="common-inner-wrap"> */}
        <div className={loginStyle.login_header_wrap}>
          <div className={loginStyle.login_header}>
            <img
              src={logo1}
              onClick={() => {
                navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <div className={loginStyle.signup_content_wrap}>
          <div className={loginStyle.signup_content}>
            <h3 className={loginStyle.signup_h3}>이메일 회원가입</h3>
            <div
              className={`${loginStyle.signup_section} ${loginStyle.signup_section_1}`}
            >
              <label>이름</label>
              <Namediv
                className={loginStyle.signup_name_inp}
                propsChange={changeNameColor}
              >
                <input
                  className="inp_singup"
                  type="text"
                  placeholder="이름을 입력해주세요."
                  name="signName"
                  id="signName"
                  onChange={onChangeName}
                  autoComplete="off"
                  value={nameValue}
                  onBlur={nameBlur}
                />

                <p className={loginStyle.p_nameByte}>{nameByte}/20</p>
              </Namediv>

              {!changeNameColor ? (
                <em className={loginStyle.error_em}>
                  한글/숫자/영문자만 가능합니다.(공백금지)
                </em>
              ) : null}
            </div>
            {/* <div
              className={`${loginStyle.signup_section} ${loginStyle.signup_section_1}`}
            >
              <label>닉네임</label>
              <Namediv
                className={loginStyle.signup_name_inp}
                propsChange={changeNameColor}
              >
                <input
                  className="inp_singup"
                  type="text"
                  placeholder="닉네임을 입력해주세요."
                  name="signName"
                  id="signName"
                  onChange={onChangeName}
                  autoComplete="off"
                  value={nameValue}
                  onBlur={nameBlur}
                />

                <p className={loginStyle.p_nameByte}>{nameByte}/20</p>
              </Namediv>

              {!changeNameColor ? (
                <em className={loginStyle.error_em}>
                  한글/숫자/영문자만 가능합니다.(공백금지)
                </em>
              ) : null}
            </div> */}
            <div
              className={`${loginStyle.signup_section} ${loginStyle.signup_section_2}`}
            >
              <label>이메일</label>
              <div className={loginStyle.signup_email_box}>
                <Email1div
                  className={loginStyle.signup_email_inp}
                  emailBtn={emailError}
                >
                  <input
                    className="inp_singup"
                    type="text"
                    placeholder="이메일주소를 입력해주세요."
                    name="email"
                    id="email"
                    onChange={onChangeEmail}
                    autoComplete="off"
                    disabled={inputEmailDisable}
                  />
                </Email1div>
                {btnState ? (
                  <BtnEmailSend
                    type="button"
                    emailBtn={emailBtn}
                    onClick={sendEmail}
                    disabled={emailBtn}
                  >
                    인증 재발송
                  </BtnEmailSend>
                ) : (
                  <BtnEmailSend
                    type="button"
                    emailBtn={emailBtn}
                    onClick={sendEmail}
                    disabled={emailBtn}
                  >
                    인증하기
                  </BtnEmailSend>
                )}
              </div>
              {emailError ? null : (
                <em className={loginStyle.error_em}>
                  이메일형식이 맞지 않습니다.
                </em>
              )}
              {/* {confirmViewTest ? null : <p>인증을 진행하지 않았습니다.</p>} */}

              {confirmView ? (
                <EmailConfirm
                  timeR={timeR} //시간
                  setMailNum={setMailNum} //이메일 인증 값
                  mailNum={mailNum} //이메일 인증 값
                  setEmailConfirmTest={setEmailConfirmTest} //이메일 인증 에러 메세지
                  emailConfirmTest={emailConfirmTest} //이메일 인증 에러 메세지
                  setEmailBtn={setEmailBtn} //이메일 인증 전송버튼 disable
                  setInputEmailDisable={setInputEmailDisable}
                />
              ) : null}
            </div>{" "}
            {/* <div
              className={`${loginStyle.signup_section} ${loginStyle.signup_section_2}`}
            >
              <label>핸드폰</label>
              <div className={loginStyle.signup_email_box}>
                <Email1div
                  className={loginStyle.signup_email_inp}
                  emailBtn={emailError}
                >
                  <input
                    className="inp_singup"
                    type="text"
                    placeholder="핸드폰번호를 입력해주세요."
                    name="email"
                    id="email"
                    onChange={onChangeEmail}
                    autoComplete="off"
                    disabled={inputEmailDisable}
                  />
                </Email1div>
                {btnState ? (
                  <BtnEmailSend
                    type="button"
                    emailBtn={emailBtn}
                    onClick={sendEmail}
                    disabled={emailBtn}
                  >
                    인증 재발송
                  </BtnEmailSend>
                ) : (
                  <BtnEmailSend
                    type="button"
                    emailBtn={emailBtn}
                    onClick={sendEmail}
                    disabled={emailBtn}
                  >
                    인증하기
                  </BtnEmailSend>
                )}
              </div>
              {emailError ? null : (
                <em className={loginStyle.error_em}>
                  이메일형식이 맞지 않습니다.
                </em>
              )}
          

              {confirmView ? (
                <EmailConfirm
                  timeR={timeR} //시간
                  setMailNum={setMailNum} //이메일 인증 값
                  mailNum={mailNum} //이메일 인증 값
                  setEmailConfirmTest={setEmailConfirmTest} //이메일 인증 에러 메세지
                  emailConfirmTest={emailConfirmTest} //이메일 인증 에러 메세지
                  setEmailBtn={setEmailBtn} //이메일 인증 전송버튼 disable
                  setInputEmailDisable={setInputEmailDisable}
                />
              ) : null}
            </div> */}
            {/* <DaumPostcode style={postCodeStyle} onComplete={onCompletePost} />{" "} */}
            {/* <ZipCodeInput
	onChange={handleZipCode}
	value={inputZipCodeValue}
	placeholder="우편번호"
	type={'text'}
></ZipCodeInput> */}
            <div
              className={`${loginStyle.signup_section} ${loginStyle.signup_section_3}`}
            >
              <label>비밀번호</label>
              <Pass1div
                className={loginStyle.signup_pass_inp}
                changePassColor={changePassColor}
              >
                <input
                  className="inp_singup"
                  type="password"
                  placeholder="비밀번호를 입력해주세요."
                  name="signName"
                  id="signName"
                  onChange={onChangePassword}
                  autoComplete="off"
                  // value={nameValue}
                />
                {/* <p className={loginStyle.p_nameByte}>{nameByte}/10</p> */}
              </Pass1div>
              {!changePassColor ? (
                <em className={loginStyle.error_em}>
                  영문/ 숫자 / 특수문자 중 2가지 이상 조합 (10자 이상)
                </em>
              ) : (
                <em className={loginStyle.noerror_em}>
                  영문/ 숫자 / 특수문자 중 2가지 이상 조합 (10자 이상)
                </em>
              )}
            </div>
            <div
              className={`${loginStyle.signup_section} ${loginStyle.signup_section_4}`}
            >
              <label>비밀번호 확인</label>
              <Pass2div
                className={loginStyle.signup_pass_inp}
                changePass2Color={changePass2Color}
              >
                <input
                  className="inp_singup"
                  type="password"
                  placeholder="비밀번호를 입력해주세요."
                  name="signName"
                  id="signName"
                  onChange={onChangeConfirm}
                  autoComplete="off"
                  // value={nameValue}
                />
                {/* <p className={loginStyle.p_nameByte}>{nameByte}/10</p> */}
              </Pass2div>
              {!changePass2Color ? (
                <em className={loginStyle.error_em}>
                  비밀번호가 일치하지않습니다.
                </em>
              ) : null}
            </div>
            <div className={loginStyle.btn_signup_box}>
              {signSwitch && (
                <button className={loginStyle.btn_signup} onClick={signUpClcik}>
                  회원가입하기
                </button>
              )}
              {!signSwitch && (
                <button className={loginStyle.no_btn_signup}>
                  회원가입하기
                </button>
              )}
            </div>
            <div className={loginStyle.sns_signup}>
              <span className={loginStyle.sns_title_signup}>
                SNS계정으로 간편가입
              </span>
              <KakaoLogin type={true} />

              {/* <button
                className={`${loginStyle.login_btn} ${loginStyle.login_naver}`}
              >
                <img src={naver_icon} alt="naver_icon" />
                네이버로 시작하기
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="signup-wrapper">
        <div className="signup-inner">
          <div id="signup-title">
            <h2>회원가입</h2>
          </div>
          <div className="signup-box">
            <div className="signup-content">
              <div className="signup-sec signup-first">
                <label>이름</label>
                <div className="singup_name_inp">
                  <input
                    className="inp_singup"
                    type="text"
                    placeholder="이름을 입력해주세요."
                    name="signName"
                    id="signName"
                    onChange={onChangeName}
                    autoComplete="off"
                    value={nameValue}
                  />
                  <p className="p_nameByte">{nameByte}/10</p>
                </div>

                {nameError ? null : <p>이름을 입력하세요(한/영만 가능)</p>}
              </div>
              <div className="signup-sec signup-second">
                <label>이메일</label>
                <div className="email-NormalBox">
                  <div className="email-NormalInner">
                    <input
                      className="inp_singup"
                      type="text"
                      placeholder="이메일주소를 입력해주세요."
                      name="email"
                      id="email"
                      onChange={onChangeEmail}
                      autoComplete="off"
                      disabled={inputEmailDisable}
                    />
                    {emailError ? null : <p>이메일형식이 맞지 않습니다.</p>}
                    {confirmViewTest ? null : (
                      <p>인증을 진행하지 않았습니다.</p>
                    )}
                  </div>
                  {btnState ? (
                    <BtnEmailSend
                      type="button"
                      emailBtn={emailBtn}
                      onClick={sendEmail}
                      disabled={emailBtn}
                    >
                      인증 재발송
                    </BtnEmailSend>
                  ) : (
                    <BtnEmailSend
                      type="button"
                      emailBtn={emailBtn}
                      onClick={sendEmail}
                      disabled={emailBtn}
                    >
                      인증메일 발송
                    </BtnEmailSend>
                  )}
                </div>

                {confirmView ? (
                  <EmailConfirm
                    timeR={timeR} //시간
                    setMailNum={setMailNum} //이메일 인증 값
                    mailNum={mailNum} //이메일 인증 값
                    setEmailConfirmTest={setEmailConfirmTest} //이메일 인증 에러 메세지
                    emailConfirmTest={emailConfirmTest} //이메일 인증 에러 메세지
                    setEmailBtn={setEmailBtn} //이메일 인증 전송버튼 disable
                    setInputEmailDisable={setInputEmailDisable}
                  />
                ) : null}
              </div>

              <div className="signup-sec signup-third">
                <label>비밀번호</label>
                <input
                  className="inp_singup"
                  type="password"
                  name="password"
                  placeholder="비밀번호를 입력해주세요."
                  id="password"
                  onChange={onChangePassword}
                  autoComplete="off"
                  maxLength={32}
                />
                {passwordError === null ? (
                  <p style={{ color: "#476cd3" }}>
                    영문/ 숫자 / 특수문자 중 2가지 이상 조합 (8자 ~ 32자)
                  </p>
                ) : passwordError === true ? (
                  <p style={{ color: "#476cd3" }}>
                    영문/ 숫자 / 특수문자 중 2가지 이상 조합 (8자 ~ 32자)
                  </p>
                ) : (
                  <p>영문/ 숫자 / 특수문자 중 2가지 이상 조합 (8자 ~ 32자)</p>
                )}
              </div>
              <div className="signup-sec signup-fourth">
                <label>비밀번호 확인</label>
                <input
                  className="inp_singup"
                  type="password"
                  // onKeyUp={inpEvent}
                  placeholder="비밀번호를 한 번 더 입력해주세요"
                  name="password_confirm"
                  id="password_confirm"
                  onChange={onChangeConfirm}
                  autoComplete="off"
                  maxLength={32}
                />
                {confirmError ? null : <p>비밀번호가 일치하지 않습니다.</p>}
              </div>
              <div className="signup-sec signup-last">
                <p>
                  본인은 사이트 이용약관, 개인정보 수집 및 이용, 개인정보 제공에
                  대한 내용을 확인하였고, <br />
                  이에 동의합니다.
                </p>
              </div>
              <button
                id="btn-signup-process"
                type="submit"
                onClick={() => signUpClcik()}
              >
                동의하고 핸드폰 번호 인증
              </button>

              <hr></hr>
              <div className="signup-btn-box">
                <KakaoLogin type={false} />
              
                <NaverLogin onClick={() => false} />
                <div
                  className="sns-signup naver-signup"
                  style={{
                    background: "#03C75A",
                    width: "100%",

                    color: "#fff",
                  }}
                  onClick={() =>
                    document.querySelector("#naverIdLogin_loginButton").click()
                  }
                >
                  <img src={naver_icon} alt="naver_icon" />
                  <p>네이버로 시작하기</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
const BtnEmailSend = styled.button`
  cursor: ${(props) => (props.emailBtn ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.emailBtn ? "#00c73c" : "#00c73c ")};
`;
const Namediv = styled.div`
  border: ${(props) =>
    props.propsChange ? " 2px solid #00c73c" : "2px solid #e2885e"};
  // cursor: ${(props) => (props.emailBtn ? "not-allowed" : "pointer")};
  // background-color: ${(props) => (props.emailBtn ? "#93c8ec" : "#3498db")};
`;

const Email1div = styled.div`
  border: ${(props) =>
    props.emailBtn ? " 2px solid #00c73c" : "2px solid #e2885e"};

  // cursor: ${(props) => (props.emailBtn ? "not-allowed" : "pointer")};
  // background-color: ${(props) => (props.emailBtn ? "#93c8ec" : "#3498db")};
`;

const Pass1div = styled.div`
  border: ${(props) =>
    props.changePassColor ? " 2px solid #00c73c" : "2px solid #e2885e"};
  // cursor: ${(props) => (props.emailBtn ? "not-allowed" : "pointer")};
  // background-color: ${(props) => (props.emailBtn ? "#93c8ec" : "#3498db")};
`;

const Pass2div = styled.div`
  border: ${(props) =>
    props.changePass2Color ? " 2px solid #00c73c" : "2px solid #e2885e"};
  // cursor: ${(props) => (props.emailBtn ? "not-allowed" : "pointer")};
  // background-color: ${(props) => (props.emailBtn ? "#93c8ec" : "#3498db")};
`;

export default Signup;
