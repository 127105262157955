import com from "../../../styles/company.module.css";
import { GiFarmTractor } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcStackOfPhotos,
  FcSelfie,
  FcRuler,
} from "react-icons/fc";
import { useEffect, useState } from "react";
import { CommonAxios } from "../../CommonAxios";
// import FundingContentItem from "./FundingContentItem";

const CompanyQna = () => {
  const cont = [
    {
      title: "META12는 어떤 서비스인가요?",
      cont: "META12는 투자를 통해 누구나 쉽게 수직농장을 소유하고, 생산한 농산품을 판매하여 수익을 창출하는 친환경 수직농장 투자플랫폼입니다.",
    },
    {
      title: `META12 투자는 예적금과 무엇이 다른가요?`,
      cont: `은행 예적금은 수익률이 2%내외로 낮은 무위험 저수익 투자상품이에요. META12 투자상품은 주식이나 펀드에 비해 안정적인 수익을 내며 예적금 보다 높은 이자율을 제공하는 다양한 농업 투자상품을 제공해요.`,
    },
    {
      title: `투자 신청 후 취소할 수 있나요?`,
      cont: `투자 취소는 투자하신 상품이 모집 완료되기 전까지만 가능해요.
      하지만, 모집이 완료된 이후에는 투자취소가 불가해요.`,
    },
    {
      title: `투자상품에 투자하고 싶은데 어떻게 하면 되나요?`,
      cont: `현재 모집중인 투자상품에 쉽게 투자할 수 있어요. 
      
      1. 투자하고 싶은 농업 투자상품 페이지에서 ‘투자하기’ 버튼을 클릭해주세요.
      
      2. 간단 회원가입 및 로그인 후 계좌이체를 진행해주세요.

      3. 협동조합 가입 및 동의 절차 진행 후 투자가 완료돼요.`,
    },
    {
      title: `투자금이 어떤 용도로 사용되는지 알수 있나요?`,
      cont: `투자금은 META12 농장을 구축하고, 운영 및 유지보수 그리고 수익금 지급에 사용됩니다.`,
    },
    {
      title: `이자는 확정 이자인가요?`,
      cont: `이자율은 다음과 같이 계산됩니다. 이자율 = 기본금리(6.5~7%) + 보너스금리(2.5%, 2023년 6월 30일까지) + 변동금리(최대 5%)입니다.`,
    },
    {
      title: `상품가입후 추가 입금이 가능하나요?`,
      cont: `상품가입 후 추가 입금이 가능합니다. 3500만원 한도 내에서 횟수 제한 없이 자유롭게 추가 납입하실 수 있습니다.`,
    },
    {
      title: `이자는 언제 지급되나요?`,
      cont: `이자는 투자 직후 발생합니다. 내 투자 페이지에서 실시간으로 발생하는 이자를 확인하실 수 있으며, 10만원 이상부터 즉시 출금이 가능합니다.`,
    },
  ];
  const [data, setData] = useState([]);
  useEffect(() => {
    let sendData = "";
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/company_select_fnq",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);
  const Liitem = ({ data }) => {
    const [imgActive, setImgActive] = useState(false);
    const [bodyActive, setBodyActive] = useState(false);

    const btn_title = () => {
      setImgActive(!imgActive);
    };
    return (
      <>
        <li className={com.qna_item}>
          <div className={com.qna_title}>
            <button
              type="button"
              title="답변 열기"
              className={
                imgActive ? `${com.qna_btn} ${com.active}` : com.qna_btn
              }
              onClick={btn_title}
            >
              {data.fnq_question}
            </button>
          </div>
          <div
            className={
              imgActive ? `${com.qna_body} ${com.active}` : com.qna_body
            }
          >
            {data.fnq_answer}
          </div>
        </li>
      </>
    );
  };
  return (
    <div className={com.rowgroup5}>
      <section className={com.qna}>
        <h2>자주하는 질문</h2>
        <div className={com.accordion}>
          <ul className={com.qna_list}>
            {data.map((data) => {
              return <Liitem data={data} />;
            })}
          </ul>
        </div>
      </section>
    </div>
  );
};
export default CompanyQna;
