import com from "../../../styles/company.module.css";
import { GiFarmTractor } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcStackOfPhotos,
  FcSelfie,
  FcRuler,
} from "react-icons/fc";
import { CommonAxios } from "../../CommonAxios";
// import FundingContentItem from "./FundingContentItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Moment from "react-moment";
import { FaStar } from "react-icons/fa";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { numberToKorean } from "../../../lib/numberToKorean";
import { wonComma } from "../../../lib/wonComma";
const CompanyReview = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    let sendData = "";
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/company_select_review",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);
  const SliderItemList = ({ data }) => {
    const [view, setView] = useState(false);
    String.prototype.replaceAt = function (index, character) {
      return (
        this.substr(0, index) +
        character +
        this.substr(index + character.length)
      );
    };
    const replaceName = (nameData) => {
      let str = nameData.length;
      let replaceStr = nameData.replaceAt(str - 2, "*");
      return replaceStr;
    };
    const cli = () => {
      setView(!view);
    };
    return (
      <>
        <div
          className={
            view ? `${com.review_item} ${com.view} ` : `${com.review_item}`
          }
          onClick={cli}
        >
          <div className={com.review_inner}>
            <div className={com.review_text}>
              <p className={com.review_title}>
                누적 투자금액 {wonComma(numberToKorean(data.review_invest))}원
              </p>
              <div className={com.review_name}>
                <span>{data.review_user_age}대·</span>
                <span>{replaceName(data.review_user_name)}</span>
              </div>
              <p className={com.text}>{data.review_content}</p>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const slickRef = useRef(null);

  const previous = useCallback(() => slickRef.current.slickPrev(), []);
  const next = useCallback(() => slickRef.current.slickNext(), []);
  var settings = {
    // centerMode: false,
    // dots: false,
    // infinite: false,
    // slidesToShow: 4,
    // slidesToScroll: 1, dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    // padding: "20px",
    // centerPadding: "60px",
    // afterChange: (current) => {
    //   console.log(current.length);
    //   setState(current + 1);
    // },
    responsive: [
      {
        breakpoint: 970,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };
  const [state, setState] = useState(1);

  return (
    <>
      <div className={com.rowgroup4}>
        <section className={com.review}>
          <h2>리뷰</h2>
          <div className={com.review_list}>
            <Slider {...settings} ref={slickRef}>
              {/* {a.map((data) => {
                return (
                  <div> */}
              {data.map((data) => {
                return (
                  <div>
                    <SliderItemList data={data} />
                  </div>
                );
              })}
              {/* </div>
                );
              })} */}
            </Slider>
            <div
              className={com.reivew_arrow_left}
              onClick={previous}
              // style={{ opacity: state === 1 ? "0" : "1" }}
            >
              <MdKeyboardArrowLeft />
            </div>

            <div
              className={com.review_arrow_right}
              onClick={next}
              // style={{ opacity: state === 2 ? "0" : "1" }}
            >
              <MdKeyboardArrowRight />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default CompanyReview;
