import React, { useState, useEffect, useRef, useCallback } from "react";
import ProductInfo from "./../components/admin/details/ProductInfo";
import ProductSubTable from "./../components/admin/details/ProductSubTable";
import ProductInvestList from "../components/admin/details/ProductInvestList";
import Moment from "react-moment";
import styles from "../styles/admin.module.css";
import ProductRefundList from "../components/admin/details/ProductRefundList";
import ProductInterestList from "../components/admin/details/ProductInterestList";
import ProductInvestList2 from "../components/admin/details/ProductInvestList2";
import { useNavigate, useParams } from "react-router-dom";
import { textReplace } from "../lib/textReplace";
import { CommonAxios } from "../components/CommonAxios";
import Swal from "sweetalert2";
import { wonComma } from "../lib/wonComma";
import { getTextTotal, textOverCut } from "../lib/stringLenghts";
const ReviewDetail = () => {
  const [options, setOptions] = useState("all");
  const [openValue, setOpenValue] = useState("Y");
  const [ageValue, setAgeValue] = useState(20);
  const [areaValue, setAreaValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [investValue, setInvestValue] = useState("");
  const [areaByte, setAreaByte] = useState(0);
  const changeAge = useCallback((e) => {
    setAgeValue(e.target.value);
  }, []);

  const changeArea = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 300);
    let text = textOverCut(e.target.value, 300);

    setAreaValue(text);
    setAreaByte(byte.byte);
  }, []);
  const changeName = useCallback((e) => {
    setNameValue(e.target.value);
  }, []);

  const changeInvest = useCallback((e) => {
    setInvestValue(wonComma(e.target.value.replace(/[^\d]+/g, "")));
  }, []);

  const { id } = useParams();
  useEffect(() => {
    let sendData = {
      no: id,
      // mobile :
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_review_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setAgeValue(result.messageinfo.message[0].review_user_age);
          setAreaValue(result.messageinfo.message[0].review_content);
          let byte = getTextTotal(
            result.messageinfo.message[0].review_content,
            300
          );

          setAreaByte(byte.byte);
          setNameValue(result.messageinfo.message[0].review_user_name);
          setInvestValue(wonComma(result.messageinfo.message[0].review_invest));
          setOpenValue(result.messageinfo.message[0].review_open);
        } else {
        }
      }
    );
  }, []);

  const navigate = useNavigate();
  const [imgArr, setImgArr] = useState([]);
  const btn_review_reg = () => {
    if (!openValue || !areaValue || !nameValue || !investValue) {
      return Swal.fire({
        title: "빈 칸을 채워주세요.",

        confirmButtonText: "확인",
      });
    }

    let sendData = {
      no: id,
      investValue: investValue.replace(/[^\d]+/g, ""),

      nameValue: textReplace(nameValue),
      ageValue: ageValue,
      areaValue: textReplace(areaValue),

      open: openValue,

      // mobile :
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_review",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          Swal.fire({
            title: "수정 완료",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/admin/review");
            }
          });
        } else {
          navigate("/admin/review");
        }
      }
    );
  };
  const change_open = (data) => {
    setOpenValue(data);
  };
  return (
    <>
      {" "}
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          게시판관리 &nbsp;〉 &nbsp;리뷰 목록〉 &nbsp;리뷰 상세
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.review_write_wrap}>
            <div className={styles.review_write_inner}>
              <div className={styles.review_write_title}>
                <h4>리뷰 수정</h4>
                <div className={styles.review_write_button}>
                  <button onClick={btn_review_reg}>수정</button>
                </div>
              </div>
              <div className={styles.review_write_content}>
                <div className={styles.review_write_bottom}>
                  <div className={styles.review_inp_first}>
                    <div>
                      <label>투자누적금액</label>
                      <input
                        className={styles.review_write_inp}
                        placeholder="숫자만 입력하세요."
                        onChange={changeInvest}
                        value={investValue}
                        // onChange={onChangeTitle}
                        // value={title}
                      />
                    </div>
                    <div>
                      <label>이름</label>
                      <input
                        className={styles.review_write_inp}
                        placeholder="이름을 입력하세요(세글자 이상)"
                        onChange={changeName}
                        value={nameValue}
                        // onChange={onChangeTitle}
                        // value={title}
                      />
                    </div>{" "}
                    <div>
                      <label>연령대</label>
                      <select onChange={changeAge} value={ageValue}>
                        <option value="20">20대</option>
                        <option value="30">30대</option>
                        <option value="40">40대</option>
                        <option value="50">50대</option>
                        <option value="60">60대</option>
                        <option value="70">70대</option>
                        <option value="80">80대</option>
                      </select>
                    </div>
                  </div>{" "}
                  <div className={styles.review_inp_last}>
                    {" "}
                    <label>내용</label>
                    <textarea
                      className={styles.review_write_inp_area}
                      placeholder="내용을 입력해주세요"
                      onChange={changeArea}
                      value={areaValue}
                      // onChange={onChangeTitle}
                      // value={title}
                    ></textarea>
                    <span>{areaByte}/300</span>
                  </div>
                  {/* <div className={styles.review_open_box}>
                    <h4>공개 여부</h4>
                    <label>
                      <input
                        type="radio"
                        name="open"
                        value="Y"
                        onChange={() => change_open("Y")}
                        checked={openValue === "Y" ? true : false}
                      />{" "}
                      공개
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="open"
                        value="N"
                        onChange={() => change_open("N")}
                        checked={openValue === "N" ? true : false}
                      />{" "}
                      비공개
                    </label>
                  </div> */}
                  {/* <textarea></textarea> */}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewDetail;
