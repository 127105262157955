import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import styles from "../../../styles/admin.module.css";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";

function InvestIngCustomTable({
  columns,
  data,
  options,
  setOptions,
  arrayList,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    selectedFlatRows,
    prepareRow,
    //   canPreviousPage,
    //   canNextPage,
    pageOptions,
    pageCount, // 총 페이지
    gotoPage,
    //   nextPage,
    //   previousPage,
    //   setPageSize,
    state: { pageIndex, pageSize }, // pageIndex : 현재 페이지( 0 ~ 총페이지-1 ex) 1페이지 : 0 )
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // getToggleAllPageRowsSelectedProps : 페이지에 보여지는 row 전체 선택
          // getToggleAllRowsSelectedProps : 모든 row 전체 선택
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div style={{ display: "none" }}>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div style={{ display: "none" }}>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [

    //     {
    //       id: "selection",

    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //         </div>
    //       ),

    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  //체크박스 옵션
  const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = useRef();
      const resolvedRef = ref || defaultRef;

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            style={{ width: "32px" }}
          />
        </>
      );
    }
  );

  useEffect(() => {}, [pageIndex]);

  const btn_send = () => {
    // console.log(selectedFlatRows);
  };
  return (
    <>
      <table
        className="table_style"
        {...getTableProps()}
        style={{ margin: "auto" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}{" "}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            background: "rgb(0, 199, 60)",
                            // visibility: "visible",
                            lineHeight: "18px",
                            fontSize: "10px",
                            color: "#fff",
                            borderRadius: "3px",
                          }}
                        >
                          <BiSolidDownArrow style={{ marginBottom: "5px" }} />
                        </i>
                      ) : (
                        <i
                          style={{
                            display: "inline-block",
                            width: "15px",
                            height: "15px",
                            background: "rgb(0, 199, 60)",
                            // visibility: "visible",
                            lineHeight: "18px",
                            fontSize: "10px",
                            color: "#fff",
                            borderRadius: "3px",
                          }}
                        >
                          <BiSolidUpArrow style={{ marginBottom: "5px" }} />{" "}
                        </i>
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {arrayList.length > 0 &&
            page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="table_row">
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className={styles.table_pagination}>
        {pageIndex >= 10 && (
          <button onClick={() => gotoPage(Math.floor(pageIndex / 10) * 10 - 1)}>
            {"<"}
          </button>
        )}
        {pageOptions.map((page, index) => {
          if (pageIndex < 10 && index < 10) {
            return (
              <button
                key={index}
                onClick={() => gotoPage(index)}
                style={{
                  fontWeight: pageIndex === index ? "bold" : "normal",
                  color: pageIndex === index ? "#fff" : "#000",
                  background:
                    pageIndex === index ? "rgb(0, 199, 60)" : "#f5f5f5",

                  width: "30px",
                  height: "30px",
                  borderRadius: "5px",
                }}
              >
                {index + 1}
              </button>
            );
          } else if (pageIndex >= 10) {
            const currentPageGroup = Math.floor(pageIndex / 10); // 현재 페이지 그룹 번호 계산
            const startPageIndex = currentPageGroup * 10; // 현재 페이지 그룹의 첫 번째 페이지 인덱스
            const endPageIndex = Math.min(startPageIndex + 10, pageCount); // 현재 페이지 그룹의 마지막 페이지 인덱스
            if (index >= startPageIndex && index < endPageIndex) {
              return (
                <button
                  key={index}
                  onClick={() => gotoPage(index)}
                  style={{
                    fontWeight: pageIndex === index ? "bold" : "normal",
                    color: pageIndex === index ? "#fff" : "#000",
                    background:
                      pageIndex === index ? "rgb(0, 199, 60)" : "#f5f5f5",

                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                >
                  {index + 1}
                </button>
              );
            }
          }
          return null;
        })}
        {Math.floor(pageIndex / 10) < Math.floor(pageCount / 10) && (
          <button onClick={() => gotoPage(Math.floor(pageIndex / 10 + 1) * 10)}>
            {">"}
          </button>
        )}
      </div>
    </>
  );
}

export default InvestIngCustomTable;
