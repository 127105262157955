import { createAction, handleActions } from "redux-actions";
import * as authAPI from "../lib/api/auth";
import createRequestThunk from "../lib/api/createRequestThunk";

//로그인 후 유저정보 가져오기
const GET_ADMIN_NEWS_JOIN = "admin/GET_ADMIN_NEWS_JOIN";
const GET_ADMIN_NEWS_INTE = "admin/GET_ADMIN_NEWS_INTE";
const GET_ADMIN_NEWS_END = "admin/GET_ADMIN_NEWS_END";
const GET_ADMIN_NEWS_REFU = "admin/GET_ADMIN_NEWS_REFU";
const GET_ADMIN_NEWS_QNA = "admin/GET_ADMIN_NEWS_QNA";
const RESET = "admin/RESET";

export const getJoin = createAction(GET_ADMIN_NEWS_JOIN);
export const getInte = createAction(GET_ADMIN_NEWS_INTE);

export const getEnd = createAction(GET_ADMIN_NEWS_END);
export const getRefu = createAction(GET_ADMIN_NEWS_REFU);
export const getQna = createAction(GET_ADMIN_NEWS_QNA);

export const reset = createAction(RESET);

const initialState = {
  joinArr: null,
  inteArr: null,
  endArr: null,
  refuArr: null,
  qnaArr: null,
};

const user = handleActions(
  {
    [GET_ADMIN_NEWS_JOIN]: (state, action) => ({
      ...state,
      joinArr: action.payload,
    }),
    [GET_ADMIN_NEWS_INTE]: (state, action) => ({
      ...state,
      inteArr: action.payload,
    }),
    [GET_ADMIN_NEWS_END]: (state, action) => ({
      ...state,
      endArr: action.payload,
    }),
    [GET_ADMIN_NEWS_REFU]: (state, action) => ({
      ...state,
      refuArr: action.payload,
    }),
    [GET_ADMIN_NEWS_QNA]: (state, action) => ({
      ...state,
      qnaArr: action.payload,
    }),

    [RESET]: (state) => ({
      ...state,
      joinArr: null,
      inteArr: null,
      endArr: null,
      refuArr: null,
      qnaArr: null,
    }),
  },
  initialState
);

export default user;
