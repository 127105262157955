import React, { useMemo, useState, useEffect, useRef } from "react";
import CustomTable from "./CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "../CommonAxios";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/admin.module.css";
import { Link, useNavigate } from "react-router-dom";
import InvestCustomTable from "./table/InvestCustomTable";
import { wonComma } from "../../lib/wonComma";
import { numberToKorean } from "../../lib/numberToKorean";
const InvestList = () => {
  const navigate = useNavigate();
  const userDetailInfo = (no) => {
    navigate(`/admin/deposit/${no}`);
  };
  const TableColumn = useMemo(() => [
    {
      Header: "이메일",
      accessor: "invest_user_email",
      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => userDetailInfo(oriNo)}
            >
              {data.value}
            </div>
          </>
        );
      },
    },
    {
      Header: "이름",
      accessor: "invest_user_name",
      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => userDetailInfo(oriNo)}
            >
              {data.value}
            </div>
          </>
        );
      },
    },
    {
      Header: "투자상품명",
      accessor: "pro_title",
      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => userDetailInfo(oriNo)}
            >
              <span>{data.value ? data.value : "삭제된 상품"}</span>{" "}
              {data.row.original.invest_view_news === "N" && (
                <i className={styles.n_mark}>
                  <span>N</span>
                </i>
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "투자금액",
      accessor: "invest_amount",
      Cell: ({ cell: { value } }) => <>{wonComma(numberToKorean(value))}원</>,
    },
    {
      Header: "투자신청날짜",
      accessor: "invest_date",
      Cell: ({ cell: { value } }) => <Date value={value} />,
    },
    // {
    //   Header: "투자승인여부",
    //   accessor: "invest_confirm",
    //   Cell: (row) => {
    //     let data = row.cell;
    //     let oriNo = data.row.original.no;

    //     return (
    //       <>
    //         {data.value === "Y"
    //           ? data.row.original.invest_step_confirm === "Y"
    //             ? "승인"
    //             : "승인(대기중)"
    //           : "미승인"}
    //       </>
    //     );
    //   },
    // },
  ]);
  const Date = ({ value }) => {
    if (value) {
      return <Moment format="YYYY.MM.DD.">{value}</Moment>;
    } else {
      return <>수정기록없음</>;
    }
  };

  const St = ({ value }) => {
    if (value === "Y") {
      return <>승인</>;
    } else {
      return <>미승인</>;
    }
  };
  const Title = ({ row }) => {
    return (
      <div style={{ display: "flex", width: "200px" }}>
        <div
          style={{
            height: "40px",
            lineHeight: "40px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.original.notice_title}
        </div>
      </div>
    );
  };

  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState("all");

  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_deposit",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
          if (result.messageinfo.message.length === 0) {
            Swal.fire({
              title: "투자신청한 데이터가 없습니다.",

              confirmButtonText: "확인",
            }).then((res) => {
              if (res.isConfirmed) {
              }
            });
          }
        } else {
        }
      }
    );
  }, []);

  useEffect(() => {
    if (arrayList.length > 0) {
      if (options == "all") {
        if (filterWord == null) {
          setSendList(arrayList);
        } else {
          let list = arrayList.filter(
            (a) => a.invest_user_email.includes(filterWord) == true
          );
          setSendList(list);
        }
      } else if (options == "normal") {
        const norList = arrayList.filter((a) => a.user_kind == 3);
        if (filterWord == null) {
          setSendList(norList);
        } else {
          let list = norList.filter(
            (a) => a.invest_user_email.includes(filterWord) == true
          );
          setSendList(list);
        }
      } else if (options == "develop") {
        const devList = arrayList.filter((a) => a.user_kind == 2);
        if (filterWord == null) {
          setSendList(devList);
        } else {
          let list = devList.filter(
            (a) => a.invest_user_email.includes(filterWord) == true
          );
          setSendList(list);
        }
      } else {
        const admnList = arrayList.filter((a) => a.user_kind == 1);
        if (filterWord == null) {
          setSendList(admnList);
        } else {
          let list = admnList.filter(
            (a) => a.invest_user_email.includes(filterWord) == true
          );
          setSendList(list);
        }
      }
    }
  }, [arrayList, filterWord, options]);

  const filterUser = (e) => {
    setFilterWord(e.target.value);
  };

  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>투자 가입 신청 </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.user_search}>
            <span className={styles.search_icon}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </span>
            <input
              type="text"
              onChange={(e) => filterUser(e)}
              placeholder="찾고자 하는 아이디, 이름 등을 검색하세요."
            />
          </div>
          {/* 전체사용 일반사용자 */}

          <InvestCustomTable
            columns={TableColumn}
            data={sendList}
            options={options}
            setOptions={setOptions}
            arrayList={arrayList}
            setArrayList={setArrayList}
          />
        </div>
      </div>
    </>
  );
};

export default InvestList;
