import React from "react";

import main from "../../../styles/main.module.css";
import ex from "../../../assets/images/img_6.jpg";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/basicImg.png";
const MainNewsSkeleton = () => {
  return (
    <>
      <div className={main.main_news_item}>
        <div className={main.main_news_inner}>
          <div className={`${main.main_news_img} ${main.main_skeleton_img}`}>
            {/* <img src="" /> */}
          </div>
          <div className={main.main_news_content}>
            {/* <h4 className={`${main.main_news_title}`}>
              <span className={main.ellipsis}>{title}</span>
            </h4> */}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>{" "}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>
            {/* <p className={`${main.main_news_cont} ${main.two_ellipsis}`}>
              {title}
            </p> */}
          </div>
        </div>
      </div>{" "}
      <div className={main.main_news_item}>
        <div className={main.main_news_inner}>
          <div className={`${main.main_news_img} ${main.main_skeleton_img}`}>
            {/* <img src="" /> */}
          </div>
          <div className={main.main_news_content}>
            {/* <h4 className={`${main.main_news_title}`}>
              <span className={main.ellipsis}>{title}</span>
            </h4> */}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>{" "}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>
            {/* <p className={`${main.main_news_cont} ${main.two_ellipsis}`}>
              {title}
            </p> */}
          </div>
        </div>
      </div>{" "}
      <div className={main.main_news_item}>
        <div className={main.main_news_inner}>
          <div className={`${main.main_news_img} ${main.main_skeleton_img}`}>
            {/* <img src="" /> */}
          </div>
          <div className={main.main_news_content}>
            {/* <h4 className={`${main.main_news_title}`}>
              <span className={main.ellipsis}>{title}</span>
            </h4> */}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>{" "}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>
            {/* <p className={`${main.main_news_cont} ${main.two_ellipsis}`}>
              {title}
            </p> */}
          </div>
        </div>
      </div>{" "}
      <div className={main.main_news_item}>
        <div className={main.main_news_inner}>
          <div className={`${main.main_news_img} ${main.main_skeleton_img}`}>
            {/* <img src="" /> */}
          </div>
          <div className={main.main_news_content}>
            {/* <h4 className={`${main.main_news_title}`}>
              <span className={main.ellipsis}>{title}</span>
            </h4> */}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>{" "}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>
            {/* <p className={`${main.main_news_cont} ${main.two_ellipsis}`}>
              {title}
            </p> */}
          </div>
        </div>
      </div>{" "}
      <div className={main.main_news_item}>
        <div className={main.main_news_inner}>
          <div className={`${main.main_news_img} ${main.main_skeleton_img}`}>
            {/* <img src="" /> */}
          </div>
          <div className={main.main_news_content}>
            {/* <h4 className={`${main.main_news_title}`}>
              <span className={main.ellipsis}>{title}</span>
            </h4> */}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>{" "}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>
            {/* <p className={`${main.main_news_cont} ${main.two_ellipsis}`}>
              {title}
            </p> */}
          </div>
        </div>
      </div>{" "}
      <div className={main.main_news_item}>
        <div className={main.main_news_inner}>
          <div className={`${main.main_news_img} ${main.main_skeleton_img}`}>
            {/* <img src="" /> */}
          </div>
          <div className={main.main_news_content}>
            {/* <h4 className={`${main.main_news_title}`}>
              <span className={main.ellipsis}>{title}</span>
            </h4> */}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>{" "}
            <p
              className={`${main.main_news_cont} ${main.main_skeleton_cont}`}
            ></p>
            {/* <p className={`${main.main_news_cont} ${main.two_ellipsis}`}>
              {title}
            </p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default MainNewsSkeleton;
