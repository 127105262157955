import React , {useState, useEffect, useRef} from "react";
import CountUp from 'react-countup';

const CountNum = () => {

  const [ invCash, setInvCash ] = useState(0);
  const [ profit, setProfit ] = useState(0);
  const [ starts, setStarts ] = useState(0);

  useEffect(() => {
    if(invCash == 0){

    } else {
      setProfit(invCash/10);
      setStarts(profit);
    }
  },[invCash])

  const chgCash = (e) => {
    setInvCash(e.target.value);
  }

  return (
    <div style={{margin: "30px auto", width: "800px"}}>
      <span>{invCash == 0 ? "-" : invCash}만원 투자하면</span>
      <div style={{fontSize:"20px", color:"green", fontWeight:"bold"}}>{invCash == 0 ? <span>-</span> :<CountUp start={starts} end={profit} duration={2} /> }만원을 받아요.</div>
      <div style={{width: "100%",height:"60px", padding: "10px", borderRadius:"5px", border:"1px solid black", boxSizing:"border-box", marginTop:"10px", marginBottom:"10px", display:"flex", justifyContent:"space-between"}}>
        <div style={{width:"80px", lineHeight:"40px"}} >투자금액</div>
        <input style={{fontSize:"15px", width:"100%", border:"0", textAlign:"right"}} value={invCash == 0 ? "-" : invCash } onChange={chgCash} />
        <span style={{width:"40px", lineHeight:"38px"}}>만원</span>
      </div>
      <div style={{display:"flex", justifyContent:"space-between"}}>
        <button className="btn" style={{width:"24%", backgroundColor:"rgb(211,211,211)", border:"0", height:"50px", textAlign:"center", borderRadius:"10px", cursor:"pointer"}} onClick={()=>setInvCash(invCash+100)}>+100만</button>
        <button className="btn" style={{width:"24%", backgroundColor:"rgb(211,211,211)", border:"0", height:"50px", textAlign:"center", borderRadius:"10px", cursor:"pointer"}} onClick={()=>setInvCash(invCash+500)}>+500만</button>
        <button className="btn" style={{width:"24%", backgroundColor:"rgb(211,211,211)", border:"0", height:"50px", textAlign:"center", borderRadius:"10px", cursor:"pointer"}} onClick={()=>setInvCash(invCash+1000)}>+1000만</button>
        <button className="btn" style={{width:"24%", backgroundColor:"rgb(211,211,211)", border:"0", height:"50px", textAlign:"center", borderRadius:"10px", cursor:"pointer"}} onClick={()=>setInvCash(invCash+3000)}>+3000만</button>
      </div>
    </div>
  );
}

export default CountNum;