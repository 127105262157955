import styles from "../../../styles/admin.module.css";
import { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import AdminUserInfo from "./AdminUserInfo";
import ToastEditor from "../editor/ToastEditor";
import { textReplace } from "../../../lib/textReplace";
import { FcEditImage } from "react-icons/fc";
const FnqWriteDetail = () => {
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  const imgNameRef = useRef(); //이미지 파일 이름 REF
  const [imageFile, setImageFile] = useState(""); //이미지 속성정보
  const [getimage, setGetImage] = useState(""); //render.result 값
  const [openValue, setOpenValue] = useState("Y");
  const [qu, setQu] = useState("");
  const [an, setAn] = useState("");
  const [agency, setAgency] = useState("");
  const [dateValue, setDateValue] = useState("");
  const changeQu = useCallback((e) => {
    setQu(e.target.value);
  }, []);

  const changeAn = useCallback((e) => {
    setAn(e.target.value);
  }, []);
  const changeAgency = useCallback((e) => {
    setAgency(e.target.value);
  }, []);

  const changeDate = useCallback((e) => {
    setDateValue(e.target.value);
  }, []);

  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 500;

    if (file[0] === undefined) {
      return;
    }

    setImageFile(file);
  };

  useEffect(() => {
    preview(); // 미리보기
  });

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      imageRef.current.src = reader.result;
      setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };

  // const checkOnlyOne = (element) => {
  //   const checkboxes = document.getElementsByName("open");

  //   checkboxes.forEach((cb) => {
  //     cb.checked = false;
  //   });

  //   element.checked = true;
  // };
  const navigate = useNavigate();
  const [imgArr, setImgArr] = useState([]);
  const btn_fnq_reg = () => {
    if (!qu || !openValue || !an) {
      alert("체크 필수");
      return;
    }

    let sendData = {
      qu: textReplace(qu),
      an: textReplace(an),
      open: openValue,

      // mobile :
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_insert_fnq",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          navigate("/admin/fnq");
        } else {
          navigate("/admin/fnq");
        }
      }
    );
  };
  const change_open = (data) => {
    setOpenValue(data);
  };
  return (
    <>
      <div className={styles.fnq_write_wrap}>
        <div className={styles.fnq_write_inner}>
          <div className={styles.fnq_write_title}>
            <h4>자주하는 질문 작성</h4>
            <div className={styles.fnq_write_button}>
              <button onClick={btn_fnq_reg}>등록</button>
            </div>
          </div>
          <div className={styles.fnq_write_content}>
            <div className={styles.fnq_write_bottom}>
              <div>
                <label>질문</label>
                <input
                  className={styles.fnq_write_inp}
                  placeholder="질문을 입력해주세요."
                  onChange={changeQu}
                  value={qu}
                  // onChange={onChangeTitle}
                  // value={title}
                />
              </div>
              <div>
                {" "}
                <label>답변</label>
                <textarea
                  className={styles.fnq_write_inp_area}
                  onChange={changeAn}
                  value={an}
                  placeholder="답변을 입력해주세요."
                ></textarea>
              </div>
              {/* <div className={styles.fnq_open_box}>
                <h4>공개 여부</h4>
                <label>
                  <input
                    type="radio"
                    name="open"
                    value="Y"
                    onChange={() => change_open("Y")}
                  />{" "}
                  공개
                </label>
                <label>
                  <input
                    type="radio"
                    name="open"
                    value="N"
                    onChange={() => change_open("N")}
                  />{" "}
                  비공개
                </label>
              </div> */}

              {/* <textarea></textarea> */}
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default FnqWriteDetail;
