import CompanyBanner from "./detail/CompanyBanner";
import CompanyNews from "./detail/CompanyNews";
import CompanyQna from "./detail/CompanyQna";
import CompanyReview from "./detail/CompanyReview";
import CompanyStory from "./detail/CompanyStory";

const Company = () => {
  return (
    <>
      <div
        className="common-wrap"
        style={{ minHeight: "1770px", background: "#EBEEF3" }}
      >
        <div style={{ background: "#fff" }}>
          <CompanyBanner />
        </div>
        <div style={{ background: "#fff", marginTop: "10px" }}>
          {" "}
          <div className="common-inner-wrap">
            <CompanyNews />{" "}
          </div>
        </div>
        <div style={{ background: "#fff", marginTop: "10px" }}>
          {" "}
          <div className="common-inner-wrap">
            <CompanyStory />{" "}
          </div>
        </div>
        <div style={{ background: "#fff", marginTop: "10px" }}>
          {" "}
          <div className="common-inner-wrap">
            <CompanyReview />{" "}
          </div>
        </div>
        <div style={{ background: "#fff", marginTop: "10px" }}>
          {" "}
          <div className="common-inner-wrap">
            <CompanyQna />{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Company;
