import main from "../../../styles/main.module.css";
import ex from "../../../assets/images/img_6.jpg";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
const MainRankDetail = ({ data1 }) => {
  const {
    pro_content,
    pro_category,
    pro_rate,
    category_name,
    pro_title,
    c,
    pro_img,
    ROWNUM,
    no,
    pro_company,
  } = data1;
  const navigate = useNavigate();
  const btn_move = (num) => {
    let sendData = {
      no: num,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/main_funding_click",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );

    navigate(`/invest/${num}/process`);

    // navigate(`/notice/${no}`);
  };

  return (
    <>
      <div
        className={main.main_rank_item}
        onClick={() => btn_move(no)}
        style={{ cursor: "pointer" }}
      >
        <div className={main.main_rank_item_inner}>
          <span className={main.main_rank_num}>{ROWNUM}</span>
          <div className={main.main_rank_img_mobile}>
            <img src={`/product/${no}/${pro_img}`} />
          </div>
          <div className={main.main_rank_content}>
            <p className={main.main_rank_title}>
              [{category_name}] {pro_title}
            </p>

            <div className={main.main_rank_detail}>
              <em>{pro_rate}% </em>
              {/* <span>{pro_company}</span> */}
            </div>
            {/* <p className={main.main_rank_cont}> */}
            {/* {pro_date ? <Moment format="YYYY-MM-DD">{pro_date}</Moment> : ""}{" "} */}
            {/* </p> */}
          </div>
          <div className={main.main_rank_img}>
            <img src={`/product/${no}/${pro_img}`} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainRankDetail;
