import MypageQnaDetail from "../components/mypage/MypageQnaDetail";

const MypageQna = () => {
  return (
    <>
      <MypageQnaDetail />
    </>
  );
};

export default MypageQna;
