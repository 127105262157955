import MypageDeleteDetail from "../components/mypage/MypageDeleteDetail";

const MypageDelete = () => {
  return (
    <>
      <MypageDeleteDetail />
    </>
  );
};

export default MypageDelete;
