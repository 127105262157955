import MypageInvestDetail2 from "../components/mypage/MypageInvestDetail2";

const MypageInvestPage = () => {
  return (
    <>
      <MypageInvestDetail2 />
    </>
  );
};

export default MypageInvestPage;
