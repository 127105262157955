import React, { useMemo, useState, useEffect, useRef } from "react";
import CustomTable from "./CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "../CommonAxios";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/admin.module.css";
import { Link, useNavigate } from "react-router-dom";
import NoticeCustomTable from "./table/NoticeCustomTable";
import PressCustomTable from "./table/PressCustomTable";

const PressList = () => {
  const navigate = useNavigate();
  const userDetailInfo = (no) => {
    navigate(`/admin/press/detail/${no}`);
  };
  const TableColumn = useMemo(() => [
    {
      Header: "이미지",
      accessor: "press_img",
      Cell: (row) => {
        let data = row.cell;
        let oriNo = data.row.original.no;

        return (
          <>
            <div
              style={{
                cursor: "pointer",
                width: "95px",
                height: "50px",
                margin: "0 auto",
              }}
              onClick={() => userDetailInfo(oriNo)}
            >
              {data.value && (
                <img
                  src={"/press/" + oriNo + "/" + data.value}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: "제목",
      accessor: "press_title",

      Cell: ({ row }) => <Title row={row} />,
    },
    {
      Header: "출처",
      accessor: "press_agency",

      Cell: ({ cell: { value } }) => <>{value}</>,
    },
    // {
    //   Header: "공개여부",
    //   accessor: "press_open",
    //   Cell: ({ cell: { value } }) => <>{value == "Y" ? "공개" : "비공개"}</>,
    // },
    {
      Header: "게시날짜",
      accessor: "press_date",
      Cell: ({ cell: { value } }) => <Date value={value} />,
    },
    // {
    //   Header: "수정날짜",
    //   accessor: "notice_update_date",
    //   Cell: ({ cell: { value } }) => <Date value={value} />,
    // },
  ]);
  const Date = ({ value }) => {
    if (value) {
      return <Moment format="YYYY.MM.DD.">{value}</Moment>;
    } else {
      return <>수정기록없음</>;
    }
  };
  const Title = ({ row }) => {
    return (
      <div
        style={{ display: "flex", width: "200px", cursor: "pointer" }}
        onClick={() => userDetailInfo(row.original.no)}
      >
        <div
          style={{
            height: "40px",
            lineHeight: "40px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.original.press_title}
        </div>
      </div>
    );
  };

  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState("all");

  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_press",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
    // let sendData = {};

    // CommonAxios.CommonAxios(
    //     process.env.REACT_APP_HOSTDONAME + "/api/select_users",
    //     sendData,
    //     function (result) {
    //       if (result.messageinfo.state == "ok") {
    //         setArrayList(result.messageinfo.message);
    //       } else {
    //           Swal.fire({
    //           title: "조회 실패",
    //           icon: "error",
    //           confirmButtonText: "확인",
    //           }).then((res) => {
    //               if (res.isConfirmed) {
    //               }
    //           });
    //       }
    //     }
    // );
    // setArrayList(UserData);
  }, []);

  useEffect(() => {
    if (arrayList.length > 0) {
      if (options == "all") {
        if (filterWord == null) {
          setSendList(arrayList);
        } else {
          let list = arrayList.filter(
            (a) => a.press_title.includes(filterWord) == true
          );
          setSendList(list);
        }
      } else if (options == "normal") {
        const norList = arrayList.filter((a) => a.press_open == "Y");
        if (filterWord == null) {
          setSendList(norList);
        } else {
          let list = norList.filter(
            (a) => a.press_title.includes(filterWord) == true
          );
          setSendList(list);
        }
      } else if (options == "develop") {
        const devList = arrayList.filter((a) => a.user_kind == 2);
        if (filterWord == null) {
          setSendList(devList);
        } else {
          let list = devList.filter(
            (a) => a.press_title.includes(filterWord) == true
          );
          setSendList(list);
        }
      } else {
        const admnList = arrayList.filter((a) => a.user_kind == 1);
        if (filterWord == null) {
          setSendList(admnList);
        } else {
          let list = admnList.filter(
            (a) => a.press_title.includes(filterWord) == true
          );
          setSendList(list);
        }
      }
    }
  }, [arrayList, filterWord, options]);

  const filterUser = (e) => {
    setFilterWord(e.target.value);
  };

  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          회사소개 &nbsp;〉 &nbsp;언론보도 목록{" "}
          <span className={styles.header_button}>
            <button
              onClick={() => {
                navigate("/admin/press/write");
              }}
            >
              언론보도 등록
            </button>
          </span>
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.user_search}>
            <span className={styles.search_icon}>
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </span>
            <input
              type="text"
              onChange={(e) => filterUser(e)}
              placeholder="찾고자 하는 제목을 검색하세요."
            />
          </div>
          {/* 전체사용 일반사용자 */}

          <PressCustomTable
            columns={TableColumn}
            data={sendList}
            options={options}
            setOptions={setOptions}
            arrayList={arrayList}
            setArrayList={setArrayList}
            setSendList={setSendList}
          />
        </div>
      </div>
    </>
  );
};

export default PressList;
