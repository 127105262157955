import React, { useState, useEffect, useRef, forwardRef } from "react";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import styles from "../../styles/admin.module.css";

function CustomSubTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    rows,
    pageOptions,
    pageCount, // 총 페이지
    gotoPage,
    state: { pageIndex, pageSize }, // pageIndex : 현재 페이지( 0 ~ 총페이지-1 ex) 1페이지 : 0 )
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useSortBy,
    usePagination,
    useRowSelect
  );

  useEffect(() => {}, [pageIndex]);

  return (
    <>
      <table
        className="table_style"
        {...getTableProps()}
        style={{
          margin: " 20px auto",
          width: "90%",
          textAlign: "center",
          border: "2px solid gray",
        }}
      >
        <thead style={{ backgroundColor: "#eaeaea" }}>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{
                borderBottom: "1px solid #ccc",
                fontSize: "13px",
                height: "45px",
              }}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{ borderRight: "1px solid #ccc" }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="table_row"
                style={{
                  borderBottom: "1px solid #ccc",
                  fontSize: "13px",
                  height: "45px",
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.table_pagination}>
        {pageIndex >= 10 && (
          <button onClick={() => gotoPage(Math.floor(pageIndex / 10) * 10 - 1)}>
            {"<"}
          </button>
        )}
        {pageOptions.map((page, index) => {
          if (pageIndex < 10 && index < 10) {
            return (
              <button
                key={index}
                onClick={() => gotoPage(index)}
                style={{
                  fontWeight: pageIndex === index ? "bold" : "normal",
                  color: pageIndex === index ? "#fff" : "#000",
                  background:
                    pageIndex === index ? "rgb(0, 199, 60)" : "#f5f5f5",

                  width: "30px",
                  height: "30px",
                  borderRadius: "5px",
                }}
              >
                {index + 1}
              </button>
            );
          } else if (pageIndex >= 10) {
            const currentPageGroup = Math.floor(pageIndex / 10); // 현재 페이지 그룹 번호 계산
            const startPageIndex = currentPageGroup * 10; // 현재 페이지 그룹의 첫 번째 페이지 인덱스
            const endPageIndex = Math.min(startPageIndex + 10, pageCount); // 현재 페이지 그룹의 마지막 페이지 인덱스
            if (index >= startPageIndex && index < endPageIndex) {
              return (
                <button
                  key={index}
                  onClick={() => gotoPage(index)}
                  style={{
                    fontWeight: pageIndex === index ? "bold" : "normal",
                    color: pageIndex === index ? "#fff" : "#000",
                    background:
                      pageIndex === index ? "rgb(0, 199, 60)" : "#f5f5f5",

                    width: "30px",
                    height: "30px",
                    borderRadius: "5px",
                  }}
                >
                  {index + 1}
                </button>
              );
            }
          }
          return null;
        })}
        {Math.floor(pageIndex / 10) < Math.floor(pageCount / 10) && (
          <button onClick={() => gotoPage(Math.floor(pageIndex / 10 + 1) * 10)}>
            {">"}
          </button>
        )}
      </div>
    </>
  );
}

export default CustomSubTable;
