import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CommonAxios } from "../components/CommonAxios";
import { useNavigate } from "react-router-dom";
// import { getSnsUsers, logout } from "../modules/user";
import { useDispatch } from "react-redux";

// import { useSelector, useDispatch } from "react-redux";
import { encrypt } from "../lib/api/tokenCrypto";
import Swal from "sweetalert2";
import axios from "axios";
import { ramdomASCIIDate } from "../lib/radomASCII";
import { getUsers } from "../modules/user";
const { naver } = window;

function NaverLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { user } = useSelector(({ user }) => ({
  //   user: user.user,
  // }));
  const location = useLocation();
  const initializeNaverLogin = () => {
    const naverLogin = new naver.LoginWithNaverId({
      clientId: process.env.REACT_APP_NAVER_ID,
      callbackUrl: process.env.REACT_APP_NAVER_REDIRECT_URL,
      isPopup: false, // popup 형식으로 띄울것인지 설정
      loginButton: { color: "white", type: 1, height: "47" }, //버튼의 스타일, 타입, 크기를 지정
    });
    naverLogin.init();
    naverLogin.logout();

    naverLogin.getLoginStatus((status) => {
      if (status) {
        let sendData = {
          id: "naver_" + naverLogin.user.id,
          name: naverLogin.user.name,
          email: naverLogin.user.email,
          mobile: "",
          recomendcode: ramdomASCIIDate(),
          // image: naverLogin.user.profile_image,
          type: 3,
          // nickname: "funers",
          // mode: "일반",
        };
        if (
          naverLogin.user.mobile !== undefined &&
          naverLogin.user.mobile !== ""
        ) {
          sendData.mobile = naverLogin.user.mobile.replaceAll("-", "");
        }
        CommonAxios(
          process.env.REACT_APP_HOSTDONAME + "/api/sns_signin",
          sendData,
          function (result) {
            if (result.messageinfo.state == "ok") {
              // dispatch(getUsers(sendData));
              localStorage.setItem(
                "meta_info",
                encrypt(result.messageinfo.message, "meta_info")
              );
              dispatch(getUsers(result.messageinfo.message));
              document.location.href = "/";
            } else {
              console.log("실패");
            }
          }
        );
        // CommonAxios.CommonAxios(
        //   process.env.REACT_APP_HOSTDONAME + "/api/sns_signin",
        //   sendData,
        //   function (result) {
        //     console.log(result);
        //     if (result.messageinfo.state == "ok") {
        //       dispatch(getUsers(sendData));
        //       document.location.href = "/";
        //     } else {
        //       console.log("실패");
        //     }
        //   }
        // );
        // dispatch(getSnsUsers(sendData));
      }
    });
  };

  const userAccessToken = () => {
    if (!location.hash) return;
    const token = location.hash.split("=")[1].split("&")[0];

    // console.log(token);
  };
  useEffect(() => {
    initializeNaverLogin();
    userAccessToken();
  }, []);

  // useEffect(() => {
  //   const error2 = "탈퇴한 계정";

  //   if (user !== null && user === error2) {
  //     Swal.fire({
  //       title: "로그인 실패",
  //       text: "탈퇴한 계정입니다.(고객센터 문의)",
  //       icon: "error",
  //       confirmButtonText: "확인",
  //       confirmButtonColor: "#FF0000",
  //       allowOutsideClick: false,
  //     });
  //     dispatch(logout());
  //   } else if (user !== null && user !== error2) {
  //     localStorage.setItem("serveInfo", encrypt(user, "serveInfo"));
  //     const url = process.env.REACT_APP_HOSTDONAME + "/api/googleMobile";
  //     let config = {
  //       user,
  //     };
  //     axios.post(url, config).then((res) => {
  //       if (res.data.messageinfo.message[0].user_mobile !== "") {
  //         navigate("/");
  //       } else {
  //         Swal.fire({
  //           title: "핸드폰 인증이 필요합니다.",
  //           icon: "error",
  //           confirmButtonText: "확인",
  //           confirmButtonColor: "#FF0000",
  //         }).then((res) => {
  //           if (res.isConfirmed) {
  //             document.location.replace("/myinfo");
  //           }
  //         });
  //       }
  //     });
  //   }
  // }, [navigate, user]);
  return (
    <>
      <div id="naverIdLogin" style={{ display: "none" }} />
    </>
  );
}

export default NaverLogin;
