import intro from "../../../styles/intro.module.css";
// import img from "../../../assets/images/img_1.jpg";

const Intro_1 = ({ data }) => {
  return (
    data.length > 0 && (
      <>
        <div className={intro.intro_common_wrap}>
          <div className={intro.intro_common}>
            {data[2].intro_img_yn === "N" && (
              <>
                {data[2].intro_title && (
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: data[2].intro_title.replaceAll(
                        /(\n|\r\n)/g,
                        "<br/>"
                      ),
                    }}
                    className={intro.title}
                  ></h2>
                )}
                <div className={intro.intro_content}>
                  <div className={intro.content_1}>
                    {" "}
                    {data[2].intro_strong && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[2].intro_strong.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></p>
                    )}
                    {/* <p>사업계획</p> */}
                  </div>
                  <div className={intro.content_2}>
                    {data[2].intro_text && (
                      <div
                        className={intro.content_text}
                        dangerouslySetInnerHTML={{
                          __html: data[2].intro_text.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></div>
                    )}

                    <div className={intro.content_text_detail}>
                      {data[2].intro_subject_1 && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: data[2].intro_subject_1.replaceAll(
                              /(\n|\r\n)/g,
                              "<br/>"
                            ),
                          }}
                        ></span>
                      )}

                      {data[2].intro_content_1 && (
                        <em
                          dangerouslySetInnerHTML={{
                            __html: data[2].intro_content_1.replaceAll(
                              /(\n|\r\n)/g,
                              "<br/>"
                            ),
                          }}
                        ></em>
                      )}
                    </div>
                    <div className={intro.content_text_detail}>
                      {data[2].intro_subject_2 && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: data[2].intro_subject_2.replaceAll(
                              /(\n|\r\n)/g,
                              "<br/>"
                            ),
                          }}
                        ></span>
                      )}

                      {data[2].intro_content_2 && (
                        <em
                          dangerouslySetInnerHTML={{
                            __html: data[2].intro_content_2.replaceAll(
                              /(\n|\r\n)/g,
                              "<br/>"
                            ),
                          }}
                        ></em>
                      )}
                    </div>
                  </div>{" "}
                </div>
              </>
            )}
            {data[2].intro_img_yn === "Y" && (
              <img src={`/introduce/${data[2].no}/${data[2].intro_img_1}`} />
            )}
          </div>
        </div>
        <div className={intro.intro_common_wrap}>
          {" "}
          <div className={intro.intro_common}>
            {" "}
            {data[3].intro_img_yn === "N" && (
              <>
                {data[3].intro_title && (
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: data[3].intro_title.replaceAll(
                        /(\n|\r\n)/g,
                        "<br/>"
                      ),
                    }}
                    className={intro.title}
                  ></h2>
                )}
                <div className={intro.intro_content}>
                  <div className={intro.content_1}>
                    {" "}
                    {data[3].intro_strong && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[3].intro_strong.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></p>
                    )}
                    {/* <p>사업계획</p> */}
                  </div>
                  <div className={intro.content_2}>
                    {data[3].intro_text && (
                      <div
                        className={intro.content_text}
                        dangerouslySetInnerHTML={{
                          __html: data[3].intro_text.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></div>
                    )}

                    <div className={intro.content_text_detail}>
                      {data[3].intro_subject_1 && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: data[3].intro_subject_1.replaceAll(
                              /(\n|\r\n)/g,
                              "<br/>"
                            ),
                          }}
                        ></span>
                      )}

                      {data[3].intro_content_1 && (
                        <em
                          dangerouslySetInnerHTML={{
                            __html: data[3].intro_content_1.replaceAll(
                              /(\n|\r\n)/g,
                              "<br/>"
                            ),
                          }}
                        ></em>
                      )}
                    </div>
                  </div>{" "}
                </div>{" "}
              </>
            )}
            {data[3].intro_img_yn === "Y" && (
              <img src={`/introduce/${data[3].no}/${data[3].intro_img_1}`} />
            )}
          </div>
        </div>
        <div className={intro.intro_common_wrap}>
          {" "}
          <div className={intro.intro_common}>
            {" "}
            {data[4].intro_img_yn === "N" && (
              <>
                {data[4].intro_title && (
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: data[4].intro_title.replaceAll(
                        /(\n|\r\n)/g,
                        "<br/>"
                      ),
                    }}
                    className={intro.title}
                  ></h2>
                )}
                <div className={intro.intro_content}>
                  <div className={intro.content_1}>
                    {" "}
                    {data[4].intro_strong && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[4].intro_strong.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></p>
                    )}
                    {/* <p>사업계획</p> */}
                  </div>
                  <div className={intro.content_2}>
                    {data[4].intro_text && (
                      <div
                        className={intro.content_text}
                        dangerouslySetInnerHTML={{
                          __html: data[4].intro_text.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></div>
                    )}
                  </div>{" "}
                </div>{" "}
              </>
            )}
            {data[4].intro_img_yn === "Y" && (
              <img src={`/introduce/${data[4].no}/${data[4].intro_img_1}`} />
            )}
          </div>
        </div>
        <div className={intro.intro_common_wrap}>
          {" "}
          <div className={intro.intro_common}>
            {" "}
            {data[5].intro_img_yn === "N" && (
              <>
                {data[5].intro_title && (
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: data[5].intro_title.replaceAll(
                        /(\n|\r\n)/g,
                        "<br/>"
                      ),
                    }}
                    className={intro.title}
                  ></h2>
                )}
                <div className={intro.intro_content}>
                  <div className={intro.content_1}>
                    {" "}
                    {data[5].intro_strong && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data[5].intro_strong.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></p>
                    )}
                    {/* <p>사업계획</p> */}
                  </div>
                  <div className={intro.content_2}>
                    {data[5].intro_text && (
                      <div
                        className={intro.content_text}
                        dangerouslySetInnerHTML={{
                          __html: data[5].intro_text.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></div>
                    )}
                  </div>{" "}
                </div>{" "}
              </>
            )}
            {data[5].intro_img_yn === "Y" && (
              <img src={`/introduce/${data[5].no}/${data[5].intro_img_1}`} />
            )}
          </div>
        </div>
      </>
    )
  );
};

export default Intro_1;
