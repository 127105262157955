import React, { useState, useEffect } from "react";
import logo from "../logo.svg";
// import "../App.css";

import MainBanner from "../components/main/MainBanner";
import MainSection1 from "../components/main/MainSection1";
import SuccessAlert from "../components/alert/Alert";
import { Link } from "react-router-dom";
import notice from "../styles/notice.module.css";
import NoticeDetail from "../components/notice/NoticeDetail";
import QnaDetail from "../components/qna/QnaDetail";
import MypageQnaWriteDetail from "../components/mypage/MypageQnaWriteDetail";
import MypageQnaViewDetail from "../components/mypage/MypageQnaViewDetail";
function MypageQnaView() {
  const [message, setMessage] = useState("");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  return (
    <>
      <MypageQnaViewDetail />
    </>
  );
}

export default MypageQnaView;
