import styles from "../../../styles/admin.module.css";
import { useState, useEffect, useRef, useCallback } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../../CommonAxios";
import AdminUserInfo from "./AdminUserInfo";
import ToastEditor from "../editor/ToastEditor";
import { textReplace } from "../../../lib/textReplace";
import { FcEditImage } from "react-icons/fc";
import { wonComma } from "../../../lib/wonComma";
import Swal from "sweetalert2";
import { getTextTotal, textOverCut } from "../../../lib/stringLenghts";
const ReviewWriteDetail = () => {
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  const imgNameRef = useRef(); //이미지 파일 이름 REF
  const [imageFile, setImageFile] = useState(""); //이미지 속성정보
  const [getimage, setGetImage] = useState(""); //render.result 값
  const [openValue, setOpenValue] = useState("Y");
  const [ageValue, setAgeValue] = useState(20);
  const [areaValue, setAreaValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [investValue, setInvestValue] = useState("");
  const changeAge = useCallback((e) => {
    setAgeValue(e.target.value);
  }, []);
  const [areaByte, setAreaByte] = useState(0);
  const changeArea = useCallback((e) => {
    let byte = getTextTotal(e.target.value, 300);
    let text = textOverCut(e.target.value, 300);

    setAreaValue(text);
    setAreaByte(byte.byte);
  }, []);
  const changeName = useCallback((e) => {
    setNameValue(e.target.value);
  }, []);

  const changeInvest = useCallback((e) => {
    setInvestValue(wonComma(e.target.value.replace(/[^\d]+/g, "")));
  }, []);

  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 500;

    if (file[0] === undefined) {
      return;
    }
    setImageFile(file);
  };

  useEffect(() => {
    preview(); // 미리보기
  });

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      imageRef.current.src = reader.result;
      setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };

  // const checkOnlyOne = (element) => {
  //   const checkboxes = document.getElementsByName("open");

  //   checkboxes.forEach((cb) => {
  //     cb.checked = false;
  //   });

  //   element.checked = true;
  // };
  const navigate = useNavigate();
  const [imgArr, setImgArr] = useState([]);
  const btn_review_reg = () => {
    if (!openValue || !areaValue || !nameValue || !investValue) {
      return Swal.fire({
        title: "빈 칸을 채워주세요.",

        confirmButtonText: "확인",
      });
    }

    let sendData = {
      investValue: investValue.replace(/[^\d]+/g, ""),

      nameValue: textReplace(nameValue),
      ageValue: ageValue,
      areaValue: textReplace(areaValue),

      open: openValue,

      // mobile :
    };

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_insert_review",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          navigate("/admin/review");
        } else {
          navigate("/admin/review");
        }
      }
    );
  };
  const change_open = (data) => {
    setOpenValue(data);
  };
  return (
    <>
      <div className={styles.review_write_wrap}>
        <div className={styles.review_write_inner}>
          <div className={styles.review_write_title}>
            <h4>리뷰 작성</h4>
            <div className={styles.review_write_button}>
              <button onClick={btn_review_reg}>등록</button>
            </div>
          </div>
          <div className={styles.review_write_content}>
            <div className={styles.review_write_bottom}>
              <div className={styles.review_inp_first}>
                <div>
                  <label>투자누적금액</label>
                  <input
                    className={styles.review_write_inp}
                    placeholder="숫자만 입력하세요."
                    onChange={changeInvest}
                    value={investValue}
                    // onChange={onChangeTitle}
                    // value={title}
                  />
                </div>
                <div>
                  <label>이름</label>
                  <input
                    className={styles.review_write_inp}
                    placeholder="이름을 입력하세요(세글자 이상)"
                    onChange={changeName}
                    value={nameValue}
                    // onChange={onChangeTitle}
                    // value={title}
                  />
                </div>{" "}
                <div>
                  <label>연령대</label>
                  <select onChange={changeAge}>
                    <option value="20">20대</option>
                    <option value="30">30대</option>
                    <option value="40">40대</option>
                    <option value="50">50대</option>
                    <option value="60">60대</option>
                    <option value="70">70대</option>
                    <option value="80">80대</option>
                  </select>
                </div>
              </div>{" "}
              <div className={styles.review_inp_last}>
                {" "}
                <label>내용</label>
                <textarea
                  className={styles.review_write_inp_area}
                  placeholder="내용을 입력해주세요"
                  onChange={changeArea}
                  value={areaValue}
                  // onChange={onChangeTitle}
                  // value={title}
                ></textarea>{" "}
                <span>{areaByte}/300</span>
              </div>
              {/* <div className={styles.review_open_box}>
                <h4>공개 여부</h4>
                <label>
                  <input
                    type="radio"
                    name="open"
                    value="Y"
                    onChange={() => change_open("Y")}
                  />{" "}
                  공개
                </label>
                <label>
                  <input
                    type="radio"
                    name="open"
                    value="N"
                    onChange={() => change_open("N")}
                  />{" "}
                  비공개
                </label>
              </div> */}
              {/* <textarea></textarea> */}
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewWriteDetail;
