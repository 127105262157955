import { useNavigate } from "react-router-dom";
import main from "../../styles/main.module.css";
import { CommonAxios } from "../CommonAxios";
import { useEffect, useState } from "react";

const MainSection3 = () => {
  const navigate = useNavigate();
  const [mainNews, setMainNews] = useState([]);
  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_banner_invest",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          let data = result.messageinfo.message;
          // let dataCnt = result.messageinfo.message.length;

          setMainNews(result.messageinfo.message);

          // setUserInfo(result.messageinfo.message[0]);
          // localStorage.removeItem("meta_info");
          // document.location.href = "/";
        } else {
          //   alert(result.messageinfo.message);
        }
      }
    );
  }, []);
  const btn_move = (link) => {
    let linkto = link.replaceAll("https://meta12.net", "");
    navigate(linkto);

    // navigate(`/notice/${no}`);
  };

  return (
    <>
      {mainNews.length > 0 && (
        <div
          className={main.main_section3_imgbox}
          onClick={() => btn_move(mainNews[0].banner_link)}
          style={{
            background: `url(/banner/${mainNews[0].no}/${mainNews[0].banner_img}) no-repeat center`,
          }}
        >
          {/* <img src={img_1} /> */}
          <div className={main.main_section3_content}>
            {mainNews.length > 0 && mainNews[0].banner_text && (
              <h3
                className={main.main_section3_h3}
                dangerouslySetInnerHTML={{
                  __html: mainNews[0].banner_text.replaceAll(
                    /(\n|\r\n)/g,
                    "<br/>"
                  ),
                }}
              ></h3>
            )}
            {mainNews.length > 0 && mainNews[0].banner_text2 && (
              <p
                dangerouslySetInnerHTML={{
                  __html: mainNews[0].banner_text2.replaceAll(
                    /(\n|\r\n)/g,
                    "<br/>"
                  ),
                }}
              ></p>
            )}

            <button>투자하러 가기</button>
          </div>
        </div>
      )}
    </>
  );
};
export default MainSection3;
