import { Outlet } from "react-router-dom";
import Dashboard from "../components/admin/Dashboard";
// import "../styles/admin.css";
import styles from "../styles/admin.module.css";

const AdminLayout = () => {
  return (
    <>
      <div className={styles.wrap1}>
        <Dashboard />
        <Outlet />
      </div>
    </>
  );
};

export default AdminLayout;
