import styles from "../../styles/mypage.module.css";
import { FaUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { CommonAxios } from "../CommonAxios";
import { FaUserAlt } from "react-icons/fa";
import { MdOutlinePassword } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import MypageInvest from "./detail/MypageInvest";
import MypageInvestWait from "./detail/MypageInvestWait";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import InvestDetailCom from "./detail/InvestDetailCom";
const MypageInvestDetail2 = () => {
  const { id } = useParams();
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const navigate = useNavigate();

  const btn_move = (data) => {
    if (data === 1) {
      navigate("/mypage/profile");
    } else {
      if (user.ur_type === 1) {
        navigate("/mypage/password");
      } else {
        return alert("소셜 계정은 이용할 수 없습니다.");
      }
    }
  };

  const [arrayList, setArrayList] = useState([]);
  const [info, setInfo] = useState([]);
  const [myAccount, setMyAccount] = useState([]);
  useEffect(() => {
    let sendData = {
      userNo: user.no,
      no: id,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/mypage_select_invest_detail",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setArrayList(result.messageinfo.message);
          setInfo(result.messageinfo.message_data2);
          setMyAccount(result.messageinfo.message_data3);
        } else {
          // Swal.fire({
          //   title: "조회 실패",
          //   icon: "error",
          //   confirmButtonText: "확인",
          // }).then((res) => {
          //   if (res.isConfirmed) {
          //   }
          // });
        }
      }
    );
  }, []);

  return (
    <>
      <div className={styles.mypage_content_right}>
        <h3 className={styles.mypage_content_title}>투자 상품 상세</h3>
        <div
          className={`${styles.mypage_content_inner} ${styles.invest_detail_wrap}`}
        >
          <div className={styles.mypage_investdetail_wrap}>
            <div className={styles.mypage_investdetail_box}>
              {arrayList.length > 0 &&
                arrayList[0].invest_confirm_ing === "Y" && (
                  <InvestDetailCom
                    data={arrayList[0]}
                    yn={"Y"}
                    info={info}
                    myAccount={myAccount}
                  />
                )}
              {arrayList.length > 0 &&
                arrayList[0].invest_confirm_ing === "N" && (
                  <InvestDetailCom
                    data={arrayList[0]}
                    yn={"N"}
                    info={info}
                    myAccount={myAccount}
                  />
                )}
              {arrayList.length > 0 &&
                (arrayList[0].invest_confirm_ing === "R" ||
                  arrayList[0].invest_confirm_ing === "C") && (
                  <InvestDetailCom
                    data={arrayList[0]}
                    yn={arrayList[0].invest_confirm_ing}
                    info={info}
                    myAccount={myAccount}
                  />
                )}{" "}
              {arrayList.length > 0 &&
                (arrayList[0].invest_confirm_ing === "EE" ||
                  arrayList[0].invest_confirm_ing === "E") && (
                  <InvestDetailCom
                    data={arrayList[0]}
                    yn={arrayList[0].invest_confirm_ing}
                    info={info}
                    myAccount={myAccount}
                  />
                )}
            </div>

            <div className={styles.mypage_investdetail_table}>
              {/* <h4>투자 출금 내역</h4> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MypageInvestDetail2;
