import com from "../../../styles/company.module.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { CommonAxios } from "../../CommonAxios";
const CompanyBanner = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    let sendData = "";
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/company_select_introduce",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const handleResize = () => {
    setWindowSize(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    data.length > 0 && (
      <>
        <div
          className={com.company_back_wrap}
          style={{
            background: `url(/introduce/${data[0].no}/${data[0].intro_img_1}) no-repeat  `,
            backgroundSize: windowSize <= "1100" ? "cover" : "100%",
            // overflow: "hidden",
          }}
        >
          <div className="common-inner-wrap">
            <div>
              <section className={com.infomation}>
                <div className={com.info_text}>
                  {" "}
                  {data[0].intro_strong && (
                    <strong
                      dangerouslySetInnerHTML={{
                        __html: data[0].intro_strong.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></strong>
                  )}
                  {data[0].intro_text && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data[0].intro_text.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></p>
                  )}
                  {/* <strong>
                    고객님의 투자로
                    <br />
                    지구를 지킬 수 있습니다.
                  </strong>
                  <p>
                    주식회사 아시아로는 무농약 <br />
                    친환경 담수경 스마트팜 수경재배로 <br />
                    2023년 14,000평, 2024년 29,000평, <br />
                    2025년 45,200평의 농장을 운영하여 <br />
                    지구 지킴이의 역할을 하겠습니다. <br />
                  </p> */}
                </div>
                <div className={com.info_num}>
                  <ul>
                    {/* <li>
                      <span>2025년 총 면적</span>
                      <em>45,000평</em>
                    </li>{" "}
                    <li>
                      {" "}
                      <span>2025년 총 매출액</span>
                      <em>90억</em>
                    </li> */}{" "}
                    <li>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data[0].intro_subject_1.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></span>
                      <em
                        dangerouslySetInnerHTML={{
                          __html: data[0].intro_content_1.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></em>
                    </li>{" "}
                    <li>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data[0].intro_subject_2.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></span>
                      <em
                        dangerouslySetInnerHTML={{
                          __html: data[0].intro_content_2.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></em>
                    </li>
                  </ul>
                </div>
              </section>
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className="common-inner-wrap">
          {data[1].intro_img_yn === "N" && (
            <div>
              <section className={com.info_link}>
                <div className={com.text_box}>
                  {" "}
                  {data[1].intro_strong && (
                    <strong
                      dangerouslySetInnerHTML={{
                        __html: data[1].intro_strong.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></strong>
                  )}
                  {data[1].intro_text && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: data[1].intro_text.replaceAll(
                          /(\n|\r\n)/g,
                          "<br/>"
                        ),
                      }}
                    ></p>
                  )}
                </div>{" "}
                <div className={com.info_num}>
                  <ul>
                    <li>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: data[1].intro_subject_1.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></span>
                      <em
                        dangerouslySetInnerHTML={{
                          __html: data[1].intro_content_1.replaceAll(
                            /(\n|\r\n)/g,
                            "<br/>"
                          ),
                        }}
                      ></em>
                    </li>{" "}
                  </ul>
                </div>
              </section>{" "}
            </div>
          )}{" "}
          {data[1].intro_img_yn === "Y" && (
            <div style={{ width: "100%" }}>
              <img
                src={`/introduce/${data[1].no}/${data[1].intro_img_1}`}
                style={{ width: "100%" }}
              />
            </div>
          )}
        </div>
        <div className={com.info_move_box}>
          {" "}
          <div className="common-inner-wrap">
            {" "}
            <section className={com.info_move_intro}>
              <Link to="/introduce" className={com.info_go}>
                자세히 알아보기
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  aria-hidden="true"
                  width="16"
                  color="#374151"
                  style={{ marginBottom: "1px" }}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  ></path>
                </svg>
              </Link>{" "}
            </section>{" "}
          </div>
        </div>
      </>
    )
  );
};

export default CompanyBanner;
