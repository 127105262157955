import { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
// import { changeContent, changeImgText } from "../../modules/notice";
import { useDispatch } from "react-redux";
import { textReplace } from "../../../lib/textReplace";
import axios from "axios";
// Toast 에디터
import { Editor } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/toastui-editor.css";

// Toast 플러그인
import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";

const Wrap = styled.div`
  width: 100%;
  margin: 0 auto;
  // position: relative;
`;

const ToastEditor = ({
  contentData,
  setImgArr,
  imgArr,
  setContent,
  content,
}) => {
  const dispatch = useDispatch();
  // Editor DOM 선택용
  const editorRef = useRef();

  const onChange = () => {
    let data = editorRef.current.getInstance().getHTML();
    data = textReplace(data);
    setContent(data);
    // dispatch(changeContent(data));
  };

  const [imgCheck, setImgCheck] = useState(false);
  const [imgTemp, setImgTemp] = useState("");
  useEffect(() => {
    if (imgTemp !== "") {
      setImgArr(imgArr.concat(imgTemp));
    }
  }, [imgTemp]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getInstance().removeHook("addImageBlobHook");

      editorRef.current
        .getInstance()
        .addHook("addImageBlobHook", (blob, callback) => {
          (async () => {
            // let maxsize = 1 * 1024 * 1024; //1mb
            // if (uploadImage.length >= 3) {
            //   Swal.fire({
            //     title: "이미지 파일은 3개까지 첨부할 수 있습니다.",
            //     text: "서버에 업로드된 사진을 지워주세요.",
            //     icon: "error",
            //     confirmButtonColor: "#FF0000",
            //   });
            //   return;
            // }
            // if (blob.size > maxsize) {
            //   Swal.fire({
            //     title: "이미지 용량 초과",
            //     text: "다른 이미지를 업로드해주세요.",
            //     icon: "error",
            //     confirmButtonColor: "#FF0000",
            //   });
            //   return;
            // }
            // setSpinnerView(true);
            // let today = new Date();
            // let year = today.getFullYear().toString().slice(-2);
            // let month = ("0" + (today.getMonth() + 1)).slice(-2);
            // let day = ("0" + today.getDate()).slice(-2);
            // let dateString = year + month + day;
            let fileName =
              Math.floor(Math.random() * 10000000) + "_" + new Date().getTime();
            let splitFileName = blob.name.split(".");
            fileName = `${fileName}.${splitFileName[splitFileName.length - 1]}`;

            // console.log(imgArr);
            // console.log(imgArr.length);
            // if (imgArr.length > 0) {
            //   setImgArr(imgArr.concat({ fileName }));
            // } else {
            //   setImgArr(fileName);
            // }

            setImgTemp(fileName);
            const formData = new FormData();
            formData.append("filename", fileName);
            // formData.append("no1", classinfo); //클래스 고유번호
            // formData.append("capNo", chapterNum); //챕터 고유번호
            // formData.append("fieldName", fieldname); // 필드 네임
            // formData.append("pathname", path); //text 필드
            // formData.append("step", `ch${step.substr(-2)}`);
            // formData.append("tablename", tablename); //테이블 네임
            // formData.append("fileNum", fileNum); //파일명
            // formData.append("imageText", imageText); //이미지 이름 배열 항목 +  fileNum 개념
            formData.append("imgFile", blob);
            axios
              .post(
                process.env.REACT_APP_HOSTDONAME + "/api/notice_upload_img",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                if (response.data.messageinfo.state === "ok") {
                  // setUploadImage(
                  //   uploadImage.concat({
                  //     id: uploadImageRef.current++,
                  //     imageUrl: `/classImage/${classinfo}/${chapterNum}/${path}/${fileNum}`,
                  //     imagename: fileNum,
                  //   })
                  // );
                  // setImageText(imageText + fileNum + ",");
                  // // 응답 처리
                  // setSpinnerView(false);
                  callback(`/notice/temp_img/${fileName}`, "alt_text");
                }
              })
              .catch((error) => {
                // 예외 처리
              });
          })();

          return false;
        });
    }
  }, [editorRef]);

  // linkAttribute: {
  //   target: '_blank',
  //   contenteditable: 'false',
  //   rel: 'noopener noreferrer'
  // }

  return (
    <>
      <Wrap>
        <form>
          <Editor
            ref={editorRef} // DOM 선택용 useRef
            // placeholder="내용을 입력해주세요."
            previewStyle="vertical" // 미리보기 스타일 지정
            height="500px" // 에디터 창 높이
            initialEditType="wysiwyg" // 기본 에디터 타입
            toolbarItems={[
              // 툴바 옵션 설정
              // ["heading", "bold", "italic", "strike"],
              // ["hr", "quote", "link"],
              // ["ul", "ol", "task", "indent", "outdent"],
              ["heading", "bold", "italic", "strike"],
              ["hr", "quote"],
              ["ul", "ol", "task", "indent", "outdent"],
              ["image", "link"],
              ["code", "codeblock"],
            ]}
            linkAttributes={{
              target: "_blank", //A태그 새창열기
              contenteditable: "false",
            }}
            hideModeSwitch={true}
            onChange={onChange}
            initialValue={content}
            autofocus={false}
            useCommandShortcut={false} // 키보드 입력 컨트롤 방지
            plugins={[colorSyntax]} // 색상 플러그인 적용
          ></Editor>
        </form>
      </Wrap>
    </>
  );
};

export default ToastEditor;
