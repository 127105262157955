import { Link } from "react-router-dom";
import logo from "../assets/images/Logo.png";
import { useEffect, useRef, useState } from "react";
import "../styles/login.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { encrypt } from "../lib/api/tokenCrypto";
// import { getUsers, logout } from "../modules/user";
// import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";

function EmailLogin() {
  const inpUsingRef = useRef([]);
  const { id } = useParams();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const email_test =
    /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
  // const { user } = useSelector(({ user }) => ({
  //   user: user.user,
  // }));

  const [pOneShow, setPOneShow] = useState(false);
  const [pTwoShow, setPTwoShow] = useState(false);
  const [studentIdErro, setStudentIdErro] = useState(false);
  const [studentPwErro, setStudentPwErro] = useState(false);
  const [autoState, setAutoState] = useState(true);
  // const context = useContext(userContext);
  // console.log(c);

  const checkHandler = (e) => {
    setAutoState(!autoState);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onLogin(inpUsingRef.current[0].value, inpUsingRef.current[1].value);
    }
  };
  const onLogin = (email, password) => {
    if (id === "email") {
      if (email === "" && password === "")
        return setPOneShow(true), setPTwoShow(true);

      if (email === "" || !email_test.test(email)) return setPOneShow(true);

      if (password === "") return setPTwoShow(true);
    }
    if (id === "student") {
      if (email === "" && password === "")
        return setStudentIdErro(true), setStudentPwErro(true);

      if (email === "" || email_test.test(email)) return setStudentIdErro(true);

      if (password === "") return setStudentPwErro(true);
    }

    const sendData = {
      email: email,
      password: password,
    };

    // dispatch(getUsers(sendData));
  };

  // useEffect(() => {
  //   const error = "사용자 아이디와 비밀번호를 확인하여 주시기 바랍니다.";
  //   const error1 = "로그인 불가능 계정";
  //   const error2 = "탈퇴한 계정";
  //   if (user !== null && user === error) {
  //     Swal.fire({
  //       title: "로그인 실패",
  //       text: "아이디와 비밀번호를 확인해주세요.",
  //       icon: "error",
  //       confirmButtonText: "확인",
  //       confirmButtonColor: "#FF0000",
  //       allowOutsideClick: false,
  //     });
  //     dispatch(logout());
  //   } else if (user !== null && user === error1) {
  //     Swal.fire({
  //       title: "로그인 실패",
  //       text: "로그인 접속 가능한 계정인지 확인해주세요.(고객센터 문의)",
  //       icon: "error",
  //       confirmButtonText: "확인",
  //       confirmButtonColor: "#FF0000",
  //       allowOutsideClick: false,
  //     });
  //     dispatch(logout());
  //   } else if (user !== null && user === error2) {
  //     Swal.fire({
  //       title: "로그인 실패",
  //       text: "탈퇴한 계정입니다.(고객센터 문의)",
  //       icon: "error",
  //       confirmButtonText: "확인",
  //       confirmButtonColor: "#FF0000",
  //       allowOutsideClick: false,
  //     });
  //     dispatch(logout());
  //   } else if (
  //     user !== null &&
  //     user !== error &&
  //     user !== error1 &&
  //     user !== error2
  //   ) {
  //     // localStorage.setItem("serveInfo", user);
  //     localStorage.setItem("serveInfo", encrypt(user, "serveInfo"));
  //     const url = process.env.REACT_APP_HOSTDONAME + "/api/googleMobile";
  //     let config = {
  //       user,
  //     };
  //     axios.post(url, config).then((res) => {
  //       if (id === "email") {
  //         if (res.data.messageinfo.message[0].user_mobile !== "") {
  //           navigate("/");
  //         } else {
  //           Swal.fire({
  //             title: "핸드폰 인증이 필요합니다.",
  //             icon: "error",
  //             confirmButtonText: "확인",
  //             confirmButtonColor: "#FF0000",
  //             allowOutsideClick: false,
  //           }).then((res) => {
  //             if (res.isConfirmed) {
  //               document.location.replace("/myinfo");
  //             }
  //           });
  //         }
  //       }
  //       if (id === "student") {
  //         navigate("/");
  //       }
  //     });
  //   }
  // }, [navigate, user]);

  const btn_move_page = () => {
    navigate("/login/sendMail");
  };
  return (
    <div className="login-wrapper">
      <div className="login-inner">
        <div className="login-left email-left">
          <div className="login-hd email-hd">
            <h1 className="logo-box">
              <Link
                to="/"
                style={{
                  width: "147px",
                }}
              >
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    display: "block",
                    width: "147px",
                  }}
                />
              </Link>
            </h1>
          </div>
          <div className="login-content login-content-2">
            <h2 className="login-title email-title">
              이메일로 로그인
            </h2>
            <div className="email-login-box">
              <div className="email-div">
                <label>아이디</label>
                <input
                  type="text"
                  placeholder="아이디를 입력하세요"
                  ref={(elem) => (inpUsingRef.current[0] = elem)}
                  className="inp_login_email"
                  onKeyDown={() => setPOneShow(false)}
                />
                <PStyle pOneShow={pOneShow}>이메일을 입력하세요.</PStyle>
              </div>
              <div className="email-div">
                <label>비밀번호</label>
                <input
                  type="password"
                  placeholder="비밀번호를 입력하세요"
                  ref={(elem) => (inpUsingRef.current[1] = elem)}
                  className="inp_login_password"
                  onKeyDown={() => setPTwoShow(false)}
                  onKeyPress={handleKeyPress}
                />
                <PStyleTwo pTwoShow={pTwoShow}>
                  비밀번호를 입력하세요.
                </PStyleTwo>
              </div>
              <button
                className="btn-login"
                onClick={() =>
                  onLogin(
                    inpUsingRef.current[0].value,
                    inpUsingRef.current[1].value
                  )
                }
                type="button"
              >
                로그인
              </button>
              <div
                className="login-info-div"
                style={{
                  fontSize: "14px",
                  color: "#7f7f7f",
                }}
              >
                <div>
                  <label htmlFor="cd1">
                    <input type="checkbox" id="cd1" />
                    <span style={{ cursor: "pointer" }}>아이디 기억하기</span>
                  </label>
                </div>
                <div>
                  <Link to="/SignUp">이메일로 회원가입</Link>
                </div>
              </div>
              {/* <div className="sign-up-div">
                <Link to="/SignUp">이메일로 회원가입</Link>
              </div> */}
              <span className="login-span login-span-email">
                본인은 사이트 이용약관, 개인정보 수집 및 이용, 그리고
                <br />
                개인정보 제공에 대한 내용을 확인하였고, 이에 동의합니다.
              </span>
              <p className="login-p">
                {" "}
                <span onClick={btn_move_page}>
                  🤔 비밀번호를 잊어버리셨나요?
                </span>
              </p>
            </div>
          </div>
        </div>
        {/* <div className="login-right">
          <div className="login-imagebox">
            <img
              src={loginRight1}
              alt="loginImage"
              style={{
                width: "100%",
                height: "100%",

                objectFit: "cover",
                // paddingLeft:"5px"
                // opacity:"0"
              }}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
const PStyle = styled.p`
  display: ${(props) => (props.pOneShow ? "block" : "none")};
`;
const PStyleTwo = styled.p`
  display: ${(props) => (props.pTwoShow ? "block" : "none")};
`;
export default EmailLogin;
