import MainItemSlider from "./MainItemSlider";
import main from "../../styles/main.module.css";
import { useEffect, useState } from "react";
import { CommonAxios } from "../CommonAxios";
import { useSelector } from "react-redux";
import store1 from "../../assets/images/Layer 521.png";
import store2 from "../../assets/images/Layer 522.png";
import store3 from "../../assets/images/Layer 523.png";
// import store4 from "../../assets/images/Layer 524.png";
import store4 from "../../assets/images/Layer 525.png";
import store5 from "../../assets/images/Layer 526.png";
import store6 from "../../assets/images/Layer 527.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
const MainSection4 = () => {
  const [data, setData] = useState([]);
  const { user } = useSelector(({ user }) => ({
    user: user.user,
  }));
  const navigate = useNavigate();
  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_main_notice",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          setData(result.messageinfo.message);
          setSkeleton(true);
        } else {
        }
      }
    );
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const SliderItem = ({ data }) => {
    return (
      <div
        className={main.main_new_news_in}
        onClick={() => {
          navigate(`/notice/${data.no}`);
        }}
      >
        {/* <span className={main.main_new_news_type}>
          {data.notice_type === 1 ? "공지사항" : "보도자료"}
        </span> */}
        <div className={main.main_new_news_title}>
          <em>{data.notice_title}</em>
          {/* <span>“2023 서울머니쇼 주식회사 메타그린... 경품행사”</span> */}
        </div>
      </div>
    );
  };
  const [skeleton, setSkeleton] = useState();
  return (
    <>
      <h3 className={main.main_title_h3}>새로운 소식 </h3>
      {/* <span className={main.main_title_sub}>투자의 기회를 잡으세요!</span> */}
      {/* {data && <MainItemSlider data={data} />} */}

      <div className={main.main_new_news}>
        <div className={main.main_new_news_inner}>
          {!skeleton && (
            <div
              className={`${main.main_new_news_in} ${main.main_new_news_in_skeleton}`}
            ></div>
          )}
          {skeleton && data.length === 0 && (
            <div className={main.main_new_news_in}>
              <div className={main.main_new_news_title}>
                <em
                  style={{
                    color: "#898989",
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  아직 새로운 소식이 없습니다.
                </em>
                {/* <span>“2023 서울머니쇼 주식회사 메타그린... 경품행사”</span> */}
              </div>
            </div>
          )}
          {skeleton && data.length === 1 && (
            <div
              className={main.main_new_news_in}
              onClick={() => {
                navigate(`/notice/${data[0].no}`);
              }}
            >
              {/* <span className={main.main_new_news_type}>
                {data[0].notice_type === 1 ? "공지사항" : "보도자료"}
              </span> */}
              <div className={main.main_new_news_title}>
                <em>{data[0].notice_title}</em>
                {/* <span>“2023 서울머니쇼 주식회사 메타그린... 경품행사”</span> */}
              </div>
            </div>
          )}
          {skeleton && data.length > 1 && (
            <Slider {...settings}>
              {data.map((data) => {
                return <SliderItem data={data} />;
              })}
            </Slider>
          )}
        </div>
      </div>
    </>
  );
};
export default MainSection4;
