import styles from "../../styles/admin.module.css";
import { useState, useEffect, useCallback, useRef } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { CommonAxios } from "../CommonAxios";
import NoticeWrite from "./detail/NoticeWriteDetail";
import NoticeViewDetail from "./detail/NoticeViewDetail";
import NoticeWriteDetail from "./detail/NoticeWriteDetail";
import Swal from "sweetalert2";

const PopupDetail = () => {
  const [options, setOptions] = useState("all");

  const [userInfo, setUserInfo] = useState([]);
  const { id } = useParams();
  const [openValue, setOpenValue] = useState("Y");
  const imageRef = useRef(); //이미지 주소 (src 속성을 넣기 위해 사용)
  const imgNameRef = useRef(); //이미지 파일 이름 REF
  const [imageFile, setImageFile] = useState(""); //이미지 속성정보
  const [getimage, setGetImage] = useState(""); //render.result 값

  const [updateImg, setUpdateImg] = useState("");

  const inpChange = (e) => {
    const file = e.target.files;
    const imgExtension = file[0].name.split(".");
    const maxSize = 1024 * 1024 * 1;

    if (file[0] === undefined) {
      return;
    }

    // if (
    //   imgExtension[1] !== "jpg" ||
    //   imgExtension[1] !== "png" ||
    //   imgExtension[1] !== "jpeg" ||
    //   imgExtension[1] !== "gif"
    // ) {
    //   Swal.fire({
    //     title: "이미지 업로드 불가",
    //     text: "JPG , PNG , JPEG , GIF 확장자 파일만 올려주세요.",
    //     icon: "error",
    //     confirmButtonText: "확인",
    //     confirmButtonColor: "#FF0000",
    //   });
    //   return;
    // }
    if (file[0].size >= maxSize) {
      //이미지 사이즈가 500k보다 크다면,
      setImageFile(imageFile);
      Swal.fire({
        title: "이미지 용량 초과",
        html: `1MB 사진을 올려주세요.`,

        confirmButtonText: "확인",
      });
      return;
    } else {
      setImageFile(file);
    }
  };

  useEffect(() => {
    preview(); // 미리보기
  });

  const preview = () => {
    if (!imageFile) return; //이미지 정보가 없다면 리턴

    const reader = new FileReader();

    reader.onload = () => {
      imageRef.current.src = reader.result;
      setGetImage(reader.result);
    };
    reader.readAsDataURL(imageFile[0]);
  };

  useEffect(() => {
    let sendData = {};
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_popup",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setLink(result.messageinfo.message[0].popup_link);
          setOpenValue(result.messageinfo.message[0].popup_open);

          if (result.messageinfo.message[0].popup_img) {
            setUpdateImg(
              `/popup/${result.messageinfo.message[0].no}/${result.messageinfo.message[0].popup_img}`
            );
          }
        } else {
        }
      }
    );
  }, []);
  const change_open = (data) => {
    setOpenValue(data);
  };

  const [link, setLink] = useState("");
  const change_link = useCallback((e) => {
    setLink(e.target.value);
  }, []);

  const [linkYn, setLinkYn] = useState("Y");
  const change_link_yn = (data) => {
    setLinkYn(data);
  };

  const btn_popup_reg = () => {
    // alert("개발중");

    if (!openValue) {
      return Swal.fire({
        title: "공개/비공개를 선택해주세요.",

        confirmButtonText: "확인",
      });
    }

    let sendData = {
      no: 1,
      popup_open: openValue,
      popup_link: link,
      filename: "",
      image: "",
      beforeImg: updateImg.split("/")[updateImg.split("/").length - 1],
      // mobile :
    };

    if (imageFile !== "") {
      var file_info = imageFile[0].name.split(".");
      var file_name = `${Math.floor(Math.random() * 100000)}.${file_info[1]}`;
      sendData.filename = file_name;
      sendData.image = `${getimage}file_name:${file_name}file_name:file_name:/userProfile/`;
    }

    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_popup",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          Swal.fire({
            title: "수정 완료",
            confirmButtonText: "확인",
          });
        } else {
        }
      }
    );
  };
  return (
    <>
      {" "}
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          홈 &nbsp;〉 &nbsp;메인 팝업창{" "}
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={styles.popup_write_wrap}>
            <div className={styles.popup_write_inner}>
              <div className={styles.popup_write_title}>
                <h4>메인 팝업창 설정</h4>
                <div className={styles.popup_write_button}>
                  <button onClick={btn_popup_reg}>설정 완료</button>
                </div>
              </div>
              <div className={styles.popup_write_content}>
                <div className={styles.popup_write_left}>
                  <div className={styles.popup_img_box}>
                    {updateImg === "" ? (
                      !imageFile ? (
                        <div>
                          <span className={styles.image_span}>Image</span>
                        </div>
                      ) : (
                        <img src="" ref={imageRef} />
                      )
                    ) : (
                      <img src={updateImg} ref={imageRef} />
                    )}
                  </div>
                </div>{" "}
                <div className={styles.popup_write_right}>
                  {" "}
                  <div className={styles.popup_file_box}>
                    <h4>팝업 이미지 업로드</h4>

                    <label className={styles.popup_file}>
                      <input
                        type="file"
                        className={styles.popup_file_inp}
                        accept="image/*"
                        onChange={inpChange}
                      />
                      이미지 업로드
                    </label>
                  </div>{" "}
                  <p className={styles.popup_warning}>
                    {" "}
                    width :360(px) 고정
                    <br />
                    height:최소 240(px) ~ 최대 540px
                  </p>{" "}
                  <div className={styles.popup_link_box}>
                    <h4>링크</h4>
                    <input type="text" onChange={change_link} value={link} />
                  </div>
                  <div className={styles.popup_open_box}>
                    <h4>공개 여부</h4>
                    <label>
                      <input
                        type="radio"
                        name="open"
                        value="Y"
                        checked={openValue === "Y" ? true : false}
                        onChange={() => change_open("Y")}
                      />{" "}
                      공개
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="open"
                        value="N"
                        checked={openValue === "N" ? true : false}
                        onChange={() => change_open("N")}
                      />{" "}
                      비공개
                    </label>
                  </div>
                  {/* <div className={styles.popup_link_box}>
                    <h4>링크 적용 여부</h4>
                    <label>
                      <input
                        type="radio"
                        name="linkyn"
                        value="Y"
                        onChange={() => change_link_yn("Y")}
                      />{" "}
                      공개
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="linkyn"
                        value="N"
                        onChange={() => change_link_yn("N")}
                      />{" "}
                      비공개
                    </label>
                  </div> */}
                  {/* <div className={styles.popup_open_box}>
                  <h4>중요 표시</h4>
                  <label>
                    <input
                      type="radio"
                      name="important"
                      value="Y"
                      onChange={() => change_important("Y")}
                    />{" "}
                    중요함
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="important"
                      value="N"
                      onChange={() => change_important("N")}
                    />{" "}
                    중요안함
                  </label>
                </div> */}
                  {/* <div className={styles.popup_open_box}>
                  <h4>게시 필드</h4>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="1"
                      onChange={() => change_type(1)}
                      checked={typed === 1 ? true : false}
                    />{" "}
                    공지사항
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="type"
                      value="2"
                      onChange={() => change_type(2)}
                      checked={typed === 2 ? true : false}
                    />{" "}
                    보도자료
                  </label>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupDetail;
