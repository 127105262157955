import React, { useMemo, useState, useEffect, useRef } from "react";
import notice from "../../../styles/notice.module.css";
import { CommonAxios } from "../../CommonAxios";

import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../../assets/images/Artboard 2.png";
import Moment from "react-moment";
const ContDetail = ({ data }) => {
  const { id } = useParams();

  return (
    <>
      <div className={notice.notice_content_view}>
        <div className={notice.notice_view_tite}>
          {/* <div>
            <span>
              <img src={logo} />
            </span>
          </div> */}
          <p>{data[0].notice_title}</p>
          <span>
            <Moment format="YY.MM.DD">{data[0].notice_date}</Moment>{" "}
          </span>
        </div>
        <div className={notice.notice_view_cont}>
          <div
            dangerouslySetInnerHTML={{ __html: data[0].notice_content }}
          ></div>
        </div>{" "}
        {/* <div className={notice.notice_view_bottom}></div> */}
      </div>
      {/* <NoticeCustomTable columns={TableColumn} data={data} /> */}
    </>
  );
};

export default ContDetail;
