import style from "../styles/wadiz.module.css";
import { useEffect } from "react";
const Wadiz2 = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);
  const scrollEvt = (data) => {
    const targetNo1 = document.getElementById("target_no1");
    const targetNo2 = document.getElementById("target_no2");
    const targetNo3 = document.getElementById("target_no3");
    const targetNo4 = document.getElementById("target_no4");
    const targetNo5 = document.getElementById("target_no5");
    const targetNo6 = document.getElementById("target_no6");

    if (data == "no1" && targetNo1) {
      targetNo1.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no2" && targetNo2) {
      targetNo2.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no3" && targetNo3) {
      targetNo3.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no4" && targetNo4) {
      targetNo4.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no5" && targetNo5) {
      targetNo5.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (data == "no6" && targetNo6) {
      targetNo6.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className={`common-wrap`} style={{ borderTop: "1px solid #b6b6b6b5" }}>
      {" "}
      <div className="common-inner-wrap">
        <div className={style.policy_wrap}>
          <h1>회원가입약관</h1>
          <p>META12 회원가입과 관련하여 필요한 사항을 규정합니다.</p>
          <div className={style.policy_update_div}>
            <h5>업데이트 노트</h5>
            <ul>
              <li>본 이용약관은 2023년 8월 1일부터 시행됩니다.</li>
              <li>시행 2023.08.01</li>
            </ul>
          </div>
          <ol className={style.policy_subtitle}>
            <li onClick={() => scrollEvt("no1")}>제1장 총칙</li>
            <li onClick={() => scrollEvt("no2")}>제2장 서비스의 이용</li>
            <li onClick={() => scrollEvt("no3")}>
              제3장 회원정보의 보호 및 관리
            </li>
            <li onClick={() => scrollEvt("no4")}>제4장 기타</li>
            <li onClick={() => scrollEvt("no5")}>부칙</li>
            <li onClick={() => scrollEvt("no6")}>별지: 서비스 이용료</li>
          </ol>
          <hr />

          <h2 id="target_no1">제1장 총칙</h2>
          <h3>제1조 (목적)</h3>
          <p>
            본 약관은 아시아로 주식회사(이하 "회사")가 인터넷
            사이트(https://www.meta12.net)를 통하여 제공하는 회원 서비스,
            크라우드펀딩 서비스, 스토어 서비스 등 제반 서비스의 이용과 관련하여
            회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을
            목적으로 합니다.
          </p>
          <h3>제2조 (정의)</h3>
          <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              서비스 : 회원이 온라인 홈페이지를 통하여 본 약관에 따라 이용할 수
              있는 회사 또는 회사의 자회사가 제공하는 모든 서비스를 의미합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              홈페이지 : 본 약관에 따라 회사가 제공하는 서비스가 구현되는
              온라인상의 웹페이지(https://www.meta12.net)를 말합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              회원 : 본 약관에 따라 회사와 서비스이용계약을 체결하고 홈페이지를
              통하여 서비스를 이용할 수 있는 자격을 부여받은 자를 말합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              아이디(ID) : 회원의 식별과 서비스 이용을 위하여 회원이 정하고
              회사가 승인하는 문자와 숫자의 조합을 의미합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              비밀번호 : 회원이 부여 받은 아이디와 일치되는 회원임을 확인하고
              비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을
              의미합니다.
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              게시물 : 회원이 서비스를 이용함에 있어 홈페이지의 게시판에 게재한
              부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진,
              동영상 및 각종파일과 링크 등을 의미합니다.
            </li>
          </ol>

          <h2 id="target_no2">제2장 서비스의 이용</h2>

          <h3>제3조 (서비스의 제공)</h3>
          <ol>
            <li>
              본 약관에 동의하여 서비스 이용계약을 체결하여 회원이 된 자는
              서비스를 구성하는 다음 각 호의 서비스를 이용할 수 있습니다.
              <ol>
                <li>회원 서비스</li>
                <li>투자형 크라우드 펀딩 관련 서비스</li>
                <li>a호 외 리워드형 크라우드 펀딩 관련 서비스</li>
                <li>통신 판매 서비스</li>
                <li>통신 판매 중개 서비스</li>
                <li>서포터클럽 멤버십 서비스</li>
                <li>
                  위 각 호 서비스 제공을 위해 필요하거나 이에 부수하는 서비스
                </li>
              </ol>
            </li>
            <li>
              회원은 제1항 각 호의 서비스 중 특정 개별 서비스를 이용하기
              위해서는 회사 또는 회사의 자회사가 별도로 제공하는 이용약관 및
              운영정책, 세부지침(이하 "개별약관 등")에 동의 하셔야 할 수
              있습니다. 이 경우 각 개별 서비스의 구체적인 내용은 각 개별약관
              등에서 정한 바에 따릅니다.
            </li>
            <li>
              서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다. 다만,
              회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능시간을
              별도로 지정할 수 있으며 이러한 경우에는 그 내용을 사전에
              공지합니다.
            </li>
            <li>
              회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절
              또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로
              중단할 수 있습니다. 이 경우 회사는 회원에게 통지합니다. 다만,
              회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에
              통지할 수 있습니다.
            </li>
            <li>
              회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
              정기점검 시간은 서비스제공화면에 공지한 바에 따릅니다.
            </li>
          </ol>

          <h3>제4조 (서비스의 변경)</h3>
          <ol>
            <li>
              회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
              제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.
            </li>
            <li>
              서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는
              변경사유, 변경될 서비스의 내용 및 제공일자 등은 그 변경 전에 해당
              서비스 초기화면에 게시됩니다.
            </li>
            <li>
              회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및
              운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관계법령에
              특별한 규정이 없는 한 회원에게 별도의 보상을 하지 않습니다.
            </li>
          </ol>

          <h3>제5조 (이용계약의 체결)</h3>
          <ol>
            <li>
              회사가 본 약관에 따라 제공하는 서비스를 이용하기 위하여 회사와
              서비스이용계약(이하 “이용계약”)을 체결하여 회원가입에 따른 회원의
              자격을 부여받아야 합니다.
            </li>
            <li>
              서비스를 이용하고자 하는 자(이하 "이용신청자")가 본 약관의 내용에
              대하여 동의를 한 다음 회사가 제공하는 양식에 따라 정보입력을 하고
              본인확인을 위한 인증절차를 이행하는 방법으로 회원가입신청을 하면
              서비스에 대한 이용신청(이하 “이용신청”)이 있는 것으로 보며,
              이용신청에 대하여 회사가 승낙함으로써 이용계약이 체결됩니다.
            </li>
            <li>
              제2항의 회원가입신청 절차의 방법과 내용은 회원이 개인(자연인)인
              경우와 법인인 경우에 따라 다를 수 있습니다.
            </li>
            <li>
              회사는 관계 법령에 따라 이용신청자가 제1항에 따라 입력한 정보에
              대한 사실 확인을 위하여 필요한 경우 이용신청자에게 증빙자료의
              제출을 요청할 수 있으며, 이용신청자는 이에 따라 증빙자료를
              제출하여야 합니다.
            </li>
            <li>
              회사는 다음 각 호에 해당하는 이용신청에 대하여는 승낙을 하지 않을
              수 있습니다.
              <ol>
                <li>
                  이용신청자가 이전에 본 약관에 의하여 회원자격을 상실한 적이
                  있는 경우(회사의 회원 재가입 승낙을 얻은 경우 제외)
                </li>
                <li>
                  이용신청자가 본 약관에 의하여 이전에 회사로부터 서비스
                  이용제한 조치를 받은 상태에서 이용계약을 해지하고 다시
                  이용신청을 한 경우
                </li>
                <li>
                  제2항의 이용신청 시 실명이 아니거나 타인의 명의를 이용하여
                  이용신청을한 경우
                </li>
                <li>
                  제2항의 이용신청 시 필요한 정보를 입력하지 않거나 허위의
                  정보를 기재한 경우
                </li>
                <li>
                  제4항에 따라 요청받은 사실확인을 위한 증빙자료를 제출하지 않은
                  경우
                </li>
                <li>14세 미만 아동이 법정대리인의 동의를 얻지 아니한 경우</li>
                <li>사회의 안녕과 질서, 미풍양속을 저해할 우려가 있는 경우</li>
                <li>
                  타인의 서비스 이용을 방해하거나 그 정보를 도용하는 등
                  전자상거래 질서를 위협할 우려가 있는 경우
                </li>
                <li>위법 또는 부당한 목적으로 이용신청을 한 경우</li>
                <li>본 약관을 위반하며 신청하는 경우</li>
              </ol>
            </li>
            <li>
              회사는 다음 각 호의 경우에 승낙을 유보할 수 있습니다.
              <ol>
                <li>
                  제공하는 서비스 관련 설비의 용량이 부족한 등 여유가 없는 경우
                </li>
                <li>
                  기타 서비스 제공을 위한 재정적, 기술적 문제가 있다고 판단되는
                  경우
                </li>
              </ol>
            </li>
            <li>
              회사는 본 약관 및 개별약관에서 정한 바에 따라 회원별로 서비스의
              이용시간, 이용횟수, 메뉴 등을 세분하여 달리 적용할 수 있습니다.
            </li>
          </ol>

          <h3>제6조 (서비스 이용료)</h3>
          <ol>
            <li>
              회사는 제3조에 따른 서비스를 이용하는 회원에 대하여 {"<별지>"}에서
              정하는 수수료를 징수할 수 있으며, 서비스 이용을 위한 접속통신망의
              이용료는 회원이 직접 납부하여야 합니다.
            </li>
            <li>
              회원이 타 유료서비스에 접속하여 서비스를 이용하는 경우에 부과되는
              일체의 요금은 회원이 해당 서비스 업체에 지불하여야 합니다.
            </li>
          </ol>

          <h3>제6조의2 (포인트)</h3>
          <ol>
            <li>
              '포인트'라 함은 서비스를 이용하는 과정에서 대금 결제 수단으로
              사용할 수 있는 현금등가의 결제수단을 의미합니다.
            </li>
            <li>
              포인트는 회원이 유료로 구매하는 유료 포인트와 회사가 회원의 구매
              활동, 이벤트참여 등에 따라 적립, 부여하는 무료 포인트로
              구분됩니다. 단, 법인회원의 경우, 유료포인트를 구매할 수 없습니다.
            </li>
            <li>
              무료 포인트의 유효기간은 회사에서 회원에게 적립 시 공지한
              유효기간에 따르며, 유효기간 동안 사용하지 않을 경우 자동으로
              소멸됩니다.
            </li>
            <li>
              회원 탈퇴 시 사용하지 아니한 무료 포인트는 자동으로 소멸되며,
              회사는 무료 포인트에 대하여서는 별도의 보상을 하지 않습니다.
            </li>
            <li>
              유료 포인트는 적립일로부터 5년 경과 시까지 이용하지 않을 경우
              상사소멸시효에 따라 소멸됩니다.
            </li>
            <li>포인트 사용은 만료 기간이 빠른 무료 포인트부터 사용됩니다.</li>
            <li>
              회사는 이용자가 잔여 유료 포인트에 대한 환급을 요구할 경우, 환급할
              수 있습니다. 단, 무료포인트는 환급되지 않습니다.
            </li>
            <li>
              회사는 회원이 홈페이지에서 포인트를 사용하는 경우 휴대폰인증,
              I-PIN 인증 등 회사가 정한 절차를 거치도록 할 수 있습니다.
            </li>
          </ol>

          <h3>제6조의3 (쿠폰)</h3>
          <ol>
            <li>
              회사는 서비스를 이용하는 회원에게 서비스 이용 시 일정금액 또는
              일정비율을 할인 받거나 일정금액이 지급되는 쿠폰을 발급할 수
              있으며, 쿠폰별 유효기간, 할인금액 및 지급금액, 사용방법 등은 개별
              안내사항을 통하여 확인 가능합니다.
            </li>
            <li>
              할인 쿠폰은 회원 본인의 구매에만 사용할 수 있으며, 어떠한 경우에도
              타인에게 매매하거나 양도할 수 없습니다.
            </li>
            <li>
              회원이 쿠폰의 적용을 위해 필요한 정보의 입력과정에서 발행한 오류에
              따른 모든 불이익에 대하여 회사는 책임을 지지 않습니다.
            </li>
            <li>
              쿠폰은 일부 품목이나 금액에 따라 사용이 제한될 수 있으며
              유효기간이 지난 후에는 사용할 수 없고, 서비스 이용 후 환불이
              이루어졌을 때에는 재사용을 할 수 없습니다.
            </li>
            <li>
              회원에게 제공된 쿠폰은 서비스 이용을 위한 적용 시에 중복으로
              사용할 수 없습니다. 단, 쿠폰의 종류와 내용에 따라 회사, 발행자,
              회원의 중복사용 허용 등 사용범위를 달리 정한 경우에는 그러하지
              않습니다.
            </li>
            <li>
              쿠폰은 현금으로 출금될 수 없으며, 쿠폰에 표시된 유효기간이
              만료되거나 이용계약이 종료되면 소멸합니다.
            </li>
            <li>
              회사는 회원이 부정한 목적과 방법으로 할인쿠폰 등을 획득하거나
              사용하는 사실이 확인될 경우, 해당 이용자에 대한 할인쿠폰을 회수
              또는 소멸시키거나 회원자격을 제한할 수 있습니다.
            </li>
          </ol>

          <h3>제7조 (회원의 이용계약 해지 등)</h3>
          <ol>
            <li>
              회원은 서비스 중단을 원할 경우 회사가 제공하는 양식에 따라
              회원탈퇴신청을 하면 이용계약 해지의 의사표시를 한 것으로 보며,
              회사에 해지의 의사표시가 도달한 때에 이용계약의 해지효력이
              발생합니다.
            </li>
            <li>
              해지효력이 발생한 때로부터 회원은 회원의 자격을 상실하며, 회사가
              제공하는 모든 서비스를 이용할 수 없게 됩니다.
            </li>
            <li>
              제1항에도 불구하고 회원이 개별약관에 따라 회사의 개별 서비스를
              이용하고 있는 경우에는 회원탈퇴신청이 제한될 수 있습니다.
            </li>
            <li>
              제2항에도 불구하고 이용계약의 해지효력이 발생할 당시 당해 회원이
              회사가 제공하는 서비스를 통해 진행 중인 거래 또는 절차가 있다면
              당해 서비스에 관한 개별약관 또는 회사의 안내에 따라 정산절차를
              거쳐야 합니다.
            </li>
            <li>
              회원이 제1항과 제2항에 따라 이용계약을 해지하여 회원자격을
              상실하게 될 경우, 당해 회원은 본 약관에 따라 다시 회원가입을
              신청하는 데 있어 제한을 받을 수 있습니다.
            </li>
            <li>
              회원이 제1항과 제2항에 따라 이용계약을 해지할 경우, 관계법령 및
              개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를
              제외하고는 회원의 모든 데이터는 소멸됩니다. 다만, 이 경우에도
              타인에 의해 담기, 스크랩 등이 되어 재게시되거나, 공용게시판에
              등록된 게시물 등은 삭제되지 않을 수 있습니다.
            </li>
          </ol>

          <h3>제8조 (회사의 이용계약의 제한, 해지 등)</h3>
          <ol>
            <li>
              회사는 회원에게 다음 각 호의 해지사유가 있는 경우 7일 이상으로
              정한 기간 내에 그 해지사유의 시정을 요구하고, 이와 같이 정한 기간
              내에 회원이 이를 시정하지 아니하면 회사는 이용계약을 해지할 수
              있습니다.
              <ol>
                <li>
                  제5조 제5항 각 호에서 정한 승낙거부사유가 있음이 밝혀진 경우
                </li>
                <li>
                  회원이 공공질서 및 미풍양속에 위배되는 거래행위를 하거나
                  시도한 경우
                </li>
                <li>
                  회원이 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을
                  방해한 경우
                </li>
                <li>
                  주민등록법을 위반한 명의도용 및 결제도용, 저작권법 및
                  컴퓨터프로그램보호법을 위반한 불법프로그램의 제공 및 운영방해,
                  정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하
                  "정보통신망법")을 위반한 불법통신 및 해킹, 악성프로그램의
                  배포, 접속권한 초과행위 등 관계법령을 위반한 경우
                </li>
                <li>제3자의 결제수단이나 개인정보를 도용한 경우</li>
                <li>
                  회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해행위를 한
                  경우
                </li>
                <li>
                  회사 및 기타 제3자의 권리나 명예, 신용 기타 정당한 이익을
                  침해하는 행위를 한 경우
                </li>
                <li>
                  외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
                  정보를 홈페이지에 공개 또는 게시하는 행위
                </li>
                <li>회사의 동의 없이 영리를 목적으로 서비스를 이용한 경우</li>
                <li>기타 위법하거나 부당한 목적으로 서비스를 이용한 경우</li>
                <li>제16조 제5항 단서의 경우에 해당하는 경우</li>
                <li>본 약관에 위반하는 행위를 한 경우</li>
              </ol>
            </li>
            <li>
              제1항의 경우에도 불구하고 회사는 제1항 각호의 사유가 시정이
              가능하다고 인정되는 경우 회원에게 그 사유를 통지를 하고 일정한
              기간 동안 회원에게 경고, 일시정지, 영구이용정지 등으로 서비스의
              이용을 단계적으로 제한할 수 있습니다. 다만, 개별 서비스의
              이용제한에 관한 조건 및 세부내용은 개별 서비스상의 개별약관 또는
              회사가 미리 고지한 정책에서 정하는 바에 의합니다.
            </li>
            <li>
              제1항에 따라 이용계약이 해지된 경우 해지되기 전까지 회원이 서비스
              이용을 통해 획득한 "포인트" 및 기타 혜택 등도 모두 소멸되며,
              회사는 이에 대해 별도로 보상하지 않습니다.
            </li>
            <li>
              본 조에 따라 서비스 이용을 제한하거나 이용계약을 해지하는 경우에는
              회사는 제13조에 따라 통지합니다.
            </li>
            <li>
              회원은 제1항과 제2항에 따른 이용해지 또는 이용제한 등에 대하여
              회사가 제공하는 양식과 절차에 따라 이의신청을 할 수 있습니다. 이
              경우 이의신청이 정당하다고 회사가 인정하는 경우 회사는 서비스의
              이용을 재개합니다.
            </li>
          </ol>

          <h2 id="target_no3">제3장 회원정보의 보호 및 관리</h2>

          <h3>제9조 (회원정보의 수집과 보호)</h3>
          <ol>
            <li>
              회사는 서비스 제공을 위하여 이용계약의 체결 시 필요한 정보(이하
              “회원정보”)를 수집할 수 있으며, 그 외에도 수집목적 또는 이용목적을
              밝혀 회원으로부터 정보를 수집할 수 있습니다. 이 경우 회사는
              회원으로부터 정보수집에 대한 동의를 받으며, 회원은 정보제공에
              동의를 한 이후에도 회사가 제공하는 양식에 따라 그 동의를 철회할 수
              있습니다.
            </li>
            <li>
              회원의 개인정보의 수집, 보호 및 사용에 대해서는 관계법령 및 회사의
              개인정보처리방침이 적용됩니다. 다만, 회사의 홈페이지 이외의 링크된
              사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다.
            </li>
            <li>
              관계 법령에 따라 회원정보의 이용과 제3자에 대한 정보제공을
              허용하고 있는 경우를 제외하고 회사는 제1항에 따라 회원으로부터
              동의를 받은 목적 외에 회원의 동의 없이 다른 제3자에게 회원정보를
              제공하지 않습니다.
            </li>
            <li>
              회사는 정보통신망법 등 관계 법령이 정하는 바에 따라 회원의
              개인정보를 보호하기 위해 노력합니다.
            </li>
            <li>회사의 개인정보 보호책임자를 지정하여 운영한다.</li>
          </ol>

          <h3>제10조 (회원의 회원정보 관리에 대한 의무)</h3>
          <ol>
            <li>
              회원의 아이디와 비밀번호를 포함한 회원정보에 관한 관리책임은
              회원에게 있습니다.
            </li>
            <li>
              회사는 회원의 아이디가 개인정보 유출의 우려가 있거나, 반사회적
              또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인할 우려가
              있는 경우, 해당 아이디의 이용을 제한할 수 있습니다.
            </li>
            <li>
              회원은 주민등록번호, 동일숫자, 연속숫자 등의 비밀번호를 사용하지
              말아야 하며 타인이 쉽게 추측할 수 있는 비밀번호 사용을 자제하여
              관리에 유의하여야 합니다.
            </li>
            <li>
              회원은 아이디, 비밀번호 등을 포함한 회원정보를 타인에게
              누설하여서는 아니 되며, 만약 누설하였을 경우 지체 없이 회사에게
              이러한 사실을 [서면 또는 전자우편]의 방법으로 통지하여 알려야
              합니다.
            </li>
            <li>
              회원은 회원정보를 제3자가 양도, 대여, 이용하도록 하여서는 안
              됩니다.
            </li>
            <li>
              회원은 제3자에 의해 회원정보가 도용되거나 사용되고 있음을 인지한
              경우에는 이를 즉시 회사에 제4항과 같은 방법으로 통지하고 회사의
              안내에 따라야 합니다.
            </li>
            <li>
              제4항과 제6항의 경우에 해당 회원이 회사에 그 사실을 통지하지
              않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에
              대하여 회사는 책임을 지지 않습니다.
            </li>
          </ol>

          <h3>제11조 (쿠키(Cookie)의 운용 및 활용)</h3>
          <ol>
            <li>
              회사는 회원에게 적합하고 유용한 서비스를 제공하기 위해서 회원의
              정보를 저장하고 수시로 불러오는 쿠키(cookie)를 이용합니다.
            </li>
            <li>
              제1항과 관련하여 회원은 쿠키이용에 대한 선택권을 가지며 쿠키의
              수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 이용하는 컴퓨터
              브라우저의 설정을 변경할 수 있습니다. 다만, 쿠키의 저장을 거부할
              경우, 로그인이 필요한 서비스를 이용할 수 없게 됨으로써 발생되는
              문제에 대한 책임은 회원에게 있습니다.
            </li>
          </ol>

          <h3>제12조 (회원정보의 변경)</h3>
          <ol>
            <li>
              회원은 서비스 내 “회원정보” 화면을 통하여 회원의 정보를 열람하고
              수정할 수 있습니다. 다만, 서비스의 제공 및 관리를 위해 필요한
              실명, 주민등록번호, 아이디 등은 수정이 불가능할 수 있습니다.
            </li>
            <li>
              회원은 회원가입신청 시 입력한 사항이 변경되었을 경우 온라인으로
              수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을
              알려야 하며, 이와 같이 변경사항을 회사에 알리지 않아 발생한
              불이익에 대하여 회사는 책임지지 않습니다.
            </li>
          </ol>

          <h2 id="target_no4">제4장 기타</h2>

          <h3>제13조 (회원에 대한 통지)</h3>
          <ol>
            <li>
              회사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한
              서비스 내 전자우편주소, 전자쪽지 등으로 할 수 있습니다.
            </li>
            <li>
              회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 게시판에
              게시함으로써 제1항의 통지에 갈음할 수 있습니다.
            </li>
          </ol>

          <h3>제14조 (정보의 제공 및 광고의 게재)</h3>
          <ol>
            <li>
              회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보성
              알림을 게시판의 공지사항이나 전자우편, SMS, 푸쉬 알림 등의
              방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관계법령에 따른
              거래 관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지
              전자우편 등의 수신에 대하여 거절할 수 있습니다.
            </li>
            <li>
              회사는 서비스의 운영과 관련하여 서비스 화면, 전자우편 등에 회사
              또는 제휴업체의 광고를 게재할 수 있습니다. 광고성 정보 전송의 경우
              회원의 사전 동의를 받아서 전송하고, 회원은 광고성 정보 수신을
              원하지 않을 때에는 언제나 수신동의를 철회할 수 있습니다.
            </li>
          </ol>

          <h3>제15조 (게시물의 관리 등)</h3>
          <ol>
            <li>
              회원이 홈페이지 내에 게시한 게시물의 저작권은 해당 게시물의
              저작자에게 귀속됩니다.
            </li>
            <li>
              회원이 홈페이지 내에 게시하는 게시물은 검색결과 내지 서비스 및
              관련 프로모션 등에 의해 노출될 수 있으며, 해당 노출을 위해 필요한
              범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이
              경우 회사는 저작권법 규정을 준수하며, 회원은 회사의 고객센터 또는
              서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외,
              비공개 등의 조치를 회사가 정한 절차에 따라 요구할 수 있습니다.
            </li>
            <li>
              회사는 제2항 이외의 방법으로 회원의 게시물을 이용하고자 하는
              경우에는 회원의 동의를 받습니다.
            </li>
            <li>
              회원의 게시물이 정보통신망법 및 저작권법 등 관계법령에 위반되는
              내용을 포함하거나 권리침해가 인정될 만한 사유가 있거나 기타 회사
              정책 및 관련법에 위반되는 경우에 회사는 삭제, 비공개 등의 조치를
              취할 수 있습니다.
            </li>
          </ol>

          <h3>제16조 (권리의 귀속)</h3>
          <ol>
            <li>
              서비스에 대한 저작권 및 지적재산권은 회사에 귀속됩니다. 다만,
              회원의 게시물 및 제휴계약에 따라 제공된 저작물 등은 제외합니다.
            </li>
            <li>
              회사는 서비스와 관련하여 회원에게 본 약관에 따라 "아이디",
              “콘텐츠”, "포인트" 등을 포함한 서비스를 이용할 수 있는 이용권만을
              부여하며, 회원은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수
              없습니다.
            </li>
          </ol>

          <h3>제17조 (책임제한)</h3>
          <ol>
            <li>
              개별약관에서 개별 서비스에 관한 회사의 책임범위를 별도로 정한 경우
              그에 따릅니다.
            </li>
            <li>
              회사는 천재지변 또는 이에 준하는 불가항력, 정보통신설비상의
              문제(보수, 점검, 교체, 고장, 통신두절 등)으로 인하여 서비스를
              원활하게 제공할 수 없는 경우에는 서비스 제공에 관한 책임이
              면제됩니다.
            </li>
            <li>
              회사는 제3자 또는 회원의 고의 또는 과실로 인한 서비스 이용의
              장애에 대하여는 책임을 지지 않습니다.
            </li>
            <li>
              회사는 회원이 홈페이지에 게재한 정보, 자료, 사실에 대하여 신뢰도,
              정확성 등을 보증하지 않으며, 회사의 고의 또는 과실이 없는 한
              회원이 이를 신뢰함에 따라 입은 손해에 대하여 책임을 지지 않습니다.
            </li>
            <li>
              회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여
              개별적으로 거래 등을 한 경우에는 책임을 지지 않습니다.
            </li>
            <li>
              회사는 무료로 제공되는 서비스 이용과 관련하여 관계법령에 특별한
              규정이 없는 한 책임을 지지 않습니다.
            </li>
            <li>
              회사는 독자적으로 운영되는 회사의 서비스 화면과 링크 등으로 연결된
              사이트를 운영하는 법인(이하 “피연결회사”) 또는 홈페이지에 게재되어
              있거나 회사의 제휴업체와 회원과 사이에 이루어진 교신, 거래 및 이로
              인해 발생한 손해, 손실 등에 대하여 책임을 지지 않습니다.
            </li>
            <li>
              회사는 피연결회사가 온라인 또는 오프라인을 통해 제공하는 정보나
              서비스에 대하여는 일체의 책임을 지지 않습니다.
            </li>
          </ol>

          <h3>제18조 (약관의 게시와 개정)</h3>
          <ol>
            <li>
              회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기
              화면에 게시합니다.
            </li>
            <li>
              회사는 약관의 규제에 관한 법률, 정보통신망법 등 관계법령을
              위반하지 않는 범위에서 본 약관을 개정할 수 있습니다.
            </li>
            <li>
              회사가 약관을 개정할 경우에는 적용일자를 명시하여 제1항의 방법에
              따라 그 개정약관을 7일 이상 공지합니다. 다만, 회원에게 불리한
              내용으로 약관의 개정이 있는 경우에는 공지 외에 일정기간 서비스 내
              전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 따로
              명확히 통지하도록 합니다.
            </li>
            <li>
              회사가 제3항에 따라 개정약관을 공지 또는 통지하면서 회원에게 7일
              기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는
              뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의
              의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로
              봅니다.
            </li>
            <li>
              회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의
              내용을 적용하지 않으며, 이 경우 회원은 제7조에서 정한 바에 따라
              이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는
              특별한 사정이 있는 경우에는 회사가 동의하지 않은 회원과의
              이용계약을 해지할 수 있습니다.
            </li>
          </ol>

          <h3>제19조 (약관의 효력 및 해석)</h3>
          <ol>
            <li>
              개별약관 등의 내용이 본 약관과 상충할 경우에는 "개별약관 등"이
              우선하여 적용됩니다.
            </li>
            <li>
              본 약관에서 정하지 아니한 사항이나 해석에 대해서는 "개별약관 등"
              및 관계법령 또는 상관례에 따릅니다.
            </li>
          </ol>

          <h3>제20조 (준거법 및 재판관할)</h3>
          <ol>
            <li>
              회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
            </li>
            <li>
              회사와 회원 간 발생한 분쟁에 관한 소송은 민사소송법 상의
              관할법원에서 해결합니다.
            </li>
          </ol>

          <h2 id="target_no5">부칙</h2>

          <h3>제1조 (시행일)</h3>
          <ol>
            <li>본 약관은 2023년 8월 1일부터 적용됩니다.</li>
          </ol>

          <h2 id="target_no6">별지: 서비스 이용료 (제6조 제1항 관련)</h2>
          <p>
            회사 또는 회사의 자회사가 제공하는 서비스 이용에 대한 이용료 또는
            수수료는 다음 각 호와 같습니다.
          </p>
          <ol>
            <li>
              투자하기 서비스
              <ol>
                <li>
                  투자자 회원: 청약증거금 이체 시 이체 수수료를 부담하게 될 수
                  있습니다.
                </li>
                <li>
                  발행인 회원: 회사의 자회사와 별도로 체결하는 약정에 따라
                  착수수수료, 사실확인 등 수수료, 성공수수료가 발생할 수 있으며,
                  성공수수료의 경우 모집금액의 10% 이하(부가세 별도)의 범위에서
                  결정됩니다.
                </li>
              </ol>
            </li>
            <li>
              펀딩하기 서비스
              <ol>
                <li>
                  펀딩 서포터: 별도의 수수료가 부과되지 않습니다. 단, 회사가
                  별도로 제공하는 서포터클럽 멤버십 서비스에 가입하는 경우에는
                  관련 개별약관 및 정책에 따라 수수료가 발생할 수 있습니다.
                </li>
                <li>
                  펀딩 메이커: 회사가 별도로 제공하는 펀딩 서비스 메이커
                  이용약관 및 펀딩 요금제 정책에 따라 수수료가 발생할 수
                  있습니다.
                </li>
              </ol>
            </li>
            <li>
              구매하기 서비스
              <ol>
                <li>스토어 서포터: 별도의 수수료가 부과되지 않습니다.</li>
                <li>
                  스토어 메이커: 회사가 별도로 제공하는 스토어 서비스 이용약관
                  및 스토어 서비스 운영정책에 따라 수수료가 발생할 수 있습니다.
                </li>
              </ol>
            </li>
          </ol>
        </div>{" "}
      </div>
    </div>
  );
};

export default Wadiz2;
