import React, { useMemo, useState, useEffect, useRef } from "react";
import CustomTable from "./CustomTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CommonAxios } from "../CommonAxios";
import Swal from "sweetalert2";
// import "../styles/chats.css";
import moment from "moment";
import Moment from "react-moment";
import styles from "../../styles/admin.module.css";
import { Link, useNavigate } from "react-router-dom";
import { textReplace } from "../../lib/textReplace";
import { useCallback } from "react";

const FooterList = () => {
  const navigate = useNavigate();

  // {
  //   Header: "구분",
  //   accessor: "notice_type",

  //   Cell: ({ cell: { value } }) => (
  //     <>{value == 1 ? "공지사항" : "보도자료"}</>
  //   ),
  // },
  // {
  //   Header: "공개여부",
  //   accessor: "notice_open",
  //   Cell: ({ cell: { value } }) => <>{value == "Y" ? "공개" : "비공개"}</>,
  // },

  // {
  //   Header: "답변여부",
  //   accessor: "qna_answer_yn",
  //   Cell: ({ cell: { value } }) => (
  //     <>{value === "Y" ? "답변완료" : "답변미완료"}</>
  //   ),
  // },
  // {
  //   Header: "",
  //   accessor: "qna_date",
  //   Cell: ({ cell: { value } }) => <Date value={value} />,

  const [arrayList, setArrayList] = useState([]);
  const [sendList, setSendList] = useState([]);
  const [filterWord, setFilterWord] = useState("");
  const [options, setOptions] = useState(1);
  const [options2, setOptions2] = useState(1);
  const [banSw, setBanSw] = useState(false);

  const [a1, setA1] = useState("");
  const [a2, setA2] = useState("");
  const [a3, setA3] = useState("");
  const [a4, setA4] = useState("");
  const [a5, setA5] = useState("");
  const [a6, setA6] = useState("");
  const [a7, setA7] = useState("");
  const [a8, setA8] = useState("");
  const [a9, setA9] = useState("");
  const [a10, setA10] = useState("");
  const [a11, setA11] = useState("");
  const [a12, setA12] = useState("");

  useEffect(() => {
    setBanSw(false);
    let sendData = {
      type: options,
      no: options2,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_select_footer",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          // setArrayList(result.messageinfo.message);
          if (result.messageinfo.message.length > 0) {
            setArrayList(result.messageinfo.message);
            setA1(result.messageinfo.message[0].footer_text);
            setA2(result.messageinfo.message[1].footer_text);
            setA3(result.messageinfo.message[2].footer_text);
            setA4(result.messageinfo.message[3].footer_text);
            setA5(result.messageinfo.message[4].footer_text);
            setA6(result.messageinfo.message[5].footer_text);
            setA7(result.messageinfo.message[6].footer_text);
            setA8(result.messageinfo.message[7].footer_text);
            setA9(result.messageinfo.message[8].footer_text);
            setA10(result.messageinfo.message[9].footer_text);
            setA11(result.messageinfo.message[10].footer_text);
            setA12(result.messageinfo.message[11].footer_text);
          }
          setBanSw(true);
        } else {
          setBanSw(true);
        }
      }
    );
  }, [options, options2]);

  const axiosFun = (data, no) => {
    let sendData = {
      footer_text: textReplace(data),
      no: no,
    };
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/admin_update_footer_text",
      sendData,
      function (result) {
        if (result.messageinfo.state == "ok") {
          Swal.fire({
            title: "변경 완료",

            confirmButtonText: "확인",
          });
          // setArrayList(result.messageinfo.message);
        }
      }
    );
  };

  const changeA1 = useCallback((e) => {
    setA1(e.target.value);
  }, []);
  const changeA2 = useCallback((e) => {
    setA2(e.target.value);
  }, []);
  const changeA3 = useCallback((e) => {
    setA3(e.target.value);
  }, []);
  const changeA4 = useCallback((e) => {
    setA4(e.target.value);
  }, []);
  const changeA5 = useCallback((e) => {
    setA5(e.target.value);
  }, []);
  const changeA6 = useCallback((e) => {
    setA6(e.target.value);
  }, []);
  const changeA7 = useCallback((e) => {
    setA7(e.target.value);
  }, []);
  const changeA8 = useCallback((e) => {
    setA8(e.target.value);
  }, []);
  const changeA9 = useCallback((e) => {
    setA9(e.target.value);
  }, []);
  const changeA10 = useCallback((e) => {
    setA10(e.target.value);
  }, []);
  const changeA11 = useCallback((e) => {
    setA11(e.target.value);
  }, []);
  const changeA12 = useCallback((e) => {
    setA12(e.target.value);
  }, []);

  return (
    <>
      <div className={styles.wrap_content_box}>
        <div className={styles.wrap_content_box_header}>
          홈 &nbsp;〉 &nbsp;푸터 관리{" "}
        </div>
        <div className={styles.wrap_content_box_body}>
          <div className={`${styles.info_write_wrap} ${styles.footer_wrap}`}>
            <div className={`${styles.info_write_inner}`}>
              <div className={styles.info_write_title}>
                <h4>푸터</h4>
              </div>
              <div className={styles.info_write_content}>
                <div>
                  <label>상담시간</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a1}
                      onChange={changeA1}
                    />
                  </div>

                  <button onClick={() => axiosFun(a1, arrayList[0].no)}>
                    변경
                  </button>

                  {/* <input type="text" onChange={onChangeName} value={name} /> */}
                </div>
                <div>
                  <label>상호</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a2}
                      onChange={changeA2}
                    />
                  </div>
                  <button onClick={() => axiosFun(a2, arrayList[1].no)}>
                    변경
                  </button>
                </div>{" "}
                <div>
                  <label>대표이사</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a3}
                      onChange={changeA3}
                    />
                  </div>
                  <button onClick={() => axiosFun(a3, arrayList[2].no)}>
                    변경
                  </button>
                </div>
                <div>
                  <label>사업자등록번호</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a4}
                      onChange={changeA4}
                    />
                  </div>
                  <button onClick={() => axiosFun(a4, arrayList[3].no)}>
                    변경
                  </button>
                </div>
                <div>
                  <label>통신판매업신고번호</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a5}
                      onChange={changeA5}
                    />
                  </div>
                  <button onClick={() => axiosFun(a5, arrayList[4].no)}>
                    변경
                  </button>
                </div>
                <div>
                  <label>주소</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a6}
                      onChange={changeA6}
                    />
                  </div>
                  <button onClick={() => axiosFun(a6, arrayList[5].no)}>
                    변경
                  </button>
                </div>{" "}
                <div>
                  <label>이메일</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a7}
                      onChange={changeA7}
                    />
                  </div>
                  <button onClick={() => axiosFun(a7, arrayList[6].no)}>
                    변경
                  </button>
                  {/* <button onClick={() => btn_change_account2}>변경</button> */}
                </div>{" "}
                <div>
                  <label>전화</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a8}
                      onChange={changeA8}
                    />
                  </div>
                  <button onClick={() => axiosFun(a8, arrayList[7].no)}>
                    변경
                  </button>
                  {/* <button onClick={() => btn_change_account2}>변경</button> */}
                </div>{" "}
                <div>
                  <label>저작권</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a9}
                      onChange={changeA9}
                    />
                  </div>
                  <button onClick={() => axiosFun(a9, arrayList[8].no)}>
                    변경
                  </button>
                  {/* <button onClick={() => btn_change_account2}>변경</button> */}
                </div>{" "}
                <div>
                  <label>문구1</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a10}
                      onChange={changeA10}
                    />
                  </div>
                  <button onClick={() => axiosFun(a10, arrayList[9].no)}>
                    변경
                  </button>
                  {/* <button onClick={() => btn_change_account2}>변경</button> */}
                </div>{" "}
                <div>
                  <label>문구2</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a11}
                      onChange={changeA11}
                    />
                  </div>
                  <button onClick={() => axiosFun(a11, arrayList[10].no)}>
                    변경
                  </button>
                  {/* <button onClick={() => btn_change_account2}>변경</button> */}
                </div>{" "}
                <div>
                  <label>문구3</label>
                  <div className={styles.info_footer_div}>
                    <input
                      className={styles.info_footer_inp}
                      value={a12}
                      onChange={changeA12}
                    />
                  </div>
                  <button onClick={() => axiosFun(a12, arrayList[11].no)}>
                    변경
                  </button>
                  {/* <button onClick={() => btn_change_account2}>변경</button> */}
                </div>
                {/* <div>
                  <label>위약금</label>

                  <input
                    type="text"
                    placeholder="단위는 %입니다."
                    onChange={onChangePercent}
                    value={percent}
                  />
                  <button onClick={() => btn_change_percent(percent)}>
                    변경
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterList;
