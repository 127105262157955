import inv from "../../../styles/invest.module.css";

import { GiFarmTractor } from "react-icons/gi";
import { TbCup } from "react-icons/tb";
import {
  FcGrid,
  FcShipped,
  FcIcons8Cup,
  FcHome,
  FcStackOfPhotos,
  FcSelfie,
  FcRuler,
} from "react-icons/fc";
import { RiLoginBoxLine } from "react-icons/ri";
import React from "react";
import farm_2020 from "../../../assets/images/farm_2020.png";
import farm_2021 from "../../../assets/images/farm_2021.jpg";
import farm_2022 from "../../../assets/images/farm_2022.jpg";
import farm_2023 from "../../../assets/images/farm_2023.jpg";
import { useSelector } from "react-redux";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import SwiperCore, { Virtual, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import Swal from "sweetalert2";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import InvestProductItem from "./InvestProductItem";

import Moment from "react-moment";
import { wonComma } from "../../../lib/wonComma";
import { CommonAxios } from "../../CommonAxios";
import { MdError } from "react-icons/md";
// import FundingContentItem from "./FundingContentItem";

const MyinvestEContent = ({ user }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [myAccount, setMyAccount] = useState([]);
  const SliderItemList = ({ data }) => {
    const click_interest = () => {
      if (data.invest_interest_withdraw !== "N") {
        return Swal.fire({
          title: "종료 신청 실패",
          html: `현재 종료 신청 진행을 하였습니다.
      `,

          confirmButtonText: "확인",
        });
      }
      if (false) {
        return Swal.fire({
          title: "이자 출금 신청",
          text: "이자 출금은 10만원 이상부터만 가능합니다.",
          confirmButtonText: "확인",
        });
      } else {
        let inputNum = "";
        let inputName = "";
        let inputBank = "";
        let inputAccount = "";
        let mobile = "";
        let mobileN = "";
        Swal.fire({
          title: "종료 신청",
          showCancelButton: true,
          html: `아래 계좌로 입금 신청하시겠습니까?.<br/>
            <div style=" color:#00c73c; font-size:14px; "> (계좌 정보가 틀렸다면 마이페이지에서 변경하세요.)</div>

          



            <p style="text-align:center; margin-top:2px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">예금주</label><br/> ${myAccount[0].user_acName}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">은행 : </label> <br/>${myAccount[0].user_acBank}<p>
            <p style="text-align:center; margin-top:5px; color:#00c73c; font-size:14px !important; font-weight:500;"><label style="color:black;">계좌번호 : </label><br/> ${myAccount[0].user_account}<p>`,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "종료 신청",
              showCancelButton: true,
              // icon: "error",
              html: ` <p style="text-align:center; font-size:14px;">
              본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
              <label>핸드폰 번호</label>
              <input type="text" placeholder="하이픈(-)없이 입력해주세요" class="swal2-input" id="num-value5" />  
               `,
              confirmButtonText: "확인",
              cancelButtonText: "취소",
              preConfirm: (data) => {
                const mobileNum =
                  Swal.getHtmlContainer().querySelector("#num-value5");

                if (!/[0-9]/g.test(mobileNum.value)) {
                  Swal.showValidationMessage(`숫자만 입력하세요.`);
                } else {
                  mobile = mobileNum.value;
                  let sendData = {
                    mobileNum: mobileNum.value,
                  };
                  CommonAxios(
                    process.env.REACT_APP_HOSTDONAME + "/api/send_sms",
                    sendData,
                    function (result) {
                      // console.log(result);
                      if (result.messageinfo.state == "ok") {
                        mobileN = result.messageinfo.message;
                      } else {
                        Swal.showValidationMessage(`숫자만 입력하세요.`);
                      }
                    }
                  );
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire({
                  title: "종료 신청",
                  showCancelButton: true,
                  // icon: "error",
                  html: ` <p style="text-align:center; font-size:14px;">
                  본인 확인을 위해 핸드폰 인증을 완료해주세요</p><br/>
                  <label>인증 번호</label>
                  <input type="text" value='${mobileN}'  class="swal2-input" id="num-value6" />  
                   `,
                  confirmButtonText: "확인",
                  cancelButtonText: "취소",
                  preConfirm: (data) => {
                    const mobileNumA =
                      Swal.getHtmlContainer().querySelector("#num-value6");

                    if (!/[0-9]/g.test(mobileNumA.value)) {
                      Swal.showValidationMessage(`숫자만 입력하세요.`);
                    } else {
                      if (Number(mobileN) === Number(mobileNumA.value)) {
                      } else {
                        Swal.showValidationMessage(
                          `인증번호가 일치하지 않습니다.`
                        );
                      }
                    }
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    var sendData = {
                      end_user_id: user.no,
                      end_invest_no: data.invest_wait_id,
                      end_product_id: data.invest_product_no,
                      end_account: myAccount[0].user_account,
                      end_bank: myAccount[0].user_acBank,
                      end_name: myAccount[0].user_acName,
                      end_mobile: mobile.replace(
                        /^(\d{2,3})(\d{3,4})(\d{4})$/,
                        `$1-$2-$3`
                      ),
                      end_amount: data.invest_amount,
                      end_interest_result: Math.floor(data.invest_interest_sum),
                      end_rate: data.pro_rate,
                      end_percent: 0,
                      end_amount_result:
                        data.invest_amount +
                        Math.floor(data.invest_interest_sum),
                      end_kind: "E",
                    };
                    // setMobileGetNum("123456");
                    CommonAxios(
                      process.env.REACT_APP_HOSTDONAME +
                        "/api/myInvest_insert_end",
                      sendData,
                      function (result) {
                        // console.log(result);
                        if (result.messageinfo.state == "ok") {
                          let sendData2 = {
                            adminEmail: adminInfo[0].user_email,
                            adminMobile: adminInfo[0].user_mobile,
                            username: user.ur_name,
                            mobile: myAccount[0].user_mobile,
                            email: myAccount[0].user_email,
                            getamount: wonComma(
                              data.invest_amount +
                                Math.floor(data.invest_interest_sum)
                            ),
                            price: wonComma(data.invest_amount),
                            type: "종료",
                            name: myAccount[0].user_name,
                          };
                          CommonAxios(
                            process.env.REACT_APP_HOSTDONAME +
                              "/api/user_invest_comfirm_send",
                            sendData2,
                            function (result) {
                              if (result.messageinfo.state === "ok") {
                              }
                            }
                          );

                          Swal.fire({
                            title: "종료 신청 완료",

                            confirmButtonText: "확인",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              // setData(result.messageinfo.message);
                              let sendData = {
                                no: user.no,
                              };
                              // dispatch(selectInvest(sendData));
                              CommonAxios(
                                process.env.REACT_APP_HOSTDONAME +
                                  "/api/myInvest_select_end",
                                sendData,
                                function (result) {
                                  if (result.messageinfo.state === "ok") {
                                    setData(result.messageinfo.message);
                                    setData1(result.messageinfo.message_data2);
                                    setMyAccount(
                                      result.messageinfo.message_data3
                                    );
                                  } else {
                                  }
                                }
                              );
                            }
                          });
                        } else {
                        }
                      }
                    );
                  }
                });
              }
            });
          }
        });
      }
    };
    return (
      <>
        <div className={inv.invest_myList_end_item}>
          <div className={inv.invest_myList_end_title}>
            {" "}
            {data.pro_title ? data.pro_title : "삭제된 상품"}
          </div>
          <div className={inv.invest_myList_end_join}>
            만기일{" "}
            <Moment format="YYYY-MM-DD HH:mm">{data.invest_end_date}</Moment>
          </div>
          <ul>
            <li>
              <span>투자금액</span>
              <span className={inv.myList_end_value}>
                {wonComma(data.invest_amount)}원
              </span>
            </li>{" "}
            <li>
              <span>금리(종료당일)</span>
              <span className={inv.myList_end_value}>{data.invest_rate}%</span>
            </li>{" "}
            <li>
              <span>총 이자</span>
              <span className={inv.myList_end_value}>
                {" "}
                {wonComma(data.invest_interest_sum)}원
              </span>
            </li>
          </ul>
          <button onClick={click_interest}>종료 신청</button>
        </div>
      </>
    );
  };

  const SliderItemList1 = ({ data }) => {
    return (
      <>
        <div className={inv.invest_myList_end_item}>
          <div className={inv.invest_myList_end_title}>{data.pro_title}</div>
          <div className={inv.invest_myList_end_join}>
            만기일{" "}
            <Moment format="YYYY-MM-DD HH:mm">{data.invest_cancel_date}</Moment>
          </div>
          <ul>
            <li>
              <span>투자금액</span>
              <span className={inv.myList_end_value}>
                {wonComma(data.invest_amount)}원
              </span>
            </li>{" "}
            <li>
              <span>금리(종료당일)</span>
              <span className={inv.myList_end_value}>{data.invest_rate}%</span>
            </li>{" "}
            <li>
              <span>총 이자</span>
              <span className={inv.myList_end_value}>
                {" "}
                {wonComma(data.invest_interest_sum)}원
              </span>
            </li>
            <li>
              <span>받을금액</span>
              <span className={inv.myList_end_value}>
                {" "}
                {wonComma(
                  data.invest_amount + Math.floor(data.invest_interest_sum)
                )}
                원
              </span>
            </li>{" "}
          </ul>
        </div>
      </>
    );
  };
  const slickRef = useRef(null);

  const [state, setState] = useState(1);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [mainImageIndx, setMainImageIndx] = useState(0);
  const parmss = {
    navigation: { prevEl: prevRef.current, nextEl: nextRef.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper,
    onSlideChange: (e) => setMainImageIndx(e.activeIndex),
  };

  const prevRef1 = useRef(null);
  const nextRef1 = useRef(null);
  const [swiper1, setSwiper1] = useState(null);
  const [mainImageIndx1, setMainImageIndx1] = useState(0);
  const parmss1 = {
    navigation: { prevEl: prevRef1.current, nextEl: nextRef1.current },
    onBeforeInit: (swiper) => {
      swiper.params.navigation.prevEl = prevRef1.current;
      swiper.params.navigation.nextEl = nextRef1.current;
      swiper.activeIndex = mainImageIndx;
      swiper.navigation.update();
    },
    onSwiper: setSwiper1,
    onSlideChange: (e) => setMainImageIndx1(e.activeIndex),
  };
  const [skeleton, setSkeleton] = useState(false);
  const [switchs, setSwtichs] = useState(false);

  const [adminInfo, setAdminInfo] = useState([]);

  useEffect(() => {
    let sendData = {
      // no: id,
      no: user.no,
    };

    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/myInvest_select_end",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setData(result.messageinfo.message);
          setData1(result.messageinfo.message_data2);
          setMyAccount(result.messageinfo.message_data3);
          setSkeleton(true);
        } else {
        }
      }
    );
  }, []);

  useEffect(() => {
    let sendData = {
      // no: id,
    };

    // dispatch(selectInvest(sendData));
    CommonAxios(
      process.env.REACT_APP_HOSTDONAME + "/api/select_admin_info",
      sendData,
      function (result) {
        if (result.messageinfo.state === "ok") {
          setAdminInfo(result.messageinfo.message);
        } else {
        }
      }
    );
  }, []);

  return (
    <div className={inv.invest_my_wrap}>
      {" "}
      <div className={inv.invest_my_content}>
        <div className={inv.invest_my_top}>
          <h4>
            <em>{user.ur_name}</em>님의
            <br />
            종료상품 내역입니다.
          </h4>
        </div>
        <div
          className={inv.invest_my_bottom}
          style={{
            border: "2.5px solid #e5e7eb",

            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <h4>종료된 상품(종료 신청 전)</h4>

          <div className={inv.invest_my_list_box}>
            {" "}
            {data.length === 0 && (
              <>
                {/* <div className={inv.invest_my_data_end_not}>
                  <h4 className={inv.invest_my_data_end_not_h4}>
                    <MdError style={{ marginRight: "4px" }} /> 종료된 투자
                    상품이 없습니다.
                  </h4>
                </div> */}
              </>
            )}
            {data.length > 0 && (
              <>
                <Swiper
                  className="mySwiper"
                  slidesPerView={1}
                  ref={setSwiper}
                  {...parmss}
                  style={{ position: "relative" }}
                  // history={{
                  //   key: "slide",
                  // }}
                  modules={[Navigation]}
                  breakpoints={{
                    740: {
                      slidesPerView: 3,
                      // spaceBetween: 20,
                    },
                    970: {
                      slidesPerView: 4,
                      // spaceBetween: 20,
                    },
                  }}
                >
                  {data.map((data) => {
                    return (
                      <SwiperSlide>
                        <div>
                          <SliderItemList data={data} />
                        </div>{" "}
                      </SwiperSlide>
                    );
                  })}
                  {/* </div>
                );
              })} */}
                </Swiper>{" "}
                <ButtonPrev2
                  ref={prevRef}

                  // style={{ width: "100px", height: "100px", background: "blue" }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdKeyboardArrowLeft />{" "}
                  </div>
                </ButtonPrev2>{" "}
                {/* <div
                ref={prevRef}
                style={{ width: "100px", height: "100px", background: "blue" }}
              ></div> */}
                <ButtonNext2 ref={nextRef}>
                  {" "}
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdKeyboardArrowRight />
                  </div>
                </ButtonNext2>
              </>
            )}
          </div>
        </div>
        <div
          className={inv.invest_my_bottom}
          style={{
            marginBottom: "40px",
            border: "2.5px solid #e5e7eb",

            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <h4>종료된 상품(종료 신청 후)</h4>

          <div className={inv.invest_my_list_box}>
            {" "}
            {data1.length === 0 && (
              <>
                {/* <div className={inv.invest_my_data_end_not}>
                  <h4 className={inv.invest_my_data_end_not_h4}>
                    <MdError style={{ marginRight: "4px" }} /> 종료신청하신 투자
                    상품이 없습니다.
                  </h4>
                </div> */}
              </>
            )}
            {data1.length > 0 && (
              <>
                {" "}
                <Swiper
                  className="mySwiper"
                  slidesPerView={1}
                  ref={setSwiper1}
                  {...parmss1}
                  style={{ position: "relative" }}
                  // history={{
                  //   key: "slide",
                  // }}
                  modules={[Navigation]}
                  breakpoints={{
                    740: {
                      slidesPerView: 3,
                      // spaceBetween: 20,
                    },
                    970: {
                      slidesPerView: 4,
                      // spaceBetween: 20,
                    },
                  }}
                >
                  {/* {a.map((data) => {
                return (
                  <div> */}
                  {data1.map((data) => {
                    return (
                      <SwiperSlide>
                        <div>
                          <SliderItemList1 data={data} />
                        </div>{" "}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>{" "}
                <ButtonPrev3
                  ref={prevRef1}

                  // style={{ width: "100px", height: "100px", background: "blue" }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdKeyboardArrowLeft />{" "}
                  </div>
                </ButtonPrev3>{" "}
                {/* <div
                ref={prevRef}
                style={{ width: "100px", height: "100px", background: "blue" }}
              ></div> */}
                <ButtonNext3 ref={nextRef1}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MdKeyboardArrowRight />
                  </div>
                </ButtonNext3>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const ButtonPrev2 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 50%;
  left: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonNext2 = styled.div`
  position: absolute;
  z-index: 1;

  width: 35px;
  height: 35px;
  display: block;
  background: #fff;
  top: 50%;
  right: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonPrev3 = styled.div`
  position: absolute;
  z-index: 1;
  display: block;
  width: 35px;
  height: 35px;
  background: #fff;
  top: 50%;
  left: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
const ButtonNext3 = styled.div`
  position: absolute;
  z-index: 1;

  width: 35px;
  height: 35px;
  display: block;
  background: #fff;
  top: 50%;
  right: -8px;
  margin-top: -25px;
  font-size: 26px;
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.4);
  border-radius: 50%;
  color: #939393;
`;
export default MyinvestEContent;
