import MypageInvestDetail from "../components/mypage/MypageInvestDetail";

const MypageInvestInfo = () => {
  return (
    <>
      <MypageInvestDetail />
    </>
  );
};

export default MypageInvestInfo;
